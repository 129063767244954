import React from 'react';
import useRedirect from '../customHooks/useRedirect';
import DoctorCircleCards from '../doctorCircles/DoctorCircleCards';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import './css/doctorSlide.scss';
import LazyImage from '../CustomLibraries/LazyImage';
import TrackerWrapper from '../tracking/TrackerWrapper';
import { CARD_VIEW } from '../tracking/constants';
const DoctorSlide = ({ data, id, type }) => {
  const { redirectTo } = useRedirect();
  const doctorData = data?.session_doctor_entities;
  return (
    <div className="cmnDoctor pe-0 pe-md-4 mb-20">
      {doctorData?.length == 1 ? (
        <div
          className="cmnDoctorBox text-start"
          // onClick={() => {
          //   if (doctorData[0]?.session_doctor_id) {
          //     redirectTo(
          //       'SpeakerProfile',
          //       doctorData[0]?.session_doctor_id,
          //       doctorData[0]?.session_doctor_name
          //     );
          //   }
          // }}
        >
          <div className="d-inline-flex rounded bg-white cmnDoctorBoxIn py-2 px-3 border border-light d-flex justify-content-between align-items-center">
            <div className="cmnDoctorPic rounded-circle position-relative overflow-hidden border border-white">
              <LazyImage
                src={doctorData[0].session_doctor_image}
                className="object-fit-cover w-100 h-100 float-start"
                fallback_image={'https://assets.clirnet.com/common/defaultdr.png'}
                logo
              />
            </div>
            <div className="cmnDoctorContent ps-3">
              <h4 className="text-black fs-4">{doctorData[0].session_doctor_name}</h4>
              <p className="text-dark fs-5">{doctorData[0].DepartmentName}</p>
            </div>
          </div>
        </div>
      ) : null}

      {/* //&& doctorData?.length < 3 */}
      {doctorData?.length > 1 && doctorData?.length < 3 ? (
        <Swiper spaceBetween={6} slidesPerView={1} loop={false}>
          {doctorData?.map((singleData, index) => {
            return (
              <SwiperSlide
                key={index + 1}
                className="cmnDoctorBox text-start pr-2"
                // onClick={() => {
                //   if (singleData?.session_doctor_id) {
                //     redirectTo(
                //       'SpeakerProfile',
                //       singleData?.session_doctor_id,
                //       singleData?.session_doctor_name
                //     );
                //   }
                // }}
              >
                <TrackerWrapper
                  type={CARD_VIEW}
                  data={singleData}
                  className="w-100 rounded bg-white cmnDoctorBoxIn py-2 px-3 border border-light d-flex justify-content-between align-items-center"
                >
                  <div className="cmnDoctorPic rounded-circle position-relative overflow-hidden border border-white">
                    <LazyImage
                      src={singleData.session_doctor_image}
                      className="object-fit-cover w-100 h-100 float-start"
                      fallback_image={'https://assets.clirnet.com/common/defaultdr.png'}
                      logo
                    />
                  </div>
                  <div className="cmnDoctorContent ps-3">
                    <h4 className="text-black fs-4">{singleData.session_doctor_name}</h4>
                    <p className="text-dark fs-5">{singleData.DepartmentName}</p>
                  </div>
                </TrackerWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
      {doctorData?.length >= 3 && <DoctorCircleCards data={data} type={type} id={id} />}
    </div>
  );
};

export default DoctorSlide;
