import React, { memo } from 'react';
import WhatMakesYouMentor from '../../../share/images/whatMakesYouMentor.svg';
import LazyImage from '../../../../components/CustomLibraries/LazyImage';
import { default_images } from '../../../../common/defaultImages';
import AapiLogo from '../../../../assets/images/aapi-logo.png';
import { isMobile } from 'react-device-detect';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../../components/tracking/constants';
const ClaimMentorSection = () => {
  return (
    <>
      <TrackerWrapper type={CARD_VIEW} data={{}} className="d-flex flex-column gap-md-20 gap-4">
        <div>
          <div className="rounded-4 bg-white gap-4 gap-md-20 position-relative overflow-hidden clr_v3_cornerGraphicsDynamic claimMentorSection_hero">
            <div className="position-absolute top-0 start-0 w-100 h-100 z-0 clr_v3_cornerGraphicsDynamic__Graphics z-0">
              <img src={WhatMakesYouMentor} className="w-100 h-100 object-fit-cover" />
            </div>

            <div className="row m-0 justify-content-center justify-content-md-between">
              <div
                className={
                  'col-md-4 col-sm-5 col-12 align-items-center justify-content-center d-flex position-relative ' +
                  (isMobile ? 'w-75 h-75' : '')
                }
              >
                <svg
                  className="position-absolute top-0 translate-middle-x start-50"
                  width="173"
                  height="90"
                  viewBox="0 0 173 90"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-97"
                    y="-178.755"
                    width="26"
                    height="366"
                    rx="13"
                    transform="rotate(-41.549 -97 -178.755)"
                    fill="#1FCB9D"
                    fill-opacity="0.5"
                  />
                  <rect
                    x="-73"
                    y="-210.755"
                    width="4.35324"
                    height="366"
                    rx="2.17662"
                    transform="rotate(-41.549 -73 -210.755)"
                    fill="#1FCB9D"
                    fill-opacity="0.5"
                  />
                </svg>
                <svg
                  className="position-absolute bottom-0 end-0"
                  width="90"
                  height="78"
                  viewBox="0 0 90 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_592_3396"
                    // style="mask-type:alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="90"
                    height="78"
                  >
                    <rect width="90" height="78" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_592_3396)">
                    <rect
                      x="-3"
                      y="13.2446"
                      width="26"
                      height="366"
                      rx="13"
                      transform="rotate(-41.549 -3 13.2446)"
                      fill="#1FCB9D"
                      fill-opacity="0.5"
                    />
                    <rect
                      x="8"
                      y="46.8872"
                      width="4.35324"
                      height="366"
                      rx="2.17662"
                      transform="rotate(-41.549 8 46.8872)"
                      fill="#1FCB9D"
                      fill-opacity="0.5"
                    />
                  </g>
                </svg>

                <div className="py-20 py-sm-50 w-100 ">
                  <div
                    className="position-relative bg-soft-secondary rounded-4 overflow-hidden w-100"
                    style={{ aspectRatio: '1 / 1' }}
                  >
                    <LazyImage
                      src={default_images.observership1}
                      alt="Mentor"
                      className="w-100 h-100 object-fit-cover"
                      extraClass="w-100 h-100 overflow-hidden"
                    />
                    <svg
                      className="w-100 h-100 position-absolute top-0 start-0 z-1"
                      width="302"
                      height="302"
                      viewBox="0 0 302 302"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M302 0H0V302H302V0ZM161 278C235.558 278 296 217.558 296 143C296 68.4416 235.558 8 161 8C86.4416 8 26 68.4416 26 143C26 217.558 86.4416 278 161 278Z"
                        fill="white"
                      />
                      <path
                        d="M127.443 291.504C126.78 294.44 123.858 296.292 120.947 295.527C99.4263 289.876 79.3238 279.728 61.9752 265.73C43.2112 250.591 28.1686 231.35 18.0034 209.488C7.83825 187.625 2.82133 163.723 3.33827 139.619C3.81622 117.332 9.01292 95.4217 18.5616 75.3244C19.8534 72.6055 23.1519 71.5654 25.8245 72.9503V72.9503C28.4971 74.3353 29.5311 77.6207 28.2463 80.3429C19.4604 98.9578 14.6787 119.232 14.2365 139.852C13.7553 162.291 18.4254 184.541 27.8879 204.892C37.3504 225.243 51.3533 243.154 68.8202 257.247C84.8716 270.198 103.456 279.608 123.351 284.888C126.261 285.66 128.107 288.568 127.443 291.504V291.504Z"
                        fill="#159A6C"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="py-20 py-sm-70 ps-md-5 d-flex align-items-center col-md-8 col-sm-7 col-12">
                <div className="d-flex w-100 flex-column">
                  <h4 className="fs-5 text-dark fst-italic mb-4">
                    Join Today to Shape Tomorrow's Excellence."
                  </h4>
                  <div className="d-flex gap-4 justify-content-between flex-row-reverse mb-20 mb-md-5">
                    <img
                      src={AapiLogo}
                      alt="sponsor"
                      width=""
                      height=""
                      className="claimMentorSection_hero__apiLogo w-auto h-auto"
                    />
                    <h2 className="fs-4 text-black ">
                      <span className="d-block">What Makes you</span>

                      <span className="fw-semibold fs-2 text-white bg-secondary py-2 px-3 d-inline-block mt-2">
                        A Mentor Doctor!
                      </span>
                    </h2>
                  </div>
                  <ul className="d-flex flex-column m-0 gap-20 list-unstyled">
                    <li className="">
                      <h4 className="fs-4 mb-2 text-black fw-semibold">Clinical Expertise:</h4>
                      <p>Possesses advanced knowledge and experience in their medical specialty.</p>
                    </li>
                    <li>
                      <h4 className="fs-4 mb-2 text-black fw-semibold">Effective Communication:</h4>
                      <p>
                        Demonstrates strong communication skills to impart knowledge and guide
                        effectively.
                      </p>
                    </li>
                    <li>
                      <h4 className="fs-4 mb-2 text-black fw-semibold">Empathy and Compassion:</h4>
                      <p>
                        Shows genuine care for mentees' professional development and well-being.
                      </p>
                    </li>
                    <li>
                      <h4 className="fs-4 mb-2 text-black fw-semibold">Commitment to Education:</h4>
                      <p>
                        Dedicated to sharing knowledge, fostering learning, and supporting career
                        growth.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TrackerWrapper>
    </>
  );
};

export default memo(ClaimMentorSection);
