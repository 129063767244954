import React, { memo, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

const MainContainer = (props) => {
  const [eleHeight, setEleHeight] = useState(0);
  useEffect(() => {
    if (document.getElementById('headerDesktopNavBar')) {
      setEleHeight(document.getElementById('headerDesktopNavBar').offsetHeight);
    }
    // updatePosition();
  }, []);

  return (
    <div
      className={`col-lg-6 col-12 clr_v3_mainContent px-0 pb-4 d-flex justify-content-center flex-1 ${
        props.className ? props.className : ''
      }`}
      style={!isMobile ? { minHeight: `calc(100vh - ${eleHeight}px)`, top: `${eleHeight}px` } : {}}
    >
      <div className="w-100 py-0 px-3 p-lg-4 pb-lg-0 clr_v3_mainContent__width container-maxWidth">
        {props.children}
      </div>
    </div>
  );
};

export default memo(MainContainer);
