import React from 'react';
import '../../pages/shareLogin/css/doctubeShare.scss';
import { Skeleton } from '../Skeleton';

const DoctubeShareSkeleton = () => {
  return (
    <div className="col-xxl-4 col-lg-5 col-md-5 col-12">
      <div className="dt_shareTop_Left text-start pe-lg-4 pe-md-3">
        <h4 className="fs-2 fw-semibold text-black mb-3">
          <Skeleton variant="text" height={25} width={90} />
        </h4>
        <div className="dt_Logo mb-4">
          <Skeleton variant="rectangular" height={80} width={190} className="rounded-3" />
        </div>
        <h5 className="fs-16 fw-medium text-black mb-2">
          <Skeleton variant="text" height={30} width={'100%'} />
          <Skeleton variant="text" height={30} width={'35%'} />
        </h5>
        <h6 className="fs-4 fw-medium text-dark">
          <Skeleton variant="text" height={20} width={'100%'} />
          <Skeleton variant="text" height={20} width={'100%'} />
          <Skeleton variant="text" height={20} width={'30%'} />
        </h6>
        <div className="dt_share_statArea my-4">
          <Skeleton
            variant="rectangular"
            height={50}
            width={100}
            className="d-inline-flex text-start align-items-center justify-content-center rounded-3 dt_share_statBox"
          />
          <Skeleton
            variant="rectangular"
            height={50}
            width={100}
            className="d-inline-flex text-start align-items-center justify-content-center rounded-3 dt_share_statBox"
          />
          <Skeleton
            variant="rectangular"
            height={50}
            width={100}
            className="d-inline-flex text-start align-items-center justify-content-center rounded-3 dt_share_statBox"
          />
        </div>
        <a
          href="javascript:void(0)"
          className="cmnBtn fs-4 fw-medium rounded-3 text-white text-center text-capitalize w-100 dt_bg-secondary mb-5 d-md-none"
        >
          Join Now
        </a>
      </div>
    </div>
  );
};

export default DoctubeShareSkeleton;
