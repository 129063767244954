import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import LazyImage from '../../CustomLibraries/LazyImage';
import { card_pic_static_image } from '../../../common/assets.common';
import useRedirect from '../../customHooks/useRedirect';
import { routeNames } from '../../../router/constants';
import TrackerWrapper from '../../tracking/TrackerWrapper';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { CARD_VIEW, CARD_CLICK } from '../../tracking/constants';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const SpeakerDoc = ({ data = [], fullData = {}, fontSize = 4, iconSize = 32, smIconSize = 36 }) => {
  const docNameList = data && data.length > 0 ? data?.map((i) => i.doctor_name) : [];
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();

  return (
    <>
      {data && data.length > 0 && (
        <TrackerWrapper
          data={fullData}
          type={CARD_VIEW}
          className="clr_v3_card__speaker d-flex align-items-center justify-content-between"
        >
          <div>
            <div className=" d-flex align-items-center">
              <div className="me-3 d-flex">
                {data && data.length > 1
                  ? data.slice(0, 2).map((_i, indx) => (
                      <div
                        className={`hover-image rounded-circle p-33 border border-primary border-105 bg-white ${
                          indx != 0 ? 'ms-n4 z-1' : ''
                        }`}
                        key={indx + 1}
                      >
                        <div
                          className={`icon-size-${iconSize} icon-size-sm-${smIconSize} overflow-hidden rounded-circle cursorPointer`}
                          onClick={() => {
                            trackActivityEvent(CARD_CLICK, _i);
                            redirectTo(
                              routeNames.sessions.speaker,
                              _i.session_doctor_id,
                              _i.session_doctor_name
                            );
                          }}
                        >
                          <LazyImage
                            src={
                              _i.session_doctor_image !== ''
                                ? _i.session_doctor_image
                                : card_pic_static_image
                            }
                            alt="banner"
                            fallback_image={card_pic_static_image}
                            className={`object-fit-cover w-100 h-100`}
                          />
                        </div>
                      </div>
                    ))
                  : data &&
                    data?.length > 0 && (
                      <div
                        className="rounded-circle p-33 border border-primary border-105 bg-white cursorPointer"
                        onClick={() => {
                          trackActivityEvent(CARD_CLICK, data[0]);
                          redirectTo(
                            routeNames.sessions.speaker,
                            data[0]?.session_doctor_id,
                            data[0]?.session_doctor_name
                          );
                        }}
                      >
                        <div
                          className={`icon-size-${iconSize} icon-size-sm-${smIconSize} overflow-hidden rounded-circle`}
                        >
                          <LazyImage
                            src={
                              data[0]?.session_doctor_image !== ''
                                ? data[0]?.session_doctor_image
                                : card_pic_static_image
                            }
                            alt="banner"
                            fallback_image={card_pic_static_image}
                            className={`object-fit-cover w-100 h-100`}
                          />
                        </div>
                      </div>
                    )}
              </div>

              <div className="flex-grow-1">
                {data && data.length > 1 ? (
                  <h4 className={`fs-${fontSize} line-clamp-2`}>
                    {/* Speaker{' '} */}
                    <span
                      className="fw-semibold hover-primary cursorPointer"
                      onClick={() => {
                        trackActivityEvent(CARD_CLICK, data[0]);
                        redirectTo(
                          routeNames.sessions.speaker,
                          data[0].session_doctor_id,
                          data[0].session_doctor_name
                        );
                      }}
                    >
                      {data[0].session_doctor_name},{' '}
                    </span>{' '}
                    <span
                      className="fw-semibold hover-primary cursorPointer"
                      onClick={() => {
                        trackActivityEvent(CARD_CLICK, data[1]);
                        redirectTo(
                          routeNames.sessions.speaker,
                          data[1].session_doctor_id,
                          data[1].session_doctor_name
                        );
                      }}
                    >
                      {data[1].session_doctor_name}
                    </span>{' '}
                    {data.length > 2 && (
                      <>
                        and{' '}
                        <span className="fw-semibold hover-primary cursorPointer">
                          {data.length - 2} more{' '}
                        </span>
                      </>
                    )}
                  </h4>
                ) : (
                  data &&
                  data?.length > 0 && (
                    <div className="d-flex flex-column">
                      <h4
                        className={`fs-${fontSize} line-clamp-1 fw-semibold hover-primary cursorPointer`}
                        onClick={() => {
                          trackActivityEvent(CARD_CLICK, data[0]);
                          redirectTo(
                            routeNames.sessions.speaker,
                            data[0].session_doctor_id,
                            data[0].session_doctor_name
                          );
                        }}
                      >
                        {data[0].session_doctor_name}
                      </h4>
                      {/* {data && data[0].profile && <p className="line-clamp-1">{data[0].profile}</p>} */}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </TrackerWrapper>
      )}
    </>
  );
};

SpeakerDoc.propTypes = propTypes;
// #endregion

export default memo(SpeakerDoc);
