import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useBookedSlots } from '../../../../Queries/QueryHooks/observership.hook';
import dayjs from 'dayjs';
import useRedirect from '../../../../components/customHooks/useRedirect';
import { routeNames } from '../../../../router/constants';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../../components/tracking/constants';

function UpcomingAppointments({ cardType = 'appointment' }) {
  if (cardType != 'career') {
    return null;
  }
  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={{}}
      className="rounded-3 bg-extra-light p-3 p-sm-4 d-flex flex-column observership-card-1 gap-2 border border-primary"
    >
      <h2 className="fs-16 text-black fw-semibold mb-2">
        <Title cardType={cardType} />
      </h2>
      <div className="d-flex p-3 bg-soft-primary rounded d-flex align-items-center gap-3 mb-1">
        <Content cardType={cardType} />
        <Icon cardType={cardType} />
      </div>
      <SubTitle cardType={cardType} />
      <Button cardType={cardType} />
    </TrackerWrapper>
  );
}

function Icon({ cardType }) {
  switch (cardType) {
    case 'appointment':
      return (
        <svg
          className="observership-card-1__icon icon-size-36 flex-shrink-0"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_817_16385)">
            <path
              d="M31.4581 8.4677C30.779 7.54851 30.0025 6.69642 29.1504 5.9349L29.8168 5.18896C30.7198 5.99605 31.5428 6.89911 32.2627 7.87322L31.4581 8.4677Z"
              fillOpacity={0.8}
            />
            <path
              d="M18.0641 35.9996C18.0632 35.9999 18.0624 35.9999 18.0607 35.9996C18.0607 35.9996 18.0604 35.9996 18.0601 35.9996C8.33091 35.9976 0.416016 28.0813 0.416016 18.3516C0.416016 15.5139 1.1059 12.6932 2.41038 10.1943L3.29701 10.6575C2.06643 13.0136 1.41645 15.6744 1.41645 18.3516C1.41645 27.5307 8.8835 34.9983 18.0624 34.9995C27.2413 34.9983 34.7083 27.5307 34.7083 18.3516C34.7083 15.6744 34.0583 13.0139 32.828 10.6575L33.7144 10.1943C35.0191 12.6934 35.7085 15.5139 35.7085 18.3516C35.7085 28.0813 27.7939 35.9976 18.0646 35.9996C18.0644 35.9996 18.0644 35.9996 18.0641 35.9996Z"
              fillOpacity={0.8}
            />
            <path
              d="M4.66782 8.4677L3.86328 7.87322C4.58289 6.89967 5.40555 5.99662 6.30917 5.18896L6.97556 5.93462C6.1229 6.6967 5.34667 7.5488 4.66782 8.4677Z"
              fillOpacity={0.8}
            />
            <path
              d="M20.0503 14.2998H12.7505C11.7877 14.2998 11 15.0876 11 16.0504V21.9497C11 22.9125 11.7877 23.7002 12.7505 23.7002H20.0503C21.0131 23.7002 21.8009 22.9125 21.8009 21.9497V16.0504C21.8009 15.07 21.0131 14.2998 20.0503 14.2998Z"
              fillOpacity={0.8}
            />
            <path
              d="M25.7042 15.2626C25.5992 15.2801 25.4942 15.3326 25.4066 15.3851L22.6758 16.9606V21.0219L25.4241 22.5974C25.9318 22.895 26.562 22.7199 26.8596 22.2122C26.9471 22.0547 26.9996 21.8796 26.9996 21.6871V16.2779C26.9996 15.6302 26.3869 15.105 25.7042 15.2626Z"
              fillOpacity={0.8}
            />
            <path d="M19.1746 17.0339C20.7532 17.0339 22.0329 15.7542 22.0329 14.1756C22.0329 12.5971 20.7532 11.3174 19.1746 11.3174C17.5961 11.3174 16.3164 12.5971 16.3164 14.1756C16.3164 15.7542 17.5961 17.0339 19.1746 17.0339Z" />
            <path
              d="M19.1758 11C18.5477 11 17.9337 11.1863 17.4114 11.5352C16.8892 11.8842 16.4821 12.3802 16.2417 12.9605C16.0014 13.5408 15.9385 14.1793 16.061 14.7954C16.1836 15.4114 16.486 15.9773 16.9302 16.4215C17.3743 16.8656 17.9402 17.1681 18.5562 17.2906C19.1723 17.4132 19.8109 17.3503 20.3912 17.1099C20.9715 16.8695 21.4675 16.4625 21.8164 15.9402C22.1654 15.418 22.3516 14.8039 22.3516 14.1758C22.3507 13.3338 22.0157 12.5266 21.4204 11.9313C20.825 11.3359 20.0178 11.001 19.1758 11ZM19.1758 16.7165C18.6733 16.7165 18.1821 16.5675 17.7643 16.2883C17.3465 16.0091 17.0209 15.6123 16.8286 15.1481C16.6363 14.6838 16.586 14.173 16.684 13.6802C16.782 13.1873 17.024 12.7346 17.3793 12.3793C17.7346 12.024 18.1873 11.782 18.6802 11.684C19.173 11.586 19.6838 11.6363 20.1481 11.8286C20.6123 12.0209 21.0091 12.3465 21.2883 12.7643C21.5675 13.1821 21.7165 13.6733 21.7165 14.1758C21.7158 14.8494 21.4479 15.4952 20.9716 15.9716C20.4952 16.4479 19.8494 16.7158 19.1758 16.7165Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_817_16385">
              <rect width="36" height="36" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case 'career':
      return (
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1240_22430)">
            <path
              d="M9.2941 13.3499H8.1122V12.168H7.11205V13.3499H5.80078V14.35H7.11205V15.6613H8.1122V14.35H9.2941V13.3499Z"
              fill="#F44141"
            />
            <path
              d="M29.1205 24.822C29.1205 26.6474 27.677 28.1325 25.8687 28.2058V28.2092H25.807C25.782 28.2092 25.7568 28.2126 25.7302 28.2126C25.7053 28.2126 25.6801 28.2092 25.6538 28.2092H10.7608V28.2041C10.709 28.2075 10.6605 28.2126 10.6087 28.2126C8.73696 28.2126 7.21875 26.6941 7.21875 24.822C7.21875 23.2718 8.26052 21.9651 9.68219 21.5634C9.79486 21.5308 9.91008 21.5042 10.027 21.4836C10.3075 21.4346 10.5204 21.2044 10.534 20.9199C10.5349 20.8998 10.536 20.882 10.5371 20.8684C10.7189 18.4548 12.7339 16.5547 15.1942 16.5547C16.0826 16.5547 16.9146 16.803 17.6228 17.2364C17.6605 17.259 17.6973 17.2825 17.7341 17.306C18.0039 17.481 18.349 17.4722 18.6182 17.2958C18.6199 17.295 18.6213 17.2941 18.623 17.293C19.3514 16.8262 20.2165 16.5547 21.1448 16.5547C23.525 16.5547 25.4902 18.3348 25.7786 20.6368V20.6399C25.7823 20.6677 25.7854 20.7028 25.7886 20.7421C25.8174 21.117 26.0852 21.4303 26.4524 21.5113L26.462 21.5133C27.9822 21.8468 29.1205 23.2002 29.1205 24.822Z"
              fill="#F8A496"
            />
            <path
              d="M15.286 28.2118H14.2825C14.1559 27.0964 13.1643 26.7063 13.1526 26.7015L12.6855 26.5249L13.0408 25.5898L13.5076 25.7665C14.1092 25.9964 15.1793 26.7848 15.286 28.2118Z"
              fill="#DF5030"
            />
            <path
              d="M23.0435 26.5249L22.5784 26.7015C22.5232 26.7233 21.5715 27.1216 21.4466 28.2118H20.4434C20.5498 26.7848 21.6199 25.9964 22.2217 25.7665L22.6885 25.5898L23.0435 26.5249Z"
              fill="#DF5030"
            />
            <path
              d="M21.9632 19.0752L21.2767 19.8036L20.9132 19.4605C20.0914 18.6854 18.653 19.2488 18.638 19.2536L18.6213 19.2601V25.9444H17.6212V21.0606C17.2611 20.9453 16.1511 20.662 15.466 21.3071L15.1011 21.6505L14.416 20.9221L14.7792 20.5801C15.6927 19.7201 16.9395 19.8552 17.6212 20.0219V17.2383C17.6588 17.2609 17.6956 17.2844 17.7324 17.3082C18.0022 17.4829 18.3473 17.4744 18.6165 17.298L18.6213 17.2949V18.2101C19.2611 18.0315 20.6216 17.8118 21.5983 18.7319L21.9632 19.0752Z"
              fill="#DF5030"
            />
            <path
              d="M12.6634 24.2798L11.6731 24.4233L11.6012 23.9282C11.5528 23.5865 11.4529 23.2898 11.3246 23.0313C10.8748 23.2731 10.3381 23.6898 10.2163 24.3333L10.1246 24.825L9.14258 24.6399L9.23458 24.1498C9.37783 23.3781 9.90296 22.703 10.7183 22.2248C10.3681 21.8979 9.97967 21.6912 9.6813 21.5664C9.79425 21.5335 9.90975 21.5069 10.0269 21.4862C10.3072 21.4376 10.5195 21.2077 10.5331 20.9237C10.534 20.9031 10.5351 20.8852 10.5362 20.8711C11.3614 21.3263 12.3616 22.1948 12.5915 23.7846L12.6634 24.2798Z"
              fill="#DF5030"
            />
            <path
              d="M25.7855 24.4394L26.1923 24.731L25.6071 25.5426L25.2023 25.2493C24.2372 24.5543 24.0671 23.7059 24.0838 23.1409C23.8055 23.1907 23.4987 23.2173 23.1604 23.2173H22.6602V22.2174H23.1604C24.9322 22.2174 25.5573 21.294 25.7773 20.6406C25.7815 20.6732 25.7855 20.7159 25.7889 20.7643C25.8152 21.1332 26.0861 21.4321 26.4479 21.5111L26.4606 21.514C26.2039 21.9822 25.7923 22.464 25.1389 22.7961L25.1123 22.9243C25.0254 23.3311 25.0721 23.9242 25.7855 24.4394Z"
              fill="#DF5030"
            />
            <path
              d="M31.4581 8.46624C30.779 7.54705 30.0025 6.69495 29.1504 5.93344L29.8168 5.1875C30.7198 5.99459 31.5428 6.89764 32.2627 7.87175L31.4581 8.46624Z"
              fill="#F44141"
            />
            <path
              d="M18.0641 36.0006C18.0632 36.0009 18.0624 36.0009 18.0607 36.0006C18.0607 36.0006 18.0604 36.0006 18.0601 36.0006C8.33091 35.9986 0.416016 28.0823 0.416016 18.3525C0.416016 15.5148 1.1059 12.6941 2.41038 10.1953L3.29701 10.6584C2.06643 13.0146 1.41645 15.6754 1.41645 18.3525C1.41645 27.5317 8.8835 34.9993 18.0624 35.0004C27.2413 34.9993 34.7083 27.5317 34.7083 18.3525C34.7083 15.6754 34.0583 13.0149 32.828 10.6584L33.7144 10.1953C35.0191 12.6944 35.7085 15.5148 35.7085 18.3525C35.7085 28.0823 27.7939 35.9986 18.0646 36.0006C18.0644 36.0006 18.0644 36.0006 18.0641 36.0006Z"
              fill="#D82650"
            />
            <path
              d="M4.66758 8.46624L3.86304 7.87175C4.58265 6.89821 5.40531 5.99515 6.30893 5.1875L6.97532 5.93316C6.12266 6.69523 5.34642 7.54733 4.66758 8.46624Z"
              fill="#F44141"
            />
            <path
              d="M18.6392 0.157681C18.5461 0.0583164 18.4161 0.00141544 18.28 0C18.2786 0 18.2766 0 18.2752 0C18.1407 0 18.0116 0.0540701 17.9176 0.150037L16.8968 1.19322C16.7558 1.33703 16.7151 1.55133 16.7929 1.73704C16.871 1.92246 17.0528 2.04306 17.2541 2.04306H17.7548V13.9928H18.7547V2.04306H19.2558C19.4551 2.04306 19.6354 1.92444 19.7147 1.74128C19.7939 1.55841 19.7566 1.34581 19.6198 1.20058L18.6392 0.157681Z"
              fill="#89C763"
            />
            <path
              d="M13.6998 4.36081C13.6063 4.26144 13.4767 4.20482 13.3405 4.20312C13.1993 4.20709 13.0733 4.25606 12.9782 4.35345L11.9571 5.39663C11.8164 5.54044 11.7756 5.75474 11.8535 5.94016C11.9313 6.12587 12.1131 6.24646 12.3143 6.24646H12.8151V13.996H13.8153V6.24646H14.3161C14.5156 6.24646 14.696 6.12785 14.7752 5.94497C14.8542 5.76181 14.8171 5.54921 14.6804 5.40399L13.6998 4.36081Z"
              fill="#FBAD18"
            />
            <path
              d="M24.4127 5.40399L23.4324 4.36081C23.3393 4.26144 23.2093 4.20482 23.0732 4.20312C23.0715 4.20312 23.0695 4.20312 23.0681 4.20312C22.9336 4.20312 22.8048 4.2572 22.7108 4.35345L21.6897 5.39663C21.549 5.54044 21.508 5.75474 21.5858 5.94016C21.6639 6.12587 21.8454 6.24646 22.047 6.24646H22.5477V13.996H23.5479V6.24646H24.0487C24.248 6.24646 24.4283 6.12785 24.5076 5.94497C24.5866 5.76181 24.5495 5.5495 24.4127 5.40399Z"
              fill="#FBAD18"
            />
            <path d="M12.8145 6.25H13.8146V7.25015H12.8145V6.25Z" fill="#F47E20" />
            <path d="M22.5469 6.24609H23.547V7.24625H22.5469V6.24609Z" fill="#F47E20" />
            <path d="M17.7539 2.05078H18.7538V3.05094H17.7539V2.05078Z" fill="#61AF60" />
          </g>
          <defs>
            <clipPath id="clip0_1240_22430">
              <rect width="36" height="36" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    default:
      return <></>;
  }
}

function Title({ cardType }) {
  switch (cardType) {
    case 'appointment':
      return (
        <>
          <span className="text-primary">Upcoming</span> Appointments
        </>
      );

    case 'career':
      return <>Career Advancement Hub</>;

    default:
      return <></>;
  }
}

function SubTitle({ cardType }) {
  switch (cardType) {
    case 'appointment':
      return (
        <>
          <p>View all upcoming appointment scheduled.</p>
        </>
      );

    case 'career':
      return <p>Unlock success with expert advice, courses, and exclusive career opportunities.</p>;

    default:
      return <></>;
  }
}
function Button({ cardType }) {
  const { redirectTo } = useRedirect();
  const onExploreClick = () => {
    redirectTo(routeNames.course.landing);
  };
  switch (cardType) {
    case 'appointment':
      return (
        <a href="javascript:void(0)" className="ms-auto d-inline-flex gap-2 align-items-center">
          View All <i className="flaticon flaticon-next fs-4"></i>
        </a>
      );

    case 'career':
      return (
        <a
          href="javascript:void(0)"
          onClick={onExploreClick}
          className="ms-auto d-inline-flex gap-2 align-items-center"
        >
          Explore now <i className="flaticon flaticon-next fs-4"></i>
        </a>
      );

    default:
      return <></>;
  }
}

function Content({ cardType }) {
  const userDetails = useSelector((state) => state.common.user_details);
  const { bookedSlots = [] } = useBookedSlots(userDetails?.mentor_user_master_id);
  let firstBookedSlot = bookedSlots[0];
  switch (cardType) {
    case 'appointment':
      return (
        <h4 className="flex-grow-1">
          {dayjs(firstBookedSlot?.date).format('dddd, DD MMMM YYYY')}{' '}
        </h4>
      );

    case 'career':
      return <h4 className="flex-grow-1">I want to improve my knowledge</h4>;

    default:
      return <></>;
  }
}
export default memo(UpcomingAppointments);
