// export const apifallback = [
//   {
//     authorisedURL: 'knwlg/feedDetail',
//     openAPIURL:"openapi/service?type=comp&type_id=100&utm_source=ANANT_DOCTUBE_AUTOAUTH&referal_code=&user_type=doctor_master&user_id=1"
//   }
// ];
// import appConfig from '../../config/config';
const baseURL = process.env.REACT_APP_API_URL;

export function generateApiFallback(authorisedURL) {
  if (authorisedURL) {
    if (
      authorisedURL.startsWith('knwlg/feedDetail') ||
      authorisedURL.startsWith('knwlg/archiveVideoDetail') ||
      authorisedURL.startsWith('Channelnew/detail') ||
      authorisedURL.startsWith('training/detail') ||
      authorisedURL.startsWith('survey/detail') ||
      authorisedURL.startsWith('knwlgmastersessionnew/sessiondetail_new') ||
      authorisedURL.startsWith('gr/detail') ||
      authorisedURL.startsWith('epub/detail') ||
      authorisedURL.startsWith('bot/historyDiagnosisbyTopic') ||
      authorisedURL.startsWith('Channelnew/all')
    ) {
      const url = new URL(authorisedURL, baseURL);
      var newURl = 'openapi/opendetail?type=';
      if (url.searchParams.has('type')) {
        newURl = newURl + url.searchParams.get('type')?.replaceAll('"', '');
      } else if (authorisedURL.startsWith('survey/detail')) {
        newURl = newURl + 'spq';
      } else if (authorisedURL.startsWith('bot/historyDiagnosisbyTopic')) {
        newURl = newURl + 'differential_diagnosis';
      } else if (authorisedURL.startsWith('Channelnew/all')) {
        newURl = newURl + 'channel_content';
      } else if (authorisedURL.startsWith('knwlgmastersessionnew/sessiondetail_new')) {
        newURl = newURl + 'session';
      }

      if (authorisedURL.startsWith('knwlgmastersessionnew/sessiondetail_new')) {
        newURl = newURl + '&type_id=' + url.searchParams.get('session_id');
      } else if (
        authorisedURL.startsWith('training/detail') ||
        authorisedURL.startsWith('survey/detail')
      ) {
        newURl = newURl + '&type_id=' + url.searchParams.get('id');
      } else if (authorisedURL.startsWith('bot/historyDiagnosisbyTopic')) {
        newURl = newURl + '&type_id=' + url.searchParams.get('topic_id');
      } else {
        newURl = newURl + '&type_id=' + url.searchParams.get('type_id');
      }

      return newURl;
    }
    return null;
  } else {
    return null;
  }
}
