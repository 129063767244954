import React from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import DetailsPageLayout from '../../layout/DetailsPageLayout';
import SpecialityDetailsLayout from '../../layout/SpecialityDetailsLayout';
import SpecialityLayout from '../../layout/SpecialityLayout';
import SpecialityLayoutOldPage from '../../layout/SpecialityLayoutOldPage';
import SpecialityDefaultLayout from '../../layout/uiv3/SpecialityDefaultLayout';

import { Outlet } from 'react-router-dom';
import OldLayoutManager from '../../components/OldLayoutManager';
import ApplicationDetails from '../../pages/uiv3/observership/ApplicationDetails';
import { routeNames } from '../constants';
import Loadable from '../Loadable';

const AllListing = Loadable({
  loader: () => import('../../pages/uiv3/Listing/AllListing')
});

const NewLogin = Loadable({
  loader: () => import('../../../src/pages/uiv3/auth/Login')
});
const Dashboard = Loadable({
  loader: () => import('../../../src/pages/dashboard/Dashboard')
});
const EbookListing = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookListing')
});
const EbookDetails = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookDetails')
});
const EpaperDetails = Loadable({
  loader: () => import('../../../src/pages/uiv3/epaper/EpaperDetails')
});
const Vault = Loadable({
  loader: () => import('../../../src/pages/vault/Vault')
});

const HelpAndSupport = Loadable({
  loader: () => import('../../../src/pages/helpAndSupport/HelpAndSupport')
});
const MedWikiListing = Loadable({
  loader: () => import('../../../src/pages/medwiki/MedwikiListing')
});
const MedwikiPopular = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiLandingComponents/MedwikiPopular')
});

const CpdReview = Loadable({
  loader: () => import('../../../src/pages/userProfile/CpdReview')
});

const GrandRoundListing = Loadable({
  loader: () => import('../../../src/pages/grandRounds/GrandRoundListing')
});

const DiscussRefer = Loadable({
  loader: () => import('../../../src/pages/discussRefer/DiscussRefer')
});
const HospitalProfile = Loadable({
  loader: () => import('../../../src/pages/discussRefer/hospitalProfile')
});
const DiscussForm = Loadable({
  loader: () => import('../../../src/pages/discussRefer/DiscussForm')
});
const CaseDetails = Loadable({
  loader: () => import('../../../src/pages/discussRefer/CaseDetails')
});
const ReInitiateRefer = Loadable({
  loader: () => import('../../../src/pages/discussRefer/ReInitiateRefer')
});
const Activities = Loadable({
  loader: () => import('../../../src/pages/discussRefer/Activities')
});
const ClinicalVideoList = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalVideoList')
});
const ClinicalVideoDetails = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalDetails')
});
const ClinicalVideoDetailsV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/clinicalVideo/ClinicalVideoDetails')
});
const ChannelLanding = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelLanding')
});
const ChannelManage = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelManage')
});
const ChannelProfile = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelProfile')
});
const TopRatedMedwikiListing = Loadable({
  loader: () => import('../../../src/pages/uiv3/medwikiV2/TopRatedMedwikiListing')
});
const ChannelSuggestion = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelSugesstion')
});
const ChannelLandingV2 = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelLandingV2')
});
const ChannelProfileV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/channel/ChannelProfile')
});

const ReferThankYou = Loadable({
  loader: () => import('../../../src/pages/discussRefer/ReferThankyou')
});
const SPQ = Loadable({
  loader: () => import('../../../src/pages/spq/spq')
});
const UserPoint = Loadable({
  loader: () => import('../../../src/pages/spq/UserPoint')
});
const SPQreview = Loadable({
  loader: () => import('../../../src/pages/spq/SPQreview')
});

const SessionListing = Loadable({
  loader: () => import('../../../src/pages/session/SessionListing')
});
const SessionWaitingRoom = Loadable({
  loader: () => import('../../../src/pages/session/SessionWaitingRoom')
});

const AllSessionList = Loadable({
  loader: () => import('../../../src/pages/session/AllSessionList')
});
const LiveSession = Loadable({
  loader: () => import('../../../src/pages/session/LiveSession')
});
const ElectionLandinng = Loadable({
  loader: () => import('../../../src/pages/election/ElectionLanding')
});
const CandidateSelection = Loadable({
  loader: () => import('../../../src/pages/election/CandidateSelection')
});
const VotingSummary = Loadable({
  loader: () => import('../../../src/pages/election/VotingSummary')
});
const MobileVerification = Loadable({
  loader: () => import('../../../src/pages/election/MobileVerification')
});

const Notification = Loadable({
  loader: () => import('../../../src/pages/uiv3/notification/Notification')
});
const DoctubeLanding = Loadable({
  loader: () => import('../../../src/pages/doctubeLanding/DoctubeLanding')
});

const DoctubeListingVideos = Loadable({
  loader: () =>
    import(
      '../../../src/pages/doctube/doctubeProfileManagement/components/listing/DoctubeListingVideos'
    )
});
const ProfileSteps = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/ProfileSteps')
});
const DoctubeDashboard = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeDashboard')
});
const DoctubeVideoUpload = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeVideoUpload')
});
const DoctubeManagePlaylist = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeManagePlaylist')
});

const ShareLoginNew = Loadable({
  loader: () => import('../../../src/pages/shareLogin/ShareLoginNew')
});

const ClinicalVideoLanding = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalVideoLanding')
});

const SearchResult = Loadable({
  loader: () => import('../../../src/pages/Search/SearchResult')
});
const SearchResultV2 = Loadable({
  loader: () => import('../../../src/pages/Search/SearchV3')
});
const SearchList = Loadable({
  loader: () => import('../../../src/pages/Search/SearchList')
});
const TelemedLite = Loadable({
  loader: () => import('../../../src/pages/telemedLite/TelemedLite')
});

const UploadCertificate = Loadable({
  loader: () => import('../../../src/pages/certificates/UploadCertificate')
});
const CertificatesListing = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificatesListing')
});

const ProfileVideos = Loadable({
  loader: () => import('../../../src/pages/userProfile/ProfileVideos')
});

const GRlanding = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingLanding')
});
const GRlisting = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingListing')
});
const GRcover = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingCover')
});
const GRcontent = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingContent')
});
const GRsummary = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingSummary')
});
const MedwikiLandingV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/medwikiV2/MedwikiLandingV2')
});
const Demo = Loadable({
  loader: () => import('../../../src/pages/Demo')
});
const MedwikiDetails = Loadable({
  loader: () => import('../../../src/pages/uiv3/medwiki/MedwikiDetails')
});
const MedwikiDetailsV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/medwikiV2/MedwikiDetailsV2')
});

const AdsPlayer = Loadable({
  loader: () => import('../../components/CustomLibraries/AdsPlayer')
});

const PollDetails = Loadable({
  loader: () => import('../../../src/pages/spq/PollDetails')
});

const CertificateDetails = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificateDetails')
});

const QueryListing = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/QueryListing')
});

const UpcomingSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/UpcomingSessionsList')
});

const PreviousSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/PrviousSession')
});

// const SpeakerProfile = Loadable({
//   loader: () => import('../../../src/pages/SpeakerProfile/SpeakerProfile')
// });

const SPQquestion = Loadable({
  loader: () => import('../../../src/pages/spq/SPQquestion')
});

const SPQsubmitSuccessfully = Loadable({
  loader: () => import('../../../src/pages/spq/SPQsubmitSuccessfully')
});

const MedwikiLanding = Loadable({
  loader: () => import('../../../src/pages/medwiki/MedwikiLanding')
});

const SPQcompleted = Loadable({
  loader: () => import('../../../src/pages/spq/SPQcompleted')
});
const SPQlanding = Loadable({
  loader: () => import('../../../src/pages/spq/SPQlanding')
});

// const SessionLanding = Loadable(() => import("../../src/pages/session/SessionLanding"));
// const SessionDetails = Loadable({
//   loader: () => import('../../../src/pages/session/SessionDetails'),
//
// });
const SessionDetailsOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/SessionDetailsOutlet')
});
const LiveSessionOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/LiveSessionOutlet')
});

const Photobooth = Loadable({
  loader: () => import('../../../src/pages/multidaySession/PhotoBooth')
});
// const ConnectDetails = Loadable({
//   loader: () => import('../../src/pages/connect/ConnectDetails'),
//
// });

const DoctubePlaylistDetails = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubePlaylistDetails')
});

const GrandRoundDetails = Loadable({
  loader: () => import('../../../src/pages/grandRounds/GrandRoundDetails')
});

const SPQdetails = Loadable({
  loader: () => import('../../../src/pages/spq/SPQdetails')
});
const SpecialityDashboard = Loadable({
  loader: () => import('../../../src/pages/uiv3/dashboard/Dashboard')
});

const Profile = Loadable({
  loader: () => import('../../../src/pages/uiv3/profile/Profile')
});

const UserProfile = Loadable({
  loader: () => import('../../../src/pages/userProfile/UserProfileBkp.jsx')
});
const Ddx = Loadable({
  loader: () => import('../../../src/pages/askClirnet/DiagnosisHelp')
});
const DDI = Loadable({
  loader: () => import('../../../src/pages/ddi/DrugToDrugInteraction')
});
const AiDALanding = Loadable({
  loader: () => import('../../../src/pages/uiv3/aida/AiDALanding')
});
const MostReadContent = Loadable({
  loader: () => import('../../../src/pages/mostReadContent/MostReadContent')
});

const SpecialityLanding = Loadable({
  loader: () => import('../../../src/pages/uiv3/speciality/SpecialityLanding')
});

const AllSpecialities = Loadable({
  loader: () => import('../../../src/pages/uiv3/AllSpeciality')
});

const MostCommentedMedwikiListing = Loadable({
  loader: () => import('../../../src/pages/uiv3/medwikiV2/MostCommentedMedwikiListing')
});
const TagSearch = Loadable({
  loader: () => import('../../../src/pages/Search/TagSearch')
});

const Subscription = Loadable({
  loader: () => import('../../../src/pages/subscription/Subscription')
});

const SubscriptionOldLayout = Loadable({
  loader: () => import('../../../src/pages/subscription/SubscriptionOldLayout')
});

const ObservershipLanding = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/ObservershipLanding')
});

const ApplyToOpportunity = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/ApplyToOpportunity')
});

const ApplicantsListing = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/ApplicantsListing')
});

const ObservershipRegistration = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/ObservershipRegistration')
});

const ObservershipProfile = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/ObservershipProfile')
});

const PostOpportunity = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/PostOpportunity')
});

const ObservershipDetails = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/ObservershipDetails')
});
const ObservershpChat = Loadable({
  loader: () => import('../../../src/pages/uiv3/observership/ObservershpChat')
});
const AidaContainer = Loadable({
  loader: () => import('../../pages/aida')
});
const UnavailableV2 = Loadable({
  loader: () => import('../../../src/pages/unavailable/UnavailableV2')
});

export const newRoutes = [
  {
    layout: <Outlet />,
    SpecialityLayout: <Outlet />,
    subpath: [
      {
        path: routeNames.dashboard.newLogin,
        component: <NewLogin />,
        specialityComponent: <NewLogin />,
        module: 'generic'
      }
    ]
  },
  {
    layout: <DefaultLayout />,
    SpecialityLayout: <SpecialityLayoutOldPage />,
    subpath: [
      {
        path: routeNames.speciality.vault,
        component: <Vault />,
        module: 'speciality'
      },
      {
        path: routeNames.helpAndSupport.landing,
        component: <HelpAndSupport />,
        module: 'generic'
      },
      {
        path: routeNames.search.landing,
        component: <SearchResultV2 />,
        module: 'generic'
      },
      {
        path: routeNames.search.tag,
        component: <TagSearch />,
        module: 'generic'
      },
      {
        path: routeNames.search.listing,
        component: <SearchList />,
        module: 'generic'
      },

      {
        path: routeNames.certificate.upload,
        component: <UploadCertificate />,
        module: 'generic'
      },
      {
        path: routeNames.certificate.landing,
        component: <CertificatesListing />,
        module: 'generic'
      },
      {
        path: routeNames.cpdReview.landing,
        component: <CpdReview />,
        module: 'generic'
      },
      {
        path: routeNames.sessions.upcoming,
        paramPath: [':id'],
        component: <UpcomingSessionList />,
        module: 'generic'
      },
      {
        path: routeNames.sessions.previous,
        paramPath: [':id'],
        component: <PreviousSessionList />,
        module: 'generic'
      },
      {
        path: routeNames.sessions.queryListing,
        paramPath: [':id'],
        component: <QueryListing />,
        module: 'generic'
      },
      {
        path: routeNames.spq.question,
        paramPath: [':id', ':id/:title'],
        component: <SPQquestion />,
        module: 'spq'
      },
      {
        path: routeNames.spq.review,
        paramPath: [':id', ':id/:title'],
        component: <SPQreview />,
        module: 'spq'
      },
      {
        path: routeNames.spq.result,
        paramPath: [':id', ':id/:title'],
        component: <SPQsubmitSuccessfully />,
        module: 'spq'
      },
      {
        path: routeNames.spq.attempt,
        component: <SPQcompleted />,
        module: 'spq'
      },

      {
        path: routeNames.sessions.landing,
        component: <SessionListing />,
        module: 'session'
      },

      {
        path: routeNames.epaper.landing,
        component: <EbookListing />,
        module: 'epub'
      },

      {
        path: routeNames.grandRound.landing,
        component: <GrandRoundListing />,
        module: 'gr'
      },
      {
        path: routeNames.discussRefer.landing,
        component: <DiscussRefer />,
        module: 'dnr'
      },
      {
        path: routeNames.hospitalProfile.landing,
        paramPath: [':id', ':id/:title'],
        component: <HospitalProfile />,
        module: 'generic'
      },
      {
        path: routeNames.discussRefer.discussForm,
        paramPath: [':id', ':id/:title'],
        component: <DiscussForm />,
        module: 'dnr'
      },
      {
        path: routeNames.discussRefer.referForm,
        paramPath: [':id', ':id/:title'],
        component: <DiscussForm />,
        module: 'dnr'
      },
      {
        path: routeNames.speciality.activities,
        component: <Activities />,
        module: 'speciality'
      },
      {
        path: routeNames.discussRefer.referReinitiate,
        component: <ReInitiateRefer />,
        module: 'dnr'
      },
      {
        path: routeNames.discussRefer.referSubmit,
        component: <ReferThankYou />,
        module: 'dnr'
      },

      {
        path: routeNames.medwiki.landing,
        component: <MedwikiLandingV2 />,
        module: 'medwiki'
      },
      {
        path: routeNames.demo.landing,
        component: <Demo />,
        module: 'generic'
      },
      {
        path: routeNames.clinicalVideo.landing,
        component: <ClinicalVideoLanding />,
        module: 'clinicalVideo'
      },
      {
        path: routeNames.channel.landing,
        component: <ChannelLandingV2 />,
        module: 'channel'
      },
      {
        path: routeNames.channel.manage,
        component: <ChannelManage />,
        module: 'channel'
      },
      {
        path: routeNames.channel.suggestion,
        component: <ChannelSuggestion />,
        module: 'channel'
      },

      {
        path: routeNames.telemed.landing,
        component: <TelemedLite />,
        module: 'telemed'
      },
      {
        path: routeNames.spq.landing,
        component: <SPQlanding />,
        module: 'spq'
      },
      {
        path: routeNames.spq.points,
        component: <UserPoint />,
        module: 'generic'
      },
      {
        path: routeNames.election.landing,
        paramPath: [':electionId'],
        component: <ElectionLandinng />,
        module: 'election'
      },
      {
        path: routeNames.election.candidateSelection,
        paramPath: [':electionId', ':electionId/:positionId'],
        component: <CandidateSelection />,
        module: 'election'
      },
      {
        path: routeNames.election.voting,
        paramPath: [':electionId', ':electionId/:positionId'],
        component: <VotingSummary />,
        module: 'election'
      },
      {
        path: routeNames.verification.mobile,
        paramPath: [':electionId', ':electionId/:positionId'],
        component: <MobileVerification />,
        module: 'generic'
      },
      {
        path: routeNames.ads.landing,
        component: <AdsPlayer />,
        module: 'generic'
      },
      {
        path: routeNames.doctube.profileVideo,
        component: <ProfileVideos />,
        module: 'generic'
      },
      {
        path: routeNames.course.landing,
        component: <GRlanding />,
        module: 'courses'
      },

      {
        path: routeNames.course.summary,
        paramPath: [':id', ':id/:title'],
        component: <GRsummary />,
        module: 'courses'
      },

      {
        path: routeNames.doctube.landing,
        component: <DoctubeLanding />,
        module: 'doctube'
      },

      {
        path: routeNames.doctube.listing,
        component: <DoctubeListingVideos />,
        module: 'doctube'
      },
      {
        path: routeNames.doctube.profile,
        component: <ProfileSteps />,
        module: 'doctube'
      },
      {
        path: routeNames.doctube.dashboard,
        component: <DoctubeDashboard key={'doctube-dashboard'} />,
        module: 'doctube'
      },
      {
        path: routeNames.doctube.analytics,
        component: <DoctubeDashboard key={'doctube-analytics'} />,
        module: 'doctube'
      },
      {
        path: routeNames.doctube.upload,
        component: <DoctubeVideoUpload />,
        module: 'doctube'
      },
      {
        path: routeNames.doctube.playlistManage,
        component: <DoctubeManagePlaylist />,
        module: 'doctube'
      },
      {
        path: '/SPQ',
        component: <SPQ />,
        module: 'spq'
      }
    ]
  },
  {
    layout: <DefaultLayout />,
    SpecialityLayout: <SpecialityLayout />,
    subpath: [
      {
        path: routeNames.dashboard.landing,
        component: <Dashboard />,
        specialityComponent: <SpecialityDashboard />,
        module: 'generic'
      },
      {
        path: routeNames.userProfile.landing,
        component: <UserProfile />,
        specialityComponent: <Profile />,
        module: 'generic'
      },
      {
        path: routeNames.speciality.listing,
        component: <AllSpecialities />,
        module: 'speciality'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    SpecialityLayout: <SpecialityDetailsLayout />,
    subpath: [
      {
        path: routeNames.differentialDiagnosis.landing,
        paramPath: ['', ':id'],
        component: (
          <OldLayoutManager>
            <AidaContainer />
          </OldLayoutManager>
        ),
        module: 'aidda'
      },
      {
        path: routeNames.differentialDiagnosis.details,
        paramPath: ['', ':id'],
        component: (
          <OldLayoutManager>
            {/* <AidaHistoryShareDetails share={false} /> */}
            <AidaContainer />
          </OldLayoutManager>
        ),
        module: 'aidda'
      },
      {
        path: routeNames.course.content,
        paramPath: [':id', ':id/:title', ':id/:title/:module_active'],
        component: <GRcontent />,
        module: 'courses'
      },
      // {
      //   path: routeNames.sessions.speaker,
      //   paramPath: [':id', ':id/:title', ':id/:title/:active'],
      //   component: <SpeakerProfile />,
      //   module: 'generic'
      // },

      {
        path: routeNames.certificate.detail,
        paramPath: [':id', ':id/:title'],
        component: <CertificateDetails />,
        module: 'generic'
      },
      {
        path: routeNames.spq.detail,
        paramPath: [':id', ':id/:title'],
        component: <SPQdetails />,
        module: 'spq'
      },
      {
        path: routeNames.spq.poll,
        paramPath: [':id', ':id/:title'],
        component: <PollDetails />,
        module: 'spq'
      },
      // {
      //   path: '/SessionDetails',
      //   component: <SessionDetails />
      // },
      {
        path: routeNames.sessions.detail,
        paramPath: [':id', ':id/:title'],
        component: <SessionDetailsOutlet />,
        module: 'session'
      },
      // {
      //   path: routeNames.clinicalVideo.detail,
      //   paramPath: [':id', ':id/:title'],
      //   component: <ClinicalVideoDetails />,
      //   module: 'clinicalVideo'
      // },
      {
        path: routeNames.sessions.live,
        paramPath: [':id', ':id/:title'],
        component: <LiveSessionOutlet />,
        module: 'session'
      },
      // {
      //   path: '/SessionWaitingroom',
      //   component: <SessionWaitingroomOutlet />,
      //   module: 'session'
      // },
      {
        path: routeNames.sessions.photoBooth,
        component: <Photobooth />,
        module: 'session'
      },
      {
        path: routeNames.discussRefer.caseDetail,
        component: <CaseDetails />,
        module: 'generic'
      },
      {
        path: routeNames.grandRound.detail,
        paramPath: [':id', ':id/:title'],
        component: <GrandRoundDetails />,
        module: 'gr'
      },

      {
        path: routeNames.doctube.playlist,
        paramPath: [':id', ':id/:title'],
        component: <DoctubePlaylistDetails />,
        module: 'doctube'
      },

      {
        path: routeNames.course.cover,
        paramPath: [':id', ':id/:title'],
        component: <GRcover />,
        module: 'gr'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    SpecialityLayout: <SpecialityDefaultLayout />,
    subpath: [
      {
        path: routeNames.observership.landing,

        specialityComponent: <ObservershipLanding />,
        component: (
          <OldLayoutManager>
            <ObservershipLanding />
          </OldLayoutManager>
        ),
        module: 'observership'
      },
      {
        path: routeNames.observership.registration,
        specialityComponent: <ObservershipRegistration />,
        component: (
          <OldLayoutManager>
            <ObservershipRegistration />
          </OldLayoutManager>
        ),
        module: 'observership'
      },
      {
        path: routeNames.observership.profile,
        paramPath: [':id', ':id/:title'],
        specialityComponent: <ObservershipProfile />,
        component: (
          <OldLayoutManager>
            <ObservershipProfile />
          </OldLayoutManager>
        ),
        module: 'observership'
      },
      {
        path: routeNames.sessions.speaker,
        paramPath: [':id', ':id/:title', ':id/:title/:active'],
        specialityComponent: <ObservershipProfile />,
        component: (
          <OldLayoutManager>
            <ObservershipProfile />
          </OldLayoutManager>
        ),
        module: 'session'
      },
      {
        path: routeNames.observership.postOpportunity,
        paramPath: ['', ':id'],
        specialityComponent: <PostOpportunity />,
        component: (
          <OldLayoutManager>
            <PostOpportunity />
          </OldLayoutManager>
        ),
        module: 'observership'
      },
      {
        path: routeNames.observership.observershipDetails,
        paramPath: [':id', ':id/:title'],
        specialityComponent: <ObservershipDetails />,
        component: (
          <OldLayoutManager>
            <ObservershipDetails />
          </OldLayoutManager>
        ),
        module: 'observership'
      },
      {
        path: routeNames.observership.observershipDetailsPreview,
        paramPath: [':id', ':id/:title'],
        specialityComponent: <ObservershipDetails type="preview" />,
        component: (
          <OldLayoutManager>
            <ObservershipDetails type="preview" />
          </OldLayoutManager>
        ),
        module: 'observership'
      },
      {
        path: routeNames.observership.observershpChat,
        paramPath: ['', ':id', ':id/:title'],
        specialityComponent: <ObservershpChat />,
        component: (
          <OldLayoutManager>
            <ObservershpChat />
          </OldLayoutManager>
        ),
        module: 'observership'
      },
      {
        path: routeNames.medwiki.detail,
        paramPath: [':id', ':id/:title'],
        component: <MedwikiDetailsV2 />,
        specialityComponent: <MedwikiDetails />,
        module: 'medwiki'
      },
      {
        path: routeNames.notification.landing,
        component: <Notification />,
        module: 'generic'
      },
      {
        path: routeNames.clinicalVideo.detail,
        paramPath: [':id', ':id/:title'],
        specialityComponent: <ClinicalVideoDetailsV2 />,
        component: <ClinicalVideoDetails />,
        module: 'clinicalVideo'
      },
      {
        path: routeNames.channel.detail,
        paramPath: [':id', ':id/:title', ':/id/:title/:auto_subscription'],
        specialityComponent: <ChannelProfileV2 isSpecialityLayout />,
        component: (
          <OldLayoutManager>
            <ChannelProfileV2 />
          </OldLayoutManager>
        ),
        module: 'channel'
      },
      {
        path: routeNames.epaper.detail,
        paramPath: [':id', ':id/:title'],
        component: (
          <OldLayoutManager>
            <EpaperDetails />
          </OldLayoutManager>
        ),
        specialityComponent: <EpaperDetails />,
        module: 'epub'
      },
      {
        path: routeNames.subscription.pricing,
        paramPath: [
          ':redirectService',
          ':redirectService/:redirectServiceId',
          ':redirectService/:redirectServiceId/:redirectServiceTitle',
          ':redirectService/:redirectServiceId/:redirectServiceTitle/:extra'
        ],
        component: <SubscriptionOldLayout />,
        specialityComponent: <Subscription />,
        module: 'generic'
      },
      {
        path: routeNames.observership.applyForObserverShip,
        paramPath: [':id', ':id/:title'],
        specialityComponent: <ApplyToOpportunity />,
        component: (
          <OldLayoutManager>
            <ApplyToOpportunity />
          </OldLayoutManager>
        ),
        module: 'generic'
      },
      {
        path: routeNames.observership.applicantsListing,
        paramPath: [':id', ':id/:title'],
        specialityComponent: <ApplicantsListing />,
        component: (
          <OldLayoutManager>
            <ApplicantsListing />
          </OldLayoutManager>
        ),
        module: 'generic'
      },
      {
        path: routeNames.observership.applicationDetails,
        paramPath: [':id', ':id/:title'],
        specialityComponent: <ApplicationDetails />,
        component: (
          <OldLayoutManager>
            <ApplicationDetails />
          </OldLayoutManager>
        ),
        module: 'generic'
      },
      {
        path: routeNames.ddi.landing,
        paramPath: ['', ':id'],
        component: <DDI />,
        module: 'aidda'
      },
      {
        path: routeNames.openddx.landing,
        paramPath: ['', ':id'],
        component: (
          <OldLayoutManager>
            <AidaContainer />
          </OldLayoutManager>
        ),
        redirectTypeRoute: routeNames.openddx.landing
      },
      {
        path: routeNames.aida.landing,
        component: <AiDALanding />,
        module: 'aidda'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    SpecialityLayout: <SpecialityDefaultLayout isNavOnlyHeader={true} />,
    subpath: [
      {
        path: routeNames.course.listing,
        // component: <GRlisting />,
        component: <AllListing type="training" showVideoFilter={false} module_name={'training'} />,
        module: 'courses'
      },
      {
        path: routeNames.medwiki.topRated,
        component: <AllListing subType="topratedmedwiki" module_name={'medwiki'} />,
        // component: <TopRatedMedwikiListing />,
        module: 'medwiki'
      },
      {
        path: routeNames.clinicalVideo.listing,
        // component: <ClinicalVideoList  />,
        component: (
          <AllListing type="archived_video" showVideoFilter={false} module_name={'clinical'} />
        ),
        module: 'clinicalVideo'
      },
      {
        path: routeNames.medwiki.popular,
        // component: <MedWikiListing />,
        component: <AllListing type="popular" module_name={'medwiki'} />,
        module: 'medwiki'
      },
      {
        path: routeNames.medwiki.mostCommented,
        // component: <MostCommentedMedwikiListing />,
        component: <AllListing subType="topcommentedmedwiki" module_name={'medwiki'} />,
        module: 'medwiki'
      },
      {
        path: routeNames.medwiki.listing,
        // component: <MedWikiListing />,
        component: <AllListing type="comp" module_name={'medwiki'} />,
        module: 'medwiki'
      },
      {
        path: routeNames.sessions.listing,
        // component: <AllSessionList />,
        component: <AllListing showVideoFilter={false} module_name={'session'} />,
        module: 'session'
      },
      {
        path: routeNames.spq.listing,
        // component: <SPQ />,
        component: <AllListing type="spq" showVideoFilter={false} module_name={'spq'} />,
        paramPath: ['', ':type'],
        module: 'spq'
      },
      {
        path: routeNames.medwiki.mostRead,
        // component: <MostReadContent />,
        component: <AllListing type="mostRead" module_name={'medwiki'} />,
        // specialityComponent: <MostReadContent />,
        module: 'speciality'
      },
      {
        path: routeNames.speciality.landing,
        paramPath: [':id', ':id/:title'],
        // component: <SpecialityLanding />,
        component: (
          <AllListing
            type="speciality"
            isFilter={false}
            showVideoFilter={false}
            module_name={'speciality'}
          />
        ),
        module: 'speciality'
      }
    ]
  }
];
