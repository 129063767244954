import React, { useState, useEffect, memo } from 'react';
import { toast } from 'react-toastify';
import { isMobileApp } from '../common/common';
import { Share } from '@capacitor/share';
import { useTracker } from '../TrackerFolder/TrackerComponent';
import { SHARE_CLICK } from './tracking/constants';

function ShareButton({
  apiData,
  data,
  customClass,
  click,
  buttonText = '',
  svgClass = '',
  id = '',
  gtmTagVariable = ''
}) {
  const { is_share = true } = data;
  const [shareData, setShareData] = useState({});
  // const [extraCustomClass, setextraCustomClass] = useState(null);
  const { trackActivityEvent } = useTracker();
  useEffect(() => {
    let tempData = {};
    tempData = {
      title: data.title,
      // text: data.text,
      url: data.url
    };
    setShareData(tempData);

    // if (customClass) {
    //   setextraCustomClass(customClass);
    // }
  }, [data]);

  const share_url = async () => {
    if (isMobileApp()) {
      await Share.share(shareData);
    } else {
      console.log(shareData);
      if (navigator.canShare) {
        try {
          await navigator.share(shareData);
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.info('Copied to clipboard');
        copy_to_Clipboard(shareData.url);
      }
    }
  };
  const copy_to_Clipboard = (str) => {
    let textarea;
    let result;
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = str;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, str); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }

    return true;
  };
  // className={extraCustomClass} //extra className

  const isShareable = typeof is_share === 'boolean' ? is_share : is_share == '1' ? true : false;

  if (!isShareable) return null;
  return (
    // <li
    //   className="cursorPointer clr_v3_contentActionIcons border-soft-primary list-unstyled"
    //   onClick={() => {
    //     share_url();
    //     click && click();
    //   }}
    // >
    //   <span className="icon-size-32 ripple-primary rippleEffect position-relative fs-3">
    //     <button
    //       // cmnBtn fw-semibold bg-primary rippleEffect rounded
    //       className={`${customClass} ${gtmTagVariable} d-flex align-items-center`}

    //       // id={`share_button_${id}`}
    //     >
    //       <i
    //         className={`flaticon-share-arrow ${svgClass ? svgClass : 'fs-3 me-1'} ${gtmTagVariable}`}
    //       ></i>
    //       {buttonText}
    //     </button>
    //   </span>
    // </li>
    <a
      onClick={() => {
        trackActivityEvent(SHARE_CLICK, apiData);
        share_url();
        if (click) {
          click();
        }
      }}
      role="button" // cmnBtn fw-semibold bg-primary rippleEffect rounded
      className={`${customClass} ${gtmTagVariable} d-flex align-items-center`} // id={`share_button_${id}`}
    >
      <i
        className={`flaticon-share-arrow ${svgClass ? svgClass : 'fs-3 me-1'} ${gtmTagVariable}`}
      ></i>
      {buttonText}
    </a>
  );
}
export default memo(ShareButton);
