// import Color, { Palette } from 'color-thief-react';
import dayjs from 'dayjs';
import React, { memo } from 'react';
import { getSessionCategoryIcon, reactHtmlParser, timeDifference } from '../../../common/common';
import DoctorsSectionForCard from '../../../components/DoctorSectionForCard/DoctorsSectionForCard';
import CountDown from './CountDown';
// import ReactHtmlParser from 'react-html-parser';
import SponsorLogoSwiper from './SponsorLogoSwiper';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { useDispatch } from 'react-redux';
import { toggleReference } from '../../../Store/actions';
import { removeHtmlTags } from '../../channel/utils';
const SessionHeroCard = ({ data, channel_name, channel_logo, gtmTagVariable = '' }) => {
  const {
    cover_image,
    ms_cat_name,
    session_topic,
    session_description,
    sponsor_entities,
    session_status,
    session_doctor_entities,
    start_datetime,
    end_datetime,
    session_id
  } = data;

  let isLiveStart =
    start_datetime && end_datetime
      ? timeDifference(start_datetime) >= 0 && timeDifference(end_datetime) <= 0
      : false;
  const diff = dayjs(start_datetime).diff(dayjs(new Date()));

  const dispatch = useDispatch();

  function onReadMoreClick() {
    dispatch(toggleReference(true));
  }

  console.log('data session', start_datetime, end_datetime, diff, isLiveStart);
  const DEMO_SESSION_IMAGE =
    'https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400086/55848266-modern-thin-line-design-concept-for-healthcare-website-banner-vector-illustration-concept-for-health.jpg';

  return (
    <div className="w-100 float-start rounded-3 shadow overflow-hidden bg-white shareLoginV2Featured shareLoginV2Session p-3 p-md-4 position-relative z-1">
      <div className="shareLoginV2FeaturedIn d-flex flex-column flex-sm-row align-items-center">
        <div
          className="shareLoginV2FeaturedCircle position-absolute rounded-circle"
          style={{ backgroundColor: '#355fd3' }}
        ></div>
        <div className="shareLoginV2FeaturedPic position-relative h-auto pe-4 pb-4">
          {cover_image && (
            <div className="shareLoginV2FeaturedPicIn rounded-3 position-relative overflow-hidden z-1">
              <LazyImage
                src={cover_image}
                className="object-fit-cover w-100 h-100 float-start"
                fallback_image={DEMO_SESSION_IMAGE}
              />
              {session_status == 6 ? (
                <div className="overlay d-flex justify-content-center align-items-end pb-3  z-2">
                  <div class="w-100 text-center sessionCancelPospndIn d-inline-block bg-white bg-opacity-50 py-3 px-4">
                    <h4 class=" text-black fw-semibold text-uppercase fs-16">Session Cancelled</h4>
                  </div>
                </div>
              ) : session_status == 5 ? (
                <div className="overlay d-flex justify-content-center align-items-end pb-3  z-2">
                  <div class="w-100 text-center sessionCancelPospndIn d-inline-block bg-white bg-opacity-50 py-3 px-4">
                    <h4 class=" text-black fw-semibold text-uppercase fs-16">Session Postponed</h4>
                  </div>
                </div>
              ) : session_status == 3 ? (
                <div className="overlay d-flex justify-content-center align-items-end pb-3  z-2">
                  <div class="w-100 text-center sessionCancelPospndIn d-inline-block bg-white bg-opacity-50 py-3 px-4">
                    <h4 class=" text-black fw-semibold text-uppercase fs-16">Session Closed</h4>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <div className="shareLoginV2FeaturedRight text-start ps-0 ps-md-20 mt-4 mt-md-0">
          {((start_datetime && session_status != 4 && !isLiveStart && diff > 0) || isLiveStart) && (
            <div className="d-flex align-items-center justify-content-between mb-5">
              {start_datetime && session_status != 4 && !isLiveStart && diff > 0 ? (
                <CountDown date={start_datetime} />
              ) : (
                <div> </div>
              )}

              {isLiveStart ? (
                <span className="text-danger fs-16 fw-semibold d-inline-flex align-items-center gap-2">
                  <div className="pulseCircle"></div> Live
                </span>
              ) : (
                <></>
              )}
            </div>
          )}

          <h5 className="fs-4 fw-normal text-black d-block mb-2 d-flex">
            <i className={'text-primary fs-3 me-3 ' + getSessionCategoryIcon(ms_cat_name)}></i>
            {ms_cat_name}
          </h5>
          <h2 className="fs-16 fw-medium text-black mb-3">{session_topic}</h2>

          {session_description && (
            <h4 className="line-clamp-2 fs-4 fw-normal text-dark">
              {removeHtmlTags(session_description)?.substring(0, 180)}
              {removeHtmlTags(session_description)?.length > 180 && (
                <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                  {' '}
                  ...Read More
                </a>
              )}
            </h4>
          )}

          <div className="d-flex justify-content-between mt-4 gap-3">
            <div className="shareLogineV2Doctor">
              <DoctorsSectionForCard
                cardData={{ session_doctor_entities }}
                type="session"
                id={session_id}
              />
              {channel_logo && (
                <div
                  className={`w-100 float-start shareLoginV2FeaturedChannel d-flex align-items-center position-relative ${
                    session_doctor_entities?.length > 0 ? 'mt-4' : ''
                  }`}
                >
                  <div className="rounded-circle shareLoginV2ChanlPic d-flex align-items-center justify-content-center bg-light position-absolute top-0 start-0">
                    <LazyImage
                      src={channel_logo}
                      alt="Profile Image"
                      className="mw-100 mh-100 w-auto h-auto"
                      logo
                    />{' '}
                  </div>
                  <span className="d-block fs-5 text-dark">{channel_name}</span>
                </div>
              )}
            </div>
            {sponsor_entities && Array.isArray(sponsor_entities)
              ? sponsor_entities?.length > 0 && (
                  <SponsorLogoSwiper sponsorLogo={sponsor_entities?.map((_s) => _s.sponsor_logo)} />
                )
              : sponsor_entities
              ? Object.keys(sponsor_entities)?.length > 0 && (
                  <SponsorLogoSwiper sponsorLogo={[sponsor_entities.sponsor_logo]} />
                )
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SessionHeroCard);
