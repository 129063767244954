import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getLocalStorage } from '../../common/common';
import { ADD_BANNER_REPORT } from './apis/banner.apis';

const WS_URL = `wss://${ADD_BANNER_REPORT.replace('https://', '')}`;
const MAX_RETRIES = 5;
const DB_NAME = 'WebSocketQueueDB';
const STORE_NAME = 'messages';
const MAX_QUEUE_SIZE = 1000;

const WebSocketContext = createContext(null);

// IndexedDB setup and helper functions
const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'timestamp' });
      }
    };
  });
};

const loadQueueFromStorage = async () => {
  try {
    const db = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.getAll();

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error loading queue from IndexedDB:', error);
    return [];
  }
};

const saveQueueToStorage = async (queue) => {
  try {
    const db = await initDB();
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    // Clear existing store
    await new Promise((resolve, reject) => {
      const clearRequest = store.clear();
      clearRequest.onsuccess = resolve;
      clearRequest.onerror = reject;
    });

    // If queue is too large, truncate it
    const truncatedQueue =
      queue.length > MAX_QUEUE_SIZE ? queue.slice(-Math.floor(MAX_QUEUE_SIZE / 2)) : queue;

    // Add all messages
    for (const message of truncatedQueue) {
      store.add(message);
    }

    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => resolve(truncatedQueue);
      transaction.onerror = () => reject(transaction.error);
    });
  } catch (error) {
    console.error('Error saving queue to IndexedDB:', error);
    return queue;
  }
};

export const BannerWebsocketConfig = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const websocketRef = useRef(null);
  const retryCountRef = useRef(0);
  const queueRef = useRef([]);
  const reconnectTimeoutRef = useRef(null);

  // Initialize queueRef with data from IndexedDB
  useEffect(() => {
    loadQueueFromStorage().then((queue) => {
      queueRef.current = queue;
    });
  }, []);

  const enqueueMessage = useCallback(async (message) => {
    const queue = queueRef.current;
    if (queue.length >= MAX_QUEUE_SIZE) {
      queue.splice(0, queue.length - Math.floor(MAX_QUEUE_SIZE / 2));
    }

    const enrichedMessage = {
      ...message,
      timestamp: Date.now(),
      retryCount: 0
    };

    const updatedQueue = [...queue, enrichedMessage];
    queueRef.current = await saveQueueToStorage(updatedQueue);
  }, []);

  const dequeueMessage = useCallback(async () => {
    const queue = queueRef.current;
    if (queue.length === 0) return null;

    const [message, ...remaining] = queue;
    queueRef.current = await saveQueueToStorage(remaining);
    return message;
  }, []);

  const processQueue = useCallback(async () => {
    if (isProcessingQueue || !isConnected || queueRef.current.length === 0) return;

    setIsProcessingQueue(true);

    while (queueRef.current.length > 0 && isConnected) {
      const message = queueRef.current[0];

      try {
        if (websocketRef.current?.readyState === WebSocket.OPEN) {
          websocketRef.current.send(JSON.stringify(message));
          await dequeueMessage();
        } else {
          break;
        }
      } catch (error) {
        console.error('Error sending queued message:', error);
        const updatedMessage = { ...message, retryCount: (message.retryCount || 0) + 1 };

        if (updatedMessage.retryCount >= 3) {
          await dequeueMessage();
        } else {
          const updatedQueue = [updatedMessage, ...queueRef.current.slice(1)];
          queueRef.current = await saveQueueToStorage(updatedQueue);
        }
        break;
      }
    }

    setIsProcessingQueue(false);
  }, [isConnected, isProcessingQueue, dequeueMessage]);

  const connect = useCallback(() => {
    if (websocketRef.current?.readyState === WebSocket.OPEN) return;

    // Clear any existing connection and timeout
    if (websocketRef.current) {
      websocketRef.current.close();
    }
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    websocketRef.current = new WebSocket(WS_URL);

    websocketRef.current.onopen = () => {
      console.log('WebSocket connected');
      setIsConnected(true);
      retryCountRef.current = 0;
      processQueue();
    };

    websocketRef.current.onclose = (event) => {
      console.log('WebSocket disconnected', event.reason);
      setIsConnected(false);

      // Only attempt to reconnect if we haven't reached max retries
      if (retryCountRef.current < MAX_RETRIES) {
        retryCountRef.current++;
        const backoffTime = Math.min(1000 * Math.pow(2, retryCountRef.current), 30000);
        console.log(`Attempting to reconnect... (Attempt ${retryCountRef.current})`);
        reconnectTimeoutRef.current = setTimeout(connect, backoffTime);
      } else {
        console.error('Max retries reached. WebSocket connection failed.');
      }
    };

    websocketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    websocketRef.current.onmessage = async (event) => {
      const message = JSON.parse(event.data);
      // Handle incoming messages here
    };
  }, [processQueue]);

  useEffect(() => {
    connect();

    const handleOnline = () => {
      console.log('Network connection restored');
      retryCountRef.current = 0; // Reset retry count when network comes back
      connect();
    };

    const handleOffline = () => {
      console.log('Network connection lost');
      setIsConnected(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []); // Remove processQueue from dependency array

  const sendMessage = useCallback(
    async (type, data) => {
      const message = {
        type,
        data,
        token: getLocalStorage('accessToken', '') || null
      };

      if (websocketRef.current?.readyState === WebSocket.OPEN) {
        try {
          websocketRef.current.send(JSON.stringify(message));
        } catch (error) {
          console.error('Error sending message:', error);
          await enqueueMessage(message);
        }
      } else {
        console.log('WebSocket is not open. Message added to queue.');
        await enqueueMessage(message);
      }
    },
    [enqueueMessage]
  );

  const getQueueStatus = useCallback(
    () => ({
      queueSize: queueRef.current.length,
      isProcessing: isProcessingQueue,
      oldestMessage: queueRef.current[0]?.timestamp,
      newestMessage: queueRef.current[queueRef.current.length - 1]?.timestamp
    }),
    [isProcessingQueue]
  );

  return (
    <WebSocketContext.Provider
      value={{
        isConnected,
        sendMessage,
        getQueueStatus
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useBannerWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === null) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
