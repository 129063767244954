import React, { memo, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMarquee } from '../../Store/actions/dashboard.action';
import { getLocalStorage, isIosApp, isMobileApp, removeLocalStorage } from '../../common/common';
import { GrClose } from '../../components/icons';
import { routeNames } from '../../router/constants';
import useGetParams from '../customHooks/useGetParams';
import useRedirect from '../customHooks/useRedirect';
import './css/headerMobileDetails.scss';
import logoTop from './images/logoTop.png';
import LoaderLine from '../LoaderLine';
function HeaderMobileDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectTo } = useRedirect();
  const { id, title } = useGetParams();
  const dispatch = useDispatch();
  const isFromNotification = getLocalStorage('autoAuthFromNotification', false);
  const isFromAutoAuth = getLocalStorage('autoAuthFromAppUrlListener', false);
  const current_menu = useSelector((state) => state.common.current_menu);
  // const marqueeText = useSelector((state) => state.dashboard.marquee);
  const [marqueeText, setMarqueeText] = useState([]);
  useEffect(() => {
    console.log('HEADER_DETAILS_LOCATION', location);
    dispatch(
      getMarquee((res) => {
        if (res != 'error') {
          setMarqueeText(res?.data);
        }
      })
    );
  }, []);
  const redirectUrl = useSelector((state) => state.session.redirectUrl);
  function onExitClick() {
    if (redirectUrl?.startsWith(window.location.origin)) {
      let replacedNavigation = redirectUrl.replace(window.location.origin + '/#', '');
      redirectTo(replacedNavigation);
    } else {
      window.location.href = redirectUrl;
    }
  }

  const cancelClick = () => {
    if (redirectUrl) {
      onExitClick();
      return;
    }

    if (isMobileApp()) {
      if (isFromNotification) {
        navigate('/Notification');
        removeLocalStorage('autoAuthFromNotification');
      } else if (isFromAutoAuth) {
        navigate(routeNames.dashboard.landing);
        removeLocalStorage('autoAuthFromAppUrlListener');
      } else {
        if (location?.state?.from_login) {
          if (current_menu?.toLowerCase()?.includes('session')) {
            navigate(routeNames.sessions.landing);
          } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
            navigate(routeNames.medwiki.landing);
          } else if (current_menu?.toLowerCase()?.includes('spq')) {
            navigate(routeNames.spq.landing);
          } else if (current_menu?.toLowerCase()?.includes('clvideo')) {
            navigate(routeNames.clinicalVideo.landing);
          } else if (current_menu?.toLowerCase()?.includes('gr')) {
            navigate(routeNames.grandRound.landing);
          } else if (
            current_menu?.toLowerCase()?.includes('ebook') ||
            current_menu?.toLowerCase()?.includes('epaper')
          ) {
            navigate(routeNames.epaper.landing);
          } else if (current_menu?.toLowerCase()?.includes('channel')) {
            navigate(routeNames.channel.landing);
          } else if (current_menu?.toLowerCase()?.includes('dnr')) {
            navigate(routeNames.discussRefer.landing);
          } else if (current_menu?.toLowerCase()?.includes('certificate')) {
            navigate(routeNames.certificate.landing);
          } else if (
            current_menu?.toLowerCase()?.includes('training') ||
            current_menu?.toLowerCase()?.includes('course')
          ) {
            navigate(routeNames.course.landing);
          } else {
            navigate(routeNames.dashboard.landing);
          }
        } else {
          if (location.pathname?.toLowerCase().includes('livesession')) {
            redirectTo(routeNames.sessions.detail, id, title);
          } else if (current_menu?.toLowerCase()?.includes('session')) {
            navigate(routeNames.sessions.landing);
          } else {
            navigate(-1);
          }
        }
      }
    } else if (location?.state?.from_login) {
      if (current_menu?.toLowerCase()?.includes('session')) {
        navigate(routeNames.sessions.landing);
      } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
        navigate(routeNames.medwiki.landing);
      } else if (current_menu?.toLowerCase()?.includes('spq')) {
        navigate(routeNames.spq.landing);
      } else if (current_menu?.toLowerCase()?.includes('clvideo')) {
        navigate(routeNames.clinicalVideo.landing);
      } else if (current_menu?.toLowerCase()?.includes('gr')) {
        navigate(routeNames.grandRound.landing);
      } else if (current_menu?.toLowerCase()?.includes('ebook')) {
        navigate(routeNames.epaper.landing);
      } else if (current_menu?.toLowerCase()?.includes('channel')) {
        navigate(routeNames.channel.landing);
      } else if (current_menu?.toLowerCase()?.includes('dnr')) {
        navigate(routeNames.discussRefer.landing);
      } else if (current_menu?.toLowerCase()?.includes('certificate')) {
        navigate(routeNames.certificate.landing);
      } else if (current_menu?.toLowerCase()?.includes('training')) {
        navigate(routeNames.course.landing);
      } else {
        navigate(routeNames.dashboard.landing);
      }
    } else {
      if (location.pathname?.toLowerCase().includes('livesession')) {
        redirectTo(routeNames.sessions.detail, id, title);
      } else if (current_menu?.toLowerCase()?.includes('session')) {
        navigate(routeNames.sessions.landing);
      } else {
        navigate(-1);
      }
    }
  };

  const HandleAppReload = () => {
    window.location.reload();
  };

  return (
    <Navbar fixed="top" className="mblHeaderDetails flex-column p-0">
      <LoaderLine />
      {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100 bg-light"></div> : <></>}
      {marqueeText?.length > 0 && (
        <div className="bg-black text-white fst-italic fw-semibold fs-4 w-100 text-nowrap lh-lg mblMarquee">
          <Marquee>
            {marqueeText?.map((_m) => (
              <span className="me-5" key={_m.id}>
                {_m.text}
              </span>
            ))}
          </Marquee>
        </div>
      )}
      <div className="mblHeaderDetailsMain w-100 bg-white shadow d-flex justify-content-between align-items-center">
        <div
          className="mblDtlsNavLeft gtm_cl_header_clirnet_logo"
          onClick={() => navigate(routeNames.dashboard.landing)}
        >
          <img
            src={logoTop}
            alt="top Logo"
            id="header_mobileDetails_clirnet_logo"
            className="float-start gtm_cl_header_clirnet_logo"
          />
        </div>
        <div className="d-flex align-items-center ms-auto gap-4">
          {isMobileApp() && (
            <a
              href="javascript:void(0)"
              className="bg-light ratio-1 rounded-circle p-2 text-black me-4"
              style={{ '--bs-bg-opacity': '.9' }}
              onClick={HandleAppReload}
            >
              <i className="flaticon-undo fs-30"></i>
            </a>
          )}
          <div className="mblDtlsNavRight" onClick={cancelClick}>
            <GrClose customClass="text-black fs-30" id={'header_mobileDetails_close'} />
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default memo(HeaderMobileDetails);
