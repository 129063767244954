import React, { memo } from 'react';
import '../../../../assets/sass/uiV3/pages/share/_ddiShare.scss';
import MobileScreen from '../../../../assets/images/share/ddi-mobile.png';
function DdiIntro() {
  return (
    <>
      <div className="rounded-3 overflow-hidden bg-primary position-relative z-1 px-20 px-md-0 d-flex flex-md-row flex-column ddiShareIntro mb-md-20 mb-4">
        <svg
          className="position-absolute z-0 ddiShareIntro__bgImg"
          width="242"
          height="242"
          viewBox="0 0 242 242"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="121" cy="121" r="121" fill="white" fill-opacity="0.25" />
          <circle cx="121" cy="121" r="92.0282" fill="#F8F8F8" fill-opacity="0.1" />
          <circle cx="121" cy="121" r="64.7606" fill="#13388C" fill-opacity="0.25" />
        </svg>
        <div className="d-flex flex-column flex-shrink-0 gap-2 py-md-4 py-20 px-md-5 justify-content-center text-white ddiShareIntro__content text-md-start text-center">
          <h1 className="fs-30 text-white fw-semibold">Drug to Drug Interaction </h1>
          <p>
            Discover DDI with CLIRNET - your compass for Drug to Drug Interaction insights. In the
            healthcare landscape, where medications intertwine.
          </p>
        </div>
        <div className="d-flex align-items-end pt-md-70 justify-content-center ddiShareIntro__imageWrapper">
          <div className="ddiShareIntro__image">
            <img src={MobileScreen} alt="mobile view" className="w-100 h-auto" />
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(DdiIntro);
