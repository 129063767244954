import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getLocalStorage, removeSessionStorage } from '../common/common';

const WS_URL = `wss://${process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT.replace(
  'https://',
  ''
)}`; // Replace with your WebSocket server URL
const MAX_RETRIES = 5;

let websocket = null;
let retryCount = 0;

const TrackingWebSocketContext = createContext(null);

export const TrackerWebsocketConfig = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);

  const connect = useCallback(() => {
    if (websocket && websocket.readyState === WebSocket.OPEN) return;

    websocket = new WebSocket(WS_URL);

    websocket.onopen = () => {
      console.log('WebSocket connected');
      setIsConnected(true);
      retryCount = 0; // Reset retry count on successful connection
    };

    websocket.onclose = (event) => {
      console.log('WebSocket disconnected', event.reason);
      setIsConnected(false);

      if (retryCount < MAX_RETRIES) {
        retryCount++;
        console.log(`Attempting to reconnect... (Attempt ${retryCount})`);
        setTimeout(connect, 5000); // Retry after 5 seconds
      } else {
        console.error('Max retries reached. WebSocket connection failed.');
      }
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    websocket.onmessage = async (event) => {
      const message = JSON.parse(event.data);
      // Handle incoming messages here
    };
  }, []);

  useEffect(() => {
    connect(); // Initial connection

    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, [connect]);

  const sendMessage = useCallback((type, data) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      if (Array.isArray(data?.activity_events) && data?.activity_events.length > 0) {
        websocket.send(
          JSON.stringify({
            type,
            data,
            token: getLocalStorage('accessToken', '') ? getLocalStorage('accessToken', '') : null
          })
        );
      }
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  }, []);

  return (
    <TrackingWebSocketContext.Provider value={{ isConnected, sendMessage }}>
      {children}
    </TrackingWebSocketContext.Provider>
  );
};

export const useTrackingWebSocket = () => {
  const context = useContext(TrackingWebSocketContext);
  if (context === null) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
