import React, { useEffect, useRef, useState } from 'react';
import { FloatingLabel, Form, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFcmTokenByUserToken, toggleReference } from '../../../Store/actions';
import { followSwitching, getChannelDetails } from '../../../Store/actions/channelActions';
import {
  getSharePageQuestions,
  postResponseByToken
} from '../../../Store/actions/customizedShare.action';
import {
  authenticateUser,
  checkEmail,
  loginSuccess,
  loginWithEmail,
  otpVerification,
  postResetPasswordRequest,
  updatePassword,
  validateSecretKey,
  verifyTheOtp
} from '../../../Store/actions/login.action';
import {
  getAllSpecialities,
  newsignUpEmail,
  signUpEmail
} from '../../../Store/actions/registrationAction';
import { getVaultList } from '../../../Store/actions/vault.action';
import {
  getLocalStorage,
  log,
  removeSessionStorage,
  setLocalStorage,
  stringToArray
} from '../../../common/common';
import { firebaseUpload } from '../../../common/firebase';
import BackdropComponent from '../../../components/BackdropComponent';
import ISDPicker from '../../../components/ISDPicker';
import useGetParams from '../../../components/customHooks/useGetParams';
import { PaginationLoader } from '../../../components/loader';
import { routeNames } from '../../../router/constants';
import other from '../../registration/assets/OtherParamedics.svg';
import doctor from '../../registration/assets/doctor.svg';
import nurse from '../../registration/assets/nurse.svg';
import pharmacist from '../../registration/assets/pharmacist.svg';
import AppBanner from '../../share/components/AppBanner';
import Checkbox from '../FormComponents/Checkbox';
import Dropdown from '../FormComponents/Dropdown';
import FileUpload from '../FormComponents/FileUpload';
import MobileNumber from '../FormComponents/MobileNumber';
import TextArea from '../FormComponents/TextArea';
import TextField from '../FormComponents/TextField';
import { returnLabelName } from './demoJson';
const loginTypes = {
  LOGIN_WITH_MOBILE: 'LOGIN_WITH_MOBILE',
  LOGIN_WITH_EMAIL: 'LOGIN_WITH_EMAIL',
  ENTER_OTP: 'ENTER_OTP',
  REGISTRATION: 'REGISTRATION',
  FORGET_PASSWORD: 'FORGET_PASSWORD',
  RESET_PASSWORD_TRIGGER: 'RESET_PASSWORD_TRIGGER',
  RESET_PASSWORD_CHANGE: 'RESET_PASSWORD_CHANGE'
};
const ALLOWED_REGION = 'IN';

function FormArea({
  gtmTagVariable,
  focusRef,
  setBackdrop: setCustomBackdrop,
  redirectType,
  to,
  id,
  contrastClass = 'white',
  template_id,
  share_type = '',
  textClass = 'text-black',
  channel_id = 0,
  hideHeader = false
}) {
  const verfication = useSelector((state) => state.share.verfication);
  const userRegionState = useSelector((state) => state.share.UserRegion);
  const isUserGlobal = userRegionState === 'GT' || userRegionState === 'GL';
  const [isChecked, SetIsChecked] = useState(true);
  const { secretCode, email: preFilledEmail, referal_code: preFilledRefCode } = useParams();
  const [backdrop, setBackdrop] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);
  const [err_msg, set_err_msg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginType, setLoginType] = useState();
  const [referalInputDisabled, setReferalInputDisabled] = useState(false);

  const [shareformdataLoading, setShareformdataLoading] = useState(true);
  const [shareFormQuestions, setShareFormQuestions] = useState({ user: [], others: [] });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filledFormData, setFilledFormData] = useState({});

  const { type: loginTypeURL, data } = useGetParams();

  function handleScreenChange(loginType, error) {
    set_err_msg(error ?? '');
    setIsLoading(false);
    setBackdrop(false);
    setLoginType(loginType);
  }

  useEffect(() => {
    if (loginTypeURL) {
      if (loginTypeURL === 'mobile') {
        handleScreenChange(loginTypes.LOGIN_WITH_MOBILE);
      } else if (loginTypeURL === 'email') handleScreenChange(loginTypes.LOGIN_WITH_EMAIL);
    }
  }, [loginTypeURL]);

  useEffect(() => {
    if (id != 0 && share_type != '') {
      setShareformdataLoading(true);
      dispatch(
        getSharePageQuestions(share_type, id, (res) => {
          setShareformdataLoading(false);
          if (res.data) {
            let user = res.data?.user ? res.data?.user?.filter((_q) => _q.visibility != 0) : [];
            let others = res.data?.others
              ? res.data?.others?.filter((_q) => _q.visibility != 0)
              : [];
            log('CHANNEL SHARE QUESTIONS', { user, others });
            setShareFormQuestions({ user, others });
          }
        })
      );
    } else {
      setShareformdataLoading(false);
    }
  }, [id]);

  useEffect(() => {
    let temp_data = {
      ...filledFormData
    };

    if (preFilledEmail) {
      temp_data.email = preFilledEmail;
    }

    if (preFilledRefCode) {
      temp_data.referalCode = preFilledRefCode;
      setReferalInputDisabled(true);
    }

    if (!isUserGlobal) {
      temp_data.user_type = 1;
    }

    setFilledFormData(temp_data);
  }, [preFilledEmail, preFilledRefCode]);

  useEffect(() => {
    if (isUserGlobal) {
      handleScreenChange(loginTypes.LOGIN_WITH_EMAIL);
    } else {
      handleScreenChange(loginTypes.LOGIN_WITH_MOBILE);
    }
  }, [isUserGlobal]);

  const userDet = useSelector((state) => state.common.user_details);
  const navigate = useNavigate();
  const location = useLocation();
  function handleFormChange(e) {
    setFilledFormData({
      ...filledFormData,
      [e.target.name]: e.target.value
    });
  }

  const dispatch = useDispatch();

  function reserveChannelSession(user, callback) {
    if (share_type == 'session') {
      let payload = {
        session_id: id,
        question: '',
        my_participant_id: '',
        attachFilePath: '',
        utm_source: '',
        redirect_to_live: 0,
        first_name: user?.first_name,
        last_name: user?.last_name
      };
      dispatch(
        reserveSession(payload, 'submit', (res) => {
          queryClient.setQueryData([SESSION_DETAILS, page_id], (oldQueryData) => {
            if (oldQueryData) {
              let temp_session_data = oldQueryData;

              temp_session_data.data.data[0].my_participant_id = res?.data?.participation_id || 0;
              temp_session_data.data.data[0].is_booked = true;

              return { ...temp_session_data };
            }
          });

          callback();
        })
      );
    } else {
      callback();
    }
  }

  function formatFormData(userArray, othersArray, answers) {
    const userData = [];
    const othersData = [];

    // Process user array
    userArray.forEach((field) => {
      const fieldName = field.field_name;
      if (answers[fieldName] !== undefined) {
        userData.push({ [fieldName]: answers[fieldName] });
      }
    });

    // Process others array
    othersArray.forEach((field) => {
      const fieldName = field.field_name;
      if (answers[fieldName] !== undefined) {
        othersData.push({ [fieldName]: answers[fieldName] });
      }
    });

    return { user: userData, others: othersData };
  }
  const postUserFieldsResponse = (token, user) => {
    const { others: OthersData, user: userFilledData } = formatFormData(
      shareFormQuestions.user,
      shareFormQuestions.others,
      filledFormData
    );
    dispatch(
      postResponseByToken(token, channel_id, userFilledData, OthersData, (res) => {
        setBackdrop(false);
        if (res != 'error') {
          if (id != 0) {
            dispatch(
              getChannelDetails(channel_id, (response) => {
                if (response != 'error') {
                  if (Array.isArray(response?.data) && response?.data[0]?.type_id) {
                    let privacyStatus = response.data[0].privacy_status;
                    let followStatus = response.data[0].followed_status;
                    reserveChannelSession(user, () => {
                      if (privacyStatus == 0 && followStatus == 0) {
                        dispatch(followSwitching(channel_id, (res) => {}));
                      } else {
                      }
                    });
                  }
                }
              })
            );
          }
        }
      })
    );
  };

  const fileUpload = (field_name, _file) => {
    if (['jpg', 'jpeg', 'png', 'pdf'].indexOf(_file?.name?.split('.').pop()) > -1) {
      if (isFileAllowed(_file, 2)) {
        setUploadedFiles({
          ...uploadedFiles,
          [field_name]: _file
        });
      } else {
        toast.error('Please upload a file of below 2 mb');
      }
    } else {
      toast.error('This file format is not supported');
    }
  };

  const cancelUpload = (field_name, _file) => {
    setUploadedFiles({
      ...uploadedFiles,
      [field_name]: null
    });
  };

  const checkForMandatoryFields = () => {
    return (
      shareFormQuestions?.user.some(
        (_q) => _q.is_mandatory == 1 && !filledFormData[_q.field_name]
      ) ||
      shareFormQuestions?.others
        ?.filter((_f) => _f.type != 'fileupload')
        .some((_q) => _q.is_mandatory == 1 && !filledFormData[_q.field_name]) ||
      (shareFormQuestions?.others?.filter((_f) => _f.type == 'fileupload')?.length > 0 &&
        shareFormQuestions?.others
          ?.filter((_f) => _f.type == 'fileupload')
          .some((_q) => _q.is_mandatory == 1 && !uploadedFiles[_q.field_name]))
    );
  };

  const toastMandatory = () => toast.error('Please fill all the mandatory fields');

  const checkFullName = (name) => {
    if (name) {
      let nameArray = name.trim().split(' ');
      if (nameArray.length == 1) {
        toast.error('Please Enter Your Last Name');
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const startFileUploadProcess = (userToken) => {
    dispatch(
      getFcmTokenByUserToken(userToken, (firebaseToken) => {
        uploadFileOnebyOne(0, firebaseToken, userToken);
      })
    );
  };

  function uploadFileOnebyOne(index, firebaseToken, userToken) {
    firebaseUpload(
      Object.entries(uploadedFiles)[index][1],
      firebaseToken,
      ['pdf', 'jpg', 'jpeg', 'png'],
      (prog) => {
        console.log('prog', prog);
      },
      (fileUrl) => {
        othersInput[Object.entries(uploadedFiles)[index][0]] = fileUrl;
        index += 1;
        if (index == Object.entries(uploadedFiles)?.length) {
          postUserFieldsResponse(userToken);
        } else {
          uploadFileOnebyOne(index, firebaseToken, userToken);
        }
      },
      () => {
        console.log('error');
      }
    );
  }

  const authenticateAndRedirect = (accessToken) => {
    dispatch(
      authenticateUser(
        accessToken,
        (resp) => {
          if (resp != 'error') {
            if (resp.status_code == 200 && share_type != 'doctube') {
              dispatch(
                getVaultList((value_c) => {
                  dispatch(loginSuccess());
                  if (value_c != 'error') {
                    Object.keys(uploadedFiles)?.length > 0
                      ? startFileUploadProcess(accessToken, resp?.data)
                      : postUserFieldsResponse(accessToken, resp?.data);

                    // log("vaultCount", res.data.length);
                    if (value_c.data) {
                      setLocalStorage('vaultCount', value_c.data?.length ?? 0);
                    } else {
                      setLocalStorage('vaultCount', 0);
                    }
                  }
                })
              );
            }
            setLocalStorage('user', resp.data);
          }
        },
        share_type
      )
    );
  };

  const GetOtpFunc = (e) => {
    if (e) {
      e.preventDefault();
    }

    setBackdrop(true);
    console.log('checkForMandatoryFields()', checkForMandatoryFields(), shareFormQuestions);
    // if (checkForMandatoryFields()) {
    //   toastMandatory();
    //   set_err_msg('Please fill all the mandatory fields');
    //   setBackdrop(false);
    //   setIsLoading(false);
    // } else {
    const {
      mobileNo,
      name,
      speciality = {
        master_specialities_id: 0
      }
    } = filledFormData;

    if (!mobileNo || mobileNo == '') {
      set_err_msg('Please Enter Your Mobile No');
      setBackdrop(false);
    } else {
      let regexMob = /^[0-9]{6,13}$/;
      let alpanum = /^[a-z0-9]+$/i;
      if (!mobileNo?.trim().match(regexMob) && mobileNo?.trim().match(alpanum)) {
        set_err_msg('Please Enter Mobile No in Correct Format');
        setBackdrop(false);
      } else {
        set_err_msg('');
        setIsLoading(true);

        console.log('filledFormData', filledFormData);

        if (parseInt(verfication) == 1) {
          if (!!name && !checkFullName(name)) {
            set_err_msg('Please Enter Your Name');
            setBackdrop(false);
            return;
          } else {
            if (loginType === loginTypes.REGISTRATION && !isChecked) {
              toast.error('Please accept our terms and conditions');
              setBackdrop(false);
              return;
            } else {
              let formdata = new FormData();
              formdata.append('name', name);
              formdata.append('phone_no', mobileNo);
              formdata.append('speciality', speciality?.master_specialities_id);
              formdata.append('page_name', 'channel');
              dispatch(
                newsignUpEmail(formdata, (new_signup_response) => {
                  if (new_signup_response.status_code == 203) {
                    toast.error(new_signup_response.message);
                    setIsLoading(false);
                    setBackdrop(false);
                  } else if (new_signup_response.status_code == 200) {
                    setLocalStorage('accessToken', new_signup_response?.data.token);
                    setLocalStorage('refreshToken', new_signup_response?.data.refresh_token);
                    authenticateAndRedirect(new_signup_response?.data.token);
                    setIsLoading(false);
                    setBackdrop(false);
                  } else {
                    toast.error(new_signup_response.message);
                    setIsLoading(false);
                    setBackdrop(false);
                  }
                })
              );
            }
          }
        } else {
          let formData = new FormData();
          formData.append('isdCode', filledFormData?.isd_code);
          formData.append('phone_no', mobileNo?.trim());
          if (getLocalStorage('utm_source', '') !== '') {
            formData.append('utm_source', getLocalStorage('utm_source', ''));
          }
          dispatch(
            otpVerification(formData, (res) => {
              setIsLoading(false);
              if (res != 'error') {
                if (res.status_code == 203) {
                  setIsLoading(false);
                  setBackdrop(false);
                  toast.error(res.message);
                } else if (res.status_code == 200) {
                  setIsLoading(false);
                  setBackdrop(false);
                  handleScreenChange(loginTypes.ENTER_OTP);
                }
              } else {
                setIsLoading(false);
                setBackdrop(false);
                toast.error('Something Went Wrong');
              }
            })
          );
        }
      }
    }
    // }
  };

  const loginEmail = (e) => {
    e.preventDefault();
    if (typeof setCustomBackdrop == 'function') {
      setCustomBackdrop(true);
    }
    setIsLoading(true);
    setBackdrop(true);
    const { email = null, password = null } = filledFormData || {};

    log('Proceed Login');
    if (email == undefined || email == null || email == '') {
      if (typeof setCustomBackdrop == 'function') {
        setCustomBackdrop(false);
      }
      setIsLoading(false);
      setBackdrop(false);
      set_err_msg('Please Enter Your Email');
    } else {
      let regexEmail =
        /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
      if (!email?.trim().match(regexEmail)) {
        if (typeof setCustomBackdrop == 'function') {
          setCustomBackdrop(false);
        }
        setIsLoading(false);
        setBackdrop(false);
        set_err_msg('Please Enter a Valid Email');
      } else {
        if (password == undefined || password == null || password == '') {
          if (typeof setCustomBackdrop == 'function') {
            setCustomBackdrop(false);
          }
          set_err_msg('Please Enter Your Password');
          setIsLoading(false);
          setBackdrop(false);
        } else {
          let emailFormData = new FormData();
          emailFormData.append('email', email?.trim());

          dispatch(
            checkEmail(emailFormData, (res) => {
              if (res.status_code == 200) {
                let formData = new FormData();
                formData.append('email', email?.trim());
                formData.append('password', password);
                const utm_source = getLocalStorage('utm_source', '');
                if (utm_source !== '') {
                  formData.append('utm_source', getLocalStorage('utm_source', ''));
                }

                dispatch(
                  loginWithEmail(formData, (res) => {
                    if (res != 'error') {
                      setIsLoading(false);
                      setBackdrop(false);
                      if (res.status_code == 203) {
                        if (typeof setCustomBackdrop == 'function') {
                          setCustomBackdrop(false);
                        }
                        set_err_msg(res.message);
                      } else if (res.status_code == 200) {
                        if (res.data.status == 3) {
                          setLocalStorage('accessToken', res.data.token);
                          setLocalStorage('refreshToken', res.data.refresh_token);
                          authenticateAndRedirect(res?.data.token);
                        } else {
                          if (typeof setCustomBackdrop == 'function') {
                            setCustomBackdrop(false);
                          }
                          set_err_msg('This Email is not Registered or Not Verified');
                        }
                      } else {
                      }
                    } else {
                      setIsLoading(false);
                      setBackdrop(false);
                      if (typeof setCustomBackdrop == 'function') {
                        setCustomBackdrop(false);
                      }
                      set_err_msg('Something Went Wrong');
                    }
                  })
                );
              } else {
                handleScreenChange(loginTypes.REGISTRATION);
              }
            })
          );
        }
      }
    }
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (typeof setCustomBackdrop == 'function') {
      setCustomBackdrop(true);
    }
    const { otp = null, isd, mobileNo } = filledFormData || {};
    if (otp == undefined || otp == null || otp == '') {
      set_err_msg('Please Enter the Otp');
      if (typeof setCustomBackdrop == 'function') {
        setCustomBackdrop(false);
      }
    } else {
      set_err_msg('');
      let formData = new FormData();
      formData.append('isdCode', filledFormData?.isd_code);
      formData.append('phone_no', mobileNo?.trim());
      formData.append('otp', otp?.trim());
      dispatch(
        verifyTheOtp(formData, (res) => {
          log('verified response', res);
          if (res != 'error') {
            if (res.status_code == 203) {
              if (typeof setCustomBackdrop == 'function') {
                setCustomBackdrop(false);
              }
              set_err_msg(res.message);
              // setShowButton(true);
            } else if (res.status_code == 200) {
              if (res.data.status == 2) {
                handleScreenChange(
                  loginTypes.REGISTRATION,
                  'You Are not registered with us.Please Register'
                );
              }

              if (res.data.status == 3) {
                setLocalStorage('accessToken', res.data.token);
                setLocalStorage('refreshToken', res.data.refresh_token);
                authenticateAndRedirect(res?.data.token);
              } else {
                if (typeof setCustomBackdrop == 'function') {
                  setCustomBackdrop(false);
                }
                handleScreenChange(
                  loginTypes.REGISTRATION,
                  'This Mobile is not Registered,Please Use Your Email Id or Register with Us'
                );
              }
            } else {
            }
          } else {
            if (typeof setCustomBackdrop == 'function') {
              setCustomBackdrop(false);
            }
            set_err_msg('Something Went Wrong');
          }
        })
      );
    }
  };

  const sendRegDetails = (e) => {
    e.preventDefault();
    const {
      phoneNo = null,
      password = null,
      confirmPassword = null,
      user_type = null,
      email = null,
      fullName = null,
      referalCode = '',
      honarific = 'Dr',
      speciality = { master_specialities_id: 0 },
      isd_code = '91'
    } = filledFormData || {};

    console.log('speciality', filledFormData);

    if (isChecked == false) {
      set_err_msg('Please Accept the terms and Conditions');
      return;
    }
    if (phoneNo == undefined || phoneNo == null || phoneNo == '') {
      set_err_msg('Please Enter a Mobile Number');
      return;
    } else {
      let regexMob = /^[0-9]{6,13}$/;
      let alpanum = /^[a-z0-9]+$/i;
      if (!phoneNo.match(regexMob) && phoneNo.match(alpanum)) {
        set_err_msg('Please Enter Mobile No in Correct Format');
        return;
      }
    }

    if (password == undefined || password == null || password?.trim() == '') {
      set_err_msg('Please Enter Password');
      return;
    } else if (
      confirmPassword == undefined ||
      confirmPassword == null ||
      confirmPassword?.trim() == ''
    ) {
      set_err_msg('Please Enter Confirm Password');
      return;
    } else {
      if (password?.trim() != confirmPassword?.trim()) {
        set_err_msg('Password and confirm password must be same');
        return;
      }
    }

    if (user_type == undefined || user_type == null || user_type == '') {
      set_err_msg('Please Select Your Profession');
      return;
    }

    if (email == undefined || email == null || email?.trim() == '') {
      set_err_msg('Please Enter email ID');
      return;
    } else {
      var reEmail =
        /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
      if (!email.match(reEmail)) {
        set_err_msg('Please Enter valid email');
        return;
      }
    }

    if (fullName == undefined || fullName == null || fullName == '') {
      set_err_msg('Please Enter Your Name');
      return;
    } else {
      var namep = [];
      let temp_name = stringToArray(fullName?.trim());
      for (const each of temp_name) {
        if (each?.trim()) {
          namep.push(each);
        }
      }
      if (namep.length == 1) {
        set_err_msg('Please Enter Your Last Name');
        return;
      }
    }

    const formdata = new FormData();
    formdata.append('name', namep.join(' '));
    formdata.append('email', email);
    formdata.append('phone_no', phoneNo);
    formdata.append('password', password);
    formdata.append('confirm_password', confirmPassword);
    formdata.append('referal_code', referalCode);
    formdata.append('honarific', honarific);
    formdata.append('speciality', speciality?.master_specialities_id);
    formdata.append('user_type', user_type);
    formdata.append('isd_code', isd_code?.replace(/\+/g, ''));
    if (getLocalStorage('utm_source', '')) {
      formdata.append('utm_source', getLocalStorage('utm_source', ''));
    }
    setIsLoading(true);
    dispatch(
      signUpEmail(formdata, (response) => {
        if (response.status_code == 200) {
          if (response.data.status == 3) {
            setLocalStorage('accessToken', response.data.token);
            setLocalStorage('refreshToken', response.data.refresh_token);
            authenticateAndRedirect(response.data.token);
          } else {
            setShowRestrictedModal(true);
          }
        } else {
          setIsLoading(false);
          if (
            response.message ==
              'A user with this Email already exists. Please enter a different Email.' &&
            response.status_code == 203
          ) {
            handleScreenChange(
              loginTypes.LOGIN_WITH_EMAIL,
              'You have already registered with this email address. Please login to proceed.'
            );
          } else {
            set_err_msg(response.message);
          }
        }
      })
    );
  };

  const autoAuthLocation = useRef();
  const navigateToDetailsPage = (content_type, content_id) => {
    removeSessionStorage('autoauth_location');
    if (content_type == 'session') {
      navigate(`${routeNames.sessions.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'medwiki') {
      navigate(`${routeNames.medwiki.detail}?/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'archived_video') {
      navigate(`${routeNames.clinicalVideo.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'survey') {
      navigate(`${routeNames.spq.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'gr') {
      navigate(`${routeNames.grandRound.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'epub') {
      navigate(`${routeNames.epaper.detail}/${content_id}`, { state: { from_login: true } });
    } else {
      navigate(routeNames.dashboard.landing, { state: { from_login: true } });
    }
  };

  const sendForgetPassword = (e) => {
    e.preventDefault();
    const { email = '' } = filledFormData || {};

    if (!email || email == '') {
      set_err_msg('Please Enter Your Email');
      return;
    } else {
      let regexEmail =
        /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
      if (!email.trim().match(regexEmail)) {
        if (setCustomBackdrop == 'function') {
          setCustomBackdrop(false);
        }
        set_err_msg('Please Enter a Valid Email');
        return;
      } else {
        let emailFormData = new FormData();
        emailFormData.append('email', email.trim());
        emailFormData.append('url', `${window.location.origin}/#/reset-password`);

        dispatch(
          postResetPasswordRequest(emailFormData, (res) => {
            if (res !== 'error') {
              if (res.status_code == 200) {
                toast.success(res.message);
                if (isUserGlobal) {
                  handleScreenChange(loginTypes.LOGIN_WITH_EMAIL);
                } else {
                  handleScreenChange(loginTypes.LOGIN_WITH_MOBILE);
                }
              } else if (res.status_code == 203) {
                set_err_msg(res.message);
                toast.error(err_msg);
              }
            } else {
              set_err_msg('Something went wrong. Please try again');
            }
          })
        );
      }
    }
  };
  //redirect to destination
  useEffect(() => {
    const userDetails = userDet || {};
    const { user_master_id } = userDetails;
    console.log('testing redirect', user_master_id, redirectType, id);

    if (user_master_id) {
      if (typeof setCustomBackdrop == 'function') {
        setCustomBackdrop(false);
      }
      if (autoAuthLocation.current) {
        navigateToDetailsPage(autoAuthLocation.current.type, autoAuthLocation.current.id);
      } else {
        console.log('redirectType', id ? `${redirectType}/${id}` : `${redirectType}`);
        if (redirectType == routeNames.dashboard.landing) {
          if (to?.pathname == '/TrainingContent') {
            navigate({ ...to, pathname: '/TrainingCover' }, { state: { from_login: true } });
          } else if (to?.pathname?.includes(routeNames.course.content)) {
            navigate(
              {
                ...to,
                pathname: to?.pathname?.replace(routeNames.course.content, routeNames.course.cover)
              },
              { state: { from_login: true } }
            );
          } else {
            navigate(to, { state: { from_login: true } });
          }
        } else if (
          redirectType == routeNames.telemed.landing ||
          redirectType == routeNames.userProfile.landing
        ) {
          navigate(`/${redirectType}`, { state: { from_login: true } });
        } else if (redirectType == 'dnr') {
          navigate(routeNames.discussRefer.landing, {
            state: { fromShare: true, from_login: true }
          });
        } else if (redirectType == routeNames.election.landing) {
          navigate(`${routeNames.election.landing}/${election_id}`, {
            state: { from_login: true }
          });
        } else if (redirectType === routeNames.doctube.landing) {
          navigate(`${routeNames.doctube.landing}`, {
            state: { from_login: true }
          });
        } else {
          console.log('navigating from here', id ? `${redirectType}/${id}` : `${redirectType}`);
          navigate(id ? `${redirectType}/${id}` : `${redirectType}`, {
            state: { from_login: true }
          });
        }
      }
    } else {
      // <Navigate to="/" state={{ from: location }} />;
    }
  }, [userDet, redirectType, id]);

  useEffect(() => {
    if (location.state) {
      set_err_msg(location.state.errMsg);
    }
  }, []);

  const heading =
    loginType == loginTypes.REGISTRATION
      ? 'Register'
      : loginType == loginTypes.FORGET_PASSWORD
      ? 'Forget Password'
      : loginType == loginTypes.RESET_PASSWORD_TRIGGER
      ? 'Reset Password'
      : 'Login';

  const checkSecretCode = (secretKey) => {
    return new Promise((resolve, reject) => {
      if (secretKey) {
        dispatch(
          validateSecretKey(secretKey, (response) => {
            if (response == 'error') {
              resolve(false);
            } else {
              if (response.status_code == 200) {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          })
        );
      } else {
        reject('Problem loading page');
      }
    });
  };

  const validateSecretCode = async () => {
    if (!secretCode) {
      return;
    }
    try {
      const result = await checkSecretCode(secretCode);
      setIsLoading(false);
      if (result === true) {
      } else {
        set_err_msg('Your link has expired');
      }

      // setLoginType(5);
      console.log('secret', result);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    validateSecretCode();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes('/reset-password')) {
      handleScreenChange(loginTypes.RESET_PASSWORD_CHANGE);
    } else if (pathname.includes('/forget-password')) {
      handleScreenChange(loginTypes.FORGET_PASSWORD);
    } else if (pathname.includes('/registration')) {
      handleScreenChange(loginTypes.REGISTRATION);
    }
  }, [pathname]);

  const getTemplateSpecificClass = () => {
    switch (template_id) {
      case 1:
        return '';
    }

    return '';
  };

  console.log('loginTypes', loginType);

  const resetPassword = (e) => {
    const { newPassword, confirmPassword } = filledFormData || {};
    console.log('filledFormData', filledFormData);
    if (e) {
      e.preventDefault();
    }

    if (!newPassword) {
      set_err_msg('Please enter a password');
      return;
    } else if (!confirmPassword) {
      set_err_msg('Please enter confirm password');
      return;
    } else if (newPassword !== confirmPassword) {
      set_err_msg("Your password doesn't matched with the confirmed password");
      return;
    } else {
      let passwordFormData = new FormData();
      passwordFormData.append('password', newPassword);
      passwordFormData.append('secretkey', secretCode);
      dispatch(
        updatePassword(passwordFormData, (res) => {
          if (res == 'error') {
            // setLoginType(1);
            handleScreenChange(loginTypes.LOGIN_WITH_EMAIL);
            toast.error('Something went wrong. Please try again');
          } else {
            if (res.status_code == 200) {
              toast.success('Your password has been updated successfully, Please login');
              handleScreenChange(loginTypes.LOGIN_WITH_EMAIL);
            } else if (res.status_code == 203) {
              handleScreenChange(loginTypes.LOGIN_WITH_EMAIL, res.message);
              toast.error(err_msg);
            }
          }
        })
      );
    }
  };

  // if (userRegionState == '') return <PaginationLoader />;
  if (isUserGlobal)
    return (
      <AppBanner
        msg={
          template_id == 1 || template_id == 2
            ? 'Experience the expertise of your peers at your fingertips—get the App now! 🚀💉'
            : ''
        }
        extraClass={
          template_id == 2 ? 'bg-opacity-25 align-self-stretch justify-content-center' : ''
        }
        textClass={template_id == 2 ? 'white' : 'black'}
      />
    );

  return (
    <>
      <div
        className={`w-100 shareLogin_In ${getTemplateSpecificClass()} text-${contrastClass} position-relative z-1`}
      >
        {!hideHeader && (
          <>
            {' '}
            <h2 className={`fs-1 fw-bold text-${contrastClass} text-uppercase mb-3`}>{heading}</h2>
            <p className="fs-4 fw-normal">
              This platform is meant for Registered Medical Practitioners only.
            </p>
          </>
        )}
        <div className="w-100 shareLoginForm mt-3">
          <div className="row mt-n3 m-0">
            <RenderFields
              loginType={loginType}
              handleFormChange={handleFormChange}
              filledFormData={filledFormData}
              isUserGlobal={isUserGlobal}
              setFilledFormData={setFilledFormData}
              isChecked={isChecked}
              SetIsChecked={SetIsChecked}
              err_msg={err_msg}
              referalInputDisabled={referalInputDisabled}
              contrastClass={contrastClass}
              id={id}
              isLoading={isLoading}
              share_type={share_type}
              gtmTagVariable={gtmTagVariable}
              textClass={textClass}
              shareformdataLoading={shareformdataLoading}
              focusRef={focusRef}
              shareFormQuestions={shareFormQuestions}
              fileUpload={fileUpload}
              cancelUpload={cancelUpload}
              uploadedFiles={uploadedFiles}
              handleScreenChange={handleScreenChange}
            />

            <RenderButtons
              loginType={loginType}
              setLoginType={setLoginType}
              isLoading={isLoading}
              GetOtpFunc={GetOtpFunc}
              loginEmail={loginEmail}
              userRegionState={userRegionState}
              verifyOtp={verifyOtp}
              isUserGlobal={isUserGlobal}
              sendRegDetails={sendRegDetails}
              sendForgetPassword={sendForgetPassword}
              set_err_msg={set_err_msg}
              contrastClass={contrastClass}
              template_id={template_id}
              resetPassword={resetPassword}
              handleScreenChange={handleScreenChange}
            />

            {err_msg && (
              <div className="col-12 shareLoginFormRow text-danger text-center mt-3 position-relative p-0">
                {err_msg}
              </div>
            )}
          </div>
        </div>
      </div>

      <RestrictedModal
        showRestrictedModal={showRestrictedModal}
        setShowRestrictedModal={setShowRestrictedModal}
        gtmTagVariable={gtmTagVariable}
        focusRef={focusRef}
      />
      <BackdropComponent backdrop={backdrop} />
    </>
  );
}

export default FormArea;

function RenderFields({
  loginType,
  handleFormChange,
  filledFormData,
  focusRef,
  isUserGlobal,
  setFilledFormData,
  isChecked,
  SetIsChecked,
  err_msg,
  isLoading,
  referalInputDisabled,
  contrastClass,
  id,
  share_type,
  gtmTagVariable,
  textClass,
  shareformdataLoading,
  shareFormQuestions,
  fileUpload,
  cancelUpload,
  uploadedFiles,
  handleScreenChange
}) {
  const [show, setShow] = useState(false);
  const [specsData, setSpecsData] = useState([]);
  const dispatch = useDispatch();

  const referenceValue = useSelector((state) => state.common.reference);
  const inputRef = useRef();
  useEffect(() => {
    console.log('REFERENCE', referenceValue);
    if (inputRef?.current) {
      if (referenceValue) {
        inputRef?.current?.focus();
      }
      dispatch(toggleReference(false));
    }
  }, [referenceValue]);

  useEffect(() => {
    dispatch(
      getAllSpecialities((response) => {
        if (response.status_code == 200) {
          setSpecsData(response.data.speciality_data);
        }
      })
    );

    setFilledFormData({
      ...filledFormData,
      user_type: isUserGlobal ? '' : 1,
      speciality: {
        master_specialities_id: 0
      }
    });
  }, []);

  const renderOtherTypeQuestion = (question, index) => {
    const { field_name, type, is_mandatory, options } = question;
    switch (type) {
      case 'text':
      case 'number':
        return (
          <TextField
            label={field_name}
            name={field_name}
            onChange={handleFormChange}
            type="other"
            is_mandatory={is_mandatory}
            textClass={textClass}
          />
        );

      case 'textarea':
        return (
          <TextArea
            label={field_name}
            name={field_name}
            onChange={handleFormChange}
            type="other"
            is_mandatory={is_mandatory}
            textClass={textClass}
          />
        );
      case 'radio':
        return (
          <Checkbox
            label={field_name}
            options={options}
            idx={index + 'others'}
            type={'radio'}
            onChange={(_g) => handleFormChange({ target: { name: field_name, value: _g } })}
            is_mandatory={is_mandatory}
            textClass={textClass}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            label={field_name}
            options={options}
            idx={index + 'others'}
            type={'checkbox'}
            onChange={(_g) => handleFormChange({ name: field_name, value: _g })}
            is_mandatory={is_mandatory}
            textClass={textClass}
          />
        );
      case 'dropdown':
        return (
          <Dropdown
            label={field_name}
            options={options}
            onChange={(_g) => handleFormChange({ target: { name: field_name, value: _g } })}
            is_mandatory={is_mandatory}
            textClass={textClass}
          />
        );
      case 'fileupload':
        return (
          <FileUpload
            label={field_name}
            fileUpload={(_file) => fileUpload(field_name, _file)}
            attachedFile={uploadedFiles[field_name]}
            is_mandatory={is_mandatory}
            cancelClick={(_file) => cancelUpload(field_name, _file)}
            textClass={textClass}
          />
        );
      default:
        return <></>;
    }
  };
  const renderUserTypeQuestion = (question, index) => {
    const { field_name, type, is_mandatory, options } = question;

    if (field_name == 'phone') {
      if (loginType == loginTypes.LOGIN_WITH_MOBILE) return null;
      return (
        <div className="col-12 shareLoginV2FormRow shareLoginV2MobileNo rounded-3 position-relative p-0 mt-4">
          <MobileNumber
            gtmTagVariable={`${gtmTagVariable}_enterYourMobileNumber`}
            value={filledFormData.phone}
            onChangeIsd={(_isd) =>
              setFilledFormData({
                ...filledFormData,
                isd_code: _isd
              })
            }
            onChange={(e) => console.log('oonchange', e)}
            // onKeyPress={(e) => onEnterPress(e, 'login_phone')}
            isGlobalRegion={isUserGlobal}
            focusRef={focusRef}
          />
        </div>
      );
    } else {
      switch (type) {
        case 'text':
        case 'number':
          return (
            <TextField
              label={returnLabelName(field_name)}
              name={field_name}
              value={filledFormData[field_name]}
              onChange={handleFormChange}
              type="user"
              is_mandatory={is_mandatory}
            />
          );

        case 'textarea':
          return (
            <TextArea
              label={returnLabelName(field_name)}
              name={field_name}
              onChange={handleFormChange}
              type="user"
              value={filledFormData[field_name]}
              is_mandatory={is_mandatory}
            />
          );
        case 'radio':
          return (
            <Checkbox
              label={returnLabelName(field_name)}
              options={options}
              idx={index + 'user'}
              type={'radio'}
              onChange={(_g) => handleFormChange({ target: { name: field_name, value: _g } })}
              is_mandatory={is_mandatory}
              textClass={textClass}
            />
          );
        case 'checkbox':
          return (
            <Checkbox
              label={returnLabelName(field_name)}
              options={options}
              idx={index + 'user'}
              type={'checkbox'}
              onChange={(_g) => handleFormChange({ name: field_name, value: _g })}
              is_mandatory={is_mandatory}
              textClass={textClass}
            />
          );
        case 'dropdown':
          return (
            <Dropdown
              label={returnLabelName(field_name)}
              options={options}
              onChange={(_g) => handleFormChange({ target: { name: field_name, value: _g } })}
              is_mandatory={is_mandatory}
            />
          );

        default:
          return <></>;
      }
    }
  };

  if (shareformdataLoading) return <PaginationLoader />;

  switch (loginType) {
    case loginTypes.LOGIN_WITH_MOBILE:
      return (
        <>
          <div className="col-12 shareLoginFormRow shareLoginMobileNo rounded mt-3 position-relative d-flex gap-3 px-0">
            <div
              className={`shareLoginIsd bg-white w-100px text-dark border-1  border-${contrastClass}`}
            >
              <ISDPicker
                className="w-100 h-100 pe-20 ps-3 form-control text-start"
                onChange={handleFormChange}
              />
            </div>
            <FloatingLabel
              className="flex-grow-1"
              controlId="floatingInput"
              label="Enter Mobile No.*"
            >
              <Form.Control
                className={`fs-4 `}
                type="text"
                placeholder="Enter Mobile No."
                pattern="[6789][0-9]{9}"
                value={filledFormData?.mobileNo}
                name="mobileNo"
                onChange={(e) => {
                  if (shareFormQuestions.user.filter((_q) => _q.field_name == 'phone').length > 0) {
                    setFilledFormData({
                      ...filledFormData,
                      mobileNo: e.target.value,
                      phone: e.target.value
                    });
                  } else {
                    handleFormChange(e);
                  }
                }}
                ref={inputRef}
              />
            </FloatingLabel>
          </div>
          {shareFormQuestions?.user?.length > 0
            ? shareFormQuestions?.user
                ?.filter(
                  (_q) =>
                    _q.field_name !== 'mobile no' &&
                    _q.field_name !== 'email' &&
                    _q.field_name !== 'passsword'
                )
                .map((_qus, _idx) => (
                  <div
                    className="col-12 shareLoginV2FormRow mt-4 position-relative p-0"
                    key={_idx + 1}
                  >
                    {renderUserTypeQuestion(_qus, _idx)}
                  </div>
                ))
            : null}
          {shareFormQuestions?.others?.length > 0
            ? shareFormQuestions?.others?.map((_qus, _idx) => (
                <div
                  className="col-12 shareLoginV2FormRow mt-4 position-relative p-0"
                  key={_idx + 1}
                >
                  {renderOtherTypeQuestion(_qus, _idx)}
                </div>
              ))
            : null}
        </>
      );
    case loginTypes.LOGIN_WITH_EMAIL:
      return (
        <>
          <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
            <FloatingLabel controlId="floatingInput" label="Email ID*">
              <Form.Control
                className={`fs-4`}
                type="text"
                placeholder="Email ID*"
                value={filledFormData.email}
                name="email"
                onChange={handleFormChange}
                ref={inputRef}
              />
            </FloatingLabel>
          </div>
          <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
            <FloatingLabel controlId="floatingInput1" label="Password*">
              <Form.Control
                className={`fs-4`}
                type="password"
                placeholder="Password*"
                name="password"
                value={filledFormData.password}
                onChange={handleFormChange}
              />
            </FloatingLabel>
          </div>

          {shareFormQuestions?.user?.length > 0 &&
            shareFormQuestions?.user
              ?.filter(
                (_q) =>
                  _q.field_name !== 'mobile no' &&
                  _q.field_name !== 'email' &&
                  _q.field_name !== 'passsword'
              )
              .map((_qus, _idx) => (
                <div
                  className="col-12 shareLoginV2FormRow mt-4 position-relative p-0"
                  key={_idx + 1}
                >
                  {renderUserTypeQuestion(_qus, _idx)}
                </div>
              ))}
          {shareFormQuestions?.others?.length > 0
            ? shareFormQuestions?.others?.map((_qus, _idx) => (
                <div
                  className="col-12 shareLoginV2FormRow mt-4 position-relative p-0"
                  key={_idx + 1}
                >
                  {renderOtherTypeQuestion(_qus, _idx)}
                </div>
              ))
            : null}
        </>
      );
    case loginTypes.ENTER_OTP:
      return (
        <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
          <FloatingLabel controlId="floatingInput" label="Enter OTP">
            <Form.Control
              className={`fs-4`}
              type="text"
              placeholder="Enter Mobile No."
              autocomplete="one-time-code"
              inputmode="numeric"
              pattern="[0-9]*"
              value={filledFormData?.otp}
              name="otp"
              onChange={handleFormChange}
            />
          </FloatingLabel>
        </div>
      );
    case loginTypes.REGISTRATION:
      return (
        <>
          <div className="col-12 shareLoginFormRow shareLoginMobileNo rounded mt-4 position-relative p-0 d-flex gap-3">
            <div className="shareLoginIsd bg-white text-dark">
              <Form.Select
                className="w-100px h-100 pe-5 ps-3 form-control registerSelect"
                name="honarific"
                defaultChecked="Dr"
                onChange={(e) =>
                  handleFormChange({
                    target: { name: 'honarific', value: e.target.value }
                  })
                }
              >
                {['Dr', 'Mr', 'Ms', 'Mrs'].map((item) => (
                  <option
                    value="Dr"
                    // className={`${gtmTagVariable}_registrationForm_honarific_select`}
                  >
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
            <FloatingLabel className="flex-grow-1" controlId="floatingInput" label="Full Name*">
              <Form.Control
                className="fs-4"
                type="text"
                placeholder="Full Name"
                name="fullName"
                onChange={handleFormChange}
                value={filledFormData?.fullName}
              />
            </FloatingLabel>
          </div>

          <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
            <FloatingLabel controlId="floatingInput1" label="Email*">
              <Form.Control
                className={`fs-4 `}
                type="email"
                placeholder="Email*"
                name="email"
                onChange={handleFormChange}
                value={filledFormData?.email}
              />
            </FloatingLabel>
          </div>

          <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
            <FloatingLabel controlId="floatingInput2" label="Password*">
              <Form.Control
                className={`fs-4 `}
                type="password"
                placeholder="Password*"
                name="password"
                onChange={handleFormChange}
                value={filledFormData?.password}
              />
            </FloatingLabel>
          </div>

          <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
            <FloatingLabel controlId="floatingInput3" label="Confirm Password*">
              <Form.Control
                className={`fs-4 `}
                type="password"
                placeholder="Confirm Password*"
                name="confirmPassword"
                onChange={handleFormChange}
                value={filledFormData?.confirmPassword}
              />
            </FloatingLabel>
          </div>

          <div className="col-12 shareLoginFormRow shareLoginMobileNo rounded mt-4 position-relative p-0 d-flex gap-3">
            <div className="shareLoginIsd text-dark">
              <ISDPicker
                className="w-100px h-100 pe-5 ps-3 form-control registerSelec"
                onChange={handleFormChange}
              />
            </div>
            <FloatingLabel
              className="flex-grow-1"
              controlId="floatingInput"
              label="Enter Mobile No.*"
            >
              <Form.Control
                className={`fs-4 `}
                name="phoneNo"
                type="text"
                placeholder="Enter Mobile No."
                pattern="[6789][0-9]{9}"
                value={filledFormData?.phoneNo}
                onChange={handleFormChange}
              />
            </FloatingLabel>
          </div>

          <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
            <FloatingLabel controlId="floatingInput4" label="Referral Code">
              <Form.Control
                disabled={referalInputDisabled}
                className={`fs-4  `}
                type="text"
                placeholder="Referral Code"
                name="referalCode"
                value={filledFormData.referalCode}
                onChange={handleFormChange}
              />
            </FloatingLabel>
          </div>

          {isUserGlobal ? (
            <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
              <p className="fs-4 fw-normal">Select your profession</p>
              <div className="row ms-n3 mt-n3 me-0">
                {[
                  {
                    id: 1,
                    title: 'Doctor',
                    icon: doctor
                  },
                  { id: 11, title: 'Nurse', icon: nurse },
                  { id: 13, title: 'Pharmacist', icon: pharmacist },
                  { id: 12, title: 'Other', icon: other }
                ].map((_r, _idx) => (
                  <div className="col-6 ps-3 pt-3 pe-0" key={_idx}>
                    <div className="clr_v3_checkbox position-relative overflow-hidden clr_v3_checkbox__specialityCard">
                      <input
                        type="radio"
                        name="user_type"
                        id={`checkbox_${_idx}`}
                        value={_r.title}
                        checked={filledFormData.user_type == _r.id}
                        onChange={(e) => {
                          setFilledFormData({
                            ...filledFormData,
                            speciality: { master_specialities_id: 0 },
                            user_type: _r.id
                          });
                        }}
                        className="clr_v3_checkbox__input position-absolute start-0 top-0 w-100 h-100 z-3 opacity-0"
                      />
                      <label
                        for={`checkbox_${_idx}`}
                        className={`clr_v3_checkbox__label position-relative overflow-hidden rounded-3 p-3 border border-105 border-${contrastClass} w-100 h-100`}
                      >
                        <div className="overlay z-1 bg-primary"></div>
                        <div className="clr_v3_checkbox__content d-flex flex-column justify-conntent-between">
                          <div className="d-flex align-items-center justify-content-center p-2 clr_v3_checkbox__specialityIcon mb-3">
                            <img src={_r.icon} alt={_r.title} className="w-100 h-100" />
                          </div>
                          <span className="flex-1 mt-auto">{_r.title}</span>
                        </div>
                        <div className="clr_v3_checkbox__icon d-flex align-items-center justify-content-center z-2 rounded-circle position-absolute start-50 top-50">
                          <i className="flaticon-check fs-30"></i>
                        </div>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          {parseInt(filledFormData?.user_type) === 1 && (
            <div className="col-12 shareLoginFormRow text-center cursorPointer mt-5 mb-3 position-relative p-0">
              <div
                className={`w-100 text-uppercase fs-4 fw-normal text-${contrastClass} `}
                onClick={() => {
                  setShow(true);
                }}
              >
                {filledFormData?.speciality &&
                filledFormData?.speciality != '' &&
                'specialities_name' in filledFormData?.speciality
                  ? `${filledFormData?.speciality?.specialities_name}- Change Speciality`
                  : ' + Select Specialities'}
              </div>
            </div>
          )}

          <div
            className={`col-12 shareLoginFormRow text-${contrastClass} mt-5 position-relative p-0  d-flex align-items-center`}
          >
            <Form.Check
              type={'checkbox'}
              className={` d-inline-block `}
              id={'tnc'}
              checked={isChecked}
              onChange={() => {
                SetIsChecked(!isChecked);
              }}
              label={<span className={`text-${contrastClass}`}>I Accept</span>}
            />
            <a
              className={`d-inline-block ms-3 text-secondary`}
              style={{ verticalAlign: 'top' }}
              target="_blank"
              // href="https://doctor.clirnet.com/knowledge/registration/terms_page"
              href="https://www.clirnet.com/terms-and-conditions"
            >
              Terms and Conditions
            </a>
          </div>

          <Modal
            className="rightModal medWikiFilterModal p-0"
            size="sm"
            show={show}
            onHide={() => setShow(false)}
          >
            <Modal.Header className="bg-primary position-relative rounded-0 border-0 p-4">
              <Modal.Title className={`text-light fs-3`}>Select Specialities</Modal.Title>
              <span
                className={`modalClose rounded-circle text-white cursorPointer  position-absolute top-50 translate-middle-y d-flex justify-content-center align-items-center`}
                onClick={() => setShow(false)}
              >
                ok
              </span>
            </Modal.Header>
            <Modal.Body className="py-20 px-4 overflow-y-auto bg-white">
              {specsData && specsData.length > 0
                ? specsData?.map((singleSpec, specInd) => (
                    <div className="medWikiFilterModalCheck" key={specInd + 1}>
                      <Form.Check
                        className={``}
                        name="specialitySelector"
                        type="radio"
                        checked={
                          filledFormData.speciality?.master_specialities_id ==
                          singleSpec.master_specialities_id
                        }
                        label={singleSpec.specialities_name}
                        id={singleSpec.master_specialities_id}
                        onChange={() =>
                          setFilledFormData({ ...filledFormData, speciality: singleSpec })
                        }
                      />
                    </div>
                  ))
                : 'Unable To Show Data'}
            </Modal.Body>
          </Modal>
        </>
      );
    case loginTypes.FORGET_PASSWORD:
      return (
        <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
          <FloatingLabel controlId="floatingInput" label="Email ID*">
            <Form.Control
              className={`fs-4`}
              type="email"
              placeholder="Email ID*"
              name="email"
              value={filledFormData?.email}
              onChange={handleFormChange}
            />
          </FloatingLabel>
        </div>
      );
    case loginTypes.RESET_PASSWORD_CHANGE:
      return (
        <>
          {isLoading ? (
            <PaginationLoader />
          ) : (
            <>
              <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <FloatingLabel controlId="floatingInput1" label="New Password*">
                  <Form.Control
                    className={`fs-4`}
                    type="password"
                    placeholder="New Password*"
                    name="newPassword"
                    value={filledFormData?.newPassword}
                    onChange={handleFormChange}
                    disabled={isLoading}
                  />
                </FloatingLabel>
              </div>
              <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <FloatingLabel controlId="floatingInput1" label="Confirm Password*">
                  <Form.Control
                    className={`fs-4`}
                    type="password"
                    placeholder="Confirm Password*"
                    value={filledFormData?.confirmPassword}
                    name="confirmPassword"
                    onChange={handleFormChange}
                    disabled={isLoading}
                  />
                </FloatingLabel>
              </div>
            </>
          )}
        </>
      );
    default:
      return null;
  }
}

function RenderButtons({
  loginType,
  setLoginType,
  isLoading,
  GetOtpFunc,
  loginEmail,
  userRegionState,
  verifyOtp,
  isUserGlobal,
  sendRegDetails,
  sendForgetPassword,
  set_err_msg,
  contrastClass,
  template_id,
  resetPassword,
  handleScreenChange
}) {
  switch (loginType) {
    case loginTypes.LOGIN_WITH_MOBILE:
      return (
        <>
          <div className="d-flex flex-column gap-3 mt-4 px-0">
            <button
              className={`w-100 rounded-3 text-uppercase btn btn-primary`}
              onClick={GetOtpFunc}
              aria-label={template_id == 1 ? 'Proceed' : 'Start Here'}
              disabled={isLoading}
              type="button"
            >
              {isLoading ? (
                <Loader type="ThreeDots" color="#FFFFFF" height={25} width={30} />
              ) : template_id == 1 ? (
                'Proceed'
              ) : (
                'LOGIN'
              )}
            </button>
            <span className={`mx-auto text-${contrastClass}`}>or</span>
            <button
              className={`w-100 rounded-3 text-uppercase btn btn-${
                contrastClass === 'light' ? 'secondary' : 'light'
              }`}
              onClick={() => handleScreenChange(loginTypes.LOGIN_WITH_EMAIL)}
              aria-label="Login with email"
              type="button"
            >
              Login with Email
            </button>
            <h4
              className={`col-12 text-center py-3  text-${contrastClass} cursorPointer`}
              onClick={() => {
                handleScreenChange(loginTypes.REGISTRATION);
              }}
            >
              New to CLIRNET?{' '}
              <span className="text-secondary hover-primary">Request for access!</span>
            </h4>
          </div>
        </>
      );
    case loginTypes.LOGIN_WITH_EMAIL:
      return (
        <>
          <div className="d-flex flex-column gap-3 mt-4">
            <button
              className={`w-100 rounded-3 text-uppercase btn btn-primary`}
              type="button"
              aria-label="Submit"
              onClick={loginEmail}
              disabled={isLoading}
            >
              Submit
            </button>
            {userRegionState == ALLOWED_REGION && (
              <span className={`mx-auto text-${contrastClass}`}>or</span>
            )}
            {userRegionState == ALLOWED_REGION && (
              <button
                className={`w-100 rounded-3 text-uppercase btn  btn-${
                  contrastClass === 'light' ? 'secondary' : 'light'
                }`}
                onClick={() => handleScreenChange(loginTypes.LOGIN_WITH_MOBILE)}
                aria-label="Login with mobile number"
                type="button"
              >
                Login With Mobile Number
              </button>
            )}

            <h4
              className={`col-12 text-center  text-${contrastClass} cursorPointer`}
              onClick={() => {
                handleScreenChange(loginTypes.FORGET_PASSWORD);
              }}
            >
              <span className="text-secondary hover-primary">Forget Password?</span>
            </h4>
          </div>
        </>
      );
    case loginTypes.ENTER_OTP:
      return (
        <>
          <div className="d-flex flex-column gap-3 mt-4">
            <button
              type="button"
              className={`w-100 rounded-3 text-uppercase btn btn-primary`}
              onClick={verifyOtp}
              aria-label="Submit OTP"
            >
              Submit Otp
            </button>
            <button
              type="button"
              className={`w-100 rounded-3 text-uppercase btn btn-light`}
              onClick={() => handleScreenChange(loginTypes.LOGIN_WITH_MOBILE)}
              aria-label="Edit mobile number"
            >
              Edit Mobile Number
            </button>
          </div>
        </>
      );
    case loginTypes.REGISTRATION:
      return (
        <>
          <div className="d-flex flex-column gap-3 mt-4">
            <button
              type="button"
              className={`w-100 rounded-3 text-uppercase btn btn-primary`}
              onClick={(e) => sendRegDetails(e)}
              aria-label="Submit"
              disabled={isLoading}
            >
              {isLoading ? 'Please wait...' : isUserGlobal ? 'Register' : 'Request Access'}
            </button>

            <h4
              className={`col-12 text-center  text-${contrastClass} cursorPointer`}
              onClick={() => {
                handleScreenChange(
                  isUserGlobal ? loginTypes.LOGIN_WITH_EMAIL : loginTypes.LOGIN_WITH_MOBILE
                );
              }}
            >
              Already Registered? <span className="text-secondary hover-primary">Login</span>
            </h4>
          </div>
        </>
      );
    case loginTypes.FORGET_PASSWORD:
      return (
        <div className="d-flex flex-column gap-3 mt-4">
          <button
            className={`w-100 rounded-3 text-uppercase btn btn-primary`}
            type="button"
            aria-label="Submit"
            onClick={sendForgetPassword}
          >
            Submit
          </button>
          <h4
            className={`col-12 text-center py-3  text-${contrastClass} cursorPointer`}
            onClick={() => {
              handleScreenChange(loginTypes.REGISTRATION);
            }}
          >
            New to CLIRNET?{' '}
            <span className="text-secondary hover-primary">Request for access!</span>
          </h4>
        </div>
      );
    case loginTypes.RESET_PASSWORD_CHANGE:
      return (
        <div className="d-flex flex-column gap-3 mt-4">
          <button
            className={`w-100 rounded-3 text-uppercase btn btn-primary`}
            type="button"
            aria-label="Submit"
            onClick={resetPassword}
          >
            Submit
          </button>
          <h4
            className={`col-12 text-center py-3 text-${contrastClass} cursorPointer`}
            onClick={() => {
              handleScreenChange(loginTypes.REGISTRATION);
            }}
          >
            New to CLIRNET?{' '}
            <span className="text-secondary hover-primary">Request for access!</span>
          </h4>
        </div>
      );
    default:
      return null;
  }
}

function RestrictedModal({ showRestrictedModal, setShowRestrictedModal, gtmTagVariable }) {
  return (
    <Modal
      className="centerModal"
      size="md"
      centered
      show={showRestrictedModal}
      onHide={() => setShowRestrictedModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="position-relative p-4 border-0 mb-1">
        <Modal.Title className="fs-16 text-black fw-semibold">Please Note</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <form>
          <div className="w-100 centerModalFrm">
            <div className="row mt-n4">
              <div className="w-100 text-center fw-medium fs-4 my-2 text-black">
                Your request has been recorded. You shall hear from us shortly.
              </div>
              <div className="w-100 text-center fw-medium fs-4 my-2 text-black">
                *Direct access is restricted in order to allow only Registered Healthcare
                Professionals to view the content
              </div>
              <div className="mt-4 d-flex flex-wrap">
                <button
                  className={`col-12 fs-16 btn btn-primary py-3 px-4 ${gtmTagVariable}_knowMore`}
                  type="button"
                  onClick={() => {
                    window.location.href = ' https://www.clirnet.com/newly-featured';
                  }}
                  aria-label="Know More"
                >
                  Know More
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
