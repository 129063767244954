import React, { memo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useGetISDcodes } from '../Queries/QueryHooks/isdcodes.hook';

function ISDPicker({ className, onChange, defaultValue = null, name }) {
  function onSuccess(res) {
    if (Array.isArray(res)) {
      onChange({
        target: {
          name: name ? name : 'isd_code',
          value: res.find((item) => item.status == 1)?.value
        }
      });
    }
  }
  const { data: isdCodes = [] } = useGetISDcodes(defaultValue, onSuccess);

  useEffect(() => {
    if (Array.isArray(isdCodes)) {
      onChange({
        target: {
          name: name ? name : 'isd_code',
          value: isdCodes.find((item) => item.status == 1)?.value
        }
      });
    }
  }, [isdCodes]);

  return (
    <Form.Select
      className={className}
      onChange={(e) => onChange(e)}
      name={name ? name : 'isd_code'}
    >
      {isdCodes?.length > 0 &&
        isdCodes.map((item, index) => (
          <option
            key={index + 1}
            name={item.name}
            value={item.value}
            selected={item.status === 1 ? true : false}
          >
            + {item.value}
          </option>
        ))}
    </Form.Select>
  );
}

export default memo(ISDPicker);
