import React, { memo, useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';

const RightContainer = (props) => {
  const [eleHeight, setEleHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const rightSidebarRef = useRef(null);

  useEffect(() => {
    if (document.getElementById('headerDesktopNavBar')) {
      setEleHeight(document.getElementById('headerDesktopNavBar').offsetHeight);
    }
    // updatePosition();
  }, []);

  useEffect(() => {
    function updatePosition() {
      if (!isSticky) {
        setScrollHeight({ scrollX: window.scrollX, scrollY: window.scrollY });
      }
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();

    return () => window.removeEventListener('scroll', updatePosition);
  }, [isSticky]);

  return (
    <div
      className={`col-lg-3 px-4 d-lg-flex flex-column gap-4 d-none clr_v3_rightSideBar ${
        props.className ? props.className : ''
      } ${isSticky ? 'position-sticky' : ''}`}
      style={{ top: `${eleHeight - scrollHeight.scrollY}px` }}
      ref={rightSidebarRef}
    >
      {props.children}
      <InView as="div" onChange={(inView, entry) => setIsSticky(inView)}>
        <div></div>
      </InView>
    </div>
  );
};

export default memo(RightContainer);
