import { shareConstants } from '../constants/shareConstants';

var initialState = {
  UserRegion: '',
  details: {},
  relatedData: [],
  trainingModuleList: [],
  verfication: 0
};

export const shareReducer = (state = initialState, action) => {
  switch (action.type) {
    case shareConstants.GET_CLINICAL_VIDEOS_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.archived_video,
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_CLINICAL_VIDEOS_SHARE_FALIURE:
    case shareConstants.GET_MEDWIKI_SHARE_FALIURE:
    case shareConstants.GET_SESSION_SHARE_FALIURE:
    case shareConstants.GET_GR_SHARE_FALIURE:
    case shareConstants.GET_SPQ_SHARE_FALIURE:
    case shareConstants.GET_EBOOK_SHARE_FALIURE:
    case shareConstants.GET_CHANNEL_SHARE_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };

    case shareConstants.GET_MEDWIKI_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action?.payload?.data?.comp,
        relatedData: action?.payload?.data?.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_SESSION_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details:
          'session' in action.payload.data && action.payload.data?.session.length > 0
            ? action.payload.data?.session[0]
            : {},
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_GR_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.gr[0],
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_SPQ_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.survey,
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_EBOOK_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.epub[0],
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };
    case shareConstants.GET_CHANNEL_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.channel[0],
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };
    case shareConstants.GET_OBSERVERSHIP_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.observer,
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_OBSERVERSHIP_MENTOR_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: { ...state.details, mentor: action.payload.data.mentor },
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_MENTOR_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.mentor,
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_TRAINING_SHARE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.training,
        relatedData: action.payload.data.related,
        verfication: action.payload.data.verfication ?? 0
      };

    case shareConstants.GET_TRAINING_MODULE_LIST_SUCCESS: {
      return {
        ...state,
        trainingModuleList: action.payload.data
      };
    }

    case 'SAVE_UTM':
      return {
        ...state,
        utmSource: action.payload
      };

    case 'GET_USER_REGION':
      return {
        ...state,
        UserRegion: action.payload.data.user_env
      };

      return {
        ...state,
        shareData: action.payload
      };
    default:
      return state;
  }
};
