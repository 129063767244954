import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

function CountDown(props) {
  const calculateTimeLeft = () => {
    const datetime = dayjs(props.date).diff(dayjs(new Date()));

    let timeLeft = {};

    if (datetime > 0) {
      timeLeft = {
        days: Math.floor(datetime / (1000 * 60 * 60 * 24)),
        hours: Math.floor((datetime / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((datetime / 1000 / 60) % 60),
        seconds: Math.floor((datetime / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
    return () => setTimeLeft({});
    
  },[]);

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
  });

  return (
    <>
      <div className="shareSsnTimerBox fw-normal fs-5 text-white py-4 px-20 position-relative">
        <span className="fw-light fs-36 d-block lh-1">
          {timeLeft.days < 9 ? '0' + timeLeft.days : timeLeft.days}
        </span>
        Days
      </div>
      <div className="shareSsnTimerBox fw-normal fs-5 text-white py-4 px-20 position-relative">
        <span className="fw-light fs-36 d-block lh-1">
          {timeLeft.hours < 9 ? '0' + timeLeft.hours : timeLeft.hours}
        </span>
        Hours
      </div>
      <div className="shareSsnTimerBox fw-normal fs-5 text-white py-4 px-20 position-relative">
        <span className="fw-light fs-36 d-block lh-1">
          {timeLeft.minutes < 9 ? '0' + timeLeft.minutes : timeLeft.minutes}
        </span>
        Minutes
      </div>
      <div className="shareSsnTimerBox fw-normal fs-5 text-white py-4 px-20 position-relative">
        <span className="fw-light fs-36 d-block lh-1">
          {timeLeft.seconds < 9 ? '0' + timeLeft.seconds : timeLeft.seconds}
        </span>
        Seconds
      </div>
    </>
  );
}

export default CountDown;

// 2021-12-22 13:42:30
