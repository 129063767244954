import React from 'react';
import LazyImage from '../../../../components/CustomLibraries/LazyImage';
import { Badge, Button } from 'react-bootstrap';
import '../../../../assets/sass/uiV3/pages/share/_observershipHeroCard.scss';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import { DEMO_USER_PICTURE } from '../../../../common/common';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../../components/tracking/constants';
function ObservershipHeroCard({ data }) {
  console.log('observership card data', data);
  const startMonth = dayjs(data?.start_month).format('MMM');
  const endMonth = dayjs(data?.start_month).add(parseInt(data?.duration), 'month').format('MMM');
  const year = dayjs(data?.start_month).format('YYYY');
  return (
    <>
      <TrackerWrapper type={CARD_VIEW} data={data} className="d-flex rounded-4 bg-white p-4 p-sm-20 gap-4 gap-md-20 flex-sm-row flex-column position-relative overflow-hidden clr_v3_cornerGraphicsDynamic observershipShare_hero">
 
          {/* graphics section start */}
          <div className="position-absolute top-0 start-0 w-100 h-100 z-0 clr_v3_cornerGraphicsDynamic__Graphics z-0">
            {/* <img className='w-100 h-100 object-fit-cover' /> */}

            <div
              className="position-absolute graphics-size-lg top right bg-primary transition clr_v3_cornerGraphicsDynamic__Graphics__corner--pill"
              style={{ opacity: 0.2 }}
            ></div>
            <div
              className="position-absolute rounded-circle graphics-size-lg bottom left bg-primary transition"
              style={isMobile ? { opacity: 0.12 } : { opacity: 0.2 }}
            ></div>
          </div>
          {/* graphics section end */}
          <div className=" position-relative ps-20 pt-20">
            <div className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 z-1 p-4">
              <svg
                className="w-100 position-absolute top-50 start-50 translate-middle observershipShare_hero__pollygon"
                width="88"
                height="91"
                viewBox="0 0 88 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M47.365 3.46793L49.4047 6.0027C51.1465 8.16735 54.1467 8.85296 56.6559 7.65979L59.5782 6.27017C61.8818 5.17475 64.6084 6.48635 65.1904 8.96987L65.9444 12.1872C66.5766 14.8847 68.9735 16.7985 71.744 16.818L75.0088 16.8409C77.5523 16.8587 79.4334 19.2149 78.8875 21.6993L78.1685 24.9715C77.5759 27.668 78.8999 30.4208 81.3764 31.6412L84.353 33.1081C86.6294 34.2299 87.2991 37.1608 85.7358 39.16L83.6683 41.8041C81.9703 43.9755 81.9703 47.0245 83.6683 49.1959L85.7358 51.84C87.2991 53.8392 86.6294 56.7701 84.353 57.8919L81.3764 59.3588C78.8999 60.5792 77.5759 63.332 78.1685 66.0285L78.8875 69.3007C79.4334 71.7851 77.5523 74.1413 75.0088 74.1591L71.744 74.182C68.9735 74.2014 66.5766 76.1153 65.9444 78.8128L65.1904 82.0301C64.6084 84.5136 61.8818 85.8252 59.5782 84.7298L56.6559 83.3402C54.1467 82.147 51.1465 82.8326 49.4047 84.9973L47.365 87.5321C45.7639 89.5219 42.7335 89.5219 41.1323 87.5321L39.0927 84.9973C37.3508 82.8326 34.3507 82.147 31.8415 83.3402L28.9192 84.7298C26.6155 85.8252 23.8889 84.5136 23.3069 82.0301L22.5529 78.8128C21.9208 76.1153 19.5238 74.2014 16.7533 74.182L13.4886 74.1591C10.945 74.1413 9.06393 71.7851 9.60985 69.3007L10.3289 66.0285C10.9214 63.332 9.59743 60.5792 7.12099 59.3588L4.14438 57.8919C1.86794 56.7701 1.19825 53.8392 2.76155 51.84L4.82909 49.1959C6.52706 47.0245 6.52706 43.9755 4.82909 41.8041L2.76155 39.16C1.19825 37.1608 1.86794 34.2299 4.14438 33.1081L7.12099 31.6412C9.59743 30.4208 10.9214 27.668 10.3289 24.9715L9.60985 21.6993C9.06393 19.2149 10.945 16.8587 13.4886 16.8409L16.7533 16.818C19.5238 16.7985 21.9208 14.8847 22.5529 12.1872L23.3069 8.96987C23.8889 6.48635 26.6155 5.17475 28.9192 6.27017L31.8415 7.65979C34.3507 8.85296 37.3508 8.16735 39.0927 6.0027L41.1323 3.46793C42.7335 1.47813 45.7639 1.47813 47.365 3.46793Z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
              <h5 className="fs-5 text-white text-center position-relative z-1 fw-semibold">
                <span className="d-block">
                  {startMonth}-{endMonth}
                </span>
                <span className="d-block">{year}</span>
              </h5>
            </div>
            <LazyImage
              src={data?.image || DEMO_USER_PICTURE}
              alt="profile"
              fallback_image={DEMO_USER_PICTURE}
              className={`object-fit-cover w-100 h-100 transition`}
              extraClass="position-relative hover-image overflow-hidden flex-shrink-0 observershipShare_hero__image"
            />
          </div>

          <div className="flex-grow-1 pt-md-20 d-flex flex-column gap-3 justify-content-center">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-4 align-items-center">
                <h5 className="fs-4 d-flex align-items-center gap-2 text-black fw-normal flex-shrink-0">
                  <i className="flaticon-focus text-primary"></i>{' '}
                  <span className="line-clamp-1">Observership</span>
                </h5>
                <Badge bg="soft-primary" className="fs-4 px-3 py-2 rounded-pill fw-medium">
                  {data?.is_paid == 1 ? 'Paid' : 'Unpaid'}
                </Badge>
              </div>
              {data?.state_name && data?.country_code && (
                <h5 className="fs-5 d-flex align-items-center gap-2 text-black fw-normal flex-shrink-0">
                  <i className="flaticon-pin text-primary"></i>{' '}
                  <span className="line-clamp-1">
                    {data?.state_name}, {data?.country_code}
                  </span>
                </h5>
              )}
            </div>
            {data?.title && (
              <h4 className={`fs-2 text-black line-clamp-1 fw-semibold`}>{data?.title}</h4>
            )}
            <div className="d-flex gap-4 align-items-center">
              {data?.specialities_name && (
                <Button variant="outline-primary" className="fs-5 px-3 py-2 rounded-2 fw-medium">
                  {data?.specialities_name}
                </Button>
              )}
              {data?.duration && (
                <Badge bg="soft-primary" className="rounded-pill fw-medium fs-5 py-2 lh-base px-3">
                  {data?.duration} Months
                </Badge>
              )}
            </div>
            <p>
              To apply for the program, please fill out the online application form. Ensure that you
              provide all necessary documents, including your resume, letter of intent, and any
              required certifications.
            </p>
            <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
              <div className="transition d-flex gap-3 align-items-center cursorPointer">
                <div className="transition flex-shrink-0 position-relative">
                  <div className="rounded-circle overflow-hidden d-flex align-items-center justify-content-center  icon-size-36 bg-white ">
                    <img
                      src={data?.mentor?.profile_image || DEMO_USER_PICTURE}
                      width="36"
                      height="36"
                      alt="image"
                      className="object-fit-cover w-100 h-100"
                    />
                  </div>
                </div>
                {data?.mentor?.first_name && data?.mentor?.last_name && (
                  <div className="w-100 d-flex flex-column transition flex-grow-1">
                    <h4 className="fs-4 text-black fw-semibold">
                      Dr. {data?.mentor?.first_name || ''} {data?.mentor?.last_name || ''}
                    </h4>
                  </div>
                )}
              </div>

              <Button
                className={`fw-medium py-3 text-nowrap d-flex align-items-center justify-content-center flex-shrink-0 ms-auto`}
                options={{ variant: 'primary' }}
              >
                Apply Now
              </Button>
            </div>
          </div>
    
      </TrackerWrapper>
    </>
  );
}

export default React.memo(ObservershipHeroCard);
