import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../../assets/sass/uiV3/pages/_specialityDefaultLayout.scss';
import HeaderProfileMobile from '../../components/baseUiV3/template/header/HeaderProfileMobile';
import HeaderSpecialityDetailsMobile from '../../components/baseUiV3/template/header/HeaderSpecialityDetailsMobile';
import HeaderSpecialityDesktop from '../../components/baseUiV3/template/header/HeaderSpecialityDesktop';
import { isMobile } from 'react-device-detect';
import { Row } from 'react-bootstrap';
import { routeNames } from '../../router/constants';
import { isACommendableRoute } from '../../common/common';
import { useSelector } from 'react-redux';
import HeaderSpecialityMobile from '../../components/baseUiV3/template/header/HeaderSpecialityMobile';
import MobileFooterNav from '../../components/baseUiV3/template/footer/MobileFooterNav';

function SpecialityDefaultLayout({ isNavOnlyHeader }) {
  const [elHeight, setElheight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);
  const commentsShowing = useSelector((state) => state.common.showComment);
  const getElementHeight = (data) => {
    // alert(data)
    setElheight(data);
  };

  const [navItem, setNavItem] = useState('All');
  const selectedNavItem = (data) => {
    setNavItem(data);
  };

  const { pathname } = useLocation();
  const pageName = pathname.slice(1).toLowerCase();

  const getElementBottomHeight = (data) => {
    // alert(data)
    setBottomHeight(data);
  };

  return (
    <>
      {isMobile ? (
        pageName == 'userProfile' ? (
          <HeaderProfileMobile getElementHeight={getElementHeight} />
        ) : isNavOnlyHeader ? (
          <HeaderSpecialityMobile
            // getElementHeight={getElementHeight}
            selectedNavItem={selectedNavItem}
          />
        ) : (
          <HeaderSpecialityDetailsMobile getElementHeight={getElementHeight} />
        )
      ) : (
        <HeaderSpecialityDesktop getElementHeight={getElementHeight} elHeight={elHeight} />
      )}
      <div
        className={`${
          isMobile ? 'mobile' : 'desktop'
        } speciality-portal speciality-portal--defaultLayout w-100 d-flex min-vh-100`}
        style={{
          paddingTop: `${!isNavOnlyHeader || !isMobile ? `${elHeight}` : ''}px`,
          paddingBottom: `${
            isACommendableRoute(pathname) && commentsShowing
              ? 75
              : isNavOnlyHeader
              ? `${bottomHeight}`
              : ''
          }px`
        }}
      >
        <div className="py-4 w-100 d-flex flex-column position-relative">
          <div className="container-fluid">
            <Row className="align-items-start flex-nowrap">
              <Outlet />
            </Row>
          </div>
        </div>
      </div>
      {isNavOnlyHeader ? (
        <MobileFooterNav
          getElementBottomHeight={getElementBottomHeight}
          // exploreMenuOpen={exploreMenuOpen}
          // exploreMenuToggler={exploreMenuToggler}
          height={elHeight}
        />
      ) : null}
    </>
  );
}

export default SpecialityDefaultLayout;
