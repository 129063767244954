import React, { memo, useState, useEffect, useRef } from 'react';

import { defaultFallbackInView } from 'react-intersection-observer';
import useImageLoaded from '../customHooks/useImageLoaded';
// let errorCounter = 0;
import useNetwork from '../customHooks/useNetwork';
const LazyImage = ({
  width = '100%',
  height = '100%',
  extraClass = '',
  src = '',
  alt,
  fallback_image = '',
  click,
  logo = false,
  ...rest
}) => {
  // console.log('Imaze specialityClass', extraClass);
  const connection = useNetwork();
  // console.log('netWork________', connection.effectiveType);
  defaultFallbackInView(true);

  const [image_ref, loaded, onImageLoad] = useImageLoaded();

  const image_container_ref = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [thumbSrc, setThumbSrc] = useState('');

  const [errorCounter, setErrorCounter] = useState(0);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  // useEffect(() => {
  //   if (src) {
  //     generateCfImageUrl(src);
  //   } else {
  //     setImageSrc(
  //       fallback_image ??
  //         'https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1902/pavelstasevich190200120/124934975-no-image-available-icon-vector-flat.jpg?ver=6'
  //     );
  //   }
  // }, [src]);

  useEffect(() => {
    if (src) {
      generateThumbUrl(src);
    } else {
      setThumbnailLoaded(true);
      setImageSrc(
        fallback_image ??
          'https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1902/pavelstasevich190200120/124934975-no-image-available-icon-vector-flat.jpg?ver=6'
      );
    }
    return () => {
      setImageSrc('');
      setThumbSrc('');
      setThumbnailLoaded(false);
      setErrorCounter(0);
    };
  }, [src]);

  function generateThumbUrl(url = '') {
    let transformation = '?tr=bl-10';

    // console.log('trsnformation....', elementOffsetWidth, elementOffsetHeight, transformation);
    if (url && typeof url == 'string') {
      const cfImageUrl = `${url}${transformation}`;
      // console.log('PATH_EXTRACTED_IMAGE', cfImageUrl);
      setThumbSrc(cfImageUrl);
    } else {
      setThumbSrc(url);
    }
  }

  function generateCfImageUrl(url = '') {
    let elementOffsetWidth = image_container_ref?.current?.offsetWidth;
    let elementOffsetHeight = image_container_ref?.current?.offsetHeight;

    switch (connection.effectiveType) {
      case '4g':
        elementOffsetHeight *= 3;
        elementOffsetWidth *= 3;
        break;
      case '3g':
        elementOffsetHeight *= 2;
        elementOffsetWidth *= 2;
        break;
      default:
        break;
    }
    let transformation =
      elementOffsetWidth > 0 || elementOffsetHeight > 0
        ? `?tr=${elementOffsetWidth > 0 && 'w-' + elementOffsetWidth}${
            elementOffsetHeight > 0 ? ',h-' + elementOffsetHeight : ',h-auto'
          },pr=true${logo ? `,c-at_max` : ''}`
        : '';

    if (url && typeof url == 'string') {
      const cfImageUrl = `${url}${transformation}`;

      setImageSrc(cfImageUrl);
    } else {
      setImageSrc(`${url}${transformation}`);
    }
  }

  const onErrorLoad = (e) => {
    if (errorCounter < 1) {
      // e.target.onerror = null;
      e.target.src = fallback_image
        ? fallback_image
        : 'https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1902/pavelstasevich190200120/124934975-no-image-available-icon-vector-flat.jpg?ver=6';
    }
    setErrorCounter((prev) => {
      return prev + 1;
    });
  };

  // const setRefs = useCallback(
  //   (node) => {
  //     // Ref's from useRef needs to have the node assigned to `current`
  //     ref.current = node;
  //     // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
  //     inViewRef(node);
  //   },
  //   [inViewRef]
  // );

  const lazyLoad = {
    opacity: '0',
    transition: 'all 1s ease-in-out'
  };

  const isVisible = {
    opacity: '1'
  };
  const onThumbnailLoad = () => {
    setThumbnailLoaded(true);
    generateCfImageUrl(src);
  };
  return (
    <div
      ref={image_container_ref}
      className={`${extraClass == '' ? 'w-100 h-100 position-relative' : extraClass}`}
      onClick={() => click && click()}
    >
      {(imageSrc || thumbSrc) && (
        <>
          {thumbSrc && !thumbnailLoaded && !imageSrc ? (
            <img
              {...rest}
              ref={image_ref}
              alt={alt}
              src={thumbSrc}
              onError={onErrorLoad}
              loading="lazy"
              onLoad={onThumbnailLoad}
            />
          ) : (
            <img
              {...rest}
              ref={image_ref}
              alt={alt}
              src={imageSrc}
              onError={onErrorLoad}
              loading="lazy"
              onLoad={onImageLoad}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(LazyImage);
