import React from 'react';
// import SessionCardV1 from '../../../components/cards/session/sessionCardV1/SessionCardV1';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';

import ShareLogin from '../../../common/LoginForAll/login/Login';
import { grand_rounds_static_image, surveys_static_image } from '../../../common/assets.common';
import checkForPremiumIcon from '../../../common/checkForPremiumIcon';
import { getSessionCategoryIcon, openNewTab } from '../../../common/common';
import Medwiki from '../../../components/baseUiV3/cards/ruleBasedCard/Medwiki';
import ClinicalVideoCard from '../../../components/baseUiV3/contentCards/ClinicalVideoCard';
import CmeCard from '../../../components/baseUiV3/contentCards/CmeCard';
import DoctorVoiceCard from '../../../components/baseUiV3/contentCards/DoctorVoiceCard';
import GrandRoundCard from '../../../components/baseUiV3/contentCards/GrandRoundCard';
import SpqCard from '../../../components/baseUiV3/contentCards/SpqCard';
import useGetParams from '../../../components/customHooks/useGetParams';
import { routeNames } from '../../../router/constants';
import RegistrationForm from '../../registration/RegistrationForm';
import ShareHeaderNew from '../shareHeader';
import AppBanner from './AppBanner';
import ClirnetOtherCard from './ClirnetOtherCard';
import Disclaimer from './disclaimer/Disclaimer';
import useRedirect from '../../../components/customHooks/useRedirect';
import RelatedCard from '../../../components/baseUiV3/contentCards/RelatedCard';
import { generateProps } from '../../../utils/generateProps';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';
import { CARD_CLICK } from '../../../components/tracking/constants';

let path = '';
function NewRelatedCardsArea({
  relatedCardsData,
  paramData,
  setBackdrop,
  pageName,
  isLogin,
  isReg,
  params,
  excludeId = '',
  shareDesign,
  disclaimer,
  type,
  hideDisclaimer = false
}) {
  const trackerCardClick = (details) => {
    trackActivityEvent(CARD_CLICK, details);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { redirect_type, id } = useGetParams();
  const { redirectTo } = useRedirect();
  console.log('redirect_type', redirect_type);
  const { trackActivityEvent } = useTracker();
  const { from } = location.state || { from: { pathname: '/dashboard' } };
  const { contentAccessRedirect } = useContentAccessRedirect();

  const redirect = () => {
    openNewTab('https://www.clirnet.com/company');
  };

  const showCardsData = (trending_type, details) => {
    switch (trending_type) {
      case 'session':
        if (details.session_id != excludeId) {
          return (
            <div
              className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 clr_v3_masonry_card"
              key="session_key"
            >
              {/* <CmeCard
                // hideImage={true}
                sponsorLogo={details?.sponsor_logo}
                startDateTime={details?.start_datetime}
                sessionStatus={details?.session_status}
                endDateTime={details?.end_datetime}
                id={details?.type_id}
                title={details?.session_topic}
                specialities={details?.specialities}
                deeplink={details?.deeplink}
                moduleType={details?.type}
                image={details?.cover_image}
                likeCount={details?.rating}
                participantCount={details?.participant_count}
                viewCount={details?.view_count}
                masterCast={details?.ms_cat_name}
                icon={getSessionCategoryIcon(details?.ms_cat_name)}
                type={''}
                specialities_ids_and_names={details?.specialities_ids_and_names}
                cpdDetails={details?.cpddetail}
                premiumContent={details?.is_locked}
                displayIcon={checkForPremiumIcon(details?.is_locked)}
                price={details?.price}
                userContentPayment={details?.user_content_payment}
                click={() => {
                  redirectTo(routeNames.share.session, details.type_id || details.session_id);
                  trackerCardClick(details);
                }}
                onGetRecClick={() => navigate(`/share/session/${details.type_id}`)}
                isShare={details?.is_share}
              /> */}
              <RelatedCard
                data={details}
                pageId={id}
                cardType="lg"
                {...generateProps(details, contentAccessRedirect)}
              />
            </div>
          );
        }
        break;
      case 'survey':
        switch (details.category) {
          case 'survey':
          case 'poll':
          case 'quiz':
            if (details.survey_id != excludeId) {
              return (
                <div
                  className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 clr_v3_masonry_card"
                  key="survey_key"
                >
                  {/* <SpqCard
                    //   hideImage={true}
                    sponsorLogo={details?.sponsor_logo}
                    id={details?.survey_id}
                    title={details?.survey_title}
                    specialities={details?.specialities_name}
                    deeplink={details?.deeplink}
                    likeCount={details?.rating}
                    moduleType={details?.type}
                    image={details?.image}
                    commentCount={details?.comment_count}
                    viewCount={details?.view_count}
                    category={details?.category || ''}
                    participantCount={details?.user_participant_count}
                    specialities_ids_and_names={details?.specialities_ids_and_names}
                    contentType="SPQ"
                    description={details?.survey_description}
                    icon="flaticon-polls"
                    // fallback_image={surveys_static_image}
                    // click={() => redirectTo(routeNames.spq.detail, data?.survey_id, data?.survey_title)}
                    questionCount={details?.question_count}
                    surveyTime={details?.survey_time}
                    point={details?.point}
                    serviceType="spq"
                    premiumContent={details?.is_locked}
                    displayIcon={checkForPremiumIcon(details?.is_locked)}
                    price={details?.price}
                    userContentPayment={details?.user_content_payment}
                    click={() => {
                      redirectTo(routeNames.share.quiz, details.survey_id);
                      // trackerCardClick(details.type_id, 'survey');
                    }}
                    isShare={details?.is_share}
                  /> */}
                  <RelatedCard
                    data={details}
                    pageId={id}
                    cardType="lg"
                    {...generateProps(details, contentAccessRedirect)}
                  />
                </div>
              );
            }
            break;
          default:
            return;
        }
      case 'gr':
      case 'comp':
      case 'video_archive':
        if (details.type_id != excludeId) {
          return (
            <div
              className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 clr_v3_masonry_card"
              key="gr_key"
            >
              {/* <GrandRoundCard
                //   hideImage={true}
                sponsorLogo={details?.sponsor_logo}
                id={details?.type_id}
                title={details?.title}
                specialities={details?.specialities}
                deeplink={details?.deeplink}
                moduleType={details?.type}
                likeCount={details?.rating}
                image={details?.image}
                commentCount={details?.comment_count}
                viewCount={details?.view_count}
                specialities_ids_and_names={details?.specialities_ids_and_names}
                // type={isMobile ? '' : cardType}
                contentType="Grand Rounds"
                description={details?.description}
                icon="flaticon-grandround"
                fallback_image={grand_rounds_static_image}
                // click={() => redirectTo(routeNames.grandRound.detail, data?.type_id, data?.title)}
                serviceType="gr"
                premiumContent={details?.is_locked}
                displayIcon={checkForPremiumIcon(details?.is_locked)}
                price={details?.price}
                userContentPayment={details?.user_content_payment}
                click={() => {
                  redirectTo(routeNames.share.gr, details.type_id);
                  trackerCardClick(details.type_id, 'Gr');
                }}
                isShare={details?.is_share}
              /> */}
              <RelatedCard
                data={details}
                pageId={id}
                cardType="lg"
                {...generateProps(details, contentAccessRedirect)}
              />
            </div>
          );
        }
        break;
      default:
        return;
    }
  };

  const showCards = (data, index) => {
    if (index == 0 && isMobile && isLogin) {
      return (
        <>
          {isMobile && (
            <div className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0 loginPageFrmCard">
              <AppBanner />
            </div>
          )}
          <div
            className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card"
            key={index}
          >
            {/* col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 clr_v3_masonry_card loginPageFrmCard */}
            <ShareLogin
              hideImage={true}
              redirectType={routeNames.dashboard.landing}
              to={from}
              setBackdrop={setBackdrop}
              pageName={pageName}
              paramData={paramData}
              type={type}
            />
          </div>
        </>
      );
    } else if (index == 2 && !isMobile && isLogin) {
      return (
        <div
          className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card"
          key={index}
        >
          <ShareLogin
            hideImage={true}
            redirectType={routeNames.dashboard.landing}
            to={from}
            setBackdrop={setBackdrop}
            pageName={pageName}
            paramData={paramData}
            type={type}
          />
        </div>
      );
    } else if (((index == 0 && isMobile) || (index == 2 && !isMobile)) && isReg) {
      return (
        <>
          {isMobile && (
            <div className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0 loginPageFrmCard">
              <AppBanner />
            </div>
          )}
          <div
            className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card"
            key={index}
          >
            <RegistrationForm
              redirectType={redirect_type || routeNames.dashboard.landing}
              hideImage={true}
              pageName={pageName}
              params={params}
              isReg={true}
              to={from}
            />
          </div>
        </>
      );
    } else if (
      ((index == 7 && !isMobile) || (index == 2 && isMobile && isLogin)) &&
      !hideDisclaimer
    ) {
      return (
        <div
          className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card"
          key={index}
        >
          <Disclaimer Disclaimer={disclaimer} />
        </div>
      );
    }
    // else if (index == 2 && isMobile && isLogin) {
    //   return (
    //     <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card">
    //       <Disclaimer Disclaimer={disclaimer} />
    //     </div>
    //   );
    // }
    else if (((index == 7 && !isMobile) || (index == 2 && isMobile && isReg)) && !hideDisclaimer) {
      return (
        <div
          className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card"
          key={index}
        >
          <Disclaimer Disclaimer="The information in this educational activity is provided for general medical education purposes meant for registered medical practitioners only. The activity is not meant to substitute for the independent medical judgment of a physician relative to diagnostic and treatment options of a specific patient’s medical condition. The viewpoints expressed in this CME activity are those of the authors/faculty. They do not represent an endorsement by CLIRNET or the Sponsor. In no event will CLIRNET, the Sponsor or, the authors/faculty be liable for any decision made or action taken in reliance upon the information provided through this CME activity. All CMEs are recorded to be used for research & information purposes only. Clirnet at the request of Sponsor, may share your details such as name, location, recording of the session and session feedback for information purposes only." />
        </div>
      );
    }
    // else if (index == 2 && isMobile && isReg) {
    //   return (
    //     <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card">
    //       <Disclaimer Disclaimer="The information in this educational activity is provided for general medical education purposes meant for registered medical practitioners only. The activity is not meant to substitute for the independent medical judgment of a physician relative to diagnostic and treatment options of a specific patient’s medical condition. The viewpoints expressed in this CME activity are those of the authors/faculty. They do not represent an endorsement by CLIRNET or the Sponsor. In no event will CLIRNET, the Sponsor or, the authors/faculty be liable for any decision made or action taken in reliance upon the information provided through this CME activity. All CMEs are recorded to be used for research & information purposes only. Clirnet at the request of Sponsor, may share your details such as name, location, recording of the session and session feedback for information purposes only." />
    //     </div>
    //   );
    // }
    else if (index == 1 && isMobile && (isLogin || isReg)) {
      return (
        <div
          className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card"
          key={index}
        >
          <ShareHeaderNew loginPage={true} />
        </div>
      );
    }
    // else if (index == 1 && isMobile && isReg) {
    //   return (
    //     <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card">
    //       <ShareHeaderNew loginPage={true} />
    //     </div>
    //   );
    // }
    // else if (
    //   (index == 5 && isMobile && (isLogin || isReg)) ||
    //   (index == 3 && !isMobile && isReg) ||
    //   (index == 6 && !isMobile && shareDesign) ||
    //   (index == 4 && isMobile && shareDesign)
    // ) {
    //   return <ClirnetOtherCard redirect={redirect} key={index} />;
    // }
    // else if (index == 3 && !isMobile && isReg) {
    //   return <ClirnetOtherCard redirect={redirect} />;
    // } else if (index == 6 && !isMobile && shareDesign) {
    //   return <ClirnetOtherCard redirect={redirect} />;
    // } else if (index == 4 && isMobile && shareDesign) {
    //   return <ClirnetOtherCard redirect={redirect} />;
    // }
    else {
      return showCardsData(data.trending_type ? data.trending_type : data.type, data);
    }
  };

  return (
    <>
      {relatedCardsData &&
        relatedCardsData?.map((_data, index) => {
          return showCards(_data, index);
        })}

      <ClirnetOtherCard redirect={redirect} />
    </>
  );
}

export default React.memo(NewRelatedCardsArea);
