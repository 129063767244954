import React, { memo } from 'react';
import useRedirect from '../customHooks/useRedirect';
import LazyImage from '../CustomLibraries/LazyImage';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
// import DoctorCircleCards from '../doctorCircles/DoctorCircleCards';
import './css/doctorSectionForCard.scss';
import { routeNames } from '../../router/constants';

const AuthorSection = ({ cardData }) => {
  const { redirectTo } = useRedirect();

  return (
    <>
      {cardData?.author_entities.length > 0 && (
        <div className="w-100 cmncrdV1Doctor text-start ps-3 ps-md-4 cursorPointer">
          {/* if single doctor ====================*/}
          {cardData?.author_entities.length == 1 && (
            <div
              className="crdV1DoctorBox text-start w-auto"
              onClick={() => {
                if (cardData?.author_entities[0]?.session_doctor_id) {
                  redirectTo(
                    routeNames.sessions.speaker,
                    cardData?.author_entities[0]?.session_doctor_id,
                    cardData?.author_entities[0]?.author_name
                  );
                }
              }}
            >
              <div className="w-100 rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center py-2 px-3 border border-light">
                <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden">
                  <LazyImage
                    src={cardData?.author_entities[0]?.author_image}
                    alt={cardData?.author_entities[0]?.author_name}
                    className="object-fit-cover w-100 h-100 float-start"
                    fallback_image={'https://assets.clirnet.com/common/defaultdr.png'}
                    logo
                  />
                </div>
                <div className="crdV1DoctorContent ps-4">
                  <h4 className="text-black fs-4 line-clamp-1">
                    {cardData?.author_entities[0]?.author_name}
                  </h4>
                  <p className="text-dark fs-4 line-clamp-1">
                    {cardData?.author_entities[0]?.author_description}
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* if single doctor ====================*/}

          {/* if multi doctor ===========*/}
          {cardData?.author_entities?.length > 1 && <DoctorSwiper cardData={cardData} />}
          {/* if multi doctor =============*/}
        </div>
      )}
    </>
  );
};

export default AuthorSection;

const DoctorSwiper = memo(({ cardData }) => {
  return (
    <Swiper spaceBetween={6} slidesPerView={1} loop={false}>
      {cardData?.author_entities?.map((_doc, _idx) => (
        <SwiperSlide className="crdV1DoctorBox text-start text-start w-auto" key={_idx + 1}>
          <div className="w-100 rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center py-2 px-3 border border-light">
            <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden">
              <LazyImage
                src={_doc?.author_image}
                alt={_doc?.author_name}
                className="object-fit-cover w-100 h-100 float-start"
                fallback_image={'https://assets.clirnet.com/common/defaultdr.png'}
                logo
              />
            </div>
            <div className="crdV1DoctorContent ps-4">
              <h4 className="text-black fs-4 line-clamp-1">{_doc?.author_name}</h4>
              <p className="text-dark fs-4 line-clamp-1">{_doc?.author_description}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
});
