// import Color, { Palette } from 'color-thief-react';
import React, { memo } from 'react';
import { reactHtmlParser, specialityPopOver } from '../../../common/common';
import SponsorLogoSwiper from './SponsorLogoSwiper';
// import ReactHtmlParser from 'react-html-parser';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { medwiki_static_image } from '../../../common/assets.common';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
import { useDispatch } from 'react-redux';
import { toggleReference } from '../../../Store/actions';
const MedwikiHeroCard = ({ data, channel_name, channel_logo }) => {
  console.log(data, 'data');
  const { image, specialities, question, answer, sponsor_logo } = data;

  const dispatch = useDispatch();

  function onReadMoreClick() {
    dispatch(toggleReference(true));
  }
  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={data}
      className="w-100 rounded-3 shadow overflow-hidden bg-white shareLoginV2Featured shareLoginV2Medwiki p-3 p-md-4 position-relative z-1"
    >
      <div className="shareLoginV2FeaturedIn d-flex flex-column flex-sm-row align-items-center">
        <div
          className="shareLoginV2FeaturedCircle position-absolute rounded-circle"
          style={{ backgroundColor: '#355fd3' }}
        ></div>
        <div className="shareLoginV2FeaturedPic position-relative h-auto pe-4 pb-4">
          <div className="shareLoginV2FeaturedPicIn rounded-3 position-relative overflow-hidden z-1">
            <LazyImage
              src={image ?? medwiki_static_image}
              className="object-fit-cover w-100 h-100 float-start"
              fallback_image={medwiki_static_image}
            />
          </div>
        </div>
        <div className="shareLoginV2FeaturedRight text-start ps-0 ps-md-20 mt-4 mt-md-0">
          {specialities && (
            <span className="text-black d-block mb-2 d-flex align-items-center gap-2">
              <span className="fs-4 cursorPointer">{specialities?.split(',')[0]}</span>
              {specialityPopOver(specialities)}
            </span>
          )}
          <h2 className="fs-16 fw-medium text-black mb-3">{question}</h2>
          {answer && (
            <h4 className=" fs-4 fw-normal text-dark">
              {reactHtmlParser(answer)?.substring(0, 150)}
              <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                {' '}
                ...Read More
              </a>
            </h4>
          )}
          <div className="d-flex justify-content-between mt-4 gap-3">
            {channel_logo && (
              <div className="shareLogineV2Doctor">
                <div className="shareLoginV2FeaturedChannel d-flex align-items-center position-relative">
                  <div className="rounded-circle shareLoginV2ChanlPic d-flex align-items-center justify-content-center bg-light position-absolute overflow-hidden top-0 start-0">
                    <LazyImage
                      src={channel_logo}
                      alt="Profile Image"
                      className="mw-100 mh-100 w-auto h-auto"
                      extraClass="d-flex align-items-center justify-content-center"
                      logo
                    />{' '}
                  </div>

                  <span className="d-block fs-4 text-black">{channel_name}</span>
                </div>
              </div>
            )}
            {sponsor_logo && (
              <SponsorLogoSwiper sponsorLogo={sponsor_logo ? sponsor_logo.split(',') : []} />
            )}
          </div>
        </div>
      </div>
    </TrackerWrapper>
  );
};

export default memo(MedwikiHeroCard);
