export const demoJson = {
  status_code: 200,
  message: 'Success',
  timestamp_added: 1654347763,
  data: {
    user: [
      {
        field_name: 'name',
        type: 'text',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'phone',
        type: 'text',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'medical_council',
        type: 'text',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'registration_no',
        type: 'text',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'age',
        type: 'number',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'gender',
        type: 'radio',
        is_mandatory: '1',
        options: 'male,female',
        visibility: '3'
      },
      {
        field_name: 'location',
        type: 'text',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'usertype',
        type: 'radio',
        is_mandatory: '1',
        options: 'student,doctor',
        visibility: '3'
      }
    ],
    others: [
      {
        field_name: 'why_you_want_to_join_our_channel?',
        type: 'text',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'what_is_your_opinion?',
        type: 'text',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'which_speaker_do_you_have_interest?',
        type: 'textarea',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'are_you_available_for_our_live_sessions?',
        type: 'textarea',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'demo dropdown',
        type: 'checkbox',
        is_mandatory: '0',
        options: 'option1,option2',
        visibility: '3'
      },
      {
        field_name: 'usertype demo other question',
        type: 'fileupload',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      },
      {
        field_name: 'usertype demo other question 2',
        type: 'fileupload',
        is_mandatory: '1',
        options: '',
        visibility: '3'
      }
    ]
  }
};

export const returnLabelName = (field) => {
  if (field == 'name') {
    return 'Please Enter Your Name';
  } else if (field == 'medical_council') {
    return 'Please Enter Your Medical Council';
  } else if (field == 'registration_no') {
    return 'Please Enter Your Registration No';
  } else if (field == 'age') {
    return 'Please Enter Your Age';
  } else if (field == 'gender') {
    return 'Please Select Your Gender';
  } else if (field == 'location') {
    return 'Please Enter Your Location';
  } else if (field == 'usertype') {
    return 'Please Select Your User Type';
  }
};
