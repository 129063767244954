import Compressor from 'compressorjs';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { jwtDecode } from 'jwt-decode';
import ls from 'localstorage-slim';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  browserName,
  fullBrowserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from 'react-device-detect';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import packageJson from '../../package.json';
import { routeNames } from '../router/constants';
import {
  certified_courses_static_image,
  clinical_Video_static_image,
  grand_rounds_static_image,
  medwiki_static_image,
  quizzes_static_image
} from './assets.common';

const appVersion = packageJson.version;
const prodMode = process.env.NODE_ENV === 'production';
export const makeFirstLetterCapital = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function generateUUID() {
  var d = new Date().getTime();
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  var uuid = 'xxx-xxx-xxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      d = Math.floor(d / 16);
      r = (d + r) % 16 | 0;
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == 'x' ? r : (r & 7) | 8).toString(16);
  });
  return uuid;
}

export const sendPageViewToGa = (_page, _title, _location) => {
  ReactGA.send({
    hitType: 'pageview',
    page: _page,
    title: _title,
    location: _location
  });
};
export const isExpired = (accessToken) => {
  if (accessToken) {
    let access = jwtDecode(accessToken);
    let isExpired = dayjs.unix(access.exp).diff(dayjs()) < 1;
    return isExpired;
  } else {
    return true;
  }
};
export const setLocalStorage = (key, value) => {
  const isEncrypted = localStorage.getItem('encrypted') ? localStorage.getItem('encrypted') : false;
  console.log('isEncrypted', isEncrypted);
  if (isEncrypted === 'true') {
    if (ls) {
      ls.set(
        `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`,
        typeof value == 'object' ? JSON.stringify(value) : value,
        { encrypt: true }
      );
    }
  } else {
    localStorage.setItem(
      `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`,
      typeof value == 'object' ? JSON.stringify(value) : value
    );
  }
};
// export const isApp = () => (getLocalStorage('from_app', false) ? true : false);
export const removeLocalStorage = (key) => {
  localStorage.removeItem(`${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`);
};

export const getLocalStorage = (key, defaultValue = '') => {
  const isEncrypted = localStorage.getItem('encrypted') ? localStorage.getItem('encrypted') : false;
  var local_data;
  if (isEncrypted === 'true') {
    local_data = ls.get(`${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`, {
      decrypt: true
    });
  } else {
    local_data = localStorage.getItem(
      `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`
    );
  }
  if (local_data) {
    try {
      let parsed_data = JSON.parse(local_data);
      if (parsed_data) {
        return parsed_data;
      }
    } catch (error) {
      // console.log('===>>>error', error);
      return local_data;
    }
  } else {
    return defaultValue;
  }
  // }
};

export const sanitizeLocalStorage = () => {
  console.log('sanitization called!!!');
  const isEncrypted = localStorage.getItem('encrypted') ? localStorage.getItem('encrypted') : false;
  if (isEncrypted != 'true') {
    const oldKeys = Object.keys(localStorage);
    var i = oldKeys.length;
    while (i--) {
      ls.set(
        `${oldKeys[i]}`,
        typeof value == 'object'
          ? JSON.stringify(localStorage.getItem(oldKeys[i]))
          : localStorage.getItem(oldKeys[i]),
        { encrypt: true }
      );
    }
    localStorage.setItem('encrypted', true);
  }
};

export const setSessionStorage = (key, value) => {
  if (sessionStorage) {
    sessionStorage.setItem(
      `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`,
      value
    );
  }
};
export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(`${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`);
};
export const getSessionStorage = (key, defaultValue) => {
  let local_data = sessionStorage.getItem(
    `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`
  );
  if (local_data) {
    return local_data;
  } else {
    return defaultValue;
  }
};

export const log = (msg = 'clirnet:log:', value = 'noop') => {
  console.log(`====>>>${msg}`, value);
};
export const specialityPopOver = (val, customClass = 'text-primary fs-2 cursorPointer ms-2') => {
  let speciality_array = val ? val?.split?.(',') : [];
  if (speciality_array?.length > 0) {
    speciality_array.shift();
  }

  return (
    <>
      {val && val?.split(',').length > 1 ? (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-right" className="fs-5">
              {val
                ?.split(',')
                ?.filter((_, _i) => _i != 0)
                ?.join(', ')}
            </Tooltip>
          }
        >
          <span className="d-flex">
            <i className={`flaticon-more ${customClass}`}></i>
          </span>
        </OverlayTrigger>
      ) : null}
    </>
  );
};
export const specialityPopOverFromArray = (
  array,
  customClass = 'text-primary fs-2 cursorPointer ms-2'
) => {
  // let speciality_array = val ? val?.split?.(',') : [];
  // if (speciality_array?.length > 0) {
  //   speciality_array.shift();
  // }
  let val = array?.map((_sp) => _sp.name)?.join(',');
  return (
    <>
      {val && val?.split(',').length > 1 ? (
        <OverlayTrigger
          placement="right"
          rootClose={true}
          trigger={isMobile ? 'click' : 'hover'}
          // show={false}
          overlay={
            <Tooltip id="tooltip-right" className="fs-5">
              {val
                ?.split(',')
                ?.filter((_, _i) => _i != 0)
                ?.join(', ')}
            </Tooltip>
          }
        >
          <span className="d-flex">
            <i className={`flaticon-more ${customClass}`}></i>
            {/* <svg className={customClass} strokeWidth="0" viewBox="0 0 16 16">
              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
            </svg> */}
          </span>
        </OverlayTrigger>
      ) : null}
    </>
  );
};

export class Countdown {
  constructor(options) {
    var timer,
      instance = this,
      seconds = options.seconds || 10,
      updateStatus = options.onUpdateStatus || function () {},
      counterEnd = options.onCounterEnd || function () {};

    function decrementCounter() {
      updateStatus(seconds);
      if (seconds === 0) {
        counterEnd();
        instance.stop();
      }
      seconds--;
    }

    this.start = function () {
      clearInterval(timer);
      timer = 0;
      seconds = options.seconds;
      timer = setInterval(decrementCounter, 1000);
    };

    this.stop = function () {
      clearInterval(timer);
    };
  }
}
export const getUtmSource = () => {
  let local_data = localStorage.getItem(
    `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_utm_source`
  );
  if (local_data) {
    return local_data;
  } else {
    return '';
  }
};
export const generateUtmExt = () => {
  return getLocalStorage('utm_source', '')
    ? `&utm_source=${getLocalStorage('utm_source', '')}`
    : '';
};
export const getAgent = () => {
  var OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
  if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
  if (navigator.appVersion.indexOf('IOS') != -1) OSName = 'IOS';
  if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
  if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
  if (navigator.appVersion.indexOf('Android') != -1) OSName = 'Android';
  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //   var device_type = 'Mobile';
  // } else {
  //   var device_type = 'PC';
  // }
  return OSName;
};

// export const redirectTo = (type,id) => {
//     return navigate(`/${type}/${id}`)
// }
const getFormatedData = (val = 0) => {
  return val.toString().padStart(2, 0);
};
export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? getFormatedData(h) + ':' : '';
  var mDisplay = m > 0 ? getFormatedData(m) + ':' : '';
  var sDisplay = s > 0 ? getFormatedData(s) : '';
  return hDisplay + mDisplay + sDisplay;
};
export const generateTitle = (title) => {
  return (window.document.title = `CLIRNET - ${title}`);
};

export const timeDifference = (from) => {
  let now = dayjs(new Date()); //todays date
  let end = dayjs(from); // another date
  let seconds = now.diff(end, 's');

  return seconds;
};

export const getCurrentTime = (_format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).format(_format);
};

export const downloadFromUrl = (remote_url = '', file_name = 'clirnet_file') => {
  fetch(remote_url, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  })
    .then((response) => {
      response
        .blob()
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = file_name;
          a.click();
        })
        .catch((error) => {
          console.log('===>>ERROR WHILE FETCHING', error);
        });
    })
    .catch((error) => {
      console.log('===>>ERROR WHILE FETCHING', error);
    });
};

export const isFileAllowed = (file = null, sizeInMb = 2) => {
  if (file) {
    console.log('fileSize', file.size);
    if (file?.size / 1024 > sizeInMb * 1024) {
      return false;
    } else return true;
  }
};

export const handleCompressedUpload = (image = null, callback) => {
  if (image) {
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        const myFile = new File([compressedResult], compressedResult.name, {
          type: compressedResult.type
        });
        callback(myFile);
        // console.log('COMPRESSED', compressedResult, myFile);
        // callback(compressedResult);
      },
      error(err) {
        console.log(err.message);
      }
    });
  }
};
export const getPollQuestionCount = (data) => {
  if (data?.json_data && data?.json_data !== 'false') {
    var parsedData = JSON.parse(unescape(data.json_data));
    let qArray = Object.keys(parsedData?.surveys).map((k) => {
      return parsedData.surveys[k];
    });
    return qArray.length;
  } else {
    return -1;
  }
};
export const getLimitedText = (text, limit) => {
  if (text?.length > limit) {
    return text?.replace(/&\w+;\s*/g, '').substring(0, limit) + '...';
  } else {
    return text?.replace(/&\w+;\s*/g, '');
  }
};
export const getClientDeviceDetails = (mobile_number = 'NOT_FOUND') => {
  return {
    platform: (osName ?? 'NOT_FOUND') + ' ' + (osVersion ?? 'NOT_FOUND'),
    version: appVersion || 'NOT_FOUND',
    browser: browserName || 'NOT_FOUND',
    browser_version: fullBrowserVersion || 'NOT_FOUND',
    mobilenumber: mobile_number,
    mobilemodel: isMobile ? mobileVendor + ' ' + mobileModel : 'NOT_MOBILE',
    user_master_id: getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0
  };
};
export const deleteFromArray = (elem, array) => {
  const index = array.indexOf(elem);
  if (index > -1) {
    array.splice(index, 1); // 2nd parameter means remove one item only
  }

  return array;
};
export const isApp = () => (getLocalStorage('from_app', false) ? true : false);
export const calculateMultiArrayLength = (array) => {
  let arrayLength = 0;
  if (array && array?.length > 0) {
    array.forEach((_it) => {
      arrayLength += _it?.length;
    });
    return arrayLength;
  } else {
    return arrayLength;
  }
};
//'https://appcdn.clirnet.com/common/assets/default_doctor_image.svg'
export const DEMO_USER_PICTURE =
  'https://storage.googleapis.com/gl-clirnet-static-assets-prod/defaultContentImage/images/mentor-default-image-3.png';
export const CLINICAL_DEMO = clinical_Video_static_image;
export const SESSION_DEMO =
  'https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400086/55848266-modern-thin-line-design-concept-for-healthcare-website-banner-vector-illustration-concept-for-health.jpg';
export const MEDWIKI_DEMO = medwiki_static_image;
export const QUIZ_DEMO = quizzes_static_image;
export const GR_DEMO = grand_rounds_static_image;
export const COURSE_DEMO = certified_courses_static_image;
export const placeDemoUserPicture = (e) => {
  e.target.src =
    'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F0E57DB94-9662-11F6-3541-148D6F42BDC8%2F0E57DB94-9662-11F6-3541-148D6F42BDC8167242955049480.png?alt=media&token=1754cd39-f142-4ad9-aca8-8cc102dfe88f';
};
export const parseJson = (str, default_value = null) => {
  if (!str) return false;
  try {
    let parsed_data = JSON.parse(str);
    return parsed_data;
  } catch (error) {
    console.log('error===========>', error);
    return default_value ? default_value : {};
  }
};
export const MEDWIKI_NAME = (
  <>
    MedWiki<sup>&reg;</sup>
  </>
);
export const stringToArray = (param = '') => {
  var string = param;
  string = string.split(' ');
  var stringArray = [];
  for (var i = 0; i < string.length; i++) {
    stringArray.push(string[i]);
    if (i != string.length - 1) {
      stringArray.push(' ');
    }
  }
  return stringArray;
};

export const reactHtmlParser = (_params) => {
  if (_params || typeof _params == 'string') {
    return parse(`${_params}`);
  }
};
export const userDetails = () =>
  getLocalStorage('user', false) ? getLocalStorage('user', {}) : {};
// export const jsonParser =  (stringifiedJSON) => {
//   let paresdJSON=null
//   try {
//     paresdJSON = JSON.parse(stringifiedJSON);
//     console.log('paresdJSON', paresdJSON);
//     return paresdJSON;
//   }
//   catch (error) {
//     console.log('error===>', error);
//     return false
//   }
// }
export const isEligibleForSpeciality = (details) => {
  if (!details || typeof details != 'object') {
    console.log('isEligibleForSpecialityif', typeof details);
    return false;
  } else {
    const { spec_dashboard_eligibility_status, specility_update_status } = details;

    return (
      spec_dashboard_eligibility_status ||
      (spec_dashboard_eligibility_status && specility_update_status === '0')
    );
    // return spec_dashboard_eligibility_status;
  }
};

export const isEligibleForDoctorVoice = (details) => {
  if (!details || typeof details != 'object') {
    console.log('isEligibleForSpecialityif', typeof details);
    return false;
  } else {
    const { doctor_voice_eligibility_status } = details;
    return doctor_voice_eligibility_status;
  }
};

export const isMobileApp = () => {
  if (process.env.REACT_CAPACITOR_APP == 1) {
    return true;
  } else {
    return false;
  }
};
export const isIosApp = () => {
  // for Web uncomment bellow return
  // if (Capacitor.getPlatform() == 'ios') {
  //   return true;
  // } else {
  //   return false;
  // }

  return false;
};
export const isAndroidApp = async () => {
  // for Web uncomment bellow return
  // if (Capacitor.getPlatform() == 'android') {
  //   return true;
  // } else {
  //   return false;
  // }

  return false;
};
export const getNativePlatform = async () => {
  // for APP concomment below 2 line
  // return Capacitor.getPlatform();

  // for Web uncomment bellow return
  return false;
};

export const openNewTab = (url, cb) => {
  let win = window.open(url, '_blank');
  console.log(win, win.location);
  if (win) {
    win.location;
  } else {
    if (cb) {
      cb();
    } else {
      window.location.href = url;
    }
  }
};

export const generateUrl = () => {
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const currentUrl = baseUrl + '#' + location.pathname + location.search + location.hash;
  return currentUrl;
};
export const deleteCaches = () => {
  caches
    .keys()
    .then((keys) =>
      Promise.all(
        keys.map((key) => {
          if (!key?.includes('workbox-precache')) {
            return caches.delete(key);
          }
        })
      )
    )
    .then(() => {
      console.log('SW now ready to handle fetches!');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const changeUrl = (urlName) => {
  let url = urlName?.toLowerCase();
  switch (url) {
    case 'dashboard':
      return routeNames.dashboard.landing.substring(1);

    case 'doctube':
      return routeNames.doctube.landing.substring(1);

    case 'channel':
      return routeNames.channel.landing.substring(1);

    case 'sessions':
      return routeNames.sessions.landing.substring(1);

    case 'medwiki':
      return routeNames.medwiki.landing.substring(1);
    case 'grandround':
      return routeNames.grandRound.landing.substring(1);
    case 'clinicalvideolanding':
      return routeNames.clinicalVideo.landing.substring(1);

    case 'traininglanding':
      return routeNames.course.landing.substring(1);

    case 'spqlanding':
      return routeNames.spq.landing.substring(1);
    case 'differentialdiagnosis':
      return routeNames.differentialDiagnosis.landing.substring(1);

    case 'ebook':
      return routeNames.epaper.landing.substring(1);

    case 'telemed':
      return routeNames.telemed.landing.substring(1);

    case 'discussrefer':
      return routeNames.discussRefer.landing.substring(1);

    default:
      return url;
  }
};

export const convertAllMenu = (all_menus) => {
  return all_menus && all_menus?.length > 0
    ? all_menus?.map((menu) => {
        return {
          ...menu,
          url: changeUrl(menu.url)
        };
      })
    : [];
};

export const isLocked = true;

export const generateName = (firstName, middleName, lastName) => {
  var name = '';

  if (firstName) {
    name = firstName;
  }

  if (middleName) {
    name = name + ' ' + middleName;
  }

  if (lastName) {
    name = name + ' ' + lastName;
  }

  return name;
};
export const getTimeZone = () => {
  try {
    const currentTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(currentTimeZone, 'currentTimeZone');
    return currentTimeZone;
  } catch (error) {
    return '';
  }
};
function removeSpacesAndLowerCase(inputString) {
  // Remove spaces using regular expression
  const stringWithoutSpaces = inputString?.replace(/\s/g, '');

  // Convert to lowercase
  const lowerCaseString = stringWithoutSpaces?.toLowerCase();

  return lowerCaseString;
}
export const getSessionCategoryIcon = (session_category) => {
  let category = removeSpacesAndLowerCase(session_category);
  if (category) {
    switch (category) {
      case 'mastercast':
      default:
        return 'flaticon-conference';

      case 'podcast':
        return 'flaticon-microphone';

      case 'masterconsult':
        return 'flaticon-medical-service';

      case 'mastercircle':
        return 'flaticon-video-call';
    }
  }
};
export const ModuleTitle = ({ title }) => {
  let splitted = title?.split(':');
  if (splitted?.length > 1) {
    let rest = splitted?.slice(1);
    return (
      <React.Fragment>
        <span>
          <span className="fs-16 fw-semibold"> {splitted[0]} :</span>&nbsp;
          {rest.join('')}
        </span>
      </React.Fragment>
    );
  }
  return <React.Fragment>{title}</React.Fragment>;
};

export function isACommendableRoute(pathname) {
  const commentableRoutes = [
    'TrainingContent',
    'MedwikiDetails',
    'ClinicalVideoDetails',
    'GrandRoundDetails',
    routeNames.course.content,
    routeNames.medwiki.detail,
    routeNames.clinicalVideo.detail,
    routeNames.grandRound.detail,
    routeNames.differentialDiagnosis.landing
  ];

  var flag = false;
  commentableRoutes.forEach((element, index) => {
    if (pathname.toLowerCase()?.includes(element.toLowerCase())) {
      flag = true;
    }
  });
  return flag;
}

export function getRedirectType(serviceName, serviceType) {
  switch (serviceName) {
    case 'comp':
      return routeNames.medwiki.detail;
    case 'gr':
      return routeNames.grandRound.detail;
    case 'epub':
      return routeNames.epaper.detail;
    case 'survey':
      switch (serviceType) {
        case 'poll':
          return routeNames.spq.poll;
        default:
          return routeNames.spq.detail;
      }
    case 'session':
      return routeNames.sessions.detail;
    case 'archived_video':
      return routeNames.clinicalVideo.detail;
    case 'observer':
      return routeNames.observership.observershipDetails;
    case 'mentor':
      return routeNames.observership.profile;
    case 'training':
      return routeNames.course.cover;
    case 'differential_diagnosis':
    case 'ddi':
      return routeNames.differentialDiagnosis.details;
    case 'channel':
      return routeNames.channel.detail;
    case 'doctube':
      return routeNames.doctube.landing;
    default:
      return routeNames.dashboard.landing;
  }
}

export function textIteratorFun(text, partLength) {
  let index = 0;
  return {
    next() {
      if (index >= text.length) {
        return { done: true, value: '' };
      }
      const part = text.slice(index, index + partLength);
      index += partLength;
      return { value: part, done: false };
    }
  };
}
export const checkYear = (year, futureData = false) => {
  if (futureData) {
    if (parseInt(year) >= 1700) return true;
    else return false;
  } else {
    if (parseInt(year) >= 1700 && parseInt(year) <= dayjs().year()) return true;
    else return false;
  }
};

export const isUserLoggedIn = () => {
  return getLocalStorage('refreshToken', false) ? true : false;
};

export function removeFootnoteList(htmlString) {
  // Create a temporary DOM element
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Find all elements with class 'footnote'
  const footnote = tempDiv.querySelectorAll('.footnote');
  const footnotes = tempDiv.querySelectorAll('.footnotes');

  // Remove each footnote element
  footnotes.forEach((footnote) => {
    footnote.parentNode.removeChild(footnote);
  });

  footnote.forEach((footnote) => {
    footnote.parentNode.removeChild(footnote);
  });

  // Return the cleaned HTML string
  return tempDiv.innerHTML;
}

export function extractCitationData(answer_htm, isNewPattern) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = answer_htm;

  let citationContent = '';
  let citationCount = 0;

  const serializer = new XMLSerializer();

  if (isNewPattern) {
    const footnotes = tempDiv.querySelectorAll('.footnote');
    console.log('footnotes found:', footnotes.length);

    footnotes.forEach((footnote) => {
      // Clone the node to preserve all attributes
      const clonedNode = footnote.cloneNode(true);
      // Serialize the node to string
      citationContent += serializer.serializeToString(clonedNode);

      const footnoteLists = footnote.querySelectorAll('section.footnote-list');
      citationCount += footnoteLists.length;
    });
  } else {
    const footnotes = tempDiv.querySelectorAll('.footnotes');
    console.log('footnotes found:', footnotes.length);

    footnotes.forEach((footnote) => {
      // Clone the node to preserve all attributes
      const clonedNode = footnote.cloneNode(true);
      const h2Elements = clonedNode.querySelectorAll('h2');
      h2Elements.forEach((h2) => h2.remove());
      // Serialize the node to string
      citationContent += serializer.serializeToString(clonedNode);

      const citationItems = footnote.querySelectorAll('ol li[data-footnote-id]');
      citationCount += citationItems.length;
    });
  }

  return {
    citation: citationContent,
    numberOfCitation: citationCount
  };
}
