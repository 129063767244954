import React, { useCallback, useState, useEffect, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../assets/sass/uiV3/pages/_specialitylanding.scss';
import '../assets/sass/uiV3/component/_header.scss';
import HeaderSpecialityDesktop from '../components/baseUiV3/template/header/HeaderSpecialityDesktop';
import HeaderSpecialityMobile from '../components/baseUiV3/template/header/HeaderSpecialityMobile';
import { isMobile } from 'react-device-detect';
import MobileFooterNav from '../components/baseUiV3/template/footer/MobileFooterNav';
import HeaderProfileMobile from '../components/baseUiV3/template/header/HeaderProfileMobile';
import HeaderSpecialityDetailsMobile from '../components/baseUiV3/template/header/HeaderSpecialityDetailsMobile';
import { routeNames } from '../router/constants';
import { useSelector } from 'react-redux';
import { isACommendableRoute } from '../common/common';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const SpecialityDetailsLayout = () => {
  const [elHeight, setElheight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);
  const [navItem, setNavItem] = useState('All');
  const { pathname } = useLocation();
  const pageName = pathname.slice(1);

  const getElementHeight = (data) => {
    // alert(data)
    setElheight(data);
  };
  const getElementBottomHeight = (data) => {
    // alert(data)
    setBottomHeight(data);
  };
  const selectedNavItem = (data) => {
    setNavItem(data);
  };
  // useEffect(() => {
  //   // let paramObj = {};
  //   // if (navItem == 'CME') {
  //   //   paramObj[`type`] = `${navItem}`;
  //   //   paramObj[`sessionType`] = `${navItem}`;
  //   // } else {
  //   // }
  //   setSearchParams({ type: `${navItem}` }, { replace: true });
  // }, [navItem]);

  const donstShowFooter = pathname.toLocaleLowerCase()?.includes('TrainingContent'.toLowerCase())
    ? true
    : false;
  const current_menu = useSelector((state) => state.common.current_menu);
  const isDetailsPage = current_menu.includes('details');
  const commentsShowing = useSelector((state) => state.common.showComment);
  return (
    <>
      {/* (isMobile ? 'w-100 mblscreen' : 'w-100 dskScreen desktopBody'); */}
      <div className={`${isMobile ? 'w-100 mblscreen' : 'w-100 dskScreen desktopBody p-0'} py-0`}>
        {isMobile ? (
          pageName == 'userProfile' ? (
            <HeaderProfileMobile getElementHeight={getElementHeight} />
          ) : (
            <HeaderSpecialityDetailsMobile getElementHeight={getElementHeight} />
          )
        ) : (
          <HeaderSpecialityDesktop getElementHeight={getElementHeight} elHeight={elHeight} />
        )}
        {console.log('bottomHeight', pathname)}
        <div
          className={`${isMobile ? 'mobile' : 'desktop'} speciality-portal w-100 d-flex`}
          style={{
            paddingTop: `${elHeight}px`,
            paddingBottom: `${
              pathname === routeNames.differentialDiagnosis.landing ||
              pathname.includes(routeNames.differentialDiagnosis.details)
                ? 0
                : isACommendableRoute(pathname) && commentsShowing
                ? 75
                : bottomHeight
            }px`
          }}
        >
          {/* <div className="w-100 d-flex flex-column position-relative">
          <div className="container-fluid"> */}
          <Outlet />
          {/* </div>
        </div> */}
        </div>
        {pageName !== 'speciality' &&
        isMobile &&
        !isACommendableRoute(pathname) &&
        !isDetailsPage ? (
          <MobileFooterNav
            getElementBottomHeight={getElementBottomHeight}
            // exploreMenuOpen={exploreMenuOpen}
            // exploreMenuToggler={exploreMenuToggler}
            height={elHeight}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

SpecialityDetailsLayout.propTypes = propTypes;
// #endregion

export default memo(SpecialityDetailsLayout);
