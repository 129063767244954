// STATIC IMAGES
export const clinical_Video_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Clinical_Video_Static_Image.jpg';
export const medwiki_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Medwiki_Static_Image.jpg';
export const ebook_static_image = 'https://assets.clirnet.com/common/portrait.png';
export const quizzes_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Quizzes_Static_Image.jpg';
export const certified_courses_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/certified_courses_static_image.jpg';
export const grand_rounds_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Grand_Round_Static_Image.jpg';
export const surveys_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Surveys_Static_Image.jpg';
export const card_pic_static_image = 'https://assets.clirnet.com/common/defaultdr.png';
export const card_top_part_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Card_Top_Part_Static_Image.jpeg';
export const content_channel_card_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Content_Channel_Card_Static_Image.png';
export const content_cta_suscribe_service_v1_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Cta_Suscribe_Service_V1_Static_Image.png';
export const content_cta_suscribe_speciality_v1_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Cta_Suscribe_Speciality_V1_Static_Image.png';
export const content_cta_suscribe_speciality_v2_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Cta_Suscribe_Speciality_V2_Static_Image.png';
export const cta_card_v6_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Cta_Card_V6_Static_Image.jpg';
export const channel_cards_static_image_1 =
  'https://assets.clirnet.com/defaultContentImage/images/Channel_Card_Static_Image.jpg';
export const channel_cards_static_image_2 =
  'https://assets.clirnet.com/defaultContentImage/images/Channel_Card_Static_Image_2.jpg';
export const channel_cards_static_image_3 =
  'https://assets.clirnet.com/defaultContentImage/images/Channel_Card_Static_Image_3.jpeg';
export const master_doctor_sidebar_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Master_Doctor_Sidebar_Static_Image.jpg';
export const discuss_reffer_static_image_1 =
  'https://assets.clirnet.com/defaultContentImage/images/Discuss_Reffer_Static_Image_1.jpg';
export const discuss_reffer_static_image_2 =
  'https://assets.clirnet.com/defaultContentImage/images/Discuss_Reffer_Static_Image_2.jpg';
export const discuss_reffer_static_image_3 =
  'https://assets.clirnet.com/defaultContentImage/images/Discuss_Reffer_Static_Image_3.jpg';
export const doctube_video_card_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Doctube_Video_Card_Static_Image.jpg';
export const clirnet_logo_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/clirnet_logo_static_image.png';
export const help_and_support_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Help_and_Support_Static_Image.webp';
export const telemed_share_static_image_1 =
  'https://assets.clirnet.com/defaultContentImage/images/Telemed_Share_Static_Image_1.jpg';
export const telemed_share_static_image_2 =
  'https://assets.clirnet.com/defaultContentImage/images/Telemed_Share_Static_Image_2.jpg';
export const telemed_share_static_image_3 =
  'https://assets.clirnet.com/defaultContentImage/images/Telemed_Share_Static_Image_3.jpg';
export const telemed_share_static_image_4 =
  'https://assets.clirnet.com/defaultContentImage/images/Telemed_Share_Static_Image_4.jpg';
export const telemed_share_static_image_5 =
  'https://assets.clirnet.com/defaultContentImage/images/Telemed_Share_Static_Image_5.jpg';
export const doctube_playlist_details_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Doctube_Playlist_Details_Static_Image.png';

// STATIC VIDEOS
export const ads_player_static_video = 'https://assets.clirnet.com/video/Ads_Player_demo.mp4';

// STATIC PDF
export const certificate_details_static_pdf =
  'https://assets.clirnet.com/pdf/Certificate_Details_Demo_Pdf.pdf';

export const certificate_static_image = 'https://assets.clirnet.com/common/certificate.png';
export const session_static_image =
  'https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400086/55848266-modern-thin-line-design-concept-for-healthcare-website-banner-vector-illustration-concept-for-health.jpg';
