import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';

function FilePreviewModal({ show, onHide, fileUrl, isPdf }) {
  return (
    <Modal className="centerModal" size={isPdf ? 'lg' : 'md'} centered show={show} onHide={onHide}>
      <Modal.Header className="position-relative border-0 p-4 mb-1" closeButton>
        <Modal.Title className="fs-4 text-black fw-semibold">File Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="w-100 centerModalFrm">
          {isPdf ? (
            <iframe
              src={fileUrl}
              title="iq-doc"
              loading="lazy"
              style={{ width: '100%', height: '49rem' }}
            ></iframe>
          ) : (
            <div className="w-100 h-100">
              <img src={fileUrl} alt="" className="w-100 h-100 object-fit-cover" />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(FilePreviewModal);
