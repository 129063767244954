import React, { memo, useCallback, useEffect } from 'react';
import { makeFirstLetterCapital } from '../../../common/common';
import SeverityPill from './SeverityPill';
import dayjs from 'dayjs';
import useShare from '../../../components/customHooks/useShare';
import { Fade } from 'react-awesome-reveal';
import { isMobile } from 'react-device-detect';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { SHARE_CLICK } from '../../../components/tracking/constants';

const ResultArea = ({ result, className = '', styles = {} }) => {
  const { timestamp, response, request } = result || {};
  const ddiList = response?.DDi_list || [];
  const { trackActivityEvent } = useTracker();

  if (!ddiList?.length) return null;
  useEffect(() => {
    if (result?.response?.DDi_list?.length > 0) {
      document.getElementById('ddiresultarea').scrollIntoView({ behavior: 'smooth' });
    }
  }, [result]);
  function removeKeys(inputObject) {
    const { ddi_id, combination, Interaction_severity, is_liked, is_disliked, ...rest } =
      inputObject;
    return rest;
  }
  const share_url = useShare();
  const handleShareClick = useCallback(
    (id) => {
      const ddiMedicines = Object.values(result?.request)?.filter((med) => !!med);
      let lastMedicine = ddiMedicines[ddiMedicines.length - 1];
      let withoutLastMedicine = ddiMedicines?.filter((med) => med !== lastMedicine);

      let string = withoutLastMedicine.join(', ') + ' and ' + lastMedicine;

      share_url(
        `Drug Interactions between ${string}`,
        process.env.REACT_APP_BASE_URL + 'share/ddi/' + id
      );
    },
    [result]
  );

  return (
    <Fade>
      <div
        className={
          'rounded-3 bg-white position-relative z-1 border border-1 border-soft-primary ' +
          className
        }
        style={styles}
        id={'ddiresultarea'}
      >
        <div className="d-flex flex-column p-sm-4 p-3 bg-white rounded-3 position-relative z-1">
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between gap-2">
            <div
              className={
                'd-flex justify-content-between order-1 order-md-2 ' + (isMobile ? 'w-100' : '')
              }
            >
              <span className="fs-4 fw-normal me-3">
                {dayjs(timestamp).format('DD MMMM, YYYY')}
              </span>
              <i
                className="flaticon-share-arrow fs-3 text-primary ms-auto"
                onClick={() => {
                  trackActivityEvent(SHARE_CLICK, result);
                  handleShareClick(result?.ddi_id);
                }}
              ></i>
            </div>
            <h1 className="fs-2 fw-semibold text-black mb-3 order-2 order-md-1">
              {ddiList?.length} Interactions found
            </h1>
          </div>

          <div className="clr_v3_TtlDivider position-relative bg-light me-auto rounded overflow-hidden">
            <span className="w-50 bg-primary position-absolute top-0 start-0 h-100"></span>
          </div>

          {ddiList.map((ddi, index) => {
            let interactionMeds = ddi?.combination?.includes('+')
              ? ddi?.combination?.split('+')
              : ddi?.combination?.includes('-')
              ? ddi?.combination?.split('-')
              : [];
            if (interactionMeds.length > 0) {
              interactionMeds = interactionMeds.map((med) => med.trim());
            }
            return (
              <div className="d-flex flex-column gap-2 mt-3">
                <div className="d-flex flex-wrap justify-content-between align-items-center bg-extra-light rounded-3 py-4 px-3 gap-3 gap-md-0">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <span className="text-black text-nowrap">
                      {makeFirstLetterCapital(interactionMeds[0])}
                    </span>
                    <i className="flaticon-transfer-data fs-4 mx-2 mx-md-4 text-primary"></i>
                    <span className="text-black text-nowrap">
                      {makeFirstLetterCapital(interactionMeds[1])}
                    </span>
                  </div>

                  <SeverityPill severity={ddi?.Interaction_severity?.toLowerCase()} />
                </div>
                <div className="w-100 p-3 pt-0">
                  {Object.keys(removeKeys(ddi)).map((key) => {
                    return (
                      <p className="mb-3 text-black" key={key}>
                        <span
                          className={`bg-primary me-2`}
                          style={{
                            height: '0.8rem',
                            width: '0.8rem',
                            transform: 'rotate(45deg)',
                            display: 'inline-block'
                          }}
                        ></span>{' '}
                        <span className="fw-medium">{key?.replace(/_/g, ' ')}</span> : {ddi[key]}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fade>
  );
};

export default memo(ResultArea);
