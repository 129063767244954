import React, { memo } from 'react';
import { CgArrowLongRight, MdVerified } from '../../../../components/icons';
import '../../../../components/ContentSlider/css/contentSlider.scss';
import '../css/medwikiLandingV2.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

SwiperCore.use([Pagination, Navigation]);
import useRedirect from '../../../../components/customHooks/useRedirect';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';

import ShareButton from '../../../../components/ShareButton';
// import ReactHtmlParser from 'react-html-parser';
import MedwikiLandingFeaturedLoader from '../../../../skeleton/medwiki/MedwikiLandingV2/MedwikiLandingFeaturedLoader';
import { default_images } from '../../../../common/defaultImages';
import LazyImage from '../../../../components/CustomLibraries/LazyImage';
import { isMobile } from 'react-device-detect';
import { MEDWIKI_NAME, isLocked, reactHtmlParser } from '../../../../common/common';
import InView from 'react-intersection-observer';
import { useTrackUserActivity } from '../../../../components/customHooks/useTrackUserActivity';
import { routeNames } from '../../../../router/constants';
import useContentAccessRedirect from '../../../../common/useContentAccessRedirect';
import checkForPremiumIcon from '../../../../common/checkForPremiumIcon';
import TrackerWrapper from '../.././../../components/tracking/TrackerWrapper';
import {
  CARD_CLICK,
  CARD_VIEW,
  EXPLORE_MORE_CLICK,
  MORE_CLICK
} from '../../../../components/tracking/constants';
import { trackEvent } from '../../../../components/tracking/UserActions';
import { removeHtmlTags } from '../../../channel/utils';
import { activeStatusCode } from '../../../../utils/constants/common.constant';
import InactiveComponent from '../../../../components/InactiveComponent';

const MedwikiLandingFeatureCard = ({
  data,
  popularData,
  isLoading,
  extraClass,
  extraClassForPopular,
  statusCode
}) => {
  const { userActivityTracker } = useTrackUserActivity();
  const { redirectTo } = useRedirect();
  const { contentAccessRedirect } = useContentAccessRedirect();
  const { trackActivityEvent } = useTracker();
  const specialitiesName = (_data) =>
    _data && _data?.specialities?.split(',').length >= 2
      ? _data?.specialities?.split(',').map((_i, index) => (
          <li className="list-inline-item" key={index + 1}>
            {_i}
          </li>
        ))
      : _data?.specialities;

  console.log('test', data);
  return (
    <>
      <div className="medwikiTopLeft pe-0 pe-lg-5">
        {isLoading == false ? (
          <>
            {statusCode === activeStatusCode ? (
              <Swiper
                className={`medwikiFeaturedSlide shadow rounded-3 bg-white w-100 position-relative ${extraClass}`}
                slidesPerView={1}
                loop={false}
                pagination={{ clickable: true }}
                // effect={'fade'}
                id="medwiki_landing_feature_card"
              >
                {/* Medwiki landing featured card starts here */}
                {data &&
                  data?.length > 0 &&
                  data?.slice(0, 3).map((_data, i) => {
                    const displayIcon = checkForPremiumIcon(_data.is_locked);
                    return (
                      <SwiperSlide
                        className="medwikiFeaturedMain d-flex flex-wrap bg-white h-auto p-3 pb-50 p-md-4 pb-md-4"
                        key={i + 1}
                      >
                        <div className="medwikiFeaturedPic rounded-3 position-relative overflow-hidden mb-20 mb-md-0">
                          {displayIcon ? (
                            <div className="oldUser--premium__tag position-absolute shineEffect icon-size-24 rounded-circle top-0 end-0 mt-2 me-2 d-flex align-items-center justify-content-center">
                              <i className="flaticon-premium fs-5 text-black"></i>
                            </div>
                          ) : (
                            <></>
                          )}
                          <LazyImage
                            src={_data?.image ?? default_images.medwiki}
                            className="object-fit-cover w-100 h-100 float-start"
                            alt="image"
                            fallback_image={default_images.medwiki}
                          />
                          <div className="medwikiFeaturedPicIcon d-flex justify-content-end align-items-start bg-secondary text-white p-4 position-absolute top-0">
                            <MdVerified customClass={'text-white fs-30'} />
                          </div>
                        </div>
                        <TrackerWrapper
                          data={_data}
                          type={CARD_VIEW}
                          className="d-flex medwikiFeaturedRight flex-column justify-content-center text-start position-relative z-1 p-0 ps-0 py-md-3 ps-md-20 ps-xxl-5"
                        >
                          <div className="mb-md-4 mb-4">
                            <Swiper
                              className="medwikiFeaturedSponser float-end text-end"
                              navigation={false}
                              spaceBetween={0}
                              slidesPerView={1}
                              loop={true}
                              autoplay={{
                                delay: 2500
                              }}
                              effect={'fade'}
                            >
                              {_data?.sponsor_logo?.split(',')?.map((_d, index) => (
                                <SwiperSlide
                                  className="medwikiFeaturedSponserIn bg-white position-relative w-100"
                                  key={index + 1}
                                >
                                  <LazyImage
                                    src={_d}
                                    className="position-absolute top-50 end-0 translate-middle-y mw-100 mh-100 w-auto h-auto"
                                    alt="Sponsor"
                                    logo
                                  />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                          <div className="rounded-3 bg-white shadow medwikiFeaturedContent p-0 p-md-20 py-lg-20 px-lg-5 position-relative">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <span className="text-black d-inline-block fs-4 gtm_cl_medwiki_landing_featured_speciality">
                                {/* {_data.specialities?.split(',')[0]} */}
                                {/* {specialitiesName(_data)} */}
                                {/* {_data.specialities ? _data.specialities?.replaceAll(',', ' | ') : ''} */}
                                {_data?.specialities_ids_and_names &&
                                  _data?.specialities_ids_and_names.length > 0 &&
                                  _data.specialities_ids_and_names.map((speciality, index) => (
                                    <span
                                      key={index + 1}
                                      className="cursorPointer"
                                      onClick={() => {
                                        if (speciality) {
                                          redirectTo(
                                            routeNames.speciality.landing,
                                            speciality.id,
                                            removeHtmlTags(speciality.name)
                                          );
                                        }
                                      }}
                                    >
                                      {speciality.name}{' '}
                                      {index != _data.specialities_ids_and_names.length - 1
                                        ? ` | `
                                        : ''}
                                    </span>
                                  ))}
                              </span>
                              <ShareButton
                                apiData={_data}
                                gtmTagVariable="gtm_cl_medwiki_landing_featured_share"
                                buttonText={''}
                                customClass="fs-2 text-black"
                                svgClass="medwiki_landing_featured_share"
                                // id={_data.id}
                                data={{
                                  is_share: _data?.is_share,
                                  title: _data.question,
                                  url: _data.deeplink
                                }}
                              />
                              {/* <FiShare2 customClass="fs-2 text-black" /> */}
                            </div>

                            <h2
                              className="line-clamp-2 fs-16 text-black mb-3 gtm_cl_medwiki_landing_feature_card"
                              onClick={() => {
                                // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                                contentAccessRedirect(
                                  routeNames.medwiki.detail,
                                  _data.type_id,
                                  _data.question,
                                  _data.is_locked,
                                  _data.price,
                                  _data.user_content_payment,
                                  `type=${_data.con_type}`,
                                  _data
                                );
                              }}
                            >
                              {reactHtmlParser(_data?.question)}
                            </h2>
                            <h5
                              className="line-clamp-2 fs-4 text-dark mb-4 gtm_cl_medwiki_landing_feature_card"
                              onClick={() => {
                                // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                                contentAccessRedirect(
                                  routeNames.medwiki.detail,
                                  _data.type_id,
                                  _data.question,
                                  _data.is_locked,
                                  _data.price,
                                  _data.user_content_payment,
                                  `type=${_data.con_type}`,
                                  _data
                                );
                              }}
                            >
                              {reactHtmlParser(_data?.answer)}
                            </h5>
                            <button
                              className="btn btn-primary rounded-3 medwikiFeaturedBtn gtm_cl_medwiki_landing_feature_card d-flex gap-2 align-items-center"
                              onClick={() => {
                                contentAccessRedirect(
                                  routeNames.medwiki.detail,
                                  _data.type_id,
                                  _data.question,
                                  _data.is_locked,
                                  _data.price,
                                  _data.user_content_payment,
                                  `type=${_data.con_type}`,
                                  _data
                                );
                                // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                              }}
                            >
                              View Details
                              <CgArrowLongRight
                                customClass="fs-4 medwiki_landing_feature_card_view_details_icon"
                                gtmTagVariable="gtm_cl_medwiki_landing_feature_card"
                              />
                            </button>
                          </div>
                        </TrackerWrapper>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            ) : (
              <InactiveComponent
                statuscode={statusCode}
                header="Inactive Content"
                subHeader="This content is inactive"
                icon=""
              />
            )}

            {/* Popular medwiki starts here */}
            {statusCode === activeStatusCode ? (
              <div
                className={`w-100 popularMedwiki shadow bg-white rounded-3 p-3 p-md-4 my-4 text-start position-relative overflow-hidden d-flex justify-content-between ${extraClassForPopular}`}
                id="medwiki_popular_box"
              >
                {/*  .topRatedMedwiki and .mostCommentedMedwiki classe to be added accordingly */}
                <div className="headingPart pt-2 text-start d-flex justify-content-center align-items-center rounded-3">
                  <span className="fs-4 fw-medium text-black">
                    Popular
                    <span className="fs-2 fw-semibold text-secondary d-block">
                      {
                        <>
                          MedWiki<sup>&reg;</sup>
                        </>
                      }
                    </span>
                  </span>
                  <a
                    className="fs-4 fw-medium text-primary d-block d-lg-none d-md-none gtm_cl_medwiki_landing_popular_box_explore_more"
                    onClick={() => {
                      trackActivityEvent(MORE_CLICK, {});
                      redirectTo(routeNames.medwiki.listing);
                    }}
                    id={
                      isMobile
                        ? 'medwiki_popular_box_view_all'
                        : 'medwiki_default_popular_box_veiw_all_mobile'
                    }
                  >
                    Explore More
                  </a>
                </div>
                <div className="popularMedwikiSlider mb-0">
                  <Swiper
                    className="popularMedwikiSliderIn text-start pt-3 pb-0 pt-md-1 pb-md-1 ps-0 ps-md-4"
                    spaceBetween={24}
                    slidesPerView={3}
                    loop={false}
                    breakpoints={{
                      1: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 12
                      },
                      992: {
                        slidesPerView: 2,
                        spaceBetween: 15
                      },
                      1400: {
                        slidesPerView: 3,
                        spaceBetween: 15
                      },
                      1600: {
                        slidesPerView: 4,
                        spaceBetween: 18
                      }
                    }}
                  >
                    {popularData &&
                      popularData?.length > 0 &&
                      popularData?.map((_data, i) => {
                        return (
                          <SwiperSlide
                            className="popularMedwikiSliderBox d-flex justify-content-between align-items-center position-relative border-end border-2 border-secondary"
                            key={i + 1}
                          >
                            <div
                              className="popularMedwikiPic overflow-hidden rounded-3 gtm_cl_medwiki_landing_popular_box"
                              onClick={() => {
                                // trackActivityEvent(CARD_CLICK, trackEvent('VIEW_DETAILS_CLICK'));
                                contentAccessRedirect(
                                  routeNames.medwiki.detail,
                                  _data.type_id,
                                  _data.question,
                                  _data.is_locked,
                                  _data.price,
                                  _data.user_content_payment,
                                  `type=${_data.con_type}`,
                                  _data
                                );
                              }}
                            >
                              <LazyImage
                                src={_data?.image ?? default_images.medwiki}
                                className="object-fit-cover w-100 h-100 float-start gtm_cl_medwiki_landing_popular_box"
                                alt="Medwiki"
                                fallback_image={default_images?.medwiki}
                                onClick={() => {
                                  // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                                  contentAccessRedirect(
                                    routeNames.medwiki.detail,
                                    _data.type_id,
                                    _data.question,
                                    _data.is_locked,
                                    _data.price,
                                    _data.user_content_payment,
                                    `type=${_data.con_type}`,
                                    _data
                                  );
                                }}
                              />
                            </div>
                            <TrackerWrapper
                              data={_data}
                              type={CARD_VIEW}
                              className="popularMedwikiDetails position-relative ps-3 pe-4"
                            >
                              <h6
                                className="fs-5 fw-normal text-dark gtm_cl_medwiki_landing_popular_box_speciality"
                                onClick={() => {
                                  // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                                  contentAccessRedirect(
                                    routeNames.medwiki.detail,
                                    _data.type_id,
                                    _data.question,
                                    _data.is_locked,
                                    _data.price,
                                    _data.user_content_payment,
                                    `type=${_data.con_type}`,
                                    _data
                                  );
                                }}
                              >
                                {_data.specialities?.split(',')[0]}
                              </h6>
                              <h4
                                className="fs-4 fw-medium text-black line-clamp-2 gtm_cl_medwiki_landing_popular_box"
                                onClick={() => {
                                  // trackActivityEvent(EVENT, trackEvent('VIEW_DETAILS_CLICK'));
                                  contentAccessRedirect(
                                    routeNames.medwiki.detail,
                                    _data.type_id,
                                    _data.question,
                                    _data.is_locked,
                                    _data.price,
                                    _data.user_content_payment,
                                    `type=${_data.con_type}`,
                                    _data
                                  );
                                }}
                              >
                                {reactHtmlParser(_data?.question)}
                              </h4>
                            </TrackerWrapper>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
                <div className="popularMedwikiBtn d-flex justify-content-end align-items-center p-4 position-absolute top-0 end-0 h-100">
                  <a
                    href="javascript:void(0)"
                    className="fs-4 fw-medium text-primary gtm_cl_medwiki_landing_popular_box_explore_more"
                    onClick={() => {
                      trackActivityEvent(MORE_CLICK, {});
                      redirectTo(routeNames.medwiki.popular);
                    }}
                    id={
                      isMobile
                        ? 'medwiki_default_popular_box_veiw_all_mobile'
                        : 'medwiki_popular_box_view_all'
                    }
                  >
                    Explore More
                  </a>
                </div>
              </div>
            ) : (
              <InactiveComponent
                statuscode={statusCode}
                header="Inactive Content"
                subHeader="This content is inactive"
                icon=""
              />
            )}
          </>
        ) : (
          <MedwikiLandingFeaturedLoader />
        )}
      </div>
    </>
  );
};

export default memo(MedwikiLandingFeatureCard);
