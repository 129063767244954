import React, { useEffect, useRef, useState } from 'react';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticateUser, loginSuccess } from '../../Store/actions/login.action';
import { getAllSpecialities, signUpEmail } from '../../Store/actions/registrationAction';
import { getVaultList } from '../../Store/actions/vault.action';
import {
  getLocalStorage,
  getSessionStorage,
  log,
  removeSessionStorage,
  setLocalStorage,
  stringToArray
} from '../../common/common';
import '../../components/centerModal/css/centerModal.scss';
import '../../components/rightSideModal/css/rightSideModal.scss';
import { routeNames } from '../../router/constants';
import '../share/css/share.scss';
import other from './assets/OtherParamedics.svg';
import doctor from './assets/doctor.svg';
import nurse from './assets/nurse.svg';
import pharmacist from './assets/pharmacist.svg';

import ISDPicker from '../../components/ISDPicker';
import { commonConstants } from '../../Store/constants/commonConstants';
import { safeJsonParser } from '../../utils/common';
import { useTracker } from '../../TrackerFolder/TrackerComponent';
import { SUBMIT_CLICK } from '../../components/tracking/constants';
var SelecSpecilalities = [];
var parse_id = '';

const RegistrationForm = (props) => {
  const UserRegion = useSelector((state) => state.share.UserRegion);
  const isUserGlobal = UserRegion === 'GT' || UserRegion === 'GL';
  const autoAuthLocation = useRef(null);
  const navigate = useNavigate();
  const [SelectedSpecialityDet, setSelectedSpecialityDet] = useState('');
  const [rerender, setRerender] = useState(false);
  const [isChecked, SetIsChecked] = useState(false);
  const [err_msg, setErr_msg] = useState('');

  const { search, state } = useLocation();
  const phoneDetail = queryString.parse(search);

  const { redirect_type } = queryString.parse(search) || { redirect_type: 'Dashboard' };
  const [selectedspecility, setselectedspecility] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let formdata = new FormData();

  const [show, setShow] = useState(false);
  const [waitShow, setWaitShow] = useState(false);
  const [specsData, setSpecsData] = useState([]);
  const [referalInputDisabled, setReferalInputDisabled] = useState(false);
  const { trackActivityEvent } = useTracker();
  const { id, election_id } = state || { id: '', election_id: '' };
  useEffect(() => {
    // console.log('TEXT_TO_SPLIT', stringArray);
    dispatch(
      getAllSpecialities((response) => {
        if (response.status_code == 200) {
          setSpecsData(response.data.speciality_data);
        }
      })
    );

    if (location.state) {
      setErr_msg(location.state.errMsg);
    }

    if (phoneDetail.mobile) {
      setRegistrationInput((prevState) => {
        return {
          ...prevState,
          ['phoneNo']: phoneDetail.mobile
        };
      });
    }
  }, []);

  useEffect(() => {
    if (props?.params?.referal_code) {
      setReferalInputDisabled(true);
      setRegistrationInput((prevState) => {
        return {
          ...prevState,
          ['referalCode']: props.params.referal_code
        };
      });
    }
    if (props?.params?.email) {
      setRegistrationInput((prevState) => {
        return {
          ...prevState,
          ['email']: props.params.email
        };
      });
    }
  }, [props?.params]);

  const [registrationInput, setRegistrationInput] = useState({
    isd_code: '',
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNo: '',
    // pin: '',
    referalCode: '',
    honarific: 'Dr',
    user_type: isUserGlobal ? '' : 1,
    speciality: ''
  });

  const handleChangeRegistrationInput = (e) => {
    setRegistrationInput((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      };
    });
  };

  // let { from } = location.state || { from: { pathname: "/DashBoard" } };

  const sendRegDetails = (e) => {
    e.preventDefault();
    console.log('Hello');

    trackActivityEvent(SUBMIT_CLICK, {});

    let has_error = 0;

    if (isChecked == false) {
      setErr_msg('Please Accept the terms and Conditions');
      has_error = 1;
    }

    // if (registrationInput.pin == '') {
    //   has_error = 1;
    //   setErr_msg('Please Enter the Pin Code');
    // }

    if (registrationInput.phoneNo == '') {
      has_error = 1;
      setErr_msg('Please Enter a Mobile Number');
    } else {
      let regexMob = /^[0-9]{6,13}$/;
      let alpanum = /^[a-z0-9]+$/i;
      if (!registrationInput.phoneNo.match(regexMob) && registrationInput.phoneNo.match(alpanum)) {
        setErr_msg('Please Enter Mobile No in Correct Format');
        has_error = 1;
      }
    }

    if (registrationInput.password.trim() == '') {
      has_error = 1;
      setErr_msg('Please Enter Password');
    } else if (registrationInput.confirmPassword.trim() == '') {
      has_error = 1;
      setErr_msg('Please Enter Confirm Password');
    } else {
      if (registrationInput.password.trim() != registrationInput.confirmPassword.trim()) {
        has_error = 1;
        setErr_msg('Password and confirm password must be same');
      }
    }

    if (registrationInput.user_type == '') {
      has_error = 1;
      setErr_msg('Please Select Your Profession');
    }
    if (registrationInput.email == '') {
      has_error = 1;
      setErr_msg('Please Enter email ID');
    } else {
      var reEmail =
        /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
      if (!registrationInput.email.match(reEmail)) {
        has_error = 1;
        setErr_msg('Please Enter valid email');
      }
    }

    if (registrationInput.fullName == '') {
      has_error = 1;
      setErr_msg('Please Enter Your Name');
    } else {
      var namep = [];
      let temp_name = stringToArray(registrationInput.fullName.trim());
      console.log('FUNCTION_TEMP', temp_name);
      for (const each of temp_name) {
        if (each?.trim()) {
          namep.push(each);
        }
      }
      console.log('NAME_TEMP', namep);
      if (namep.length == 1) {
        has_error = 1;
        setErr_msg('Please Enter Your Last Name');
      }
    }

    if (has_error == 0) {
      formdata.append('name', namep.join(' '));
      formdata.append('email', registrationInput.email);
      formdata.append('phone_no', registrationInput.phoneNo);
      formdata.append('password', registrationInput.password);
      formdata.append('confirm_password', registrationInput.confirmPassword);
      formdata.append('referal_code', registrationInput.referalCode);
      formdata.append('pin', registrationInput.pin);
      formdata.append('honarific', registrationInput.honarific);
      formdata.append('speciality', registrationInput.speciality?.master_specialities_id);
      formdata.append('user_type', registrationInput.user_type);
      formdata.append('isd_code', registrationInput.isd_code?.replace(/\+/g, ''));
      if (getLocalStorage('utm_source', '')) {
        formdata.append('utm_source', getLocalStorage('utm_source', ''));
      }
      if (redirect_type) {
        formdata.append('page_name', redirect_type);
      }
      setIsLoading(true);
      dispatch(
        signUpEmail(formdata, (response) => {
          if (response.status_code == 200) {
            if (response.data.status == 3) {
              setLocalStorage('accessToken', response.data.token);
              setLocalStorage('refreshToken', response.data.refresh_token);
              dispatch(
                authenticateUser(response.data.token, (resp) => {
                  if (resp != 'error') {
                    setIsLoading(false);
                    if (resp.status_code == 200) {
                      // namep = [];
                      dispatch(
                        getVaultList((res) => {
                          if (res != 'error') {
                            log('vaultCount', res.data.length);
                            if (res.data) {
                              setLocalStorage('vaultCount', res.data?.length);
                            } else {
                              setLocalStorage('vaultCount', 0);
                            }
                          }
                        })
                      );
                      setLocalStorage('user', resp.data);
                      if (resp?.data?.menu && safeJsonParser(resp?.data?.menu, {})?.main == 'dnr') {
                        setLocalStorage('main_menu', 'dnr');
                      }

                      dispatch(loginSuccess());
                    }
                  }
                })
              );
            } else {
              setWaitShow(true);
            }
          } else {
            setIsLoading(false);
            if (
              response.message ==
                'A user with this Email already exists. Please enter a different Email.' &&
              response.status_code == 203
            ) {
              setErr_msg(
                'You have already registered with this email address. Please login to proceed.'
              );
              navigate(`/Login?email=${registrationInput.email}`);
            } else {
              setErr_msg(response.message);
            }
          }
        })
      );
    } else {
      // props.tracker.on(
      //   CLICK_REGISTER_BUTTON,
      //   onRegisterButtonClick(props.pageName, '', '', '', '', '', '')
      // );
    }
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const userDet = useSelector((state) => state.common.user_details);
  const isJSON = (text) => {
    if (
      /^[\],:{}\s]*$/.test(
        text
          .replace(/\\["\\\/bfnrtu]/g, '@')
          .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
          .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  // useEffect(() => {
  //   if (userDet) {
  //     // if (typeof props.setBackdrop == 'function') {
  //     //   props.setBackdrop(false);
  //     // }

  //     if (autoAuthLocation.current) {
  //       navigateToDetailsPage(autoAuthLocation.current.type, autoAuthLocation.current.id);
  //     } else {
  //       console.log('props.redirectType===>>>>', props.redirectType);
  //       if (props.redirectType == routeNames.dashboard.landing) {
  //         if (userDet?.menu && isJSON(userDet?.menu) && JSON.parse(userDet?.menu)?.main == 'dnr') {
  //           setLocalStorage('main_menu', 'dnr');
  //           navigate(routeNames.discussRefer.landing, { state: { from_login: true } });
  //         } else {
  //           console.log('exit===<<<<<<<||||>>>>>>===');

  //           navigateToAssociatedPath();
  //         }
  //         console.log('exit===<<<<<<<---->>>>>>===');
  //       } else if (
  //         props.redirectType == routeNames.telemed.landing ||
  //         props.redirectType == routeNames.userProfile.landing
  //       ) {
  //         console.log('exit===<<<<<<<...>>>>>>===', props.redirectType);

  //         navigate(`/${props.redirectType}`);
  //       } else if (props.redirectType == 'dnr') {
  //         console.log('exit===<<<<<<<>>>>>>===', props.redirectType);

  //         navigate(routeNames.discussRefer.landing, {
  //           state: { fromShare: true, from_login: true }
  //         });
  //       } else if (props.redirectType == routeNames.election.landing) {
  //         console.log('exit===<<<<<<<>>>>>>===', props.redirectType);

  //         navigate(`${routeNames.election.landing}/${election_id}`, {
  //           state: { from_login: true }
  //         });
  //       } else {
  //         console.log('exit===<<<<<<<>>>>>>===', id, props.redirectType);
  //         navigate(id ? `${props.redirectType}/${id}` : `${props.redirectType}`, {
  //           state: { from_login: true }
  //         });
  //       }
  //     }
  //   }
  // }, [userDet]);

  const navigateToAssociatedPath = () => {
    // alert('navigateToAssociatedPath===<<<<<<<>>>>>>==='+ redirect_type);

    if (props?.to?.pathname == '/TrainingContent') {
      navigate({ ...props.to, pathname: '/TrainingCover' }, { state: { from_login: true } });
    } else if (props?.to?.pathname?.includes(routeNames.course.content)) {
      navigate(
        {
          ...props.to,
          pathname: props?.to?.pathname?.replace(routeNames.course.content, routeNames.course.cover)
        },
        { state: { from_login: true } }
      );
    } else {
      console.log('navigateToAssociatedPath else===<<<<<<<>>>>>>===', props.to, redirect_type);
      if (props.to) navigate(props.to, { state: { from_login: true } });
      else navigate(routeNames.dashboard.landing, { state: { from_login: true } });
    }
  };

  const navigateToDetailsPage = (content_type, content_id) => {
    removeSessionStorage('autoauth_location');
    if (content_type == 'session') {
      navigate(`${routeNames.sessions.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'medwiki') {
      navigate(`${routeNames.medwiki.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'archived_video') {
      navigate(`${routeNames.clinicalVideo.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'survey') {
      navigate(`${routeNames.spq.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'gr') {
      navigate(`${routeNames.grandRound.detail}/${content_id}`, { state: { from_login: true } });
    } else if (content_type == 'epub') {
      navigate(`${routeNames.epaper.detail}/${content_id}`, { state: { from_login: true } });
    } else {
      navigate(routeNames.dashboard.landing, { state: { from_login: true } });
    }
  };

  useEffect(() => {
    if (getSessionStorage('autoauth_location', '')) {
      let temp = getSessionStorage('autoauth_location', '')?.split(',');
      console.log('temp', temp);
      if (temp?.length > 1) {
        autoAuthLocation.current = { type: temp[0], id: temp[1] };
      }
    }

    return () => {};
  }, []);

  const onUserTypeChange = (e, id) => {
    setRegistrationInput((prevState) => {
      return {
        ...prevState,
        [e.target.name]: id
      };
    });
  };

  return (
    <div className="w-100 text-start shareLogin rounded pt-5 px-4 pb-5 shadow position-relative overflow-hidden d-flex justify-content-center align-items-center">
      <div className="rounded-circle bg-white bg-opacity-10 shareLoginCircle position-absolute"></div>
      <div className="w-100 shareLogin_In text-white position-relative z-1">
        <h2 className="fs-1 fw-bold text-white text-uppercase mb-3">Register Now</h2>
        <p className="fs-4 fw-normal">
          This platform is meant for Registered Medical practitioners only.
        </p>
        <div className="w-100 shareLoginForm mt-3">
          <form>
            <div className="row mt-n3 m-0">
              <div className="col-12 shareLoginFormRow shareLoginMobileNo rounded mt-4 position-relative p-0">
                <SelectHonarific
                  handleChangeHonarific={handleChangeRegistrationInput}
                  gtmTagVariable={props.gtmTagVariable}
                />
                <FloatingLabel controlId="floatingInput" label="Full Name*">
                  <Form.Control
                    className={`fs-4 border-0 ${props.gtmTagVariable}_registrationForm_fullName`}
                    type="text"
                    placeholder="Full Name"
                    name="fullName"
                    onChange={handleChangeRegistrationInput}
                  />
                </FloatingLabel>
              </div>
              <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <FloatingLabel controlId="floatingInput1" label="Email*">
                  <Form.Control
                    className={`fs-4 border-0 ${props.gtmTagVariable}_registrationForm_email`}
                    type="email"
                    placeholder="Email*"
                    name="email"
                    value={registrationInput.email}
                    onChange={handleChangeRegistrationInput}
                  />
                </FloatingLabel>
              </div>
              <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <FloatingLabel controlId="floatingInput2" label="Password*">
                  <Form.Control
                    className={`fs-4 border-0 ${props.gtmTagVariable}_registrationForm_password`}
                    type="password"
                    placeholder="Password*"
                    name="password"
                    onChange={handleChangeRegistrationInput}
                  />
                </FloatingLabel>
              </div>
              <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <FloatingLabel controlId="floatingInput3" label="Confirm Password*">
                  <Form.Control
                    className={`fs-4 border-0 ${props.gtmTagVariable}_registrationForm_confirm_password`}
                    type="password"
                    placeholder="Confirm Password*"
                    name="confirmPassword"
                    onChange={handleChangeRegistrationInput}
                  />
                </FloatingLabel>
              </div>

              <div className="col-12 shareLoginFormRow shareLoginMobileNo rounded mt-4 position-relative p-0">
                <div className="shareLoginIsd position-absolute top-0 start-0 h-100 bg-white text-dark border-1 border-end border-light">
                  <ISDPicker
                    className="w-100 h-100 pe-5 ps-3 form-control border-0 registerSelec text-center"
                    onChange={handleChangeRegistrationInput}
                  />

                  {/* <IoIosArrowDown
                    customClass={'fs-16 position-absolute top-50 translate-middle-y'}
                  /> */}
                </div>
                <FloatingLabel controlId="floatingInput" label="Enter Mobile No.*">
                  <Form.Control
                    className={`fs-4 border-0 ${props.gtmTagVariable}_registrationForm_phNumber`}
                    name="phoneNo"
                    type="text"
                    placeholder="Enter Mobile No."
                    pattern="[6789][0-9]{9}"
                    value={registrationInput.phoneNo}
                    onChange={handleChangeRegistrationInput}
                  />
                </FloatingLabel>
              </div>

              {/* <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <FloatingLabel controlId="floatingInput5" label="Pin*">
                  <Form.Control
                    className={`fs-4 border-0 ${props.gtmTagVariable}_registrationForm_pin`}
                    type="text"
                    placeholder="Pin*"
                    name="pin"
                    onChange={handleChangeRegistrationInput}
                  />
                </FloatingLabel>
              </div> */}
              <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <FloatingLabel controlId="floatingInput4" label="Referral Code">
                  <Form.Control
                    disabled={referalInputDisabled}
                    className={`fs-4 border-0 ${props.gtmTagVariable}_registrationForm_referralCode`}
                    type="text"
                    placeholder="Referral Code"
                    name="referalCode"
                    value={registrationInput.referalCode}
                    onChange={handleChangeRegistrationInput}
                  />
                </FloatingLabel>
              </div>

              {isUserGlobal ? (
                <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                  <p className="fs-4 fw-normal">Select your profession</p>
                  <div className="row ms-n3 mt-n3 me-0">
                    {[
                      {
                        id: 1,
                        title: 'Doctor',
                        icon: doctor
                      },
                      { id: 11, title: 'Nurse', icon: nurse },
                      { id: 13, title: 'Pharmacist', icon: pharmacist },
                      { id: 12, title: 'Other', icon: other }
                    ].map((_r, _idx) => (
                      <div className="col-6 ps-3 pt-3 pe-0" key={_idx}>
                        <div className="clr_v3_checkbox position-relative overflow-hidden clr_v3_checkbox__specialityCard">
                          <input
                            type="radio"
                            name="user_type"
                            id={`checkbox_${_idx}`}
                            value={_r.title}
                            checked={registrationInput.user_type == _r.id}
                            onChange={(e) => {
                              setRegistrationInput({
                                ...registrationInput,
                                speciality: { master_specialities_id: 0 }
                              });
                              onUserTypeChange(e, _r.id);
                            }}
                            className="clr_v3_checkbox__input position-absolute start-0 top-0 w-100 h-100 z-3 opacity-0"
                          />
                          <label
                            htmlFor={`checkbox_${_idx}`}
                            className="clr_v3_checkbox__label position-relative overflow-hidden rounded-3 p-3 border border-105 border-light w-100 h-100"
                          >
                            <div className="overlay z-1 bg-primary"></div>
                            <div className="clr_v3_checkbox__content d-flex flex-column justify-conntent-between">
                              <div className="d-flex align-items-center justify-content-center p-2 clr_v3_checkbox__specialityIcon mb-3">
                                <img src={_r.icon} alt={_r.title} className="w-100 h-100" />
                              </div>
                              <span className="flex-1 mt-auto">{_r.title}</span>
                            </div>
                            <div className="clr_v3_checkbox__icon d-flex align-items-center justify-content-center z-2 rounded-circle position-absolute start-50 top-50">
                              <i className="flaticon-check fs-30"></i>
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {parseInt(registrationInput.user_type) === 1 && (
                <div className="col-12 shareLoginFormRow text-center cursorPointer mt-5 mb-3 position-relative p-0">
                  <div
                    className={`w-100 text-uppercase fs-4 fw-normal text-white ${props.gtmTagVariable}_registrationForm_selectSpecialities`}
                    onClick={() => handleShow()}
                  >
                    {registrationInput?.speciality != '' &&
                    'specialities_name' in registrationInput?.speciality
                      ? `${registrationInput?.speciality?.specialities_name}- Change Speciality`
                      : ' + Select Specialities'}
                  </div>
                </div>
              )}
              <div className="col-12 shareLoginFormRow text-white mt-5 position-relative p-0  d-flex align-items-center">
                <Form.Check
                  type={'checkbox'}
                  className={` d-inline-block ${props.gtmTagVariable}_registrationForm_i_accept`}
                  id={'tnc'}
                  checked={isChecked}
                  onChange={() => {
                    SetIsChecked(!isChecked);
                  }}
                  label={<span className="text-white">I Accept</span>}
                />
                <a
                  className={`d-inline-block ms-3 text-secondary ${props.gtmTagVariable}_registrationForm_termsConditions`}
                  style={{ verticalAlign: 'top' }}
                  target="_blank"
                  // href="https://doctor.clirnet.com/knowledge/registration/terms_page"
                  href="https://www.clirnet.com/terms-and-conditions"
                >
                  Terms and Conditions
                </a>
              </div>
              <div className="col-12 shareLoginFormRow mt-4 position-relative p-0">
                <button
                  className={`w-100 fs-16 fw-medium text-uppercase btn btn-secondary rounded-pill ${props.gtmTagVariable}_registrationForm_dnrRegisterRequestAccess`}
                  onClick={(e) => sendRegDetails(e)}
                  aria-label="Submit"
                  disabled={isLoading}
                >
                  {isLoading
                    ? 'Please wait...'
                    : redirect_type == 'Dnr' || UserRegion == 'GT' || UserRegion == 'GL'
                    ? 'Register'
                    : 'Request Access'}
                </button>
              </div>

              <div className="col-12 shareLoginFormRow text-danger text-center mt-4 position-relative p-0">
                {err_msg}
              </div>
              <div className="col-12 shareLoginFormRow text-center cursorPointer mt-4 position-relative p-0">
                <a
                  className={`fs-4 fw-normal text-secondary ${props.gtmTagVariable}_registrationForm_alreadyRegisteredLogin`}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  Already Registered? Login
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal
        className="rightModal medWikiFilterModal p-0"
        size="sm"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="bg-primary position-relative rounded-0 border-0 p-4">
          <Modal.Title className="text-white fs-3">Select Specialities</Modal.Title>
          <span
            className="modalClose rounded-circle cursorPointer text-white position-absolute top-50 translate-middle-y d-flex justify-content-center align-items-center"
            onClick={handleClose}
          >
            ok
            {/* <RiCloseLine className="fs-1" /> */}
          </span>
        </Modal.Header>
        <Modal.Body className="py-20 px-4 overflow-y-auto bg-white">
          {console.log('SPECIALITY', specsData)}
          {specsData && specsData.length > 0
            ? specsData?.map((singleSpec, specInd) => (
                <div className="medWikiFilterModalCheck" key={specInd + 1}>
                  <Form.Check
                    className={`${props.gtmTagVariable}_registrationForm_speciality_select`}
                    name="specialitySelector"
                    type="radio"
                    checked={
                      registrationInput.speciality?.master_specialities_id ==
                      singleSpec.master_specialities_id
                    }
                    label={singleSpec.specialities_name}
                    id={singleSpec.master_specialities_id}
                    onChange={() =>
                      setRegistrationInput({ ...registrationInput, speciality: singleSpec })
                    }
                  />
                </div>
              ))
            : 'Unable To Show Data'}
          {/* {specsData.length > 0
            ? specsdata?.map((singleSpec, specInd) => (
                <div className="medWikiFilterModalCheck" key={specInd + 1}>
                  {SelecSpecilalities.indexOf(singleSpec.master_specialities_id) != -1 ? (
                    <Form.Check
                      className={`${props.gtmTagVariable}_registrationForm_speciality_select`}
                      name="specialitySelector"
                      type="radio"
                      checked
                      label={singleSpec.specialities_name}
                      id={singleSpec.master_specialities_id}
                      onChange={() =>
                        SelectedSpeciality(singleSpec.master_specialities_id, specInd)
                      }
                    />
                  ) : (
                    <Form.Check
                      className={`${props.gtmTagVariable}_registrationForm_speciality_select`}
                      type="radio"
                      name="specialitySelector"
                      label={singleSpec.specialities_name}
                      id={singleSpec.master_specialities_id}
                      onChange={() => {
                        SelectedSpeciality(singleSpec.master_specialities_id, specInd);
                      }}
                    />
                  )}
                </div>
              ))
            : 'Unable To Show Data'} */}
        </Modal.Body>
      </Modal>
      <Modal
        className="centerModal"
        size="md"
        centered
        show={waitShow}
        onHide={() => setWaitShow(false)}
      >
        <Modal.Header className="position-relative p-4 border-0 mb-1">
          <Modal.Title className="fs-16 text-black fw-semibold">Please Note</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <form>
            <div className="w-100 centerModalFrm">
              <div className="row mt-n4">
                <div className="w-100 text-center fw-medium fs-4 my-2 text-black">
                  Your request has been recorded. You shall hear from us shortly.
                </div>
                <div className="w-100 text-center fw-medium fs-4 my-2 text-black">
                  *Direct access is restricted in order to allow only Registered Healthcare
                  Professionals to view the content
                </div>
                <div className="mt-4 d-flex flex-wrap">
                  <button
                    className={`col-12 fs-16 btn btn-primary py-3 px-4  ${props.gtmTagVariable}_registrationForm_know_more`}
                    type="button"
                    onClick={() => {
                      window.location.href = ' https://www.clirnet.com/newly-featured';
                    }}
                    aria-label="Know More"
                  >
                    Know More
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegistrationForm;

function SelectHonarific({ handleChangeHonarific, gtmTagVariable }) {
  return (
    <div className="shareLoginIsd position-absolute top-0 start-0 h-100 bg-white text-dark border-1 border-end border-light">
      <Form.Select
        className="w-100 h-100 pe-5 ps-3 form-control border-0 registerSelect"
        name="honarific"
        defaultChecked="Dr"
        onChange={handleChangeHonarific}
      >
        <option value="Dr" className={`${gtmTagVariable}_registrationForm_honarific_select`}>
          Dr
        </option>
        <option value="Mr" className={`${gtmTagVariable}_registrationForm_honarific_select`}>
          Mr
        </option>
        <option value="Mrs" className={`${gtmTagVariable}_registrationForm_honarific_select`}>
          Ms
        </option>
        <option value="Mrs" className={`${gtmTagVariable}_registrationForm_honarific_select`}>
          Mrs
        </option>
      </Form.Select>

      {/* <IoIosArrowDown
    customClass={'fs-16 position-absolute top-50 translate-middle-y'}
  /> */}
    </div>
  );
}
