import React, { memo, useCallback, useState } from 'react';

import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
// import '../../../components/ContentSlider/css/contentSlider.scss';
// import './css/medwikiLandingV2.scss';

import '../../components/ContentSlider/css/contentSlider.scss';
import '../../pages/medwikiv2/css/medwikiLandingV2.scss';

import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { pageTitles } from '../../common/pageTitles';
import useContentAccessRedirect from '../../common/useContentAccessRedirect';
import { BannerItem } from '../../components/banner';
import RelatedCard from '../../components/baseUiV3/contentCards/RelatedCard';
import useGetParams from '../../components/customHooks/useGetParams';
import useManualScrollRestoration from '../../components/customHooks/useManualScrollRestoration';
import useRedirect from '../../components/customHooks/useRedirect';
import InactiveComponent from '../../components/InactiveComponent';
import { PaginationLoader } from '../../components/loader';
import NoDataFound from '../../components/NoDataFound';
import PopularSpeciality from '../../components/popularSpecialityBox/PopularSpeciality';
import { Seo } from '../../components/seo/Seo';
import { MORE_CLICK, PAGE_VIEW } from '../../components/tracking/constants';
import MedwikiLandingFeatureCard from '../../pages/uiv3/medwikiV2/MedwikiLandingComponents/MedwikiLandingFeatureCard';
import MedwikiLandingFeaturedLoader from '../../skeleton/medwiki/MedwikiLandingV2/MedwikiLandingFeaturedLoader';
import PopularSpecialityLoader from '../../skeleton/medwiki/MedwikiLandingV2/PopularSpecialityLoader';
import MedwikiLoader from '../../skeleton/medwiki/medwikiLoader';
import { TrackerComponent } from '../../TrackerFolder';
import { useTracker } from '../../TrackerFolder/TrackerComponent';
import { activeStatusCode } from '../../utils/constants/common.constant';
import { generateProps } from '../../utils/generateProps';
import { routeNames } from '../constants';
SwiperCore.use([Pagination, Navigation]);
const pageName = pageTitles.medwiki.landing;
const ListingPageBackground = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onArrowClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);
  useManualScrollRestoration();
  const featuredLoader = false;
  const medwikiList = {
    data: [
      {
        slno: 1,
        con_type: 'text',
        is_locked: {
          currency: 'USD',
          display_icon: false,
          content_access: true
        },
        price: null,
        user_content_payment: null,
        env: 2,
        vendor: 'others',
        src: 'null',
        type_id: 34467,
        is_share: 1,
        type: 'comp',
        date: ' 28th March 25',
        question: '<p><strong>What should be the treatment for diarrhoea?</strong></p>',
        image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1735636736_202.jpg',
        color: '#918c91',
        answer: `<p>The treatment of diarrhoea depends on the nature of the underlying cause and the overall nutritional status of the patient. Rehydration is the most important treatment for diarrhoea. Administration of oral rehydration solution (ORS) is the most common and readily available form of rehydration therapy. However, if oral rehydration is not sufficient, then intravenous rehydration will be needed.&nbsp;</p>

<p>As most commonly occurring diarrhoea are caused by viral infections and are usually self-limiting, antibiotic treatment is seldom required. To ensure that, rapid identification of viral origin will be helpful. However, antibiotics have to be used in some rare and severe cases of diarrhoea. Moreover, antibiotic treatment has been found to effectively reduce symptoms of travellers&rsquo; diarrhoea.<sup data-footnote-id=""vguhg""><a href=""#footnote-1"" id=""footnote-marker-1-1"" rel=""footnote"">[1]</a></sup>Fluoroquinolones are the preferred antibiotics for treating diarrhoea.&nbsp;</p>

<p>The treatment of chronic diarrhoea depends on the nature of the underlying cause. In such cases, the underlying cause has to be rectified to cure diarrhoea. For ulcerative colitis, the drug Mesalamine can be used. Depending on the degree of severity, corticosteroids can be used as well. If carcinoid syndrome is responsible for diarrhoea, resection of the tumour can be done. If diarrhoea is caused due to food allergy, certain foods should be avoided. In patients with malabsorption, it is important to maintain the nutritional status by the supplementation of fat-soluble vitamins- Vitamin A, Vitamin D, Vitamin E and Vitamin K. Zinc supplementation is also required for treating diarrhoeal episodes in children.</p>

<section class=""footnotes"">
<header>
<h2>Citation</h2>
</header>

<ol>
	<li data-footnote-id=""vguhg"" id=""footnote-1""><sup><a href=""#footnote-marker-1-1"">^</a> </sup><cite>Drancourt M. Acute Diarrhea.&nbsp;Infectious Diseases. 2017;335-340.e2. doi:10.1016/B978-0-7020-6285-8.00038-1https:<a href=""http://www.ncbi.nlm.nih.gov/pmc/articles/PMC7148607/"">//www.ncbi.nlm.nih.gov/pmc/articles/PMC7148607/</a></cite></li>
</ol>
</section>
`,
        specialities: 'Gastroenterology,Radiology',
        specialities_ids_and_names: [
          {
            id: '1281',
            name: 'Radiology'
          },
          {
            id: '8',
            name: 'Gastroenterology'
          }
        ],
        sponsor_name: null,
        sponsor_id: null,
        sponsor_logo: '',
        all_sponsor: [],
        comment_count: 0,
        rating: 40,
        myrating: false,
        vault: 0,
        deeplink: 'https://uat.clrn.in?l=q7fHX'
      },
      {
        slno: 1,
        con_type: 'text',
        is_locked: {
          currency: 'USD',
          display_icon: false,
          content_access: true
        },
        price: null,
        user_content_payment: null,
        env: 2,
        vendor: 'others',
        src: 'null',
        type_id: 34467,
        is_share: 1,
        type: 'comp',
        date: ' 28th March 25',
        question:
          '<p><strong>What is the role of Racecadotril and Loperamide in the treatment of acute diarrhoea?</strong></p>',
        image: 'https://storage.googleapis.com/medwiki/43_server/images/1698834295_13479.png',
        color: '#918c91',
        answer: `<p><span style="font-size:14px"><span style="color:#000000">Diarrhoea is a condition of passing loose or watery stools three or more times a day. This can result in a big loss of fluid and electrolytes from the body. Therefore, medications for stopping the loose motion are often required. For that purpose, drugs like Racecadotril and Loperamide are sometimes used in the treatment of acute diarrhoea.Â </span></span></p><p><span style="font-size:14px"><span style="color:#000000">Racecadotril selectively inhibits the enzyme neutral endopeptidase, whose normal activity is to promote secretion. Racecadotril is an antisecretory agent that may prevent fluid/electrolyte depletion from the bowel due to acute diarrhoea without affecting intestinal motility.<sup data-footnote-id="62uyr"><a href="#footnote-1" id="footnote-marker-1-1" rel="footnote">[1]</a></sup>Racecadotril administration is supposed to decrease stool frequency within 24 hours to reduce symptoms of acute diarrhoea.Â </span></span></p><p><span style="font-size:14px"><span style="color:#000000">Loperamide is used in the treatment of travelerâ€™s diarrhoea, irritable bowel syndrome associated with chronic diarrhoea, and other types of diarrhoea. It is a synthetic phenylpiperidine opioid that acts on mu-opioid receptors on intestinal muscles and inhibits peristalsis.<sup data-footnote-id="jvuh3"><a href="#footnote-2" id="footnote-marker-2-1" rel="footnote">[2]</a></sup>Loperamide also prevents loss of electrolytes and increases rectal tone. Loperamide can thus decrease stool frequency and is recommended only in the initial stage of acute diarrhoea associated with frequent watery stool.</span></span></p>`,
        specialities: 'Gastroenterology,Radiology',
        specialities_ids_and_names: [
          {
            id: '1281',
            name: 'Radiology'
          },
          {
            id: '8',
            name: 'Gastroenterology'
          }
        ],
        sponsor_name: null,
        sponsor_id: null,
        sponsor_logo: '',
        all_sponsor: [],
        comment_count: 0,
        rating: 40,
        myrating: false,
        vault: 0,
        deeplink: 'https://uat.clrn.in?l=q7fHX'
      }
    ],
    statusCode: 200
  };
  const popularLoader = false;

  const medwikiPopularList = {
    data: [
      {
        slno: 1,
        con_type: 'text',
        is_locked: {
          currency: 'USD',
          display_icon: false,
          content_access: true
        },
        price: null,
        user_content_payment: null,
        env: 2,
        vendor: 'others',
        src: 'null',
        type_id: 35436,
        is_share: 1,
        type: 'comp',
        date: ' 28th March 25',
        question: 'Integrating artificial intelligence in stroke care ',
        image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1743151217_CLI.jpg',
        color: '#918c91',
        answer:
          'Stroke is a leading global cause of death and disability, with over 5.5 million deaths annually. Mortality rates are estimated to reach 10% within 30 days and 40% within a year of an ischaemic stroke. In India, the incidence of stroke is rising, highlighting the need for improved care. In the presen',
        specialities: 'Cerebrovascular disease,Neurology',
        specialities_ids_and_names: [
          {
            id: '1205',
            name: 'Cerebrovascular disease'
          },
          {
            id: '13',
            name: 'Neurology'
          }
        ],
        sponsor_name: null,
        sponsor_id: null,
        sponsor_logo: '',
        all_sponsor: [],
        comment_count: 0,
        rating: 47,
        myrating: false,
        vault: 0,
        deeplink: 'https://uat.clrn.in?l=hkEaa'
      },
      {
        slno: 2,
        con_type: 'text',
        is_locked: {
          currency: 'USD',
          display_icon: false,
          content_access: true
        },
        price: null,
        user_content_payment: null,
        env: 2,
        vendor: 'others',
        src: 'null',
        type_id: 35426,
        is_share: 1,
        type: 'comp',
        date: ' 26th March 25',
        question: 'Probiotics for diarrhoea: understanding their role and benefits',
        image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1742988021_CLI.jpg',
        color: '#918c91',
        answer:
          'Diarrhoea may be acute, persistent, or chronic. Acute diarrhoea is usually infectious, often viral. Prebiotics and probiotics modulate gut microbiota, benefiting gastrointestinal health. Probiotics, such as Saccharomyces boulardii, Lactobacillus, and Bifidobacterium strains, can reduce the duration ',
        specialities: 'Gastroenterology,General Medicine',
        specialities_ids_and_names: [
          {
            id: '1252',
            name: 'General Medicine'
          },
          {
            id: '8',
            name: 'Gastroenterology'
          }
        ],
        sponsor_name: null,
        sponsor_id: null,
        sponsor_logo: '',
        all_sponsor: [],
        comment_count: 0,
        rating: 46,
        myrating: false,
        vault: 0,
        deeplink: 'https://uat.clrn.in?l=V9diB'
      },
      {
        slno: 3,
        con_type: 'text',
        is_locked: {
          currency: 'USD',
          display_icon: false,
          content_access: true
        },
        price: null,
        user_content_payment: null,
        env: 2,
        vendor: 'others',
        src: 'null',
        type_id: 35376,
        is_share: 1,
        type: 'comp',
        date: ' 18th March 25',
        question:
          'Gastric outlet obstruction due to peptic ulcer disease in a 5-year-old: A case report',
        image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1742275314_CLI.jpg',
        color: '#918c91',
        answer:
          'A 5-year-old female presented with a 3-month history of projectile vomiting of undigested food, which was non-bilious and occurring every 4 to 5 hours with a frequency of 1 to 2 times per day.\r\n\r\nClinical presentation:\r\n\r\n\r\n\tTwo weeks before the presentation, the vomiting had worsened and become mor',
        specialities: 'Paediatrics,Paediatrics Gastroenterology',
        specialities_ids_and_names: [
          {
            id: '12',
            name: 'Paediatrics'
          },
          {
            id: '145',
            name: 'Paediatrics Gastroenterology'
          }
        ],
        sponsor_name: null,
        sponsor_id: null,
        sponsor_logo: '',
        all_sponsor: [],
        comment_count: 0,
        rating: 47,
        myrating: false,
        vault: 0,
        deeplink: 'https://clrn.in?l=bZ70u'
      },
      {
        slno: 4,
        con_type: 'text',
        is_locked: {
          currency: 'USD',
          display_icon: false,
          content_access: true
        },
        price: null,
        user_content_payment: null,
        env: 2,
        vendor: 'others',
        src: 'null',
        type_id: 35378,
        is_share: 1,
        type: 'comp',
        date: ' 18th March 25',
        question: 'A case report on gastric diverticulum: an uncommon cause of dyspepsia',
        image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1742275851_CLI.jpg',
        color: '#918c91',
        answer:
          'A 58-year-old male without any known medical disease presented to the surgical outpatient clinic with a 1 week-history of abdominal pain, pricking in nature at the epigastric region. It was also associated with a retrosternal burning sensation and acidic brash exacerbating after meals. \r\n\r\nPatient h',
        specialities: 'Gastroenterology',
        specialities_ids_and_names: [
          {
            id: '8',
            name: 'Gastroenterology'
          }
        ],
        sponsor_name: null,
        sponsor_id: null,
        sponsor_logo: '',
        all_sponsor: [],
        comment_count: 0,
        rating: 32,
        myrating: false,
        vault: 0,
        deeplink: 'https://clrn.in?l=2GEgs'
      },
      {
        slno: 5,
        con_type: 'text',
        is_locked: {
          currency: 'USD',
          display_icon: false,
          content_access: true
        },
        price: null,
        user_content_payment: null,
        env: 2,
        vendor: 'others',
        src: 'null',
        type_id: 35377,
        is_share: 1,
        type: 'comp',
        date: ' 18th March 25',
        question:
          'Contained perforated peptic ulcer with pancreatic communication leading to haemorrhagic shock: a case report ',
        image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1742275542_CLI.jpg',
        color: '#918c91',
        answer:
          'A 48-year-old male presented with a 2-day history of epigastric pain, haematemesis, and melena. \r\n\r\nPatient history:\r\n\r\n\r\n\tThe patient had a past medical history of chronic pain associated with the daily use of NSAIDs. \r\n\tThe patient was a regular tobacco user. \r\n\tThe patient also reported a history',
        specialities: 'Critical Care,Gastroenterology',
        specialities_ids_and_names: [
          {
            id: '25',
            name: 'Critical Care'
          },
          {
            id: '8',
            name: 'Gastroenterology'
          }
        ],
        sponsor_name: null,
        sponsor_id: null,
        sponsor_logo: '',
        all_sponsor: [],
        comment_count: 0,
        rating: 43,
        myrating: false,
        vault: 0,
        deeplink: 'https://clrn.in?l=vjdzy'
      }
    ],
    statusCode: 200
  };

  const specialityLoader = false;
  const medwikiSpecialities = {
    data: [
      {
        total: 6394,
        specialities_id: 6,
        master_specialities_id: 6,
        specialities_name: 'Internal Medicine',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/6_InternalMedicine.svg'
      },
      {
        total: 5040,
        specialities_id: 5,
        master_specialities_id: 5,
        specialities_name: 'Obstetrics & Gynaecology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/5_Gynaecology.svg'
      },
      {
        total: 4721,
        specialities_id: 12,
        master_specialities_id: 12,
        specialities_name: 'Paediatrics',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/12_Paediatrics.svg'
      },
      {
        total: 4016,
        specialities_id: 3,
        master_specialities_id: 3,
        specialities_name: 'Cardiology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/3_Cardiology.svg'
      },
      {
        total: 3671,
        specialities_id: 8,
        master_specialities_id: 8,
        specialities_name: 'Gastroenterology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/8_Gastroenterology.svg'
      },
      {
        total: 3567,
        specialities_id: 1281,
        master_specialities_id: 1281,
        specialities_name: 'Radiology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/50_Radiology.svg'
      },
      {
        total: 2780,
        specialities_id: 15,
        master_specialities_id: 15,
        specialities_name: 'Oncology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/15_Oncology.svg'
      },
      {
        total: 2774,
        specialities_id: 1266,
        master_specialities_id: 1266,
        specialities_name: 'Interventional Radiology',
        icon: null
      },
      {
        total: 2723,
        specialities_id: 13,
        master_specialities_id: 13,
        specialities_name: 'Neurology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/13_Neurology.svg'
      },
      {
        total: 2507,
        specialities_id: 7,
        master_specialities_id: 7,
        specialities_name: 'Pulmonology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/7_Pulmonology.svg'
      },
      {
        total: 2338,
        specialities_id: 1,
        master_specialities_id: 1,
        specialities_name: 'Diabetology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/1_Diabetology.svg'
      },
      {
        total: 2225,
        specialities_id: 1252,
        master_specialities_id: 1252,
        specialities_name: 'General Medicine',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/25_General.svg'
      },
      {
        total: 1910,
        specialities_id: 25,
        master_specialities_id: 25,
        specialities_name: 'Critical Care',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/24_CriticalCare.svg'
      },
      {
        total: 1874,
        specialities_id: 4,
        master_specialities_id: 4,
        specialities_name: 'Nephrology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/4_Nephrology.svg'
      },
      {
        total: 1664,
        specialities_id: 9,
        master_specialities_id: 9,
        specialities_name: 'Orthopaedics',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/9_Orthopaedics.svg'
      },
      {
        total: 1521,
        specialities_id: 23,
        master_specialities_id: 23,
        specialities_name: 'Urology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/23_Urology.svg'
      },
      {
        total: 1469,
        specialities_id: 1276,
        master_specialities_id: 1276,
        specialities_name: 'Pathology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/351_Pathology.svg'
      },
      {
        total: 1353,
        specialities_id: 22,
        master_specialities_id: 22,
        specialities_name: 'Dermatology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/22_Dermatology.svg'
      },
      {
        total: 1276,
        specialities_id: 17,
        master_specialities_id: 17,
        specialities_name: 'Haematology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/17_Haematology.svg'
      },
      {
        total: 1252,
        specialities_id: 11,
        master_specialities_id: 11,
        specialities_name: 'Endocrinology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/11_Endocrinology.svg'
      },
      {
        total: 1210,
        specialities_id: 14,
        master_specialities_id: 14,
        specialities_name: 'Allergy & Immunology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/14_Immunology.svg'
      },
      {
        total: 1206,
        specialities_id: 10,
        master_specialities_id: 10,
        specialities_name: 'Hepatology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/10_Hepatology.svg'
      },
      {
        total: 1132,
        specialities_id: 19,
        master_specialities_id: 19,
        specialities_name: 'General Surgery',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/19_GeneralSurgery.svg'
      },
      {
        total: 1093,
        specialities_id: 18,
        master_specialities_id: 18,
        specialities_name: 'Psychiatry',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/18_Psychiatry.svg'
      },
      {
        total: 995,
        specialities_id: 2,
        master_specialities_id: 2,
        specialities_name: 'Rheumatology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/2_Rheumatology.svg'
      },
      {
        total: 940,
        specialities_id: 16,
        master_specialities_id: 16,
        specialities_name: 'ENT',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/16_ENT.svg'
      },
      {
        total: 746,
        specialities_id: 21,
        master_specialities_id: 21,
        specialities_name: 'Ophthalmology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/21_Ophthalmology.svg'
      },
      {
        total: 365,
        specialities_id: 24,
        master_specialities_id: 24,
        specialities_name: 'Clinical Pharmacology',
        icon: null
      },
      {
        total: 352,
        specialities_id: 26,
        master_specialities_id: 26,
        specialities_name: 'Community Medicine',
        icon: null
      },
      {
        total: 265,
        specialities_id: 1272,
        master_specialities_id: 1272,
        specialities_name: 'Geriatrics',
        icon: null
      },
      {
        total: 263,
        specialities_id: 29,
        master_specialities_id: 29,
        specialities_name: 'General',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/25_General.svg'
      },
      {
        total: 242,
        specialities_id: 1259,
        master_specialities_id: 1259,
        specialities_name: 'Anesthesiology',
        icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/345_Anesthesiology.svg'
      },
      {
        total: 227,
        specialities_id: 1270,
        master_specialities_id: 1270,
        specialities_name: 'Molecular Biology',
        icon: null
      },
      {
        total: 87,
        specialities_id: 28,
        master_specialities_id: 28,
        specialities_name: 'Medico Legal',
        icon: null
      },
      {
        total: 24,
        specialities_id: 1267,
        master_specialities_id: 1267,
        specialities_name: 'Forensic Science',
        icon: null
      },
      {
        total: 12,
        specialities_id: 1275,
        master_specialities_id: 1275,
        specialities_name: 'Dentistry',
        icon: null
      },
      {
        total: 3,
        specialities_id: 1257,
        master_specialities_id: 1257,
        specialities_name: 'Medical geneticist',
        icon: null
      }
    ],
    statusCode: 200
  };

  return (
    <>
      <div className="w-100 float-start desktopBodyIn">
        <div className="container-fluid">
          <TrackerComponent page_name={pageName} module_page="medwiki" type={PAGE_VIEW} />
          <Seo title={`CLIRNET - ${pageName}`} url={window.location.href} />
          {/* <BannerWorker page_name={'comp'} /> */}
          <div className="w-100 topBanner">
            <BannerItem position={'top'} page_name={'comp'} />
          </div>
          <section className="w-100 cmnPageContent">
            {/* common for all page */}
            <div className="w-100 float-start medwikiLandingV2">
              <div className="medwikiLandingTop mb-4 position-relative d-flex flex-column flex-lg-row justify-content-between">
                {featuredLoader ? (
                  <MedwikiLandingFeaturedLoader />
                ) : medwikiList && medwikiList?.statusCode === activeStatusCode ? (
                  medwikiList &&
                  medwikiList?.data &&
                  Array.isArray(medwikiList?.data) &&
                  medwikiList?.data?.length > 0 ? (
                    <MedwikiLandingFeatureCard
                      data={medwikiList?.data}
                      statusCode={medwikiList?.statusCode}
                      popularData={medwikiPopularList?.data}
                      isLoading={featuredLoader && popularLoader}
                      extraClass="medwiki_feature_tour"
                      extraClassForPopular="medwiki_popular_tour"
                    />
                  ) : (
                    <NoDataFound sectionRemove={true} />
                  )
                ) : (
                  <InactiveComponent statuscode={medwikiList && medwikiList?.statusCode} />
                )}

                {specialityLoader ? (
                  <PopularSpecialityLoader />
                ) : medwikiSpecialities && medwikiSpecialities?.statusCode === activeStatusCode ? (
                  medwikiSpecialities &&
                  medwikiSpecialities?.data &&
                  Array.isArray(medwikiSpecialities?.data) &&
                  medwikiSpecialities?.data?.length > 0 ? (
                    <PopularSpeciality
                      data={medwikiSpecialities?.data}
                      statusCode={medwikiSpecialities?.statusCode}
                      isLoading={specialityLoader}
                      title={'Browse By Specialities'}
                      numberOfItems={isMobile ? (isExpanded ? 12 : 4) : 13}
                      extraClass="medwiki_speciality_tour"
                      isExpanded={isExpanded}
                      onArrowClick={onArrowClick}
                      gtmTagVariable={'gtm_cl_medwiki_landing_browse_by_specialities'}
                    />
                  ) : (
                    <NoDataFound sectionRemove={true} />
                  )
                ) : (
                  <InactiveComponent
                    statuscode={medwikiSpecialities && medwikiSpecialities?.statusCode}
                  />
                )}
              </div>
              <MedwikiLandingSpecialities />
            </div>
          </section>
        </div>
      </div>
      {/* <FreshChatHandler /> */}
    </>
  );
};

export default memo(ListingPageBackground);

const MedwikiLandingSpecialities = () => {
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();
  const { id } = useGetParams();
  const { contentAccessRedirect } = useContentAccessRedirect();
  const specWiseData = [
    {
      specialityName: 'General',
      specialityId: 29,
      data: []
    },
    {
      specialityName: 'General',
      specialityId: 29,
      data: []
    },
    {
      specialityName: 'Internal Medicine',
      specialityId: 6,
      data: [
        {
          slno: 1,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 34773,
          is_share: 1,
          type: 'comp',
          date: ' 28th February 25',
          question:
            '2024-2025 AAP guideline: antiviral treatment and chemoprophylaxis for paediatric influenza',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1737529361_CLI.jpg',
          color: '#918c91',
          answer:
            'Influenza is a commonly encountered condition among children worldwide, with the youngest children being particularly vulnerable to severe disease.[1] Children with influenza commonly present with a combination of subjective fever, cough, headache, pharyngitis, and coryza, as well as generalised mal',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 3,
          rating: 165,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=X44ml'
        },
        {
          slno: 2,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 32102,
          is_share: 1,
          type: 'comp',
          date: ' 27th December 24',
          question: 'New approaches in HIV care: emerging therapies and best practices',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1725272554_CLI.jpg',
          color: '#918c91',
          answer:
            'HIV is a significant public health concern worldwide, with an estimated 42.3 million lives lost so far.[1] Antiretroviral therapy (ART) plays a pivotal role in the fight against HIV/AIDS, being recommended for individuals with HIV to lower morbidity and mortality rates, as well as to prevent the tra',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 39,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=Aeg3V'
        },
        {
          slno: 3,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 33730,
          is_share: 1,
          type: 'comp',
          date: ' 18th December 24',
          question: 'Delving into novel therapeutic approaches for hospital-acquired pneumonia ',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1732019597_CLI.jpg',
          color: '#918c91',
          answer:
            'Hospital-acquired pneumonia (HAP), also known as nosocomial pneumonia, represents a pulmonary inflammatory process of an infectious origin which is absent during hospital admission. Ventilator-associated pneumonia (VAP) is a significant subset of HAP.[1]\r\n\r\nThe treatment of HAP and VAP warrants the ',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 32,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=tQ61m'
        },
        {
          slno: 4,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 31864,
          is_share: 1,
          type: 'comp',
          date: ' 22nd November 24',
          question:
            'Advancing pertussis care in 2024: key insights from the latest CDC treatment update ',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1723536761_CLI.jpg',
          color: '#918c91',
          answer:
            "Pertussis, also known as whooping cough, is a respiratory infection mediated by Bordetella pertussis. Classic pertussis is associated with a cough, which may last for many weeks and is demarcated by paroxysms of repeated coughs, which end with a gasping 'whoop'.[1]Pertussis can affect indivi",
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 33,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=LG9p8'
        },
        {
          slno: 5,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 31663,
          is_share: 1,
          type: 'comp',
          date: ' 20th November 24',
          question: 'The FDA-approved antivirals for influenza treatment',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1722598921_CLI.jpg',
          color: '#918c91',
          answer:
            'The prevention and control of influenza virus infection remain a global public health challenge, as seasonal epidemics and unexpected pandemics are caused by it. High morbidity, mortality, and substantial economic impact are attributed to these infections. Vaccines are considered the primary prophyl',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 39,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=cxTze'
        },
        {
          slno: 6,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21638,
          is_share: 1,
          type: 'comp',
          date: ' 22nd March 23',
          question: 'Rutherford clinical categories of acute limb ischaemia\r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1676464053_20221218_Overview_of_Upper_Limb_Ischaemia_07_03.jpg',
          color: '#918c91',
          answer:
            'Acute limb ischaemia (ALI) is the sudden loss of limb perfusion for up to 2 weeks following an inciting event. It can occur in any peripheral artery of the upper and lower limbs and can lead to limb-threatening ischaemia. The symptoms of ALI can arise in several minutes to hours or days and can rang',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 4,
          rating: 217,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=sAwFR'
        },
        {
          slno: 7,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21803,
          is_share: 1,
          type: 'comp',
          date: ' 25th February 23',
          question:
            'NACO guidelines regarding the diagnosis of depression among people living with human immunodeficiency virus',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1676901464_CLIRNet_File704_01_01.jpg',
          color: '#918c91',
          answer:
            'People living with human immunodeficiency virus (PLHIV) may experience a wide range of psychological disorders such as depression, anxiety, internalised stigma, etc. which can significantly impair adherence and mental well-being. Depression, which is a common mental disorder, manifests as persistent',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 5,
          rating: 208,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=SekQd'
        },
        {
          slno: 8,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21642,
          is_share: 1,
          type: 'comp',
          date: ' 17th February 23',
          question:
            'ESVS guideline recommendations for thrombolysis for the treatment of acute limb ischaemia \r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1676464702_20221218_Overview_of_Upper_Limb_Ischaemia_07_07.jpg',
          color: '#918c91',
          answer:
            'Acute limb ischaemia (ALI) is the result of an acute (<2 weeks) abrupt reduction in arterial perfusion of the limb that can lead to tissue loss and threaten limb viability. The European Society for Vascular Surgery (ESVS) guideline recommendations for thrombolysis for the treatment of ALI are as ',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 1,
          rating: 146,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=FiYqT'
        },
        {
          slno: 9,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21618,
          is_share: 1,
          type: 'comp',
          date: ' 16th February 23',
          question: 'Laboratory investigations for metabolic syndrome \r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1675922224_20230120_Noncommunicable_Diseases_and_their_Consequences_06_02.jpg',
          color: '#918c91',
          answer:
            'Metabolic syndrome is defined as a cluster of cardiometabolic risk factors with an increased risk of multiple chronic diseases, such as cardiovascular diseases and cancer. After obtaining a thorough history of the patient and performing a physical examination, the evaluation for metabolic syndrome n',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 2,
          rating: 247,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=FgEEC'
        },
        {
          slno: 10,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21516,
          is_share: 1,
          type: 'comp',
          date: ' 7th February 23',
          question:
            'Rapid diagnostic test (RDT) as a diagnostic tool for the detection of malaria  \r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1675516075_20230111_Malaria_Prevention_and_Control_Measures_04_04.jpg',
          color: '#918c91',
          answer:
            'Malaria, a vector-borne disease, is caused by protozoa of the genus Plasmodium, a diverse group that infects a variety of vertebrate hosts, including primates.\r\n\r\nRapid diagnostic tests (RDTs) detect different parasitic antigens such as lactate dehydrogenase (LD), histidine-rich-protein-2 (HRP-2), a',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 4,
          rating: 151,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=jCj7J'
        }
      ]
    },
    {
      specialityName: 'Internal Medicine',
      specialityId: 6,
      data: [
        {
          slno: 1,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 34773,
          is_share: 1,
          type: 'comp',
          date: ' 28th February 25',
          question:
            '2024-2025 AAP guideline: antiviral treatment and chemoprophylaxis for paediatric influenza',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1737529361_CLI.jpg',
          color: '#918c91',
          answer:
            'Influenza is a commonly encountered condition among children worldwide, with the youngest children being particularly vulnerable to severe disease.[1] Children with influenza commonly present with a combination of subjective fever, cough, headache, pharyngitis, and coryza, as well as generalised mal',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 3,
          rating: 165,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=X44ml'
        },
        {
          slno: 2,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 32102,
          is_share: 1,
          type: 'comp',
          date: ' 27th December 24',
          question: 'New approaches in HIV care: emerging therapies and best practices',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1725272554_CLI.jpg',
          color: '#918c91',
          answer:
            'HIV is a significant public health concern worldwide, with an estimated 42.3 million lives lost so far.[1] Antiretroviral therapy (ART) plays a pivotal role in the fight against HIV/AIDS, being recommended for individuals with HIV to lower morbidity and mortality rates, as well as to prevent the tra',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 39,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=Aeg3V'
        },
        {
          slno: 3,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 33730,
          is_share: 1,
          type: 'comp',
          date: ' 18th December 24',
          question: 'Delving into novel therapeutic approaches for hospital-acquired pneumonia ',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1732019597_CLI.jpg',
          color: '#918c91',
          answer:
            'Hospital-acquired pneumonia (HAP), also known as nosocomial pneumonia, represents a pulmonary inflammatory process of an infectious origin which is absent during hospital admission. Ventilator-associated pneumonia (VAP) is a significant subset of HAP.[1]\r\n\r\nThe treatment of HAP and VAP warrants the ',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 32,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=tQ61m'
        },
        {
          slno: 4,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 31864,
          is_share: 1,
          type: 'comp',
          date: ' 22nd November 24',
          question:
            'Advancing pertussis care in 2024: key insights from the latest CDC treatment update ',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1723536761_CLI.jpg',
          color: '#918c91',
          answer:
            "Pertussis, also known as whooping cough, is a respiratory infection mediated by Bordetella pertussis. Classic pertussis is associated with a cough, which may last for many weeks and is demarcated by paroxysms of repeated coughs, which end with a gasping 'whoop'.[1]Pertussis can affect indivi",
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 33,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=LG9p8'
        },
        {
          slno: 5,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 31663,
          is_share: 1,
          type: 'comp',
          date: ' 20th November 24',
          question: 'The FDA-approved antivirals for influenza treatment',
          image: 'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1722598921_CLI.jpg',
          color: '#918c91',
          answer:
            'The prevention and control of influenza virus infection remain a global public health challenge, as seasonal epidemics and unexpected pandemics are caused by it. High morbidity, mortality, and substantial economic impact are attributed to these infections. Vaccines are considered the primary prophyl',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 0,
          rating: 39,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=cxTze'
        },
        {
          slno: 6,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21638,
          is_share: 1,
          type: 'comp',
          date: ' 22nd March 23',
          question: 'Rutherford clinical categories of acute limb ischaemia\r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1676464053_20221218_Overview_of_Upper_Limb_Ischaemia_07_03.jpg',
          color: '#918c91',
          answer:
            'Acute limb ischaemia (ALI) is the sudden loss of limb perfusion for up to 2 weeks following an inciting event. It can occur in any peripheral artery of the upper and lower limbs and can lead to limb-threatening ischaemia. The symptoms of ALI can arise in several minutes to hours or days and can rang',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 4,
          rating: 217,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=sAwFR'
        },
        {
          slno: 7,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21803,
          is_share: 1,
          type: 'comp',
          date: ' 25th February 23',
          question:
            'NACO guidelines regarding the diagnosis of depression among people living with human immunodeficiency virus',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1676901464_CLIRNet_File704_01_01.jpg',
          color: '#918c91',
          answer:
            'People living with human immunodeficiency virus (PLHIV) may experience a wide range of psychological disorders such as depression, anxiety, internalised stigma, etc. which can significantly impair adherence and mental well-being. Depression, which is a common mental disorder, manifests as persistent',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 5,
          rating: 208,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=SekQd'
        },
        {
          slno: 8,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21642,
          is_share: 1,
          type: 'comp',
          date: ' 17th February 23',
          question:
            'ESVS guideline recommendations for thrombolysis for the treatment of acute limb ischaemia \r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1676464702_20221218_Overview_of_Upper_Limb_Ischaemia_07_07.jpg',
          color: '#918c91',
          answer:
            'Acute limb ischaemia (ALI) is the result of an acute (<2 weeks) abrupt reduction in arterial perfusion of the limb that can lead to tissue loss and threaten limb viability. The European Society for Vascular Surgery (ESVS) guideline recommendations for thrombolysis for the treatment of ALI are as ',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 1,
          rating: 146,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=FiYqT'
        },
        {
          slno: 9,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21618,
          is_share: 1,
          type: 'comp',
          date: ' 16th February 23',
          question: 'Laboratory investigations for metabolic syndrome \r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1675922224_20230120_Noncommunicable_Diseases_and_their_Consequences_06_02.jpg',
          color: '#918c91',
          answer:
            'Metabolic syndrome is defined as a cluster of cardiometabolic risk factors with an increased risk of multiple chronic diseases, such as cardiovascular diseases and cancer. After obtaining a thorough history of the patient and performing a physical examination, the evaluation for metabolic syndrome n',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 2,
          rating: 247,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=FgEEC'
        },
        {
          slno: 10,
          con_type: 'text',
          is_locked: {
            currency: 'USD',
            display_icon: false,
            content_access: true
          },
          price: null,
          user_content_payment: null,
          env: 2,
          vendor: 'others',
          src: 'null',
          type_id: 21516,
          is_share: 1,
          type: 'comp',
          date: ' 7th February 23',
          question:
            'Rapid diagnostic test (RDT) as a diagnostic tool for the detection of malaria  \r\n',
          image:
            'https://clirnet-cms.b-cdn.net/medwiki/43_server/images/1675516075_20230111_Malaria_Prevention_and_Control_Measures_04_04.jpg',
          color: '#918c91',
          answer:
            'Malaria, a vector-borne disease, is caused by protozoa of the genus Plasmodium, a diverse group that infects a variety of vertebrate hosts, including primates.\r\n\r\nRapid diagnostic tests (RDTs) detect different parasitic antigens such as lactate dehydrogenase (LD), histidine-rich-protein-2 (HRP-2), a',
          specialities: 'Internal Medicine',
          specialities_ids_and_names: [
            {
              id: '6',
              name: 'Internal Medicine'
            }
          ],
          sponsor_name: null,
          sponsor_id: null,
          sponsor_logo: '',
          all_sponsor: [],
          comment_count: 4,
          rating: 151,
          myrating: false,
          vault: 0,
          deeplink: 'https://clrn.in?l=jCj7J'
        }
      ]
    }
  ];

  const specDataLoader = false;
  const hasMore = false;
  const statusCode = 200;
  return (
    <>
      {specDataLoader == false ? (
        <>
          {statusCode === activeStatusCode ? (
            <InfiniteScroll
              dataLength={specWiseData.length}
              hasMore={hasMore}
              loader={<PaginationLoader />}
              next={() => {}}
              style={{ overflow: 'none' }}
            >
              {specWiseData &&
                Array.isArray(specWiseData) &&
                specWiseData?.length > 0 &&
                specWiseData?.map((_specData, index) => {
                  return (
                    <React.Fragment key={index + 1}>
                      {_specData && _specData?.data.length > 0 && (
                        <div className="w-100 cmnLandingSlideRow medwikiSliderArea mt-4 mb-5">
                          <div className="d-flex justify-content-between align-items-baseline">
                            <h2
                              className="text-black fs-2 fw-semibold cursorPointer"
                              onClick={() =>
                                redirectTo(
                                  routeNames.speciality.landing,
                                  _specData.specialityId,
                                  _specData.specialityName
                                )
                              }
                            >
                              {_specData.specialityName}
                            </h2>
                            <a
                              href="javascript:void(0)"
                              className="text-primary rippleEffect fs-4 fw-medium gtm_cl_medwiki_landing_specialities_explore_more"
                              onClick={() => {
                                trackActivityEvent(MORE_CLICK, {});
                                redirectTo(
                                  routeNames.medwiki.listing,
                                  '',
                                  '',
                                  `specialities=${_specData.specialityId}`
                                );
                              }}
                            >
                              Explore More
                            </a>
                          </div>
                          <div className="cmnLndngRowSlidePrrnt">
                            <Swiper
                              className="cmnLndngRowSlide medwikiSlide"
                              spaceBetween={24}
                              slidesPerView={3}
                              loop={false}
                              breakpoints={{
                                1: {
                                  slidesPerView: 1,
                                  spaceBetween: 10
                                },
                                640: {
                                  slidesPerView: 3,
                                  spaceBetween: 15
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 22
                                },
                                992: {
                                  slidesPerView: 3,
                                  spaceBetween: 24
                                },
                                1400: {
                                  slidesPerView: 4,
                                  spaceBetween: 26
                                }
                              }}
                            >
                              {_specData?.data?.map((item, index) => {
                                return (
                                  <SwiperSlide key={index + 1}>
                                    <RelatedCard
                                      data={item}
                                      pageId={id}
                                      cardType="lg"
                                      {...generateProps(item, contentAccessRedirect)}
                                    />
                                    {/* <FeedContent data={{ ...item, type: 'comp' }} /> */}
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
            </InfiniteScroll>
          ) : (
            <InactiveComponent statuscode={statusCode} header="" subHeader="" icon="" />
          )}
        </>
      ) : specDataLoader ? (
        <div className="w-100 medwikiLUslideArea">
          <Swiper
            className="medwikiLUslide"
            spaceBetween={24}
            slidesPerView={3}
            loop={false}
            breakpoints={{
              1: {
                slidesPerView: 1,
                spaceBetween: 10
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 22
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 24
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 26
              }
            }}
          >
            {[...Array(8).keys()]?.slice(3, 10)?.map((_video, index) => (
              <SwiperSlide key={index + 1}>
                <MedwikiLoader />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : null}
    </>
  );
};
