import React, { useEffect, useState, useRef } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import DoctorsVoiceCard from '../../../../components/baseUiV3/cards/DoctorsVoiceCard';
import FollowChannelSection from './FollowChannelSection';
import { BannerItem } from '../../../../components/banner';

const RightSideContent = () => {
  return (
    <>
      <DoctorsVoiceCard className="clr_v3_card__doctorVoice--color" type={'doctorVoice'} />
      <BannerItem
        position="side-top"
        page_name="dashboard"
        className="clr_v3_card__sponsor--color"
      />
      <FollowChannelSection />
    </>
  );
};

export default RightSideContent;
