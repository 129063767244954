import React, { memo } from 'react';
import { Skeleton } from '../../Skeleton';

const PopularSpecialityLoader = () => {
  return (
    <>
      <div className="w-100 shadow bg-white rounded-3 specialitiesBox">
        <div className="d-flex justify-content-between mb-4">
          <h2 className="text-black fw-medium">
            <span className="text-secondary">
            <Skeleton variant="rectangular" width={100} height={48} />
            </span>
            
          </h2>
          <a href="javascript:void(0)" className="text-primary">
          <Skeleton variant="rectangular" width={88} height={15} />
          </a>
        </div>
        <ul className="specialitiesList mt-2">
          <li>
            
          
            <Skeleton variant="text" width="100%" height="100%" />
           
          </li>
          <li>
         
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
          
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
         
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
          
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
          
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
          
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
          
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
         
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
          
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
         
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
          
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
          <li>
         
            <Skeleton variant="text" width="100%" height="100%" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default memo(PopularSpecialityLoader);
