import React, { memo, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import InactiveComponent from '../../../components/InactiveComponent';
import NoDataFound from '../../../components/NoDataFound';
import { CARD_VIEW, SHARE_CLICK } from '../../../components/tracking/constants';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { useGetLinkShareDDX } from '../../../Queries/QueryHooks/aida.hook';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { safeJsonParser } from '../../../utils/common';
import { activeStatusCode } from '../../../utils/constants/common.constant';
import DoctorChatListSkeleton from '../../uiv3/observership/components/skeleton/DoctorChatListSkeleton';

const ChatListWrapper = memo(({ children, type }) => {
  return type === 'chat' ? (
    <Swiper
      direction={'vertical'}
      slidesPerView="auto"
      spaceBetween={8}
      mousewheel={{ forceToAxis: true, sensitivity: 2 }} // Increase sensitivity
      freeMode={{
        enabled: true,
        momentum: true,
        momentumRatio: 20,
        momentumBounceRatio: 0,
        sticky: true
      }}
      className="w-100 d-flex flex-column row-gap-3 flex-grow-1 observer__doctorlist max-vh-100"
    >
      {children}
    </Swiper>
  ) : (
    <div className="w-100 d-flex flex-column row-gap-3 flex-grow-1 observer__doctorlist">
      {children}
    </div>
  );
});

function HistoryListing({
  selectedChathead,
  contentClick,
  onBackBtnClick,
  isAidaHistoryListLoading,
  aidaHistoryList,
  handleStartNewChat,
  question
}) {
  const [searchText, setSearchText] = useState('');

  const userDetails = useSelector((state) => state.common.user_details);

  const doctorsList = {
    data: [
      {
        profile_image:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBvqzyx_zoi6q2c0Gd1XnE7wysD9PGOLe3-A&s',
        first_name: 'Aritra',
        last_name: 'Basu',
        middle_name: 'Kumar'
      },
      {
        profile_image:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBvqzyx_zoi6q2c0Gd1XnE7wysD9PGOLe3-A&s',
        first_name: 'Aritra',
        last_name: 'Basu',
        middle_name: 'Kumar'
      }
    ],
    statusCode: 200
  };

  const getActiveClass = (ddx_id) => {
    if (selectedChathead?.ddx_id === ddx_id) return 'active';
  };

  const renderHistoryItems = () => {
    if (!aidaHistoryList || Object.keys(aidaHistoryList).length === 0) {
      return null;
    }

    return Object.entries(aidaHistoryList).map(([date, historyData], ind) => {
      if (!Array.isArray(historyData) && !date) return null;
      console.log('datesssssssssssssssss', date, aidaHistoryList);

      return (
        <React.Fragment key={`${ind}_${date}`}>
          {/* <SwiperSlide className="fw-bold fs-5 text-dark h-auto">
            {dayjs(date, 'DD-MM-YYYY').format('DD MMM YYYY')}
          </SwiperSlide> */}
          {historyData.map((item) => (
            <SwiperSlide
              key={item?.ddx_id}
              className={`observer__doctorlist__item transition w-100 d-flex gap-3 rounded-3 h-auto align-items-center cursorPointer ${getActiveClass(
                item?.ddx_id
              )}`}
              onClick={() => contentClick(item)}
            >
              <HistoryCard data={item} />
            </SwiperSlide>
          ))}
        </React.Fragment>
      );
    });
  };

  if (!doctorsList || doctorsList?.statusCode !== activeStatusCode) {
    return <InactiveComponent statuscode={doctorsList?.statusCode} />;
  }

  if (!doctorsList?.data?.length) {
    return <NoDataFound sectionRemove={true} />;
  }

  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={{}}
      className="d-flex flex-column gap-2 rounded-3 bg-extra-light p-3 p-sm-4 h-100"
    >
      <div className="d-flex align-items-center mb-2 justify-content-between">
        <div className="d-flex align-items-center justify-content-center gap-4">
          {isMobile && (
            <Button
              variant={'light'}
              size="sm"
              className={`flex-shrink-0 d-flex align-items-center justify-content-center icon-size-32 fs-1 p-0 rounded-circle text-black`}
              onClick={onBackBtnClick}
            >
              {/* tooltip for mobile */}
              <i className="flaticon-back"></i>
            </Button>
          )}
          <h2 className="fs-16 flex-grow-1 text-black fw-semibold">Chat History</h2>
        </div>
        <div>
          {question && (
            <Button variant={'primary'} size="sm" onClick={handleStartNewChat}>
              Start a new chat
            </Button>
          )}
        </div>
      </div>

      <ChatListWrapper type={isAidaHistoryListLoading ? 'skeleton' : 'chat'}>
        {isAidaHistoryListLoading ? <DoctorChatListSkeleton /> : renderHistoryItems()}
      </ChatListWrapper>
    </TrackerWrapper>
  );
}

export default HistoryListing;

const HistoryCard = ({ data }) => {
  const [selectedDDXId, setSelectedDDXId] = useState(null);
  const { data: shareLink, isLoading: isShareLinkLoading } = useGetLinkShareDDX(selectedDDXId);
  const { trackActivityEvent } = useTracker();
  const copy_to_Clipboard = (str) => {
    let textarea;
    let result;
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = str;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, str); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }

    return true;
  };

  const fetchShareLink = async () => {
    if (navigator.canShare) {
      try {
        await navigator.share({
          title: 'Check out this DDX',
          text: "Here's a DDX link you might find interesting:",
          url: shareLink
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      toast.info('Copied to clipboard');
      copy_to_Clipboard(shareLink);
    }
  };

  useEffect(() => {
    if (selectedDDXId && !!shareLink) {
      fetchShareLink();
    }
  }, [isShareLinkLoading, selectedDDXId]);

  const handleShareClick = async (e) => {
    e.stopPropagation();
    if (shareLink) {
      fetchShareLink();
    } else {
      setSelectedDDXId(data.ddx_id);
    }
  };

  return (
    <TrackerWrapper
      data={data}
      type={CARD_VIEW}
      className="w-100 d-flex flex-column transition flex-grow-1 border border-light rounded-3 h-auto p-3 p-sm-3"
    >
      <div className="d-flex justify-content-between gap-2">
        <h4 className="fs-4 text-black fw-semibold line-clamp-1">{data.question}</h4>
        <button
          onClick={(e) => {
            trackActivityEvent(SHARE_CLICK, data);
            handleShareClick(e);
          }}
        >
          <i class="flaticon-share-arrow"></i>
        </button>
      </div>
      <p className="line-clamp-2">
        {Array.isArray(safeJsonParser(data?.answer)?.D_list) &&
        safeJsonParser(data?.answer)?.D_list.length > 0
          ? safeJsonParser(data?.answer)?.D_list[0]?.Reason_of_diagnosis
          : ''}
      </p>
    </TrackerWrapper>
  );
};
