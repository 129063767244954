import React from 'react';
import { routeNames } from '../constants';
import Loadable from '../Loadable';
import { Outlet } from 'react-router-dom';
export const shareRoutes = [
  {
    path: '/share/:serviceName',
    component: <Outlet />,
    subPath: ['', ':id', ':id/:utm', ':id/:utm/:templateId'],
    ShareRoute: true
  }
];
