import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import ArchiveVideoCardV1 from '../../../components/cards/arcchiveVideo/archiveVideoCardV1/ArchiveVideoCardV1';
import ClinicalVideoCardV1 from '../../../components/cards/clinicalVideo/ClinicalVideoCardV1';
import GrCardV1 from '../../../components/cards/grandRound/grCardV1/GrCardV1';
import MedwikiCardV1 from '../../../components/cards/medwiki/medwikiCardV1/MedwikiCardV1';
import PollCardV1d from '../../../components/cards/poll/pollCardV1/PollCardV1d';
import QuizCardV1 from '../../../components/cards/quiz/quizCardV1/QuizCardV1';
import SessionCardV1 from '../../../components/cards/session/sessionCardV1/SessionCardV1';
import SurveyCardV1 from '../../../components/cards/survey/surveyCardV1/SurveyCardV1';
import Masonry from 'react-masonry-component';
import EbookCard from '../../ebook/component/EbookCard';
import ClirnetOtherCard from '../../share/components/ClirnetOtherCard';
import { openNewTab } from '../../../common/common';
import ObservershipCard from '../../../components/baseUiV3/contentCards/observership/ObservershipCard';
import MentorCard from '../../../components/baseUiV3/contentCards/observership/MentorCard';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
import useGetParams from '../../../components/customHooks/useGetParams';
import RelatedCard from '../../../components/baseUiV3/contentCards/RelatedCard';
import { generateProps } from '../../../utils/generateProps';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';
const CardsArea = ({ data, gtmTagVariable = '', isShare = false }) => {
  const { contentAccessRedirect } = useContentAccessRedirect();

  const navigate = useNavigate();
  const { utm, templateId, id, serviceName } = useGetParams();
  const showCardsData = (trending_type, details, index) => {
    const redirect = () => {
      openNewTab('https://www.clirnet.com/company', () => {
        window.location.href = 'https://www.clirnet.com/company';
      });
    };
    if (index == 0) {
      return (
        <div
          className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
          key={index + 1}
        >
          <TrackerWrapper
            type={CARD_VIEW}
            data={details}
            className="w-100 float-start newLogoCard d-flex flex-column p-3 text-start shadow bg-white rounded-3"
          >
            <div>
              <span className="m-3 d-flex justify-content-start">
                <p className="fs-2 fw-semibold">Don't know what CLIRNET does?</p>
              </span>
              <span className="p-3 d-flex flex-row justify-content-between">
                <img
                  className=""
                  src="https://img-cdn.clirnet.com/medwiki/images/clirnet_logo.jpg"
                  alt="Clirnet"
                />
                <button
                  onClick={redirect}
                  className={`"fs-4 fw-medium ms-auto py-2 px-3 btn btn-outline-secondary rounded py-3 px-4 ${gtmTagVariable}_clirnetOtherCard_click_here_to_find_out`}
                  aria-label="Click here to find out"
                >
                  Click here to find out
                </button>
              </span>
            </div>
          </TrackerWrapper>
        </div>
      );
    }

    function getShareRouteUrl(type) {
      switch (type) {
        case 'session':
          return `/share/session/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`;
        case 'epub':
          return `/share/epub/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`;
        case 'comp':
          return `/share/medwiki/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`;
        case 'survey':
          return `/share/survey/${details.survey_id}${templateId ? `/${utm}/${templateId}` : ''}`;
        case 'poll':
          return `/share/Poll/${details.survey_id}${templateId ? `/${utm}/${templateId}` : ''}`;
        case 'quiz':
          return `/share/survey/${details.survey_id}${templateId ? `/${utm}/${templateId}` : ''}`;
        case 'gr':
          return `/share/gr/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`;
        case 'video_archive':
          return `/share/archived_video/${details.type_id}${
            templateId ? `/${utm}/${templateId}` : ''
          }`;
        case 'video':
          return `/share/archived_video/${details.type_id}${
            templateId ? `/${utm}/${templateId}` : ''
          }`;
        default:
          return `/share/${details.type}/${details.type_id}${
            templateId ? `/${utm}/${templateId}` : ''
          }`;
      }
    }
    switch (trending_type) {
      case 'observership':
        return (
          <div
            className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
            key={index + 1}
          >
            <ObservershipCard {...details} />
          </div>
        );
      case 'mentor':
        return (
          <div
            className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
            key={index + 1}
          >
            <MentorCard {...details} cardType="small" />
          </div>
        );

      default:
        return (
          <div
            className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
            key={index + 1}
          >
            {' '}
            <RelatedCard
              data={details}
              pageId={id}
              cardType="lg"
              {...generateProps(details, contentAccessRedirect, () =>
                navigate(getShareRouteUrl(details.type))
              )}
            />
          </div>
        );

      // case 'session':
      //   return (
      //     <div
      //       className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //       key={index + 1}
      //     >
      //       {/* <SessionCardV1
      //         gtmTagVariable={gtmTagVariable}
      //         hideImage={true}
      //         data={details}
      //         click={() => {
      //           navigate(
      //             `/share/session/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`
      //           );
      //         }}
      //         onGetRecClick={() =>
      //           navigate(
      //             `/share/session/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`
      //           )
      //         }
      //         isShare={details.is_share}
      //       /> */}

      //     </div>
      //   );
      // case 'epub':
      //   return (
      //     <div
      //       className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //       key={index + 1}
      //     >
      //       {/* <EbookCard
      //         gtmTagVariable={gtmTagVariable}
      //         image={details.image}
      //         click={() => {
      //           navigate(
      //             `/share/epub/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`
      //           );
      //         }}
      //         data={details}
      //       /> */}
      //       <RelatedCard
      //         data={details}
      //         pageId={id}
      //         cardType="lg"
      //         {...generateProps(details, contentAccessRedirect)}
      //       />
      //     </div>
      //   );
      // case 'comp':
      //   return (
      //     <div
      //       className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //       key={index + 1}
      //     >
      //       {/* {details.con_type == 'text' && (

      //       )} */}
      //       {details.con_type == 'video' ? (
      //         // <ArchiveVideoCardV1
      //         //   gtmTagVariable={gtmTagVariable}
      //         //   hideImage={true}
      //         //   medWikiListVideoCardDetails={details}
      //         //   click={() => {
      //         //     navigate(
      //         //       `/share/medwiki/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`
      //         //     );
      //         //   }}
      //         // />
      //         <RelatedCard
      //           data={details}
      //           pageId={id}
      //           cardType="lg"
      //           {...generateProps(details, contentAccessRedirect)}
      //         />
      //       ) : (
      //         // <MedwikiCardV1
      //         //   gtmTagVariable={gtmTagVariable}
      //         //   hideImage={true}
      //         //   medwikiListDetails={details}
      //         //   click={() => {
      //         //     navigate(
      //         //       `/share/medwiki/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`
      //         //     );
      //         //   }}
      //         // />
      //         <RelatedCard
      //           data={details}
      //           pageId={id}
      //           cardType="lg"
      //           {...generateProps(details, contentAccessRedirect)}
      //         />
      //       )}
      //     </div>
      //   );

      // case 'survey':
      //   switch (details.category) {
      //     case 'survey':
      //       return (
      //         <div
      //           className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //           key={index + 1}
      //         >
      //           {/* <SurveyCardV1
      //             gtmTagVariable={gtmTagVariable}
      //             hideImage={true}
      //             data={details}
      //             click={() => {
      //               navigate(
      //                 `/share/survey/${details.survey_id}${
      //                   templateId ? `/${utm}/${templateId}` : ''
      //                 }`
      //               );
      //             }}
      //           /> */}
      //           <RelatedCard
      //             data={details}
      //             pageId={id}
      //             cardType="lg"
      //             {...generateProps(details, contentAccessRedirect)}
      //           />
      //         </div>
      //       );

      //     case 'poll':
      //       return (
      //         <div
      //           className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //           key={index + 1}
      //         >
      //           {/* <PollCardV1d
      //             gtmTagVariable={gtmTagVariable}
      //             hideImage={true}
      //             data={details}
      //             click={() => {
      //               navigate(
      //                 `/share/Poll/${details.survey_id}${templateId ? `/${utm}/${templateId}` : ''}`
      //               );
      //             }}
      //           /> */}
      //           <RelatedCard
      //             data={details}
      //             pageId={id}
      //             cardType="lg"
      //             {...generateProps(details, contentAccessRedirect)}
      //           />
      //         </div>
      //       );

      //     case 'quiz':
      //       return (
      //         <div
      //           className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //           key={index + 1}
      //         >
      //           {/* <QuizCardV1
      //             gtmTagVariable={gtmTagVariable}
      //             hideImage={true}
      //             data={details}
      //             click={() => {
      //               navigate(
      //                 `/share/survey/${details.survey_id}${
      //                   templateId ? `/${utm}/${templateId}` : ''
      //                 }`
      //               );
      //             }}
      //           /> */}
      //           <RelatedCard
      //             data={details}
      //             pageId={id}
      //             cardType="lg"
      //             {...generateProps(details, contentAccessRedirect)}
      //           />
      //         </div>
      //       );

      //     default:
      //       return;
      //   }

      // case 'gr':
      //   return (
      //     <div
      //       className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //       key={index + 1}
      //     >
      //       {/* <GrCardV1
      //         gtmTagVariable={gtmTagVariable}
      //         hideImage={true}
      //         cardData={details}
      //         click={() => {
      //           navigate(
      //             `/share/gr/${details.type_id}${templateId ? `/${utm}/${templateId}` : ''}`
      //           );
      //         }}
      //       /> */}
      //       <RelatedCard
      //         data={details}
      //         pageId={id}
      //         cardType="lg"
      //         {...generateProps(details, contentAccessRedirect)}
      //       />
      //     </div>
      //   );

      // case 'video_archive':
      // case 'video':
      //   return (
      //     <div
      //       className="position-absolute col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //       key={index + 1}
      //     >
      //       {/* <ClinicalVideoCardV1
      //         gtmTagVariable={gtmTagVariable}
      //         hideImage={true}
      //         data={details}
      //         click={() => {
      //           navigate(
      //             `/share/archived_video/${details.type_id}${
      //               templateId ? `/${utm}/${templateId}` : ''
      //             }`
      //           );
      //         }}
      //       /> */}
      //       <RelatedCard
      //         data={details}
      //         pageId={id}
      //         cardType="lg"
      //         {...generateProps(details, contentAccessRedirect)}
      //       />
      //     </div>
      //   );

      // case 'video':
      //   return (
      //     <div
      //       className="col-xxl-4 col-lg-6 col-sm-6 col-12 mt-4 ps-4 mt-md-20 ps-md-20 pe-0"
      //       key={index + 1}
      //     >
      //       <ClinicalVideoCardV1
      //         gtmTagVariable={gtmTagVariable}
      //         hideImage={true}
      //         data={details}
      //         click={() => {
      //           navigate(`/share/archived_video/${details.type_id}`);
      //         }}
      //       />
      //     </div>
      //   );
      // default:
      //   return null;
    }
  };
  return (
    <Masonry
      enableResizableChildren={true}
      className={'position-relative ms-n4 ms-md-n20 mt-n4 mt-md-n20 me-0'}
    >
      {data &&
        data?.map((_d, index) => {
          return showCardsData(_d.trending_type ? _d.trending_type : _d.type, _d, index);
        })}
    </Masonry>
  );
};

export default memo(CardsArea);
