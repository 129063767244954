export const TRAINING_FEATURED = 'TRAINING_FEATURED';
export const TRAINING_POPULAR = 'TRAINING_POPULAR';
export const TRAINING_COMPLETED = 'TRAINING_COMPLETED';
export const TRAINING_INCOMPLETED = 'TRAINING_INCOMPLETED';
export const TRAINING_COUNT = 'TRAINING_COUNT';
export const TRAINING_LIST = 'TRAINING_LIST';
export const TRAINING_DETAILS = 'TRAINING_DETAILS';
export const TOP_SPECIALITY = 'TOP_SPECIALITY';
export const MODULE_LIST = 'MODULE_LIST';
export const CONTENT_BY_MODULE = 'CONTENT_BY_MODULE';
export const TRAINING_REVIEWS = 'TRAINING_REVIEWS';
export const TRAINING_STATUS = 'TRAINING_STATUS';
// SPECIALITY
export const SPECIALITY_TRAINING = 'SPECIALITY_TRAINING';
export const MY_PAID_LISTING = 'MY_PAID_LISTING';
