import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import playStore from '../../../pages/uiv3/appRegister/play-store.svg';
import appStore from '../../../pages/uiv3/appRegister/app-store.svg';
import { useSelector } from 'react-redux';
function AppBanner({
  msg = 'Experience the expertise of your peers at your fingertips—get the App now! 🚀💉',
  extraClass = 'shadow-lg rounded-3',
  textClass = 'black'
}) {
  const userRegion = useSelector((state) => state.share.UserRegion);
  if (userRegion === 'IN') {
    return null;
  }

  return (
    <>
      <div
        className={`w-100 w-md-auto d-flex flex-column p-4 p-md-20 text-start bg-white ${extraClass} ${
          isMobile ? '' : 'rounded-bottom-0'
        }`}
      >
        <h4 className={`fs-3 text-${textClass} text-center fw-semibold mb-3 `}>{msg}</h4>

        <div className="d-flex gap-3 justify-content-center">
          <Button
            size="lg"
            type="button"
            onClick={() => {
              window.location.href = 'https://clirnet.page.link/Uo2c';
            }}
            variant={'black'}
            className={
              'rounded-3 px-2 py-3 fs-4 d-flex justify-content-center align-items-center gap-2 gap-sm-3 ' +
              (isMobile ? 'w-100' : '')
            }
            style={
              !isMobile
                ? {
                    minWidth: '20rem'
                  }
                : {}
            }
          >
            <img src={playStore} alt="play store" className="icon-size-32 me-2" />
            <div className="d-flex flex-column align-items-start justify-content-between">
              {' '}
              <span className="fs-6 d-block">GET IT ON</span>
              <span className="d-block">Play Store</span>
            </div>
          </Button>
          <Button
            style={
              !isMobile
                ? {
                    minWidth: '20rem'
                  }
                : {}
            }
            size="lg"
            type="button"
            onClick={() => {
              window.location.href = 'https://clirnet.page.link/Uo2c';
            }}
            variant={'black'}
            className={
              'rounded-3 px-2 py-3 fs-4 d-flex justify-content-center align-items-center gap-2 gap-sm-3 appstoreBtn-share ' +
              (isMobile ? 'w-100' : '')
            }
          >
            <img src={appStore} alt="app store" className="icon-size-32 me-2" />
            <div className="d-flex flex-column align-items-start justify-content-between">
              {' '}
              <span className="fs-6 d-block">Download on the</span>
              <span className="d-block">App Store</span>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}

export default memo(AppBanner);
