export const bannerSettingsData = [
  {
    id: '1',
    module: 'session',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '2',
    module: 'session',
    position: 'Side-Top',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '3',
    module: 'session',
    position: 'side-bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '4',
    module: 'session_waiting_room',
    position: 'side-top',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '5',
    module: 'live_session',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '6',
    module: 'session',
    position: 'bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '7',
    module: 'comp',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '8',
    module: 'comp',
    position: 'side-top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '9',
    module: 'comp',
    position: 'side-bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '10',
    module: 'comp',
    position: 'Bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '11',
    module: 'survey',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '12',
    module: 'survey',
    position: 'bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '13',
    module: 'survey',
    position: 'side-top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '14',
    module: 'survey',
    position: 'side-bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '15',
    module: 'gr',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '16',
    module: 'gr',
    position: 'bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '17',
    module: 'gr',
    position: 'side-top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '18',
    module: 'gr',
    position: 'side-bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '19',
    module: 'video_archive',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '20',
    module: 'video_archive',
    position: 'bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '21',
    module: 'video_archive',
    position: 'side-top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '22',
    module: 'video_archive',
    position: 'side-bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '23',
    module: 'channel',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '24',
    module: 'channel',
    position: 'bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '25',
    module: 'channel',
    position: 'side-top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '26',
    module: 'channel',
    position: 'side-bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '27',
    module: 'epub',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '28',
    module: 'epub',
    position: 'bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '29',
    module: 'epub',
    position: 'side-top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '30',
    module: 'epub',
    position: 'side-bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '31',
    module: 'hospital_profile',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '32',
    module: 'hospital_profile',
    position: 'bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '33',
    module: 'hospital_profile',
    position: 'side-top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '34',
    module: 'hospital_profile',
    position: 'Side-Bottom',
    desktop: '',
    mobile: '',
    status: '3'
  },
  {
    id: '35',
    module: 'dashboard',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '36',
    module: 'dashboard',
    position: 'Side-Top',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '37',
    module: 'dashboard',
    position: 'section-header',
    desktop: '728x90',
    mobile: '',
    status: '3'
  },
  {
    id: '38',
    module: 'session',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '39',
    module: 'session',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '40',
    module: 'comp',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '41',
    module: 'comp',
    position: 'section-header',
    desktop: '',
    mobile: '',
    status: '3'
  },
  {
    id: '42',
    module: 'survey',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '43',
    module: 'survey',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '44',
    module: 'gr',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '45',
    module: 'gr',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '46',
    module: 'video_archive',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '47',
    module: 'video_archive',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '48',
    module: 'channel',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '49',
    module: 'channel',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '50',
    module: 'epub',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '51',
    module: 'epub',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '52',
    module: 'hospital_profile',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '53',
    module: 'hospital_profile',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '54',
    module: 'profile',
    position: 'section-header',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '58',
    module: 'speciality',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '59',
    module: 'election',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '60',
    module: 'certificate',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '61',
    module: 'telemed_lite',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '62',
    module: 'vault',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '63',
    module: 'search',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '64',
    module: 'training',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '65',
    module: 'help_support',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '66',
    module: 'help_support',
    position: 'Bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '67',
    module: 'help_support',
    position: 'Side-Top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '68',
    module: 'help_support',
    position: 'Side-Bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '69',
    module: 'doctube',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '70',
    module: 'doctube',
    position: 'Bottom',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '71',
    module: 'video_archive',
    position: 'video-floating',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '72',
    module: 'comp',
    position: 'interstitial',
    desktop: '320x480',
    mobile: '320x480',
    status: '3'
  },
  {
    id: '73',
    module: 'survey',
    position: 'interstitial',
    desktop: '320x480',
    mobile: '320x480',
    status: '3'
  },
  {
    id: '74',
    module: 'dashboard',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '75',
    module: 'differential_diagnosis',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '76',
    module: 'training',
    position: 'listing',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '77',
    module: 'dashboard',
    position: 'listing',
    desktop: '300x200',
    mobile: '300x200',
    status: '3'
  },
  {
    id: '78',
    module: 'dashboard',
    position: 'Side-Bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '79',
    module: 'profile',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '80',
    module: 'observership',
    position: 'Side-Bottom',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '81',
    module: 'observership',
    position: 'Side-Top',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '82',
    module: 'dashboard',
    position: 'top',
    desktop: '728x90',
    mobile: '',
    status: '3'
  },
  {
    id: '83',
    module: 'epub',
    position: 'interstitial',
    desktop: '320x480',
    mobile: '320x480',
    status: '3'
  },
  {
    id: '84',
    module: 'dashboard',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '85',
    module: 'comp',
    position: 'middle-leaderboard',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    id: '86',
    module: 'comp',
    position: 'middle-square',
    desktop: '300x200',
    mobile: '',
    status: '3'
  },
  {
    id: '87',
    module: 'observership',
    position: 'top',
    desktop: '728x90',
    mobile: '320x100',
    status: '3'
  },
  {
    position: 'video',
    time_difference_between_ads: '30',
    max_ads_per_video: '2',
    duration_between_ads: -1,
    max_overlay_per_video: -1,
    status: '3'
  }
];
