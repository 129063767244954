import { useLocation, useNavigate } from 'react-router-dom';
import { removeHtmlTags } from '../../pages/channel/utils';
import { routeNames } from '../../router/constants';
import importStatements from './importStatements';
import { useDispatch } from 'react-redux';
import { getSessionDetail, toggleLoadingBar } from '../../Store/actions';
import { getLocalStorage, isEligibleForSpeciality, setLocalStorage } from '../../common/common';
import { useIsMultidaySession } from '../../Queries/QueryHooks/multiday.hook';
import { useRef } from 'react';
import { checkUserAlreadyExists, loginToDoctube } from '../../Store/actions/doctube.action';
import { useDoctubeChannelDetails } from '../../Queries/QueryHooks/doctube.hook';
import { toast } from 'react-toastify';

const useRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const convertToSlug = (Text) => {
    return Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/[^\w\s]/gi, '-');
  };

  const findImportStatement = (path) => {
    if (!path) return null;
    return importStatements.find(
      (statement) => statement.path === path || statement.path === `/${path}`
    );
  };
  const onSuccess = (res) => {
    console.log('ON_SUCCESS', res);
    let { channel_name, about, avatar, cover, consult_link } = res;

    let stateToPass = {
      state_data: { channel_name, about, avatar, cover, consult_link }
    };

    if (res?.created_by == 1) {
      getLocalStorage('doctube_access_token', false) &&
        redirectTo(routeNames.doctube.dashboard, '', '', '', stateToPass, true);
    } else {
      getLocalStorage('doctube_access_token', false) &&
        redirectTo(routeNames.doctube.profile, '', '', '', stateToPass, true);
    }
  };
  const onError = (e) => {
    console.log('ON_ERROR', e);
    // navigate('/', { replace: true });
  };
  const { data: channel, isLoading, refetch } = useDoctubeChannelDetails(false, onSuccess, onError);
  const redirect = (moduleName, id = '', name = '', extra = '', state = {}, replace) => {
    const sessionRoutes = [
      routeNames.sessions.detail,
      routeNames.sessions.waitingRoom,
      routeNames.sessions.live
    ];
    const urlParams = `${moduleName}/${id}/${convertToSlug(removeHtmlTags(name))}${
      extra ? `?${extra}` : ''
    }`;
    const url = `${moduleName}${extra ? `?${extra}` : ''}`;
    const doNavigate = () => {
      const stateToPass = {
        replace,
        state: { ...state, ...location }
      };
      navigate(
        moduleName?.startsWith('/') ? (id ? urlParams : url) : id ? `/${urlParams}` : `/${url}`,
        stateToPass
      );
      //dispatch(toggleLoadingBar(false));
    };

    if (sessionRoutes.includes(moduleName) || sessionRoutes.includes(`/${moduleName}`)) {
      dispatch(getSessionDetail(id, () => doNavigate()));
    } else if (
      routeNames.doctube.landing === moduleName ||
      routeNames.doctube.landing === `/${moduleName}`
    ) {
      dispatch(
        checkUserAlreadyExists((user) => {
          if (user) {
            let { id, smtoken } = user;
            dispatch(
              loginToDoctube(id, smtoken, (res) => {
                if (res) {
                  console.log('LOGIN_TO_DOCTUBE', res?.refresh_token);
                  setLocalStorage('doctube_access_token', res?.token);
                  setLocalStorage('doctube_refresh_token', res?.refresh_token);
                  console.log(
                    'DOCTUBE_ACCESS_TOKEN',
                    getLocalStorage('doctube_access_token', false)
                  );
                  refetch();
                } else {
                  //dispatch(toggleLoadingBar(false));
                  toast.error('Something went wrong');
                }
              })
            );
          } else {
            navigate(routeNames.doctube.landing, {
              state: {
                isStatic: 'true'
              }
            });
            //dispatch(toggleLoadingBar(false));
          }
        })
      );
    } else {
      doNavigate();
    }
  };
  const redirectTo = (moduleName, id = '', name = '', extra = '', state = {}, replace = false) => {
    dispatch(toggleLoadingBar(true));

    try {
      let importStatement = findImportStatement(moduleName);
      if (importStatement) {
        const isSpecialityEligible = isEligibleForSpeciality(getLocalStorage('user', '{}'));
        let statement = isSpecialityEligible
          ? importStatement.statement()
          : importStatement?.oldStatement
          ? importStatement?.oldStatement()
          : importStatement?.statement();
        statement
          .then((module) => {
            redirect(moduleName, id, name, extra, state, replace);
          })
          .catch((error) => {
            redirect(moduleName, id, name, extra, state, replace);
          });
      } else {
        redirect(moduleName, id, name, extra, state, replace);
      }
    } catch (error) {
      redirect(moduleName, id, name, extra, state, replace);
    }
  };

  function getSharePageURL(content_type, content_id, utmSource = null) {
    var utm_source = utmSource === null ? getLocalStorage('utm_source', '') : utmSource;
    var url = '/';
    if (
      content_type == 'session' ||
      content_type == 'medwiki' ||
      content_type == 'feed' ||
      content_type == 'archived_video' ||
      content_type == 'video_archive' ||
      content_type == 'survey' ||
      content_type == 'gr' ||
      content_type == 'channel' ||
      content_type == 'connect' ||
      content_type == 'epub' ||
      content_type == 'training' ||
      content_type == 'mentor' ||
      content_type == 'observership' ||
      content_type == 'claim_mentorship' ||
      content_type == 'differential_diagnosis'
    ) {
      url = `/share/${content_type}/${content_id}/${utm_source}`;
    } else if (
      content_type == 'profile' ||
      content_type == 'doctube' ||
      content_type == 'doctube_fst'
    ) {
      url = `/share/${content_type}/${utm_source}`;
    }
    return url;
  }

  function navigateToSharePage(content_type, content_id, utmSource = null, state = {}) {
    const url = getSharePageURL(content_type, content_id, utmSource);

    if (url) {
      navigate(url, {
        state: {
          ...state
        }
      });
    } else {
    }
  }

  function navigateToAssociatedPath(content_type, content_id) {
    const options = { state: { from_login: true } };

    if (content_type == 'session') {
      if (content_id != 0) {
        navigate(`${routeNames.sessions.detail}/${content_id}`, options);
      } else {
        navigate(routeNames.sessions.landing, options);
      }
    } else if (content_type == 'live') {
      if (content_id != 0) {
        navigate(`${routeNames.sessions.live}/${content_id}`, options);
      } else {
        navigate(routeNames.sessions.landing, options);
      }
    } else if (content_type == 'medwiki' || content_type == 'feed') {
      if (content_id != 0) {
        navigate(`${routeNames.medwiki.detail}/${content_id}`, options);
      } else {
        navigate(routeNames.medwiki.landing, options);
      }
    } else if (content_type == 'archived_video' || content_type == 'video_archive') {
      if (content_id != 0) {
        navigate(`${routeNames.clinicalVideo.detail}/${content_id}`, options);
      } else {
        navigate(routeNames.clinicalVideo.landing, options);
      }
    } else if (content_type == 'survey') {
      if (content_id != 0) {
        navigate(`${routeNames.spq.detail}/${content_id}`, options);
      } else {
        navigate(routeNames.spq.landing, options);
      }
    } else if (content_type == 'gr') {
      if (content_id != 0) {
        navigate(`${routeNames.grandRound.detail}/${content_id}`, options);
      } else {
        navigate(routeNames.grandRound.landing, options);
      }
    } else if (content_type == 'channel') {
      if (content_id != 0) {
        navigate(`${routeNames.channel.detail}/${content_id}`, options);
      } else {
        navigate(routeNames.channel.landing, options);
      }
    } else if (content_type == 'profile') {
      navigate(routeNames.userProfile.landing, options);
    } else if (content_type == 'certificate') {
      if (content_id == 0) {
        navigate(routeNames.certificate.landing, options);
      } else {
        navigate(`${routeNames.certificate.detail}/${content_id}`, options);
      }
    } else if (content_type == 'training') {
      if (content_id == 0) {
        navigate(`${routeNames.course.landing}`, options);
      } else {
        navigate(`${routeNames.course.cover}/${content_id}`, options);
      }
    } else if (content_type == 'election') {
      navigate(`${routeNames.election.landing}/${content_id}`, options);
    } else if (content_type == 'connect') {
      content_id != 0
        ? navigate(`${routeNames.sessions.speaker}/${content_id}&active=true`, options)
        : navigate(`${routeNames.sessions.listing}?type=master_doctors`, options);
    } else if (content_type == 'dashboard') {
      navigate(routeNames.dashboard.landing, options);
    } else if (content_type == 'epub') {
      if (content_id == 0) {
        navigate(routeNames.epaper.landing, options);
      } else {
        navigate(`${routeNames.epaper.detail}/${content_id}`, options);
      }
    } else if (content_type == 'doctube' || content_type == 'doctube_fst') {
      navigate(routeNames.doctube.landing, options);
    } else if (content_type == 'doctubeAnalytics') {
      navigate(routeNames.doctube.landing, { state: { toAnalytics: true } });
    } else if (content_type == 'cpd') {
      navigate(routeNames.cpdReview.landing, options);
    } else if (content_type == 'claim_certificate') {
      if (claimedCertificateId && claimedCertificateId != 0) {
        navigate(`${routeNames.certificate.detail}/${claimedCertificateId}`, options);
      } else {
        navigate(routeNames.certificate.landing, options);
      }
    } else if (content_type == 'differential_diagnosis') {
      if (!content_id || content_id == 0) {
        navigate(routeNames.differentialDiagnosis.landing, options);
      } else {
        navigate(`${routeNames.differentialDiagnosis.landing}/${content_id}`, options);
      }
    } else if (content_type == 'claim_mentorship') {
      if (!content_id || content_id == 0) {
        navigate(routeNames.observership.registration, options);
      } else {
        navigate(`${routeNames.observership.profile}/${content_id}`, options);
      }
    } else if (content_type == 'mentor') {
      if (!content_id || content_id == 0) {
        navigate(routeNames.observership.registration, options);
      } else {
        navigate(`${routeNames.observership.profile}/${content_id}`, options);
      }
    } else if (content_type == 'observership') {
      if (!content_id || content_id == 0) {
        navigate(routeNames.observership.registration, options);
      } else {
        navigate(`${routeNames.observership.observershipDetails}/${content_id}`, options);
      }
    } else {
      navigate(`/`, options);
    }
  }

  return { redirectTo, navigateToSharePage, getSharePageURL, navigateToAssociatedPath };
};

export default useRedirect;
