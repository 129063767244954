import React, { useEffect, useState } from 'react';
import LeftContainer from '../../components/baseUiV3/template/pageContainer/LeftContainer';
import MainContainer from '../../components/baseUiV3/template/pageContainer/MainContainer';
import RightContainer from '../../components/baseUiV3/template/pageContainer/RightContainer';
import RightSideContent from '../uiv3/profile/components/RightSideContent';
import Aida from './Aida';
import AidaHistory from './components/AidaHistory';
import DDXResult from './components/DDXResult';
import { useGetImageToText } from '../../Queries/QueryHooks/aida.hook';
import LeftSideContent from '../uiv3/dashboard/components/LeftSideContent';
import { useQueryClient } from 'react-query';
import { AIDA_HISTORY_LIST_KEY } from '../../Queries/QueryKeys/aida.key';
import { getLocalStorage } from '../../common/common';
import { CARD_CLICK } from '../../components/tracking/constants';
import { useTracker } from '../../TrackerFolder/TrackerComponent';
import AidaChatScreen from './components/AidaChatScreen';

const AidaContainer = ({ share = false }) => {
  const queryClient = useQueryClient();
  const [isHistoryPage, setIsHistoryPage] = useState(false);
  const [isResultPage, setIsResultPage] = useState(false);
  const { trackActivityEvent } = useTracker();
  const [fileDDX, setFileDDX] = useState('');
  const [userCurrentInput, setUserCurrentInput] = useState({
    chiefComplaint: '',
    extraDetails: ''
  });
  const { data: aidaImageToText, isLoading: isAidaImageToTextLoading } = useGetImageToText(fileDDX);

  useEffect(() => {
    setUserCurrentInput({
      chiefComplaint: aidaImageToText || '',
      extraDetails: ''
    });
  }, [aidaImageToText, isAidaImageToTextLoading]);

  const invalidateAidaHistoryList = () => {
    const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
    queryClient.invalidateQueries([`${AIDA_HISTORY_LIST_KEY}_${userId}`]);
  };

  const showHistoryHandler = () => {
    trackActivityEvent(CARD_CLICK, {});
    setIsHistoryPage(true);
  };
  const hideHistoryHandler = () => {
    setIsHistoryPage(false);
  };
  const showResultHandler = () => {
    trackActivityEvent(CARD_CLICK, {});
    invalidateAidaHistoryList();
    setIsResultPage(true);
  };
  const hideResultHandler = () => {
    setIsResultPage(false);
  };
  const handleInputChange = (event) => {
    setUserCurrentInput((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const clearUserInputHandler = () => {
    setUserCurrentInput({ chiefComplaint: '', extraDetails: '' });
    setFileDDX('');
  };
  const showDemoHandler = (value) => {
    setUserCurrentInput({ chiefComplaint: value, extraDetails: '' });
    setTimeout(() => {
      showResultHandler();
    }, 1500);
  };

  const fileDDXHandler = (value) => {
    setFileDDX(value);
  };

  return (
    <>
      {/* <LeftContainer>
        <LeftSideContent page="profile" />
      </LeftContainer> */}

      {/* <MainContainer>
        {!isHistoryPage && !isResultPage && (
          <Aida
            showHistoryHandler={showHistoryHandler}
            showResultHandler={showResultHandler}
            userCurrentInput={userCurrentInput}
            handleInputChange={handleInputChange}
            clearUserInputHandler={clearUserInputHandler}
            showDemoHandler={showDemoHandler}
            fileDDX={fileDDX}
            fileDDXHandler={fileDDXHandler}
            isAidaImageToTextLoading={isAidaImageToTextLoading}
          />
        )}
        {isHistoryPage && <AidaHistory hideHistoryHandler={hideHistoryHandler} />}
        {isResultPage && (
          <DDXResult
            hideResultHandler={hideResultHandler}
            userCurrentInput={userCurrentInput}
            fileDDX={fileDDX}
          />
        )}
      </MainContainer> */}
      <AidaChatScreen share={share} />
    </>
  );
};

export default AidaContainer;
