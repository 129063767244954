import { useQuery } from 'react-query';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { GET_BANNER_DATA } from '../apis/banner.apis';

const staleTime = 900000;
const cacheTime = 900000;

const FetchBanner = async ({ queryKey }) => {
  return axiosInstance.get(`${GET_BANNER_DATA}?position=${queryKey[1]}`);
};

export const useBannerData = (position, enabled, page_name, page_id, uuid, onSuccess) => {
  return useQuery([GET_BANNER_DATA, position, page_name, page_id, uuid], FetchBanner, {
    select: (response) => {
      return {
        data: response?.data?.data
          ? position in response?.data?.data
            ? response?.data?.data[position]
            : undefined
          : undefined,
        ...response?.data?.data,
        timestamp_get: new Date().valueOf()
      };
    },
    enabled: false,
    // cacheTime,
    // staleTime,
    refetchOnWindowFocus: false,
    onSuccess
  });
};
