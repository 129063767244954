import React, { useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import packageJSON from '../../../package.json';
import {
  downloadFromUrl,
  generateUUID,
  getClientDeviceDetails,
  getLocalStorage,
  userDetails
} from '../../common/common';
import { GET_CHANNEL_DETAILS } from '../../Queries/QueryKeys/channel.key';
import { CL_VIDEO_DETAILS } from '../../Queries/QueryKeys/clvideo.key';
import { Ebooks_Details } from '../../Queries/QueryKeys/ebook.key';
import { GRANDROUND_DETAILS } from '../../Queries/QueryKeys/grandround.key';
import { MEDWIKI_DETAILS } from '../../Queries/QueryKeys/medwiki.key';
import { SESSION_DETAILS } from '../../Queries/QueryKeys/session.key';
import { SPQ_DETAILS } from '../../Queries/QueryKeys/spq.key';
import { TRAINING_DETAILS } from '../../Queries/QueryKeys/training.key';
import { Skeleton } from '../../skeleton/Skeleton';
import { addBannerClickReport, addBannerConsentClickReport } from '../../Store/actions';
import axiosInstance from '../../Store/helper/axiosInstance';
import useRedirect from '../customHooks/useRedirect';
import { useBannerData } from './actions/useBannerData';
import { useBannerSettings } from './actions/useBannerSettings';
import { ADD_BANNER_REPORT } from './apis/banner.apis';
import { useBannerWebSocket } from './BannerWebsocketConfig';
import ConsentModal from './ConsentModal';
import ImageBannerPlayer from './ImageBannerPlayer';
import { getCurrentTime } from './utils/getCurrentTime';
import { getPlatForm } from './utils/getPlatForm';
import { openInNewTab } from './utils/openInNewTab';

let visibilityChange = typeof document.hidden
  ? 'visibilitychange'
  : typeof document.msHidden
  ? 'msvisibilitychange'
  : typeof document.webkitHidden
  ? 'webkitvisibilitychange'
  : 'visibilitychange';

function BannerItem({
  position,
  page_id: pageId = null,
  page_name = null,
  wrapper = null,
  layout = 'default',
  type = 'default',
  closedAdsCount = 0,
  setHasData = null,
  disableHasData = null,
  setBannerData = null,
  setPlayedDuration,
  isDataLoading = false
}) {
  const [cardRef, inView] = useInView({
    fallbackInView: true,
    threshold: 0
  });
  const { redirectTo } = useRedirect();
  const { id } = useParams();
  const page_id = pageId ? pageId : id;
  const [palying, setPlaying] = useState(false);
  const [is_consent_modal, setIsConsentModal] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [enableGlobalCampaign, setEnableGlobalCampaign] = useState(false);
  const dispatch = useDispatch();
  const uuid = useRef(generateUUID());

  // const { campaignData: allCampaignData, contentCampaignData, reportBannerData } = useBanner();
  const { data: currentSettings } = useBannerSettings(position, type);
  const enabled = inView && enableGlobalCampaign;

  const settings =
    inView && currentSettings ? currentSettings[isMobile ? 'mobile' : 'desktop'] : null;

  const { refetch } = useBannerData(settings, enabled, page_name, page_id, uuid.current, (res) => {
    console.log('checkNextItem -1', res);
    if (res?.data) {
      setCampaignData({ ...res, start_time: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      setPlaying(true);
      // } else {
      // refetch();
    }
  });
  const { isConnected, sendMessage } = useBannerWebSocket();
  const queryClient = useQueryClient();
  function getContentCampaignData(page_name, page_id) {
    console.log('from survey', page_name);

    switch (page_name.trim().toLowerCase()) {
      case 'comp':
        return queryClient.getQueryData([MEDWIKI_DETAILS, page_id]);
      case 'video_archive':
        return queryClient.getQueryData([CL_VIDEO_DETAILS, page_id]);
      case 'epub':
        return queryClient.getQueryData([Ebooks_Details, page_id]);
      case 'session':
      case 'live_session':
      case 'session_waiting_room':
        return queryClient.getQueryData([SESSION_DETAILS, page_id]);
      case 'training':
        return queryClient.getQueryData([TRAINING_DETAILS, page_id]);
      case 'survey':
        return queryClient.getQueryData([SPQ_DETAILS, page_id]);
      case 'gr':
        return queryClient.getQueryData([GRANDROUND_DETAILS, page_id]);
      case 'channel':
        return queryClient.getQueryData([GET_CHANNEL_DETAILS, page_id]);
    }
  }

  const contentCampaignData =
    page_id && page_name ? getContentCampaignData(page_name, page_id) : undefined;

  async function reportPartialView() {
    const payload = {
      banner_user: getLocalStorage('user', '{}')?.user_master_id,
      campaign_id: campaignData?.CampaignId,
      creative_id: campaignData ? campaignData?.data?.CrId : '',
      duration: dayjs().diff(campaignData?.start_time, 'second'),
      position: position,
      os: getPlatForm(isMobile),
      start_time: campaignData?.start_time,
      end_time: getCurrentTime(),
      timestamp_get: campaignData?.timestamp_get,
      type: page_name,
      type_id: page_id,
      version: `rjsw ${packageJSON.version}`
    };

    if (isConnected) {
      sendMessage('ADD_BANNER_REPORT', [payload]);
    } else {
      await axiosInstance.post(ADD_BANNER_REPORT, [payload]);
    }
  }

  useEffect(() => {
    setEnableGlobalCampaign(false);

    if (inView) {
      if (page_id && page_name) {
        var contentData = Array.isArray(contentCampaignData?.data?.data)
          ? contentCampaignData?.data?.data[0]
          : contentCampaignData?.data?.data;

        console.log('contentData', contentData);

        if (contentData) {
          if (contentData?.display_banner === false) {
            //dont show banner
          } else {
            const campaign_data = contentData?.campaign_data?.[0];

            console.log('campaign_data', campaign_data);

            if (campaign_data) {
              setCampaignData({
                data: campaign_data[
                  currentSettings ? currentSettings[isMobile ? 'mobile' : 'desktop'] : null
                ],
                ...campaign_data,
                timestamp_get: new Date().valueOf(),
                contentCampaign: true
              });
              setPlaying(true);
            } else {
              setEnableGlobalCampaign(true);
            }
          }
        }
      } else {
        setEnableGlobalCampaign(true);
      }
    } else {
      if (palying) {
        reportPartialView();
        setPlaying(false);
      }
    }
  }, [page_id, page_name, contentCampaignData, inView, currentSettings]);

  // const { getNewBanner } = useBanner();

  function checkNextItem() {
    setPlaying(false);
    reportBannerView(campaignData);
    if (!campaignData?.contentCampaign && inView) {
      refetch();
    }
  }
  const cardRefNew = React.useRef(null);
  const playerRef = React.useRef(null);

  const getBannerClass = useMemo(() => {
    let tempClass = '';

    if (layout != 'speciality') {
      if (position == 'top') {
        if (isMobile && page_id && page_id != 0 && layout == 'speciality') {
          tempClass = 'mb-sm-4 mb-3 mt-20 px-3';
        } else {
          tempClass = 'mb-sm-4 mb-3 mt-3';
        }
      } else if (position == 'bottom') {
        tempClass = 'mb-4 mt-sm-4 mt-3 pt-3';
      } else if (position == 'side-top') {
        if (layout != 'speciality') {
          tempClass = 'mb-4';
        } else {
          tempClass = 'mt-3';
        }
      } else if (position == 'side-bottom') {
        if (layout != 'speciality') {
          tempClass = 'mt-4 mb-4';
        } else {
          tempClass = 'mt-3';
        }
      }

      // else if (position == 'section-header') {
      //   tempClass = 'mt-4 mb-4';
      // }
    }

    return tempClass;
  }, [position]);

  const setBannerPosition = (increase) => {
    const payload = {};
    if (currentSettings != null) {
      if (
        !isMobile &&
        (position == 'top' || position == 'bottom' || position == 'section-header')
      ) {
        payload.maxWidth = currentSettings.width + 'px';
        payload.marginLeft = 'auto';
        payload.marginRight = 'auto';
        payload.display = 'flex';
      }

      const parent_width = cardRefNew?.current?.clientWidth;
      const height =
        (parent_width * parseInt(currentSettings.height)) / parseInt(currentSettings.width);

      (payload.height = increase ? `calc(${height}px + 1.5rem)` : height), (payload.width = '100%');
    } else {
      payload.height = '0px';
      payload.width = '100%';
    }

    return payload;
  };

  const setBannerHeight = (increase) => {
    if (currentSettings != null) {
      const parent_width = cardRefNew?.current?.clientWidth;
      const height =
        (parent_width * parseInt(currentSettings.height)) / parseInt(currentSettings.width);
      const payload = {
        height: increase ? `calc(${height}px + 1.5rem)` : height,
        width: '100%'
      };
      return payload;
    } else {
      return {
        height: '0px',
        width: '100%'
      };
    }
  };

  const onBannerClick = () => {
    if (campaignData) {
      let temp_click_report = {
        campaign_id: campaignData?.CampaignId ? campaignData?.CampaignId : 0,
        content_type: page_name ? page_name : 'not found',
        content_type_id: page_id,
        banner_position: position,
        creative_id: campaignData?.data?.CrId ?? 0
      };
      dispatch(
        addBannerClickReport(temp_click_report, (response) => {
          //console.log("click report added")
        })
      );

      if (campaignData?.CTA) {
        handleCTAAction();
      } else {
        return false;
      }
    }
  };

  function handleCTAAction() {
    if (campaignData?.CTA) {
      switch (campaignData?.CTA?.Type) {
        case 'con':
          toggleConsentModal(true);
          break;
        case 'ext':
        case 'int':
          if (campaignData?.CTA?.Behave === 'pop') {
            openInNewTab(campaignData?.CTA?.ActionPath);
          } else if (campaignData?.CTA?.Behave === 'download') {
            downloadFromUrl(campaignData?.CTA?.ActionPath);
          } else {
            if (campaignData?.CTA?.ActionID && campaignData?.CTA?.ActionID != 0) {
              redirectTo(campaignData?.CTA?.ActionPath, campaignData?.CTA?.ActionID);
            } else {
              openInNewTab(campaignData?.CTA?.ActionPath);
            }
          }

          break;

        default:
          console.log('no cta found-----☹☹');
          break;
      }
    } else {
      return false;
    }
  }

  function getModifiedURL() {
    const url = campaignData ? campaignData?.data?.CrUrl : '';

    const creative_id = campaignData ? campaignData?.data?.CrId : '';
    const campaign_id = campaignData ? campaignData?.CampaignId : '';

    let transformation = ''; //`?tr=pr=true,c-at_max`;
    // console.log('inside video banner player', transformation, url);
    if (url && typeof url == 'string' && !url.includes('https')) {
      let splittedImg = url?.split('/');
      let path_extracted_image = splittedImg[splittedImg?.length - 1];
      const cfImageUrl = `https://bnr-cdn.clirnet.com/${path_extracted_image}${transformation}&cid=${campaign_id}&umid=${
        userDetails().user_master_id
      }&crid=${creative_id}`;
      return cfImageUrl;
    } else {
      // console.log('inside video banner player in else ', url);
      return url;
    }
  }

  function handleSuspend() {
    if (isMobile && campaignData) {
      document.body.addEventListener('touchstart', handleMobileLowPowerMode, true);
    }
  }

  function handleMobileLowPowerMode() {
    const player = playerRef.current;
    if (player && palying && campaignData?.Type === 'video') {
      const videoElement = player.getInternalPlayer();
      videoElement.play();
    }
  }

  async function reportBannerView(item = null) {
    const playedItem = item ? item : campaignData;

    if (playedItem) {
      const payload = {
        banner_user: getLocalStorage('user', '{}')?.user_master_id,
        campaign_id: campaignData?.CampaignId,
        creative_id: campaignData ? campaignData?.data?.CrId : '',
        duration: 5,
        position: position,
        os: getPlatForm(isMobile),
        start_time: dayjs().subtract(5, 'second').format('YYYY-MM-DD HH:mm:ss'),
        end_time: getCurrentTime(),
        timestamp_get: campaignData?.timestamp_get,
        type: page_name,
        type_id: page_id,
        version: `rjsw ${packageJSON.version}`
      };

      if (isConnected) {
        sendMessage('ADD_BANNER_REPORT', [payload]);
      } else {
        await axiosInstance.post(ADD_BANNER_REPORT, [payload]);
      }
    }
  }

  const toggleConsentModal = (payload) => {
    setPlaying(!payload);
    setIsConsentModal(payload);
  };

  const onAgreeConsent = () => {
    let mobile_primary = userDetails()?.mobile_primary ? userDetails()?.mobile_primary : '';
    let device_details = getClientDeviceDetails(mobile_primary);

    dispatch(
      addBannerConsentClickReport(
        parseInt(campaignData?.CampaignId),
        JSON.stringify(device_details),
        (response) => {
          toggleConsentModal(false);
          if (campaignData?.CTA?.ActionID && campaignData?.CTA?.ActionID != 0) {
            redirectTo(campaignData?.CTA?.ActionPath, campaignData?.CTA?.ActionID);
          } else {
            openInNewTab(campaignData?.CTA?.ActionPath);
          }
        }
      )
    );
  };

  useEffect(() => {
    document.addEventListener(visibilityChange, reportBannerView(campaignData));
    document.addEventListener('pagehide', reportBannerView(campaignData));

    return () => {
      document.addEventListener(visibilityChange, reportBannerView(campaignData));
      document.addEventListener('pagehide', reportBannerView(campaignData));
    };
  }, []);

  if (currentSettings && isDataLoading) {
    return (
      <div ref={cardRef}>
        <Skeleton
          skeletonStyle={layout != 'speciality' ? setBannerPosition(true) : setBannerHeight(true)}
        />
      </div>
    );
  } else if (
    currentSettings &&
    campaignData &&
    campaignData?.Type === 'video' &&
    campaignData?.data
  ) {
    if (wrapper !== null)
      return wrapper({
        bannerDiv: (
          <div
            ref={cardRef}
            // style={layout === 'speciality' ? setBannerHeight() : setBannerPosition()}
            style={layout != 'speciality' ? setBannerPosition() : setBannerHeight()}
            onClick={onBannerClick}
          >
            <div
              ref={cardRefNew}
              style={setBannerHeight()}
              // className={
              //   position == 'top' || position == 'bottom' ? 'globalBanner_style' : ''
              // }
            >
              <ReactPlayer
                // onReady={handleLoad}
                className="d-flex rounded-3 overflow-hidden cursorPointer"
                key={campaignData ? campaignData?.data?.CrId : ''}
                width={'100%'}
                height={'100%'}
                muted={true}
                playsinline={true}
                controls={false}
                playing={inView && palying}
                url={getModifiedURL()}
                onProgress={(e) => {
                  setPlayedDuration && setPlayedDuration(e.playedSeconds);
                }}
                onEnded={checkNextItem}
                ref={playerRef}
                onSuspend={handleSuspend}
                // onError={(e) => {
                //   trackActivityEvent(
                //     BANNER_COMPONENT_VIDEO_ERROR,
                //     onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)
                //     // true
                //   );

                //   // sendReport(
                //   //   generatePayload(
                //   //     networkState,
                //   //     page_name,
                //   //     page_id,
                //   //     position,
                //   //     'BANNER_COMPONENT_VIDEO_ERROR',
                //   //     e
                //   //   )
                //   // );
                // }}
              />
              <ConsentModal
                title="Terms & Conditions"
                is_consent_modal={is_consent_modal}
                consent_text={campaignData?.CTA?.ConVal}
                onHide={() => toggleConsentModal(false)}
                onAgreeConsent={onAgreeConsent}
              />
            </div>
          </div>
        ),
        title: campaignData?.CTA?.Title || '',
        buttonTxt: campaignData?.CTA?.ButtonText || '',
        btnClick: onBannerClick,
        removeDiv: campaignData?.data ? false : true
      });
    else
      return (
        <div
          className={getBannerClass}
          ref={cardRefNew}
          style={layout != 'speciality' ? setBannerPosition(true) : setBannerHeight(true)}
          // className={`globalBanner_style ${getBannerClass}`}
        >
          <div
            ref={cardRef}
            style={setBannerHeight()}
            onClick={onBannerClick}
            className={`mt-4 position-relative`}
          >
            <h4
              className="fs-6 fw-medium text-black position-absolute text-right"
              style={{ right: '0', bottom: '100%' }}
              title={campaignData?.CTA?.Title ? campaignData?.CTA?.Title : 'Sponsored Ad'}
            >
              Ad
            </h4>
            <ReactPlayer
              // onReady={handleLoad}
              className="d-flex rounded-3 overflow-hidden cursorPointer"
              key={campaignData ? campaignData?.data?.CrId : ''}
              width={'100%'}
              height={'100%'}
              muted={true}
              playsinline={true}
              controls={false}
              playing={inView && palying}
              url={getModifiedURL()}
              onProgress={(e) => {
                setPlayedDuration && setPlayedDuration(e.playedSeconds);
              }}
              onEnded={checkNextItem}
              ref={playerRef}
              onSuspend={handleSuspend}
              // onError={(e) => {
              //   trackActivityEvent(
              //     BANNER_COMPONENT_VIDEO_ERROR,
              //     onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)
              //     // true
              //   );

              //   // sendReport(
              //   //   generatePayload(
              //   //     networkState,
              //   //     page_name,
              //   //     page_id,
              //   //     position,
              //   //     'BANNER_COMPONENT_VIDEO_ERROR',
              //   //     e
              //   //   )
              //   // );
              // }}
            />
            <ConsentModal
              title="Terms & Conditions"
              is_consent_modal={is_consent_modal}
              consent_text={campaignData?.CTA?.ConVal}
              onHide={() => toggleConsentModal(false)}
              onAgreeConsent={onAgreeConsent}
            />
          </div>
        </div>
      );
  } else if (
    campaignData &&
    currentSettings &&
    campaignData?.Type === 'image' &&
    campaignData?.data
  ) {
    if (wrapper != null) {
      return wrapper({
        bannerDiv: (
          <div
            ref={cardRef}
            // style={layout === 'speciality' ? setBannerHeight() : setBannerPosition()}
            style={layout != 'speciality' ? setBannerPosition() : setBannerHeight()}
            onClick={onBannerClick}
          >
            <div
              ref={cardRefNew}
              style={setBannerHeight()}
              // className={
              //   position == 'top' || position == 'bottom' ? 'globalBanner_style' : ''
              // }
            >
              <ImageBannerPlayer
                className="d-flex rounded-3 overflow-hidden cursorPointer"
                url={campaignData ? campaignData?.data?.CrUrl : ''}
                seconds={5}
                playing={inView && palying}
                onEnded={checkNextItem}
                campaign_id={campaignData?.CampaignId}
                creative_id={campaignData ? campaignData?.data?.CrId : ''}
                // onDuration={checkNextItem}
                onDuration={(e) => setPlayedDuration && setPlayedDuration(e)}
                // onError={(e) => {
                //   trackActivityEvent(
                //     BANNER_COMPONENT_VIDEO_ERROR,
                //     onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)

                //     // true
                //   );
                //   // sendReport(
                //   //   generatePayload(
                //   //     networkState,
                //   //     page_name,
                //   //     page_id,
                //   //     position,
                //   //     'BANNER_COMPONENT_IMAGE_ERROR',
                //   //     e
                //   //   )
                //   // );
                // }}
              />
              <ConsentModal
                title="Terms & Conditions"
                is_consent_modal={is_consent_modal}
                consent_text={campaignData?.CTA?.ConVal}
                onHide={() => toggleConsentModal(false)}
                onAgreeConsent={onAgreeConsent}
              />
            </div>
          </div>
        ),
        title: campaignData?.CTA?.Title || '',
        buttonTxt: campaignData?.CTA?.ButtonText || '',
        btnClick: onBannerClick,
        removeDiv: campaignData?.data ? false : true
      });
    } else
      return (
        <div
          className={getBannerClass}
          ref={cardRefNew}
          style={layout != 'speciality' ? setBannerPosition(true) : setBannerHeight(true)}
          // className={`globalBanner_style ${getBannerClass}`}
        >
          <div
            ref={cardRef}
            style={setBannerHeight()}
            onClick={onBannerClick}
            className={`mt-4 position-relative`}
          >
            <h4
              className="fs-6 fw-medium text-black position-absolute text-right"
              style={{ right: '0', bottom: '100%' }}
              title={campaignData?.CTA?.Title ? campaignData?.CTA?.Title : 'Sponsored Ad'}
            >
              Ad
            </h4>
            <ImageBannerPlayer
              className="d-flex rounded-3 overflow-hidden cursorPointer"
              url={campaignData ? campaignData?.data?.CrUrl : ''}
              seconds={5}
              playing={inView && palying}
              onEnded={checkNextItem}
              campaign_id={campaignData?.CampaignId}
              creative_id={campaignData ? campaignData?.data?.CrId : ''}
              // onDuration={checkNextItem}
              onDuration={(e) => setPlayedDuration && setPlayedDuration(e)}
              // onError={(e) => {
              //   trackActivityEvent(
              //     BANNER_COMPONENT_VIDEO_ERROR,
              //     onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)

              //     // true
              //   );
              //   // sendReport(
              //   //   generatePayload(
              //   //     networkState,
              //   //     page_name,
              //   //     page_id,
              //   //     position,
              //   //     'BANNER_COMPONENT_IMAGE_ERROR',
              //   //     e
              //   //   )
              //   // );
              // }}
            />
            <ConsentModal
              title="Terms & Conditions"
              is_consent_modal={is_consent_modal}
              consent_text={campaignData?.CTA?.ConVal}
              onHide={() => toggleConsentModal(false)}
              onAgreeConsent={onAgreeConsent}
            />
          </div>
        </div>
      );
  } else return <div ref={cardRef}></div>;
}

export default BannerItem;
