import axiosInstance from '../helper/axiosInstance';
import { generateUtmExt, log } from '../../common/common';
import { logoutUser } from './login.action';
import dayjs from 'dayjs';
import axios from 'axios';
const url = process.env.REACT_APP_API_URL;
export const getSpecialityTrainings = (specs, pagination, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`training/listing?from=${pagination}&to=10&spIds=${specs}`)
      .then((response) => {
        if (response.data) {
          callback(response.data, response?.status);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};

export const getContentsByModules = (training_id, module_id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`training/contentsbymodule?training_id=${training_id}&module_id=${module_id}`)
      .then((response) => {
        if (response?.data) {
          // callback(response.data?.data);
          callback({
            data: response?.data?.data,
            statusCode: response?.status
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback({ statusCode: error?.status });
      });
  };
};
//training/contents_by_module?type_id=20&module_id=2
//training/track_user
export const trackUserCompletion = (trackingObj, callback) => {
  return async (dispatch) => {
    let data = trackingObj.data;
    data[0].training_details.viewed_time =
      Math.round(dayjs().diff(dayjs(trackingObj.present_timestamp)) / 1000) ?? 0;

    axiosInstance
      .post('training/track_user', data)
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const postInitialUserCompletion = (trackingObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('training/track_user', trackingObj)
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const getOpenApiDetails = (training_id, callback) => {
  return async (dispatch) => {
    axios
      .get(`${url}openapi/trainingdetail?id=${training_id}${generateUtmExt()}`)
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const getOpenApiModuleList = (training_id, callback) => {
  return async (dispatch) => {
    axios
      .get(`${url}openapi/trainingmodulelist?id=${training_id}${generateUtmExt()}`)
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const getModuleList = (training_id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`training/module_list?id=${training_id}`)
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const getOpenContentsByModules = (training_id, module_id, callback) => {
  return async (dispatch) => {
    axios
      .get(
        `${url}openapi/trainingcontentsbymodule?training_id=${training_id}&module_id=${module_id}`
      )
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
//totalsurvey?training_id=1
//openapi/trainingcontentsbymodule?training_id=1&module_id=1
export const postReview = (training_id, rating, review, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('training/postreview', { training_id, rating, review })
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const getSpqScores = (training_id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`training/totalsurvey?training_id=${training_id}`)
      .then((response) => {
        if (response) {
          callback(response);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
//openapi/trainingdetail?id=2
//openapi/trainingmodulelist?id=1
//https://dockerapidev.clirnet.com/rnv32/training/postreview
