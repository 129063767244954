import React, { memo } from 'react'
import { Skeleton } from '../../../../../skeleton/Skeleton'

function DoctorChatListSkeleton() {
  return (
    <div className='d-flex flex-column rounded-3 bg-extra-light p-3 p-sm-4 h-100'>
        <div className='d-flex align-items-center'>
            <h2 className='fs-16 flex-grow-1 text-black fw-semibold'>
                <Skeleton variant={'text'} height={30} width={160} />
            </h2>
        </div>
        
        <Skeleton variant={'text'} height={70} width={'100%'} className='flex-shrink-0 position-relative search_inputContainer'/>
        <div className='w-100 d-flex flex-column row-gap-3 flex-grow-1 observer__doctorlist'>
            {[1,2,3,4,5].map((i)=> (
                <div>
                <div className='observer__doctorlist__item transition w-100 d-flex gap-3 rounded-3 h-auto align-items-center cursorPointer swiper-slide-active'>
                    <div className='transition flex-shrink-0 position-relative'>
                        <div className='rounded-circle overflow-hidden d-flex align-items-center justify-content-center  icon-size-48  bg-white '>
                        <Skeleton variant={'circular'} className='object-fit-cover w-100 h-100'/>
                        </div>
                    </div>
                    <div className='w-100 d-flex flex-column transition flex-grow-1'>
                    <Skeleton variant={'text'} height={20} width={100} />
                    <Skeleton variant={'text'} height={15} width={50} />
                    </div>
                    <span className='icon-size-32 transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative fs-3 clr_v3_icon-btn-1'>
                    <Skeleton variant={'circular'} height={30} width={30} />
                    </span>
                </div>
            </div>
            ))}
            
        </div>
    </div>
  )
}

export default memo(DoctorChatListSkeleton);