import React, { memo } from 'react';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';

const WarningBox = () => {
  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={{}}
      className="rounded-3 bg-white position-relative border border-1 border-soft-danger text-danger p-3"
    >
      <div className="d-flex align-items-start gap-2 my-2 fw-medium">
        <i className="flaticon-warning fs-4 text-danger mt-1"></i>
        <span className="fs-4">
          <strong>Warning: </strong> If no interactions are found between two drugs, it does not
          necessary mean that no interactions exist. Always consult with a Health Care professional
        </span>
      </div>
    </TrackerWrapper>
  );
};

export default memo(WarningBox);
