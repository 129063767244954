import React, { memo } from 'react';
import { AchievementColor } from '../../../../icons';
import '../css/creaditsPoints.scss';

const CpdPoint = ({ data }) => {
  const cpddetail = data?.cpddetail ?? []; //data;
  console.log('cpddetail', cpddetail);
  return (
    <>
      {cpddetail && cpddetail?.length > 0 && (
        // <div className="ssnCardV1Credit bg-white rounded-3 text-center">
        //   <span className="ssnCardV1CreditIcon rounded-circle bg-white">
        //     <AchievementColor customClass="ssnCardV1CreditIcon_SVG" />
        //   </span>
        //   <div className="ssnCardV1CreditPoints d-flex align-items-center justify-content-center bgColorBlue rounded-3">
        //     <h4 className="fw-medium text-white fs-4">
        //       {cpddetail[0].points && (
        //         <span className="d-block font_22 font700">
        //           {cpddetail[0]?.points >= 0 ? cpddetail[0]?.points : 0}
        //         </span>
        //       )}
        //       CME Credits
        //     </h4>
        //   </div>
        //   {cpddetail[0].short_name ? (
        //     <span className="fw-semibold text-black text-center position-relative ssnCardV1CreditCouncil">
        //       {cpddetail[0].short_name}
        //     </span>
        //   ) : (
        //     <span className="fw-semibold text-black text-center position-relative ssnCardV1CreditCouncil"></span>
        //   )}
        // </div>

        <div className="ssnCardV1Credit bg-white rounded-3 text-center overflow-hidden position-absolute p-1 z-1">
          <span className="ssnCardV1CreditIcon rounded-circle bg-white position-absolute">
            <AchievementColor customClass="ssnCardV1CreditIcon_SVG position-absolute" />
          </span>
          <div className="ssnCardV1CreditPoints p-3 d-flex align-items-center justify-content-center bg-primary rounded-3">
            <h4 className="fw-medium text-white fs-4">
              {cpddetail[0]?.points && (
                <span className="d-block fs-2 fw-bold">{cpddetail[0]?.points}</span>
              )}
              CME Credits
            </h4>
          </div>
          <span className="fw-semibold text-black text-center position-relative ssnCardV1CreditCouncil w-100 py-33 d-block">
            {cpddetail[0]?.short_name ?? ''}
          </span>
        </div>
      )}
      {/** end of section */}
    </>
  );
};
export default memo(CpdPoint);
