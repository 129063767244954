import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import PopularSpecialityLoader from '../../skeleton/medwiki/MedwikiLandingV2/PopularSpecialityLoader';
import { useTracker } from '../../TrackerFolder/TrackerComponent';
import useRedirect from '../customHooks/useRedirect';
import { IoIosArrowDown, IoIosArrowUp, MdKeyboardArrowRight } from '../icons';
import './css/style.scss';
import { routeNames } from '../../router/constants';
import { MORE_CLICK } from '../tracking/constants';
import { activeStatusCode } from '../../utils/constants/common.constant';
import InactiveComponent from '../InactiveComponent';

function PopularSpeciality({
  data,
  isLoading,
  title,
  numberOfItems = 15,
  statusCode,
  extraClass,
  isExpanded,
  onArrowClick,
  gtmTagVariable = ''
}) {
  // const isLoading =  true;
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();
  console.log('title', title, data, isLoading);
  return (
    <>
      {isLoading == false ? (
        <>
          {statusCode === activeStatusCode ? (
            <div
              className={`w-100 shadow bg-white rounded-3 specialitiesBox p-3 p-md-4 ${extraClass}`}
              id={
                title == 'Browse By Specialities'
                  ? 'medwiki_popular_speciality'
                  : 'medwiki_detail_popular_speciality'
              }
            >
              <div className="d-flex align-items-baseline justify-content-between mb-4">
                <h2 className="text-black fs-3 fw-medium text-start">
                  <span className="text-secondary">{title?.split(' ')[0]}</span>{' '}
                  {title?.slice(title?.split(' ')[0].length, title?.length || 0)}
                </h2>
                <a
                  href="javascript:void(0)"
                  className={`text-primary d-inline-block text-nowrap ${gtmTagVariable}_explore_more`}
                  onClick={() => {
                    trackActivityEvent(MORE_CLICK, data);
                    redirectTo(routeNames.medwiki.listing, '', '');
                  }}
                  id={
                    title == 'Browse By Specialities'
                      ? 'medwiki_popular_speciality_view_all'
                      : 'medwiki_detail_popular_speciality_view_all'
                  }
                >
                  Explore More
                </a>
              </div>
              <ul className="specialitiesList mt-2 ps-0 text-start">
                {data &&
                  data?.slice(0, numberOfItems).map((_d, index) => (
                    <li
                      key={index + 1}
                      className={`d-block fs-4 ps-20 position-relative mb-4 text-black cursorPointer ${gtmTagVariable}_specialities`}
                      // id={`speciality_${_d.master_specialities_id}`}
                      onClick={() => {
                        trackActivityEvent(MORE_CLICK, data);
                        redirectTo(
                          routeNames.medwiki.listing,
                          '',
                          '',
                          `specialities=${_d.master_specialities_id}`
                        );
                      }}
                    >
                      <MdKeyboardArrowRight
                        customClass="fs-3 specialitiesListIcon position-absolute start-0"
                        gtmTagVariable={`${gtmTagVariable}_specialities`}
                      />
                      {_d.specialities_name}
                    </li>
                  ))}
              </ul>
              {isMobile && (
                <div className="text-center" onClick={onArrowClick}>
                  {isExpanded ? (
                    <IoIosArrowUp
                      customClass={'fs-1 text-primary'}
                      gtmTagVariable={`${gtmTagVariable}_explore_more`}
                    />
                  ) : (
                    <IoIosArrowDown
                      customClass={'fs-1 text-primary'}
                      gtmTagVariable={`${gtmTagVariable}_explore_more`}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <InactiveComponent statuscode={statusCode} />
          )}
        </>
      ) : (
        <PopularSpecialityLoader title={title} />
      )}
    </>
  );
}

export default memo(PopularSpeciality);
