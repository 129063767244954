import React from 'react';
import { Skeleton } from '../Skeleton';

function ChannelCardSkeleton() {
  return (
    <div className="d-flex gap-3 gap-md-4 align-items-start border border-1 rounded-3 border-light p-3 clr_v3__channelCard clr_v3_channelCard--sidebar">
      <div className={`rounded-circle p-33 border border-primary border-105 bg-white`}>
        <div className="icon-size-36 icon-size-sm-48 overflow-hidden rounded-circle">
          <div className={`object-fit-cover w-100 h-100 position-relative`}>
            <Skeleton variant="circular" height={'100%'} width={'100%'} />
          </div>
        </div>
      </div>
      <div className="flex-1 d-flex flex-column">
        <h2 className="fs-4 fw-semibold mb-2 text-black line-clamp-2 position-relative">
          <Skeleton variant="text" height={'5'} width={'100%'} />
        </h2>

        <p className="line-clamp-1 position-relative">
          <Skeleton variant="rounded" height={'5'} width={'80%'} />
        </p>
      </div>
    </div>
  );
}

export default ChannelCardSkeleton;
