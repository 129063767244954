const url = [
  'https://storage.googleapis.com/medwiki/43_server/video/1665729790_DepuralogoV3.png',
  'https://storage.googleapis.com/medwiki/43_server/video/1657006938_Allegra_logo.png',
  'https://img-cdn.clirnet.com/medwiki/43_server/video/1729838524_Untitled-1.png'
];

export const isSponsorUrlIncluded = (sponserLogo) => {
  if (sponserLogo && sponserLogo.length === 1) {
    // Check for a single sponsor logo
    return url.includes(sponserLogo[0]);
  } else if (sponserLogo && sponserLogo.length > 1) {
    // Check if any of the sponsor logos are in the URL array
    return sponserLogo.some((logo) => url.includes(logo));
  }
  return false;
};

export const safeJsonParser = (json, defaultValue = null) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return defaultValue;
  }
};
