import React, { memo } from 'react';
import DoctorsSectionForCard from '../../../components/DoctorSectionForCard/DoctorsSectionForCard';
// import ReactHtmlParser from 'react-html-parser';
import ChannelHeroCardSkeleton from '../../../skeleton/channelSkeleton/ChannelHeroCardSkeleton';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { reactHtmlParser } from '../../../common/common';
import { removeHtmlTags } from '../../channel/utils';
import { isMobile } from 'react-device-detect';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
import { useDispatch } from 'react-redux';
import { toggleReference } from '../../../Store/actions';
const ChannelHeroCard = ({ data, gtmTagVariable = '' }) => {
  console.log('CHANNEL DATA', data);
  const { cover_image, logo, description, title, channel_doctor_entities, address, type_id } = data;

  const dispatch = useDispatch();

  function onReadMoreClick() {
    dispatch(toggleReference(true));
  }

  if (!type_id) return <></>;
  return (
    // <ChannelHeroCardSkeleton/>
    <TrackerWrapper
      type={CARD_VIEW}
      data={data}
      className="w-100 float-start rounded-3 shadow overflow-hidden bg-white shareLoginV2Featured shareLoginV2Channel p-3 p-md-4 position-relative z-1"
    >
      <div className="shareLoginV2FeaturedIn d-flex flex-column align-items-center">
        <div
          className="shareLoginV2FeaturedCircle position-absolute rounded-circle"
          style={{ backgroundColor: '#355fd3' }}
        ></div>
        {!isMobile ? (
          <div className="shareLoginV2FeaturedPic position-relative h-auto w-100 p-0">
            <div className="shareLoginV2FeaturedPicIn rounded-3 position-relative overflow-hidden z-1">
              <LazyImage src={cover_image} className="object-fit-cover w-100 h-100 float-start" />
              <div className="overlay bg-black bg-opacity-25"></div>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="shareLoginV2FeaturedRight d-flex align-items-center text-start w-100 ps-md-20 ps-lg-5">
          <div
            className={`shareLoginChannelLogo rounded p-3 p-md-4 position-relative bg-light-subtle 
          ${!isMobile ? 'mt-md-n50' : ''}`}
          >
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <img src={logo} className="mw-100 mh-100 w-auto h-auto" />
            </div>
          </div>
          <div className="shareLoginChannelDetails ps-4 ps-md-20 ps-lg-5  pt-md-4 text-start">
            <h2 className="fs-3 fw-semibold text-black">{title}</h2>
            {!isMobile ? (
              <>
                <h6 className="fs-16 fw-medium text-dark mt-2">{address}</h6>

                <h4 className="line-clamp-2 fs-4 fw-normal text-dark mt-2">
                  {removeHtmlTags(description)?.substring(0, 180)}
                  {removeHtmlTags(description)?.length > 180 && (
                    <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                      {' '}
                      ...Read More
                    </a>
                  )}
                </h4>
                {channel_doctor_entities?.length > 0 && (
                  <>
                    <h6 className="fs-16 fw-medium text-black mb-2 mt-4">Office Bearers</h6>

                    <div className="d-flex justify-content-between">
                      <div className="shareLogineV2Doctor shareLogineV2DoctorFull w-100">
                        <DoctorsSectionForCard
                          gtmTagVariable={`${gtmTagVariable}_channelHeroCard`}
                          cardData={{ session_doctor_entities: channel_doctor_entities }}
                          type={'channel_share'}
                          id={type_id}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        {isMobile ? (
          <div className="d-flex flex-column w-100 mt-2">
            <h6 className="fs-16 fw-medium text-dark mt-2">{address}</h6>

            <h4 className="line-clamp-3 fs-4 fw-normal text-dark mt-2">
              {removeHtmlTags(description)}
            </h4>
            {channel_doctor_entities?.length > 0 && (
              <>
                <h6 className="fs-16 fw-medium text-black mb-2 mt-4">Office Bearers</h6>

                <div className="d-flex justify-content-between">
                  <div className="shareLogineV2Doctor shareLogineV2DoctorFull w-100">
                    <DoctorsSectionForCard
                      gtmTagVariable={`${gtmTagVariable}_channelHeroCard`}
                      cardData={{ session_doctor_entities: channel_doctor_entities }}
                      type={'channel_share'}
                      id={type_id}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </TrackerWrapper>
  );
};

export default memo(ChannelHeroCard);
