import React, { memo, useEffect, useRef } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowDown } from '../../../components/icons';
import { toggleReference } from '../../../Store/actions';
import ISDPicker from '../../../components/ISDPicker';

const MobileNumber = ({
  value,
  onChangeIsd,
  onChange,
  onKeyPress,
  gtmTagVariable = '',
  isGlobalRegion = false
}) => {
  let field_value = value ? { value: value || '' } : {};
  const dispatch = useDispatch();
  const referenceValue = useSelector((state) => state.common.reference);
  const inputref = useRef();
  useEffect(() => {
    console.log('REFERENCE', referenceValue);
    if (inputref?.current) {
      referenceValue ? inputref?.current?.focus() : inputref?.current?.blur();
    }
  }, [referenceValue]);
  const blurReference = () => {
    dispatch(toggleReference(false));
  };
  const isdCodes = ['+91', '+1', '+6', '+977', '+975', '+960', '+61', '+82', '+94', '+95'];
  const isdCodesGlobal = ['+1', '+91', '+6', '+977', '+975', '+960', '+61', '+82', '+94', '+95'];

  return (
    <>
      <div className="d-flex w-100 gap-3">
        <div className="shareLoginV2Isd bg-white text-dark flex-shrink-0 w-fit-content">
          <ISDPicker
            className="h-100 form-select w-100 py-0 d-flex align-items-center justify-content-center text-center shadow-none bg-white fs-16"
            defaultChecked={isGlobalRegion ? '+1' : '+91'}
            onChange={(e) => {
              onChangeIsd(e.target.value);
            }}
          />
          {/* <IoIosArrowDown customClass={'fs-16 '} /> */}
        </div>
        <FloatingLabel
          className="flex-grow-1"
          controlId="floatingInput"
          label={<span>Enter Your Mobile No.{<span className="text-danger">*</span>}</span>}
        >
          <Form.Control
            ref={inputref}
            onBlur={blurReference}
            className={`fs-4 ${gtmTagVariable}_enterMobileNumber`}
            type="text"
            placeholder="Enter Your Mobile No."
            pattern="[6789][0-9]{9}"
            name="phone"
            onChange={(e) => {
              onChange(e);
            }}
            onKeyPress={(e) => {
              onKeyPress && onKeyPress(e);
            }}
            {...field_value}
          />
        </FloatingLabel>
      </div>
    </>
  );
};

export default memo(MobileNumber);
