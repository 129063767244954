export const MEDWIKI_FEATURED = 'MEDWIKI_FEATURED';
export const MEDWIKI_DETAILS = 'MEDWIKI_DETAILS';
export const MEDWIKI_SPECIALITIES = 'MEDWIKI_SPECIALITIES';
export const MEDWIKI_TOP_POST = 'MEDWIKI_TOP_POST';
export const MEDWIKI_TOP_POST_INFINITYQUERY = 'MEDWIKI_TOP_POST_INFINITYQUERY';
export const MEDWIKI_LINKED_CONTENT = 'MEDWIKI_LINKED_CONTENT';
export const MEDWIKI_TOP_RATED = 'MEDWIKI_TOP_RATED';
export const MEDWIKI_TOP_COMMENTS = 'MEDWIKI_TOP_COMMENTS';
export const MEDWIKI_TOP_RATED_POST_INFINITYQUERY = 'MEDWIKI_TOP_RATED_POST_INFINITYQUERY';
export const MEDWIKI_TOP_COMMENTS_POST_INFINITYQUERY = 'MEDWIKI_TOP_COMMENTS_POST_INFINITYQUERY';
export const MEDWIKI_POPULAR_POST_INFINITYQUERY = 'MEDWIKI_POPULAR_POST_INFINITYQUERY';
export const MEDWIKI_NEW_TOP_POST_INFINITYQUERY = 'MEDWIKI_NEW_TOP_POST_INFINITYQUERY';
