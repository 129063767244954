import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import InView from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import checkForPremiumIcon from '../../../common/checkForPremiumIcon';
import { reactHtmlParser } from '../../../common/common';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';
import ModalDoctorVoice from '../../../pages/dashboard/components/ModalDoctorVoice';
import { safeJsonParser } from '../../../utils/common';
import useShare from '../../customHooks/useShare';
import { useTrackUserActivity } from '../../customHooks/useTrackUserActivity';
import { SHARE_CLICK, SUBMIT_CLICK } from '../../tracking/constants';
import ReportContentPopover from '../cards/components/ReportContentPopover';
import TooltipContainer from '../components/TooltipContainer';
import ContentCard from '../contentBasedCard/components/ContentCard';

function DoctorVoiceCard({
  type = 'poll',
  className = '',
  data = {},
  onClickPoll,
  icon = null,
  isShare = true
}) {
  const { trackActivityEvent } = useTracker();
  const userDetails = useSelector((state) => state.common.user_details);
  const { contentAccessRedirect } = useContentAccessRedirect();
  const doctorVoiceCardOptionRef = useRef(null);
  const doctorVoiceCardRef = useRef(null);
  const [isReported, setIsReported] = useState(false);
  const [isYespoll, setIsyespoll] = useState(false);
  const [widths, setWidths] = useState([]);
  const [sponserLogo, setSponserLogo] = useState([]);
  const [options, setOptions] = useState([]);
  const [question, setQuestion] = useState('');
  const [answerGiven, setAnserGiven] = useState(false);
  const [currOptions, setCurrOptions] = useState(null);
  const [show, setShow] = useState(false);
  const [toOpenModal, setToOpenModal] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const share_url = useShare();
  const refs = useRef([]);
  const modifiedData = useRef([]);
  const submitPollController = useRef(true);
  const surveyIdRef = useRef('');
  const sponsor_logo = data.sponsor_logo ? data.sponsor_logo.split(',') : [];

  console.log('kjabdifbadshf', data);
  // const is_locked = true;
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      surveyIdRef.current = data?.survey_id;
      const jsonData = data?.json_data || data?.data_json;
      let extractData =
        jsonData && typeof jsonData == 'string'
          ? safeJsonParser(jsonData, {})
          : jsonData && typeof data == 'object'
          ? jsonData
          : [];

      let getOptions =
        extractData && extractData?.surveys
          ? Object.keys(extractData?.surveys).map((i) => {
              return extractData?.surveys[i];
            })
          : [];
      console.log('extractData', extractData);
      modifiedData.current = getOptions.length != 0 ? getOptions : [];
      setQuestion(getOptions.length != 0 ? getOptions[0]?.question : '');
      let optionsList =
        getOptions.length != 0
          ? Object.keys(getOptions[0]?.options)?.map((i) => getOptions[0]?.options[i])
          : [];
      if (getOptions[0]?.options.length > 2) {
        setIsyespoll(true);
      } else {
        optionsList.map((_op) => {
          if (_op?.label?.length > 10) {
            setIsyespoll(true);
          }
        });
      }
      setOptions(optionsList);
      if (data?.sponsor_logo) {
        setSponserLogo(data?.sponsor_logo.split(','));
      }
    }
  }, [data]);

  const onPollSubmit = (selectedIndex, index) => {
    // console.log("onPollSubmit",show)
    // if (checkForContentAccess(data?.is_locked)) {
    //   contentAccessRedirect(
    //     '',
    //     data.type_id,
    //     data.question,
    //     data?.is_locked,
    //     data.price,
    //     data.user_content_payment
    //   );
    // } else {
    console.log('truncated', truncated);
    onClickPoll && onClickPoll();
    setAnserGiven(true);
    if (!answerGiven && !truncated) {
      if (submitPollController.current) {
        submitPollController.current = false;
        modifiedData.current[0].selectedIndex = selectedIndex;

        axiosInstance
          .post('survey/pollAnswer', {
            answer_json: JSON.stringify(modifiedData.current),
            survey_id: surveyIdRef.current
          })
          .then((res) => {
            axiosInstance
              .get(`survey/pollResult?id=${surveyIdRef.current}`)
              .then((response) => {
                setCurrOptions(safeJsonParser(response.data.data, [])[0]);
                console.log(
                  'new response',
                  safeJsonParser(response.data.data, [])[0],
                  safeJsonParser(response.data.data, [])[0].options[0].vote
                );
                axiosInstance
                  .post('survey/addpoint', {
                    point: data.point,
                    survey_id: surveyIdRef.current
                  })
                  .then((resp) => {
                    if (parseInt(data.point) > 0) {
                      toast.success(`You have got ${data.point} points`);
                    } else {
                      toast.success(`Thank you for your response`);
                    }
                  })
                  .catch((e) => {
                    console.log('Error', e);
                  });
              })
              .catch((e) => {
                console.log('Error', e);
              });
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }
    }
    // }
  };

  const onReportSubmit = () => {
    trackActivityEvent(SUBMIT_CLICK, data);
    setIsReported(true);
  };

  const handleClose = useCallback(() => {
    setShow(false);
  }, [show, currOptions, answerGiven]);

  const triggerModalClose = (currOptions) => {
    setAnserGiven(true);
    setCurrOptions(currOptions);
  };

  useEffect(() => {
    console.log('options', options);
    if (options?.length > 2) {
      for (const _opt of options) {
        const prntWidth = document.querySelector(`#dbVoiceLabel_${_opt.value}`)?.clientWidth ?? 0;
        var textWidth = getTextWidth(_opt.label, null);

        if (prntWidth <= textWidth) {
          setToOpenModal(true);
          break;
        }
      }
    }
  }, []);

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
  };

  const { userActivityTracker } = useTrackUserActivity();
  const displayIcon = checkForPremiumIcon(data?.is_locked);

  return (
    <ContentCard
      data={data}
      isLocked={displayIcon}
      className={`clr_v3_card--doctorVoice ${className ?? ''}`}
    >
      {displayIcon ? <ContentCard.PremiumTag></ContentCard.PremiumTag> : <></>}
      {!isReported && data && Object.keys(data)?.length > 0 && (
        <ContentCard.Container>
          <ContentCard.Header>
            {/* {contentType && ( */}
            <ContentCard.Tag
              options={{
                iconName: `${icon}`
              }}
            >
              {type == 'poll' ? 'Poll' : "Doctor's Voice"}
            </ContentCard.Tag>

            {/* )} */}

            <ContentCard.Right className="ms-auto align-items-center d-flex  gap-sm-3 gap-2">
              {
                (data && data?.survey_title,
                data?.deeplink && isShare ? (
                  <TooltipContainer text="Share">
                    <span
                      className="icon-size-32 icon-size-sm-36 clr_v3_card__icon transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative fs-2 clr_v3_icon-btn-1"
                      onClick={() => {
                        trackActivityEvent(SHARE_CLICK, data);
                        share_url(data?.survey_title, data?.deeplink);
                      }}
                    >
                      <i className={`flaticon-share-arrow`}></i>
                    </span>
                  </TooltipContainer>
                ) : (
                  <></>
                ))
              }
              {data && (
                <>
                  {/* <i className="flaticon-more-1 fs-1"></i> */}
                  <ReportContentPopover
                    type={data.category}
                    id={data?.survey_id}
                    onReportSubmit={onReportSubmit}
                    isDoctorVoiceCard={true}
                    data={data}
                  />
                </>
              )}
            </ContentCard.Right>
          </ContentCard.Header>

          <ContentCard.Body
            className="flex-grow-1 d-flex justify-content-center flex-column"
            ref={doctorVoiceCardRef}
          >
            {console.log('questionquestion', question)}
            <ContentCard.Content>
              {question != '' && (
                <ContentCard.Title className="d-flex hover-opacity cursorPointer">
                  {/* <ShowMoreText
                    anchorClass={'text-white'}
                    lines={3}
                    expandByClick={true}
                    more={'...'}
                    less={''}
                    truncatedEndingComponent=""
                    width={doctorVoiceCardRef.current?.clientWidth ?? 0}
                  > */}
                  {reactHtmlParser(question)}
                  {/* </ShowMoreText> */}
                </ContentCard.Title>
              )}
            </ContentCard.Content>

            <InView
              as="div"
              // onChange={(inView) => {
              //   userActivityTracker(
              //     inView,
              //     { ...data, type: 'doctorvoice', type_id: data.survey_id },
              //     'card'
              //   );
              // }}
            >
              <Form
                className={`d-flex ${
                  isYespoll ? 'flex-column text-start clr_v3_polls--full' : ''
                } gap-2 gap-sm-3`}
              >
                {options?.map((_i, idx) => (
                  <div className="flex-grow-1" key={idx}>
                    <div
                      className={`clr_v3_polls position-relative overflow-hidden ${
                        answerGiven ? 'clr_v3_polls--active' : 'hover-opacity'
                      }`}
                      onClick={() => {
                        trackActivityEvent(SUBMIT_CLICK, data);
                        onPollSubmit(_i?.value, idx);
                      }}
                    >
                      {/* <input
                        className="position-absolute start-0 top-0 w-100 h-100 z-3 opacity-0 clr_v3_polls__input"
                        type="radio"
                        value=""
                        id="answer"
                        name="doctorVoice"
                        disabled={answerGiven}
                      /> */}
                      <label
                        className={`clr_v3_polls__label transition w-100 border border-1 border-soft-primary overflow-hidden rounded-3 d-flex ${
                          !answerGiven ? 'cursorPointer' : ''
                        }`}
                        htmlFor="answer"
                        ref={doctorVoiceCardOptionRef}
                      >
                        <div
                          className={`clr_v3_polls__label__content d-flex position-relative transition flex-grow-1`}
                          // ${answerGiven && 'px-3'}
                          style={!isYespoll ? { width: widths[idx]?.offsetWidth } : {}}
                        >
                          {_i?.label && (
                            <span
                              className={`transition ${
                                !isYespoll ? 'position-relative translate-middle-x ' : ''
                              }
                               ${!isYespoll && answerGiven ? 'start-0' : 'start-50'}
                               `}
                              // className="d-inline-block  position-relative w-100"
                              id={`dbVoiceLabel_${_i.value}`}
                            >
                              {
                                !isYespoll
                                  ? _i.label
                                  : // <ShowMoreText
                                    //   className="fs-4 text-black fw-normal"
                                    //   anchorClass={'show-more-less-clickable'}
                                    //   width={
                                    //     doctorVoiceCardOptionRef.current?.clientWidth
                                    //       ? doctorVoiceCardOptionRef.current?.clientWidth - 15
                                    //       : 0
                                    //   }
                                    //   lines={1}
                                    //   expandByClick={false}
                                    //   onClick={handleShow}
                                    //   more={''}
                                    //   less={''}
                                    //   // truncatedEndingComponent=""
                                    //   onTruncate={() => {
                                    //     if (!truncated) {
                                    //       console.log('truncating');
                                    //       setTruncated(true);
                                    //     }
                                    //   }}
                                    // >
                                    _i.label
                                // </ShowMoreText>
                              }
                            </span>
                          )}
                          {/* {answerGiven && ( */}
                          <span
                            className={`position-absolute clr_v3_polls__percent pe-3 transition ${
                              answerGiven ? 'opacity-1' : 'opacity-0'
                            }`}
                          >
                            {answerGiven
                              ? `${currOptions ? currOptions?.options[idx]?.vote : 0}`
                              : '...'}
                            %{/* {currOptions?.options[idx]?.vote}% */}
                          </span>
                          {/* )}  */}
                        </div>

                        <div
                          className={`progressBarContainer transition w-100 px-3 start-0 position-absolute ${
                            answerGiven ? 'opacity-1' : 'opacity-0'
                          }`}
                        >
                          <ProgressBar
                            variant="primary"
                            now={currOptions ? currOptions?.options[idx]?.vote : 0}
                            className="transition"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                ))}
              </Form>
            </InView>

            {((!!data?.user_participant_count && data?.user_participant_count != 0) ||
              sponserLogo?.length > 0) && (
              <ContentCard.Footer>
                {data && data.user_participant_count && data.user_participant_count != 0 && (
                  <div className="me-auto d-flex align-items-center flex-grow-1">
                    <span
                      title="Participants"
                      className="clr_v3_card__icon text-dark py-1 d-inline-flex align-items-center justify-content-center"
                    >
                      <i className="flaticon-user me-2 fs-2"></i>
                      <span>{data.user_participant_count}</span>
                    </span>
                  </div>
                )}

                {sponserLogo && sponserLogo?.length > 0 && (
                  <ContentCard.Sponser sponsorLogo={sponsor_logo}></ContentCard.Sponser>
                )}
              </ContentCard.Footer>
            )}
          </ContentCard.Body>
          {data && Object.keys(data)?.length > 0 && show && (
            <ModalDoctorVoice
              show={show}
              data={{ ...data, json_data: data?.json_data || data?.data_json }}
              handleClose={handleClose}
              triggerModalClose={(currOptions) => triggerModalClose(currOptions)}
              headingText="Poll"
            />
          )}
        </ContentCard.Container>
      )}
    </ContentCard>
  );
}

export default memo(DoctorVoiceCard);
