import React, { memo } from 'react';
import ContentCard from '../../contentBasedCard/components/ContentCard';
// import ReportContentPopover from '../../cards/components/ReportContentPopover';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Badge } from 'react-bootstrap';
import { default_images } from '../../../../common/defaultImages';
import { routeNames } from '../../../../router/constants';
import LazyImage from '../../../CustomLibraries/LazyImage';
import useRedirect from '../../../customHooks/useRedirect';
import TooltipContainer from '../../components/TooltipContainer';
import { isMobile } from 'react-device-detect';
import useShare from '../../../customHooks/useShare';
import getNextAvailableDatetime from '../../../../pages/uiv3/observership/utils/getNextAvailableDatetime';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';
import { CARD_CLICK, SHARE_CLICK } from '../../../tracking/constants';
import { onCardClick } from '../../../tracking/UserActions';
import { safeJsonParser } from '../../../../utils/common';

const MentorCard = (prop) => {
  const {
    type = '',
    cardType = '',
    name: mentorName,
    middle_name,
    first_name,
    last_name,
    country_name,
    city_name,
    country_code,
    state_name,
    profile_image,
    specialities_name,
    next_available_on: nextAvailableOn,
    practicing,
    sessions_doctors_id,
    mentor_id,
    deeplink,
    className = '',
    isDisabled = false,
    availability,
    timezone,
    is_share = true
  } = prop;

  const next_available_on = nextAvailableOn
    ? nextAvailableOn
    : availability
    ? getNextAvailableDatetime(safeJsonParser(availability, []), timezone || '')
    : null;
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(customParseFormat);
  const share_url = useShare();
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();
  const years_of_experience = practicing ? dayjs().diff(dayjs(practicing), 'year') : null;
  const cardOptions = {
    paddingTop: cardType === 'small' ? '2' : '4'
  };

  const name = mentorName
    ? 'Dr. ' + mentorName
    : 'Dr. ' +
      first_name?.toUpperCase() +
      ' ' +
      middle_name?.toUpperCase() +
      ' ' +
      last_name?.toUpperCase();
  const isShareable = typeof is_share === 'boolean' ? is_share : is_share == '1' ? true : false;

  return (
    <ContentCard type={cardType} data={prop} className={`clr_v3_card--mentor ${className}`}>
      {/* {featured && <ContentCard.FeaturedTag></ContentCard.FeaturedTag>} */}

      <ContentCard.Container
        options={{
          paddingTop: cardType === 'small' ? { default: '2', md: '3' } : { default: '3', md: '4' },
          // paddingTop: { default: '2', md: '4' },
          gap: cardType === 'small' ? { default: '2', md: '2' } : { default: '2', md: '3' }
        }}
      >
        {cardType === 'small' && (
          <ContentCard.Header>
            <div className="d-flex align-items-center gap-4">
              <ContentCard.Tag
                options={{
                  iconSize: '5',
                  fontSize: '5',
                  iconName: `flaticon-pin`,
                  color: 'black'
                }}
              >
                {state_name ? `${state_name}, ` : null}
                {country_code ? `${country_code}` : country_name ? `${country_name}` : null}
              </ContentCard.Tag>
            </div>
            <ContentCard.Right>
              {deeplink && isShareable && (
                <TooltipContainer text="Share">
                  <span
                    className="icon-size-32 clr_v3_card__icon transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative fs-3 clr_v3_icon-btn-1"
                    onClick={() => {
                      trackActivityEvent(SHARE_CLICK, prop);
                      share_url(name, deeplink);
                    }}
                  >
                    <i className="flaticon-share-arrow"></i>
                  </span>
                </TooltipContainer>
              )}

              {/* <ReportContentPopover /> */}
              {/**
               * temp deleted
               */}
            </ContentCard.Right>
          </ContentCard.Header>
        )}
        <ContentCard.Body
          className="align-items-start"
          options={{ flexDirection: 'row', columnGap: { default: '2', md: '3' } }}
        >
          <div className="pe-3 pb-3 position-relative">
            <ContentCard.Image
              onClick={() => {
                if (!isDisabled) {
                  trackActivityEvent(CARD_CLICK, prop);
                  redirectTo(
                    routeNames.observership.profile,
                    sessions_doctors_id || mentor_id,
                    name
                  );
                }
              }}
            >
              {!profile_image || typeof profile_image === 'string' ? (
                <LazyImage
                  src={profile_image || default_images.mentor}
                  alt="profile"
                  fallback_image={default_images.mentor}
                  className={`object-fit-cover w-100 h-100 transition`}
                  extraClass="w-100 h-100 position-relative hover-image"
                />
              ) : (
                <div className="w-100 h-100 position-relative hover-image">
                  <img
                    src={URL.createObjectURL(profile_image)}
                    alt="profile"
                    className={`object-fit-cover w-100 h-100 transition`}
                  />
                </div>
              )}
            </ContentCard.Image>
            <Badge className="position-absolute fw-normal fs-5 border border-white bottom-0 end-0 ">
              Mentor
            </Badge>
          </div>

          <div className="d-flex flex-column flex-grow-1 ">
            {cardType !== 'small' && (
              <ContentCard.Header>
                <ContentCard.Title
                  className="cursorPointer"
                  onClick={() => {
                    if (!isDisabled) {
                      trackActivityEvent(CARD_CLICK, prop);
                      redirectTo(
                        routeNames.observership.profile,
                        sessions_doctors_id || mentor_id,
                        name
                      );
                    }
                  }}
                >
                  {name}
                </ContentCard.Title>

                <ContentCard.Right>
                  {deeplink && is_share && is_share != '0' && (
                    <TooltipContainer text="Share">
                      <span
                        className="icon-size-32 clr_v3_card__icon transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative fs-3 clr_v3_icon-btn-1"
                        onClick={() => {
                          trackActivityEvent(SHARE_CLICK, prop);
                          share_url(name, deeplink);
                        }}
                      >
                        <i className="flaticon-share-arrow"></i>
                      </span>
                    </TooltipContainer>
                  )}

                  {/* <ReportContentPopover /> */}
                  {/**
                   * temp deleted
                   */}
                </ContentCard.Right>
              </ContentCard.Header>
            )}

            <div className="d-flex flex-row gap-4 align-items-end justify-content-between">
              <ContentCard.Content
                onClick={() => {
                  if (!isDisabled) {
                    trackActivityEvent(CARD_CLICK, prop);
                    redirectTo(
                      routeNames.observership.profile,
                      sessions_doctors_id || mentor_id,
                      name
                    );
                  }
                }}
                options={{
                  gap: cardType === 'small' ? { default: '1', md: '1' } : { default: '1', md: '2' }
                }}
              >
                {cardType === 'small' && (
                  <ContentCard.Title
                    onClick={() => {
                      if (!isDisabled) {
                        trackActivityEvent(CARD_CLICK, prop);
                        redirectTo(
                          routeNames.observership.profile,
                          sessions_doctors_id || mentor_id,
                          name
                        );
                      }
                    }}
                  >
                    {name}
                  </ContentCard.Title>
                )}

                <h5 className="fs-5 text-primary fw-normal line-clamp-1">{specialities_name}</h5>
                <div
                  className={`d-flex gap-3 align-items-center   ${
                    cardType === 'small' ? '' : 'mb-2'
                  }`}
                  onClick={() => {
                    if (!isDisabled) {
                      trackActivityEvent(CARD_CLICK, prop);
                      redirectTo(
                        routeNames.observership.profile,
                        sessions_doctors_id || mentor_id,
                        name
                      );
                    }
                  }}
                >
                  {years_of_experience && (
                    <h5 className="fs-5 d-flex align-items-center gap-2 text-black fw-normal flex-shrink-0">
                      <i className="flaticon-star-1"></i>{' '}
                      <span className="line-clamp-1">{years_of_experience} years experience</span>
                    </h5>
                  )}
                  {cardType !== 'small' && (
                    <>
                      <span className="fs-5 d-flex align-items-center gap-2 text-black">
                        <i className="flaticon-pin"></i>
                        <span className="line-clamp-1">
                          {state_name ? `${state_name},` : null}

                          {country_code
                            ? ` ${country_code}`
                            : country_name
                            ? ` ${country_name}`
                            : null}
                        </span>
                      </span>
                    </>
                  )}
                </div>
                {next_available_on && dayjs(next_available_on?.from).isValid() && (
                  <h5
                    className={`${
                      cardType === 'small' || isMobile ? 'fs-5' : 'fs-4'
                    } text-dark fw-medium`}
                    onClick={() => {
                      if (!isDisabled) {
                        trackActivityEvent(CARD_CLICK, prop);
                        redirectTo(
                          routeNames.observership.profile,
                          sessions_doctors_id || mentor_id,
                          name
                        );
                      }
                    }}
                  >
                    Next available on{' '}
                    <span className="text-primary">
                      {dayjs(next_available_on?.from).format('DD MMM YY, hh:mm A')}
                    </span>
                  </h5>
                )}
              </ContentCard.Content>

              {cardType !== 'small' && !isMobile && (
                <ContentCard.Button
                  className="py-2 text-primary px-4 fw-medium text-nowrap flex-shrink-0"
                  options={{ variant: 'outline-light' }}
                  onClick={() => {
                    if (!isDisabled) {
                      trackActivityEvent(CARD_CLICK, prop);
                      redirectTo(
                        routeNames.observership.profile,
                        sessions_doctors_id || mentor_id,
                        name
                      );
                    }
                  }}
                >
                  View Details
                </ContentCard.Button>
              )}
            </div>
          </div>
        </ContentCard.Body>
      </ContentCard.Container>
    </ContentCard>
  );
};

export default memo(MentorCard);
