import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getLocalStorage } from '../../common/common';
import axiosInstance from '../../Store/helper/axiosInstance';
import axiosOpenInstance from '../../Store/helper/axiosOpenInstance';
import {
  AIDA_DDX_SHARE_BY_ID_KEY,
  AIDA_DDX_SHARE_KEY,
  AIDA_HISTORY_LIST_KEY,
  AIDA_IMAGE_TO_TEXT
} from '../QueryKeys/aida.key';

const staleTime = 300000;
const cacheTime = 600000;

const url = process.env.REACT_APP_API_URL;

export const getGenerateDDXApi = () => {
  return `${url}ddx_generate_stream`;
};

export const getDDXRatingApi = () => {
  return `${url}ddx_rating_stream`;
};

export const useGetAidaHistoryList = (offset) => {
  const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
  return useQuery(
    [`${AIDA_HISTORY_LIST_KEY}_${userId}_${offset}`],
    () =>
      axiosInstance.post(`ddx-history`, {
        user_id: userId,
        limit: 100,
        offset: offset
      }),
    {
      select: (response) => {
        const data = {};

        if (response.data.output.length > 0) {
          response.data.output.forEach((element) => {
            const added_on = dayjs(element?.added_on).format('DD-MM-YYYY');
            console.log('added_on', element);
            if (added_on in data) {
              data[added_on] = [...data[added_on], element];
            } else {
              data[added_on] = [element];
            }
            console.log('mdkfbiDSBfhk', data);
          });
        }

        return data;
      },
      staleTime: 0,
      cacheTime: 0,
      enabled: !!userId,
      refetchOnWindowFocus: false
    }
  );
};

export const useGetAidaSuggestionResult = (data, onSuccess) => {
  const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
  const payload = {
    ...data,
    user_id: userId
  };

  return useQuery([JSON.stringify(payload)], () => axiosInstance.post(`ddx_suggestion`, payload), {
    select: (response) => response?.data?.output?.answers_list,
    staleTime: 0,
    cacheTime: 0,
    enabled: !!payload.user_question && !!payload.user_id,
    refetchOnWindowFocus: false,
    onSuccess
  });
};

export const useGetLinkShareDDX = (ddxId) => {
  const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
  return useQuery(
    [`${AIDA_DDX_SHARE_KEY}_${userId}_${ddxId}`],
    () =>
      axiosInstance.post(`share_ddx`, {
        user_id: userId,
        ddx_id: ddxId
      }),
    {
      select: (response) => response.data.output,
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!ddxId && !!userId,
      refetchOnWindowFocus: false
    }
  );
};

export const useGetImageToText = (imgURL, onSuccess) => {
  const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
  return useQuery(
    [`${AIDA_IMAGE_TO_TEXT}_${imgURL}`],
    () =>
      axiosInstance.post(`image_to_text`, {
        url: imgURL
      }),
    {
      select: (response) => response.data.output,
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!imgURL,
      refetchOnWindowFocus: false,
      onSuccess
    }
  );
};

export const useGetDDXById = (ddxId, onError) => {
  const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
  return useQuery(
    [`${AIDA_DDX_SHARE_BY_ID_KEY}_${userId}_${ddxId}`],
    () =>
      axiosOpenInstance.post(`ddx_by_id?type=ddx`, {
        ddx_id: ddxId
      }),
    {
      select: (response) => response.data.output[0],
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!ddxId,
      onError,
      refetchOnWindowFocus: false
    }
  );
};
