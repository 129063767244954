import { useInfiniteQuery, useQuery, useQueryClient, useMutation } from 'react-query';
import { generateUtmExt, getLocalStorage, isMobileApp, setLocalStorage } from '../../common/common';
import axiosInstance from '../../Store/helper/axiosInstance';
import dayjs from 'dayjs';
import {
  Fetch_Running_Election,
  FETCH_POPULAR_POST,
  USER_SUGGESTION_CHANNEL,
  USER_CHANNEL_LIST,
  USER_CHANNEL_MANAGE_LIST,
  USER_SUGGESTION_MANAGE_LIST,
  GET_CHANNEL_DETAILS,
  GET_ALL_CHANNEL_DATA,
  MOST_VIEWED_VIDEO,
  RECENT_CME,
  TRENDING_SPQ,
  GET_FEATURED_CHANNEL,
  TRENDING_EBOOK,
  MOST_FOLLOWED_CHANNEL,
  TOP_RATED_MEDWIKI,
  FOLLOWED_COUNT,
  CHANNEL_ACTIVITY_COUNT
} from '../QueryKeys/channel.key';
import { useDispatch } from 'react-redux';
import { renderHeaderOnce } from '../../Store/actions';
import { toast } from 'react-toastify';

const staleTime = 300000;
const cacheTime = 600000;
export const useFetchRunningelection = () => {
  return useQuery(
    Fetch_Running_Election,
    () => axiosInstance.get(`election/listing?date=${dayjs().add(30, 'day').format('YYYY-MM-DD')}`),
    { refetchOnWindowFocus: false, cacheTime, select: (res) => res.data.data }
  );
};

export const useFetchPopularPost = () => {
  return useQuery(FETCH_POPULAR_POST, () => axiosInstance.get(`Channelnew/popular?from=0&to=6`), {
    staleTime,
    cacheTime,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data
  });
};

export const useUserSuggestionChannel = (enabled = true) => {
  return useQuery(
    USER_SUGGESTION_CHANNEL,
    () => axiosInstance.get(`Channelnew/suggestionList?from=0&to=10`),
    {
      cacheTime,
      enabled,
      refetchOnWindowFocus: false,
      // select: (res) => res.data.data
      select: (res) => ({
        data: res.data.data,
        statusCode: res.status
      })
    }
  );
};

export const useUserChannelList = () => {
  return useQuery(
    USER_CHANNEL_LIST,
    () => axiosInstance.get(`Channelnew/userChannel?from=0&to=10`),
    {
      cacheTime,
      // select: (res) => res.data.data,
      select: (res) => ({
        data: res.data.data,
        statusCode: res.status
      }),
      refetchOnWindowFocus: false
    }
  );
};

export const useUserChannelManageList = () => {
  return useInfiniteQuery(
    USER_CHANNEL_MANAGE_LIST,
    ({ pageParam = 0 }) => axiosInstance.get(`Channelnew/userChannel?from=${pageParam}&to=10`),
    {
      refetchOnWindowFocus: false,

      cacheTime: cacheTime,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length == 10) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useUserSuggestionChannelList = () => {
  return useInfiniteQuery(
    USER_SUGGESTION_MANAGE_LIST,
    ({ pageParam = 0 }) => axiosInstance.get(`Channelnew/suggestionList?from=${pageParam}&to=10`),
    {
      refetchOnWindowFocus: false,

      cacheTime: cacheTime,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length == 10) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useGetChannelDetails = (id, onSuccess, enebled) => {
  const queryClient = useQueryClient();
  return useQuery(
    [GET_CHANNEL_DETAILS, id],
    () => axiosInstance.get(`Channelnew/detail?type_id=${id}${generateUtmExt()}&type=channel`),
    {
      cacheTime,
      enabled: enebled,
      refetchOnWindowFocus: false,
      onSuccess,
      select: (res) => ({
        data:
          res?.data?.data?.length > 0
            ? {
                ...res.data.data[0],
                followed_status: res.data.data[0]?.followed_status ?? '0'
              }
            : [
                {
                  type_id: id,
                  followed_status: '0'
                }
              ],
        statusCode: res.status
      }),
      initialData: () => {
        const userChannel = queryClient
          .getQueryData(USER_CHANNEL_LIST)
          ?.data?.data?.find((e) => e.type_id == id);
        const suggChannel = queryClient
          .getQueryData(USER_SUGGESTION_CHANNEL)
          ?.data?.data?.find((e) => e.type_id == id);
        const channel = userChannel || suggChannel;
        if (channel) {
          return { data: { data: [channel] } };
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useGetAllChannelData = (id, onSuccess) => {
  return useQuery(
    [GET_ALL_CHANNEL_DATA, id],
    () =>
      axiosInstance.get(
        `Channelnew/all?type_id=${id}&from=0&to=600${isMobileApp() ? '&convert=1' : ''}`
      ),
    { cacheTime, staleTime, onSuccess, select: (res) => res.data.data, refetchOnWindowFocus: false }
  );
};

const saveLike = (data) => {
  return axiosInstance.post('knwlg/save_like', data);
};

export const useAddLike = (type, type_id) => {
  const queryClient = useQueryClient();

  return useMutation(saveLike, {
    onMutate: async (obj) => {
      console.log('RQ MUTATION SAVELIKE', obj);
      const queryKeysArr = [GET_CHANNEL_DETAILS, type_id];
      // await queryClient.cancelQueries([CL_VIDEO_DETAILS, type_id]);
      const previousData = queryClient.getQueryData(queryKeysArr);

      queryClient.setQueryData(queryKeysArr, (oldQueryData) => {
        //   console.log('PREVIOUS-oldQueryData======>', oldQueryData.data.data[0].featured_video[0]);
        let temp_data = oldQueryData;
        temp_data.data.data[0].featured_video[0].myrating
          ? (temp_data.data.data[0].featured_video[0].rating -= 1)
          : (temp_data.data.data[0].featured_video[0].rating += 1);
        temp_data.data.data[0].featured_video[0].myrating =
          !temp_data.data.data[0].featured_video[0].myrating;
        return temp_data;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      // queryClient.setQueryData([CL_VIDEO_DETAILS, type_id], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_CHANNEL_DETAILS, type_id]);
    }
  });
};

const saveVault = (data) => {
  return axiosInstance.post('knwlg/vault_switching', data);
};

export const useAddVault = (type, type_id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(saveVault, {
    onMutate: async (obj) => {
      console.log('RQ MUTATION ADDVAULT', obj);
      const queryKeysArr = [GET_CHANNEL_DETAILS, type_id];
      const previousData = queryClient.getQueryData(queryKeysArr);

      queryClient.setQueryData(queryKeysArr, (oldQueryData) => {
        let temp_data = oldQueryData;
        if (temp_data.data.data[0].featured_video[0].vault == 0) {
          temp_data.data.data[0].featured_video[0].vault = 1;
        } else {
          temp_data.data.data[0].featured_video[0].vault = 0;
        }
        return temp_data;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      // queryClient.setQueryData([CL_VIDEO_DETAILS, type_id], context.previousData);
    },
    onSettled: () => {
      // queryClient.invalidateQueries([Ebooks_Details, type_id]);
    },
    onSuccess: (resp) => {
      const res = resp.data;
      let vaultCount = getLocalStorage('vaultCount', false)
        ? parseInt(getLocalStorage('vaultCount', 0))
        : 0;
      if (res.data == 0) {
        setLocalStorage('vaultCount', vaultCount - 1);
      } else {
        setLocalStorage('vaultCount', vaultCount + 1);
      }
      dispatch(renderHeaderOnce());
    }
  });
};
const postFollowReq = (data) => {
  return axiosInstance.post('Channelnew/follow_switching', data);
};

export const useFollowSwitch = (type_id, onSuccess) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(postFollowReq, {
    onMutate: async (obj) => {
      console.log('RQ MUTATION FOLLOW', obj);
      const queryKeysArr = [GET_CHANNEL_DETAILS, type_id];
      // await queryClient.cancelQueries([CL_VIDEO_DETAILS, type_id]);
      const previousData = queryClient.getQueryData(queryKeysArr);

      queryClient.setQueryData(queryKeysArr, (oldQueryData) => {
        let temp_data = oldQueryData;
        let isPublic =
          temp_data.data.data[0].privacy_status == 0 || temp_data.data.data[0].privacy_status == 2;

        let isUnfollowed = temp_data.data.data[0].followed_status == 0;
        let followerCount = temp_data.data.data[0].follower_count
          ? parseInt(temp_data.data.data[0].follower_count)
          : 0;
        if (isPublic) {
          if (isUnfollowed) {
            temp_data.data.data[0].followed_status = '3';
            temp_data.data.data[0].follower_count = followerCount + 1;
          } else {
            temp_data.data.data[0].followed_status = '0';
            temp_data.data.data[0].follower_count = followerCount - 1;
            console.log('temp data here:', temp_data);
          }
        } else {
          if (isUnfollowed) {
            temp_data.data.data[0].followed_status = '1';
          } else {
            // toast.warn('You have unfollowed the channel');
            temp_data.data.data[0].followed_status = '0';
            temp_data.data.data[0].follower_count = followerCount - 1;
          }
        }
        return temp_data;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      // queryClient.setQueryData([CL_VIDEO_DETAILS, type_id], context.previousData);
    },
    onSettled: () => {
      // queryClient.invalidateQueries([GET_CHANNEL_DETAILS, type_id]);
    },
    onSuccess: onSuccess
  });
};
//mostviewedvideo
export const useMostViewedVideo = (inView = true) => {
  return useQuery(MOST_VIEWED_VIDEO, () => axiosInstance.get(`Channelnew/mostviewedvideo`), {
    cacheTime,
    staleTime,
    enabled: inView,
    // select: (res) => res.data.data,
    select: (res) => ({
      data: res.data.data,
      statusCode: res.status
    }),
    refetchOnWindowFocus: false
  });
};
export const useRecentCme = (enabled = true) => {
  return useQuery(RECENT_CME, () => axiosInstance.get(`Channelnew/upcomingsessions`), {
    cacheTime,
    staleTime,
    enabled,
    // select: (res) => res.data.data,
    select: (res) => ({
      data: res.data.data,
      statusCode: res.status
    }),
    refetchOnWindowFocus: false
  });
};
export const useTrendingSpq = (enabled = true) => {
  return useQuery(TRENDING_SPQ, () => axiosInstance.get(`Channelnew/trendingspq`), {
    cacheTime,
    staleTime,
    enabled,
    // select: (res) => res.data.data,
    select: (res) => ({
      data: res.data.data,
      statusCode: res.status
    }),
    refetchOnWindowFocus: false
  });
};
export const useTrendingEbook = (enabled = true) => {
  return useQuery(TRENDING_EBOOK, () => axiosInstance.get(`Channelnew/trendingebook`), {
    cacheTime,
    staleTime,
    enabled,
    // select: (res) => res.data.data,
    select: (res) => ({
      data: res.data.data,
      statusCode: res.status
    }),
    refetchOnWindowFocus: false
  });
};
export const useFeaturedChannel = () => {
  return useQuery(
    GET_FEATURED_CHANNEL,
    () => axiosInstance.get(`Channelnew/featured?limitTo=5&limitFrom=0`),
    {
      cacheTime,
      // select: (res) => res.data.data,
      select: (res) => ({
        data: res.data.data,
        statusCode: res.status
      }),
      refetchOnWindowFocus: false
    }
  );
};
export const useMostFollowedChannel = (enabled = true) => {
  return useQuery(
    MOST_FOLLOWED_CHANNEL,
    () => axiosInstance.get(`Channelnew/mostfollowedchannel?limitTo=20&limitFrom=0`),
    {
      cacheTime,
      staleTime,
      enabled,
      // select: (res) => res.data.data,
      select: (res) => ({
        data: res.data.data,
        statusCode: res.status
      }),
      refetchOnWindowFocus: false
    }
  );
};
export const useTopRatedMedwiki = (enabled = true) => {
  return useQuery(
    TOP_RATED_MEDWIKI,
    () => axiosInstance.get(`Channelnew/topratedmedwiki?limitTo=5&limitFrom=0`),
    {
      cacheTime,
      staleTime,
      enabled,
      // select: (res) => res.data.data,
      select: (res) => ({
        data: res.data.data,
        statusCode: res.status
      }),
      refetchOnWindowFocus: false
    }
  );
};
export const useFollowedCount = (enabled = true) => {
  return useQuery(FOLLOWED_COUNT, () => axiosInstance.get(`Channelnew/userChannelCount`), {
    cacheTime,
    staleTime,
    enabled,
    select: (res) => res?.data?.data[0]?.count ?? 0,
    refetchOnWindowFocus: false
  });
};
//userChannelCount
//Channelnew/topratedmedwiki?limitTo=5&limitFrom=0

export const useGetActivityCount = (channelId) => {
  return useQuery(
    [CHANNEL_ACTIVITY_COUNT, channelId],
    () => axiosInstance.get(`Channelnew/allcount?type_id=${channelId}`),
    {
      cacheTime,
      staleTime,
      enabled: !!channelId,
      select: (res) => res?.data?.data?.total_activity ?? 0,
      refetchOnWindowFocus: false
    }
  );
};
