import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SwiperCore from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { reactHtmlParser } from '../../../common/common';
import LazyImage from '../../CustomLibraries/LazyImage';
import useShare from '../../customHooks/useShare';
import Card from '../components/Card';
import ReportContentPopover from './components/ReportContentPopover';
SwiperCore.use([EffectFade, Autoplay]);
// import ShowMoreText from '../../showMoreText/ShowMoreText';

import InView from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import ModalDoctorVoice from '../../../pages/dashboard/components/ModalDoctorVoice';
import { useTrackUserActivity } from '../../customHooks/useTrackUserActivity';
import TooltipContainer from '../components/TooltipContainer';
import { safeJsonParser } from '../../../utils/common';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { CARD_VIEW, SHARE_CLICK, SUBMIT_CLICK } from '../../tracking/constants';
import TrackerWrapper from '../../tracking/TrackerWrapper';

const DoctorsVoiceCard = ({ type = 'poll', className = '', data = {}, onClickPoll }) => {
  const userDetails = useSelector((state) => state.common.user_details);
  const doctorVoiceCardOptionRef = useRef(null);
  const doctorVoiceCardRef = useRef(null);
  const [isReported, setIsReported] = useState(false);
  const [isYespoll, setIsyespoll] = useState(false);
  const [widths, setWidths] = useState([]);
  const [sponserLogo, setSponserLogo] = useState([]);
  const [options, setOptions] = useState([]);
  const [question, setQuestion] = useState('');
  const [answerGiven, setAnserGiven] = useState(false);
  const [currOptions, setCurrOptions] = useState(null);
  const [show, setShow] = useState(false);
  const [toOpenModal, setToOpenModal] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const share_url = useShare();
  const { trackActivityEvent } = useTracker();
  const refs = useRef([]);
  const modifiedData = useRef([]);
  const submitPollController = useRef(true);
  const surveyIdRef = useRef('');

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      surveyIdRef.current = data?.survey_id;
      let extractData =
        data?.json_data && typeof data?.json_data == 'string'
          ? safeJsonParser(data.json_data, {})
          : data?.json_data && typeof data == 'object'
          ? data?.json_data
          : [];
      let getOptions = extractData
        ? Object.keys(extractData?.surveys).map((i) => {
            return extractData?.surveys[i];
          })
        : [];
      modifiedData.current = getOptions.length != 0 ? getOptions : [];
      setQuestion(getOptions.length != 0 ? getOptions[0]?.question : '');
      let optionsList =
        getOptions.length != 0
          ? Object.keys(getOptions[0]?.options)?.map((i) => getOptions[0]?.options[i])
          : [];
      if (getOptions[0]?.options.length > 2) {
        setIsyespoll(true);
      } else {
        optionsList.forEach((_op) => {
          if (_op?.label?.length > 10) {
            setIsyespoll(true);
          }
        });
      }
      setOptions(optionsList);
      if (data?.sponsor_logo) {
        setSponserLogo(data?.sponsor_logo.split(','));
      }
    }
  }, [data]);

  function setRef(index) {
    return (ref) => {
      // setWidths([...widths, ref]);
      refs.current[index] = ref;
      setWidths(refs.current);
      // setRendering(!rendering);
    };
  }
  const onPollSubmit = (selectedIndex, index) => {
    // console.log("onPollSubmit",show)
    console.log('truncated', truncated);
    onClickPoll && onClickPoll();
    setAnserGiven(true);
    if (!answerGiven && !truncated) {
      if (submitPollController.current) {
        submitPollController.current = false;
        modifiedData.current[0].selectedIndex = selectedIndex;

        axiosInstance
          .post('survey/pollAnswer', {
            answer_json: JSON.stringify(modifiedData.current),
            survey_id: surveyIdRef.current
          })
          .then((res) => {
            axiosInstance
              .get(`survey/pollResult?id=${surveyIdRef.current}`)
              .then((response) => {
                setCurrOptions(safeJsonParser(response.data.data, [])[0]);
                console.log(
                  'new response',
                  safeJsonParser(response.data.data, [])[0],
                  safeJsonParser(response.data.data, [])[0].options[0].vote
                );
                axiosInstance
                  .post('survey/addpoint', {
                    point: data.point,
                    survey_id: surveyIdRef.current
                  })
                  .then((resp) => {
                    if (parseInt(data.point) > 0) {
                      toast.success(`You have got ${data.point} points`);
                    } else {
                      toast.success(`Thank you for your response`);
                    }
                  })
                  .catch((e) => {
                    console.log('Error', e);
                  });
              })
              .catch((e) => {
                console.log('Error', e);
              });
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }
    }
  };

  const onReportSubmit = () => {
    setIsReported(true);
  };

  const handleShow = useCallback(() => {
    console.log('clickedddd', truncated);
    if (truncated) setShow(true);
  }, [show, truncated]);
  // function handleShow() {
  //   console.log("clicked")
  // }
  const handleClose = useCallback(() => {
    setShow(false);
  }, [show, currOptions, answerGiven]);

  const triggerModalClose = (currOptions) => {
    setAnserGiven(true);
    setCurrOptions(currOptions);
  };

  useEffect(() => {
    console.log('options', options);
    if (options?.length > 2) {
      for (const _opt of options) {
        const prntWidth = document.querySelector(`#dbVoiceLabel_${_opt.value}`)?.clientWidth ?? 0;
        var textWidth = getTextWidth(_opt.label, null);

        if (prntWidth <= textWidth) {
          setToOpenModal(true);
          break;
        }
      }
    }
  }, []);

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
  };

  const { userActivityTracker } = useTrackUserActivity();

  return (
    <>
      {!isReported && data && Object.keys(data)?.length > 0 && (
        <Card className={`clr_v3_card--doctorVoice ${className ?? ''}`}>
          <Card.Body>
            <Card.Header>
              <span className="me-auto d-inline-flex align-items-center">
                <span className="d-flex align-items-center justify-content-center me-2 bg-primary p-2 rounded-1 ">
                  <i className="flaticon-poll fs-6 text-white "></i>
                </span>

                <span>{type == 'poll' ? 'Poll' : "Doctor's Voice"}</span>
              </span>
              <div className="ms-auto align-items-center d-flex  gap-sm-3 gap-2">
                {data && data?.survey_title && data?.deeplink && data?.is_share ? (
                  <TooltipContainer text="Share">
                    <span
                      className="icon-size-32 icon-size-sm-36 clr_v3_card__icon transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative fs-2 clr_v3_icon-btn-1"
                      onClick={() => {
                        trackActivityEvent(SHARE_CLICK, data);
                        share_url(data?.survey_title, data?.deeplink);
                      }}
                    >
                      <i className={`flaticon-share-arrow`}></i>
                    </span>
                  </TooltipContainer>
                ) : (
                  <></>
                )}
                {data && (
                  <>
                    {/* <i className="flaticon-more-1 fs-1"></i> */}
                    <ReportContentPopover
                      data={data}
                      type={data.category}
                      id={data?.survey_id}
                      onReportSubmit={onReportSubmit}
                      isDoctorVoiceCard={true}
                    />
                  </>
                )}
              </div>
            </Card.Header>

            <div
              className="flex-grow-1 d-flex justify-content-center flex-column"
              ref={doctorVoiceCardRef}
            >
              {question != '' && (
                <Card.Title className="d-flex hover-opacity cursorPointer">
                  {/* <ShowMoreText
                    anchorClass={'text-white'}
                    lines={3}
                    expandByClick={true}
                    more={'...'}
                    less={''}
                    truncatedEndingComponent=""
                    width={doctorVoiceCardRef.current?.clientWidth ?? 0}
                  > */}
                  {reactHtmlParser(question)}
                  {/* </ShowMoreText> */}
                </Card.Title>
              )}
            </div>
            <InView
              as="div"
              className="mt-3"
              // onChange={(inView) => {
              //   userActivityTracker(
              //     inView,
              //     { ...data, type: 'doctorvoice', type_id: data.survey_id },
              //     'card'
              //   );
              // }}
            >
              <Form
                className={`d-flex ${
                  isYespoll ? 'flex-column text-start clr_v3_polls--full' : ''
                } gap-3 gap-sm-4`}
              >
                {options?.map((_i, idx) => (
                  <div className="flex-grow-1" key={idx}>
                    <div
                      className={`clr_v3_polls position-relative overflow-hidden ${
                        answerGiven ? 'clr_v3_polls--active' : 'hover-opacity'
                      }`}
                      onClick={() => {
                        trackActivityEvent(SUBMIT_CLICK, {
                          type_id: surveyIdRef.current,
                          type: 'doctorVoice'
                        });
                        onPollSubmit(_i?.value, idx);
                      }}
                    >
                      {/* <input
                        className="position-absolute start-0 top-0 w-100 h-100 z-3 opacity-0 clr_v3_polls__input"
                        type="radio"
                        value=""
                        id="answer"
                        name="doctorVoice"
                        disabled={answerGiven}
                      /> */}
                      <label
                        className={`clr_v3_polls__label transition w-100 border border-1 border-light overflow-hidden rounded-3 d-flex  ${
                          !isYespoll ? 'justify-content-center' : ''
                        } ${!answerGiven ? 'cursorPointer' : ''}`}
                        htmlFor="answer"
                        ref={doctorVoiceCardOptionRef}
                      >
                        <div
                          className={`clr_v3_polls__label__content  position-relative transition ${
                            !isYespoll ? '' : 'flex-grow-1'
                          } `}
                          // ${answerGiven && 'px-3'}
                          style={!isYespoll ? { width: widths[idx]?.offsetWidth } : {}}
                        >
                          {_i?.label && (
                            <span
                              // className="d-inline-block  position-relative w-100"
                              id={`dbVoiceLabel_${_i.value}`}
                            >
                              {
                                !isYespoll
                                  ? _i.label
                                  : // <ShowMoreText
                                    //   className="fs-4 text-black fw-normal"
                                    //   anchorClass={'show-more-less-clickable'}
                                    //   width={
                                    //     doctorVoiceCardOptionRef.current?.clientWidth
                                    //       ? doctorVoiceCardOptionRef.current?.clientWidth - 15
                                    //       : 0
                                    //   }
                                    //   lines={1}
                                    //   expandByClick={false}
                                    //   onClick={handleShow}
                                    //   more={''}
                                    //   less={''}
                                    //   // truncatedEndingComponent=""
                                    //   onTruncate={() => {
                                    //     if (!truncated) {
                                    //       console.log('truncating');
                                    //       setTruncated(true);
                                    //     }
                                    //   }}
                                    // >
                                    _i.label
                                // </ShowMoreText>
                              }
                            </span>
                          )}
                          {/* {answerGiven && ( */}
                          <span
                            className={`position-absolute clr_v3_polls__percent pe-3 transition ${
                              answerGiven ? 'opacity-1' : 'opacity-0'
                            }`}
                          >
                            {answerGiven
                              ? `${currOptions ? currOptions?.options[idx]?.vote : 0}`
                              : '...'}
                            %{/* {currOptions?.options[idx]?.vote}% */}
                          </span>
                          {/* )}  */}
                        </div>

                        <TrackerWrapper
                          type={CARD_VIEW}
                          data={_i}
                          className={`progressBarContainer transition w-100 px-3 start-0 position-absolute ${
                            answerGiven ? 'opacity-1' : 'opacity-0'
                          }`}
                        >
                          <ProgressBar
                            variant="primary"
                            now={currOptions ? currOptions?.options[idx]?.vote : 0}
                            className="transition"
                          />
                        </TrackerWrapper>
                      </label>
                    </div>
                  </div>
                ))}
              </Form>
            </InView>

            {((!!data?.user_participant_count && data?.user_participant_count != 0) ||
              sponserLogo?.length > 0) && (
              <Card.Footer>
                {data && data.user_participant_count && data.user_participant_count != 0 && (
                  <div className="me-auto d-flex align-items-center flex-grow-1">
                    <span
                      title="Participants"
                      className="clr_v3_card__icon text-dark py-1 d-inline-flex align-items-center justify-content-center"
                    >
                      <i className="flaticon-user me-2 fs-2"></i>
                      <span>{data.user_participant_count}</span>
                    </span>
                  </div>
                )}

                {sponserLogo && sponserLogo?.length > 0 ? (
                  <Swiper
                    className="clr_v3_sponsor text-right me-0"
                    navigation={false}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 2500
                    }}
                    effect={'fade'}
                  >
                    {sponserLogo &&
                      sponserLogo?.length > 1 &&
                      sponserLogo?.map((_sp, idx) => (
                        <SwiperSlide
                          key={idx + 1}
                          className="clr_v3_sponsor_slide w-100 position-relative"
                        >
                          <LazyImage
                            src={_sp}
                            alt="sponsor"
                            className={`mw-100 mh-100 end-0 position-absolute translate-middle-y top-50`}
                            logo
                          />
                        </SwiperSlide>
                      ))}
                    {sponserLogo &&
                      sponserLogo?.length == 1 &&
                      sponserLogo?.map((_sp, idx) => (
                        <div key={idx + 1} className="clr_v3_sponsor_slide w-100 position-relative">
                          <LazyImage
                            src={sponserLogo[0]}
                            alt="sponsor"
                            className={`mw-100 mh-100 end-0 position-absolute translate-middle-y top-50`}
                            logo
                          />
                        </div>
                      ))}
                  </Swiper>
                ) : (
                  <></>
                )}
              </Card.Footer>
            )}
          </Card.Body>
          {data && Object.keys(data)?.length > 0 && show && (
            <ModalDoctorVoice
              show={show}
              data={{ ...data, json_data: data?.json_data || data?.data_json }}
              handleClose={handleClose}
              triggerModalClose={(currOptions) => triggerModalClose(currOptions)}
              headingText="Poll"
            />
          )}
        </Card>
      )}
    </>
  );
};

export default memo(DoctorsVoiceCard);
