import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Skeleton } from '../../Skeleton';

const MedwikiLandingFeaturedLoader = () => {
  return (
    <>
      <div className="medwikiTopLeft pe-0 pe-lg-5">
        <Swiper
          className={`medwikiFeaturedSlide shadow rounded-3 bg-white w-100 position-relative`}
          slidesPerView={1}
          loop={false}
          pagination={{ clickable: true }}
          effect={'fade'}
          id="medwiki_landing_feature_card"
        >
          {[1, 2].map((i, index) => (
            <SwiperSlide
              className="medwikiFeaturedMain d-flex flex-wrap bg-white h-auto p-3 pb-50 p-md-4 pb-md-4"
              key={index + 1}
            >
              <div className="medwikiFeaturedPic rounded-3 position-relative overflow-hidden mb-20 mb-md-0">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={200}
                  className="object-fit-cover w-100 h-100 float-start"
                />
              </div>
              <div className="d-flex medwikiFeaturedRight flex-column justify-content-center text-start position-relative z-1 p-0 ps-0 py-md-3 ps-md-20 ps-xxl-5">
                <div className="rounded-3 bg-white shadow medwikiFeaturedContent p-0 p-md-20 py-lg-20 px-lg-5 position-relative">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="text-black d-inline-block fs-4 cursorPointer mb-3 gtm_cl_medwiki_landing_featured_speciality">
                      <Skeleton variant="text" width={100} height={15} />
                    </span>
                    <Skeleton variant="circular" width={20} height={20} />
                  </div>

                  <h2 className="line-clamp-2 fs-16 text-black mb-3 gtm_cl_medwiki_landing_feature_card">
                    <Skeleton variant="text" width="80%" height={25} />
                  </h2>
                  <h5 className="line-clamp-2 fs-4 text-dark mb-4 gtm_cl_medwiki_landing_feature_card">
                    <Skeleton variant="text" width="100%" height={15} />
                    <Skeleton variant="text" width="90%" height={15} />
                  </h5>
                  <Skeleton variant="rounded" width={120} height={35} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* Popular medwiki starts here */}
        <div
          className={`w-100 popularMedwiki shadow bg-white rounded-3 p-3 p-md-4 my-4 text-start position-relative overflow-hidden d-flex justify-content-between`}
          id="medwiki_popular_box"
        >
          {/*  .topRatedMedwiki and .mostCommentedMedwiki classe to be added accordingly */}
          <div className="headingPart pt-2 text-start d-flex justify-content-center align-items-center rounded-3">
            <span className="fs-4 fw-medium text-black">
              Popular
              <span className="fs-2 fw-semibold text-secondary d-block">
                {
                  <>
                    MedWiki<sup>&reg;</sup>
                  </>
                }
              </span>
            </span>
            <a className="fs-4 fw-medium text-primary d-block d-lg-none d-md-none gtm_cl_medwiki_landing_popular_box_explore_more">
              <Skeleton variant="text" width={100} height={30} />
            </a>
          </div>
          <div className="popularMedwikiSlider mb-0">
            <Swiper
              className="popularMedwikiSliderIn text-start pt-3 pb-0 pt-md-1 pb-md-1 ps-0 ps-md-4 d-flex"
              spaceBetween={24}
              slidesPerView={3}
              loop={false}
              breakpoints={{
                1: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 12
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 15
                },
                1400: {
                  slidesPerView: 3,
                  spaceBetween: 15
                },
                1600: {
                  slidesPerView: 4,
                  spaceBetween: 18
                }
              }}
            >
              {[1, 2, 3].map((i, index) => (
                <SwiperSlide
                  className="popularMedwikiSliderBox d-flex justify-content-between align-items-center position-relative border-end border-2 border-secondary"
                  key={index + 1}
                >
                  <div className="popularMedwikiPic overflow-hidden rounded-3 gtm_cl_medwiki_landing_popular_box">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={200}
                      className="object-fit-cover w-100 h-100 float-start"
                    />
                  </div>
                  <div className="popularMedwikiDetails position-relative ps-3 pe-4">
                    <h6 className="fs-5 fw-normal text-dark gtm_cl_medwiki_landing_popular_box_speciality">
                      <Skeleton variant="text" width={60} height={15} />
                    </h6>
                    <h4 className="fs-4 fw-medium text-black line-clamp-2 gtm_cl_medwiki_landing_popular_box">
                      <Skeleton variant="text" width="90%" height={20} />
                    </h4>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="popularMedwikiBtn d-flex justify-content-end align-items-center p-4 position-absolute top-0 end-0 h-100">
            <a
              href="javascript:void(0)"
              className="fs-4 fw-medium text-primary gtm_cl_medwiki_landing_popular_box_explore_more"
            >
              <Skeleton variant="text" width={70} height={30} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(MedwikiLandingFeaturedLoader);