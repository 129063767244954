import React, { useMemo } from 'react';

import LazyImage from '../../../../components/CustomLibraries/LazyImage';
import { Badge, Button } from 'react-bootstrap';
import '../../../../assets/sass/uiV3/pages/share/_observershipHeroCard.scss';
import { isMobile } from 'react-device-detect';
import WhatMakesYouMentor from '../../../share/images/mentorshareBG.png';
import { DEMO_USER_PICTURE } from '../../../../common/common';
import dayjs from 'dayjs';
import getNextAvailableDatetime from '../../observership/utils/getNextAvailableDatetime';
import { safeJsonParser } from '../../../../utils/common';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../../components/tracking/constants';
const MentorHeroCard = ({ data }) => {
  const years_of_experience = data?.practicing ? dayjs().diff(dayjs(data?.practicing), 'year') : 0;
  const nextAvailability = useMemo(
    () =>
      data?.availability
        ? getNextAvailableDatetime(safeJsonParser(data?.availability, []), data?.timezone)
        : '',
    [data?.availability]
  );
  return (
    <>
      <TrackerWrapper
        type={CARD_VIEW}
        data={data}
        className="d-flex rounded-4 bg-white gap-4 gap-md-20 flex-md-row flex-column position-relative overflow-hidden clr_v3_cornerGraphicsDynamic mentorShare_hero border border-secondary"
      >
        {/* graphics section start */}
        <div className="position-absolute top-0 start-0 w-100 h-100 z-0 clr_v3_cornerGraphicsDynamic__Graphics z-0">
          <img src={WhatMakesYouMentor} className="w-100 h-100 object-fit-cover" />
          {!isMobile && (
            <svg
              className="position-absolute top-0 end-0"
              xmlns="http://www.w3.org/2000/svg"
              width="104"
              height="136"
              viewBox="0 0 104 136"
              fill="none"
            >
              <path d="M104 0V136C100.093 49.3538 33.0389 9.23077 0 0H104Z" fill="#159A6C" />
            </svg>
          )}
        </div>
        {/* graphics section end */}
        <div className="position-relative mentorShare_hero__left py-sm-50 py-20 ps-md-5 d-flex align-items-center clr_v3_cornerGraphicsDynamic  overflow-hidden justify-content-center justify-content-md-start flex-shrink-0">
          <div className="position-absolute top-0 start-0 w-100 h-100 z-0 clr_v3_cornerGraphicsDynamic__Graphics z-0">
            <div className="overlay d-flex gap-5">
              <div
                className={`flex-grow-1 h-100 bg-secondary bg-opacity-10 ${
                  isMobile ? 'rounded-circle' : ''
                }`}
              ></div>
              {!isMobile && (
                <div className={'flex-shrink-0 h-100 border-start border-secondary'}></div>
              )}
            </div>
            {isMobile && (
              <svg
                className="position-absolute top-0 end-0"
                xmlns="http://www.w3.org/2000/svg"
                width="104"
                height="136"
                viewBox="0 0 104 136"
                fill="none"
              >
                <path d="M104 0V136C100.093 49.3538 33.0389 9.23077 0 0H104Z" fill="#159A6C" />
              </svg>
            )}
            {!isMobile && (
              <svg
                className="position-absolute bottom-0 start-0"
                xmlns="http://www.w3.org/2000/svg"
                width="104"
                height="131"
                viewBox="0 0 104 131"
                fill="none"
              >
                <path d="M0 136V0C3.90668 86.6462 70.9611 126.769 104 136H0Z" fill="#159A6C" />
              </svg>
            )}
          </div>
          <div className="position-relative flex-shrink-0">
            {' '}
            <div className=" position-relative p-3">
              <svg
                className="w-100 h-100 position-absolute start-0 top-0"
                width="249"
                height="249"
                viewBox="0 0 249 249"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="124.5"
                  cy="124.5"
                  r="122.5"
                  fill="white"
                  stroke="#159A6C"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-dasharray="40% 20%"
                />
              </svg>

              <LazyImage
                src={data?.profile_image || DEMO_USER_PICTURE}
                alt="profile"
                fallback_image={DEMO_USER_PICTURE}
                className={`object-fit-cover w-100 h-100 transition `}
                extraClass="rounded-circle overflow-hidden mentorShare_hero__image border border-white position-relative z-1"
              />
            </div>
          </div>
        </div>

        <div className="flex-grow-1 d-flex flex-column gap-2 justify-content-center pe-md-50 py-md-50 pt-0 pb-20 ps-md-0 px-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className={`fs-2 text-black line-clamp-1 fw-semibold`}>
              {data?.first_name || ''} {data?.middle_name || ''} {data?.last_name || ''}
            </h4>
            <h5 className="fs-5 d-flex align-items-center gap-2 text-black fw-normal flex-shrink-0">
              <i className="flaticon-pin text-secondary"></i>{' '}
              <span className="line-clamp-1">
                {data?.state_name}, {data?.country_name}
              </span>
            </h5>
          </div>
          {data?.education?.length > 0 && (
            <h4 className={`fs-4 line-clamp-1 text-black`}>
              {data?.education?.map(({ degree }) => degree).join(', ')}
            </h4>
          )}
          <h5 className={`fs-5 line-clamp-1 fw-normal fw-semibold text-secondary`}>
            {years_of_experience} Years of experience
          </h5>

          {nextAvailability && (
            <h6 className={`fs-4 line-clamp-1 fw-medium text-black`}>
              Next Available on {dayjs(nextAvailability?.from).format('DD MMM YYYY, hh:mm A')}
            </h6>
          )}
          {data?.description && <p>{data?.description || ''}</p>}
          {data?.specialities_name && (
            <div className="d-flex gap-3 flex-wrap">
              <Badge bg="soft-primary" className="fw-normal cursorPointer transition">
                {data?.specialities_name || ''}
              </Badge>
            </div>
          )}
        </div>
      </TrackerWrapper>
    </>
  );
};

export default React.memo(MentorHeroCard);
