import React from 'react';
import './noResult/css/noResult.scss';
import noResultImg from './noResult/images/noResult.png';

const statusMessages = {
  100: '"Looking good so far! Keep sending that data—we\'re ready for it!"',
  200: '"Hooray! Everything worked perfectly. You\'re all set!"',
  201: '"Success! Your new creation is live and ready. Well done!"',
  204: ' "All done! There\'s nothing to show, but your request was completed without a hitch."',
  203: ' Non-Authoritative Information ',
  301: ' Moved Permanently: "Heads up! This page has a new home. Taking you there now—hang tight!"',
  302: '"Just a quick detour! We\'re redirecting you to the right place."',
  304: ' "No need to reload—everything’s already up-to-date! Enjoy the speed boost."',
  400: ' "Uh-oh! Something’s not quite right. Let’s double-check and give it another go!"',
  401: ' "You’re almost there! Just log in, and you’ll be good to go."',
  403: ' "Hmm, looks like this area is off-limits. If you think this is a mistake, let us know!"',
  404: '"We searched everywhere, but couldn’t find that page. Let’s head back home and try again!"',
  405: '"Whoops! That action isn’t supported. Maybe try a different approach?"',
  409: '"Looks like a bit of a mix-up! There’s a conflict with the data—let’s sort it out and try again."',
  422: '"We’re almost there! A few details need adjusting before we can proceed."',
  429: ' "Whoa there, speedster! You’re sending requests a bit too quickly. Take a breather and try again soon."',
  500: '"Yikes! Something went wrong on our end. We’re on it—please try again in a bit!"',
  502: '"Our servers are playing telephone, and the message got garbled. Give it another go!"',
  503: '"We’re just tidying up behind the scenes. Check back in a few moments!"',
  504: '"This is taking longer than usual. Give it a moment and refresh—good things come to those who wait!"'
};

const InactiveComponent = ({
  header = 'Inactive Content',
  subHeader = 'This content is inactive',
  icon = '',
  statuscode = '',
  enable = true
}) => {
  // if (!statuscode) return <></>;
  if (!statuscode || statuscode.toString().startsWith('20')) return <></>;
  const message = statusMessages[statuscode];
  return (
    enable && (
      <div className="w-100 noResult text-center">
        <img src={noResultImg} alt="No Result Found" className="noResultPic mb-3" />
        <h6 className="fs-4 fw-bold text-black mb-4">{header}</h6>
        <h6 className="fs-4 fw-normal text-black">{message}</h6>
      </div>
    )
  );
};

export default InactiveComponent;
