import axios from 'axios';
import packageJson from '../../../package.json';
import { registerConstants } from '../constants/registrationConstants';
import { isMobileApp } from '../../common/common';
import axiosOpenInstance from '../helper/axiosOpenInstance';

const url = process.env.REACT_APP_API_URL;

const apiVersion = packageJson.version;

export const signUpEmail = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: `${url}Authrjs/signupEmail`,
      data: formData,
      headers: { version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}` }
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: registerConstants.REGISTRATION_SUCCESS
          });
        }
      })
      .catch((error) => {
        callback('error');
        console.log(error);
        dispatch({
          type: registerConstants.REGISTRATION_FAILURE
        });
      });
  };
};
export const newsignUpEmail = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: `${url}Authrjs/newsignupEmail`,
      data: formData,
      headers: { version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}` }
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: registerConstants.REGISTRATION_SUCCESS
          });
        }
      })
      .catch((error) => {
        callback('error');
        console.log(error);
        dispatch({
          type: registerConstants.REGISTRATION_FAILURE
        });
      });
  };
};
export const getAllSpecialities = (callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'GET',
      url: url + 'authnew/getallspeciality',
      headers: { version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}` }
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: registerConstants.GET_ALL_SPECIALITY_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        callback('error');
        console.log(error);
        dispatch({
          type: registerConstants.GET_ALL_SPECIALITY_FALIURE,
          payload: error
        });
      });
  };
};
