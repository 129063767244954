import React from 'react';
import '../css/doctubeShare.scss';
import doctubeLogo from '../../doctubeLanding/images/doctubeLogo.png';
import cardGraph1 from '../../doctubeLanding/images/cardGraph1.png';
import doctubeBannerDots from '../../doctubeLanding/images/dots.png';
import dt_banner_cross from '../../doctubeLanding/images/cross.png';
import { Dt_BgGraph, Dt_Icon1, Dt_Icon2, Dt_Icon3 } from '../../../components/icons';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import MedwikiCardV1 from '../../../components/cards/medwiki/medwikiCardV1/MedwikiCardStat';
import {
  useDoctubeDoctorAnalyticsMetrix,
  useDoctubeOpenTrendingCategory
} from '../../../Queries/QueryHooks/doctube.hook';

import LazyImage from '../../../components/CustomLibraries/LazyImage';
import DoctubeShareSkeleton from '../../../skeleton/shareSkeleton/DoctubeShareSkeleton';
// import ReactHtmlParser from 'react-html-parser';
import { reactHtmlParser } from '../../../common/common';
import { Skeleton } from '../../../skeleton/Skeleton';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
SwiperCore.use([Pagination, Navigation]);

function DoctubeHeroCard({ gtmTagVariable = '' }) {
  const { data: metrixData, isLoading: metrixDataloading } = useDoctubeDoctorAnalyticsMetrix();
  const { data: trendCategoryData, isLoading: trendCategoryloading } =
    useDoctubeOpenTrendingCategory();
  console.log('data of metrix', metrixData, trendCategoryData);
  const onJoinNowClick = () => {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  };
  console.log('metrixData', metrixData, 'trendCategoryData', trendCategoryData);
  return (
    <TrackerWrapper
      data={metrixData}
      type={CARD_VIEW}
      className="w-100 float-start dt_share rounded-3 bg-white shadow position-relative overflow-hidden p-3 p-md-4 p-lg-20"
    >
      {/* page - start */}
      <Dt_BgGraph customClass={'dt_share_graph position-absolute h-auto z-2 opacity-50'} />
      <div className="dt_shareTop position-relative overflow-hidden">
        <div className="dt_shareTop_Dots position-absolute top-0 start-0 h-auto">
          <img
            src={doctubeBannerDots}
            className="object-fit-cover w-100 h-100 float-start"
            alt="Banner Graph"
          />
        </div>
        <div className="dt_share_cross position-absolute d-none d-md-block">
          <img
            src={dt_banner_cross}
            className="object-fit-cover w-100 h-100 float-start"
            alt="Banner Graph"
          />
        </div>
        <div className="dt_shareTopIn w-100 position-relative z-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap pt-5 pb-50 pt-lg-50 pb-lg-70">
            {!metrixDataloading ? (
              <div className="col-xxl-4 col-lg-5 col-md-5 col-12">
                <div className="dt_shareTop_Left text-start pe-lg-4 pe-md-3">
                  <h4 className="fs-2 fw-semibold text-black mb-3">Introducing</h4>
                  <div className="dt_Logo mb-4">
                    <img
                      src={doctubeLogo}
                      alt="Doctube Logo"
                      className="mw-100 mh-100 w-auto h-auto"
                    />
                  </div>
                  <h5 className="fs-16 fw-medium text-black mb-2">
                    DocTube™ is a pure video-based patient-focused health education platform.
                  </h5>
                  <h6 className="fs-4 fw-medium text-dark">
                    It is a patient-centric and patient-engaging platform where thousands of doctors
                    share valuable information to make the public more well-informed.
                  </h6>
                  <div className="dt_share_statArea my-4">
                    {!metrixDataloading && metrixData?.total_channel && (
                      <div className="d-inline-flex text-start align-items-center justify-content-center rounded-3 p-2 p-lg-3 me-3 dt_share_statBox yellow">
                        <h4 className="text-black fw-normal fs-4">
                          <span className="fs-4 text-black fw-medium">
                            <span className="fs-2 fw-semibold d-block">
                              {metrixData?.total_channel}
                            </span>
                            Channels
                          </span>
                        </h4>
                      </div>
                    )}
                    {!metrixDataloading && metrixData?.total_videos && (
                      <div className="d-inline-flex text-start align-items-center justify-content-center rounded-3 dt_share_statBox p-2 p-lg-3 me-3 red">
                        <h4 className="text-black fw-normal fs-4">
                          <span className="fs-4 text-black fw-medium">
                            <span className="fs-2 fw-semibold d-block">
                              {metrixData?.total_videos}
                            </span>
                            Videos
                          </span>
                        </h4>
                      </div>
                    )}
                    {!metrixDataloading && metrixData?.total_users && (
                      <div className="d-inline-flex text-start align-items-center justify-content-center rounded-3 dt_share_statBox p-2 p-lg-3 me-3 blue">
                        <h4 className="text-black fw-normal fs-4">
                          <span className="fs-4 text-black fw-medium">
                            <span className="fs-2 fw-semibold d-block">
                              {metrixData?.total_users}
                            </span>
                            Viewers
                          </span>
                        </h4>
                      </div>
                    )}
                  </div>
                  <a
                    href="javascript:void(0)"
                    className={`fs-4 fw-medium rounded-3 text-center text-capitalize w-100 btn btn-secondary mb-5 d-md-none py-3 px-4 ${gtmTagVariable}_doctubeHeroCard_join_now`}
                    onClick={onJoinNowClick}
                  >
                    Join Now
                  </a>
                </div>
              </div>
            ) : (
              <DoctubeShareSkeleton />
            )}
            <div className="col-xxl-7 col-lg-7 col-md-7 col-12">
              <div className="dt_shareTop_Right w-100 float-start">
                <div className="d-flex align-items-center ms-n4 ms-md-n5 mt-n4 mt-md-n5 me-0">
                  <div className="col-lg-4 col-md-4 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0 dt_shareTop_Card">
                    <div className="dt_shareTop_CardIn d-flex flex-row flex-lg-column flex-md-column align-items-center bg-white rounded-3 text-center position-relative border border-5 border-light-subtle">
                      <div className="dt_shareTop_Card_Icon mx-auto mb-0 mb-md-4 mb-lg-5">
                        <Dt_Icon1 customClass={'w-100 h-100'} />
                      </div>
                      <h4 className="fs-16 fw-semibold mb-0 mb-md-4 mb-lg-5 lh-sm">
                        <span className="text-primary">Solution</span> to medical conditions
                      </h4>
                      <h2 className="fs-36 fw-bold text-dark parcent">70%</h2>
                      <div className="dt_shareTop_Card_Graph position-absolute d-none d-md-block">
                        <img
                          src={cardGraph1}
                          alt=""
                          className="object-fit-cover w-100 h-100 float-start"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0 dt_shareTop_Card">
                    <div className="dt_shareTop_CardIn d-flex flex-row flex-lg-column flex-md-column align-items-center bg-white rounded-3 text-center position-relative border border-5 border-light-subtle">
                      <div className="dt_shareTop_Card_Icon mx-auto mb-0 mb-md-4 mb-lg-5">
                        <Dt_Icon2 customClass={'w-100 h-100'} />
                      </div>
                      <h4 className="fs-16 fw-semibold mb-0 mb-md-4 mb-lg-5 lh-sm">
                        <span className="text-primary">Validated</span> medical information
                      </h4>
                      <h2 className="fs-36 fw-bold text-dark parcent">60%</h2>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0 dt_shareTop_Card">
                    <div className="dt_shareTop_CardIn d-flex flex-row flex-lg-column flex-md-column align-items-center bg-white rounded-3 text-center position-relative border border-5 border-light-subtle">
                      <div className="dt_shareTop_Card_Icon mx-auto mb-0 mb-md-4 mb-lg-5">
                        <Dt_Icon3 customClass={'w-100 h-100'} />
                      </div>
                      <h4 className="fs-16 fw-semibold mb-0 mb-md-4 mb-lg-5 lh-sm">
                        <span className="text-primary">Better understanding</span> amongst patients
                      </h4>
                      <h2 className="fs-36 fw-bold text-dark parcent">100%</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 float-start dt_share_categories cmnLandingSlideRow mb-5">
        <h2 className="text-black fs-2 fw-semibold text-capitalize text-start">
          Trending categories
        </h2>
        <div className="cmnLndngRowSlidePrrnt">
          <Swiper
            className="cmnLndngRowSlide dt_share_category_slide"
            spaceBetween={15}
            slidesPerView={6}
            loop={false}
            breakpoints={{
              1: {
                slidesPerView: 2,
                spaceBetween: 10
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 14
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 16
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 18
              },
              1400: {
                slidesPerView: 6,
                spaceBetween: 20
              }
            }}
          >
            {!trendCategoryloading
              ? trendCategoryData?.length > 0 &&
                trendCategoryData?.slice(0, 20).map((_i) => (
                  <SwiperSlide key={_i?.id}>
                    <TrackerWrapper
                      data={_i}
                      type={CARD_VIEW}
                      className="easytoFindCard d-flex align-items-center justify-content-center flex-column rounded-3 py-4 px-3"
                    >
                      <LazyImage
                        src={_i?.icon}
                        alt="Demo icon"
                        className="mw-100 mh-100 w-auto h-auto"
                        extraClass={
                          'easytoFindCardIcon rounded-circle bg-white d-flex align-items-center justify-content-center mb-3 p-4'
                        }
                      />
                      {/* </div> */}
                      <h4 className="fs-4 text-black fw-normal line-clamp-1 text-capitalize">
                        {reactHtmlParser(_i?.name)}
                      </h4>
                    </TrackerWrapper>
                  </SwiperSlide>
                ))
              : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_s) => (
                  <SwiperSlide key={_s + 1}>
                    <Skeleton
                      variant="rectangular"
                      height={150}
                      // width={150}
                      className="easytoFindCard d-flex align-items-center justify-content-center flex-column rounded-3 py-4 px-3"
                      // className="d-inline-flex text-start align-items-center justify-content-center rounded-3 dt_share_statBox p-2 p-lg-3 me-3"
                    />
                    {/* </div> */}
                    <h4 className="fs-16 text-black fw-normal line-clamp-1 text-capitalize word_break_all">
                      {/* {reactHtmlParser(_i?.name)} */}
                    </h4>
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
      </div>
      <div className="w-100 float-start dt_share_doctors">
        <h2 className="text-black fs-2 fw-semibold text-capitalize mb-4 text-start">
          Top contributors
        </h2>
        <div className="particle-container position-relative overflow-hidden bg-white w-100">
          <div className="particles position-relative w-100 h-100">
            <span className="circle position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1624629383_5551000800d08706aEeA75DE61.png"
              />
            </span>
            <span className="circle 1 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1669718067_Dr.-Rajasekara-Chakravarthi-Madarasu.jpg"
              />
            </span>
            <span className="circle 2 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1657264824_dvCfLUiP_400x400.jpg"
              />
            </span>
            <span className="circle 3 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1665553253_DrPoonamMalhotra-large_(1).jpg"
              />
            </span>
            <span className="circle 4 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1665561967_1664016454_WhatsApp_Image_2022-09-21_at_2.07.20_PM.jpeg"
              />
            </span>
            <span className="circle 5 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1642674942_1aE9Ef96555EA3e6eF31556c6e.png"
              />
            </span>
            <span className="circle 6 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1664349422_2f11963d-cec6-459b-b08f-46c174537759.jpeg"
              />
            </span>
            <span className="circle 7 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1661334149_1649157440_5524E34c328C08622046438595.jpeg"
              />
            </span>
            <span className="circle 8 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1662465447_sheetal.jpg?tr=w-97,h-97,pr=true,c-at_max"
              />
            </span>
            <span className="circle 9 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1639388764_bB7B76bb878815C36671563c11.png"
              />
            </span>
            <span className="circle 10 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1631185305_e9106691E99933FfE5e6969Ee9.jpg"
              />
            </span>
            <span className="circle 11 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1661508198_1637758826_6716719633389b86B717aA7eE1.jpg"
              />
            </span>
            <span className="circle 12 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1646039654_25CF6652641c169622e3E21916.jpg"
              />
            </span>
            <span className="circle 13 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1658925908_WhatsApp_Image_2022-07-27_at_12.10.03_PM.jpeg"
              />
            </span>
            <span className="circle 14 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1659954875_user-scott-riley-320x320.jpg"
              />
            </span>
            <span className="circle 15 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1626937472_08748f1004161e8009498E6F01.jpg"
              />
            </span>
            <span className="circle 16 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1661337571_507cb1e6-351a-40dc-a0ee-aeb76f959523.jpg"
              />
            </span>
            <span className="circle 17 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1665729939_1564746687.jpg"
              />
            </span>
            <span className="circle 18 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1657106439_download.jpg"
              />
            </span>
            <span className="circle 19 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1657091011_Dr._Harish.jpg"
              />
            </span>
            <span className="circle 20 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1661508580_1556219287744.jpg"
              />
            </span>
            <span className="circle 21 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1653377044_28261E49c8C1585e8e19881694.png"
              />
            </span>
            <span className="circle 22 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img src="https://img-cdn.clirnet.com/medwiki/43_server/video/1657955362_S_P_Jaiswar.PNG" />
            </span>
            <span className="circle 23 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1670229392_Subrata_Basak.jpg"
              />
            </span>
            <span className="circle 24 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1670236941_Dr__Soumik_Goswami.jpg"
              />
            </span>
            <span className="circle 25 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1646030540_eE961a124FcfCCA02C09c6c066.png"
              />
            </span>
            <span className="circle 26 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1627971546_88FAa0510dDBc1Ef5C1116Dfd6.jpg"
              />
            </span>
            <span className="circle 27 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1667199066_Deepika_Verma_(2).png"
              />
            </span>
            <span className="circle 28 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1653374646_72a868666762c267Cb26ABE3e6.png"
              />
            </span>
            <span className="circle 29 position-absolute overflow-hidden top-0 start-0 rounded-circle z-2">
              <img
                className="w-100 h-100 position-relative object-fit-cover"
                src="https://img-cdn.clirnet.com/medwiki/43_server/video/1666005671_1665991413_imageonline-co-roundcorner.png"
              />
            </span>
          </div>
        </div>
      </div>
    </TrackerWrapper>
  );
}

export default DoctubeHeroCard;
