import { channelConstants } from '../constants/channelConstants';
import axios from 'axios';
import axiosInstance from '../helper/axiosInstance';
import { generateUtmExt, log } from '../../common/common';
import { logoutUser } from './login.action';
import dayjs from 'dayjs';
import axiosOpenInstance from '../helper/axiosOpenInstance';

const url = process.env.REACT_APP_API_URL;

export const getUserChannelList = (pagination, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`Channelnew/userChannel?from=${pagination}&to=10`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: channelConstants.GET_USER_CHANNEL_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
        dispatch({
          type: channelConstants.GET_USER_CHANNEL_FALIURE,
          payload: error
        });
      });
  };
};

export const getUserSuggestionList = (suggestionPagination, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`Channelnew/suggestionList?from=${suggestionPagination}&to=10`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: channelConstants.GET_USER_CHANNEL_SUGGESTION_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        log('Error', error);
        dispatch({
          type: channelConstants.GET_USER_CHANNEL_SUGGESTION_FALIURE,
          payload: error
        });
      });
  };
};

export const getChannelDetails = (id, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`Channelnew/detail?type_id=${id}${generateUtmExt()}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: channelConstants.GET_CHANNEL_DETAILS_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        console.log('Error', error);
        dispatch({
          type: channelConstants.GET_CHANNEL_DETAILS_FALIURE,
          payload: error
        });
      });
  };
};
export const getChDetailsByToken = (id, token, callback) => {
  return (dispatch) => {
    axiosOpenInstance({
      url: `${url}Channelnew/detail?type_id=${id}${generateUtmExt()}`,
      method: 'GET',
      headers: {
        Authorization: token
      }
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        console.log('Error', error);
      });
  };
};
export const followSwitchByToken = (id, token, fromMasterCast = false, callback) => {
  let formdata;
  if (fromMasterCast) {
    formdata = { type_id: id, userstatus: 1 };
  } else {
    formdata = { type_id: id };
  }
  return (dispatch) => {
    axiosOpenInstance({
      url: `${url}Channelnew/follow_switching`,
      data: JSON.stringify(formdata),
      method: 'POST',
      headers: {
        Authorization: token
      }
    })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        console.log(error);
      });
  };
};
export const GetAllChannelData = (id, paginationAll, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`Channelnew/all?type_id=${id}&from=${paginationAll}&to=600`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: channelConstants.GET_CHANNEL_ALL_DATA_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        console.log('Error', error);
        dispatch({
          type: channelConstants.GET_CHANNEL_ALL_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const followSwitching = (id, callback) => {
  let formdata = { type_id: id };
  return (dispatch) => {
    axiosInstance
      .post(`Channelnew/follow_switching`, formdata)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: channelConstants.FOLLOW_SWITCHING_SUCCESS
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        console.log(error);
        dispatch({
          type: channelConstants.FOLLOW_SWITCHING_FALIURE
        });
      });
  };
};

export const getPopularPost = (callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`Channelnew/popular?from=0&to=6`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: channelConstants.GET_POPULAR_POST_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        dispatch({
          type: channelConstants.GET_POPULAR_POST_FALIURE,
          payload: error
        });
      });
  };
};
export const getRunningElections = (limitFrom, limitTo, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(
        limitFrom && limitTo
          ? `election/listing?date=${dayjs()
              .add(30, 'day')
              .format('YYYY-MM-DD')}&limitTo=${limitTo}&limitFrom=${limitFrom}`
          : `election/listing?date=${dayjs().add(30, 'day').format('YYYY-MM-DD')}`
      )
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};
export const filterArray = (id) => {
  return {
    type: channelConstants.FILTERED_ARRAY,
    payload: id
  };
};

export const clearSuggestionArray = () => {
  return {
    type: channelConstants.CLEAR_SUGGESTION_ARRAY
  };
};

export const clearPopularListArray = () => {
  return {
    type: channelConstants.CLEAR_POPULAR_POST_ARRAY
  };
};
