import React, { useEffect, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { reactHtmlParser } from '../../../../common/common';
import '../scss/contentExploreModal.scss';
import RelatedCard from '../../../../components/baseUiV3/contentCards/RelatedCard';
import { generateProps } from '../../../../utils/generateProps';
import useContentAccessRedirect from '../../../../common/useContentAccessRedirect';
import SpecialityProgressBar from '../../../speciality/SpecialityProgressBar';
import RelatedCardSkeleton from '../../../../skeleton/uiv3/RelatedCardSkeleton';
function ContentExploreModal({
  onReadMore,
  show,
  redirectToMainContent,
  newContentId,
  data,
  isLoading
}) {
  const { contentAccessRedirect } = useContentAccessRedirect();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          redirectToMainContent();
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 5;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      setProgress(0);
    };
  }, []);

  return (
    <Modal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className="centerModal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-3 fw-semibold">
          <span className="text-danger fw-bold">🚨</span> Fresh off the Press!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-black fw-semibold fs-4 fw-semibold mb-sm-3 mb-2 lh-base">
          Here's a recommended article fresh from our Editorial Board that we think you will Love
          <i className="flaticon-heart-1 me-2 fs-4 d-inline explore-icon-area text-danger px-2"></i>
          .
        </h4>

        {!isLoading && data ? (
          <div className="border border-primary border-2 my-3 rounded-3">
            <RelatedCard
              pageId={newContentId}
              cardType="small"
              {...generateProps(data, contentAccessRedirect)}
              onClick={redirectToMainContent}
            />
          </div>
        ) : (
          <RelatedCardSkeleton type="small" />
        )}
        <div className="d-flex justify-content-between">
          <h5 className="text-black fw-semibold fs-5 fw-semibold mb-sm-3 mb-2">Loading ...</h5>
          <h5 className="text-black fw-semibold fs-5 fw-semibold mb-sm-3 mb-2">
            {Math.round(100 - (20 - progress / 5) * 5)} %
          </h5>
        </div>
        <ProgressBar now={progress} animated />

        <div className="d-flex flex-column justify-content-center align-items-center mt-20">
          <button
            className=" fs-4 btn btn-outline-primary me-3"
            type="button"
            onClick={onReadMore}
            aria-label="Skip"
          >
            Stay here
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ContentExploreModal;
