import dayjs from 'dayjs';
import convertAvailabilityForMentee from './convertAvailabilityForMentee';
import { getTimeZone } from '../../../../common/common';
function getNextAvailability(Availability, currentDay = dayjs().add(1, 'day')) {
  let toProceed =
    Availability &&
    Availability.length > 0 &&
    Availability.some((day) => day.slots.length > 0 && day.slots[0].from && day.slots[0].to);
  if (toProceed) {
    const now = currentDay;

    // Find today's availability
    const today = Availability.find((day) => day.day === now.format('dddd'));

    if (today) {
      // Filter out slots that have passed today's current time
      const availableSlots = today.slots.filter(
        (slot) =>
          slot.from && slot.to && dayjs(`${now.format('YYYY-MM-DD')} ${slot.to}`).isAfter(now)
      );

      if (availableSlots.length > 0) {
        // Sort available slots by start time
        availableSlots.sort((a, b) =>
          dayjs(`${now.format('YYYY-MM-DD')} ${a.from}`).diff(
            `${now.format('YYYY-MM-DD')} ${b.from}`
          )
        );

        // Return the start time of the first available slot
        const nextAvailableSlot = availableSlots[0];
        const nextAvailableDatetimeFrom = dayjs(
          `${now.format('YYYY-MM-DD')} ${nextAvailableSlot.from}`
        );
        const nextAvailableDatetimeTo = dayjs(
          `${now.format('YYYY-MM-DD')} ${nextAvailableSlot.to}`
        );

        return {
          from: nextAvailableDatetimeFrom.format('YYYY-MM-DD HH:mm:ss'),
          to: nextAvailableDatetimeTo.format('YYYY-MM-DD HH:mm:ss')
        };
      }
    }

    // If no available slots for today, find the first available slot for the next day
    const nextDay = (now.day() + 1) % 7; // Get the next day (0-indexed)
    const nextDayAvailability = Availability.find(
      (day) => day.day === now.add(1, 'day').format('dddd')
    );

    if (nextDayAvailability) {
      const nextDaySlots = nextDayAvailability.slots;
      const validNextDaySlots = nextDaySlots.filter((slot) => slot.from && slot.to);

      if (validNextDaySlots.length > 0) {
        const nextDaySlot = validNextDaySlots[0];
        const nextAvailableDatetimeFrom = now
          .add(1, 'day')
          .set('hour', nextDaySlot.from.split(':')[0])
          .set('minute', nextDaySlot.from.split(':')[1]);

        const nextAvailableDatetimeTo = now
          .add(1, 'day')
          .set('hour', nextDaySlot.to.split(':')[0])
          .set('minute', nextDaySlot.to.split(':')[1]);

        return {
          from: nextAvailableDatetimeFrom.format('YYYY-MM-DD HH:mm:ss'),
          to: nextAvailableDatetimeTo.format('YYYY-MM-DD HH:mm:ss')
        };
      } else {
        // Recursively check subsequent days until a valid slot is found
        return getNextAvailability(Availability, now.add(1, 'day'));
      }
    }
  }

  // If no availability found, return null
  return null;
}
export default function getNextAvailableDatetime(availability, timezone = '') {
  const mentorTimezone = timezone || 'Asia/Calcutta';
  let Availability = convertAvailabilityForMentee(availability, mentorTimezone);
  let nextAvailableOn = getNextAvailability(Availability);

  return nextAvailableOn;
}
