import React, { memo, useEffect, useState } from 'react';

import CardsArea from './CardsArea';
import ChannelHeroCard from './ChannelHeroCard';
import ClVideoHeroCard from './ClVideoHeroCard';
import EbookHeroCard from './EbookHeroCard';
import MedwikiHeroCard from './MedwikiHeroCard';
import SessionHeroCard from './SessionHeroCard';
import SurveyHeroCard from './SurveyHeroCard';
import TrainingHeroCard from './TrainingHeroCard';

import { useDispatch } from 'react-redux';
import { toggleReference } from '../../../Store/actions';
import { getOpenApiModuleList } from '../../../Store/actions/training.action';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import {
  CertificateBigIcon,
  CertificateIcon,
  CertificateSharePageIcon
} from '../../../components/icons';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
import AidaContainer from '../../aida';
import TrainingContentAccordion from '../../grTraining/components/trainingContentAccordion/TrainingContentAccordion';
import ClaimMentorSection from '../../uiv3/share/components/ClaimMentorSection';
import MentorHeroCard from '../../uiv3/share/components/MentorHeroCard';
import ObservershipHeroCard from '../../uiv3/share/components/ObservershipHeroCard';
import DdiHeroCard from './DdiHeroCard';
import DoctubeHeroCard from './DoctubeHeroCard';
import GRHeroCard from './GRHeroCard';
import PollHeroCard from './PollHeroCard';
const HeroSectionCard = ({ share_type, heroCardData, channel_name, channel_logo, page_id }) => {
  switch (share_type) {
    case 'comp':
      return (
        <MedwikiHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'session':
      return (
        <SessionHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'training':
      return (
        <TrainingHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'survey':
      return (
        <SurveyHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'video':
    case 'archived_video':
      return (
        <ClVideoHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );

    // return (
    //   <ClVideoHeroCard
    //     gtmTagVariable={`share_${share_type}`}
    //     data={heroCardData}
    //     channel_name={channel_name}
    //     channel_logo={channel_logo}
    //   />
    // );
    case 'channel':
      return (
        <ChannelHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'observership':
      return <ObservershipHeroCard gtmTagVariable={`share_${share_type}`} data={heroCardData} />;
    case 'mentor':
      return <MentorHeroCard gtmTagVariable={`share_${share_type}`} data={heroCardData} />;
    case 'claim_mentorship':
      return <ClaimMentorSection data={heroCardData} />;
    case 'epub':
      return (
        <EbookHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'doctube':
    case 'doctube_fst':
      return <DoctubeHeroCard gtmTagVariable={`share_${share_type}`} />;
    // case 'doctube_fst':
    //   return <DoctubeHeroCard gtmTagVariable={`share_${share_type}`} />;

    case 'differential_diagnosis':
      return <AidaContainer share={true} />;

    case 'ddi':
      return <DdiHeroCard id={page_id} isResult={true} isIntro={true} data={heroCardData} />;

    case 'poll':
      // console.log('POLL FOUND');
      return (
        <PollHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'gr':
      return (
        <GRHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );

    default:
      return <></>;
  }
};
const LeftContentBar = ({
  data,
  heroCardData,
  page_id = 0,
  share_type,
  channel_name,
  channel_logo
}) => {
  // const mobileInputRef = useRef();
  const dispatch = useDispatch();
  const [moduleList, setModuleList] = useState([]);
  useEffect(() => {
    if (share_type == 'training') {
      dispatch(
        getOpenApiModuleList(heroCardData?.id, (res) => {
          console.log('HERO API MODULE', res);
          res && res.length > 0 && setModuleList(res);
        })
      );
    }
  }, []);

  const focusToForm = () => {
    dispatch(toggleReference(true));
  };
  return (
    <div className="w-100 float-start shareLoginV2LeftIn p-3 p-md-4 p-lg-20 p-xl-5 position-sticky top-0 min-vh-100">
      {/* card area */}
      <div className="w-100 float-start shareLoginV2MainCardArea d-none d-lg-block mb-5">
        <HeroSectionCard
          share_type={share_type}
          channel_logo={channel_logo}
          channel_name={channel_name}
          heroCardData={heroCardData}
          page_id={page_id}
          key={page_id + share_type}
        />
      </div>
      {moduleList?.length > 0 && (
        <TrainingContentAccordion
          training_id={heroCardData?.id}
          module_list={moduleList}
          isSharePage={true}
        />
      )}

      {share_type == 'training' && (
        <div className="w-100 float-start mb-5">
          <div className="d-flex ms-n4 ms-md-n5 mt-n4 mt-md-n5 me-0 flex-wrap">
            <TrackerWrapper
              type={CARD_VIEW}
              data={data}
              className="col-xl-6 col-lg-12 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0"
            >
              <div className="grCertifiedDocs rounded-3 bg-white shadow overflow-hidden">
                <div className="d-flex flex-column flex-sm-row grCertifiedDocsMain">
                  <div className="grCertifiedDocsLeft d-inline-flex justify-content-center align-items-center ps-3 ps-md-4 pe-3 pe-md-0">
                    <div className="grCertifiedDocsLeftIn d-flex justify-content-center position-relative overflow-hidden w-100 h-100 text-center z-1">
                      <div className="traningCertiDocAnimate traningCertiDocAnimate-1 mt-n5 mx-n2 mb-0">
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2Ftrue%2FA002FB68-980C-2A58-62B4-4BE2BCC689B8165136311027131.jpg?alt=media&token=cfe10974-6818-449d-bdfa-6a62ae7d2828'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Abida Ashroff Fathima
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F9EB76057-6DAD-D540-D45A-7DE3DBF1E475%2F9EB76057-6DAD-D540-D45A-7DE3DBF1E475167341471649000.jpg?alt=media&token=11aea3f0-b22b-4f03-9a01-82cad2ff6b14'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Km Pooja Yadav
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F211050%2F211050167196062038798.jpg?alt=media&token=d3f2db81-f102-450b-b191-777ac7eb128f'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Kartik Batham
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="traningCertiDocAnimate traningCertiDocAnimate-2 mt-n5 mx-n2 mb-0">
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F4C89733F-81AE-4AC2-711E-F6653EFB22C1%2F4C89733F-81AE-4AC2-711E-F6653EFB22C1166799465088838.jpg?alt=media&token=90170a2a-e7cf-4534-a2a4-e6c85d39bb6d'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Avantika Gupta
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F8696B427-9461-2749-3A37-D52483A18B9D%2F8696B427-9461-2749-3A37-D52483A18B9D166715415896740.png?alt=media&token=32cd6f7b-8738-48c3-84f0-58eff6af1b42'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Anuj Nigam
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F263053%2F263053167170884626218.JPEG?alt=media&token=8d1cda1e-e364-44a3-9da9-e5f4029a6b5e'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Mahesh Kumar Barodia
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="traningCertiDocAnimate traningCertiDocAnimate-3 mt-n5 mx-n2 mb-0">
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2FDBDF6F94-E916-4F64-1DA4-EB5A09BA0BF1%2FDBDF6F94-E916-4F64-1DA4-EB5A09BA0BF1164387039422011.jpg?alt=media&token=75cfc0e8-c7'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Purvesh Rameshchandra Ramavat
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F725DE83B-48E4-EBAC-97AE-D0C1D86E888F%2F725DE83B-48E4-EBAC-97AE-D0C1D86E888F164251937961448.jpg?alt=media&token=d2447bfe-b1'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. FIROZ KHAN
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2Ftrue%2F506C95C8-6841-66D1-E9EB-FFD8AAFEC56E165171311023750.jpeg?alt=media&token=f3df67dd-0b33-4e99-849a-dc8f47f4a025'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Srikanth kadapa
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="traningCertiDocAnimate traningCertiDocAnimate-4 mt-n5 mx-n2 mb-0">
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2FFCB9D88C-D6DB-191F-38DE-D2CCA84F4F2C%2FFCB9D88C-D6DB-191F-38DE-D2CCA84F4F2C166585559086137.jpg?alt=media&token=9b4d940c-38ce-42af-85a4-9b36a2cfd64b'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Sathyaprabu V
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F201826%2F201826167455833763965.jpg?alt=media&token=d9690832-5828-42e5-87a2-cee18a10a68b'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. chandan samal
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F955A668A-A933-F977-1E13-96843DAA6590%2F955A668A-A933-F977-1E13-96843DAA6590166736667460129.jpg?alt=media&token=7a41a265-7d35-4971-bbc9-3273bda8c3bc'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Amol Ravindra Hartalkar
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="traningCertiDocAnimate traningCertiDocAnimate-5 mt-n5 mx-n2 mb-0">
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2FEA0A385D-B7BE-6C56-5600-16E2D60999B9%2FEA0A385D-B7BE-6C56-5600-16E2D60999B9166917326256958.jpg?alt=media&token=eeda141c-2b0c-4bf1-95e6-4e5490b6d085'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. K Aswathy Raveendran
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F5C1CA8AA-C50C-B54E-ADC0-4D355541B630%2F5C1CA8AA-C50C-B54E-ADC0-4D355541B630164550036193858.JPEG?alt=media&token=9b635b73-9'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Himmat singh Raghav
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                        <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                          <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                            <LazyImage
                              className="object-fit-cover w-100 h-100 float-start"
                              src={
                                'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F43B74BA0-31D9-4BF9-06D4-A3EF558BD053%2F43B74BA0-31D9-4BF9-06D4-A3EF558BD053166843473047526.jpg?alt=media&token=78976655-fcc4-47dd-a643-46396f5e6abc'
                              }
                            />
                          </div>
                          <div className="grCertifiedDocDetails">
                            <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                              Dr. Mohammed Mursaleen
                            </h4>
                            <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                            {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grCertifiedDocsRight bg-secondary d-flex align-items-center justify-content-center position-relative z-1 flex-grow-1 p-4 ps-4 p-md-20 ps-md-50">
                    <CertificateSharePageIcon />

                    <div className="grCertifiedDocsRightIn d-flex flex-column">
                      <CertificateBigIcon
                        customClass={'grCertifiedDocsRightIcon text-white mb-4 mx-auto'}
                      />
                      <h4 className="fs-30 fw-semibold text-white">12000</h4>
                      <span className="fs-16 fw-normal text-white">
                        Doctors get
                        <span className="fs-2 fw-light d-block">Certified till now!</span>
                      </span>
                      <div className="d-block mt-4">
                        <a
                          href="javascript:void(0)"
                          className="btn fw-normal text-white rippleEffect rounded-3 text-capitalize getCertifiedBtn py-3 px-4 text-nowrap"
                          onClick={focusToForm}
                        >
                          Get one now!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TrackerWrapper>

            <div className="col-xl-6 col-lg-12 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0">
              <TrackerWrapper
                type={CARD_VIEW}
                data={data}
                className="w-100 float-start h-100 text-start fs-5 disclaimer mb-5 rounded-3 bg-white shadow position-relative overflow-hidden p-4"
              >
                <h6 className="text-black fs-5 mb-3">Disclaimer</h6>
                {data?.disclaimer ? (
                  <p>{data?.disclaimer}</p>
                ) : (
                  <p>
                    All scientific content on the platform is provided for general medical education
                    purposes meant for registered medical practitioners only. The content is not
                    meant to substitute for the independent medical judgment of a physician relative
                    to diagnostic and treatment options of a specific patient’s medical condition.
                    In no event will CLIRNET be liable for any decision made or action taken in
                    reliance upon the information provided through this content.
                  </p>
                )}
              </TrackerWrapper>
            </div>
          </div>
        </div>
      )}

      {share_type !== 'epub' && share_type !== 'training' && (
        <TrackerWrapper
          type={CARD_VIEW}
          data={data}
          className="w-100 float-start text-start fs-5 disclaimer mb-5 rounded-3 bg-white shadow position-relative overflow-hidden p-4"
        >
          <h6 className="text-black fs-5 mb-3">Disclaimer</h6>
          {data?.disclaimer ? (
            <p>{data?.disclaimer}</p>
          ) : (
            <p>
              All scientific content on the platform is provided for general medical education
              purposes meant for registered medical practitioners only. The content is not meant to
              substitute for the independent medical judgment of a physician relative to diagnostic
              and treatment options of a specific patient’s medical condition. In no event will
              CLIRNET be liable for any decision made or action taken in reliance upon the
              information provided through this content.
            </p>
          )}
        </TrackerWrapper>
      )}
      {data && data.length > 0 && (
        <div className="w-100 float-start">
          <CardsArea data={data} isShare={data?.is_share} />
        </div>
      )}
    </div>
  );
};

export default memo(LeftContentBar);
