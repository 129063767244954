import React, { memo } from 'react';

function SeverityPill({ severity, className }) {
  const severityInLowerCase = severity?.toLowerCase();

  const interactionSeverityColor = {
    minor: 'success',
    moderate: 'warning',
    major: 'danger',
    default: 'primary'
  };
  const severityVariant =
    interactionSeverityColor[severityInLowerCase] || interactionSeverityColor.default;
  return (
    <span
      className={`px-4 py-1 border border-1 border-${severityVariant} fs-5 rounded-5 me-2 d-flex align-items-center text-uppercase text-${severityVariant} ${
        className || ''
      }`}
      style={{ width: 'fit-content' }}
    >
      <span
        className={`bg-${severityVariant} me-2 rounded-pill`}
        style={{ height: '1rem', width: '1rem' }}
      ></span>{' '}
      {severity}
    </span>
  );
}

export default memo(SeverityPill);
