import React, { memo } from 'react';
import { reactHtmlParser } from '../../../common/common';
import LazyImage from '../../CustomLibraries/LazyImage';
import { useNavigate } from 'react-router-dom';
import { routeNames } from '../../../router/constants';
import useRedirect from '../../customHooks/useRedirect';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { CARD_CLICK, CARD_VIEW } from '../../tracking/constants';
import TrackerWrapper from '../../tracking/TrackerWrapper';
const ChannelCard = ({
  type = '',
  className = '',
  image,
  title,
  description,
  channel_id,
  is_locked,
  price,
  user_content_payment,
  data
}) => {
  const navigate = useNavigate();
  const { redirectTo } = useRedirect();
  const { contentAccessRedirect } = useContentAccessRedirect();
  const { trackActivityEvent } = useTracker();
  const onChannelClick = (id) => {
    contentAccessRedirect(
      routeNames.channel.detail,
      id,
      title,
      is_locked,
      price,
      user_content_payment,
      "",
      data
    );
  };
  return (
    //   clr_v3_channelCard--sidebar

    <TrackerWrapper
      type={CARD_VIEW}
      data={data}
      className="d-flex gap-3 gap-md-4 border border-1 rounded-3 border-light p-3 clr_v3__channelCard clr_v3_channelCard--sidebar hover-opacity hover-image cursorPointer"
      onClick={() => {
        trackActivityEvent(CARD_CLICK, data);
        onChannelClick(channel_id);
      }}
    >
      <div className="d-flex align-items-start flex-shrink-0">
        <div className={`rounded-circle p-33 border border-primary border-105 bg-white`}>
          {image && (
            <div className="icon-size-36 icon-size-sm-48 overflow-hidden rounded-circle d-flex align-items-center justify-content-center p-2">
              <LazyImage
                src={image}
                alt={title}
                className={`mx-100 mw-100 h-auto w-auto`}
                extraClass="w-auto h-auto"
                logo
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center flex-grow-1">
        <div className="d-flex flex-column">
          {title && (
            <h2 className="fs-4 fw-semibold mb-1 text-black line-clamp-2">
              {reactHtmlParser(title)}
            </h2>
          )}
          {description && <p className="line-clamp-1">{reactHtmlParser(description)}</p>}
        </div>
      </div>
    </TrackerWrapper>
  );
};

export default memo(ChannelCard);
