import React, { useCallback, useState, useEffect, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../assets/sass/uiV3/pages/_specialitylanding.scss';
import '../assets/sass/uiV3/component/_header.scss';
import HeaderSpecialityDesktop from '../components/baseUiV3/template/header/HeaderSpecialityDesktop';
import HeaderSpecialityMobile from '../components/baseUiV3/template/header/HeaderSpecialityMobile';
import { isMobile } from 'react-device-detect';
import MobileFooterNav from '../components/baseUiV3/template/footer/MobileFooterNav';
import HeaderProfileMobile from '../components/baseUiV3/template/header/HeaderProfileMobile';
import { setLocalStorage } from '../common/common';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const SpecialityLayout = () => {
  const [elHeight, setElheight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);
  const [navItem, setNavItem] = useState('All');
  // const [exploreMenuOpen, setExploreMenu] = useState(false);
  const { pathname } = useLocation();
  const pageName = pathname.slice(1);
  // const exploreMenuToggler = () => {
  //   setExploreMenu(!exploreMenuOpen);
  // };
  const getElementHeight = (data) => {
    // alert(data);
    setElheight(data);
    setLocalStorage('SPECIALITY_HEADER_HEIGHT', data);
    // console.log('Show me the header height',data);
  };
  const getElementBottomHeight = (data) => {
    // alert(data)
    setBottomHeight(data);
  };
  const selectedNavItem = (data) => {
    setNavItem(data);
  };

  // useEffect(() => {
  //   setElheight(document.getElementById('headerDesktopNavBar')?.offsetHeight);
  // }, [document.getElementById('headerDesktopNavBar')?.offsetHeight]);

  return (
    <>
      {isMobile ? (
        pageName == 'UserProfile' ? (
          <HeaderProfileMobile getElementHeight={getElementHeight} />
        ) : (
          <HeaderSpecialityMobile
            getElementHeight={getElementHeight}
            selectedNavItem={selectedNavItem}
          />
        )
      ) : (
        <></>
      )}

      {!isMobile && <HeaderSpecialityDesktop getElementHeight={getElementHeight} />}
      <div
        className={`${isMobile ? 'mobile' : 'desktop'} speciality-portal w-100 d-flex`}
        style={{ paddingTop: `${!isMobile ? elHeight : 0}px`, paddingBottom: `${bottomHeight}px` }}
        id="main_outlet"
      >
        <div className="w-100 d-flex flex-column position-relative">
          <div className="container-fluid">
            <div className="row align-items-start flex-nowrap">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {pageName !== 'speciality' && (
        <MobileFooterNav
          getElementBottomHeight={getElementBottomHeight}
          // exploreMenuOpen={exploreMenuOpen}
          // exploreMenuToggler={exploreMenuToggler}
          height={elHeight}
        />
      )}
    </>
  );
};

SpecialityLayout.propTypes = propTypes;
// #endregion

export default memo(SpecialityLayout);
