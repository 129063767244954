import React from 'react';
import { useParams } from 'react-router-dom';
import FormArea from '../../../pages/shareLogin/Components/FormArea';
import { useSelector } from 'react-redux';
const ALLOWED_REGION = 'IN';
const ShareLogin = (props) => {
  const { service_type } = props;
  const userRegionState = useSelector((state) => state.share.UserRegion);
  const { id } = useParams();

  const isUserGlobal = userRegionState === 'GT' || userRegionState === 'GL';
  return (
    <>
      <div
        className={`w-100 text-start shareLogin rounded ${
          isUserGlobal ? 'p-0' : 'pt-5 px-4 pb-5'
        } shadow position-relative overflow-hidden d-flex justify-content-center align-items-center`}
      >
        <div className="rounded-circle bg-white bg-opacity-10 shareLoginCircle position-absolute"></div>
        <FormArea
          channel_id={id}
          id={id}
          consent_text={''}
          share_type={service_type}
          gtmTagVariable={''}
          page_id={id}
          className="w-100 shareLogin_In text-white position-relative z-1"
          contrastClass={props?.contrastClass ?? 'black'}
          focusRef={props?.focusRef}
          redirectType={props?.redirectType}
          to={props?.to}
          setBackdrop={props?.setBackdrop}
          pageName={props?.pageName}
          paramData={props?.paramData}
          type={props?.type}
          template_id={2}
          textClass="text-white"
        />
      </div>
    </>
  );
};

export default ShareLogin;
