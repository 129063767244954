import { useQuery } from 'react-query';
import {
  GET_CITY_LIST,
  GET_COUNTRY_LIST,
  GET_ISD_CODES,
  GET_STATE_LIST
} from '../QueryKeys/isdcodes.key';
import axiosInstance from '../../Store/helper/axiosInstance';
import axios from 'axios';
import axiosOpenInstance from '../../Store/helper/axiosOpenInstance';
const appUrl = process.env.REACT_APP_API_URL;

const staleTime = 300000;
const cacheTime = 600000;

export const useGetISDcodes = (defaultValue = null, onSuccess = () => {}) => {
  return useQuery(GET_ISD_CODES, () => axiosOpenInstance.get(appUrl + 'openapi/get_isd_code'), {
    select: (response) => {
      // console.log('isdCodes', response);
      // const myRegion = response.data.data.user_region;

      // const filterFactor = defaultValue ? defaultValue : myRegion;

      // const payloadData = response.data.data.isd_codes.map((_elem) => {
      //   if (_elem.region === filterFactor) {
      //     return { ..._elem, selected: true };
      //   } else {
      //     return { ..._elem, selected: false };
      //   }
      // });

      return response.data.data;
    },
    refetchOnWindowFocus: false,
    staleTime: staleTime,
    cacheTime: cacheTime,
    onSuccess: onSuccess
  });
};

export const useGetCountryList = (enabled = true) => {
  return useQuery(GET_COUNTRY_LIST, () => axiosOpenInstance.get(appUrl + 'openapi/country'), {
    select: (response) => {
      return response.data.data;
    },
    refetchOnWindowFocus: false,
    staleTime: staleTime,
    enabled: enabled,
    cacheTime: cacheTime
  });
};

export const useGetStateList = (country_id, enabled = true) => {
  console.log('country_id', country_id);
  return useQuery(
    [GET_STATE_LIST, country_id],
    () => axiosOpenInstance.get(appUrl + `openapi/state?country_id=${country_id}`),
    {
      select: (response) => {
        return response.data.data;
      },

      refetchOnWindowFocus: false,
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!country_id && enabled
    }
  );
};

export const useGetCityList = (state_id, enabled = true) => {
  return useQuery(
    [GET_CITY_LIST, state_id],
    () => axiosOpenInstance.get(appUrl + `openapi/city?state_id=${state_id}`),
    {
      select: (response) => {
        return response.data.data;
      },
      refetchOnWindowFocus: false,
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!state_id && enabled
    }
  );
};
