const pageTitle = {
  SESSION: 'SESSION'
};

export const activeStatusCode = 200;

// {
//   Loading ? (
//     <>skeleton</>
//   ) : status === activeStatusCode ? (
//    data?.length > 0 ? (
//       <Content/>
//     ) : (
//       <NoData sectionRemove={} />
//     )
//   ) : (
//     <InactiveComponent />
//   );
// }

// {
//   isLoading ? (
//     <>loader</>
//   ) : all_list_data &&
//     'pages' in all_list_data &&
//     Array.isArray(all_list_data?.pages) &&
//     all_list_data?.pages?.length > 0 ? (
//     all_list_data?.pages?.map((page) =>
//       page.status === activeStatusCode ? (
//         page?.data &&
//         page?.data?.data &&
//         Array.isArray(page?.data?.data) && page?.data?.data.length > 0 &&
//         page?.data?.data?.map((_all, ind) => <>contnet</>)
//       ) : (
//         <InactiveComponent statuscode={page?.status} />
//       )
//     )
//   ) : (
//     <NoDataFound sectionRemove={true} />
//   );
// }
