import React, { useEffect, useRef } from 'react';
import { ClirnetIconLogo } from '../../../icons';
import {
  getLocalStorage,
  isApp,
  isIosApp,
  isMobileApp,
  removeLocalStorage
} from '../../../../common/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useRedirect from '../../../customHooks/useRedirect';
import QueryString from 'query-string';
import { routeNames } from '../../../../router/constants';
import useGetParams from '../../../customHooks/useGetParams';
import LoaderLine from '../../../LoaderLine';
import { useTrackUserActivity } from '../../../customHooks/useTrackUserActivity';
const HeaderSpecialityDetailsMobile = ({ getElementHeight }) => {
  const location = useLocation();
  const { id, title } = useGetParams();
  const getHeight = useRef(0);
  const current_menu = useSelector((state) => state.common.current_menu);
  console.log('current_menu', current_menu);
  const isFromNotification = getLocalStorage('autoAuthFromNotification');
  const isFromAutoAuth = getLocalStorage('autoAuthFromAppUrlListener');
  const navigate = useNavigate();

  useEffect(() => {
    getElementHeight(getHeight?.current?.offsetHeight);
    window.addEventListener('resize', () => getElementHeight(getHeight?.current?.offsetHeight));
    return () => {
      window.removeEventListener('resize', () =>
        getElementHeight(getHeight?.current?.offsetHeight)
      );
    };
  }, [getHeight?.current?.offsetHeight]);

  const { redirectTo } = useRedirect();
  const redirectUrl = useSelector((state) => state.session.redirectUrl);
  function onExitClick() {
    if (redirectUrl?.startsWith(window.location.origin)) {
      let replacedNavigation = redirectUrl.replace(window.location.origin + '/#', '');
      redirectTo(replacedNavigation);
    } else {
      window.location.href = redirectUrl;
    }
  }

  const { userActivityTracker } = useTrackUserActivity();

  function sendTrackingData(to) {}
  const cancelClick = () => {
    if (redirectUrl) {
      onExitClick();
      return;
    }
    if (isMobileApp()) {
      if (isFromNotification) {
        navigate('/Notification');
        removeLocalStorage('autoAuthFromNotification');
        sendTrackingData('/Notification');
      } else if (isFromAutoAuth) {
        navigate(routeNames.dashboard.landing);
        removeLocalStorage('autoAuthFromAppUrlListener');
        sendTrackingData(routeNames.dashboard.landing);
      } else {
        if (location?.state?.from_login) {
          if (current_menu?.toLowerCase()?.includes('session')) {
            navigate(routeNames.sessions.landing);
            sendTrackingData(routeNames.sessions.landing);
          } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
            navigate(routeNames.medwiki.landing);
            sendTrackingData(routeNames.medwiki.landing);
          } else if (current_menu?.toLowerCase()?.includes('spq')) {
            navigate(routeNames.spq.landing);
            sendTrackingData(routeNames.spq.landing);
          } else if (current_menu?.toLowerCase()?.includes('clinical')) {
            navigate(routeNames.clinicalVideo.landing);
            sendTrackingData(routeNames.clinicalVideo.landing);
          } else if (current_menu?.toLowerCase()?.includes('gr')) {
            navigate(routeNames.grandRound.landing);
            sendTrackingData(routeNames.grandRound.landing);
          } else if (
            current_menu?.toLowerCase()?.includes('ebook') ||
            current_menu?.toLowerCase()?.includes('epaper')
          ) {
            navigate(routeNames.epaper.landing);
            sendTrackingData(routeNames.epaper.landing);
          } else if (current_menu?.toLowerCase()?.includes('channel')) {
            navigate(routeNames.channel.landing);
            sendTrackingData(routeNames.channel.landing);
          } else if (current_menu?.toLowerCase()?.includes('dnr')) {
            navigate(routeNames.discussRefer.landing);
            sendTrackingData(routeNames.discussRefer.landing);
          } else if (current_menu?.toLowerCase()?.includes('certificate')) {
            navigate(routeNames.certificate.landing);
            sendTrackingData(routeNames.certificate.landing);
          } else if (
            current_menu?.toLowerCase()?.includes('training') ||
            current_menu?.toLowerCase()?.includes('course')
          ) {
            navigate(routeNames.course.landing);
            sendTrackingData(routeNames.course.landing);
          } else if (current_menu?.toLowerCase()?.includes('observership')) {
            navigate(
              current_menu == 'observership'
                ? routeNames.dashboard.landing
                : routeNames.observership.landing
            );
            sendTrackingData(
              current_menu == 'observership'
                ? routeNames.dashboard.landing
                : routeNames.observership.landing
            );
          } else {
            navigate(routeNames.dashboard.landing);
            sendTrackingData(routeNames.dashboard.landing);
          }
        } else {
          if (location.pathname?.toLowerCase().includes('livesession')) {
            redirectTo(routeNames.sessions.detail, id, title);
            sendTrackingData(routeNames.sessions.detail);
          } else if (current_menu?.toLowerCase()?.includes('session')) {
            navigate(routeNames.sessions.landing);
            sendTrackingData(routeNames.sessions.landing);
          } else if (current_menu?.toLowerCase()?.includes('observership')) {
            navigate(
              current_menu == 'observership'
                ? routeNames.dashboard.landing
                : routeNames.observership.landing
            );
            sendTrackingData(
              current_menu == 'observership'
                ? routeNames.dashboard.landing
                : routeNames.observership.landing
            );
          } else {
            navigate(-1);
            sendTrackingData(-1);
          }
        }
      }
    } else if (location?.state?.from_login) {
      if (current_menu?.toLowerCase()?.includes('session')) {
        navigate(routeNames.sessions.landing);
        sendTrackingData(routeNames.sessions.landing);
      } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
        navigate(routeNames.medwiki.landing);
        sendTrackingData(routeNames.medwiki.landing);
      } else if (current_menu?.toLowerCase()?.includes('spq')) {
        navigate(routeNames.spq.landing);
        sendTrackingData(routeNames.spq.landing);
      } else if (current_menu?.toLowerCase()?.includes('clinical')) {
        navigate(routeNames.clinicalVideo.landing);
        sendTrackingData(routeNames.clinicalVideo.landing);
      } else if (current_menu?.toLowerCase()?.includes('gr')) {
        navigate();
      } else if (
        current_menu?.toLowerCase()?.includes('ebook') ||
        current_menu?.toLowerCase()?.includes('epaper')
      ) {
        navigate(routeNames.epaper.landing);
        sendTrackingData(routeNames.epaper.landing);
      } else if (current_menu?.toLowerCase()?.includes('channel')) {
        navigate(routeNames.channel.landing);
        sendTrackingData(routeNames.channel.landing);
      } else if (current_menu?.toLowerCase()?.includes('dnr')) {
        navigate(routeNames.discussRefer.landing);
        sendTrackingData(routeNames.discussRefer.landing);
      } else if (current_menu?.toLowerCase()?.includes('certificate')) {
        navigate(routeNames.certificate.landing);
        sendTrackingData(routeNames.certificate.landing);
      } else if (
        current_menu?.toLowerCase()?.includes('training') ||
        current_menu?.toLowerCase()?.includes('course')
      ) {
        navigate(routeNames.course.landing);
        sendTrackingData(routeNames.course.landing);
      } else if (current_menu?.toLowerCase()?.includes('observership')) {
        navigate(
          current_menu == 'observership'
            ? routeNames.dashboard.landing
            : routeNames.observership.landing
        );
        sendTrackingData(
          current_menu == 'observership'
            ? routeNames.dashboard.landing
            : routeNames.observership.landing
        );
      } else {
        navigate(routeNames.dashboard.landing);
        sendTrackingData(routeNames.dashboard.landing);
      }
    } else {
      if (location.pathname?.toLowerCase().includes('livesession')) {
        redirectTo(routeNames.sessions.detail, id, title);
        sendTrackingData(routeNames.sessions.detail);
      } else if (current_menu?.toLowerCase()?.includes('session')) {
        navigate(routeNames.sessions.landing);
        sendTrackingData(routeNames.sessions.landing);
      } else if (current_menu?.toLowerCase()?.includes('observership')) {
        navigate(
          current_menu == 'observership'
            ? routeNames.dashboard.landing
            : routeNames.observership.landing
        );
        sendTrackingData(
          current_menu == 'observership'
            ? routeNames.dashboard.landing
            : routeNames.observership.landing
        );
      } else {
        navigate(-1);
        sendTrackingData('-11');
      }
    }
  };

  const HandleAppReload = () => {
    window.location.reload();
  };

  return (
    <nav
      className="clr_v3_Header flex-column position-fixed text-black w-100 top-0 p-sm-4 p-3 backdrop-filter bg-white bg-opacity-50 d-lg-none d-flex"
      //   ref={headerHeightRef}
      ref={getHeight}
      id="headerDesktopNavBar"
    >
      <LoaderLine />
      {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100"></div> : <></>}
      <div className="d-flex justify-content-between align-items-center ">
        <div
          className="clr_v3_Header__brand icon-size-sm-50 icon-size-36 me-auto bg-white d-flex align-items-center justify-content-center rounded-3"
          onClick={() => navigate(routeNames.dashboard.landing)}
        >
          <ClirnetIconLogo />
        </div>
        <div className="d-flex align-items-center gap-4">
          {isMobileApp() && (
            <a
              href="javascript:void(0)"
              className="bg-light ratio-1 rounded-circle p-2 text-black"
              style={{ '--bs-bg-opacity': '.9' }}
              onClick={HandleAppReload}
            >
              <i className="flaticon-undo fs-30"></i>
            </a>
          )}

          <span
            className={`d-inline-block rounded-circle rippleEffect icon-size-32 d-flex align-items-center justify-content-center bg-black bg-opacity-25 text-white ${
              isMobileApp() ? 'p-4' : ''
            }`}
            onClick={() => cancelClick()}
          >
            <i className="flaticon flaticon-close fs-5"></i>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default HeaderSpecialityDetailsMobile;
