import TagManager from 'react-gtm-module';
import { getLocalStorage } from '../common/common';

export const generateDataLayerGtm = (page) => {
  const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
  TagManager.dataLayer({
    dataLayer: {
      userId,
      page: `CLIRNET - ${page}`
    },
    dataLayerName: 'CLIRNET_REACT_WEB'
  });
};
