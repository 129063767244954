import React from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
export function Skeleton({ variant, width, height, className = '', skeletonStyle = null }) {
  const getLastChar = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(str.length - 1);
  };
  const styles = {
    width: getLastChar(width) === '%' ? width : `${width}px`,
    height: getLastChar(height) === '%' ? height : `${height}px`
  };
  const radiusClassname =
    variant === 'circular' ? 'rounded-circle' : variant == 'text' ? 'rounded-3' : '';
  return (
    <Placeholder
      as={'div'}
      className={`${
        className + (radiusClassname ? ' ' + radiusClassname : '')
      } overflow-hidden shineEffect ${variant == 'text' ? 'my-1' : ''}`}
      style={skeletonStyle ? skeletonStyle : styles}
    ></Placeholder>
  );
}
