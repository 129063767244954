import dayjs from 'dayjs';
import { safeJsonParser } from '../../../utils/common';
const prodMode = process.env.NODE_ENV === 'production';

export const log = (data) => console.log(`BANNER::${data}`);
export const setDataOnLocalStorage = (key = '', value = '') => {
  key && value && localStorage.setItem(`@ClirnetStore:${key}`, value);
};

export function getDataFromLocalStorage(key = '') {
  return key && localStorage.getItem(`@ClirnetStore:${key}`, true);
}

export function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return safeJsonParser(stored, defaultValue);
}
export const getCurrentTime = (_format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).format(_format);
};

export const asyncLocalStorage = {
  setItem: async (key, value) => {
    await Promise.resolve().then(() => {
      return sessionStorage.setItem(
        `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`,
        value
      );
    });
  },
  getItem: async (key, value) => {
    await Promise.resolve().then(() => {
      return sessionStorage.setItem(
        `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`,
        value
      );
    });
  }
};

export function getTimeDifference(from) {
  let now = dayjs(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'); //todays date
  if (now == from) {
    return 0;
  }
  let duration = dayjs(now).diff(dayjs(from), 'second');
  return duration;
}

// export const filterJsonByPosition = (data = [], matching_value = '') => {
//   let isBetween = require('dayjs/plugin/isBetween');
//   dayjs.extend(isBetween);
//   return data.filter(
//     (x) =>
//       (x.position == matching_value &&
//         dayjs(dayjs(new Date())).isBetween(x.start_date, dayjs(x.end_date), 's') &&
//         x.target_type == 'Filler') ||
//       (x.max_view_per_session >= 0 &&
//         x.max_view_per_user_campaign < x.max_view_per_campaign &&
//         x.total_view_done_per_day_campaign < x.view_per_day &&
//         x.view_per_day > 0)
//   );
// };
export const filterJsonByPosition = (data = [], matching_value = '') => {
  // let isBetween = require('dayjs/plugin/isBetween');
  // dayjs.extend(isBetween);

  // x.max_view_per_user_campaign < x.max_view_per_campaign &&

  // dayjs(dayjs(new Date())).isBetween(x.start_date, dayjs(x.end_date), 's') &&
  console.log('filtering json' + matching_value + '::' + data?.length);
  return data.filter((x) => {
    return (
      x.position === matching_value &&
      (x.target_type === 'Filler' ||
        (x.max_view_per_session >= 0 &&
          x.bal_view_per_user_per_camp > 0 &&
          x.total_view_done_per_day_campaign < x.view_per_day &&
          x.view_per_day > 0))
    );
  });
};
export const filterExclutionListByType = (data = [], matching_value = '') => {
  return data.filter((x) => x?.type?.toLowerCase() == matching_value.toLowerCase());
};

export function filterJsonByTargetType(data = [], matching_value = '') {
  return data.filter((x) => x.target_type == matching_value);
}

// export const sortByTargeViews = (data = []) => {
//   return data.sort((a, b) => parseInt(b.view_per_day) - parseInt(a.view_per_day));
// };

export const sortByTargeViews = (data = []) => {
  // return data.sort((a, b) => parseInt(b.view_per_day) - parseInt(a.view_per_day));
  return data.sort((a, b) => parseInt(b.ad_factor) - parseInt(a.ad_factor));
};

export function sortBannerByType(data = []) {
  const order = ['Fixed', 'Floating', 'Filler'];
  data.sort((x, y) => order.indexOf(x.target_type) - order.indexOf(y.target_type));
  return data;
}

export function _isContains(json, keyname, value) {
  return Object.keys(json).some((key) => {
    return typeof json[key] === 'object'
      ? _isContains(json[key], keyname, value)
      : key === keyname && json[key] === value;
  });
}

export const getBannerReportFormat = () => ({
  type: '',
  type_id: '',
  banner_user: '',
  campaign_id: '',
  creative_id: '',
  duration: '',
  end_time: '',
  position: '',
  os: '',
  start_time: '',
  timestamp_get: ''
  // target_type: "",
  // target_views: "",
});

export const getPlatForm = () => {
  var OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
  if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
  if (navigator.appVersion.indexOf('IOS') != -1) OSName = 'IOS';
  if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
  if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
  if (navigator.appVersion.indexOf('Android') != -1) OSName = 'Android';
  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //   var device_type = 'Mobile';
  // } else {
  //   var device_type = 'PC';
  // }
  return OSName;
};

export class Countdown {
  constructor(options) {
    var timer,
      instance = this,
      seconds = options.seconds || 10,
      updateStatus = options.onUpdateStatus || function () {},
      counterEnd = options.onCounterEnd || function () {};

    function decrementCounter() {
      updateStatus(seconds);
      if (seconds === 0) {
        counterEnd();
        instance.stop();
      }
      seconds--;
    }

    this.start = function () {
      clearInterval(timer);
      timer = 0;
      seconds = options.seconds;
      timer = setInterval(decrementCounter, 1000);
    };

    this.stop = function () {
      clearInterval(timer);
    };
  }
}

export const openNewTab = (url) => {
  let win = window.open(url, '_blank');
  console.log('opening new tab -------------------------------------------------------');
  if (win) {
    win.location;
  } else {
    window.location.href = url;
  }
};

export const getSettingByModule = (data = [], matching_value = '') => {
  return data.filter((x) => {
    // console.log(x.module.toLowerCase() == matching_value.toLowerCase() + 'checking' + x.module);
    return x?.module?.toLowerCase() == matching_value?.toLowerCase();
  });
};
