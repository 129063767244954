import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { generateTitle, getRedirectType } from '../../../common/common';
import useManualScrollRestoration from '../../../components/customHooks/useManualScrollRestoration';
import { generateDataLayerGtm } from '../../../helper/generateDataLayerGtm';
import { shareActions } from '../../../Store/actions/shareActions';
import { shareConstants } from '../../../Store/constants/shareConstants';
import { TrackerComponent } from '../../../TrackerFolder';

import { useParams } from 'react-router-dom';
import BackdropComponent from '../../../components/BackdropComponent';
import useRedirect from '../../../components/customHooks/useRedirect';
import { Seo } from '../../../components/seo/Seo';
import NewShareSkeleton from '../../../skeleton/shareSkeleton/NewShareSkeleton';
import { getOpenApiDetails } from '../../../Store/actions/training.action';
import ShareLoginNew from '../../shareLogin/ShareLoginNew';

const ShareTemplate1 = ({ serviceName, constantSuccess, constantFaliure, serviceType }) => {
  const pageName = 'test';
  const { id, utm } = useParams();

  const [loader, setLoader] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const [logo, setLogo] = useState([]);

  const dispatch = useDispatch();

  const [specs, setSpecs] = useState([]);
  const [traingDetail, setTrainingDetail] = useState({});

  const { redirectTo } = useRedirect();
  const [channel_info, setChannelInfo] = useState(null);
  const details = useSelector((state) => state.share.details);

  const relatedData = useSelector((state) => state.share.relatedData);
  const focusRef = useRef(null);
  useManualScrollRestoration();

  console.log('toppppppper', details);
  useEffect(() => {
    setLoader(true);
    generateDataLayerGtm(pageName);
    generateTitle(pageName);

    const handleShareResponse = (response, mentorId = null) => {
      if (response === 'error') {
        redirectTo('/Unavailable');
        setLoader(false);
        return;
      }

      if (!response?.data) {
        redirectTo('/Unavailable');
        setLoader(false);
        return;
      }

      if (serviceName === 'observer' && mentorId === null) {
        // For observer service, fetch mentor data
        const mentorId = response?.data?.observer?.mentor_id;
        dispatch(
          shareActions(
            'mentor',
            mentorId,
            shareConstants.GET_OBSERVERSHIP_MENTOR_SHARE_SUCCESS,
            shareConstants.GET_OBSERVERSHIP_MENTOR_SHARE_FALIURE,
            '',
            '',
            '',
            '',
            (mentorResponse) => {
              if (mentorResponse !== 'error') {
                setLoader(false);
              }
            }
          )
        );
      } else {
        setLoader(false);
      }
    };

    const handleTrainingData = (res) => {
      if (res === 'error' || !res?.training?.id) {
        redirectTo('/Unavailable');
        setLoader(false);
        return;
      }
      setTrainingDetail(res?.training);
      setLoader(false);
    };

    // Handle different service types
    if (serviceName === 'differential_diagnosis') {
      setLoader(false);
    } else if (serviceName === 'training') {
      dispatch(getOpenApiDetails(id, handleTrainingData));
    } else if (serviceName === 'doctube') {
      setLoader(false);
    } else {
      // For all other services
      dispatch(
        shareActions(
          serviceName,
          id,
          constantSuccess,
          constantFaliure,
          '',
          '',
          '',
          '',
          handleShareResponse
        )
      );
    }

    window.document.title = 'CLIRNET - Medwiki Share';
  }, [id, serviceName, pageName, dispatch]);

  const focusInput = () => {
    focusRef?.current && focusRef?.current?.focus();
  };

  return (
    <>
      <TrackerComponent page_name={pageName} page_id={id} module_page="share" />
      <Seo
        title={`CLIRNET - ${pageName}`}
        description={details?.answer?.substring(0, 150)}
        image={details?.image}
        url={window.location.href}
      />
      <div
        className={
          isMobile ? 'w-100 mblscreen sharePageBody' : 'w-100 dskScreen desktopBody sharePageBody'
        }
      >
        {loader && !!details ? (
          // <Skeleton variant="rectangular" width="100%" height={300} />
          <NewShareSkeleton />
        ) : (
          <ShareLoginNew
            heroCardData={serviceName === 'training' ? traingDetail : details || {}}
            relatedData={relatedData || []}
            type={serviceName}
            page_id={id}
            channel_id={details?.channel?.channel_id}
            channel_name={serviceName === 'channel' ? details?.title : details?.channel?.title}
            channel_logo={serviceName === 'channel' ? details?.logo : details?.channel?.logo}
            serviceType={serviceType}
            redirectType={getRedirectType(serviceName, serviceType)}
          />
        )}
      </div>
      <BackdropComponent backdrop={backdrop} />
    </>
  );
};

export default ShareTemplate1;
