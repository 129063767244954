import React, { memo } from 'react';

import DdiIntro from '../../uiv3/share/components/DdiIntro';
import DdiResultShare from './DdiResultShare';

const DdiHeroCard = ({ id, data, isIntro, isResult }) => {
  return (
    <>
      {isIntro && <DdiIntro />}
      {isResult && <DdiResultShare data={data} id={id} />}
    </>
  );
};

export default memo(DdiHeroCard);
