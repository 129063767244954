import React, { memo, useState } from 'react';
import '../../../assets/sass/uiV3/pages/auth/_auth.scss';
import { log } from '../../../common/common';
import ChannelHeroCard from './ChannelHeroCard';
import ClirnetIntro from './ClirnetIntro';
import ClVideoHeroCard from './ClVideoHeroCard';
import EbookHeroCard from './EbookHeroCard';
import FormArea from './FormArea';
import TrainingHeroCard from './TrainingHeroCard';
import MedwikiHeroCard from './MedwikiHeroCard';
import SessionHeroCard from './SessionHeroCard';
import SurveyHeroCard from './SurveyHeroCard';
import PollHeroCard from './PollHeroCard';
import DoctubeHeroCard from './DoctubeHeroCard';
import DiagnosisHeroCard from './DiagnosisHeroCard';
import DdiHeroCard from './DdiHeroCard';
import ObservershipHeroCard from '../../uiv3/share/components/ObservershipHeroCard';
import MentorHeroCard from '../../uiv3/share/components/MentorHeroCard';
import ClaimMentorSection from '../../uiv3/share/components/ClaimMentorSection';
import GRHeroCard from './GRHeroCard';
import AidaHistoryShareDetails from '../../aida/components/AidaHistoryShareDetails';
import AidaContainer from '../../aida';
import { ClirnetIconLogo } from '../../../components/icons';
const HeroSectionCard = ({ share_type, heroCardData, channel_name, channel_logo, page_id }) => {
  switch (share_type) {
    case 'comp':
      return (
        <MedwikiHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'session':
      return (
        <SessionHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'training':
      return (
        <TrainingHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'survey':
      return (
        <SurveyHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'archived_video':
      return (
        <ClVideoHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'channel':
      return (
        <ChannelHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'epub':
      console.log('EBOOK FOUND');
      return (
        <EbookHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'doctube':
    case 'doctube_fst':
      return <DoctubeHeroCard gtmTagVariable={`share_${share_type}`} />;
    case 'differential_diagnosis':
      return <AidaContainer share={true} />;
    case 'ddi':
      return <DdiHeroCard id={page_id} isIntro={true} data={heroCardData} />;
    case 'poll':
      return (
        <PollHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );
    case 'observership':
      return (
        <ObservershipHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          observership_name={channel_name}
          // observership_logo={observership_logo}
        />
      );
    case 'mentor':
      return <MentorHeroCard gtmTagVariable={`share_${share_type}`} data={heroCardData} />;
    case 'claim_mentorship':
      return <ClaimMentorSection data={heroCardData} />;
    case 'gr':
      return (
        <GRHeroCard
          gtmTagVariable={`share_${share_type}`}
          data={heroCardData}
          channel_name={channel_name}
          channel_logo={channel_logo}
        />
      );

    default:
      <></>;
  }
};
const RightLoginBar = ({
  channel_id,
  heroCardData,
  consent_text = '',
  share_type,
  page_id,
  channel_name,
  channel_logo,
  redirectType
}) => {
  log('CHANNEL_ID FROM NEW SHARE', channel_id, share_type);
  console.log(
    'hellllllo',
    // channel_id,
    heroCardData
    // consent_text,
    // share_type,
    // page_id,
    // channel_name,
    // channel_logo,
    // redirectType
  );
  return (
    <>
      <div className="shareLoginV2Right position-relative mb-20 mb-lg-0">
        <div className="shareLoginV2Frm d-flex align-items-center w-100 position-sticky top-0 w-100 bg-white shadow z-2 min-vh-100">
          <div className="w-100 float-start shareLoginV2_In text-black text-start position-relative z-1 py-3 px-4 py-lg-20 px-lg-4 px-xl-5 py-xl-5 d-flex flex-column">
            {channel_name && channel_logo ? (
              <ClirnetIntro channel_name={channel_name} channel_logo={channel_logo} />
            ) : (
              <div className="mb-md-20 mb-4 text-center px-4">
                <ClirnetIconLogo className={'clr_v3_auth__IconLogo mb-3'} />
                <h1 className="fw-semibold fs-2 mb-2">Explore CLIRNET</h1>
                <p>This platform is meant for Registered Medical Practitioners only.</p>
              </div>
            )}
            <div className="w-100 float-start d-block d-lg-none shareLoginV2MainCardArea mb-20">
              <HeroSectionCard
                share_type={share_type}
                channel_logo={channel_logo}
                channel_name={channel_name}
                heroCardData={heroCardData}
                page_id={page_id}
                key={page_id + share_type}
              />
            </div>
            <FormArea
              hideHeader={channel_name && channel_logo ? false : true}
              channel_id={channel_id}
              consent_text={consent_text}
              share_type={share_type == 'doctube_fst' ? 'doctube' : share_type}
              gtmTagVariable={`share_${share_type}`}
              page_id={page_id}
              className="forMbl_shareLoginV2Form w-100 float-start py-20 px-4 py-lg-0 px-lg-0 bg-white rounded-3"
              contrastClass="dark"
              template_id={1}
              redirectType={redirectType}
              id={page_id}
            />
            {channel_name && channel_logo ? (
              <>
                <div class="d-flex align-items-center justify-content-center mt-5 flex-column text-lg-end text-start pb-5">
                  {/* <div className="ms-lg-auto me-lg-0 me-auto"> */}
                    <span className="d-block pb-2">Powered by</span>
                    <img
                      className="w-25 h-auto"
                      src="https://storage.googleapis.com/medwiki/43_server/images/1687355053_logo.png"
                    />
                  {/* </div> */}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>{' '}
      {share_type === 'ddi' && (
        <div className="w-100 float-start d-block d-lg-none mb-4 px-3 pt-3">
          <DdiHeroCard id={page_id} isResult={true} data={heroCardData} />
        </div>
      )}
    </>
  );
};

export default memo(RightLoginBar);
