import React from 'react';
import ChannelCard from '../../../../components/baseUiV3/cards/ChannelCard';

import { useUserChannelList } from '../../../../Queries/QueryHooks/channel.hook';
import ChannelCardSkeleton from '../../../../skeleton/channelSkeleton/ChannelCardSkeleton';
import useRedirect from '../../../../components/customHooks/useRedirect';
import { routeNames } from '../../../../router/constants';
import { activeStatusCode } from '../../../../utils/constants/common.constant';
import InactiveComponent from '../../../../components/InactiveComponent';
import NoDataFound from '../../../../components/NoDataFound';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';
import { MORE_CLICK } from '../../../../components/tracking/constants';

const FollowChannelSection = () => {
  const { redirectTo } = useRedirect();
  const { data: userChannel, isLoading: loader } = useUserChannelList();
  const { trackActivityEvent } = useTracker();
  const onViewAllClick = () => {
    trackActivityEvent(MORE_CLICK, {});
    redirectTo(routeNames.channel.manage);
  };

  return (
    <>
      {loader ? (
        <>
          <ChannelCardSkeleton />
          <ChannelCardSkeleton />
          <ChannelCardSkeleton />
          <ChannelCardSkeleton />
        </>
      ) : userChannel && userChannel?.statusCode === activeStatusCode ? (
        userChannel &&
        userChannel?.data &&
        Array.isArray(userChannel?.data) &&
        userChannel?.data?.length > 0 ? (
          <div className="d-flex flex-column gap-3 mt-3">
            <h2 className="fs-20 text-black fw-semibold">Followed Channel</h2>
            <div className="d-flex flex-column gap-3">
              {userChannel?.data.map((key, index) => (
                <ChannelCard
                  title={key.title}
                  image={key.logo}
                  description={key.description}
                  channel_id={key.type_id}
                  key={index + 1}
                />
              ))}
            </div>
            <button
              className="btn rounded-pill btn-outline-primary border-105 fw-semibold"
              onClick={onViewAllClick}
            >
              View All
            </button>
          </div>
        ) : (
          <NoDataFound sectionRemove={true} />
        )
      ) : (
        <InactiveComponent statuscode={userChannel && userChannel?.statusCode} />
      )}
    </>
  );
};

export default React.memo(FollowChannelSection);
