import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import AuthorSection from '../../../components/DoctorSectionForCard/AuthorSection';
import DoctorsSectionForCard from '../../../components/DoctorSectionForCard/DoctorsSectionForCard';
import { MdOutlineArrowRightAlt } from '../../../components/icons';
import { toggleReference } from '../../../Store/actions';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';

const EbookHeroCard = ({ data, channel_logo, channel_name, gtmTagVariable = '' }) => {
  const { image, author_entities, title } = data || {};
  const dispatch = useDispatch();
  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={data}
      className="row ebookShareV2 m-0 mt-lg-n20 ms-lg-n20 gap-4"
    >
      <div className="col-xxl-12 col-lg-12 col-12 ebookShareV2Card p-0 pt-lg-20 ps-lg-20">
        <div className="w-100 float-start rounded-3 shadow overflow-hidden bg-white shareLoginV2Featured shareLoginV2Ebook h-100 p-3 p-md-4 position-relative z-1">
          <div className="shareLoginV2FeaturedIn d-flex flex-column flex-sm-row align-items-center">
            <div
              className="shareLoginV2FeaturedCircle position-absolute rounded-circle"
              style={{ backgroundColor: '#355fd3' }}
            ></div>
            <div className="shareLoginV2FeaturedPic position-relative h-auto">
              <div className="shareLoginV2FeaturedPicIn rounded-3 position-relative overflow-hidden z-1">
                <LazyImage src={image} className="object-fit-cover w-100 h-100 float-start" />
              </div>
            </div>
            <div className="shareLoginV2FeaturedRight text-start ps-0 ps-md-20 mt-4 mt-md-0">
              <div className="d-flex justify-content-between mb-4">
                <div className="shareLogineV2Doctor w-100">
                  <div className="shareLoginV2FeaturedChannel d-flex align-items-center position-relative">
                    <div className="rounded-circle shareLoginV2ChanlPic d-flex align-items-center justify-content-center bg-light position-absolute top-0 start-0">
                      <div className="d-flex align-items-center justify-content-center">
                        <LazyImage
                          src={channel_logo}
                          alt="Profile Image"
                          className="mw-100 mh-100 w-auto h-auto"
                          logo
                        />{' '}
                      </div>
                    </div>
                    <span className="d-block fs-4 text-black">{channel_name}</span>
                  </div>
                </div>
              </div>
              <h2 className="fs-3 fw-medium text-black text-uppercase mb-3">{title}</h2>
              {author_entities && author_entities?.length > 0 && (
                <>
                  <h4 className="line-clamp-2 fs-16 fw-semibold text-dark">Authors</h4>
                  <div className="d-flex justify-content-between mt-4">
                    <div className="shareLogineV2Doctor w-100">
                      <AuthorSection cardData={{ author_entities }} />
                    </div>
                  </div>
                </>
              )}

              <div
                className={`readNowBtn w-100 float-start d-flex align-items-center justify-content-start fs-16 fw-medium text-primary cursorPointer position-relative ${gtmTagVariable}_ebookHeroCard_read_more`}
                onClick={() => dispatch(toggleReference(true))}
              >
                <div className="readNowBtnGraph bg-secondary bg-opacity-10 rounded-circle"></div>
                <span className="readNowBtnText ms-n20 d-flex align-items-center gap-2">
                  Read Now
                  <MdOutlineArrowRightAlt
                    customClass={`fs-4 text-primary ps-2 ${gtmTagVariable}_read_more_icon`}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-12 col-lg-12 col-12  ebookShareV2Card p-0 pt-lg-20 ps-lg-20">
        <div className="w-100 float-start text-start fs-5 disclaimer rounded-3 bg-white shadow h-100 position-relative overflow-hidden p-4">
          <h6 className="text-black fs-5 mb-3">Disclaimer</h6>
          {data?.disclaimer ? (
            <p>{data?.disclaimer}</p>
          ) : (
            <p>
              All scientific content on the platform is provided for general medical education
              purposes meant for registered medical practitioners only. The content is not meant to
              substitute for the independent medical judgment of a physician relative to diagnostic
              and treatment options of a specific patient's medical condition. In no event will
              CLIRNET be liable for any decision made or action taken in reliance upon the
              information provided through this content.
            </p>
          )}
        </div>
      </div>
    </TrackerWrapper>
  );
};

export default memo(EbookHeroCard);
