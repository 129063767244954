export const SPQ_FEATURED = 'SPQ_FEATURED';
export const POLL_FEATURED = 'POLL_FEATURED';
export const QUIZ_FEATURED = 'QUIZ_FEATURED';
export const SURVEY_FEATURED = 'SURVEY_FEATURED';
export const SPQ_RECENT = 'SPQ_RECENT';

export const SPQ_DETAILS = 'SPQ_DETAILS';
export const POLL_LIST = 'POLL_LIST';
export const QUIZ_LIST = 'QUIZ_LIST';
export const SURVEY_LIST = 'SURVEY_LIST';
export const ALL_SPQ_LIST = 'ALL_SPQ_LIST';

export const POLL_COMPLETED_LIST = 'POLL_COMPLETED_LIST';
export const QUIZ_COMPLETED_LIST = 'QUIZ_COMPLETED_LIST';
export const SURVEY_COMPLETED_LIST = 'SURVEY_COMPLETED_LIST';
export const ALL_SPQ_COMPLETED_LIST = 'ALL_SPQ_COMPLETED_LIST';

export const SPECIALITY_SURVEY = 'SPECIALITY_SURVEY';

export const LEADERBOARD_DETAILS = 'LEADERBOARD_DETAILS';
export const SURVEY_RELATED_LIST = 'SURVEY_RELATED_LIST';
