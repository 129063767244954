import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isMobileApp } from '../common/common';
import { Share } from '@capacitor/share';
import { useTracker } from '../TrackerFolder/TrackerComponent';
import { SHARE_CLICK } from './tracking/constants';

function ShareDetailsPage({ data, customClass = '', shareClick, id = '', fullData }) {
  const { is_share = true } = data;
  const [shareData, setShareData] = useState({});
  const [extraCustomClass, setextraCustomClass] = useState('');
  const { trackActivityEvent } = useTracker();
  useEffect(() => {
    let tempData = {};
    tempData = {
      title: data.title,
      // text: data.text,
      url: data.url
    };
    setShareData(tempData);

    // if (customClass) {
    //   setextraCustomClass(customClass);
    // }
  }, [data]);

  const share_url = async () => {
    // console.log(shareData); if (isMobileApp()) {
    if (isMobileApp()) {
      await Share.share(shareData);
    } else {
      if (navigator.canShare) {
        try {
          await navigator.share(shareData);
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.info('Copied to clipboard');
        copy_to_Clipboard(shareData.url);
      }
    }
  };
  const copy_to_Clipboard = (str) => {
    let textarea;
    let result;
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = str;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, str); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }

    return true;
  };
  // className={extraCustomClass} //extra class
  // position-absolute start-0 top-50 translate-middle-y

  const isShareable = typeof is_share === 'boolean' ? is_share : is_share == '1' ? true : false;

  if (!isShareable) return null;
  return (
    <>
      <i
        id={id}
        // className={`fs-2 lineIcon flaticon-share-arrow position-absolute start-0 top-50 translate-middle-y gtm_cl_share_detail_page cursorPointer ${
        //   clinical ? '' : 'text-white'
        // }`}
        className={`fs-2 lineIcon flaticon-share-arrow gtm_cl_share_detail_page cursorPointer ${
          customClass ? customClass : ''
        }`}
        onClick={() => {
          trackActivityEvent(SHARE_CLICK, fullData);
          share_url();
          shareClick && shareClick();
        }}
      ></i>
    </>
  );
}
export default memo(ShareDetailsPage);
