import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useManualScrollRestoration from '../../../components/customHooks/useManualScrollRestoration';
import { useDispatch, useSelector } from 'react-redux';
import { generateDataLayerGtm } from '../../../helper/generateDataLayerGtm';
import {
  DEMO_USER_PICTURE,
  generateTitle,
  getRedirectType,
  getSessionCategoryIcon,
  reactHtmlParser,
  setLocalStorage,
  specialityPopOver,
  timeDifference
} from '../../../common/common';
import { saveUtm, shareActions } from '../../../Store/actions/shareActions';
import { shareConstants } from '../../../Store/constants/shareConstants';
import { TrackerComponent } from '../../../TrackerFolder';

import ShareHeader from '../shareHeader';
import ShareHeaderNew from '../../share/shareHeader';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import {
  medwiki_static_image,
  session_static_image,
  surveys_static_image
} from '../../../common/assets.common';
import { Swiper, SwiperSlide } from 'swiper/react';
import ShareLogin from '../../../common/LoginForAll/login/Login';
import { routeNames } from '../../../router/constants';
import Disclaimer from '../components/disclaimer/Disclaimer';
import Masonry from 'react-masonry-component';
import NewRelatedCardsArea from '../components/NewRelatedCardsArea';
import ShareLoginNew from '../../shareLogin/ShareLoginNew';
import ShareSkeleton from '../../../skeleton/shareSkeleton/shareSkeleton';
import BackdropComponent from '../../../components/BackdropComponent';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useRedirect from '../../../components/customHooks/useRedirect';
import { Seo } from '../../../components/seo/Seo';
import DoctorSlide from '../../../components/DoctorSlide/DoctorSlide';
import dayjs from 'dayjs';
import DoctorsSectionForCard from '../../../components/DoctorSectionForCard/DoctorsSectionForCard';
import SponsorLogoSwiper from '../../shareLogin/Components/SponsorLogoSwiper';
import CpdPoint from '../../../components/cards/session/sessionCardV1/components/CpdPoint';
import DoctorVoice from '../../dashboard/components/DoctorVoice';
import { BsPlayCircleFill, MdOutlineArrowRightAlt } from '../../../components/icons';
import { removeHtmlTags } from '../../channel/utils';
import EbookHeroCard from '../../shareLogin/Components/EbookHeroCard';
import MentorHeroCard from '../../uiv3/share/components/MentorHeroCard';
import ObservershipHeroCard from '../../uiv3/share/components/ObservershipHeroCard';
import dnrShare from '../images/dnrShare.jpg';
import ChannelHeroCard from '../../shareLogin/Components/ChannelHeroCard';
import DoctubeHeroCard from '../../shareLogin/Components/DoctubeHeroCard';
import ClaimMentorSection from '../../uiv3/share/components/ClaimMentorSection';
import DdiHeroCard from '../../shareLogin/Components/DdiHeroCard';
import TrainingHeroCard from '../../shareLogin/Components/TrainingHeroCard';
import { getOpenApiDetails, getOpenApiModuleList } from '../../../Store/actions/training.action';
import ShareTrainingScrollCard from '../../shareLogin/Components/training/ShareTrainingScrollCard';
import TrainingContentAccordion from '../../grTraining/components/trainingContentAccordion/TrainingContentAccordion';
import '../css/clinicalVideosShare.scss';
import '../css/share.scss';
import '../css/grShare.scss';
import '../css/sessionShare.scss';
import '../css/surveyshare.scss';
import CountDown from '../components/TimeLeftCounter';
import AidaHistoryShareDetails from '../../aida/components/AidaHistoryShareDetails';
import AidaContainer from '../../aida';
import { toggleReference } from '../../../Store/actions';

const ShareTemplate2 = ({ serviceName, serviceType, constantSuccess, constantFaliure }) => {
  const pageName = 'test';
  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const dispatch = useDispatch();
  const { redirectTo } = useRedirect();
  const details = useSelector((state) => state.share.details);
  const [traingDetail, setTrainingDetail] = useState({});
  const [moduleList, setModuleList] = useState([]);

  const relatedData = useSelector((state) => state.share.relatedData);
  const focusRef = useRef(null);
  useManualScrollRestoration();

  useEffect(() => {
    setLoader(true);
    generateDataLayerGtm(pageName);
    generateTitle(pageName);

    const handleShareResponse = (response, mentorId = null) => {
      if (response === 'error') {
        redirectTo('/Unavailable');
        setLoader(false);
        return;
      }

      if (!response?.data) {
        redirectTo('/Unavailable');
        setLoader(false);
        return;
      }

      if (serviceName === 'channel') {
        if (!response?.data || !response?.data?.channel[0]?.type_id) {
          redirectTo('/Unavailable');
          setLoader(false);
          return;
        }
      }

      if (serviceName === 'observer' && mentorId === null) {
        // For observer service, fetch mentor data
        const mentorId = response?.data?.observer?.mentor_id;
        dispatch(
          shareActions(
            'mentor',
            mentorId,
            shareConstants.GET_OBSERVERSHIP_MENTOR_SHARE_SUCCESS,
            shareConstants.GET_OBSERVERSHIP_MENTOR_SHARE_FALIURE,
            '',
            '',
            '',
            '',
            (mentorResponse) => {
              if (mentorResponse !== 'error') {
                setLoader(false);
              }
            }
          )
        );
      } else {
        setLoader(false);
      }
    };

    const handleTrainingData = (res) => {
      if (res === 'error' || !res?.training?.id) {
        redirectTo('/Unavailable');
        setLoader(false);
        return;
      }

      dispatch(
        getOpenApiModuleList(id, (moduleRes) => {
          setLoader(false);
          if (moduleRes) {
            setTrainingDetail(res?.training);
            setModuleList(moduleRes);
          }
        })
      );
    };

    // Handle different service types
    if (serviceName === 'differential_diagnosis') {
      setLoader(false);
    } else if (serviceName === 'training') {
      dispatch(getOpenApiDetails(id, handleTrainingData));
    } else {
      // For all other services
      dispatch(
        shareActions(
          serviceName,
          id,
          constantSuccess,
          constantFaliure,
          '',
          '',
          '',
          '',
          handleShareResponse
        )
      );
    }

    window.document.title = 'CLIRNET - Medwiki Share';
  }, [id, serviceName, pageName, dispatch]);

  const focusInput = () => {
    console.log('focusRef', focusRef);
    focusRef?.current && focusRef?.current?.focus();

    dispatch(toggleReference(true));
  };
  const diff = dayjs(details?.start_datetime).diff(dayjs(new Date()));

  const shortData =
    (serviceName === 'survey' && serviceType === 'poll') || serviceType == 'claim_mentorship'
      ? true
      : false;
  const dontShowDisclaimer =
    serviceName === 'epub' || serviceName === 'observer' || serviceName === 'training'
      ? true
      : false;

  function getParentClassName() {
    switch (serviceName) {
      case 'survey':
        return 'shareSurvey';
      case 'gr':
        return 'shareGr';
      case 'session':
        return 'shareSession';
      case 'epub':
        return 'shareEpub';
      case 'observer':
        return 'shareObserver';
      case 'training':
        return 'shareTraining';
      case 'archived_video':
      case 'video_archive':
        return 'shareClVideos';
      default:
        return 'shareMedwiki';
    }
  }

  return (
    <>
      <TrackerComponent page_name={pageName} page_id={id} module_page="share" />
      <Seo
        title={`CLIRNET - ${pageName}`}
        description={details?.answer?.substring(0, 150)}
        image={details?.image}
        url={window.location.href}
      />
      <div
        className={
          isMobile ? 'w-100 mblscreen sharePageBody' : 'w-100 dskScreen desktopBody sharePageBody'
        }
      >
        {loader ? (
          <ShareSkeleton
            redirectType={getRedirectType(serviceName, serviceType)}
            setBackdrop={setBackdrop}
            pageName={pageName}
            focusRef={focusRef}
            service_type={serviceName == 'medwiki' ? 'comp' : serviceName}
            channel_id={details?.channel?.channel_id}
            contrastClass={'light'}
          />
        ) : (
          <div className={'w-100 float-start bg-body desktopBodyIn'}>
            <div className="container-fluid">
              <section className="w-100 cmnPageContent">
                {' '}
                {/* common for all page */}
                <div
                  className={`w-100 sharePage ${getParentClassName()} d-flex flex-column gap-md-4 gap-3`}
                >
                  {isMobile && <ShareHeader />}
                  {!isMobile && <ShareHeaderNew />} {/* page - start */}
                  <div className="sharePageTopSection mb-4 mb-lg-20 mb-xxl-5">
                    <div className="row ms-n4 ms-md-n5 mt-n4 mt-md-n5 me-0">
                      {/* Left side container */}
                      <div className="col-lg-8 col-md-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0">
                        <LeftSideContent
                          type={serviceName}
                          details={serviceName == 'training' ? traingDetail : details}
                          onReadMoreClick={focusInput}
                          serviceType={serviceType}
                          moduleList={moduleList}
                        />
                      </div>

                      {/* Right side container */}
                      <div className="col-lg-4 col-md-12 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0">
                        <div className="w-100 shareloginArea ">
                          <ShareLogin
                            redirectType={getRedirectType(serviceName, serviceType)}
                            setBackdrop={setBackdrop}
                            pageName={pageName}
                            focusRef={focusRef}
                            service_type={serviceName == 'medwiki' ? 'comp' : serviceName}
                            channel_id={details?.channel?.channel_id}
                            contrastClass={'white'}
                          />
                        </div>
                        {/* {isMobile && <ShareHeaderNew />} */}

                        {serviceName === 'training' ? (
                          <ShareTrainingScrollCard
                            disclaimer={details?.disclaimer}
                            focusToForm={focusInput}
                            layout="column"
                          />
                        ) : null}

                        {!shortData && !dontShowDisclaimer && (
                          <div className="w-100 shareDisclaimerArea">
                            <Disclaimer Disclaimer={details?.disclaimer} />
                          </div>
                        )}
                      </div>
                      {shortData && !dontShowDisclaimer && (
                        <div className="col-12 shareDisclaimerArea ps-4  ps-md-5 pe-0 mt-5">
                          <Disclaimer Disclaimer={details?.disclaimer} />
                        </div>
                      )}
                    </div>
                  </div>
                  <Masonry className="ms-n3 ms-md-n4 mt-n3 mt-md-n4 me-0 position-relative sharePageRelated">
                    {isMobile && (
                      <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0 loginPageFrmCard">
                        <ShareHeaderNew />
                      </div>
                    )}
                    <NewRelatedCardsArea
                      relatedCardsData={relatedData}
                      pageName={pageName}
                      excludeId={id}
                      shareDesign={true}
                      hideDisclaimer={true}
                    />
                  </Masonry>
                </div>
              </section>
            </div>
          </div>
        )}
      </div>
      <BackdropComponent backdrop={backdrop} />
    </>
  );
};

export default ShareTemplate2;

function LeftSideContent({ type, details, onReadMoreClick, serviceType, moduleList }) {
  const { id } = useParams();
  const specs = details?.specialities?.split(',') || [];
  const logo = details?.sponsor_logo?.split(',') || [];
  const { session_doctor_entities = [] } = details || {};
  let isLive = false;
  let startTimeDif = timeDifference(details?.start_datetime);
  let endTimeDif = timeDifference(details?.end_datetime);
  const location = useLocation();

  if (startTimeDif >= 0 && endTimeDif <= 0) {
    isLive = true;
  }

  const last_circle = 0;
  console.log('details', details);
  switch (type) {
    case 'comp':
      return details.type_id ? (
        <div className="w-100 rounded bg-white shareMedwikiBox shadow overflow-hidden">
          <div className="w-100 shareMedwikiPic position-relative overflow-hidden">
            <div className="overlay d-flex justify-content-center align-items-center">
              {/* <BsPlayCircleFill className="text-white display-1 position-absolute top-50 start-50 translate-middle"/> */}
            </div>

            <LazyImage
              src={details?.image ?? medwiki_static_image}
              alt="session image"
              className="object-fit-cover w-100 h-100 float-start"
              fallback_image={medwiki_static_image}
            />
          </div>
          <div className="w-100 text-start text-black fs-16 shareMedwikiContent py-4 py-md-20 px-3 px-md-4">
            <div className="shareMedwikiTop d-md-flex d-block align-items-center justify-content-between mb-md-4 mb-2">
              <a
                href="javascript:void(0)"
                className="fs-4 fw-normal text-black shareMedwikiSpeclty border-2 border-start border-secondary ps-3 d-flex align-items-center"
              >
                {specs[0]} {specs.length == 1 ? null : specialityPopOver(details?.specialities)}
              </a>
            </div>
            <h1 className="fs-2 fw-semibold text-black text-uppercase mb-3">
              {reactHtmlParser(details?.question)}
            </h1>
            <p className="fs-16 fw-normal">
              {removeHtmlTags(details?.answer)?.substring(0, 200)}
              <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                {' '}
                ...Read More
              </a>
            </p>

            <div className="w-100 shareMedwikiBttm d-flex justify-content-end text-end">
              {logo?.length == 1 && (
                <div className="shareMedwikiSponser me-0">
                  <div className="shareMedwikiSponserIn bg-white position-relative w-100">
                    <LazyImage
                      src={logo[0]}
                      className="position-absolute top-50 end-0 translate-middle-y mw-100 mh-100 w-auto h-auto"
                      logo
                    />
                  </div>
                </div>
              )}

              {logo?.length > 1 && (
                <Swiper
                  className="shareMedwikiSponser me-0"
                  navigation={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 2500
                  }}
                  effect={'fade'}
                >
                  {logo?.map((_logo, index) => {
                    return (
                      <SwiperSlide
                        className="shareMedwikiSponserIn bg-white position-relative w-100"
                        key={index + 1}
                      >
                        <LazyImage
                          src={_logo}
                          className="position-absolute top-0 end-0 translate-end mw-100 mh-100 w-auto h-auto"
                          logo
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      );
      break;
    case 'session':
      return (
        <div className="w-100 rounded shareSessionBox bg-white shadow overflow-hidden">
          <div className="w-100 shareSessionPic position-relative overflow-hidden">
            <CpdPoint data={details} />
            {isLive ? (
              <div className="overlay d-flex justify-content-center align-items-center z-2">
                <span className="text-danger fs-16 fw-semibold bg-white text-danger rounded d-flex align-items-center gap-3 px-4 py-2">
                  <div className="pulseCircle"></div> Live
                </span>
              </div>
            ) : details?.session_status == 4 ? (
              <div className="overlay d-flex justify-content-center align-items-center z-2">
                <span className="text-black fs-16 fw-semibold bg-white rounded d-flex align-items-center gap-3 px-4 py-2">
                  Upcoming TBA
                </span>
              </div>
            ) : details?.session_status == 6 ? (
              <div className="overlay d-flex justify-content-center align-items-end pb-5 px-2 z-2">
                <div class="w-100 text-center sessionCancelPospndIn d-inline-block bg-white bg-opacity-75 py-3 px-4">
                  <h4 class=" text-black fw-semibold text-uppercase fs-16">Session Cancelled</h4>
                </div>
              </div>
            ) : details?.session_status == 5 ? (
              <div className="overlay d-flex justify-content-center align-items-end pb-5 px-2 z-2">
                <div class="w-100 text-center sessionCancelPospndIn d-inline-block bg-white bg-opacity-75 py-3 px-4">
                  <h4 class=" text-black fw-semibold text-uppercase fs-16">Session Postponed</h4>
                </div>
              </div>
            ) : details?.session_status == 3 ? (
              <div className="overlay d-flex justify-content-center align-items-end pb-5 px-2 z-2">
                <div class="w-100 text-center sessionCancelPospndIn d-inline-block bg-white bg-opacity-75 py-3 px-4">
                  <h4 class=" text-black fw-semibold text-uppercase fs-16">Session Closed</h4>
                </div>
              </div>
            ) : (
              <div className="overlay d-flex justify-content-center align-items-center pb-5 px-2 z-2">
                <div className="rounded text-white shareSsnTimer d-flex bg-white bg-opacity-10 border border-white">
                  {timeDifference(details?.end_datetime) < 0 && (
                    <CountDown date={details?.start_datetime ? details?.start_datetime : ''} />
                  )}
                </div>
              </div>
            )}

            <LazyImage
              src={details?.cover_image ?? session_static_image}
              alt="session image"
              className="object-fit-cover w-100 h-100 float-start"
              fallback_image={session_static_image}
            />
          </div>
          <div className="w-100 text-start text-black fs-16 shareSsnContent py-4 py-md-20 px-3 px-md-4">
            <div className="shareSsnTop d-md-flex d-block align-items-center justify-content-between mb-md-4 mb-2">
              <span className="text-black fs-4 shareSsnSpeclty border-2 border-start border-secondary ps-3 mb-4 mb-md-0 d-flex">
                <i
                  className={
                    'text-secondary fs-2 me-2 ' + getSessionCategoryIcon(details?.ms_cat_name)
                  }
                ></i>

                {details?.ms_cat_name}
              </span>
              <h4 className="fs-3 text-black fw-medium">
                {details?.display_date_format}
                {details?.display_date}
              </h4>
            </div>
            <h1 className="fs-1 fw-bold text-black text-uppercase mb-3">
              {reactHtmlParser(details?.session_topic)}
            </h1>
            <p className="fs-16">
              {reactHtmlParser(details?.session_description)?.substring(0, 200)}
              <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                {' '}
                ...Read More
              </a>
            </p>
            <div className="w-100 shareSsnBttm mt-3 d-flex flex-column flex-md-row align-items-md-center align-items-start">
              {/* if single doctor ====================*/}
              {session_doctor_entities && (
                <DoctorSlide type={'session'} id={details?.session_id} data={details} />
              )}
              {session_doctor_entities?.length == 1 && (
                <div className="shareSsnSponser ms-auto me-0">
                  <div className="shareSsnSponserIn bg-white w-100 position-relative">
                    <LazyImage
                      src={details?.sponsor_entities[0]?.sponsor_logo}
                      alt={details?.sponsor_entities[0]?.sponsor_name}
                      className="position-absolute top-50 end-0 translate-middle mw-100 mh-100 w-auto h-auto"
                      logo
                    />
                  </div>
                </div>
              )}

              {details?.sponsor_entities?.length > 1 && (
                <Swiper
                  className="shareSsnSponser ms-auto me-0"
                  navigation={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 2500
                  }}
                  effect={'fade'}
                >
                  {details?.sponsor_entities?.map((ent, index) => {
                    return (
                      <SwiperSlide
                        className="shareSsnSponserIn bg-white w-100 position-relative"
                        key={index + 1}
                      >
                        <LazyImage
                          className="position-absolute top-0 end-0 translate-end mw-100 mh-100 w-auto h-auto"
                          src={ent.sponsor_logo}
                          alt={ent.sponsor_name}
                          logo
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      );
      break;
    case 'gr':
      return (
        <div className="w-100 rounded bg-white shareGrBox shadow overflow-hidden">
          <div className="w-100 shareGrPic overflow-hidden position-relative">
            <div className="overlay d-flex justify-content-center align-items-center"></div>

            <LazyImage
              src={details?.image}
              alt="session image"
              className="object-fit-cover w-100 h-100 float-start"
            />
          </div>
          <div className="w-100 text-start text-black fs-16 shareGrContent py-4 py-md-20 px-3 px-md-4">
            <div className="shareGrTop d-md-flex d-block align-items-center justify-content-between mb-md-4 mb-2">
              <a
                href="javascript:void(0)"
                className="fs-4 fw-normal text-black d-flex shareGrSpeclty border-2 border-start border-secondary ps-3"
              >
                {specs && Array.isArray(specs) && specs[0]}
                {specs && Array.isArray(specs) && specs.length > 1
                  ? specialityPopOver(details?.specialities)
                  : null}
              </a>
            </div>
            <h1 className="fs-2 fw-semibold text-black text-uppercase mb-3">
              {reactHtmlParser(details?.title)}
            </h1>
            <p className="fs-16 fw-normal">
              {reactHtmlParser(details?.description?.substring(0, 150))}{' '}
              <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                {' '}
                ...Read More{' '}
              </a>
            </p>
            <div className="w-100 shareClVideosBttm mt-3 d-md-flex d-block align-items-center">
              {/* if single doctor ====================*/}
              <div className="shareGrDoctor pe-0 pe-md-4 pe-lg-20">
                {session_doctor_entities &&
                Array.isArray(session_doctor_entities) &&
                session_doctor_entities?.length == 1 ? (
                  <div className="shareGrDoctorBox text-start">
                    <div className="w-100 rounded bg-white border border-light shareGrDoctorBoxIn py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="shareGrDoctorPic rounded-circle position-relative overflow-hidden border border-white">
                        <LazyImage
                          src={session_doctor_entities[0].session_doctor_image}
                          className="object-fit-cover w-100 h-100 float-start"
                          fallback_image={DEMO_USER_PICTURE}
                          logo
                        />
                      </div>
                      <div className="shareGrDoctorContent ps-3">
                        <h4 className="text-black fs-4">
                          {session_doctor_entities[0].session_doctor_name}
                        </h4>
                        <p className="text-dark fs-5">
                          {session_doctor_entities[0].DepartmentName}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {session_doctor_entities &&
                Array.isArray(session_doctor_entities) &&
                session_doctor_entities?.length > 1 &&
                session_doctor_entities?.length < 3 ? (
                  <Swiper spaceBetween={6} slidesPerView={1} loop={false}>
                    {session_doctor_entities?.map((singleData, index) => {
                      return (
                        <SwiperSlide className="shareGrDoctorBox text-start" key={index + 1}>
                          <div className="w-100 rounded bg-light shareGrDoctorBoxIn py-2 px-3 d-flex justify-content-between align-items-center">
                            <div className="shareGrDoctorPic rounded-circle position-relative overflow-hidden border border-white">
                              <LazyImage
                                src={singleData.session_doctor_image}
                                className="object-fit-cover w-100 h-100 float-start"
                                logo
                              />
                            </div>
                            <div className="shareGrDoctorContent ps-3">
                              <h4 className="text-black fs-4">{singleData.session_doctor_name}</h4>
                              <p className="text-dark fs-5">{singleData.DepartmentName}</p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : null}
                {session_doctor_entities &&
                  Array.isArray(session_doctor_entities) &&
                  session_doctor_entities?.length >= 3 && (
                    <div className={`w-100 grCardV1DocArea overflow-hidden grandround_area_${id}`}>
                      {session_doctor_entities?.map((item, i) => (
                        <div
                          className={
                            session_doctor_entities?.length > 2 &&
                            last_circle < session_doctor_entities?.length &&
                            i == last_circle &&
                            session_doctor_entities.length - last_circle > 1
                              ? 'rounded-circle grCardV1Doc lastCircle position-relative overflow-hidden float-start ms-n3 z-1 border-3 border-white'
                              : 'rounded-circle grCardV1Doc position-relative overflow-hidden float-start ms-n3 z-1 border-3 border-white '
                          }
                          key={i + 1}
                          id={`grCardV1Doc_${i}`}
                        >
                          <LazyImage
                            src={item.session_doctor_image}
                            fallback_image={DEMO_USER_PICTURE}
                            className="object-fit-cover w-100 h-100 float-start"
                            alt="doctor"
                            logo
                          />
                          {session_doctor_entities?.length > 2 &&
                            last_circle < session_doctor_entities?.length &&
                            i == last_circle &&
                            session_doctor_entities.length - last_circle > 1 && (
                              <div className="overlay text-white fs-1 bg-black bg-opacity-50 justify-content-center align-items-center">
                                <span>+{session_doctor_entities.length - last_circle}</span>
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
              </div>

              {logo && Array.isArray(logo) && logo?.length == 1 && (
                <div className="shareGrSponser mt-20 mt-md-0 float-start float-md-none">
                  <div className="shareGrSponserIn bg-white w-100 position-relative">
                    <LazyImage
                      src={logo[0]}
                      className="position-absolute top-50 end-0 translate-middle-y mw-100 mh-100 w-auto h-auto"
                      logo
                    />
                  </div>
                </div>
              )}

              {logo && Array.isArray(logo) && logo?.length > 1 && (
                <Swiper
                  className="shareGrSponser mt-20 mt-md-0 float-start float-md-none"
                  navigation={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 2500
                  }}
                  effect={'fade'}
                >
                  {logo.map((spLogo, index) => {
                    return (
                      <SwiperSlide
                        className="shareGrSponserIn bg-white w-100 position-relative"
                        key={index + 1}
                      >
                        <LazyImage
                          src={spLogo}
                          className="position-absolute top-50 end-0 translate-middle-y mw-100 mh-100 w-auto h-auto"
                          logo
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      );
      break;
    case 'epub':
      return (
        <EbookHeroCard
          // gtmTagVariable={`share_${share_type}`}
          data={details}
          channel_name={details?.channel?.title}
          channel_logo={details?.channel?.logo}
        />
      );
      break;
    case 'survey':
    case 'doctorVoice':
    case 'poll':
      switch (serviceType) {
        case 'poll':
          return (
            <div className="w-100 col-lg-8 col-md-12 text-start h-100 pe-0 pb-5">
              <DoctorVoice
                data={details}
                isShare={details?.is_share}
                headText={location.pathname?.includes('doctorVoice') ? 'Doctor’s Voice' : 'Polls'}
              />
            </div>
          );

        case 'survey':
          return (
            <div className="w-100 rounded quizShareVideosBox shadow overflow-hidden bg-white">
              <div className="w-100 quizShareVideosPic position-relative overflow-hidden">
                <div className="overlay d-flex justify-content-center align-items-center">
                  {/* <BsPlayCircleFill className="text-white display-1 position-absolute top-50 start-50 translate-middle"/> */}
                </div>

                <LazyImage
                  src={details?.image ?? surveys_static_image}
                  alt="session image"
                  className="object-fit-cover w-100 h-100 float-start"
                  fallback_image={surveys_static_image}
                />
              </div>
              <div className="w-100 text-start text-black fs-16 quizShareVideosContent py-4 py-md-20 px-3 px-md-4">
                <div className="quizShareVideosTop d-flex align-items-center justify-content-between mb-md-4 mb-2">
                  {specs && (
                    <a
                      href="javascript:void(0)"
                      className="fs-4 fw-normal text-black quizShareVideosSpeclty border-2 border-start border-secondary ps-3 d-flex align-items-center gap-2"
                    >
                      {specs[0]}
                      {specs.length > 1 ? specialityPopOver(details?.specialities_name) : null}
                    </a>
                  )}
                  {logo.length == 1 && (
                    <div className="quizShareVideosSponser me-0 mt-3 mt-md-0 float-end float-md-none">
                      <div className="quizShareVideosSponserIn bg-white w-100 position-relative">
                        <LazyImage
                          src={logo[0]}
                          className="position-absolute top-50 end-0 translate-middle-y mw-100 mh-100 w-auto h-auto"
                          logo
                        />
                      </div>
                    </div>
                  )}

                  {logo.length > 1 && (
                    <Swiper
                      className="quizShareVideosSponser me-0 mt-3 mt-md-0 float-end float-md-none"
                      navigation={false}
                      spaceBetween={0}
                      slidesPerView={1}
                      loop={true}
                      autoplay={{
                        delay: 2500
                      }}
                      effect={'fade'}
                    >
                      {logo.map((logo, index) => {
                        return (
                          <SwiperSlide
                            className="quizShareVideosSponserIn bg-white w-100 position-relative"
                            key={index + 1}
                          >
                            <LazyImage
                              src={logo}
                              className="position-absolute top-0 end-0 translate-end mw-100 mh-100 w-auto h-auto"
                              logo
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  )}
                </div>
                <h1 className="fs-2 fw-semibold text-black text-uppercase mb-3">
                  {reactHtmlParser(details?.survey_title)}
                </h1>
                <p className="fs-16 fw-normal">
                  {removeHtmlTags(details?.survey_description)?.substring(0, 200)}
                  <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                    {' '}
                    ...Read More
                  </a>
                </p>
                <div className="w-100 quizShareVideosBttm">
                  {/* if survey report ====================*/}

                  <div className="w-100 mt-lg-5 mt-4">
                    <div className="row justify-content-center">
                      <div className="col-lg-8">
                        <div className="w-100 text-center quizsharebutton py-4 border border-light">
                          <div className="row">
                            <div className="col">
                              <h3 className="fw-medium fs-4 text-black">
                                <span className="fw-semibold fs-1 d-block position-relative pb-2 mb-3">
                                  {details?.question_count}
                                </span>
                                Questions
                              </h3>
                            </div>
                            <div className="col">
                              <h3 className="fw-medium fs-4 text-black">
                                <span className="fw-semibold fs-1 d-block position-relative pb-2 mb-3">
                                  {details?.survey_time} <span className="fs-4">Min</span>
                                </span>
                                Duration
                              </h3>
                            </div>
                            <div className="col">
                              <h3 className="fw-medium fs-4 text-black">
                                <span className="fw-semibold fs-1 d-block position-relative pb-2 mb-3">
                                  {details?.survey_points}
                                </span>
                                Points
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-center align-items-center quizshareBttn mt-md-5 mt-3">
                    <a
                      href="javascript:void(0)"
                      className="fs-4 fw-normal btn btn-primary rounded-pill py-3 px-4 shadow d-flex justify-content-center align-items-center "
                      onClick={onReadMoreClick}
                      style={{ width: 'fit-content' }}
                    >
                      Begin <MdOutlineArrowRightAlt customClass="fs-4 ms-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );

        default:
          return (
            <div className="w-100 col-lg-8 col-md-12 text-start h-100 pe-0 pb-5">
              <DoctorVoice
                data={details}
                isShare={details?.is_share}
                headText={location.pathname?.includes('doctorVoice') ? 'Doctor’s Voice' : 'Polls'}
              />
            </div>
          );
      }
      break;
    case 'dnr':
      return (
        <div className="w-100 rounded bg-white telemedShareBox shadow overflow-hidden">
          <div className="w-100 telemedSharePic position-relative overflow-hidden">
            <img
              src={dnrShare}
              alt="session image"
              className="object-fit-cover w-100 h-100 float-start"
            />
          </div>

          <div className="w-100 text-start text-black fs-16 telemedShareContent py-4 pe-4 py-md-20 pe-md-20"></div>
        </div>
      );
      break;
    case 'channel':
      return (
        <ChannelHeroCard
          gtmTagVariable={`share_channel`}
          data={details}
          channel_name={details?.channel?.title}
          channel_logo={details?.channel?.logo}
        />
      );
      break;
    case 'archived_video':
    case 'video_archive':
      return (
        <div className=" w-100 rounded bg-white shareClVideosBox shadow overflow-hidden">
          <div className="w-100 shareClVideosPic position-relative overflow-hidden">
            <div
              className="overlay d-flex justify-content-center align-items-center z-3"
              onClick={onReadMoreClick}
            >
              <BsPlayCircleFill customClass="text-white display-1 position-absolute top-50 start-50 translate-middle" />
            </div>

            <LazyImage
              src={details?.image}
              alt="session image"
              className="object-fit-cover w-100 h-100 float-start"
            />
          </div>
          <div className="w-100 float-start text-start text-black fs-16 shareClVideosContent py-4 py-md-20 px-3 px-md-4">
            {specs && (
              <div className="shareClVideosTop d-md-flex d-block align-items-center justify-content-between mb-md-4 mb-2">
                <a
                  href="javascript:void(0)"
                  className="fs-4 fw-normal text-black shareClVideosSpeclty border-2 border-start border-secondary ps-3 d-flex align-items-center gap-2"
                >
                  {specs[0]} {specs.length > 1 ? specialityPopOver(details?.specialities) : null}
                </a>
              </div>
            )}
            <h1 className="fs-2 fw-semibold text-black text-uppercase mb-3">
              {reactHtmlParser(details?.question)}
            </h1>
            <p className="fs-16 fw-normal">
              {removeHtmlTags(details?.answer)?.substring(0, 200)}
              <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                {' '}
                ...Read More
              </a>
            </p>
            <div className="w-100 shareClVideosBttm mt-3 d-flex flex-column flex-md-row align-items-md-center align-items-start">
              {details && <DoctorSlide type={'clVideo'} id={details?.type_id} data={details} />}
              {logo.length == 1 && (
                <div className="shareClVideosSponser  ms-auto me-0">
                  <div className="shareClVideosSponserIn bg-white position-relative w-100">
                    <LazyImage
                      src={details?.sponsor_logo}
                      className="position-absolute top-50 end-0 translate-middle-y mw-100 mh-100 w-auto h-auto"
                      logo
                    />
                  </div>
                </div>
              )}

              {logo.length > 1 && (
                <Swiper
                  className="shareClVideosSponser  ms-auto me-0"
                  navigation={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 2500
                  }}
                  effect={'fade'}
                >
                  {logo.map((_logo, index) => {
                    return (
                      <SwiperSlide
                        className="shareClVideosSponserIn bg-white position-relative w-100"
                        key={index + 1}
                      >
                        <LazyImage
                          src={_logo}
                          className="position-absolute top-0 end-0 translate-end mw-100 mh-100 w-auto h-auto"
                          logo
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      );
      break;
    case 'observer':
      return (
        <>
          <ObservershipHeroCard gtmTagVariable={`share_observership`} data={details} />
          <div className="w-100 shareDisclaimerArea mt-5">
            <Disclaimer Disclaimer={details?.disclaimer} />
          </div>
        </>
      );
      break;
    case 'mentor':
      return <MentorHeroCard gtmTagVariable={`share_mentor`} data={details} />;
      break;
    case 'ddi':
      return <DdiHeroCard id={id} isResult={true} isIntro={true} data={details} />;
      break;
    case 'differential_diagnosis':
      return <AidaContainer share={true} />;
    case 'training':
      return (
        <div className="d-flex gap-5 flex-wrap">
          <TrainingHeroCard
            gtmTagVariable={`share_${type}`}
            data={details}
            channel_name={details?.channel?.title}
            channel_logo={details?.channel?.logo}
          />
          {console.log('moduleList', moduleList)}
          {Array.isArray(moduleList) && moduleList?.length > 1 && (
            <TrainingContentAccordion
              training_id={details?.id}
              module_list={moduleList}
              isSharePage={true}
            />
          )}
        </div>
      );
    default:
      switch (serviceType) {
        case 'doctube':
        case 'doctube_fst':
          return <DoctubeHeroCard gtmTagVariable={`share_${type}`} />;
          break;
        case 'claim_mentorship':
          return <ClaimMentorSection data={details} />;
          break;

        default:
        // return <Navigate to={'/'} replace={true} />;
      }

      return <></>;
  }
}
