//ACTION TYPE

export const PAGE_VIEW = 'PAGE_VIEW';
export const CARD_VIEW = 'CARD_VIEW';
export const CTA_CLICK = 'CTA_CLICK';
export const CTA_VIEW = 'CTA_VIEW';
export const LIKE_CLICK = 'LIKE_CLICK';
export const VAULT_CLICK = 'VAULT_CLICK';
export const TOPIC_CLICK = 'TOPIC_CLICK';
export const SHARE_CLICK = 'SHARE_CLICK';
export const CARD_CLICK = 'CARD_CLICK';
export const MORE_CLICK = 'MORE_CLICK';
export const FOLLOW_CLICK = 'FOLLOW_CLICK';
export const SUBMIT_CLICK = 'SUBMIT_CLICK';
export const COMMENT_SUBMIT = 'COMMENT_SUBMIT';
export const COMMENT_LIKE_CLICK = 'COMMENT_LIKE_CLICK';
export const COMMENT_REPLY_SUBMIT = 'COMMENT_REPLY_SUBMIT';
export const EXPLORE_MORE_CLICK = 'EXPLORE_MORE_CLICK';
export const GET_RECORDING_CLICK = 'GET_RECORDING_CLICK';
export const EVENT = 'EVENT';
export const CLICK_CTA_CARD = 'CLICK_CTA_CARD';
export const AUTOAUTH_ERROR = 'AUTOAUTH_ERROR';
export const AUTOAUTH_SIGN_UP_CLICK = 'AUTOAUTH_SIGN_UP_CLICK';
export const SESSION_INVITATION_ERROR = 'SESSION_INVITATION_ERROR';
export const BANNER_LOAD = 'BANNER_LOAD';
export const NEXT_CLICK = 'NEXT_CLICK';
export const NOT_FROM_DEEPLINK = 'NOT_FROM_DEEPLINK';

export const INNITIALIZE_PAGE_CONFIG = 'INNITIALIZE_PAGE_CONFIG';
export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const TRACK_ACTIVITY_EVENTS = 'TRACK_ACTIVITY_EVENTS';
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS';
export const REPORT_CONTENT = 'REPORT_CONTENT';
export const FILTER_CLICK = 'FILTER_CLICK';
export const RATING_CLICK = 'RATING_CLICK';

//services name

export const MEDWIKI_SERVICE = 'comp';
