import React, { Suspense } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getLocalStorage, setLocalStorage } from '../common/common';
import AutoAuthModal from '../components/AutoAuth/AutoAuthModal';
import useGetParams from '../components/customHooks/useGetParams';
import useRedirect from '../components/customHooks/useRedirect';
import FreshContentWrapper from '../components/FreshContentWrapper';
import ShareTemplates from '../pages/share/ShareTemplates';
import { saveUtm } from '../Store/actions/shareActions';
import ListingPageBackground from './components/ListingPageBackground';
import { autoAuthRedirects, routeNames } from './constants';
import LoadingFallback from './fallback/LoadingFallback';

function AuthRouter({
  children,
  autoAuth = false,
  autoLogin = false,
  shareRoute = false,
  publicRoute = false
}) {
  const userDetails = useSelector((state) => state.common.user_details);
  console.log('userDetails', userDetails);
  const location = useLocation();
  const dispatch = useDispatch();
  const { getSharePageURL } = useRedirect();

  const {
    content_type,
    content_id,
    utm_source,
    utm,
    token,
    user_type,
    user_id,
    redirect_name,
    redirect_page_id,
    base_id,
    base_type,
    base_title
  } = useGetParams();
  const { autoAuth: autoAuthFromState } = location?.state || {};
  const utmId = utm || utm_source;

  const queryClient = useQueryClient();
  function inValidateOpenapiDetails() {
    queryClient.invalidateQueries();
  }
  console.log('autoLogin', autoLogin);

  const { env, spec_dashboard_eligibility_status, specility_update_status, user_master_id } =
    userDetails || {};
  if (utmId) {
    setLocalStorage('utm_source', utmId);
    dispatch(saveUtm(utmId));
  }
  if (publicRoute) {
    return children;
  } else if (autoAuth) {
    //check for freshcontent
    var autoAuthRedirect = undefined;

    var basePayload = base_id
      ? {
          from_login: true,
          main_content_id: base_id ? content_id : null,
          main_content_type: base_type ? content_type : 'medwiki'
        }
      : {};

    if (base_id) {
      const baseType = base_type ? base_type : 'medwiki';
      autoAuthRedirect = autoAuthRedirects.find((item) =>
        item.service.some((service) => service.toLowerCase() === baseType.toLowerCase())
      );
    } else {
      autoAuthRedirect = autoAuthRedirects.find((item) =>
        item.service.some((service) => service.toLowerCase() === content_type.toLowerCase())
      );
    }

    if (autoAuthRedirect) {
      inValidateOpenapiDetails();

      if ((base_id && base_id != 0) || (content_id && content_id != 0)) {
        return (
          <Navigate
            to={`${autoAuthRedirect.redirectURL}/${base_id ? base_id : content_id}`}
            state={{
              autoAuth: true,
              token: token,
              user_type,
              user_id,
              content_type,
              content_id,
              ...basePayload
            }}
          />
        );
      } else {
        return (
          <Navigate
            to={`${
              autoAuthRedirect.redirectURLLanding
                ? autoAuthRedirect.redirectURLLanding
                : autoAuthRedirect.redirectURL
            }`}
            state={{
              autoAuth: true,
              token: token,
              user_type,
              user_id,
              content_type,
              content_id,
              ...basePayload
            }}
          />
        );
      }
    } else if (getSharePageURL(content_type, content_id, utm_source) !== '/') {
      return (
        <Navigate
          to={getSharePageURL(content_type, content_id, utm_source)}
          state={{ autoAuth: true, token: token, user_type, user_id, content_type, content_id }}
        />
      );
    } else {
      console.log('ami ekhane');
      return (
        <Navigate
          to={routeNames.dashboard.landing}
          state={{ autoAuth: true, token: token, user_type, user_id, content_type, content_id }}
        />
      );
    }
  } else if (autoAuthFromState) {
    return (
      <>
        {user_type === 'user_master' ? (
          <FreshContentWrapper>{children}</FreshContentWrapper>
        ) : content_id && content_id != 0 ? (
          <FreshContentWrapper>{children}</FreshContentWrapper>
        ) : (
          <ListingPageBackground />
        )}

        <AutoAuthModal />
      </>
    );
  } else if (autoLogin) {
    const autoAuthRedirect = autoAuthRedirects.find((item) =>
      item.service.some((service) => service.toLowerCase() === redirect_name.toLowerCase())
    );
    console.log('autoLogin', autoLogin, autoAuthRedirect, redirect_page_id);

    if (autoAuthRedirect) {
      if (redirect_page_id != 0)
        return (
          <Navigate
            to={`${autoAuthRedirect.redirectURL}/${redirect_page_id}`}
            state={{
              autoAuth: true,
              token: token,
              user_type: 'user_master',
              user_id: 0,
              content_type: redirect_name,
              content_id: redirect_page_id
            }}
          />
        );
      else if (redirect_page_id)
        return (
          <Navigate
            to={`${autoAuthRedirect.redirectURL}`}
            state={{
              autoAuth: true,
              token: token,
              user_type: 'user_master',
              user_id: 0,
              content_type: redirect_name,
              content_id: redirect_page_id
            }}
          />
        );
    } else {
      return <Navigate to={'/'} />;
    }
  } else if (shareRoute) {
    return <ShareTemplates />;
  } else {
    console.log('testing 1', user_master_id);
    if (userDetails && user_master_id) {
      console.log('testing 2', user_master_id);
      //logged in user
      if (userDetails && spec_dashboard_eligibility_status && specility_update_status === '0') {
        console.log('testing 3', user_master_id);
        //logged in user needs onboarding
        return (
          <Suspense fallback={<LoadingFallback />}>
            <SpecialityOnboarding />
          </Suspense>
        );
      } else {
        console.log('testing 4', user_master_id);
        //logged in user
        return children;
      }
    } else {
      console.log('testing 5', user_master_id);
      if (!getLocalStorage('user', false)) {
        return <Navigate to="/" state={{ from: location }} />;
      }
      //not logged in user
    }
  }
}

export default AuthRouter;
