import React from 'react';

import Masonry from 'react-masonry-component';
import ShareLogin from '../../common/LoginForAll/login/Login';
import MedwikiLoader from '../medwiki/medwikiLoader';
import { Skeleton } from '../Skeleton';

function NewShareSkeleton() {
  return (
    <>
      <div className="w-100 shareLogin shareLoginV2 d-flex flex-wrap flex-row-reverse position-relative bg-body">
        <div className="shareLoginV2Right position-relative mb-20 mb-lg-0">
          <div className="shareLoginV2Frm d-flex align-items-center w-100 position-sticky top-0 w-100 bg-white shadow z-2 min-vh-100">
            <div className="w-100 float-start shareLoginV2_In text-black text-start position-relative z-1 py-3 px-3 py-lg-20 px-lg-4 px-xl-5 py-xl-5">
              <Skeleton variant="text" height={20} width={'20%'} />
              <Skeleton variant="text" height={10} width={'100%'} />
              {[...Array(3).keys()].map((_i, index) => (
                <div className="mt-4" key={index + 1}>
                  <Skeleton variant="text" height={40} width={'100%'} />
                </div>
              ))}
              {/* <Skeleton variant="text" height={10} width={'100%'} /> */}
            </div>
          </div>
        </div>
        <div className="shareLoginV2Left">
          <div className="w-100 float-start shareLoginV2LeftIn p-3 p-md-4 p-lg-20 p-xl-5 position-sticky top-0 min-vh-100">
            <div className="w-100 float-start shareLoginV2MainCardArea d-none d-lg-block mb-5">
              <div className="row">
                <div className="col-12 bg-white p-3">
                  <div className="d-flex justify-content-between">
                    <Skeleton variant="rectangular" height={200} width={'40%'} />
                    {/* <Skeleton variant="rectangular" height={200} width={'60%'} /> */}
                  </div>
                </div>
                <div className="col-12  bg-white p-3 mt-4">
                  <Skeleton variant="rectangular" height={100} width={'100%'} />
                </div>
                <div className="col-12">
                  <Masonry
                    enableResizableChildren={true}
                    className="sharePageMasonry sharePageRelated mt-4 position-relative"
                  >
                    {[...Array(4).keys()].map((_i, index) => (
                      <div
                        className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card sharePageMasonryCard"
                        key={index + 1}
                      >
                        <MedwikiLoader />
                      </div>
                    ))}
                  </Masonry>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewShareSkeleton;
