import { Navbar, Image } from 'react-bootstrap';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import 'swiper/css';
import useRedirect from '../../../customHooks/useRedirect';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmation from '../../../modals/DeleteConfirmation';
import { ClirnetIconLogo } from '../../../icons';
import {
  changeUrl,
  convertAllMenu,
  deleteCaches,
  getLocalStorage,
  reactHtmlParser,
  setLocalStorage
} from '../../../../common/common';
import { updateUserViewSpeciality } from '../../../../Store/actions/dashboard.action';

import { changeRoute, changeTheme } from '../../../../Store/actions/settings.action';
import { Link, useLocation } from 'react-router-dom';
import LazyImage from '../../../CustomLibraries/LazyImage';
import JoyRide from '../../../reactJoyRide/JoyRide';
import { changeExploreKey } from '../../../../Store/actions';

import { DEFAULT_MENU } from '../../../../Store/constants/app.constants';
import { Skeleton } from '../../../../skeleton/Skeleton';
import { routeNames } from '../../../../router/constants';
import LoaderLine from '../../../LoaderLine';
import { safeJsonParser } from '../../../../utils/common';

const HeaderSpecialityDesktop = ({ getElementHeight, elHeight }) => {
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();
  const prevScrollPos = useRef(window.pageYOffset);
  const headerSecRef = useRef(0);
  const getHeight = useRef(0);
  const returnToSpecRef = useRef(0);
  const [headerTransform, setHeaderTransform] = useState(0);
  const [menuJson, setMenuJson] = useState('');
  const { pathname } = useLocation();
  const [specialityDataShowing, setSpecialityDataShowing] = useState('');
  const userSpeciality = safeJsonParser(
    useSelector((state) => state.common.user_details.speciality),
    []
  );
  console.log('user speciality printed here:', userSpeciality);

  // const [userSpeciality, setUserSpeciality] = useState(
  //   getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.speciality) : ''
  // );

  const [{ deletionType, deletionModalShow }, setDeletionConfirmation] = useState({
    deletionType: '',
    deletionModalShow: false
  });
  const userDetails = useSelector((state) => state.common.user_details);
  const showingSpecData = useSelector((state) => state.dashboard.user_speciality_viewing);
  const exploreKey = useSelector((state) => state.common.exploreKey);

  const handleScroll = () => {
    const currentScrollPos = window?.pageYOffset;
    const headerHeight = headerSecRef?.current?.clientHeight;
    if (prevScrollPos?.current > currentScrollPos) {
      // alert('Scroll up detected!', headerHeight);
      if (headerHeight <= 0) {
        setHeaderTransform(headerHeight);
      }
    } else {
      setHeaderTransform(0);
      // alert('Scroll down detected!');
    }
    prevScrollPos.current = currentScrollPos;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (getHeight && getHeight.current) {
      // Initially set the height
      console.log(getHeight.current, 'getHeight.current.');
      getElementHeight(getHeight.current.clientHeight);

      // Define a named function for the event handler
      const handleResize = () => {
        if (getHeight.current) {
          getElementHeight(getHeight.current.clientHeight);
        }
      };

      // Add event listener with the named function
      window.addEventListener('resize', handleResize);

      // Clean up with the same function reference
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (showingSpecData && showingSpecData?.length > 0) {
      setSpecialityDataShowing(showingSpecData);
    }
  }, [showingSpecData]);

  useEffect(() => {
    if (userDetails && userDetails?.menu) {
      try {
        if (
          'menu' in safeJsonParser(userDetails?.menu, {}) &&
          safeJsonParser(userDetails.menu, {}).menu.length > 0
        ) {
          let menu = safeJsonParser(userDetails.menu, {});
          console.log('menu', menu);
          setMenuJson({
            ...menu
            // static_menu: convertAllMenu(menu?.static_menu || []),
            // all_menu: convertAllMenu(menu?.all_menu || [])
          });
        } else setMenuJson(DEFAULT_MENU);
      } catch (e) {
        setMenuJson(DEFAULT_MENU);
      }
    }
  }, [userDetails]);

  const onGroupClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo(routeNames.channel.landing);
  };
  const onDoctubeClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo(routeNames.doctube.landing);
  };
  const onDashboardClick = () => {
    dispatch(changeExploreKey(null));
    redirectTo(routeNames.dashboard.landing);
  };
  // const onExploreClick = () => {};
  const onSearchClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo(routeNames.search.landing);
  };
  const onNotificationClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo(routeNames.notification.landing);
  };
  const logout = (type) => {
    if (type == 'logout') {
      window.location.href = '/';
      localStorage.clear();
      sessionStorage.clear();
      deleteCaches();
      // dispatch(changeRoute(true));
      // dispatch(logoutUser());
      // dispatch(resetStore());
    }
  };
  const UserRegion = useSelector((state) => state.share.UserRegion);

  const redirectToDashboard = () => {
    dispatch(changeExploreKey(null));
    redirectTo(routeNames.dashboard.landing);
  };

  const onChangeSpecialityClick = () => {
    console.log('onChangeSpecialityClick');
    redirectTo('SelectSpeciality');
  };

  const onMenuClick = (pathname, name, dontSetExploreKey = false) => {
    //  remove / from pathname
    if (!dontSetExploreKey) {
      dispatch(changeExploreKey(name));
    } else {
      dispatch(changeExploreKey(null));
    }

    // let redirectPath = pathname.replace('/', '');
    // console.log('onMenuClick', redirectPath);
    redirectTo(changeUrl(pathname));
  };

  const onReturnToSpecClick = () => {
    dispatch(updateUserViewSpeciality(userSpeciality));
    setLocalStorage('selectedSpeciality', userSpeciality[0]);
    // dispatch(changeTheme(userSpeciality?.specialities_name ?? ''));
    // document.body.setAttribute('data-bs-theme', userSpeciality?.specialities_name ?? '');
  };

  const isInDashboard = useMemo(() => {
    return pathname.toLowerCase() == '/dashboard';
  }, [pathname]);

  const current_menu = useSelector((state) => state.common.current_menu);
  const [currentMenu, setCurrentMenu] = useState('');

  useEffect(() => {
    if (current_menu?.toLowerCase()?.includes('dashboard')) {
      setCurrentMenu('dashboard');
    } else if (current_menu?.toLowerCase()?.includes('session')) {
      setCurrentMenu('session');
    } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
      setCurrentMenu('medwiki');
    } else if (current_menu?.toLowerCase()?.includes('spq')) {
      setCurrentMenu('spq');
    } else if (current_menu?.toLowerCase()?.includes('clinical')) {
      setCurrentMenu('clinical');
    } else if (current_menu?.toLowerCase()?.includes('gr')) {
      setCurrentMenu('grandround');
    } else if (
      current_menu?.toLowerCase()?.includes('ebook') ||
      current_menu?.toLowerCase()?.includes('epaper')
    ) {
      setCurrentMenu('ebook');
    } else if (current_menu?.toLowerCase()?.includes('channel')) {
      setCurrentMenu('channel');
    } else if (current_menu?.toLowerCase()?.includes('telemed')) {
      setCurrentMenu('telemed');
    } else if (current_menu?.toLowerCase()?.includes('discussrefer')) {
      setCurrentMenu('discussrefer');
    } else if (
      current_menu?.toLowerCase()?.includes('training') ||
      current_menu?.toLowerCase()?.includes('course')
    ) {
      setCurrentMenu('training');
    } else if (current_menu?.toLowerCase()?.includes('doctube')) {
      setCurrentMenu('doctube');
    } else if (current_menu?.toLowerCase()?.includes('speciality')) {
      setCurrentMenu('speciality');
    } else if (
      current_menu?.toLowerCase()?.includes('diagnosis') ||
      current_menu?.toLowerCase()?.includes('ddx')
    ) {
      setCurrentMenu('diagnosis');
    }
  }, [current_menu]);

  function getActiveClass(path) {
    if (current_menu && currentMenu !== '') {
      if (
        current_menu?.toLowerCase().includes(path?.toLowerCase()) ||
        path?.toLowerCase().includes(current_menu?.toLowerCase())
      ) {
        return 'active';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  useEffect(() => {
    const specialityArray = [];
    const selectedSpeciality = getLocalStorage('selectedSpeciality', null);
    if (selectedSpeciality) {
      specialityArray.push(selectedSpeciality);
      setSpecialityDataShowing(specialityArray);
    } else {
      setSpecialityDataShowing(
        getLocalStorage('user', false)
          ? safeJsonParser(getLocalStorage('user', {})?.speciality, [])
          : ''
      );
    }
  }, []);

  return (
    <Navbar
      className="clr_v3_Header position-fixed navbar py-0 px-4 m-0 top-0 bg-white w-100 rounded-0 flex-column  clr_v3_Header--desktop topHeader py-lg-0 py-3 d-none d-lg-flex"
      expand="lg"
      style={{ transform: `translateY(-${headerTransform}px)` }}
      ref={getHeight}
      id="headerDesktopNavBar"
    >
      <LoaderLine />
      <JoyRide />
      <div className="position-relative w-100" ref={headerSecRef}>
        <div className="clr_v3_Header__navbar position-relative pb-0">
          {isInDashboard &&
            userSpeciality?.length > 0 &&
            specialityDataShowing?.length > 0 &&
            userSpeciality[0]?.master_specialities_id !=
              specialityDataShowing[0]?.master_specialities_id && (
              <div
                ref={returnToSpecRef}
                className="px-3 bg-soft-primary mx-n4 py-1 text-black position-relative z-2 d-flex gap-2 justify-content-center align-items-center clr_v3_Header__outside_Speciality"
                onClick={onReturnToSpecClick}
              >
                <h2 className="fs-6">Viewing content outside your Speciality.</h2>
                <a
                  href="javascript:void(0)"
                  className="fw-semibold d-inline-flex gap-1  align-items-center fs-6"
                >
                  Return to my Speciality<i className="flaticon-chevron"></i>
                </a>
              </div>
            )}
          <nav className="position-relative z-2 text-white clr_v3_Header__nav">
            <div className="row align-items-center justify-content-between">
              <div className="d-flex align-items-center my-2 col-3 flex-grow-0 gap-20">
                <Navbar.Toggle aria-controls="clr_v3_headerCollapse" />

                <div
                  onClick={redirectToDashboard}
                  className="clr_v3_Header__brand icon-size-sm-50 icon-size-36 flex-shrink-0 bg-light d-flex align-items-center justify-content-center rounded-3 cursorPointer "
                  id="company_logo"
                >
                  <ClirnetIconLogo />
                </div>

                {isInDashboard && specialityDataShowing && specialityDataShowing?.length > 0 && (
                  <a
                    href="javascript:void(0)"
                    onClick={onChangeSpecialityClick}
                    className="fs-4 btn btn-outline-primary rounded-3 py-3 d-inline-flex align-content-center jusstify-content-center gap-2 clr_v3_Header__speciality__btn"
                    id="change-speciality-button"
                  >
                    <i className="flaticon-transfer-data d-inline-block align-middle fs-2"></i>
                    <span className="d-inline-block text-truncate">
                      {specialityDataShowing[0]?.specialities_name}
                    </span>
                  </a>
                )}
              </div>
              <div className="col px-20 d-flex justify-content-center">
                {/* <div className='clr_v3_Header__menu-link collapse navbar-collapse h-100 flex-grow-0' id='clr_v3_headerCollapse'> */}
                <Navbar.Collapse
                  className="clr_v3_Header__menu-link h-100 flex-grow-0"
                  id="clr_v3_headerCollapse"
                >
                  <ul className="d-flex p-0 align-items-center list-unstyled fs-4 mb-0 gap-5 ">
                    {menuJson &&
                      menuJson?.menu &&
                      menuJson?.menu.map((elem, key) => (
                        <li className={` ${getActiveClass(elem.url)}`} key={key}>
                          <a
                            href="javascript:void(0)"
                            className={`d-flex gap-2 align-items-center`}
                            // onClick={onGroupClick}
                            onClick={() =>
                              'sub_menu' in elem
                                ? {}
                                : onMenuClick(elem.url, elem['short-name'], true)
                            }
                          >
                            <div
                              className="clr_v3_Header__menu-link__nav-link__icon flex-shrink-0 d-flex align-items-center justify-content-center fs-16"
                              dangerouslySetInnerHTML={{ __html: elem.icon }}
                            />
                            <span className="fw-medium d-flex align-items-center">
                              {reactHtmlParser(elem.title)}{' '}
                              {elem.displayIcon && (
                                <>
                                  <i className="flaticon-premium fs-4 shineEffect clr_v3_menu__premium"></i>
                                </>
                              )}
                            </span>
                            {'sub_menu' in elem && (
                              <>
                                <i className="flaticon-down-arrow"></i>
                              </>
                            )}

                            {exploreKey && 'sub_menu' in elem && (
                              <>
                                <span className="badge bg-light text-black rounded-pill position-absolute translate-middle-x start-50 fw-semibold fs-6 exploretag">
                                  {reactHtmlParser(exploreKey)}
                                </span>
                              </>
                            )}
                          </a>

                          {'sub_menu' in elem && (
                            <ul
                              className="position-fixed translate-middle-x start-50 clr_v3_Header__exploreDesktop bg-white p-3 flex-wrap row-cols-3 d-flex"
                              style={{ paddingTop: `${elHeight}px` }}
                            >
                              {elem.sub_menu?.length > 0 &&
                                elem.sub_menu?.map((key, ind) => {
                                  return (
                                    <li
                                      className="p-2"
                                      onClick={() => onMenuClick(key.url, key['short-name'])}
                                      key={ind}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        className={`rounded-3 p-3 bg-primary d-flex gap-3 align-items-center position-relative overflow-hidden ${getActiveClass(
                                          key.url
                                        )}`}
                                      >
                                        {key.icon && (
                                          <div className="py-2 bg-primary rounded-2 transition clr_v3_exploreDesktop__menuIcon">
                                            <LazyImage
                                              extraClass="d-flex align-items-center justify-content-center w-100 h-100"
                                              alt={key.title}
                                              src={key.icon}
                                            />
                                          </div>
                                        )}
                                        <div className="justify-content-between d-flex flex-column text-black clr_v3_exploreDesktop__menuNumber">
                                          {/* {key.count && <span className="mb-1">{key.count}</span>} */}
                                          {key.title && (
                                            <span className="fw-medium text-black">
                                              {reactHtmlParser(key.title)}
                                            </span>
                                          )}
                                        </div>
                                        {key.displayIcon && (
                                          <span
                                            className={`clr_v3_premium__tag position-absolute shineEffect z-3 text-black icon-size-20 fs-5`}
                                          >
                                            <i
                                              className={`clr_v3_premium__tag__icon translate-middle start-50 top-50 position-absolute flaticon-premium`}
                                            ></i>
                                          </span>
                                        )}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                </Navbar.Collapse>
                {/* </div> */}
              </div>

              <div className="clr_v3_Header__right my-2 col-3 d-flex justify-content-end">
                <ul className="d-flex p-2 align-items-center list-unstyled fs-3 mb-0 bg-light rounded-pill gap-3">
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="clr_v3_icon-btn-1 rounded-circle icon-size-36 rippleEffect d-inline-flex align-items-center justify-content-center"
                      onClick={onSearchClick}
                    >
                      <i className="flaticon-loupe"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="clr_v3_icon-btn-1 rounded-circle icon-size-36 rippleEffect d-inline-flex align-items-center justify-content-center"
                      onClick={onNotificationClick}
                    >
                      <i className="flaticon-notification"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="clr_v3_icon-btn-1 rounded-circle icon-size-36 rippleEffect d-inline-flex align-items-center justify-content-center"
                      onClick={() =>
                        setDeletionConfirmation({
                          deletionType: 'logout',
                          deletionModalShow: true
                        })
                      }
                    >
                      <i className="flaticon-logout"></i>
                    </a>
                  </li>
                  {/* profile image start */}
                  {/* <li className="ms-4">
                  <div className="rounded-circle p-33 border border-white border-105 bg-transparent">
                    <div className="overflow-hidden border border-white icon-size-32 icon-size-sm-36 rounded-circle">
                      <img
                        src="https://img-cdn.clirnet.com/medwiki/43_server/video/1637400385_b1e9f19A39BB66b161489FaE84.jpeg?tr=w-264,h-264,pr=true,c-at_max"
                        width=""
                        height=""
                        alt="Dr. Tarun Grover"
                        className="object-fit-cover w-100 h-100 float-start"
                      />
                    </div>
                  </div>
                </li> */}
                  {/* profile image end */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {deletionType && (
        <DeleteConfirmation
          title={`Are you sure to log out ?`}
          type={deletionType}
          confirm={deletionModalShow}
          handleClose={() =>
            setDeletionConfirmation({ deletionType: '', deletionModalShow: false })
          }
          confirmDeletion={logout}
        />
      )}
    </Navbar>
  );
};

export default HeaderSpecialityDesktop;
