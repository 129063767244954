import React, { lazy } from 'react';
import Loadable from '../Loadable';
const SpecialityOnboarding = Loadable({
  loader: () => import('../../../src/pages/uiv3/speciality/SpecialityOnboarding')
});

const SpecialitySelect = Loadable({
  loader: () => import('../../../src/pages/uiv3/dashboard/SpecialitySelector')
});

const SessionWaitingroomOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/SessionWaitingroomOutlet')
});

const AppRegister = Loadable({
  loader: () => import('../../../src/pages/uiv3/appRegister/AppRegister')
});

export const PrivateRoutes = [
  {
    path: '/Onboarding',
    component: <SpecialityOnboarding />,
    module: 'speciality'
  },
  {
    path: '/SelectSpeciality',
    component: <SpecialitySelect />,
    module: 'speciality'
  },
  {
    path: '/SessionWaitingroom',
    component: <SessionWaitingroomOutlet />,
    module: 'session'
  },
  {
    path: '/fallback',
    component: <AppRegister />,
    module: 'speciality'
  }
];
