import React, { memo, useCallback, useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import {
  RiSearchLine,
  BsBookmark,
  HiOutlineBookmark,
  AiOutlineLogout,
  IoTrophyOutline,
  TelemedLiteLogo,
  BiMapPin,
  BsBellFill,
  BsBell
} from '../../components/icons';

import { useNavigate } from 'react-router-dom';
import './css/headerMobileMain.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getMarquee } from '../../Store/actions/dashboard.action';
import {
  getLocalStorage,
  getSessionStorage,
  isIosApp,
  isMobileApp,
  setSessionStorage
} from '../../common/common';
import logo from './images/clirnetSmallLogo.png';
import { getUserPoints } from '../../Store/actions/spq.action';
import { logoutUser } from '../../Store/actions/login.action';
import { renderHeaderOnce, resetStore, toggleMoreMobile } from '../../Store/actions';
import JoyRide from '../reactJoyRide/JoyRide';
import { openModalStatus } from '../../Store/actions';
import DeleteConfirmation from '../modals/DeleteConfirmation';
import { AiOutlineQuestionCircle } from '../reactJoyRide/icons';
import HeaderSearchBar from './HeaderSearchBar';
import LazyImage from '../CustomLibraries/LazyImage';
import { fetchUnreadCount, loginToCrm } from '../../Store/actions/notification.action';
import { routeNames } from '../../router/constants';
import LoaderLine from '../LoaderLine';
function HeaderMobileMain({ page }) {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  // const points = useSelector((state) => state.common.points);
  // const point_api_called = useSelector((state) => state.common.point_api_called);
  const [vault, setVault] = useState(0);
  // const marqueeText = useSelector((state) => state.dashboard.marquee);
  const [marqueeText, setMarqueeText] = useState([]);
  const [{ deletionType, deletionModalShow }, setDeletionConfirmation] = useState({
    deletionType: '',
    deletionModalShow: false
  });
  const renderHeader = useSelector((state) => state.common.renderHeader);
  const [showTourButton, setShowTourButton] = useState(false);
  const userInfo = useSelector((state) => state.login.userDetails);

  const merqueeText = useSelector((state) => state.common.merquee);

  const dispatch = useDispatch();
  const [openModal, setOpenModel] = useState(false);
  const notifications = useSelector((state) => state.notification);
  let app_tour_data = useSelector((state) => state.appTour.app_tour_data);
  let app_tour_modal_status = useSelector((state) => state.appTour.app_tour_modal_status);

  useEffect(() => {
    console.log('merqueeText', marqueeText, merqueeText, !marqueeText && merqueeText?.length <= 0);
    if (marqueeText?.length <= 0 && !merqueeText) {
      dispatch(
        getMarquee((res) => {
          if (res != 'error') {
            setMarqueeText(res?.data);
          }
        })
      );
    }
  }, [marqueeText, merqueeText]);

  useEffect(() => {
    setSessionStorage('sessionTourVal', 2);
  }, []);

  useEffect(() => {
    if (userInfo) {
      setUser(userInfo);
      setVault(getLocalStorage('vaultCount', 0));
    } else {
      if (getLocalStorage('user', false)) {
        setUser(getLocalStorage('user', {}));
      }
      if (getLocalStorage('vaultCount', 0)) {
        setVault(getLocalStorage('vaultCount', 0));
      }
    }
  }, [renderHeader, userInfo]);

  // useEffect(() => {
  //   !point_api_called && dispatch(getUserPoints((res) => console.log(res)));
  // }, [point_api_called]);

  const logout = (type) => {
    if (type == 'logout') {
      // navigate('/');
      window.location.href = '/';
      dispatch(logoutUser());
      dispatch(resetStore());
    }
  };

  const getUnreadCount = () => {
    dispatch(
      loginToCrm((res) => {
        const { access_token } = res;
        dispatch(fetchUnreadCount(access_token, (res) => {}));
      })
    );
  };
  useEffect(() => {
    if (notifications?.count_called == false) {
      if (notifications?.accessToken) {
        dispatch(fetchUnreadCount(notifications?.accessToken, (res) => {}));
      } else {
        getUnreadCount();
      }
    }
  }, [notifications?.count_called]);

  useEffect(() => {
    if (app_tour_data) {
      app_tour_data?.data?.maxtoursession != '0' && setShowTourButton(true);
    } else {
      setShowTourButton(false);
    }
  }, [app_tour_data]);

  const onTakeTourClick = () => {
    dispatch(openModalStatus(true));
    // setOnclickTourView(1);
    setSessionStorage('sessionTourVal', 1);
  };
  const hideBtnNav = () => {
    dispatch(toggleMoreMobile(false));
  };
  const navigateToProfile = () => {
    navigate(routeNames.userProfile.landing);
    hideBtnNav();
  };
  const onNotificationClick = () => {
    !location?.pathname?.toLowerCase().includes('notification') &&
      navigate(routeNames.notification.landing);
  };

  const HandleAppReload = () => {
    window.location.reload();
  };

  return (
    <>
      <Navbar fixed="top" className="mblHeader py-0 bg-white shadow p-0 flex-column">
        <LoaderLine />
        <JoyRide />
        {isIosApp() == true ? (
          <div className="iosSpecial-safe-area-top w-100 bg-primary"></div>
        ) : (
          <></>
        )}
        <div className="w-100">
          <div className="w-100 mblNav bg-primary py-0">
            <div className="d-flex justify-content-between align-items-center h-100">
              <div
                className="mblNavLeft rounded-circle p-2 bg-white d-inline-flex justify-content-center align-items-center"
                onClick={() => {
                  hideBtnNav();
                  if (getLocalStorage('main_menu', '') == 'dnr') {
                    navigate(routeNames.discussRefer.landing);
                  } else {
                    navigate(routeNames.dashboard.landing);
                  }
                }}
              >
                <img
                  className="mw-100 mh-100 w-auto h-auto"
                  src={logo}
                  alt="clirnet-logo"
                  id="header_mobileMain_clirnet_logo gtm_cl_header_clirnet_logo"
                />
              </div>
              <Nav className="mblNavRight gap-sm-4 gap-3 align-items-center">
                {isMobileApp() && (
                  <Nav.Link
                    onClick={HandleAppReload}
                    className="bg-white mblNavRight_link fw-medium text-white rounded-circle position-relative d-flex justify-content-center align-items-center mt-2"
                    style={{ '--bs-bg-opacity': '.1' }}
                  >
                    <i className="flaticon-undo fs-1"></i>
                  </Nav.Link>
                )}

                <Nav.Link
                  onClick={onNotificationClick}
                  className="mblNotificationBtn mblNavRight_link fw-medium text-black rounded-circle bg-transparent position-relative d-flex justify-content-center align-items-center mt-2 gtm_cl_header_notification"
                  id="header_mobileMain_notification_anchor_text"
                >
                  <span className="position-relative">
                    <BsBell
                      gtmTagVariable="gtm_cl_header_notification"
                      customClass="fs-30 text-white"
                      id="header_mobileMain_notification_icon"
                    />
                    {notifications?.count > 0 && (
                      <span
                        className="notificationCount text-white bg-danger rounded-circle position-absolute d-flex justify-content-center align-items-center fs-6"
                        id="header_mobileMain_notification_text"
                      >
                        {notifications?.count ?? 0}
                      </span>
                    )}{' '}
                  </span>
                </Nav.Link>
                {showTourButton && (
                  <Nav.Link
                    onClick={onTakeTourClick}
                    className="mblTakeTourBtn mblNavRight_link shineEffect fw-medium text-black rounded-circle bg-white bg-opacity-10 position-relative d-flex justify-content-center align-items-center gtm_cl_header_takeTour"
                    id="header_mobileMain_takeTour_anchor_text"
                  >
                    <span
                      id="header_mobileMain_takeTour_icon_span"
                      className="gtm_cl_header_takeTour"
                    >
                      <BiMapPin
                        gtmTagVariable="gtm_cl_header_takeTour"
                        customClass="fs-3 text-white"
                        id={'header_mobileMain_takeTour_icon'}
                      />
                    </span>
                  </Nav.Link>
                )}

                <HeaderSearchBar />
                <Nav.Link
                  className="mblNavRight_link mblHdrProfile fs-3 rounded-circle bg-white bg-opacity-10 position-relative overflow-hidden d-flex justify-content-center align-items-center p-0 text-white gtm_cl_header_userprofile"
                  onClick={navigateToProfile}
                  id="header_mobileMain_userProfile_anchor_text"
                >
                  {user?.profile_image ? (
                    <LazyImage
                      src={user?.profile_image}
                      className="object-fit-cover w-100 h-100 float-start gtm_cl_header_userprofile"
                      alt="profile"
                      fallback_image={'https://assets.clirnet.com/common/defaultdr.png'}
                      logo
                      id={'header_mobileMain_userProfile_image'}
                    />
                  ) : (
                    user?.first_name?.charAt(0)
                  )}
                </Nav.Link>
                <NavDropdown
                  title={<i className="flaticon-more-1 fs-1"></i>}
                  className="deskNavDots"
                  onClick={() => dispatch(renderHeaderOnce())}
                >
                  <NavDropdown.Item
                    className="position-relative d-inline-flex align-items-center p-3 fs-4 gtm_cl_header_helpsupport gap-3"
                    onClick={() => {
                      hideBtnNav();
                      navigate(routeNames.helpAndSupport.landing);
                    }}
                    id="header_mobileMain_helpSupport_anchor_text"
                  >
                    <AiOutlineQuestionCircle
                      gtmTagVariable="gtm_cl_header_helpsupport"
                      customClass="fs-3"
                      id={'header_mobileMain_helpSupport_icon'}
                    />{' '}
                    Help & Support{' '}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="deskNavVault position-relative d-inline-flex align-items-center justify-content-between gap-3 gtm_cl_header_vault fs-4"
                    onClick={() => {
                      hideBtnNav();
                      navigate(routeNames.speciality.vault);
                    }}
                    id="header_mobileMain_vault_anchor_text"
                  >
                    <span className="d-flex align-items-center gap-3">
                      <HiOutlineBookmark
                        gtmTagVariable="gtm_cl_header_vault"
                        customClass="fs-3"
                        id={'header_mobileMain_vault_icon'}
                      />{' '}
                      Saved{' '}
                    </span>

                    {vault > 0 ? (
                      <span className="rounded-circle fs-5 p-1 bg-primary text-white text-center lh-base">
                        {vault}
                      </span>
                    ) : null}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() =>
                      setDeletionConfirmation({ deletionType: 'logout', deletionModalShow: true })
                    }
                    id="header_mobileMain_logout_anchor_text"
                    className="position-relative d-inline-flex align-items-center p-3 fs-4 gtm_cl_header_logout gap-3"
                  >
                    <AiOutlineLogout
                      gtmTagVariable="gtm_cl_header_logout"
                      customClass="fs-3"
                      id="header_mobileMain_logout_icon"
                    />
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </div>
          </div>
          {marqueeText && marqueeText?.length > 0 && (
            <div className="text-black fst-italic fw-semibold fs-4 mblMarquee bg-transparent w-100 float-start text-nowrap lh-lg">
              <Marquee>
                {marqueeText?.map((_m) => (
                  <span className="me-5" key={_m.id}>
                    {_m.text}
                  </span>
                ))}
              </Marquee>
            </div>
          )}
        </div>
      </Navbar>
      {deletionType && (
        <DeleteConfirmation
          title={`Are you sure to log out ?`}
          type={deletionType}
          confirm={deletionModalShow}
          handleClose={() =>
            setDeletionConfirmation({ deletionType: '', deletionModalShow: false })
          }
          confirmDeletion={logout}
        />
      )}
    </>
  );
}

export default memo(HeaderMobileMain);
