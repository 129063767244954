import axios from 'axios';
import { log } from '../../common/common';
import axiosInstance from '../helper/axiosInstance';
import { logoutUser } from './login.action';
import axiosOpenInstance from '../helper/axiosOpenInstance';
const url = process.env.REACT_APP_API_URL;
export const getSharePageQuestions = (type, type_id, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      url: `${url}openapi/sharepage?type_id=${type_id}&type=${type}`,
      method: 'GET'
    })
      .then((response) => {
        response.data && callback(response.data);
      })
      .catch((error) => {
        callback('error');
      });
  };
};
export const getShareQuestionsDetailsPage = (channel_id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`sharepage/listing?channel_id=${channel_id}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};

export const postResponseByToken = (token, channel_id, userInfoObj, otherResponseObj, callback) => {
  let tempObj = [
    {
      channel_id,
      data: [
        {
          user: [userInfoObj],
          others: [otherResponseObj]
        }
      ]
    }
  ];
  return async (dispatch) => {
    axiosOpenInstance({
      url: `${url}sharepage/sharepageresponse`,
      method: 'POST',
      headers: {
        Authorization: token
      },
      data: tempObj
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv26/openapi/sharepagelist?channel_id=16
//https://developer.clirnet.com/knowledge/rnv26/sharepage/listing?channel_id=11
//https://developer.clirnet.com/knowledge/rnv26/sharepage/sharepageresponse
// [
//   {
//     channel_id: 16,
//     data: [
//       {
//         user: [
//           {
//             name: 'olivia',
//             phone: 312345,
//             medical_council: 3,
//             registration_no: 3,
//             age: 20,
//             gender: 'male',
//             location: 'jndkc',
//             usertype: 'student'
//           }
//         ],
//         others: [
//           {
//             question1: 'njfkv',
//             question2: 'fvfnjk',
//             question3: 'asc12',
//             question4: 'jn jdf'
//           }
//         ]
//       }
//     ]
//   }
// ];
