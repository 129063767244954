import { log } from '../../common/common';
import { vaultConstants } from '../constants/vault.constant';
import { logoutUser } from './login.action';
import axiosInstance from '../helper/axiosInstance';

const getVaultSuccess = (payload) => {
  return {
    type: vaultConstants.GET_VAULT_LIST_SUCCESS,
    payload
  };
};
const getVaultFailure = (payload) => {
  return {
    type: vaultConstants.GET_VAULT_LIST_FAILURE,
    payload
  };
};
export const getVaultList = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('knwlg/myvault')
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch(getVaultSuccess(response.data));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        dispatch(getVaultFailure(error));
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
