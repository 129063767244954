import React, { memo } from 'react';
import './css/disclaimer.scss';
import { CARD_VIEW } from '../../../../components/tracking/constants';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';
const Disclaimer = ({ Disclaimer, className = '', data = {}, isDisclaimerClass = true }) => {
  if (!Disclaimer) {
    return null;
  }
  return (
    <TrackerWrapper
      data={data}
      type={CARD_VIEW}
      className={`'w-100 ${
        isDisclaimerClass ? 'disclaimer' : ''
      } rounded bg-white shadow position-relative overflow-hidden py-20 px-4 ' ${className}
        `}
    >
      <div className="w-100 disclaimerBoxIn fw-medium text-start fs-5 text-black position-relative z-1">
        <h4 className="fs-4 text-primary text-uppercase fw-semibold mb-2">Disclaimer</h4>
        <p className="fs-5 fw-normal">{Disclaimer}</p>
      </div>
    </TrackerWrapper>
  );
};

export default memo(Disclaimer);
