import React from 'react';
import Loadable from '../Loadable';

const Login = Loadable({
  loader: () => import('../../../src/pages/login/LoginNew')
});

// const Registration = Loadable({
//   loader: () => import('../../pages/registration/Registration')
// });
const UnavailableV2 = Loadable({
  loader: () => import('../../../src/pages/unavailable/UnavailableV2')
});

const NewLogin = Loadable({
  // loader: () => import('../../../src/pages/login/LoginNew')
  loader: () => import('../../../src/pages/uiv3/auth/Login')
});

export const publicRoutes = [
  {
    path: '/',
    component: <NewLogin key="login" />,
    module: 'generic'
  },
  {
    path: '/Login',
    component: <NewLogin />,
    module: 'generic'
  },
  {
    path: '/reset-password',
    subPath: [':secretCode'],
    component: <NewLogin key="resetPassword" />,
    module: 'generic'
  },
  {
    path: '/Login/:type/:data',
    component: <NewLogin />,
    subPath: ['', ':utm'],
    module: 'generic'
  },
  {
    path: '/registration',
    component: <NewLogin />,
    subPath: ['', ':email', ':email/:referal_code'],
    module: 'generic'
  },
  {
    path: '/Unavailable',
    component: <UnavailableV2 />
  },
  {
    path: '/forget-password',
    component: <NewLogin type={4} />,
    subPath: [''],
    module: 'generic'
  }
];
