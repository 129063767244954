import React, { memo } from 'react';
import useRedirect from '../customHooks/useRedirect';
import LazyImage from '../CustomLibraries/LazyImage';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import DoctorCircleCards from '../doctorCircles/DoctorCircleCards';
import './css/doctorSectionForCard.scss';
import { DEMO_USER_PICTURE, placeDemoUserPicture } from '../../common/common';
import { routeNames } from '../../router/constants';
import { useTracker } from '../../TrackerFolder/TrackerComponent';
import { CARD_VIEW, CARD_CLICK } from '../tracking/constants';
import TrackerWrapper from '../tracking/TrackerWrapper';
const DoctorsSectionForCard = ({ cardData, type, id, className = '', gtmTagVariable = '' }) => {
  // console.log(cardData, id);

  const { trackActivityEvent } = useTracker();
  const { redirectTo } = useRedirect();
  const DoctorSwiper = ({ cardData }) => {
    return (
      <Swiper
        spaceBetween={6}
        slidesPerView={'auto'}
        loop={false}
        // breakpoints={{
        //   1: {
        //     slidesPerView: 1,
        //     spaceBetween: 10
        //   },
        //   479: {
        //     slidesPerView: 2,
        //     spaceBetween: 12
        //   },
        //   767: {
        //     slidesPerView: 3,
        //     spaceBetween: 14
        //   },
        //   992: {
        //     slidesPerView: 3,
        //     spaceBetween: 16
        //   },
        //   1400: {
        //     slidesPerView: 3,
        //     spaceBetween: 16
        //   },
        //   1920: {
        //     slidesPerView: 4,
        //     spaceBetween: 20
        //   }
        // }}
      >
        {cardData?.session_doctor_entities?.map((_doc, _idx) => (
          <SwiperSlide
            key={_idx + 1}
            className="crdV1DoctorBox text-start w-auto float-start mw-100"
            onClick={() => {
              trackActivityEvent(CARD_CLICK, _doc);
              if (_doc?.session_doctor_id) {
                redirectTo(
                  routeNames.sessions.speaker,
                  _doc?.session_doctor_id,
                  _doc?.session_doctor_name
                );
              }
            }}
          >
            <TrackerWrapper
              type={CARD_VIEW}
              data={_doc}
              className="w-100 rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center py-2 px-3 border border-light"
            >
              {_doc?.session_doctor_image && (
                <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden">
                  <LazyImage
                    src={_doc?.session_doctor_image}
                    alt={_doc?.session_doctor_name}
                    className={`object-fit-cover h-100 w-100 float-start ${gtmTagVariable}_doctorsSectionForCard`}
                    // id={`${gtmTagVariable}_doctorsSectionForCard_image_${_doc?.session_doctor_id}`}
                    fallback_image={DEMO_USER_PICTURE}
                    logo
                  />
                </div>
              )}
              <div className="crdV1DoctorContent ps-4 flex-1">
                {_doc?.session_doctor_name && (
                  <h4
                    className={`text-black fs-4 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                  >
                    {_doc?.session_doctor_name}
                  </h4>
                )}
                {_doc?.DepartmentName && (
                  <p
                    className={`d-block text-dark fs-4 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                  >
                    {_doc?.DepartmentName}
                  </p>
                )}
              </div>
            </TrackerWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };
  return (
    <>
      {cardData?.session_doctor_entities?.length <= 2 && (
        <TrackerWrapper
          type={CARD_VIEW}
          data={cardData}
          className={`w-100 float-start cmncrdV1Doctor text-start ps-3 ps-md-4 cursorPointer mb-3 ${
            className ? className : ''
          }`}
        >
          {/* if single doctor ====================*/}
          {cardData?.session_doctor_entities?.length == 1 && (
            <div
              className="crdV1DoctorBox text-start w-100 float-start"
              onClick={() => {
                trackActivityEvent(CARD_CLICK, cardData);

                if (cardData?.session_doctor_entities[0]?.session_doctor_id) {
                  redirectTo(
                    routeNames.sessions.speaker,
                    cardData?.session_doctor_entities[0]?.session_doctor_id,
                    cardData?.session_doctor_entities[0]?.session_doctor_name
                  );
                }
              }}
            >
              <div className="w-100 rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center py-2 px-3 border border-light">
                <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden">
                  <LazyImage
                    src={cardData?.session_doctor_entities[0]?.session_doctor_image}
                    alt={cardData?.session_doctor_entities[0]?.session_doctor_name}
                    className={`object-fit-cover h-100 w-100 float-start ${gtmTagVariable}_doctorsSectionForCard`}
                    // id={`${gtmTagVariable}_doctorsSectionForCard_image_${cardData?.session_doctor_entities[0]?.session_doctor_id}`}
                    fallback_image={DEMO_USER_PICTURE}
                    logo
                  />
                </div>
                <div className="crdV1DoctorContent ps-4 flex-1">
                  <h4
                    className={`text-black fs-4 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                  >
                    {cardData?.session_doctor_entities[0]?.session_doctor_name}
                  </h4>
                  <p
                    className={`d-block text-dark fs-4 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                  >
                    {cardData?.session_doctor_entities[0]?.DepartmentName}
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* if single doctor ====================*/}

          {/* if multi doctor ===========*/}
          {cardData?.session_doctor_entities?.length > 1 && <DoctorSwiper cardData={cardData} />}
          {/* if multi doctor =============*/}
        </TrackerWrapper>
      )}
      {cardData?.session_doctor_entities?.length > 2 ? (
        type != 'channel_share' ? (
          <div className="w-100 float-start cmncrdV1Doctor text-start ps-3 ps-md-4 cursorPointer">
            <DoctorCircleCards
              cardType={type}
              // id={id}
              data={cardData}
              gtmTagVariable={`${gtmTagVariable}_doctorsSectionForCard`}
            />
          </div>
        ) : (
          <div className="w-100 float-start cmncrdV1Doctor text-start ps-3 ps-md-4 cursorPointer">
            <DoctorSwiper cardData={cardData} />
          </div>
        )
      ) : null}
    </>
  );
};

export default memo(DoctorsSectionForCard);
