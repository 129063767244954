import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import Loader from '../pages/ebook/components/Loader';
import ISDPicker from './ISDPicker';

function JSONFormBuilder({ fields, btn = null, disabled }) {
  const [formData, setFormData] = useState({});
  console.log('formData', fields, formData);

  useEffect(() => {
    const fieldList = {};
    fields.forEach((field) => {
      fieldList[field?.name] = field?.defaultValue || '';
    });

    setFormData(fieldList);
  }, [fields]);

  const handleChange = (name, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  return (
    <Form className="d-flex flex-column gap-5" onSubmit={(e) => btn?.onClick(formData, e)}>
      <Row>
        {fields.map((field, index) => {
          if (field.hidden) return null;

          return (
            <Col key={index} xs={12} md={field?.half ? 6 : 12} className="my-3">
              <Form.Group controlId={field?.name} key={`${field?.name}-${index}`}>
                {field?.type === 'checkbox' ? (
                  <Form.Check
                    type={field?.type}
                    label={field?.label}
                    className={field?.className}
                    defaultChecked={field?.defaultValue}
                    value={formData[field?.name]}
                    onChange={(e) => {
                      handleChange(field?.name, e.target.checked);
                      if (field?.onChange) {
                        field?.onChange(e.target.name, e.target.checked);
                      }
                    }}
                    name={field?.name}
                  />
                ) : field?.type === 'radio' ? (
                  <Form.Check
                    type={field?.type}
                    label={field?.label}
                    className={field?.className}
                    defaultChecked={field?.defaultValue === field?.value}
                    onChange={(e) => {
                      handleChange(field?.name, e.target.checked);
                      if (field?.onChange) {
                        field?.onChange(e.target.name, e.target.checked);
                      }
                    }}
                    name={field?.name}
                    value={field?.value}
                  />
                ) : field?.type === 'term&condition' ? (
                  <div className="col p-0 mt-4 d-flex align-items-center">
                    <Form.Check
                      type={'checkbox'}
                      defaultChecked={field?.defaultValue}
                      onChange={(e) => {
                        handleChange(field?.name, e.target.checked);
                        if (field?.onChange) {
                          field?.onChange(e.target.name, e.target.checked);
                        }
                      }}
                      label={field?.label}
                    />
                    <a
                      className="d-inline-block ms-3 text-secondary"
                      style={{ verticalAlign: 'top' }}
                      target="_blank"
                      href={process.env.REACT_APP_TERMS_CONDITIONS_LINK}
                    >
                      Terms and Conditions
                    </a>
                  </div>
                ) : field?.type === 'tel' ? (
                  <div className="shareLoginForm">
                    <div
                      className="col-12 shareLoginFormRow shareLoginMobileNo rounded position-relative p-0"
                      style={{ paddingLeft: '11rem' }}
                    >
                      <div
                        className={`shareLoginIsd position-absolute top-0 start-0 h-100 bg-white text-dark border border-light ${field?.className}`}
                        style={{ width: '11rem' }}
                      >
                        <ISDPicker
                          className={`w-100 h-100 pe-5 ps-3 form-control border-0 text-center ${field?.className}`}
                          onChange={(e) => {
                            handleChange('isd_code', e.target.value);
                          }}
                        />
                      </div>
                      <FloatingLabel
                        controlId="floatingInput"
                        label={
                          field?.required && field?.label
                            ? `${field?.label} *`
                            : field?.label
                            ? field?.label
                            : ''
                        }
                        style={{ marginLeft: '11rem' }}
                      >
                        <Form.Control
                          className={`fs-4 ${field?.className} rounded-0`}
                          type="text"
                          placeholder={field?.placeholder}
                          pattern="[6789][0-9]{9}"
                          value={formData[field?.name]}
                          onChange={(e) => handleChange(field?.name, e.target.value)}
                          name={field?.name}
                          defaultValue={field?.defaultValue}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                ) : (
                  <>
                    <FloatingLabel
                      controlId="floatingInput"
                      label={field?.required ? `${field?.label} *` : field?.label}
                    >
                      <Form.Control
                        type={field.type}
                        placeholder={field?.placeholder}
                        className={field?.className}
                        defaultValue={field?.defaultValue}
                        value={field?.value}
                        onChange={(e) => {
                          handleChange(field?.name, e.target.value);
                          if (field?.onChange) {
                            field?.onChange(e.target.name, e.target.value);
                          }
                        }}
                        required={field?.required}
                        name={field?.name}
                      />
                    </FloatingLabel>
                  </>
                )}
              </Form.Group>
            </Col>
          );
        })}
      </Row>

      {btn && (
        <button
          className={btn?.className}
          type="submit"
          disabled={disabled}
          onClick={() => btn?.onClick(formData)}
        >
          {disabled ? (
            <Loader isLoading={true} type="ThreeDots" color="#FFFFFF" height={25} width={30} />
          ) : (
            btn?.text
          )}
        </button>
      )}
    </Form>
  );
}

export default JSONFormBuilder;
