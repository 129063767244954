import React from 'react';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import DoctorsSectionForCard from '../../../components/DoctorSectionForCard/DoctorsSectionForCard';
import { AiFillClockCircle, CertificateIcon } from '../../../components/icons';
import { parseDuration } from '../../grTraining/utils/utilities';
import SponsorLogoSwiper from './SponsorLogoSwiper';
import { default_images } from '../../../common/defaultImages';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
import { reactHtmlParser } from '../../../common/common';
import { useDispatch } from 'react-redux';
import { toggleReference } from '../../../Store/actions';
import { removeHtmlTags } from '../../channel/utils';
const TrainingHeroCard = ({ data, gtmTagVariable = '' }) => {
  console.log('TRAINING HERO DATA', data);
  const {
    id,
    image,
    specialities,
    title,
    description,
    sponsor_logo,
    session_doctor_entities,
    duration,
    module_Count,
    resource_count = 0,
    training_module_content,
    rating,
    live_session_status
  } = data || {};

  const dispatch = useDispatch();

  function onReadMoreClick() {
    dispatch(toggleReference(true));
  }

  if (!data) return null;
  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={data}
      className="w-100 float-start rounded-3 shadow overflow-hidden bg-white shareLoginV2Featured shareLoginV2Gr p-3 p-md-4 position-relative z-1"
    >
      <div className="shareLoginV2FeaturedIn d-flex flex-column flex-sm-row align-items-center">
        <div
          className="shareLoginV2FeaturedCircle position-absolute rounded-circle"
          style={{ backgroundColor: '#355fd3' }}
        ></div>
        <div className="shareLoginV2FeaturedPic position-relative h-auto pe-md-4 pb-md-4">
          <div className="shareLoginV2FeaturedPicIn rounded-3 position-relative overflow-hidden z-1">
            <LazyImage
              src={image ?? default_images.training}
              className="object-fit-cover w-100 h-100 float-start"
              fallback_image={default_images.training}
            />
          </div>
        </div>
        <div className="w-100 float-start shareLoginV2FeaturedRight text-start ps-0 ps-md-20 mt-4 mt-md-0">
          <div className="d-flex gap-md-4 gap-3 flex-wrap mb-md-5 mb-20">
            <div className="p-3 pe-4 bg-white flex-md-grow-0 flex-grow-1 rounded-3 shadow d-flex align-items-start gap-3">
              <div className="d-inline-flex align-items-center justify-content-center rounded-circle icon-size-36 bg-opacity-25 bg-secondary text-secondary">
                <i className="flaticon-layers fs-3"></i>
              </div>
              <div className="d-flex flex-column lh-1 gap-1">
                <span className="text-secondary fw-semibold fs-3">{module_Count}</span>
                <span className="text-black">Modules</span>
              </div>
            </div>
            <div className="p-3 pe-4 bg-white flex-md-grow-0 flex-grow-1 rounded-3 shadow d-flex align-items-start gap-3">
              <div className="d-inline-flex align-items-center justify-content-center rounded-circle icon-size-36 bg-opacity-25 bg-warning text-warning">
                <i className="flaticon-clock fs-3"></i>
              </div>
              <div className="d-flex flex-column lh-1 gap-1">
                <span className="text-warning fw-semibold fs-3">
                  {' '}
                  {parseDuration(duration)?.duration}
                </span>
                <span className="text-black"> Duration ({parseDuration(duration)?.unit})</span>
              </div>
            </div>
            {session_doctor_entities?.length > 0 && (
              <div className="p-3 pe-4 bg-white flex-md-grow-0 flex-grow-1 rounded-3 shadow d-flex align-items-start gap-3">
                <div className="d-inline-flex align-items-center justify-content-center rounded-circle icon-size-36 bg-opacity-25 bg-primary text-primary">
                  <i className="flaticon-stethoscope fs-3"></i>
                </div>
                <div className="d-flex flex-column lh-1 gap-1">
                  <span className="text-primary fw-semibold fs-3">
                    {session_doctor_entities?.length ?? 0}
                  </span>
                  <span className="text-black">Master Doctor</span>
                </div>
              </div>
            )}
            {resource_count > 0 && (
              <div className="p-3 pe-4 bg-white flex-md-grow-0 flex-grow-1 rounded-3 shadow d-flex align-items-start gap-3">
                <div className="d-inline-flex align-items-center justify-content-center rounded-circle icon-size-36 bg-opacity-25 bg-danger text-danger">
                  <i className="flaticon-attach-paperclip-symbol fs-3"></i>
                </div>
                <div className="d-flex flex-column lh-1 gap-1">
                  <span className="text-danger fw-semibold fs-3">{resource_count}</span>
                  <span className="text-black">Resources</span>
                </div>
              </div>
            )}
          </div>
          {/* {specialities && (
            <span className="text-black mb-3">
              <span className="fs-4 cursorPointer">{specialities?.split(',')[0]}</span>
              {specialityPopOver(specialities)}
            </span>
          )} */}

          <div className="crdV1topPart d-flex align-items-center justify-content-between">
            {specialities && (
              <div className="text-black crdV1Speclty">
                <span className="text-black mb-3">
                  <span className="fs-4 cursorPointer">{specialities?.split(',')[0]}</span>
                </span>
              </div>
            )}
            {/* <a href="javascript:void(0)" className="cardV1DotsMenu text-black">
              <svg className="text-black fs-2" strokeWidth="0" viewBox="0 0 1024 1024">
                <path d="M752 664c-28.5 0-54.8 10-75.4 26.7L469.4 540.8a160.68 160.68 0 0 0 0-57.6l207.2-149.9C697.2 350 723.5 360 752 360c66.2 0 120-53.8 120-120s-53.8-120-120-120-120 53.8-120 120c0 11.6 1.6 22.7 4.7 33.3L439.9 415.8C410.7 377.1 364.3 352 312 352c-88.4 0-160 71.6-160 160s71.6 160 160 160c52.3 0 98.7-25.1 127.9-63.8l196.8 142.5c-3.1 10.6-4.7 21.8-4.7 33.3 0 66.2 53.8 120 120 120s120-53.8 120-120-53.8-120-120-120zm0-476c28.7 0 52 23.3 52 52s-23.3 52-52 52-52-23.3-52-52 23.3-52 52-52zM312 600c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88zm440 236c-28.7 0-52-23.3-52-52s23.3-52 52-52 52 23.3 52 52-23.3 52-52 52z"></path>
              </svg>
            </a> */}
          </div>

          <h2 className="fs-16 fw-medium text-black my-3">{title}</h2>
          {description && (
            <h4 className="line-clamp-2 fs-4 fw-normal text-dark">
              {removeHtmlTags(description)?.substring(0, 180)}
              {removeHtmlTags(description)?.length > 180 && (
                <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                  {' '}
                  ...Read More
                </a>
              )}
            </h4>
          )}
          <div className="grShareFeaturedCrdFacility d-flex flex-wrap align-items-center d-flex flex-wrap mt-4 gap-md-20 gap-4">
            {/* <span className="me-4 mb-4">
              <span className="icon me-3">
                <AiOutlineHeart customClass="fs-1 text-dark" />
              </span>
              {rating ?? 0}
            </span> */}
            <span className="d-flex gap-2 flex-shrink-0 align-items-center">
              <CertificateIcon customClass="fs-1 text-dark" />
              Certificate of Completion
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            {session_doctor_entities && session_doctor_entities?.length > 0 && (
              <div className="shareLogineV2Doctor">
                <DoctorsSectionForCard
                  gtmTagVariable={`${gtmTagVariable}_trainingHeroCard`}
                  cardData={{ session_doctor_entities }}
                  type={'training'}
                  id={id}
                />
              </div>
            )}
            {sponsor_logo && (
              <SponsorLogoSwiper sponsorLogo={sponsor_logo ? sponsor_logo.split(',') : []} />
            )}
          </div>
        </div>
      </div>
    </TrackerWrapper>
  );
};

export default TrainingHeroCard;
