import React, { useEffect, useState, useRef, memo } from 'react';
import { InView } from 'react-intersection-observer';

const LeftSideBar = (props) => {
  const [eleHeight, setEleHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const rightSidebarRef = useRef();
  useEffect(() => {
    if (document.getElementById('headerDesktopNavBar')) {
      setEleHeight(document.getElementById('headerDesktopNavBar').offsetHeight);
    }
  }, []);
  // useEffect(() => {
  //   function updatePosition() {
  //     if (!isSticky) {
  //       setScrollHeight({ scrollX: window.scrollX, scrollY: window.scrollY });
  //     }
  //   }
  //   window.addEventListener('scroll', updatePosition);
  //   updatePosition();

  //   return () => window.removeEventListener('scroll', updatePosition);
  // }, [isSticky]);

  return (
    <div
      className={`col-lg-3 d-lg-flex flex-column ${
        props.layout == 'observership-chat' ? 'd-flex ps-3 ps-md-4 pe-md-0' : 'd-none ps-4 pe-0'
      } z-0 clr_v3_leftSideBar position-sticky ${props.className ? props.className : ''}`}
      // ${isSticky ? 'position-sticky' : ''}
      style={props?.style ? props?.style : { top: `${eleHeight + 15 - scrollHeight.scrollY}px` }}
      ref={rightSidebarRef}
    >
      <div
        className={`${
          props.layout == 'observership-chat' ? 'h-100' : ''
        } w-100 d-flex flex-column gap-lg-4 gap-3`}
      >
        {props.children}
      </div>
      <InView as="div" onChange={(inView, entry) => setIsSticky(inView)}>
        <div></div>
      </InView>
    </div>
  );
};

export default memo(LeftSideBar);
