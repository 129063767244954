import axios from 'axios';
import pako from 'pako';
import packageJson from '../../../package.json';
import { isMobileApp } from '../../common/common';
import { safeJsonParser } from '../../utils/common';
const baseURL = process.env.REACT_APP_API_URL;
const apiVersion = packageJson.version;

const axiosOpenInstance = axios.create({
  baseURL,
  headers: {
    version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
  }
});

axiosOpenInstance.interceptors.response.use((response) => {
  try {
    var bytes = window
      .atob(response.data)
      .split('')
      .map(function (c) {
        return c.charCodeAt(0);
      });

    var inflated = pako.inflate(bytes, { to: 'string' });
    response.data = safeJsonParser(inflated, {});
    return response;
  } catch {
    return response;
  }
});
export default axiosOpenInstance;
