import dayjs from 'dayjs';
import 'firebase/compat/database';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getDDXRatingApi,
  getGenerateDDXApi,
  useGetAidaHistoryList,
  useGetAidaSuggestionResult,
  useGetDDXById,
  useGetImageToText
} from '../../../Queries/QueryHooks/aida.hook';
import { getFcmToken } from '../../../Store/actions';
import TrackerComponent, { useTracker } from '../../../TrackerFolder/TrackerComponent';
import '../../../assets/sass/uiV3/pages/observership/_observership.scss';
import {
  getLocalStorage,
  handleCompressedUpload,
  isFileAllowed,
  isIosApp,
  isUserLoggedIn,
  reactHtmlParser,
  userDetails
} from '../../../common/common';
import { firebaseUpload } from '../../../common/firebase';
import { pageTitles } from '../../../common/pageTitles';
import BackdropComponent from '../../../components/BackdropComponent';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import MainContainer from '../../../components/baseUiV3/template/pageContainer/MainContainer';
import RightContainer from '../../../components/baseUiV3/template/pageContainer/RightContainer';
import '../../../components/centerModal/css/centerModal.scss';
import useGetParams from '../../../components/customHooks/useGetParams';
import useManualScrollRestoration from '../../../components/customHooks/useManualScrollRestoration';
import useRedirect from '../../../components/customHooks/useRedirect';
import { ImFilePdf, IoDocumentTextSharp } from '../../../components/icons';
import { Seo } from '../../../components/seo/Seo';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_CLICK, LIKE_CLICK, SUBMIT_CLICK } from '../../../components/tracking/constants';
import LeftSideBar from '../../../layout/uiv3/defaultLayout/components/LeftSideBar';
import { safeJsonParser } from '../../../utils/common';
import FilePreviewModal from '../../uiv3/observership/components/FilePreviewModal';
import RightSideContent from '../../uiv3/profile/components/RightSideContent';
import HistoryListing from './HistoryListing';

const pageName = pageTitles.Observership.chat;
const keyFormat = 'DD-MM-YYYY';

function AidaChatScreen({ share = false }) {
  const { redirectTo } = useRedirect();

  const [liked, setLiked] = useState(false);
  const [offset, setOffset] = useState(0);
  const [question, setQuestion] = useState();
  const [eleHeight, setEleHeight] = useState(0);
  const [messages, setMessages] = useState({});
  const [selectedChathead, setSelectedChathead] = useState(null);
  const [isOnChatScreen, setIsOnChatScreen] = useState(true);
  const { search, state } = useLocation();
  const { id, title } = useGetParams();
  const queryClient = useQueryClient();
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [selectedChat, setSelectedChat] = useState({});
  const [currentDDXId, setCurrentDDXId] = useState(null);
  const [aidaSuggestionPayload, setAidaSuggestionPayload] = useState({});
  const [creatingDDX, setCreatingDDX] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [demoMessage, setDemoMessage] = useState(null);
  const { trackActivityEvent } = useTracker();
  const [firebaseToken, setFirebaseToken] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFcmToken(setFirebaseToken));
  }, []);

  function formatMessage(sent_by, message, fulldata, extra) {
    console.log('message', message);
    var payload = {
      sent_by: sent_by,
      message: message,
      file_url: '',
      message_sent_at: new Date().toISOString(),
      isRead: false,
      fulldata: fulldata,
      ...extra
    };

    if (typeof message === 'string') {
      payload.message = message;
    } else if (typeof message === 'object') {
      payload.message = `<div className="${
        'ref_question' in message ? 'bg-white bg-opacity-75 p-3 mb-2 mt-1 rounded' : ''
      }"><div className=" d-flex justify-content-between align-items-start gap-2 mb-1">
                    <span className="fs-5">DDX <strong>(${message.Diagnosis || ''})</strong></span>
                    <span className="flex-shrink-0 observer__chatList__probability badge ${
                      parseInt(message.probability) > 70
                        ? 'bg-soft-success'
                        : parseInt(message.probability) > 30 && parseInt(message.probability) < 70
                        ? 'bg-soft-warning'
                        : 'bg-soft-danger'
                    }  d-flex gap-2 align-items-center mt-n20">
                    <i className="flaticon-target"></i> probability ${
                      message.probability || ''
                    }%</span>
                  </div>
                  <h4 className="mb-2"><strong>Reason of Diagnoses</strong><br>
                  ${message.Reason_of_diagnosis || ''}</h4>
                  <h4><strong>Treatment Options</strong><br>
                  ${message.Treatment_Options || ''}</h4></div></div><div>${
        message.ref_question || ''
      }</div>`;
    } else {
      redirectTo('Unavailable');
    }

    return payload;
  }

  const { data: aidaShareDetails, isLoading: isAidaShareDetailsLoading } = useGetDDXById(id, () => {
    redirectTo('Unavailable');
  });

  const {
    data: aidaHistoryList,
    isLoading: isAidaHistoryListLoading,
    refetch
  } = useGetAidaHistoryList(offset);
  const { data: aidaSuggestionData, isLoading: aidaSuggestionLoading } = useGetAidaSuggestionResult(
    aidaSuggestionPayload,
    () => {
      refetch();
    }
  );
  const showSuggestionModalClose = () => {
    setShowSuggestionModal(false);
  };

  useEffect(() => {
    if (!aidaSuggestionLoading && aidaSuggestionData) {
      if (Array.isArray(aidaSuggestionData)) {
        const today = dayjs().format(keyFormat);
        const tempMessages = { ...messages };

        aidaSuggestionData.forEach((item) => {
          const payload = formatMessage('bot', item?.answer, item);

          if (today in tempMessages) {
            tempMessages[today] = [...tempMessages[today], payload];
          } else {
            tempMessages[today] = [payload];
          }
        });

        setMessages(tempMessages);
      }
    }
  }, [aidaSuggestionData, aidaSuggestionLoading]);

  useEffect(() => {
    if (!isAidaShareDetailsLoading && id) {
      setSelectedChathead(aidaShareDetails);
      setQuestion(aidaShareDetails?.question);
      setCurrentDDXId(aidaShareDetails?.ddx_id);
      const data = safeJsonParser(aidaShareDetails?.answer, {});
      const answers = [];
      const { response } = aidaShareDetails || {};

      data?.D_list?.map((item) => {
        answers.push(formatMessage('bot', item, item, { ddx: true }));
      });

      if (response && Array.isArray(response)) {
        response.forEach((_elem) => {
          const ref_answer = safeJsonParser(_elem?.ref_answer, {});
          console.log('_elem', ref_answer);

          if ('direct_message' in ref_answer) {
            answers.push(formatMessage(userDetails()?.user_master_id, _elem?.ref_ques, _elem));
          } else {
            answers.push(
              formatMessage(
                userDetails()?.user_master_id,
                {
                  ref_question: _elem?.ref_ques,
                  Diagnosis: _elem?.ref_answer?.Diagnosis,
                  Reason_of_diagnosis: _elem?.ref_answer?.Reason_of_diagnosis,
                  Treatment_Options: _elem?.ref_answer?.Treatment_Options,
                  probability: _elem?.ref_answer?.probability
                },
                _elem
              )
            );
          }

          const { answers_list } = _elem || {};

          answers_list.forEach((_answers) => {
            answers.push(formatMessage('bot', _answers?.answer, _answers));
          });
        });
      }

      setMessages({
        [dayjs(aidaShareDetails.added_on || new Date()).format(keyFormat)]: [
          formatMessage(userDetails().user_master_id, aidaShareDetails.question),
          ...answers
        ]
      });
    }
  }, [aidaShareDetails, isAidaShareDetailsLoading, id]);

  useEffect(() => {
    if (document.getElementById('headerDesktopNavBar')) {
      setEleHeight(document.getElementById('headerDesktopNavBar')?.offsetHeight);
    }
    // updatePosition();
  }, []);

  const onBackBtnClick = () => {
    setIsOnChatScreen((prev) => !prev);
  };
  const onChatStart = useCallback(() => {
    setIsOnChatScreen(true);
  }, []);
  console.log('is on chat', isOnChatScreen);
  const messagesContainerRef = useRef();

  const scrollToLastMessage = () => {
    if (messagesContainerRef.current && messagesContainerRef.current.scrollHeight) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [
    messages,
    liked,
    aidaHistoryList,
    aidaSuggestionPayload,
    creatingDDX,
    aidaSuggestionLoading,
    currentDDXId,
    selectedChat,
    selectedChathead
  ]);

  useEffect(() => {
    scrollToLastMessage();
  }, []);

  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef();

  useEffect(() => {
    if (footerRef?.current) {
      setFooterHeight(footerRef?.current?.clientHeight);
    }
  }, [footerRef?.current]);

  console.log('messages', messages);

  const generateDDX = (query) => {
    if (!query) return;
    setCreatingDDX(true);
    setQuestion(query);
    const accessToken = getLocalStorage('accessToken', '') || null;
    const API_URL = getGenerateDDXApi();
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchStreamingData = async () => {
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            chief_complaint: query,
            token: accessToken
          }),
          signal
        });

        if (!response.ok) throw new Error('Failed to fetch data');
        if (!response.body) throw new Error('Response body is null');

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let accumulatedData = '';
        const today = dayjs().format(keyFormat);

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          accumulatedData += decoder.decode(value, { stream: true });

          const lines = accumulatedData.split('\n');
          accumulatedData = lines.pop();

          for (const line of lines) {
            if (line.trim() === 'data: [DONE]') {
              setCreatingDDX(false);

              setMessages((prevMessages) => {
                const tempMessages = { ...prevMessages }[today];

                const lastDdxIndex = tempMessages.reduce((lastIndex, item, currentIndex) => {
                  return item?.ddx ? currentIndex : lastIndex;
                }, -1);

                if (lastDdxIndex !== -1) {
                  tempMessages[lastDdxIndex] = {
                    ...tempMessages[lastDdxIndex],
                    showRating: true
                  };
                }
                return { ...prevMessages, [today]: tempMessages };
              });

              refetch();

              return;
            }

            try {
              const cleanJson = line.replace(/^data:\s*/, '');
              const parsedData = JSON.parse(cleanJson);

              // Use functional state update to prevent race conditions
              setMessages((prevMessages) => {
                const tempMessages = { ...prevMessages };

                if (Array.isArray(parsedData.D_list) && parsedData.D_list.length > 0) {
                  // Remove all bot messages from today's messages
                  if (today in tempMessages) {
                    tempMessages[today] = tempMessages[today].filter(
                      (msg) => msg.sent_by !== 'bot'
                    );
                  }

                  // Format and add new bot messages
                  const formattedDList = parsedData.D_list.map((item) =>
                    formatMessage('bot', item, item, { ddx: true })
                  );

                  if (today in tempMessages) {
                    tempMessages[today] = [...tempMessages[today], ...formattedDList];
                  } else {
                    tempMessages[today] = formattedDList;
                  }
                }

                return tempMessages;
              });
              console.log('parsedData', parsedData);

              if (parsedData.ddx_id) {
                setSelectedChathead({
                  ddx_id: parsedData.ddx_id,
                  question: question
                });
                setCurrentDDXId(parsedData.ddx_id);
              }
            } catch (error) {
              console.warn('JSON parse error:', error);
            }
          }
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Unexpected error:', error);
        }
      }
    };

    fetchStreamingData();
  };

  function sendMessage(payload = null, callGenerate = false) {
    const tempMessages = { ...messages };
    const data = payload ? payload : {};
    const today = dayjs().format(keyFormat);

    if (messages && today in messages) {
      //if conversation exists in that day
      tempMessages[today] = [...tempMessages[today], data];
    } else {
      //if no conversation is present for that day
      tempMessages[today] = [data];
    }

    setMessages(tempMessages);

    if (callGenerate) {
      generateDDX(payload.message);
    }
  }

  const showDemoModalOpen = () => {
    trackActivityEvent(SUBMIT_CLICK, {});
    setShowDemoModal(true);
  };
  const showDemoModalClose = () => setShowDemoModal(false);

  const likedDDXHandler = async (value) => {
    // value = 0 -> dislike, value = 1 -> like
    const userId = getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0;
    const API_URL = getDDXRatingApi();
    const controller = new AbortController();
    const signal = controller.signal;
    const today = dayjs().format(keyFormat);
    if (liked) return;
    if (value === 1) {
      // Like action
      setLiked(true);
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ddx_id: currentDDXId, rating: value, user_id: userId }),
          signal
        });

        if (!response.ok) throw new Error('Failed to send like request');

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const text = decoder.decode(value, { stream: true });
          if (text.trim() === 'data: [DONE]') {
            return;
          }
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error in like request:', error);
        }
      }
    } else {
      setCreatingDDX(true);
      // setIsLiked(false);

      //remove messages that are ddx:true

      setMessages({});

      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ddx_id: currentDDXId, rating: value, user_id: userId }),
          signal
        });

        if (!response.ok) throw new Error('Failed to send dislike request');

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let accumulatedData = '';

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          accumulatedData += decoder.decode(value, { stream: true });

          const lines = accumulatedData.split('\n');
          accumulatedData = lines.pop();

          for (const line of lines) {
            if (line.trim() === 'data: [DONE]') {
              setCreatingDDX(false);

              setMessages((prevMessages) => {
                const tempMessages = { ...prevMessages }[today];

                const lastDdxIndex = tempMessages.reduce((lastIndex, item, currentIndex) => {
                  return item?.ddx ? currentIndex : lastIndex;
                }, -1);

                if (lastDdxIndex !== -1) {
                  tempMessages[lastDdxIndex] = {
                    ...tempMessages[lastDdxIndex],
                    showRating: true
                  };
                }
                return { ...prevMessages, [today]: tempMessages };
              });

              refetch();

              return;
            }

            try {
              const cleanJson = line.replace(/^data:\s*/, '');
              const parsedData = JSON.parse(cleanJson);

              // Use functional state update to prevent race conditions
              setMessages((prevMessages) => {
                const tempMessages = { ...prevMessages };

                if (Array.isArray(parsedData.D_list) && parsedData.D_list.length > 0) {
                  // Remove all bot messages from today's messages
                  if (today in tempMessages) {
                    tempMessages[today] = tempMessages[today].filter(
                      (msg) => msg.sent_by !== 'bot'
                    );
                  }

                  // Format and add new bot messages
                  const formattedDList = parsedData.D_list.map((item) =>
                    formatMessage('bot', item, item, { ddx: true })
                  );

                  if (today in tempMessages) {
                    tempMessages[today] = [...tempMessages[today], ...formattedDList];
                  } else {
                    tempMessages[today] = formattedDList;
                  }
                }

                return tempMessages;
              });
              console.log('parsedData', parsedData);

              if (parsedData.ddx_id) {
                setSelectedChathead({
                  ddx_id: parsedData.ddx_id,
                  question: question
                });
                setCurrentDDXId(parsedData.ddx_id);
              }
            } catch (error) {
              console.warn('JSON parse error:', error);
            }
          }
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Streaming error:', error);
        }
      } finally {
      }
    }
  };

  const ddxLikedHandler = () => {
    trackActivityEvent(LIKE_CLICK, {});
    likedDDXHandler(1);
    // setIsLiked(true);
  };

  function Wrapper({ children }) {
    if (share) {
      return children;
    } else {
      return (
        <MainContainer
          className={`${isMobile ? 'clr_v3_aidaChat--maincontentMobile' : 'position-sticky'}`}
        >
          {children}
        </MainContainer>
      );
    }
  }
  useManualScrollRestoration();

  return (
    <>
      <TrackerComponent page_name={'ddx'} module_page="ddx" />
      <Seo title={`CLIRNET - ${'AiDA'}`} url={window.location.href} />
      {!share && (
        <LeftSideBar
          className={`clr_v3_aidaChat--leftContent test ${!isOnChatScreen ? ' show' : ''}`}
          layout="observership-chat"
          // style={{ top: `${eleHeight + 15}px` }}
          style={
            isMobile
              ? { height: `100vh` }
              : { height: `calc(100vh - ${eleHeight + 30}px)`, top: `${eleHeight + 15}px` }
          }
        >
          <HistoryListing
            handleStartNewChat={() => {
              setIsOnChatScreen(true);
              setMessages({});
              setQuestion(null);
              setSelectedChat({});
              setCurrentDDXId(null);
              setSelectedChathead(null);
            }}
            onCardClick={onChatStart}
            setSelectedChathead={setSelectedChathead}
            selectedChathead={selectedChathead}
            selectIcon={'chat'}
            onBackBtnClick={onBackBtnClick}
            contentClick={(fulldata) => {
              trackActivityEvent(CARD_CLICK, { type: 'ddx', ...fulldata });
              const { response } = fulldata;
              setSelectedChathead(fulldata);
              setQuestion(fulldata?.question);
              setCurrentDDXId(fulldata?.ddx_id);
              const data = safeJsonParser(fulldata.answer);
              const answers = [];

              data.D_list.map((item) => {
                answers.push(formatMessage('bot', item, item, { ddx: true }));
              });

              if (response && Array.isArray(response)) {
                response.forEach((_elem) => {
                  const ref_answer = safeJsonParser(_elem?.ref_answer, {});
                  console.log('_elem', ref_answer);

                  if ('direct_message' in ref_answer) {
                    answers.push(
                      formatMessage(userDetails()?.user_master_id, _elem?.ref_ques, _elem)
                    );
                  } else {
                    answers.push(
                      formatMessage(
                        userDetails()?.user_master_id,
                        {
                          ref_question: _elem?.ref_ques,
                          Diagnosis: _elem?.ref_answer?.Diagnosis,
                          Reason_of_diagnosis: _elem?.ref_answer?.Reason_of_diagnosis,
                          Treatment_Options: _elem?.ref_answer?.Treatment_Options,
                          probability: _elem?.ref_answer?.probability
                        },
                        _elem
                      )
                    );
                  }

                  const { answers_list } = _elem || {};

                  answers_list.forEach((_answers) => {
                    answers.push(formatMessage('bot', _answers?.answer, _answers));
                  });
                });
              }

              setMessages({
                [dayjs(fulldata.added_on).format(keyFormat)]: [
                  formatMessage(userDetails().user_master_id, fulldata.question),
                  ...answers
                ]
              });

              setIsOnChatScreen(true);
            }}
            aidaHistoryList={aidaHistoryList}
            isAidaHistoryListLoading={isAidaHistoryListLoading}
            question={question}
          />
        </LeftSideBar>
      )}
      <Wrapper>
        <div
          className={`w-100 d-flex bg-white flex-column rounded-4 overflow-hidden position-relative'
            `}
          style={
            !isMobile
              ? { height: `calc(100vh - ${eleHeight}px - 30px)` }
              : {
                  height: `calc(100vh - ${eleHeight}px - 20px)`,
                  marginTop: `10px`
                  // paddingBottom: `${footerRef?.current?.clientHeight}px`
                }
          }
        >
          <header
            className={`flex-shrink-0 w-100 d-flex flex-column gap-3 h-auto cursorPointer p-3 px-md-4 ${
              isMobile ? 'border-bottom border-extra-light' : ''
            }`}
          >
            {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100"></div> : <></>}

            <div className="gap-2 align-items-center d-flex flex-grow-1 w-100 ">
              {/* <div className="flex-shrink-0 me-2 rounded-circle overflow-hidden d-flex align-items-center justify-content-center ms-n2 ms-md-0 icon-size-36 icon-size-md-48 bg-primary ">
                {selectedChathead?.profile_image ? (
                  <LazyImage
                    src={selectedChathead?.profile_image}
                    width="36"
                    height="36"
                    alt={selectedChathead?.first_name}
                    className="object-fit-cover w-100 h-100"
                  />
                ) : (
                  <h4 className="fs-2 text-white">{selectedChathead?.first_name?.charAt(0)}</h4>
                )}
              </div> */}
              <div
                className={`d-flex flex-row transition flex-grow-1 gap-3 ${isMobile ? 'fs-5' : ''}`}
              >
                <h4 className="fs-4 text-black fw-semibold line-clamp-1">{question}</h4>
              </div>
              {question ? (
                <></>
              ) : (
                <Button
                  variant={`${isMobile ? 'link text-decoration-underline' : 'outline-primary'}`}
                  size="sm"
                  className="fs-5 flex-shrink-0 text-nowrap"
                  onClick={showDemoModalOpen}
                >
                  Show examples
                </Button>
              )}
              {isMobile && (
                <Button
                  variant={'light'}
                  size="sm"
                  className={`flex-shrink-0 d-flex align-items-center justify-content-center icon-size-32 fs-1 p-0 rounded-3 text-black`}
                  onClick={onBackBtnClick}
                >
                  {/* tooltip for mobile */}
                  <i className="flaticon-history fs-4"></i>
                </Button>
              )}
            </div>
          </header>
          <main
            className={`w-100 d-flex flex-column gap-2 p-3 flex-grow-1 ${
              share ? 'overflow-hidden' : 'overflow-auto'
            }`}
            ref={messagesContainerRef}
          >
            {!question &&
            messages &&
            Object.keys(messages) &&
            Array.isArray(Object.keys(messages)) &&
            Object.keys(messages).length == 0 ? (
              <>
                <EmptyChatBox
                  sendMessage={(message) => {
                    setDemoMessage(message);
                  }}
                />
              </>
            ) : (
              <>
                {messages &&
                  Object.keys(messages).map((date, _ind) => (
                    <div className="flex-column d-flex gap-3" key={_ind}>
                      {/* <DisplayDate date={date} /> */}
                      {messages &&
                        date in messages &&
                        Array.isArray(messages[date]) &&
                        messages[date].map((item, ind) => (
                          <div
                            className="d-flex flex-column gap-3 position-relative cursorPointer"
                            key={ind}
                          >
                            <div
                              className={`${
                                item.sent_by === userDetails().user_master_id
                                  ? 'observer__chatListItem observer__chatListItem--right'
                                  : 'observer__chatListItem border'
                              } py-2 px-3  position-relative d-flex flex-column gap-1`}
                              key={ind}
                              onClick={() => {
                                if ('Reason_of_diagnosis' in item?.fulldata) {
                                  if (!share) {
                                    setShowSuggestionModal(true);
                                    setSelectedChat(item?.fulldata);
                                  }
                                }
                              }}
                            >
                              <div className="d-flex justify-content-between fs-5  align-items-center gap-3">
                                <span className="text-black fw-medium mb-1">
                                  {dayjs(item.message_sent_at).format('hh:mm a')}
                                </span>
                              </div>

                              {item.file_url != '' ? (
                                <a
                                  // href={item.file_url}
                                  // target="_blank"
                                  href={'javascript:void(0)'}
                                  className={`d-flex gap-3 p-2  rounded-2 bg-black bg-opacity-10 text-black align-items-center hover-opacity mt-2 ${
                                    item.sent_by === userDetails().user_master_id
                                      ? 'ms-auto'
                                      : 'me-auto'
                                  }`}
                                >
                                  <div className="flex-shrink-0 p-2 d-flex align-items-center justify-content-center bg-white text-black rounded-2">
                                    <ReturnIcon file={item.message} fileUrl={item.file_url} />
                                  </div>
                                </a>
                              ) : (
                                <div>{reactHtmlParser(item.message)}</div>
                              )}
                            </div>

                            {item?.showRating && (
                              <div className="d-flex justify-content-between mb-3">
                                <p className="m-0">Please rate the above DDx</p>
                                <div className="d-flex gap-4">
                                  <i
                                    className={`${
                                      liked ? 'flaticon-like-1 text-success' : 'flaticon-like'
                                    } fs-3 cursorPointer`}
                                    onClick={ddxLikedHandler}
                                  ></i>

                                  <i
                                    className="flaticon-dislike fs-3 cursorPointer"
                                    onClick={() => likedDDXHandler(0)}
                                  ></i>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  ))}

                {(aidaSuggestionLoading || creatingDDX) && (
                  <Loader
                    className="text-center text-primary opacity-75"
                    color="currentColor"
                    type="ThreeDots"
                    height={60}
                    width={60}
                  />
                )}
              </>
            )}
          </main>

          {!share && (
            <footer
              className={`d-flex flex-shrink-0 gap-1 flex-column mt-auto m-3 pt-3 z-1 bg-white position-relative ${
                isMobile ? 'border-top border-extra-light' : ''
              }`}
              ref={footerRef}
            >
              <ChatBox
                sendMessage={sendMessage}
                messages={messages}
                question={question}
                setAidaSuggestionPayload={setAidaSuggestionPayload}
                ddxId={currentDDXId}
                demoMessage={demoMessage}
                setDemoMessage={setDemoMessage}
                share={share}
                refetch={refetch}
                scrollToLastMessage={scrollToLastMessage}
                firebaseToken={firebaseToken}
                formatMessage={formatMessage}
              />
            </footer>
          )}
        </div>
        <SuggestionModal
          showSuggestionModal={showSuggestionModal}
          showSuggestionModalClose={showSuggestionModalClose}
          data={selectedChat}
          setAidaSuggestionPayload={setAidaSuggestionPayload}
          ddxId={currentDDXId}
          chiefComplaint={question}
          handleSuggestionClick={sendMessage}
          formatMessage={formatMessage}
        />
        <ExampleModal
          showDemoModal={showDemoModal}
          showDemoModalClose={showDemoModalClose}
          showDemoHandler={(message) => {
            sendMessage(formatMessage(userDetails()?.user_master_id, message), true);
          }}
        />
      </Wrapper>

      {!share && (
        <RightContainer>
          <RightSideContent />
        </RightContainer>
      )}
    </>
  );
}

export default memo(AidaChatScreen);
const ReturnIcon = memo(({ file, fileUrl }) => {
  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  const type = get_url_extension(fileUrl);

  const [showPreview, setShowPreview] = useState(false);
  function handleShow() {
    setShowPreview(true);
  }
  function handleClose() {
    setShowPreview(false);
  }
  switch (type) {
    case 'pdf':
      return (
        <>
          <ImFilePdf customClass={'icon-size-48 text-danger'} onClick={handleShow} />;
          {file && (
            <FilePreviewModal
              show={showPreview}
              fileUrl={fileUrl}
              onHide={handleClose}
              isPdf={type === 'pdf'}
            />
          )}
        </>
      );
    case 'jpg':
    case 'jpeg':
    case 'png':
      // return <BsImage customClass={'icon-size-48 text-primary'} />;
      return (
        <>
          <LazyImage
            src={fileUrl}
            alt=""
            className={`object-fit-cover w-auto h-auto mw-100 mh-100 rounded-2`}
            extraClass="hover-image icon-size-60 position-relative d-flex align-items-center justify-content-center"
            click={handleShow}
          />
          {file && (
            <FilePreviewModal
              show={showPreview}
              fileUrl={fileUrl}
              onHide={handleClose}
              isPdf={file.type === 'application/pdf'}
            />
          )}
        </>
      );
    default:
      return (
        <a href={fileUrl} target="_blank">
          <IoDocumentTextSharp customClass={'icon-size-48 text-secondary'} />
        </a>
      );
  }
});

const DisplayDate = memo(({ date: inputDate }) => {
  console.log('inputDate', inputDate);
  const date = inputDate ? dayjs(inputDate, keyFormat) : dayjs(); // Use input date or default to today
  const today = dayjs().format('DD MMM, YYYY');
  const yesterday = dayjs().subtract(1, 'day').format('DD MMM, YYYY');

  if (date.isSame(dayjs(), 'day')) {
    return (
      <span className=" mx-auto bg-extra-light px-3 py-1 fs-5 fw-semibold text-black rounded-2 z-2">
        Today
      </span>
    );
  } else if (date.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return (
      <span className="position-sticky top-0 mx-auto bg-extra-light px-3 py-1 fs-5 fw-semibold text-black rounded-2 z-2">
        Yesterday
      </span>
    );
  } else {
    return (
      <span className="position-sticky top-0 mx-auto bg-extra-light px-3 py-1 fs-5 fw-semibold text-black rounded-2 z-2">
        {date.format('DD MMM, YYYY')}
      </span>
    );
  }
});

const ChatBox = memo(
  ({
    sendMessage,
    getZoomLink,
    messages,
    question,
    setAidaSuggestionPayload,
    ddxId,
    demoMessage,
    setDemoMessage,
    share,
    scrollToLastMessage,
    firebaseToken,
    formatMessage
  }) => {
    const { trackActivityEvent } = useTracker();
    const [backdrop, setBackdrop] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isComplete, setIsComplete] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [text, setText] = useState('');
    const [fileDDX, setFileDDX] = useState('');
    const [currentMessage, setCurrentMessage] = useState({
      sent_by: userDetails().user_master_id,
      message: '',
      file_url: '',
      message_sent_at: new Date().toISOString(),
      isRead: false
    });

    useEffect(() => {
      scrollToLastMessage();
    }, [currentMessage]);
    useGetImageToText(fileDDX, (aidaImageToText) => {
      setBackdrop(false);

      if (aidaImageToText) {
        if (question) {
          handleReply(aidaImageToText);
          return;
        } else {
          sendMessage({ ...currentMessage, message: aidaImageToText }, true);
          return;
        }
      }
    });

    const dispatch = useDispatch();

    const handleSubmit = () => {
      trackActivityEvent(SUBMIT_CLICK, {});
      sendMessage(currentMessage, true);

      setCurrentMessage(formatMessage(userDetails().user_master_id, ''));
    };

    const uploadAttachMent = (e) => {
      if (!e) return;
      if (isFileAllowed(e)) {
        handleCompressedUpload(e, (compressedFile) => {
          setBackdrop(true);
          firebaseUpload(
            compressedFile,
            firebaseToken,
            ['jpg', 'jpeg', 'png'],
            (_p) => setProgress(_p),
            (url, fileName) => {
              setFileDDX(url);
            },
            () => {
              setBackdrop(false);
              setProgress(0);
            }
          );
        });
      } else {
        toast.error('Maximum allowed file size : 2 MB');
      }
    };

    const inputRef = useRef();

    function handleReply(payload) {
      scrollToLastMessage();
      const tempMessages = { ...messages };
      var data;

      if (tempMessages && Object.keys(tempMessages) && Object.keys(tempMessages).length > 0) {
        Object.keys(tempMessages).forEach((date) => {
          const dayWiseConversation = tempMessages[date];
          const ddxResults = dayWiseConversation.filter((item) => item.ddx);
          if (ddxResults) {
            data = ddxResults[0]?.fulldata;
          }
        });
      }

      if (payload) {
        sendMessage(formatMessage(userDetails()?.user_master_id, payload));
        setAidaSuggestionPayload((prev) => {
          return {
            ...prev,
            ddx_id: ddxId,
            prompt: question,
            user_question: payload,
            ref_answer: JSON.stringify({
              Diagnosis: data?.Diagnosis,
              Reason_of_diagnosis: data?.Reason_of_diagnosis,
              Treatment_Options: data?.Treatment_Options,
              probability: data?.probability,
              direct_message: true
            })
          };
        });
        setCurrentMessage(formatMessage(userDetails().user_master_id, ''));
      } else if (data) {
        sendMessage(formatMessage(userDetails()?.user_master_id, currentMessage?.message));
        setAidaSuggestionPayload((prev) => {
          return {
            ...prev,
            ddx_id: ddxId,
            prompt: question,
            user_question: currentMessage?.message,
            ref_answer: JSON.stringify({
              Diagnosis: data?.Diagnosis,
              Reason_of_diagnosis: data?.Reason_of_diagnosis,
              Treatment_Options: data?.Treatment_Options,
              probability: data?.probability,
              direct_message: true
            })
          };
        });
        setCurrentMessage(formatMessage(userDetails().user_master_id, ''));
      }
    }

    useEffect(() => {
      const fullMessage = demoMessage;
      if (demoMessage && currentIndex < fullMessage.length) {
        const timeout = setTimeout(() => {
          setText((prevText) => prevText + fullMessage[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, Math.random() * 50 + 50); // Random delay between 50-100ms for more realistic typing

        return () => clearTimeout(timeout);
      } else if (demoMessage && !isComplete) {
        setIsComplete(true);

        sendMessage(formatMessage(userDetails().user_master_id, demoMessage), true);
        setDemoMessage(null);
      }
    }, [demoMessage, currentIndex]);

    return (
      <>
        <div className="d-flex flex-row border border-1 border-light rounded-3 p-2 gap-3 clr_v3_aidaChat--cahtForm align-items-center">
          <a
            href="javascript:void(0)"
            role="button"
            className="icon-size-36 p-0 flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill cursorPointer rippleEffect position-relative fs-16 text-black overflow-hidden"
          >
            <i className=" flaticon-attach-paperclip-symbol"></i>
            <input
              className="position-absolute w-100 h-100 opacity-0 z-1 start-0 cursorPointer"
              type="file"
              ref={inputRef}
              onClick={(e) => {
                e.target.value = '';
              }}
              onChange={(e) => uploadAttachMent(e.target.files[0])}
            />
          </a>
          {demoMessage ? (
            <textarea
              className="form-control resize-none flex-grow-1 border-0 rounded-0 px-0"
              placeholder="Write a message"
              value={text}
              disabled={share}
            ></textarea>
          ) : (
            <textarea
              className="form-control resize-none flex-grow-1 border-0 rounded-0 px-0"
              placeholder="Write a message"
              value={currentMessage.message}
              onChange={(e) => setCurrentMessage({ ...currentMessage, message: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (question) {
                    handleReply();
                    return;
                  } else {
                    handleSubmit();
                    return;
                  }
                }
              }}
              disabled={share}
            ></textarea>
          )}
          <Button
            disabled={share ? true : currentMessage.message === '' ? true : false}
            variant="primary"
            className="icon-size-36 p-0 flex-shrink-0 d-flex align-items-center justify-content-center "
            onClick={() => {
              if (question) {
                handleReply();
              } else {
                handleSubmit();
              }
            }}
          >
            <i className="flaticon-send"></i>
          </Button>
        </div>

        <BackdropComponent backdrop={backdrop} progress={progress} type="upload" />
      </>
    );
  }
);

const SuggestionModal = memo(
  ({
    showSuggestionModal,
    showSuggestionModalClose,
    data,
    setAidaSuggestionPayload,
    ddxId,
    chiefComplaint,
    handleSuggestionClick,
    formatMessage
  }) => {
    const [userSelectedSuggestionQuery, setUserSelectedSuggestionQuery] = useState('');
    const { trackActivityEvent } = useTracker();
    const userSelectedSuggestionQueryHandler = (e) => {
      setUserSelectedSuggestionQuery(e.target.value);
    };

    function handleReply(val = {}) {
      // trackActivityEvent(CARD_CLICK, val);
      handleSuggestionClick(
        formatMessage(userDetails()?.user_master_id, {
          ...data,
          ref_question: val?.question ? val?.question : userSelectedSuggestionQuery,
          test: true
        })
      );
      setAidaSuggestionPayload((prev) => {
        return {
          ...prev,
          ddx_id: ddxId,
          prompt: chiefComplaint,
          user_question: val?.question ? val?.question : userSelectedSuggestionQuery,
          ref_answer: JSON.stringify({
            Diagnosis: data?.Diagnosis,
            Reason_of_diagnosis: data?.Reason_of_diagnosis,
            Treatment_Options: data?.Treatment_Options,
            probability: data?.probability
          })
        };
      });

      showSuggestionModalClose();
    }

    return (
      <Modal
        className="centerModal"
        show={showSuggestionModal}
        onHide={showSuggestionModalClose}
        size="lg"
        centered
      >
        <Modal.Body className="bg-light p-5 rounded-2">
          <div>
            <div className="p-3 bg-white rounded-2 mb-4">
              <p className="fw-semibold">{`DDX (${data?.Diagnosis})`}</p>

              <p className="fw-semibold mb-0">Reason of Diagnoses</p>
              <p className="mb-3">{data?.Reason_of_diagnosis}</p>
              <p className="fw-semibold mb-0">Treatment Options</p>
              <p>{data?.Treatment_Options}</p>
            </div>

            <div className="mb-4">
              <p className="fw-semibold">Suggested conversation</p>
              {data?.suggestion_list?.map((val, index) => {
                return (
                  <TrackerWrapper
                    key={val?.id}
                    className="border border-black p-3 bg-white rounded-2 cursorPointer mb-2"
                    onClick={() => handleReply(val)}
                  >
                    {val?.question}
                  </TrackerWrapper>
                );
              })}
            </div>

            <div className="mt-4">
              <p className="fw-semibold mb-1">
                Type your question below to get more information about the particular diagnosis
              </p>
              <div className="position-relative">
                <input
                  type="text"
                  name="userInputText"
                  id="userInputText"
                  className="w-100 p-3 rounded-2 border border-1 mt-3 pe-5"
                  placeholder="Enter any further question here."
                  value={userSelectedSuggestionQuery}
                  onChange={userSelectedSuggestionQueryHandler}
                />
                <button
                  className="btn btn-primary position-absolute top-50 end-0 translate-middle-y p-3 m-2 rounded-2"
                  type="button"
                  disabled={userSelectedSuggestionQuery?.trim()?.length < 1}
                  onClick={handleReply}
                >
                  <i className="flaticon-send"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const ExampleModal = memo(({ showDemoModal, showDemoModalClose, showDemoHandler }) => {
  const demoExamples = [
    '20 year old female presenting with hair loss condition upon changing location.',
    'A 45-year-old male complains of persisten headaches and dizziness for the past three days',
    'Initial test were performed including blood testing consisting of a complete blood count (CBC), basic metabolic panel (BMP), lipase, and liver function test (LFT), as well as a urinalysis. Lab work was remarkable for no acute abnormalities except for a mild leukocytosis of 12.4.'
  ];

  return (
    <Modal
      className="centerModal"
      show={showDemoModal}
      onHide={showDemoModalClose}
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-4 text-black fw-semibold">Example complaints</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="p-0 m-0 list-unstyled d-flex flex-column gap-3">
          {demoExamples.map((item, index) => {
            return (
              <li
                key={`aida-example-${index}`}
                className="border border-1 rounded-3 p-3 text-muted list-group-item cursorPointer"
                onClick={() => {
                  showDemoHandler(item);
                  showDemoModalClose();
                }}
              >
                {`Example: ${item}`}
              </li>
            );
          })}
        </ul>
      </Modal.Body>
    </Modal>
  );
});

function EmptyChatBox({ sendMessage }) {
  const demoExamples = [
    '20 year old female presenting with hair loss condition upon changing location.'
  ];
  return (
    <>
      <div className="h-100 w-100 align-items-center justify-content-center d-flex flex-column gap-2">
        <div className="d-flex mb-2 rounded-3 position-relative overflow-hidden py-3">
          <div className="d-flex flex-column text-center">
            <p className="display-5 mb-3">What is AiDA?</p>
            <strong className="text-black">
              AiDA (Artificial Intelligence (AI) powered Doctor's Assistant)
            </strong>
            <p>
              AiDA is designed to assist doctors and medical practitioners in their daily practice
              by providing quick, probable, and possible competing diagnoses
            </p>
            <strong className="text-black pt-4">Differential Diagnosis (DDx)</strong>
            <p>
              DDx helps doctors make informed diagnoses by suggesting potential conditions with
              associated probabilities based on symptoms.
            </p>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-3">
          {demoExamples &&
            demoExamples.map((example) => (
              <Button
                variant="outline-light"
                className="text-primary fs-5 fw-semibold"
                size="sm"
                onClick={() => sendMessage(example)}
                disabled={isUserLoggedIn() ? false : true}
              >
                View Demo
              </Button>
            ))}
        </div>
      </div>
    </>
  );
}
