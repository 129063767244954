import React, { memo } from 'react';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { BsUpload, RiDeleteBin6Line } from '../../../components/icons';

const FileUpload = ({ label, fileUpload, is_mandatory, cancelClick, attachedFile }) => {
  return (
    <>
      <h2 className="fs-4 fw-medium  shareLoginV2Question position-relative ps-4 mt-3">
        {label} {is_mandatory === '1' ? <span className="text-danger">*</span> : null}
      </h2>
      <div className="w-100 float-start shareLoginV2FormAnswr mt-3 ps-0">
        <div className="w-100 float-start shareLoginV2FormAnswrRow mb-2">
          <div className="col-md-6 col-12 mb-3">
            <div className="cmnFileUpload text-black fs-16 text-center rounded position-relative overflow-hidden w-100 p-4 border border-light">
              <div>
                <BsUpload customClass="fs-2 me-2" />
                Choose File
              </div>
              <input
                className="cmnFileUploadInput position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0"
                type="file"
                id="formFile"
                accept="image/*,application/pdf"
                onChange={(e) => fileUpload(e.target.files[0])}
              />
            </div>
          </div>
          {/* <div className="w-100 float-start text-danger fs-5">
            *Supported file formats are jpg, jpeg, png, pdf and maximum allowed size is 2 mb
          </div> */}
          {attachedFile && (
            <div className="row cmnFileUploadShow mt-n3 ms-n3">
              <div className="col-md-4 col-6 cmnFileUploadBox ps-3 mt-3">
                <div className="cmnFileUploadBoxIn rounded position-relative overflow-hidden">
                  <LazyImage
                    src={
                      attachedFile.type != 'application/pdf'
                        ? URL.createObjectURL(attachedFile)
                        : 'https://cdn-icons-png.flaticon.com/512/337/337946.png'
                    }
                    className="object-fit-cover w-100 h-100 float-start"
                  />

                  <a
                    href="javascript:void(0)"
                    className="cmnFileUploadRemove rounded position-absolute d-flex justify-content-center align-items-center bg-white bg-opacity-75"
                    onClick={cancelClick}
                  >
                    <RiDeleteBin6Line customClass="fs-2 text-danger" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-100 float-start text-danger fs-5">
          *Supported file formats are jpg, jpeg, png, pdf and maximum allowed size is 2 mb
        </div>
      </div>
    </>
  );
};

export default memo(FileUpload);
