import React, { memo, useEffect, useState } from 'react';
import './css/mobileFooter.scss';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  getLocalStorage,
  setLocalStorage,
  log,
  MEDWIKI_NAME,
  reactHtmlParser,
  isMobileApp,
  isIosApp,
  convertAllMenu,
  changeUrl
} from '../../common/common';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  ChannelLogo,
  DashboardLogo,
  ClinicalVideoLogo,
  LiveCmeLogo,
  SpqLogo,
  MedwikiLogo,
  EbookLogo,
  TelemedLiteLogo,
  DiscussReferLogo,
  Grlogo,
  ClirnetFullLogo,
  ClirnetIconLogo,
  TrainingLogo,
  HelpAndSupport,
  DoctubeLogo,
  DiagnosisIcon
} from '../../components/icons/menuIconComponents';

import useRedirect from '../../components/customHooks/useRedirect';
import { useDispatch, useSelector } from 'react-redux';
import packageJson from '../../../package.json';
import { getUserDetails, postMenu, toggleMoreMobile } from '../../Store/actions/commonActions';
import { unionWidthDefinedSpecs } from '../../pages/medwiki/SpecialityArray';
import { DEFAULT_MENU } from '../../Store/constants/app.constants';
import { routeNames } from '../../router/constants';
import { safeJsonParser } from '../../utils/common';

const { version } = packageJson;
function MobileFooter({ current_menu }) {
  const [menuJson, setMenuJson] = useState();
  // getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.menu) : ''
  const [subMenuArray, setSubMenuArray] = useState([]);
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_specialities = useSelector((state) => state.medwiki.all_specialities);
  const userDetailsState = useSelector((state) => state.common.user_details);

  const is_more_mobile = useSelector((state) => state.common.isMoreMobileOpen);
  const [currentMenu, setCurrentMenu] = useState('');
  const { pathname } = useLocation();
  const userDetails = () => getLocalStorage('user', {});
  const userSpecialities = () =>
    userDetails()?.speciality ? safeJsonParser(userDetails()?.speciality ?? '[]', []) : [];
  const [userSpeciality, setUserSpeciality] = useState(userSpecialities());

  useEffect(() => {
    try {
      if (getLocalStorage('user', false) && safeJsonParser(getLocalStorage('user', {})?.menu, {})) {
        let menu = safeJsonParser(getLocalStorage('user', {})?.menu, {});
        if (menu) {
          if ('menu' in menu) {
            // let menu = JSON.parse(userDetails.menu);
            console.log('menu', menu);
            setMenuJson({
              ...menu
              // static_menu: convertAllMenu(menu?.static_menu || []),
              // all_menu: convertAllMenu(menu?.all_menu || [])
            });
          } else setMenuJson(DEFAULT_MENU);
        }
      }
    } catch (e) {
      console.log(e);
    }
    // else setMenuJson(DEFAULT_MENU);
  }, [pathname]);

  useEffect(() => {
    if (is_more_mobile) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [is_more_mobile]);
  //   useEffect(()=>{
  // alert("user details"+(userSpeciality))
  //   },[])
  const unionWidthDefinedSpecs = (allSpecialities, callback) => {
    if (userSpecialities()?.length > 0) {
      let userSpeciality = userSpecialities();
      for (let _spec of allSpecialities) {
        if (
          !userSpeciality
            ?.map((_s) => _s.master_specialities_id)
            ?.includes(_spec.master_specialities_id)
        ) {
          let { master_specialities_id, specialities_name } = _spec;
          userSpeciality.push({
            master_specialities_id,
            specialities_name
          });
        }
      }
      callback(userSpeciality);
    } else {
      callback(allSpecialities);
    }
  };

  useEffect(() => {
    if (current_menu?.toLowerCase()?.includes('dashboard')) {
      setCurrentMenu('dashboard');
    } else if (current_menu?.toLowerCase()?.includes('session')) {
      setCurrentMenu('session');
    } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
      setCurrentMenu('medwiki');
    } else if (current_menu?.toLowerCase()?.includes('spq')) {
      setCurrentMenu('spq');
    } else if (current_menu?.toLowerCase()?.includes('clinical')) {
      setCurrentMenu('clinical');
    } else if (current_menu?.toLowerCase()?.includes('gr')) {
      setCurrentMenu('grandround');
    } else if (
      current_menu?.toLowerCase()?.includes('ebook') ||
      current_menu?.toLowerCase()?.includes('epaper')
    ) {
      setCurrentMenu('ebook');
    } else if (current_menu?.toLowerCase()?.includes('channel')) {
      setCurrentMenu('channel');
    } else if (current_menu?.toLowerCase()?.includes('telemed')) {
      setCurrentMenu('telemed');
    } else if (current_menu?.toLowerCase()?.includes('discussrefer')) {
      setCurrentMenu('discussrefer');
    } else if (
      current_menu?.toLowerCase()?.includes('training') ||
      current_menu?.toLowerCase()?.includes('course')
    ) {
      setCurrentMenu('training');
    } else if (current_menu?.toLowerCase()?.includes('doctube')) {
      setCurrentMenu('doctube');
    } else if (current_menu?.toLowerCase()?.includes('speciality')) {
      setCurrentMenu('speciality');
    } else if (
      current_menu?.toLowerCase()?.includes('diagnosis') ||
      current_menu?.toLowerCase()?.includes('ddx')
    ) {
      setCurrentMenu('diagnosis');
    } else if (current_menu?.toLowerCase()?.includes('observership')) {
      setCurrentMenu('observership');
    }
  }, [current_menu]);

  function getActiveClass(path) {
    if (currentMenu !== '') {
      if (
        currentMenu?.toLowerCase().includes(path?.toLowerCase()) ||
        path?.toLowerCase().includes(currentMenu?.toLowerCase())
      ) {
        return 'active';
      }
    }
  }

  const hideBtnNav = () => {
    dispatch(toggleMoreMobile(false));
  };

  const postMenuConfirmation = (confirmation) => {
    const menuJson = getLocalStorage('user', false)
      ? safeJsonParser(getLocalStorage('user', {})?.menu, {})
      : '';
    let objdata = {
      data: {
        ...menuJson,
        main: confirmation ? 'dnr' : 'home'
      }
    };
    if (confirmation) {
      setLocalStorage('main_menu', 'dnr');
    } else {
      setLocalStorage('main_menu', '');
    }
    dispatch(
      postMenu(objdata, (res) => {
        log('res', res);
        if (res != 'error') {
          dispatch(
            getUserDetails((res) => {
              // setUserDetails(res?.data);
              window.location.reload();
            })
          );
        }
      })
    );
  };
  useEffect(() => {
    if (all_specialities && all_specialities?.length > 0) {
      let specialities = all_specialities?.map((_a) => {
        let { master_specialities_id, specialities_name } = _a;
        return { master_specialities_id, specialities_name };
      });
      specialities.sort(function (a, b) {
        if (a.specialities_name < b.specialities_name) {
          return -1;
        }
        if (a.specialities_name > b.specialities_name) {
          return 1;
        }
        return 0;
      });
      unionWidthDefinedSpecs(specialities, (union) => {
        console.log('ALL_SPECIALITIES', union);
        setUserSpeciality(union);
      });
    }
  }, [all_specialities]);
  const CurrentActiveMenuComp = ({ current_menu }) => {
    const CinicalVideoMenu = () => (
      <a
        href="javascript:void(0)"
        className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black gtm_cl_header_helpsupport ${getActiveClass(
          'clinicalvideo'
        )}`}
        onClick={() => {
          hideBtnNav();
          setCurrentMenu('clinical');
          redirectTo(routeNames.clinicalVideo.landing);
        }}
        id="mobileFooter_clinical_anchor_text"
      >
        <span
          id="mobileFooter_clinical_icon_span"
          className="position-relative w-100 mb-2 d-block gtm_cl_header_ClinicalVideos"
        >
          <ClinicalVideoLogo
            gtmTagVariable="gtm_cl_header_ClinicalVideos"
            id={'mobileFooter_clinical_icon'}
            className={
              'd-flex align-items-center justify-content-center mblmenuSVG fs-1 position-absolute top-50 start-50 translate-middle text-black'
            }
          />
        </span>
        Clinical Videos
      </a>
    );
    switch (current_menu) {
      // case 'clinical':
      //   return <CinicalVideoMenu />;
      case 'dashboard':
        return getLocalStorage('main_menu', '') === 'dnr' ? (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'dashboard' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('dashboard');
              redirectTo(routeNames.dashboard.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <DashboardLogo
                className={
                  'd-flex align-items-center justify-content-center mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            Home
          </a>
        ) : (
          <CinicalVideoMenu />
        );
      case 'spq':
        return (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'spq' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('spq');
              redirectTo(routeNames.spq.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <SpqLogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            Polls & Quizzes
          </a>
        );
      case 'grandround':
        return (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'grandround' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('grandround');
              redirectTo(routeNames.grandRound.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <Grlogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            Grand Rounds
          </a>
        );
      case 'ebook':
        return (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'ebook' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('ebook');
              redirectTo(routeNames.epaper.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <EbookLogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            EPapers
          </a>
        );
      case 'channel':
        return (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'channel' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('channel');
              redirectTo(routeNames.channel.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <ChannelLogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            Channels
          </a>
        );
      case 'telemed':
        return (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'telemed' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('telemed');
              redirectTo(routeNames.telemed.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <TelemedLiteLogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            Telemed Lite
          </a>
        );
      case 'discussRefer':
        return getLocalStorage('main_menu', '') !== 'dnr' ? (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'discussRefer' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('discussRefer');
              redirectTo(routeNames.discussRefer.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <DiscussReferLogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            Discuss & Refer
          </a>
        ) : (
          <CinicalVideoMenu />
        );
      case 'doctube':
        return (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'doctube' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('doctube');
              redirectTo(routeNames.doctube.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <DoctubeLogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            DocTube™
          </a>
        );
      case 'training':
        return (
          <a
            href="javascript:void(0)"
            className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black ${
              currentMenu == 'training' ? 'active' : ''
            }`}
            onClick={() => {
              hideBtnNav();
              setCurrentMenu('training');
              redirectTo(routeNames.course.landing);
            }}
          >
            <span className="position-relative w-100 mb-2 d-block">
              <TrainingLogo
                className={
                  'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black fs-1'
                }
              />
            </span>
            Certified Courses
          </a>
        );
      default:
        return <CinicalVideoMenu />;
    }
  };

  function handleExploreMore() {
    const subMenuItem = menuJson.menu.filter((menu) => 'sub_menu' in menu);
    if (subMenuItem.length > 0) {
      setSubMenuArray(subMenuItem[0].sub_menu);
    }
  }
  return (
    <>
      <Navbar
        fixed="bottom"
        className="mblFooter bg-white shadow p-0 navbar navbar-expand navbar-light fixed-bottom"
      >
        <ul className="row fw-medium fs-5 mblFooterUl ps-0 position-relative w-100 bg-white m-0 z-3 list-unstyled">
          {menuJson?.menu?.length > 0 &&
            menuJson?.menu?.map((key, ind) => {
              if ('sub_menu' in key) return <></>;
              else {
                if (ind == 2) {
                  return (
                    <>
                      <li className="col position-relative p-0 d-flex justify-content-center">
                        <a
                          href="javascript:void(0)"
                          className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black more_mobile border border-5 border-white rounded-circle mt-n3 ${
                            is_more_mobile ? 'active' : ''
                          }`}
                          onClick={() => {
                            handleExploreMore();

                            dispatch(toggleMoreMobile(!is_more_mobile));
                          }}
                        >
                          <span className="position-relative w-100 m-0 d-block">
                            <div className="mblFtr_moreMenu deskMenuToggle position-absolute top-50 start-50">
                              <span className="mblFtr_more position-absolute start-50 translate-middle-x rounded-circle m-0"></span>
                              <span className="mblFtr_more position-absolute top-50 start-50 translate-middle rounded-circle m-0"></span>
                              <span className="mblFtr_more position-absolute start-50 translate-middle-x rounded-circle m-0"></span>
                            </div>
                          </span>
                        </a>
                      </li>
                      <li
                        className="col position-relative p-0 d-flex justify-content-center"
                        key={ind + 1}
                      >
                        <Link
                          to={key.url.startsWith('/') ? key.url : '/' + key.url}
                          className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black  gtm_cl_header_home ${getActiveClass(
                            key.url
                          )}`}
                          onClick={() => {
                            hideBtnNav();
                            setCurrentMenu(key.url);
                          }}
                          id="mobileFooter_menu_session_anchor_text"
                        >
                          {/* <div
                            // dangerouslySetInnerHTML={{ __html: key.icon }}
                            gtmTagVariable="gtm_cl_header_session"
                            id={'mobileFooter_menu_session_icon'}
                            className={
                              'mblmenuSVG position-absolute top-50 start-50 translate-middle text-black'
                            }
                          >  */}
                          {key.displayIcon && (
                            <div className="oldUser--premium__tag position-absolute shineEffect icon-size-20 rounded-circle mt-1 ms-1 top-0 start-0 d-flex align-items-center justify-content-center">
                              <i className="flaticon-premium fs-5 text-black"></i>
                            </div>
                          )}
                          <span className="position-relative w-100 mb-2 d-block gtm_cl_header_home">
                            {key.icon?.includes('https') ? (
                              <img
                                src={key.icon}
                                gtmTagVariable="gtm_cl_header_ClinicalVideos"
                                className={
                                  'mblmenuSVG fs-1 position-absolute top-50 start-50 translate-middle text-black'
                                }
                              />
                            ) : (
                              <>{reactHtmlParser(key.icon)}</>
                            )}
                          </span>
                          {reactHtmlParser(key.title)}
                          {/* </div> */}
                        </Link>
                      </li>
                    </>
                  );
                } else
                  return (
                    <li
                      className="col position-relative p-0 d-flex justify-content-center"
                      key={ind + 1}
                    >
                      <Link
                        to={key.url?.replace('/', '')}
                        className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black   ${getActiveClass(
                          key.url
                        )}`}
                        onClick={() => {
                          hideBtnNav();
                          setCurrentMenu(key.url);
                        }}
                        id="mobileFooter_menu_session_anchor_text"
                      >
                        <span
                          // dangerouslySetInnerHTML={{ __html: key.icon }}
                          gtmTagVariable="gtm_cl_header_session"
                          id={'mobileFooter_menu_session_icon'}
                          className={
                            'position-relative w-100 mb-2 d-block gtm_cl_header_ClinicalVideos'
                          }
                        >
                          {reactHtmlParser(key.icon)}
                        </span>
                        {reactHtmlParser(key.title)}
                      </Link>
                    </li>
                  );
              }
            })}
          {isIosApp() == true ? <li className={`col-12 iosSpecial-safe-area-bottom`}></li> : <></>}
        </ul>
        <div
          id="more-drawer"
          className={
            is_more_mobile
              ? 'ftrBtmSubmenu moreMenuShowUL position-fixed z-1 w-100 start-0 overflow-x-hidden overflow-y-auto p-4'
              : 'ftrBtmSubmenu position-fixed z-1 w-100 start-0 overflow-x-hidden overflow-y-auto p-4'
          }
        >
          {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100"></div> : <></>}
          <div className="w-100 drawerMainMenu mb-5">
            <ul className="row flex-wrap ps-0 mt-n3 ms-n3 me-0 mb-0 list-unstyled">
              {subMenuArray?.length > 0 &&
                subMenuArray?.map((key, ind) => {
                  return (
                    <li className="col-sm-3 col-4 pt-3 ps-3 pe-0 pb-0" key={ind + 1}>
                      <a
                        href="javascript:void(0)"
                        className={`bg-white shadow d-flex justify-content-center align-items-center flex-column text-black rounded-3 h-100 py-20 px-3 fs-4 fw-medium gtm_cl_header_session ${getActiveClass(
                          key.url
                        )}`}
                        onClick={() => {
                          document.body.classList.remove('overflow-hidden');
                          hideBtnNav();
                          setCurrentMenu(key.url);
                          redirectTo(changeUrl(key.url));
                        }}
                        id="mobileFooter_menu_session_anchor_text"
                      >
                        {/* <span
                          id="mobileFooter_menu_session_icon_span"
                          className="position-relative d-block mb-3 w-100  gtm_cl_header_session "
                        >
                          {reactHtmlParser(key.icon)}
                        </span> */}

                        <span className="position-relative d-block mb-3 w-100  gtm_cl_header_session ">
                          {key.displayIcon && (
                            <div className="oldUser--premium__tag position-absolute shineEffect icon-size-20 rounded-circle mt-1 ms-1 top-0 start-0 d-flex align-items-center justify-content-center">
                              <i className="flaticon-premium fs-5 text-black"></i>
                            </div>
                          )}
                          {key.icon?.includes('https') ? (
                            <img
                              src={key.icon}
                              gtmTagVariable="gtm_cl_header_ClinicalVideos"
                              className={
                                'mblmenuSVG fs-1 position-absolute top-50 start-50 translate-middle text-black'
                              }
                            />
                          ) : (
                            <>{reactHtmlParser(key.icon)}</>
                          )}
                        </span>
                        {reactHtmlParser(key.title)}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
          {userSpeciality && userSpeciality?.length > 0 && (
            <div className="w-100 text-start mb-5">
              <h2 className="fs-1 text-black fw-semibold">
                <span className="text-secondary">Browse</span> By Specialities
              </h2>
              <div className="w-100">
                <div className="drawer_SpecialitiesSliderPrrnt ms-n4">
                  <Swiper
                    className="drawer_SpecialitiesSlider pt-3 pb-4 ps-4 pe-0"
                    slidesPerView={'auto'}
                    spaceBetween={10}
                    loop={false}
                    freeMode={true}
                  >
                    {userSpeciality?.map(
                      (key, index) =>
                        key?.specialities_name && (
                          <SwiperSlide className="w-auto" key={index + 1}>
                            <a
                              href="javascript:void(0)"
                              className="rounded-pill px-4 py-3 d-inline-flex align-items-center shadow bg-white text-black"
                              onClick={() => {
                                // alert(key?.specialities_name+"hi"+key?.master_specialities_id);
                                hideBtnNav();
                                redirectTo(
                                  routeNames.speciality.landing,
                                  key?.master_specialities_id,
                                  key?.specialities_name
                                );
                              }}
                            >
                              {key.specialities_name}
                            </a>
                          </SwiperSlide>
                        )
                    )}
                  </Swiper>
                </div>
              </div>
            </div>
          )}
          {/* <div className="w-100 text-start mb-5">
            <h2 className="fs-1 text-black fw-semibold">
              Switch your <span className="text-secondary">Home Screen</span>
            </h2>
            <div className="mt-3 w-100">
              <a
                href="javascript:void(0)"
                className={`rounded-pill px-4 py-3 d-inline-flex align-items-center shadow-lg bg-white me-4   ${
                  getLocalStorage('main_menu', '') !== 'dnr' ? 'text-primary' : 'text-black'
                }`}
                onClick={() => postMenuConfirmation(false)}
              >
                <DashboardLogo className={'fs-2 me-3'} /> Default
              </a>
              <a
                href="javascript:void(0)"
                className={`rounded-pill px-4 py-3 d-inline-flex align-items-center shadow-lg bg-white  ${
                  getLocalStorage('main_menu', '') === 'dnr' ? 'text-primary' : 'text-black'
                }`}
                onClick={() => postMenuConfirmation(true)}
              >
                <DiscussReferLogo className={'fs-2 me-3'} /> Discuss &amp; Refer
              </a>
            </div>
          </div> */}
          <div className="w-100">
            <h5 className="fs-16 text-black fw-medium text-start">CLIRNET v{version}</h5>
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default memo(MobileFooter);
