import React from 'react';
import useRedirect from '../components/customHooks/useRedirect';
import { routeNames } from '../router/constants';
import { useTracker } from '../TrackerFolder/TrackerComponent';
import { CARD_CLICK } from '../components/tracking/constants';
import { onCardClick } from '../components/tracking/UserActions';
import { removeHtmlTags } from '../pages/channel/utils';

function useContentAccessRedirect() {
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();

  const contentAccessRedirect = (
    routeName,
    id,
    title,
    isLocked,
    price,
    userContentPayment,
    extra,
    fullData
  ) => {
    const routePath = routeName.split('/');
    let serviceName = '';

    if (routePath.length > 0) {
      serviceName = routePath[1]?.replace('/', '');
    }
    trackActivityEvent(CARD_CLICK, fullData);
    if (isLocked?.content_access) {
      // if (price && price != null && parseInt(price) > 0) {
      //   // if (userContentPayment && parseInt(userContentPayment) === 3) {
      //   //   //redirect to addons page
      //   //   redirectTo(routeName, id, title, extra);
      //   // } else if (userContentPayment === null) {
      //   //   //redirect to subscription page
      //   //   redirectTo(routeName, id, title, extra);
      //   // } else {
      //   //   redirectTo(routeName, id, title, extra);
      //   // }
      // } else {
      //   redirectTo(routeName, id, title, extra);
      // }

      redirectTo(routeName, id, removeHtmlTags(title), extra);
    } else {
      //redirect to subscription page
      // redirectTo(
      //   routeNames.subscription.pricing + '/' + routeName.replace('/', ''),
      //   id,
      //   title,
      //   extra
      // );
      redirectTo(routeName, id, removeHtmlTags(title), extra);
    }
  };

  return { contentAccessRedirect };
}

export default useContentAccessRedirect;
