import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentlyOpenedMenu } from '../../Store/actions';
import useManualScrollRestoration from '../../components/customHooks/useManualScrollRestoration';
import LeftContentBar from './Components/LeftContentBar';
import RightLoginBar from './Components/RightLoginBar';
import './css/shareLoginV2.scss';
const ShareLogin = ({
  type = '',
  heroCardData = null,
  relatedData = [],
  consent_text = '',
  channel_id = 0,
  page_id = 0,
  channel_name = '',
  channel_logo = '',
  serviceType = '',
  redirectType
}) => {
  const shareType = type == 'medwiki' ? 'comp' : type;
  const dispatch = useDispatch();
  useManualScrollRestoration();

  useEffect(() => {
    dispatch(updateCurrentlyOpenedMenu('share_new'));
  }, []);

  console.log('redirectType =>', redirectType);

  return (
    <div className="w-100 shareLogin  shareLoginV2 d-flex flex-wrap flex-row-reverse position-relative bg-body">
      <RightLoginBar
        consent_text={consent_text}
        channel_id={channel_id ?? 0}
        heroCardData={heroCardData}
        share_type={shareType}
        page_id={page_id}
        channel_name={channel_name}
        channel_logo={channel_logo}
        redirectType={redirectType}
      />
      <div className="shareLoginV2Left">
        {heroCardData && (
          <LeftContentBar
            data={relatedData ?? []}
            heroCardData={heroCardData}
            share_type={shareType}
            channel_name={channel_name}
            channel_logo={channel_logo}
            page_id={page_id}
            serviceType={serviceType}
          />
        )}
      </div>
    </div>
  );
};
export default memo(ShareLogin);
