import React from 'react';

import Masonry from 'react-masonry-component';
import ShareLogin from '../../common/LoginForAll/login/Login';
import MedwikiLoader from '../medwiki/medwikiLoader';
import { Skeleton } from '../Skeleton';

function ShareSkeleton(props) {
  return (
    <>
      <div className="w-100 float-start desktopBodyIn">
        <div className="container">
          <section className="w-100 cmnPageContent">
            <div className="w-100 sharePage shareClVideos">
              <div className="sharePageTopSection">
                <div className="row sharePageMasonry">
                  <div className="col-lg-8 col-md-12 sharePageMasonryCard">
                    <div className="w-100 rounded shareClVideosBox">
                      <div className="w-100 h-auto shareClVideosPic">
                        {/* <div className="overlay">
                          <BsPlayCircleFill className="text-white display-1 translate_both" />
                        </div> */}

                        <Skeleton variant="rectangular" height={360} width={'100%'} />
                      </div>
                      <div className="w-100 text-start text-black fs-16 shareClVideosContent">
                        <div className="shareClVideosTop d-md-flex d-block align-items-center justify-content-between mb-md-4 mb-2">
                          <a href="javascript:void(0)" className="text-black shareClVideosSpeclty">
                            <Skeleton variant="text" height={20} width={100} />
                          </a>
                        </div>
                        <h1 className="fs-1 fw-bold text-black text-uppercase mb-3">
                          <Skeleton variant="text" height={25} width={'80%'} />
                        </h1>
                        <p>
                          <Skeleton variant="text" height={20} width={'45%'} />
                        </p>
                        <div className="w-100 shareClVideosBttm mt-3 d-md-flex d-block align-items-center">
                          {/* if single doctor ====================*/}
                          <div
                            className={`w-100 float-start cmncrdV1Doctor text-start cursorPointer`}
                          >
                            <div className="crdV1DoctorBox text-start w-auto float-start">
                              <div className="w-100 rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center py-2 px-3 border border-light">
                                <Skeleton variant="circular" width={50} height={50} />
                                <div className="crdV1DoctorContent ps-4 flex-1">
                                  <Skeleton variant="text" width={120} height={20} />
                                  <Skeleton variant="text" width={90} height={15} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-12 sharePageMasonryCard">
                    <div className="w-100 shareloginArea">
                      <ShareLogin {...props} />
                    </div>
                    <Skeleton variant="rounded" width={'100%'} height={80} />
                  </div>
                </div>
              </div>
              <Masonry
                enableResizableChildren={true}
                className="sharePageMasonry sharePageRelated mt-4 position-relative"
              >
                {[...Array(9).keys()].map((_i, index) => (
                  <div
                    className="position-absolute col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard clr_v3_masonry_card sharePageMasonryCard"
                    key={index + 1}
                  >
                    <MedwikiLoader />
                  </div>
                ))}
              </Masonry>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ShareSkeleton;
