import React, { memo, useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import './css/doctorVoice.scss';
import '../../../components/centerModal/css/centerModal.scss';
import { toast } from 'react-toastify';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ShareDetailsPage from '../../../components/ShareDetailPage';
import { log, reactHtmlParser } from '../../../common/common';
import { Fade } from 'react-awesome-reveal';
import { RiBarChart2Line } from '../../../components/icons';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { safeJsonParser } from '../../../utils/common';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { SUBMIT_CLICK } from '../../../components/tracking/constants';
// import { useTracker } from '../../../TrackerFolder/TrackerComponent';
const url = process.env.REACT_APP_API_URL;
const ModalDoctorVoice = ({ show, handleClose, data, triggerModalClose, headingText = '' }) => {
  const [currOptions, setCurrOptions] = useState({});
  const [ansGiven, setAnsGiven] = useState(false);
  const { trackActivityEvent } = useTracker();

  // const { trackActivityEvent } = useTracker();

  if (data && data?.json_data) {
    var parsedData = data ? safeJsonParser(data?.json_data, {}) : '';
    console.log('parsedData', parsedData);

    if (parsedData) {
      var qArray = Object.keys(parsedData.surveys).map((k) => parsedData.surveys[k]);
      var { question, options } = qArray[0];
    }
    //for dashboard please change to json_data
  }

  useEffect(() => {
    setCurrOptions(options);
  }, []);

  const onPollSubmit = (selectedIndex, id, _opt) => {
    trackActivityEvent(SUBMIT_CLICK, _opt);
    qArray[0].selectedIndex = selectedIndex;
    axiosInstance
      .post('survey/pollAnswer', {
        answer_json: JSON.stringify(qArray),
        survey_id: data.survey_id
      })
      .then((res) => {
        axiosInstance
          .get(`survey/pollResult?id=${data.survey_id}`)
          .then((response) => {
            log('Poll result', safeJsonParser(response.data.data, [])[0].options);
            setCurrOptions(safeJsonParser(response.data.data, [])[0]);
            setAnsGiven(true);
            axiosInstance
              .post('survey/addpoint', {
                point: data.point,
                survey_id: data.survey_id
              })
              .then((resp) => {
                toast.success(`You have got ${data.point} points`);
              })
              .catch((e) => {
                log('Error', e);
              });
          })
          .catch((e) => {
            log('Error', e);
          });
      })
      .catch((e) => {
        log('Error', e);
      });
  };
  const handleModalClose = () => {
    if (ansGiven) {
      triggerModalClose(currOptions);
    }
    handleClose();
  };

  console.log('currOptions', currOptions);
  return (
    <>
      <Modal
        className="centerModal dvDbV2Modal"
        size="md"
        centered
        show={show}
        onHide={handleModalClose}
      >
        <Modal.Body className="p-0">
          <div className="w-100 rounded dbDoctorVoiceIn overflow-hidden bg-white shadow p-3">
            {/* <img src={docVoiceImage} alt="graphics" className="docVoiceImage" /> */}
            <div className="w-100 d-flex justify-content-between align-items-center dbV2FeaturedBoxTtl pb-3 pb-md-4 border-bottom border-light">
              <h1 className="fs-2 text-black fw-semibold position-relative d-flex align-items-center gap-3 pl-0 ps-0">
                <span className="dbV2FeaturedBoxTtlIcon d-inline-flex align-items-center">
                  <RiBarChart2Line customClass="fs-3 text-secondary" />
                </span>
                {headingText}
              </h1>

              <a href="javascript:void(0)" className="text-black">
                <div>
                  <ShareDetailsPage
                    data={{ title: question, url: data?.deeplink, is_share: data?.is_share }}
                  />
                </div>
              </a>
            </div>
            <div className="w-100 dbDoctorVoiceContent dbBoxSlide position-relative z-1 pt-3 pt-md-4 w-100">
              <div className="w-100" id="questionBox">
                <h2 className="fs-16 text-black fw-medium">{reactHtmlParser(question)}</h2>
              </div>

              <div className="clearfix"></div>
              <div className="w-100 dbDoctorVoiceAns mt-20 p-0">
                {!ansGiven ? (
                  <div className="w-100 dbDoctorVoiceOptions">
                    <Form>
                      {options?.map((_opt, _idx) => (
                        <Form.Check
                          className="p-0 mb-4 position-relative"
                          key={_opt.value}
                          name="dbDoctorModalVoiceAnser"
                          type="radio"
                          label={<span id={`dbVoiceLabel_${_opt.value}`}>{_opt.label}</span>}
                          id={`dbDoctorModalVoiceAnser${_opt.value}`}
                          onChange={() => {
                            onPollSubmit(_opt.value, data.survey_id, _opt);
                          }}
                        />
                      ))}
                    </Form>
                  </div>
                ) : (
                  <></>
                )}
                {ansGiven ? (
                  <Fade bottom>
                    <div className="w-100 doctoVoiceProgress">
                      {currOptions.options.map((_option, index) => (
                        <div
                          className="doctoVoiceProgressRow position-relative mb-4"
                          key={index + 1}
                        >
                          <ProgressBar now={_option.vote} />
                          <div
                            className={`doctoVoiceProgressRowIn text-start py-3 px-4 position-relative overflow-hidden z-2 d-flex flex-row align-items-stretch ${
                              currOptions.selectedIndex == _option.value
                                ? 'text-primary'
                                : 'text-black'
                            }`}
                          >
                            <p className="p-0">{_option.label}</p>
                            <p className="p-0">{_option.vote}%</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Fade>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ModalDoctorVoice);
