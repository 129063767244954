import React, { memo, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import './css/grContentAccordion.scss';
import { IoIosArrowDown } from '../../../../components/icons';
import TrainingSlider from './trainingSliderCard/TrainingSlider';
import { useDispatch } from 'react-redux';
import {
  getContentsByModules,
  getOpenContentsByModules
} from '../../../../Store/actions/training.action';
import { ModuleTitle } from '../../../../common/common';
import { TrackerComponent } from '../../../../TrackerFolder';
import { CARD_VIEW } from '../../../../components/tracking/constants';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';

const TrainingContentAccordion = ({
  training_id,
  module_list,
  isSharePage = false,
  className = '',
  data
}) => {
  const [active, setActive] = useState(null);
  const [contentByModule, setContentByModule] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (module_list && module_list?.length > 0) {
      for (const [_idx, _mod] of module_list?.entries()) {
        if (_mod?.live_session_status == 1) {
          isSharePage
            ? dispatch(
                getOpenContentsByModules(training_id, _mod?.module_id, (res) => {
                  setActive(_idx);
                  setContentByModule((prev) => {
                    return [...prev, { module_id: _mod?.module_id, data: res ? res : null }];
                  });
                })
              )
            : dispatch(
                getContentsByModules(training_id, _mod?.module_id, (res) => {
                  setActive(_idx);
                  setContentByModule((prev) => {
                    return [
                      ...prev,
                      { module_id: _mod?.module_id, data: res && res?.data ? res?.data : null }
                    ];
                  });
                })
              );

          break;
        }
      }
    }
  }, [module_list]);
  const handleOpenAccordion = (index, module_id) => {
    !contentByModule?.some((_item) => _item.module_id == module_id) &&
      dispatch(
        isSharePage
          ? getOpenContentsByModules(training_id, module_id, (res) => {
              if (res != 'error') {
                setActive(index);
                setContentByModule((prev) => {
                  return [...prev, { module_id, data: res ? res : null }];
                });
              }
            })
          : getContentsByModules(training_id, module_id, (res) => {
              if (res && res?.data != 'error') {
                setActive(index);
                setContentByModule((prev) => {
                  return [...prev, { module_id, data: res && res?.data ? res?.data : null }];
                });
              }
            })
      );
  };
  // console.log('MODULE BY CONTENT', contentByModule);

  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={data}
      className={
        'w-100 float-start grContentArea rounded-3 bg-white shadow mb-3 mb-md-4 mb-lg-5 ' +
        className
      }
    >
      <h2 className="grContentAreaTitle fs-2 fw-medium text-black text-capitalize text-start position-relative p-3 p-md-4">
        What we learn
      </h2>
      <div className="grContentAreaBody p-3 p-md-4">
        {module_list && module_list?.length > 0 && (
          <Accordion activeKey={active ?? -1}>
            {module_list?.map((_module, _idx) => {
              const total_trainings = Object.values(_module.content_count).reduce(
                (a, v) => (a = a + v),
                0
              );
              console.log('TOTAL TRAININGS', total_trainings);
              return (
                total_trainings > 0 && (
                  <Accordion.Item
                    className="bg-white border border-0"
                    key={_idx + 1}
                    eventKey={_idx}
                    onClick={() => handleOpenAccordion(_idx, _module.module_id)}
                  >
                    <Accordion.Header
                      className="fs-16 fw-medium"
                      onClick={() => {
                        active == _idx ? setActive(-1) : setActive(_idx);
                      }}
                    >
                      <IoIosArrowDown customClass={'fs-2 text-dark me-4 arrowDown'} />

                      <ModuleTitle title={_module?.module_title} />
                    </Accordion.Header>
                    <Accordion.Body className="fs-4 text-start px-0 pt-2 pb-md-4">
                      {contentByModule?.some((_m) => _m.module_id == _module.module_id) && (
                        <TrainingSlider
                          contentArray={contentByModule?.find(
                            (_m) => _m.module_id == _module.module_id
                          )}
                        />
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              );
            })}
          </Accordion>
        )}
      </div>
    </TrackerWrapper>
  );
};

export default memo(TrainingContentAccordion);
