import React from 'react';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { ClirnetIconLogo } from '../../../components/icons';
import { Button } from 'react-bootstrap';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';

function ClirnetOtherCard({ redirect, gtmTagVariable = '' }) {
  return (
    <>
      <TrackerWrapper
        type={CARD_VIEW}
        data={{}}
        className="position-absolute col-xxl-3 col-lg-4 col-sm-6 col-12 mt-3 ps-3 mt-md-4 ps-md-4 pe-0 loginPageFrmCard"
      >
        <div className="w-100 newLogoCard d-flex flex-column gap-20 p-3 p-md-4 text-start bg-white rounded-3">
          <span className="d-flex justify-content-start">
            <p className="fs-2 fw-semibold mb-3">Don't know what CLIRNET does?</p>
          </span>
          <span className="d-flex flex-row justify-content-between">
            <div
              className={`icon-size-36 gap-4 d-flex align-items-center justify-content-center rounded-3 me-auto`}
            >
              <ClirnetIconLogo />
            </div>
            <Button
              variant="outline-secondary"
              onClick={redirect}
              className={`"ms-auto py-2 px-3 ${gtmTagVariable}_clirnetOtherCard_click_here_to_find_out`}
              aria-label="Click here to find out"
            >
              Click here to find out
            </Button>
          </span>
        </div>
      </TrackerWrapper>
    </>
  );
}

export default React.memo(ClirnetOtherCard);
