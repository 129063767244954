import React, { useEffect, memo, useRef } from 'react';
import dayjs from 'dayjs';
import {
  getAgent,
  getSessionStorage,
  setSessionStorage,
  getLocalStorage,
  removeSessionStorage,
  generateUUID,
  isMobileApp
} from '../common/common';
import Package from '../../package.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTrackingData,
  recordEvent,
  recordTrackingData
} from '../Store/actions/tracking.action.js';
import { useLocation, useNavigate } from 'react-router-dom';
// import { onPageView } from './actions/UserActions.js';

import useNetwork from '../components/customHooks/useNetwork.js';
import ReactGA from 'react-ga4';
import { changeShowSearch, updateCurrentlyOpenedMenu } from '../Store/actions/commonActions.js';
import { showOneSignalPrompt } from '../common/onesignal.utils.js';
import { useTrackUserActivity } from '../components/customHooks/useTrackUserActivity';
import OnesignalAppInit from '../capacitor/OnesignalAppInit.jsx';
import { safeJsonParser } from '../utils/common';
import { useTrackingWebSocket } from './TrackerWebsocketconfig';
import { NOT_FROM_DEEPLINK, PAGE_VIEW } from '../components/tracking/constants';

const apiUrl = process.env.REACT_APP_API_URL;
let appVersion = Package.version;
let pageSessionId;

export const onPageView = (page_name, id = 0, shareContentType = NOT_FROM_DEEPLINK) => {
  return {
    type: PAGE_VIEW,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    content_type: page_name,
    contentID: id,
    shareContentType: shareContentType,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

/** useTracker hook */
export function useTracker(
  page_name = '',
  page_id = '',
  appVersion = '',
  networkState = '',
  reportData
) {
  const { userActivityTracker } = useTrackUserActivity();
  const dispatch = useDispatch();
  const trackActivityEvent = (
    event_type = '',
    data = null,
    immediateFire = false,
    isConnected,
    sendMessage
  ) => {
    userActivityTracker(true, { ...data, event_type }, 'card');
    let activity_events = getSessionStorage('activity_events', false)
      ? safeJsonParser(getSessionStorage('activity_events', '[]'), [])
      : [];

    const {
      type_id,
      session_id,
      survey_id,
      content_id,
      type: contentType,
      contentID,
      ddx_id
    } = data || {};
    console.log('=====>data', data);

    const payload = {
      type: event_type,
      timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
      cardId: type_id || session_id || survey_id || content_id || contentID || ddx_id || 0,
      cardName: contentType
    };

    activity_events.push(payload);
    console.log(
      'activity_events',
      activity_events,
      type_id,
      session_id,
      survey_id,
      content_id,
      contentID,
      ddx_id
    );

    if (!immediateFire) {
      setSessionStorage('activity_events', JSON.stringify(activity_events));
    } else {
      reportData(
        getConfigData(page_name, page_id, appVersion, networkState),
        [payload],
        'immediateFire',
        dispatch,
        event_type
      );
    }
  };
  return { trackActivityEvent };
}

/** TrackerComponent */
const TrackerComponent = ({ page_name = '', page_id = 0, module_page = '' }) => {
  const networkState = useNetwork();
  const dispatch = useDispatch();
  const trackingData = useSelector((state) => state.tracking.tracking_data);
  const activityEvents = useSelector((state) => state.tracking.activity_event);
  const { state } = useLocation();
  const trackingDataRef = useRef({});
  const activityEventsRef = useRef([]);
  const hasReportedPageView = useRef(false);
  /** Report data function */
  const reportData = (config = {}, activity = [], type = '', dispatch, eventType = '') => {
    let tempPageReport = config;
    switch (type) {
      case 'immediateFire':
        tempPageReport.type = eventType;
        tempPageReport.activity_events = activity;
        tempPageReport.end_time = getCurrentTime();
        sendMessage('ADD_ANALYTICS_REPORT', tempPageReport);
        break;

      case 'pageview':
        tempPageReport.type = 'PAGE_LOADED';
        tempPageReport.activity_events = activity;
        sendMessage('ADD_ANALYTICS_REPORT', tempPageReport);
        break;

      case 'pagehide':
        dispatch(clearTrackingData());
        tempPageReport.type = 'PAGE_HIDE';
        tempPageReport.end_time = getCurrentTime();
        tempPageReport.activity_events = activity;
        sendMessage('ADD_ANALYTICS_REPORT', tempPageReport);
        break;

      case 'xhr':
        dispatch(clearTrackingData());
        tempPageReport.type = 'DEFAULT';
        tempPageReport.end_time = getCurrentTime();
        tempPageReport.activity_events = activity;
        sendMessage('ADD_ANALYTICS_REPORT', tempPageReport);
        removeSessionStorage('activity_events');
        break;

      default:
        break;
    }
  };

  const { trackActivityEvent } = useTracker(
    page_name,
    page_id,
    appVersion,
    networkState,
    reportData
  );
  const isSearchOpen = useSelector((state) => state.common.isSearchOpen);
  const { isConnected, sendMessage } = useTrackingWebSocket();

  const tracking_config = () =>
    getSessionStorage('tracking_config', false)
      ? safeJsonParser(getSessionStorage('tracking_config', '{}'), {})
      : {};

  const activity_events = () =>
    getSessionStorage('activity_events', false)
      ? safeJsonParser(getSessionStorage('activity_events', '[]'), [])
      : [];

  /** Generate page session ID */
  useEffect(() => {
    pageSessionId = generateUUID();
  }, []);

  /** Watch activity events and tracking data */
  useEffect(() => {
    if (activityEvents?.length > 0) {
      activityEventsRef.current = activityEvents;
      let activities = getSessionStorage('activity_events', false)
        ? safeJsonParser(getSessionStorage('activity_events', '[]'), [])
        : [];
      !activities?.some((act) => act.timestamp === activityEvents[0].timestamp) &&
        setSessionStorage('activity_events', JSON.stringify([...activities, ...activityEvents]));
    }
    if (trackingData && Object.keys(trackingData).length > 0) {
      setSessionStorage('tracking_config', JSON.stringify(trackingData));
      trackingDataRef.current = trackingData;
    }
  }, [activityEvents, trackingData]);

  /** Handle mobile back button */
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleMobileBackButtonPress = async () => {
    const { App } = await import('@capacitor/app');
    App.addListener('backButton', (data) => {
      if (data.canGoBack) {
        if (pathname === '/dashboard' || pathname === '/') {
          if (window.confirm('Are you sure you want to exit?')) {
            App.exitApp();
          }
        } else {
          navigate(-1);
        }
      }
    });
  };

  useEffect(() => {
    if (isMobileApp()) {
      handleMobileBackButtonPress();
    }
  }, [pathname]);

  /** Google Analytics and page tracking */
  useEffect(() => {
    let currentLocation = window.location.href;
    let pageTitle = document.title;

    dispatch(updateCurrentlyOpenedMenu(module_page));
    isSearchOpen && dispatch(changeShowSearch(false));

    if (!isMobileApp()) {
      state?.from_login && showOneSignalPrompt();
    }

    ReactGA.event({
      category: 'PAGE_LOADED',
      action: 'PAGE LOADED',
      value: getLocalStorage('user', false)
        ? parseInt(getLocalStorage('user', '{}')?.user_master_id)
        : 0,
      label: currentLocation,
      nonInteraction: true,
      transport: 'beacon'
    });

    ReactGA.send({
      hitType: 'pageview',
      page: currentLocation,
      title: pageTitle,
      location: currentLocation
    });

    return () => {
      ReactGA.event({
        category: 'PAGE_EXIT',
        action: 'PAGE EXIT',
        page_title: pageTitle,
        page_location: currentLocation,
        value: getLocalStorage('user', false)
          ? parseInt(getLocalStorage('user', '{}')?.user_master_id)
          : 0,
        label: currentLocation,
        nonInteraction: true,
        transport: 'beacon'
      });
    };
  }, [page_name, page_id]);

  /** Report data at intervals */
  useEffect(() => {
    const interval = setInterval(() => {
      reportData(tracking_config(), activity_events(), 'xhr', dispatch);
      trackingDataRef.current = '';
      activityEventsRef.current = '';
    }, parseInt(process.env.REACT_APP_REPORTING_INTERVAL));

    return () => clearInterval(interval);
  }, []);

  /** Handle visibility change */
  const reportOnVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      if (tracking_config()?.start_time && activity_events()) {
        reportData(tracking_config(), activity_events(), 'pagehide', dispatch);
        trackingDataRef.current = '';
        activityEventsRef.current = '';
      }
    } else {
      let newConfigData = getConfigData(page_name, page_id, appVersion, networkState);
      dispatch(recordTrackingData(newConfigData));
      trackActivityEvent(PAGE_VIEW, onPageView(page_name, page_id));
    }
  };

  useEffect(() => {
    let visibilityChange = null;
    if (typeof document.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }
    document.addEventListener(visibilityChange, reportOnVisibilityChange);
    document.addEventListener('pagehide', () => {
      reportData(tracking_config(), activity_events(), 'pagehide', dispatch);
      trackingDataRef.current = '';
      activityEventsRef.current = '';
    });

    let tempConfigData = getConfigData(page_name, page_id, appVersion, networkState);
    dispatch(recordTrackingData(tempConfigData));
    console.log('PAGE_VIEW', onPageView(page_name, page_id), page_name, page_id);
    if (!hasReportedPageView.current) {
      trackActivityEvent(PAGE_VIEW, onPageView(page_name, page_id));
      hasReportedPageView.current = true;
    }
    reportData(tempConfigData, activity_events(), 'pageview', dispatch);

    return () => {
      document.removeEventListener(visibilityChange, reportOnVisibilityChange);
      document.removeEventListener('pagehide', () => {
        reportData(tracking_config(), activity_events(), 'remove', dispatch);
      });
      removeSessionStorage('tracking_config');
      removeSessionStorage('activity_events');
      hasReportedPageView.current = false;
    };
  }, [page_name, page_id]);

  return <>{state?.from_login && isMobileApp() && <OnesignalAppInit />}</>;
};

export default memo(TrackerComponent);

/** Utility functions */
const getCurrentTime = () => dayjs(new Date().getTime()).format('HH:mm:ss DD:MM:YYYY');

const configureReferer = (url) => {
  let tempRefArr = [];
  tempRefArr = getSessionStorage('refer_url', []);
  if (tempRefArr && Array.isArray(tempRefArr) && tempRefArr.length > 0) {
    if (!tempRefArr[0]) {
      tempRefArr[0] = url;
    }
    if (url !== tempRefArr[1]) {
      tempRefArr[1] = url;
    }
  } else {
    tempRefArr = [];
    tempRefArr[0] = url;
  }
  setSessionStorage('refer_url', tempRefArr);
  return tempRefArr[0];
};

const getConfigData = (page_name, page_id, appVersion, networkState) => ({
  page: page_name ?? '0',
  page_id: page_id ?? '0',
  referrer: configureReferer(window.location.href) ?? '0',
  start_time: getCurrentTime() ?? '0',
  end_time: getCurrentTime() ?? '0',
  utm: getLocalStorage('utm_source', '') ? getLocalStorage('utm_source', '') : '',
  platform: getAgent(),
  version: appVersion,
  token: getLocalStorage('refreshToken', false) ? getLocalStorage('refreshToken', '') : '0',
  network_info: networkState,
  pageSessionId: pageSessionId,
  sessionId: getSessionStorage('sessionId', 'null')
});
