// import Color, { Palette } from 'color-thief-react';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';
import { reactHtmlParser, specialityPopOver } from '../../../common/common';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { RiPlayCircleFill } from '../../../components/icons';
import SponsorLogoSwiper from './SponsorLogoSwiper';
// import ReactHtmlParser from 'react-html-parser';
import DoctorsSectionForCard from '../../../components/DoctorSectionForCard/DoctorsSectionForCard';
import { clinical_Video_static_image } from '../../../common/assets.common';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
import { toggleReference } from '../../../Store/actions';
import { useDispatch } from 'react-redux';
import { removeHtmlTags } from '../../channel/utils';
const ClVideoHeroCard = ({ data, channel_name, channel_logo, gtmTagVariable = '' }) => {
  console.log(data);
  const {
    image,
    specialities,
    question,
    answer,
    sponsor_logo,
    play_time,
    src,
    type_id,
    session_doctor_entities
  } = data;
  const [sponserLogo, setSponserLogo] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoRendered, setVideoRendered] = useState(false);
  const [clVideoref, inView] = useInView({
    fallbackInView: false,
    threshold: 0
  });
  const videoDetailsParent = useRef();
  const [aspectVideoContainerSize, setAspectVideoContainerSize] = useState('0px');
  const playerRef = useRef();
  const onReady = React.useCallback(() => {
    if (play_time) {
      //console.log('===>>', play_time);

      playerRef.current.seekTo(play_time, 'seconds');
    }
  }, [playerRef.current]);
  const handleStart = () => {
    setLoading(false);
    setVideoRendered(true);
  };
  const setVideoConatinerSize = () => {
    if (videoDetailsParent.current) {
      const videoDivWidth = videoDetailsParent.current ? videoDetailsParent.current.clientWidth : 0;
      const aspectWidth = videoDivWidth / 1.7786;
      setAspectVideoContainerSize(aspectWidth > 0 ? Math.round(aspectWidth) : 0);
      //console.log('aspect size is' + aspectWidth);
    }
  };
  useEffect(() => {
    setVideoConatinerSize();
    //console.log('SET CONTAINER');
    if (data?.sponsor_logo) {
      // setSponserLogo(data?.sponsor_logo.split(','));
    }
  }, []);
  const handleVideoShow = () => {
    setLoading(true);
    setShowVideo(true);
    setTimeout(() => {
      setShowVideo(false);
      setVideoRendered(false);
    }, 30000);
  };
  const handleVideoClose = () => {
    setShowVideo(false);
    setLoading(false);
    setVideoRendered(false);
  };
  useEffect(() => {
    if (isMobile && !inView) {
      handleVideoClose();
    }
  }, [inView]);

  const dispatch = useDispatch();

  function onReadMoreClick() {
    dispatch(toggleReference(true));
  }
  return (
    <TrackerWrapper
      data={data}
      type={CARD_VIEW}
      className="w-100 float-start rounded-3 shadow overflow-hidden bg-white shareLoginV2Featured shareLoginV2ClVideos p-3 p-md-4 position-relative z-1"
    >
      <div className="shareLoginV2FeaturedIn d-flex flex-column flex-sm-row align-items-center">
        {/* {image && (
          <Palette src={image} crossOrigin="anonymous" format="hex" colorCount={4}>
            {({ data, loading }) => {
              if (loading) return null;
              return (
                
              );
            }}
          </Palette>
        )} */}
        <div
          className="shareLoginV2FeaturedCircle position-absolute rounded-circle"
          style={{ backgroundColor: '#355fd3' }}
        ></div>
        <div className="shareLoginV2FeaturedPic position-relative h-auto pe-4 pb-4">
          {image && (
            <div
              className="shareLoginV2FeaturedPicIn rounded-3 position-relative overflow-hidden z-1 w-100 h-100"
              ref={clVideoref}
            >
              <div
                className="w-100 float-start cursorPointer cmnVideoBoxContainer"
                ref={videoDetailsParent}
                style={{ height: `${aspectVideoContainerSize}px` }}
                onMouseEnter={handleVideoShow}
                onMouseLeave={handleVideoClose}
                onTouchMove={handleVideoShow}
              >
                {showVideo ? (
                  <div className="w-100 h-100 cmnVideosDbV2Video">
                    <ReactPlayer
                      ref={playerRef}
                      onReady={onReady}
                      onStart={handleStart}
                      url={src}
                      playing={true}
                      controls={false}
                      width={'100%'}
                      height={'100%'}
                      muted={true}
                    />
                  </div>
                ) : null}

                <LazyImage
                  src={data?.image ?? clinical_Video_static_image}
                  alt="video"
                  className="object-fit-cover w-100 h-100 float-start"
                  fallback_image={clinical_Video_static_image}
                />
                <div
                  className={`overlay cursorPointer d-flex align-items-center justify-content-center ${
                    loading ? 'dbClLoading' : ''
                  }${videoRendered ? 'dbClAfterLoading' : ''}`}
                >
                  {/* {!showVideo && <RiPlayCircleFill customClass="text-white fs-72" />} */}
                  {/* {loading && <img src={_cl.image} className="object-fit-cover w-100 h-100 float-start" />} */}
                  <RiPlayCircleFill customClass="text-white fs-72" />
                  <Spinner animation="border" />
                </div>
                <div className="w-100 h-100 cmnVideosTrigr cursorPointer"></div>
              </div>
            </div>
          )}
        </div>
        <div className="shareLoginV2FeaturedRight text-start ps-0 ps-md-20 mt-4 mt-md-0">
          {specialities && (
            <span className="text-black mb-2">
              <span className="d-flex fs-4 cursorPointer">
                {specialities?.split(',')[0]} {specialityPopOver(specialities)}
              </span>
            </span>
          )}
          <h2 className="fs-16 fw-medium text-black mb-2">{reactHtmlParser(question)}</h2>
          {answer && (
            <h4 className="line-clamp-2 fs-4 fw-normal text-dark">
              {removeHtmlTags(answer)?.substring(0, 180)}
              {removeHtmlTags(answer)?.length > 180 && (
                <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                  {' '}
                  ...Read More
                </a>
              )}
            </h4>
          )}
          <div className="d-flex justify-content-between mt-4 gap-3">
            <div
              className={`shareLogineV2Doctor ${
                sponsor_logo ? '' : 'shareLogineV2DoctorFull w-100'
              }`}
            >
              <DoctorsSectionForCard
                gtmTagVariable={`${gtmTagVariable}_clvideoHeroCard`}
                cardData={{ session_doctor_entities }}
                type="clVideo"
                id={type_id}
              />
              {channel_logo && (
                <div
                  className={`w-100 float-start shareLoginV2FeaturedChannel d-flex align-items-center position-relative ${
                    session_doctor_entities?.length > 0 ? 'mt-4' : ''
                  }`}
                >
                  <div className="rounded-circle shareLoginV2ChanlPic d-flex align-items-center justify-content-center bg-light position-absolute top-0 start-0">
                    <LazyImage
                      src={channel_logo}
                      alt="Profile Image"
                      className="mw-100 mh-100 w-auto h-auto"
                      extraClass={
                        'w-100 h-100 position-relative d-flex justify-content-center align-items-center'
                      }
                      logo
                    />{' '}
                  </div>
                  <span className="d-block fs-5 text-dark">{channel_name}</span>
                </div>
              )}
            </div>
            {sponsor_logo && (
              <SponsorLogoSwiper sponsorLogo={sponsor_logo ? sponsor_logo.split(',') : []} />
            )}
          </div>
        </div>
      </div>
    </TrackerWrapper>
  );
};

export default memo(ClVideoHeroCard);
