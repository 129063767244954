import React, { memo } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import './css/trainingSlider.scss';
import {
  BiLock,
  BsPlay,
  ClinicalVideoLogo,
  LiveCmeLogo,
  MedwikiLogo,
  SpqLogo,
  TrainingLogo
} from '../../../../../components/icons';
import { MEDWIKI_NAME, timeDifference } from '../../../../../common/common';
import DoctorsSectionForCard from '../../../../../components/DoctorSectionForCard/DoctorsSectionForCard';
import LazyImage from '../../../../../components/CustomLibraries/LazyImage';
import {
  clinical_Video_static_image,
  medwiki_static_image,
  quizzes_static_image
} from '../../../../../common/assets.common';
import { default_images } from '../../../../../common/defaultImages';
import TrackerWrapper from '../../../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../../../components/tracking/constants';
import RelatedCard from '../../../../../components/baseUiV3/contentCards/RelatedCard';
import { generateProps } from '../../../../../utils/generateProps';
import useContentAccessRedirect from '../../../../../common/useContentAccessRedirect';
// import { BiLock, BsPlay, BsPlayCircleFill, LiveCmeLogo } from '../../../../components/icons';
const CLINICAL_DEMO = clinical_Video_static_image;
const SESSION_DEMO =
  'https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400086/55848266-modern-thin-line-design-concept-for-healthcare-website-banner-vector-illustration-concept-for-health.jpg';
const MEDWIKI_DEMO = medwiki_static_image;
const QUIZ_DEMO = quizzes_static_image;

const demo_image = quizzes_static_image;

SwiperCore.use([Pagination, Navigation]);
const TrainingSlider = ({ contentArray }) => {
  const arrayWithFirstLiveVideo = (contents) => {
    if (contents.some((_conItem) => _conItem?.type == 'live_video')) {
      let conArrCopy = [...contents];
      const foundLiveVideo = conArrCopy.find((_conItem) => _conItem?.type == 'live_video');
      const indexOfLiveVideo = conArrCopy.findIndex((_conItem) => _conItem?.type == 'live_video');
      conArrCopy.splice(indexOfLiveVideo, 1);
      return [foundLiveVideo, ...conArrCopy];
    }
    return contents;
  };
  // const keys = Object.keys(contentArray.data);
  const values = Array.isArray(contentArray.data) ? contentArray.data : [];
  const TrainingContentCard = ({
    image,
    is_video,
    is_live,
    type,
    title,
    content_id,
    default_image,
    category = '',
    doctors = [],
    data
  }) => {
    const showLogo = () => {
      switch (type) {
        case 'archivevideo':
          return (
            <>
              <ClinicalVideoLogo className={'fs-2 text-secondary'} /> Clinical Video
            </>
          );
        case 'session':
          return (
            <>
              <LiveCmeLogo className={'fs-2 text-secondary'} /> Live CME
            </>
          );
        case 'comp':
          return (
            <>
              <MedwikiLogo className={'fs-2 text-secondary'} /> {MEDWIKI_NAME}
            </>
          );
        case 'survey':
          return (
            <>
              <SpqLogo className={'fs-2 text-secondary'} />{' '}
              {category == 'poll' ? 'Poll' : category == 'quiz' ? 'Quiz' : 'Survey'}
            </>
          );
        case 'live_video':
          return (
            <>
              <TrainingLogo className={'fs-2 text-secondary'} /> Live Training
            </>
          );
        default:
          return <></>;
      }
    };
    const { contentAccessRedirect } = useContentAccessRedirect();

    return (
      // <TrackerWrapper
      //   className="w-100 float-start grContentCard"
      //   type={CARD_VIEW}
      //   data={{ type, type_id: content_id }}
      // >
      //   <div className="w-100 float-start rounded-3 grContentCardPic cursorPointer position-relative overflow-hidden mb-4">
      //     <div className="w-100 h-100 position-relative">
      //       <LazyImage
      //         className="object-fit-cover w-100 h-100 float-start"
      //         alt="banner"
      //         src={image ?? default_image}
      //         fallback_image={default_image}
      //       />
      //     </div>
      //     <div className="overlay d-flex align-items-center justify-content-center"></div>
      //     {is_video && (
      //       <div className="rounded-circle d-inline-flex align-items-center justify-content-center position-absolute top-50 start-50 translate-middle cursorPointer grContentCardVideoPlayBtn border border-2 border-white p-3">
      //         <i className={`flaticon-play fs-36 text-white`}></i>
      //       </div>
      //     )}
      //     <div className="grContentCardLock bg-secondary text-white position-absolute top-0 p-4 d-flex justify-content-end align-items-start">
      //       <i className={`flaticon-lock-symbol fs-1 text-white`}></i>
      //     </div>
      //   </div>
      //   <div className="w-100 float-start grContentCardDetails">
      //     <h4 className="fs-16 fw-medium text-dark mb-4 grContentCardTitle line-clamp-3 lh-base">
      //       {title}
      //     </h4>
      //     {/* <div className="w-100 float-start"> */}
      //     <DoctorsSectionForCard
      //       cardData={{ session_doctor_entities: doctors }}
      //       type={type}
      //       id={content_id}
      //       className="mb-4"
      //     />
      //     {/* </div> */}
      //     <div className="w-100 float-start grContentCardDetailsBtm d-flex align-items-center justify-content-between">
      //       <span className="fs-4 text-black grContentCardSpeclty d-inline-flex align-items-center gap-2">
      //         {showLogo()}
      //       </span>
      //       {is_live && (
      //         <div>
      //           <span className="text-danger fs-4 fw-medium me-3 d-inline-flex align-items-center gap-2">
      //             <div className="pulseCircle"></div> Live Now
      //           </span>
      //         </div>
      //       )}
      //     </div>
      //   </div>
      // </TrackerWrapper>
      <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
        <div
          style={{ zIndex: 10 }}
          className="grContentCardLock bg-secondary text-white position-absolute top-0 p-4 d-flex justify-content-end align-items-start"
        >
          <i className={`flaticon-lock-symbol fs-1 text-white`}></i>
        </div>
        <RelatedCard
          data={data}
          // pageId={id}
          cardType="lg"
          {...generateProps(data, contentAccessRedirect)}
        />
      </div>
    );
  };
  return contentArray ? (
    <div className="cmnLandingSlideRow mb-4 mb-md-5">
      {/* <div className="col-xxl-4 col-lg-4 col-md-6 col">
            <div className="w-100 float-start grContentCard">
              <div className="w-100 float-start ssnCardV1BoxPic cursorPointer">
                <div className="w-100 h-100 position-relative">
                  <img
                    className="object-fit-cover w-100 h-100 float-start"
                    alt="banner"
                    src="https://storage.googleapis.com/medwiki/webp/images/1654161010_943.webp"
                  />
                </div>
                <div className="overlay d-flex align-items-center justify-content-center"></div>
              </div>
            </div>
          </div> */}

      <div className="cmnLndngRowSlidePrrnt">
        <Swiper
          className="cmnLndngRowSlide"
          spaceBetween={24}
          slidesPerView={3}
          loop={false}
          breakpoints={{
            1: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 22
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 24
            },
            1400: {
              slidesPerView: 4,
              spaceBetween: 26
            }
          }}
        >
          {contentArray &&
            values.length > 0 &&
            arrayWithFirstLiveVideo(values)?.map((_conItem, _idx) => {
              const _con = _conItem?.type;
              const details = _conItem?.detail;
              const content_id = _conItem?.content_id;
              return (
                <>
                  {_con == 'session' && details?.length > 0 && (
                    <SwiperSlide key={_idx + 1}>
                      <TrainingContentCard
                        data={_conItem?.detail}
                        content_id={content_id}
                        type={_con}
                        default_image={SESSION_DEMO}
                        title={details[0]?.session_topic}
                        image={details[0]?.cover_image[0]}
                        doctors={details[0]?.session_doctor_entities ?? []}
                        is_video={false}
                        is_live={
                          details[0]?.start_datetime && details[0]?.end_datetime
                            ? timeDifference(details[0]?.start_datetime) >= 0 &&
                              timeDifference(details[0]?.end_datetime) <= 0
                            : false
                        }
                      />
                    </SwiperSlide>
                  )}
                  {_con == 'archivevideo' && (
                    <SwiperSlide key={_idx + 1}>
                      <TrainingContentCard
                        data={_conItem?.detail}
                        content_id={content_id}
                        default_image={CLINICAL_DEMO}
                        type={_con}
                        title={details.title}
                        doctors={details.session_doctor_entities ?? []}
                        image={details.image}
                        is_video={true}
                        is_live={false}
                      />
                    </SwiperSlide>
                  )}
                  {_con == 'survey' && details?.length > 0 && (
                    <SwiperSlide key={_idx + 1}>
                      <TrainingContentCard
                        data={_conItem?.detail}
                        content_id={content_id}
                        default_image={QUIZ_DEMO}
                        type={_con}
                        title={details[0]?.survey_title}
                        image={details[0]?.image}
                        category={details[0]?.category ?? ''}
                        is_video={false}
                        is_live={false}
                      />
                    </SwiperSlide>
                  )}
                  {_con == 'live_video' && (
                    <SwiperSlide key={_idx + 1}>
                      <TrainingContentCard
                        data={_conItem?.detail}
                        content_id={content_id}
                        default_image={default_images.live_training}
                        type={_con}
                        title={'This training is live now'}
                        image={details?.image}
                        is_video={true}
                        is_live={true}
                      />
                    </SwiperSlide>
                  )}
                  {_con == 'comp' && (
                    <SwiperSlide key={_idx + 1}>
                      <TrainingContentCard
                        data={_conItem?.detail}
                        default_image={MEDWIKI_DEMO}
                        content_id={content_id}
                        type={_con}
                        title={details?.question}
                        image={details?.image}
                        is_video={false}
                        is_live={false}
                      />
                    </SwiperSlide>
                  )}
                </>
              );
            })}
        </Swiper>
      </div>
    </div>
  ) : null;
};

export default memo(TrainingSlider);
