function pad(string) {
  return ('0' + string).slice(-2);
}
export const parseDuration = (duration) => {
  let parsed = duration?.split(':');
  if (parsed && parsed.length > 0) {
    const hrs = parseInt(parsed[0]);
    const mins = parseInt(parsed[1]);
    if (hrs > 0) {
      return {
        duration: `${pad(hrs)}${mins > 0 ? `:${pad(mins)}` : ''}`,
        unit: hrs > 1 ? 'hrs' : 'hr'
      };
    } else if (mins > 0) {
      return {
        duration: pad(mins),
        unit: 'mins'
      };
    } else {
      return {
        duration: 0,
        unit: 'hr'
      };
    }
  } else {
    return {
      duration: 0,
      unit: 'hr'
    };
  }
};
export const colorDecider = (percentage) => {
  if (percentage >= 0 && percentage < 30) {
    return '#d82650';
  } else if (percentage >= 30 && percentage < 75) {
    return '#d9ae3a';
  } else if (percentage >= 75) {
    return '#269d6f';
  } else {
    return '#d82650';
  }
};
