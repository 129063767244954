import dayjs, { Dayjs } from 'dayjs';
import checkForPremiumIcon from '../common/checkForPremiumIcon';
import { isUserLoggedIn, MEDWIKI_NAME, timeDifference } from '../common/common';
import { default_images } from '../common/defaultImages';
import useRedirect from '../components/customHooks/useRedirect';
import { routeNames } from '../router/constants';

export const generateProps = (data, contentAccessRedirect, afterClick = null) => {
  const {
    question,
    image,
    survey_title,
    title,
    type_id,
    survey_id,
    is_locked,
    price,
    user_content_payment,
    category
  } = data || {};

  console.log('ppppppppppppp', data);

  let type = data?.type || data?.trending_type || '';
  if (type === 'comp')
    return {
      internalType: 'comp',
      type_id,
      icon: 'flaticon-medwiki',
      title: question,
      image: image,
      contentType: MEDWIKI_NAME,
      fallbackImage: default_images.medwiki,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      isVideo: data?.con_type,
      startDateTime: data?.start_datetime,
      point: data?.point ? `${data?.point} points` : null,

      footerSection: {
        icon: 'heart-1', // 1
        iconText: data?.rating || null, // 1
        SpeakerDocs: data?.session_doctor_entities || null, // 0
        sponsorLogo: data?.sponsor_logo || data?.sponsor_names_and_logos || null, // 0
        buttonTitle: null // 1
      },

      shareIcon: {
        isShare: data?.is_share || null,
        deeplink: data?.deeplink || null,
        question: data?.question || null,
        moduleType: data?.type || null
      },

      onClick: () => {
        afterClick && afterClick();
        if (isUserLoggedIn()) {
          contentAccessRedirect(
            routeNames.medwiki.detail,
            type_id,
            question,
            is_locked,
            price,
            user_content_payment,
            '',
            data
          );
        }
      }
    };
  else if (type === 'video_archive' || type === 'video' || data.con_type === 'video')
    return {
      internalType: 'archived_video',
      type_id,
      icon: 'flaticon-clinicalvideo',
      title: question,
      image: image,
      contentType: 'Clinical Video',
      fallbackImage: default_images.clinical,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      startDateTime: data?.start_datetime,
      point: data?.point ? `${data?.point} points` : null,
      duration: data?.duration,
      playTime: data?.play_time,
      src: data?.src,

      shareIcon: {
        isShare: data?.is_share || null,
        deeplink: data?.deeplink || null,
        question: data?.question || null,
        moduleType: data?.type || null
      },

      footerSection: {
        icon: 'heart-1', // 1
        iconText: data?.rating || null, // 1
        SpeakerDocs: data?.session_doctor_entities || null, // 0
        sponsorLogo: data?.sponsor_logo || data?.sponsor_names_and_logos || null, // 0
        buttonTitle: null // 1
      },

      onClick: () => {
        afterClick && afterClick();
        if (isUserLoggedIn()) {
          contentAccessRedirect(
            routeNames.clinicalVideo.detail,
            type_id,
            question,
            is_locked,
            price,
            user_content_payment,
            '',
            data
          );
        }
      }
    };
  else if (type === 'gr')
    return {
      internalType: 'gr',
      type_id,
      icon: 'flaticon-grandround',
      title: title,
      image: image,
      contentType: 'Grand Round',
      fallbackImage: default_images.gr,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,

      startDateTime: data?.start_datetime,
      point: data?.point ? `${data?.point} points` : null,

      shareIcon: {
        isShare: data?.is_share,
        deeplink: data?.deeplink,
        question: question,
        moduleType: data?.type
      },

      footerSection: {
        icon: null, // 1
        iconText: null, // 1
        SpeakerDocs: data?.session_doctor_entities || null, // 0
        sponsorLogo: data?.sponsor_logo || data?.sponsor_names_and_logos || null, // 0
        buttonTitle: null // 1
      },
      onClick: () => {
        afterClick && afterClick();
        if (isUserLoggedIn()) {
          contentAccessRedirect(
            routeNames.grandRound.detail,
            type_id,
            title,
            is_locked,
            price,
            user_content_payment,
            '',
            data
          );
        }
      }
    };
  else if (
    type === 'survey' ||
    data.category === 'survey' ||
    data.category === 'quiz' ||
    data.category === 'poll'
  )
    return {
      internalType: 'survey',
      category,
      survey_id: survey_id,
      type_id: survey_id,
      icon: 'flaticon-polls',
      title: survey_title,
      image: image,
      contentType: data?.category,
      fallbackImage: data?.category == 'quiz' ? default_images.quiz : default_images.survey,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,

      startDateTime: data?.start_datetime,
      point: data?.point ? `${data?.point} points` : null,

      shareIcon: {
        isShare: data?.is_share || null,
        deeplink: data?.deeplink || null,
        question: data?.question || null,
        moduleType: data?.type || null
      },

      footerSection: {
        icon: null, // 1
        iconText: null, // 1
        SpeakerDocs: data?.session_doctor_entities || null, // 0
        sponsorLogo: data?.sponsor_logo || data?.sponsor_names_and_logos || null, // 0
        buttonTitle: null // 1
      },
      onClick: () => {
        afterClick && afterClick();
        if (isUserLoggedIn()) {
          contentAccessRedirect(
            routeNames.spq.detail,
            survey_id,
            survey_title,
            is_locked,
            price,
            user_content_payment,
            '',
            data
          );
        }
      }
    };
  else if (type === 'training') {
    return {
      internalType: 'training',
      type_id,
      icon: 'flaticon-training',
      title: title,
      image: image,
      contentType: 'Certified Course',
      id: type_id,
      fallbackImage: default_images.training,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      startDateTime: data?.start_datetime,
      point: data?.duration ? `${data?.duration} hrs` : null,

      contentStatus:
        data?.is_completed < 100 && data?.is_completed > 0
          ? 'Inprogress'
          : data?.is_completed === 100
          ? 'Completed'
          : null,
      statusColor: data?.is_completed < 100 ? 'text-warning' : 'text-success',

      shareIcon: {
        isShare: data?.is_share || null,
        deeplink: data?.deeplink || null,
        question: data?.question || null,
        moduleType: data?.type || null
      },

      footerSection: {
        icon: data?.is_certificate ? 'certificate-1' : null, // 1
        iconText: data?.is_certificate ? 'Certification available' : null, // 1
        SpeakerDocs: data?.session_doctor_entities || null, // 0
        sponsorLogo: data?.sponsor_logo || data?.sponsor_names_and_logos || null, // 0
        buttonTitle: null // 1
      },

      onClick: () => {
        afterClick && afterClick();

        if (isUserLoggedIn()) {
          contentAccessRedirect(
            data?.url
              ? routeNames.course.cover
              : data?.is_completed > 0
              ? routeNames.course.content
              : routeNames.course.cover,
            data?.id,
            title,
            is_locked,
            price,
            user_content_payment,
            '',
            data
          );
        }
      }
    };
  } else if (type == 'session') {
    return {
      internalType: 'session',
      type_id: data?.session_id,
      icon: 'flaticon-livecme',
      title: data?.session_topic,
      image: Array.isArray(data?.cover_image)
        ? data?.cover_image.filter((item) => item && item != '')[0] || ''
        : data?.cover_image,
      contentType: 'Live CME',
      id: data?.session_id,
      fallbackImage: default_images.session,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      sessionStatus: data?.session_status,
      startDateTime: data?.start_datetime,
      point: data?.point ? `${data?.point} points` : null,
      isLive:
        data && data?.start_datetime && data?.end_datetime
          ? timeDifference(data?.start_datetime) >= 0 && timeDifference(data?.end_datetime) <= 0
          : false,
      shareIcon: {
        isShare: data?.is_share || null,
        deeplink: data?.deeplink || null,
        question: data?.question || null,
        moduleType: data?.type || null
      },

      footerSection: {
        icon: null, // 1
        iconText: null, // 1
        SpeakerDocs: data?.session_doctor_entities || null, // 0
        sponsorLogo: data?.sponsor_logo || data?.sponsor_names_and_logos || null, // 0
        buttonTitle: null // 1
      },
      onClick: () => {
        afterClick && afterClick();
        if (isUserLoggedIn()) {
          contentAccessRedirect(
            routeNames.sessions.detail,
            data?.session_id,
            data?.session_topic,
            is_locked,
            price,
            user_content_payment,
            '',
            data
          );
        }
      }
    };
  } else if (type == 'epub') {
    return {
      internalType: 'epub',
      type_id: data?.type_id,
      icon: 'flaticon-ebook',
      title: data?.title,
      image: data?.image,
      contentType: 'Epaper',
      id: data?.type_id,
      fallbackImage: default_images.epub,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      startDateTime: data?.start_datetime,
      point: data?.point ? `${data?.point} points` : null,

      shareIcon: {
        isShare: data?.is_share || null,
        deeplink: data?.deeplink || null,
        question: data?.question || null,
        moduleType: data?.type || null
      },

      footerSection: {
        icon: null, // 1
        iconText: null, // 1
        SpeakerDocs: data?.session_doctor_entities || null, // 0
        sponsorLogo: data?.sponsor_logo || data?.sponsor_names_and_logos || null, // 0
        buttonTitle: null // 1
      },
      onClick: () => {
        afterClick && afterClick();
        if (isUserLoggedIn()) {
          contentAccessRedirect(
            routeNames.epaper.detail,
            data?.type_id,
            data?.title,
            is_locked,
            price,
            user_content_payment,
            '',
            data
          );
        }
      }
    };
  }
};
