import React, { useState, useEffect } from 'react';
import './css/error_fallback.scss';
import '../../../src/assets/css/animation/fallback.css';
import packageJson from '../../../package.json';
import useRedirect from '../../components/customHooks/useRedirect';
import LoadingFallback from '../../router/fallback/LoadingFallback';
import axios from 'axios';
import { getLocalStorage, setLocalStorage } from '../../common/common';
export const ErrorFallback = ({ error, info }) => {
  const authService = process.env.REACT_APP_API_URL;
  const { redirectTo } = useRedirect();

  const deleteCaches = () => {
    caches
      .keys()
      .then((keys) =>
        Promise.all(
          keys.map((key) => {
            if (!key?.includes('workbox-precache')) {
              return caches.delete(key);
            }
          })
        )
      )
      .then(() => {
        console.log('SW now ready to handle fetches!');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearLocalStorage = () => {
    deleteCaches();
    const allKeys = Object.keys(localStorage);

    for (const key of allKeys) {
      localStorage.removeItem(key);
    }
    sessionStorage.clear();
  };

  const onReloadClick = () => {
    window.location.reload();
  };

  const onRedirect = () => {
    redirectTo('Dashboard');
    window.location.reload();
  };

  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const response = await axios.post(`${authService}auth/getAccessToken`, {
        token: getLocalStorage('refreshToken', '')
      });

      if (response.data.status_code === 200) {
        const newToken = response.data.data.token;
        setLocalStorage('accessToken', newToken);
        setLoading(false);
      }
    } catch (error) {
      try {
        const refreshResponse = await axios.post(`${authService}auth/getRefreshToken`, {
          token: getLocalStorage('refreshToken', '')
        });

        if (refreshResponse.data.status_code === 200) {
          const newToken = refreshResponse.data.data.token;
          setLocalStorage('accessToken', newToken);
          setLocalStorage('refreshToken', refreshResponse.data.data.refresh_token);
          setLoading(false);
        }
      } catch (error) {
        clearLocalStorage();
        window.location.href = '/';
      }
    }
  }, []);

  if (loading) return <LoadingFallback />;
  return (
    <>
      {/* <TrackerComponent page_name="error" module_page="error" /> */}
      <div className="error_container d-flex justify-content-center align-items-center text-white position-sticky overflow-hidden vh-100">
        <div id="clouds">
          <div className="cloud x1"></div>
          <div className="cloud x1_5"></div>
          <div className="cloud x2"></div>
          <div className="cloud x3"></div>
          <div className="cloud x4"></div>
          <div className="cloud x5"></div>
        </div>
        <div className="c">
          <div className="_404 position-relative d-inline-block z-2 h-auto">Oops!</div>
          <div className="_1 position-relative d-block text-center">Something went wrong</div>
          {/* <div className="_2">WAS NOT FOUND</div> */}
          <a
            className="error-btn me-3 bg-white position-relative d-inline-block py-3 px-20 mw-100 fs-3 my-0 mx-auto rounded mt-20 gtm_cl_errorFallback_back_to_home"
            href="javascript:void(0)"
            onClick={onRedirect}
          >
            BACK TO HOME
          </a>
          <a
            href="javascript:void(0)"
            className="error-btn bg-white position-relative d-inline-block py-3 px-20 mw-100 fs-3 my-0 mx-auto rounded mt-20 errorFallback_reload"
            type="button"
            onClick={onReloadClick}
          >
            RELOAD
          </a>
          <h5 className="text-white text-center mt-5 fw-semibold fs-3">
            <p>CLIRNET v{packageJson.version}</p>
          </h5>
        </div>
      </div>
    </>
  );
};
