import React from 'react';
import LazyImage from '../../../../components/CustomLibraries/LazyImage';
import { DEMO_USER_PICTURE } from '../../../../common/common';
import {
  CertificateBigIcon,
  CertificateIcon,
  CertificateSharePageIcon
} from '../../../../components/icons';

function ShareTrainingScrollCard({ disclaimer, focusToForm, layout = 'row' }) {
  return (
    <div className="w-100 float-start mb-5">
      <div className={`d-flex flex-${layout} ms-n4 ms-md-n5 mt-n4 mt-md-n5 me-0 flex-wrap`}>
        <div
          className={`${
            layout === 'row' ? 'col-xl-6' : 'col-xl-12'
          } col-lg-12 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0`}
        >
          <div className="grCertifiedDocs rounded-3 bg-white shadow overflow-hidden">
            <div className="d-flex flex-column flex-sm-row grCertifiedDocsMain">
              <div className="grCertifiedDocsLeft d-inline-flex justify-content-center align-items-center ps-3 ps-md-4 pe-3 pe-md-0">
                <div className="grCertifiedDocsLeftIn d-flex justify-content-center position-relative overflow-hidden w-100 h-100 text-center z-1">
                  <div className="traningCertiDocAnimate traningCertiDocAnimate-1 mt-n5 mx-n2 mb-0">
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        {/* <span className="fs-6 fw-normal text-black lh-1">
                            <span className="fs-4 fw-medium d-block">9</span> Certificates
                          </span> */}
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="traningCertiDocAnimate traningCertiDocAnimate-2 mt-n5 mx-n2 mb-0">
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        {/* <span className="fs-6 fw-normal text-black lh-1">
                              <span className="fs-4 fw-medium d-block">9</span> Certificates
                            </span> */}
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="traningCertiDocAnimate traningCertiDocAnimate-3 mt-n5 mx-n2 mb-0">
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="traningCertiDocAnimate traningCertiDocAnimate-4 mt-n5 mx-n2 mb-0">
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="traningCertiDocAnimate traningCertiDocAnimate-5 mt-n5 mx-n2 mb-0">
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                    <div className="traningCertiDocBox rounded-pill p-3 pb-20 p-xl-2 pb-xl-20 float-start my-0 mx-2">
                      <div className="grCertifiedDocPic rounded-circle mb-3 position-relative overflow-hidden mx-auto">
                        <LazyImage
                          className="object-fit-cover w-100 h-100 float-start"
                          src={DEMO_USER_PICTURE}
                        />
                      </div>
                      <div className="grCertifiedDocDetails">
                        <h4 className="line-clamp-2 fs-5 fw-normal text-black mb-2">
                          Prof. Dr. Chandrashekhar E. Deopujari
                        </h4>
                        <CertificateIcon customClass={'fs-30 text-secondary mb-2'} />
                        <span className="fs-6 fw-normal text-black lh-1">
                          <span className="fs-4 fw-medium d-block">9</span> Certificates
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grCertifiedDocsRight bg-secondary d-flex align-items-center justify-content-center position-relative z-1 flex-grow-1 p-4 ps-4 p-md-20 ps-md-50">
                <CertificateSharePageIcon />

                <div className="grCertifiedDocsRightIn d-flex flex-column">
                  <CertificateBigIcon
                    customClass={'grCertifiedDocsRightIcon text-white mb-4 mx-auto'}
                  />
                  <h4 className="fs-30 fw-semibold text-white">12000</h4>
                  <span className="fs-16 fw-normal text-white">
                    Doctors get
                    <span className="fs-2 fw-light d-block">Certified till now!</span>
                  </span>
                  <div className="d-block mt-4">
                    <a
                      href="javascript:void(0)"
                      className="btn fw-normal text-white rippleEffect rounded-3 text-capitalize getCertifiedBtn py-3 px-4 text-nowrap"
                      onClick={focusToForm}
                    >
                      Get one now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            layout === 'row' ? 'col-xl-6' : 'col-xl-12'
          } col-lg-12 col-12 mt-4 ps-4 mt-md-5 ps-md-5 pe-0`}
        >
          <div className="w-100 float-start h-100 text-start fs-5 disclaimer mb-5 rounded-3 bg-white shadow position-relative overflow-hidden p-4">
            <h6 className="text-black fs-5 mb-3">Disclaimer</h6>
            {disclaimer ? (
              <p>{disclaimer}</p>
            ) : (
              <p>
                All scientific content on the platform is provided for general medical education
                purposes meant for registered medical practitioners only. The content is not meant
                to substitute for the independent medical judgment of a physician relative to
                diagnostic and treatment options of a specific patient’s medical condition. In no
                event will CLIRNET be liable for any decision made or action taken in reliance upon
                the information provided through this content.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareTrainingScrollCard;
