import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { getLocalStorage } from '../../common/common';
// import { log } from '../../common/common';
import axiosInstance from '../../Store/helper/axiosInstance';
import {
  ALL_SPECIALITIES,
  CPD_DATA,
  CPD_MATRICS,
  PROFILE_COMPLETION,
  PROFILE_VIDEOS,
  REQUESTED_RECORDINGS,
  USER_DETAILS,
  USER_PROFILE_ALL,
  CPD_REELS
} from '../QueryKeys/profile.key';
// import { demoJsonProfile } from './demoProfileJson';

const staleTime = 300000;
const cacheTime = 600000;
export const useUserDetails = (onSuccess, onError, enabled = true) => {
  return useQuery(USER_DETAILS, () => axiosInstance.get('user/detail'), {
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
    // select: (response) => response.data.data,
    select: (response) => ({
      data: response.data.data,
      statusCode: response.status
    }),
    enabled: !!enabled,
    cacheTime: cacheTime
  });
};
export const useProfileCompletion = (onSuccess, onError) => {
  return useQuery(PROFILE_COMPLETION, () => axiosInstance.get('dashboard/user'), {
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
    // enabled: false,
    // select: (response) => response.data.data,
    select: (response) => ({
      data: response.data.data,
      statusCode: response.status
    }),
    cacheTime: cacheTime
  });
};
export const useProfileAll = (onSuccessCb, onErrorCb) => {
  const queryClient = useQueryClient();
  return useQuery(USER_PROFILE_ALL, () => axiosInstance.get('profile/profile_detail'), {
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      queryClient.invalidateQueries(PROFILE_COMPLETION);
    },

    onError: (_err) => {
      onErrorCb && onErrorCb();
      //   queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
      //     return demoJsonProfile;
      //   });
    },
    select: (response) => {
      const { education, membership, achievement, registration } = response.data.data;
      return {
        educationList: education ?? [],
        membershipList: membership ?? [],
        achievementList: achievement ?? [],
        registrationList: registration ?? []
      };
    },

    cacheTime: cacheTime
  });
};
//authnew/getallspeciality

export const useAllSpecialities = (onSuccess, onError) => {
  return useQuery(ALL_SPECIALITIES, () => axiosInstance.get('authnew/getallspeciality'), {
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
    select: (response) => response.data?.data?.speciality_data ?? [],
    staleTime: staleTime,
    cacheTime: cacheTime
  });
};
const submitEducation = (data) => {
  return axiosInstance.post('profile/submit_education', data);
};
const editEducation = (data) => {
  return axiosInstance.post('profile/edit_education', data);
};
const submitMembership = (data) => {
  return axiosInstance.post('profile/submit_membership', data);
};
const editMembership = (data) => {
  return axiosInstance.post('profile/edit_membership', data);
};
const submitAchievement = (data) => {
  return axiosInstance.post('profile/submit_achievement', data);
};
const editAchievement = (data) => {
  return axiosInstance.post('profile/edit_achievement', data);
};
const submitRegistration = (data) => {
  return axiosInstance.post('profile/submit_registration', data);
};
const editRegistration = (data) => {
  return axiosInstance.post('profile/edit_registration', data);
};
export const useAddEducation = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(submitEducation, {
    onMutate: (newEdu) => {
      const {
        education_degree,
        education_description,
        education_from_year,
        education_school,
        education_to_year
      } = newEdu;
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            data: {
              ...prev.data.data,
              education: [
                ...prev.data.data.education,
                {
                  degree: education_degree,
                  college: education_school,
                  start_year: education_from_year,
                  end_year: education_to_year,
                  description: education_description
                }
              ]
            }
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};
export const useEditEducation = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(editEducation, {
    onMutate: (newEdu) => {
      const {
        education_id,
        education_degree,
        education_description,
        education_from_year,
        education_school,
        education_to_year
      } = newEdu;
      console.log(newEdu);
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        let idxToChange = prev.data.data.education.findIndex(
          (_e) => _e.education_id == education_id
        );

        prev.data.data.education[idxToChange] = {
          education_id,
          degree: education_degree,
          college: education_school,
          start_year: education_from_year,
          end_year: education_to_year,
          description: education_description
        };
        return prev;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};
//profile/submit_education
export const useAddMembership = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(submitMembership, {
    onMutate: (newMem) => {
      const { end_year, institute_name, is_current_member, start_year } = newMem;
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            data: {
              ...prev.data.data,
              membership: [
                ...prev.data.data.membership,
                {
                  institute_name,
                  start_year,
                  end_year,
                  is_current_member
                }
              ]
            }
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};
export const useEditMembership = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(editMembership, {
    onMutate: (newMem) => {
      const { membership_id, end_year, institute_name, is_current_member, start_year } = newMem;
      //   console.log(newEdu);
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        let idxToChange = prev.data.data.membership.findIndex(
          (_e) => _e.membership_id == membership_id
        );

        prev.data.data.membership[idxToChange] = {
          membership_id,
          institute_name,
          start_year,
          end_year,
          is_current_member
        };
        return prev;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};
//https://34.100.156.95/rnv28/knwlg/getrecordinglist?from=0&to=10
export const useRequestedRecordings = (onSuccess, onError) => {
  return useQuery(
    REQUESTED_RECORDINGS,
    () => axiosInstance.get('knwlg/getrecordinglist?from=0&to=20'),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      // select: (response) => response.data.data,
      select: (response) => ({
        data: response.data.data,
        statusCode: response.status
      }),
      staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};
const getVideos = (type, pageParam) => {
  let api_url = '';
  if (type == 'recording') {
    api_url = `knwlg/getrecordinglist?from=${pageParam}&to=10`;
  } else {
    api_url = `knwlg/historyvideo?from=${pageParam}&to=10`;
  }
  return axiosInstance.get(api_url);
};
export const useProfileVideos = (type, onSuccess, onError) => {
  return useInfiniteQuery(
    [PROFILE_VIDEOS, type],
    ({ pageParam = 0 }) => getVideos(type, pageParam),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length == 10) {
          // console.log(allPages.length);
          return allPages.length * 10;
        } else {
          return undefined;
        }
      },
      staleTime: staleTime,
      cacheTime: cacheTime
    }
  );
};
// Achievement
export const useAddAchievement = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(submitAchievement, {
    onMutate: (newAch) => {
      const { institute, year, description } = newAch;
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            data: {
              ...prev.data.data,
              achievement: [
                ...prev.data.data.achievement,
                {
                  institute,
                  year,
                  description
                }
              ]
            }
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};
export const useEditAchievement = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(editAchievement, {
    onMutate: (newAch) => {
      const { achv_id, institute, year, description } = newAch;
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        let idxToChange = prev.data.data.achievement.findIndex((_e) => _e.achv_id == achv_id);

        prev.data.data.achievement[idxToChange] = {
          achv_id,
          institute,
          year,
          description
        };

        return prev;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};
export const useAddRegistration = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(submitRegistration, {
    onMutate: (newReg) => {
      const { institute_name, registration_number, year } = newReg;
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            data: {
              ...prev.data.data,
              registration: [
                ...prev.data.data.registration,
                { institute_name, registration_no: registration_number, year }
              ]
            }
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};

export const useEditRegistration = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(editRegistration, {
    onMutate: (newReg) => {
      const { registration_id, institute_name, registration_number, year } = newReg;
      const previousData = queryClient.getQueryData(USER_PROFILE_ALL);
      queryClient.setQueryData(USER_PROFILE_ALL, (prev) => {
        let idxToChange = prev.data.data.registration.findIndex(
          (_e) => _e.registration_id == registration_id
        );

        prev.data.data.registration[idxToChange] = {
          registration_id,
          institute_name,
          registration_no: registration_number,
          year
        };

        return prev;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(USER_PROFILE_ALL, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};

const submitUserGoal = (data) => {
  return axiosInstance.post('cpd/addanalytics', data);
};
export const useSubmitUserGoal = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(submitUserGoal, {
    onMutate: (userGoal) => {
      // const { institute_name, registration_number, year } = userGoal;
      const previousData = queryClient.getQueryData(CPD_MATRICS);
      queryClient.setQueryData(CPD_MATRICS, (prev) => {
        console.log('cpd prev data', prev);
        let tempPrev = prev;
        tempPrev.data.data.data = userGoal;
        tempPrev.data.data.status = 1;
        return {
          ...tempPrev
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      queryClient.setQueryData(CPD_MATRICS, context.previousData);
    },
    onSettled: (_err, _obj, context) => {
      queryClient.invalidateQueries(USER_PROFILE_ALL);
    },
    onSuccess
  });
};

export const useGetCpdMetrix = (onSuccess, onError) => {
  const utmSource = getLocalStorage('utm_source', '0');
  return useQuery(CPD_MATRICS, () => axiosInstance.get(`cpd/analytics?utm_source=${utmSource}`), {
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
    select: (response) => response.data?.data ?? [],
    staleTime: staleTime,
    cacheTime: cacheTime
  });
};

export const useGetCpdReelsData = (onSuccess, onError) => {
  const utmSource = getLocalStorage('utm_source', '0');
  return useQuery(CPD_REELS, () => axiosInstance.get(`cpd/reellist?utm_source=${utmSource}`), {
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
    select: (response) => response.data?.data ?? [],
    staleTime: staleTime,
    cacheTime: cacheTime
  });
};

export const useGetCpdUserData = (enabled) => {
  return useInfiniteQuery(
    CPD_DATA,
    ({ pageParam = 0 }) => {
      console.log('pageParam', pageParam);
      return axiosInstance.get(`cpd/listnew?&from=${pageParam}&to=10`);
    },
    {
      enabled: !!enabled,
      staleTime: staleTime,
      cacheTime: cacheTime,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.result?.length > 0) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useChangeUseGoal = (onSuccessCb) => {
  const queryClient = useQueryClient();
  return useMutation(changeVideoStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([DOCTUBE_VIDEO_LISTING]);
      onSuccessCb();
    }
  });
};
