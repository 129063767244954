import React from 'react';
import LazyImage from '../../components/CustomLibraries/LazyImage';
import '../share/css/share.scss';
import { isMobile } from 'react-device-detect';
import { ClirnetIconLogo } from '../../components/icons';

function ShareHeaderNew({ loginPage }) {
  return (
    <div
      className={`d-md-flex d-block justify-content-between align-items-center text-center text-md-start  ${
        isMobile ? 'loginLogoCard bg-white mt-0 mb-0 rounded py-20 px-4' : ''
      } `}
    >
      <span className="d-flex flex-md-row flex-column align-items-center gap-4">
        <div
          className={`clr_v3_Header__brand icon-size-50 gap-4 d-flex align-items-center justify-content-center rounded-3 p-2 ${
            isMobile ? 'bg-light' : 'bg-white'
          }`}
        >
          <ClirnetIconLogo />
        </div>
        <strong className="fw-bold fs-36 text-black d-block">Explore CLIRNET</strong>
      </span>

      <h1 className="fw-medium text-black fs-2">World's Fastest Growing Community of Doctors</h1>
    </div>
  );
}

export default React.memo(ShareHeaderNew);

//loginLogoCard for login page
