import React from 'react';
import { useParams } from 'react-router-dom';
import { shareConstants } from '../../Store/constants/shareConstants';
import Loadable from '../../router/Loadable';
import ShareTemplate1 from './templates/template1';
import ShareTemplate2 from './templates/template2';

function generateProps(serviceName) {
  if (serviceName.toLowerCase() == 'medwiki' || serviceName.toLowerCase() == 'feed') {
    return {
      serviceName: 'comp',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_MEDWIKI_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'gr') {
    return {
      serviceName: 'gr',
      constantSuccess: shareConstants.GET_GR_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_GR_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'epub' || serviceName.toLowerCase() == 'epaper') {
    return {
      serviceName: 'epub',
      constantSuccess: shareConstants.GET_EBOOK_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_EBOOK_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'differential_diagnosis') {
    return {
      serviceName: 'differential_diagnosis',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'ddi') {
    return {
      serviceName: 'ddi',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'openddx') {
    return {
      serviceName: 'ddx',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE
    };
  } else if (
    serviceName.toLowerCase() == 'poll' ||
    serviceName.toLowerCase() == 'survey' ||
    serviceName.toLowerCase() == 'doctorVoice'
  ) {
    return {
      serviceName: 'survey',
      serviceType: serviceName.toLowerCase(),
      constantSuccess: shareConstants.GET_SPQ_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SPQ_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'session') {
    return {
      serviceName: 'session',
      constantSuccess: shareConstants.GET_SESSION_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'channel') {
    return {
      serviceName: 'channel',
      constantSuccess: shareConstants.GET_CHANNEL_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_CHANNEL_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'election') {
    // this will not work
    return {
      serviceName: '',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'dnr') {
    // this will not work
    return {
      serviceName: 'login',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'doctube' || serviceName.toLowerCase() == 'doctube_fst') {
    return {
      serviceName: 'doctube',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE,
      serviceType: serviceName.toLowerCase()
    };
  } else if (
    serviceName.toLowerCase() == 'archived_video' ||
    serviceName.toLowerCase() == 'video_archive'
  ) {
    return {
      serviceName: 'archived_video',
      constantSuccess: shareConstants.GET_CLINICAL_VIDEOS_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_CLINICAL_VIDEOS_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'observership') {
    return {
      serviceName: 'observer',
      constantSuccess: shareConstants.GET_OBSERVERSHIP_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_OBSERVERSHIP_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'mentor') {
    return {
      serviceName: 'mentor',
      constantSuccess: shareConstants.GET_MENTOR_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_MENTOR_SHARE_FALIURE
    };
  } else if (serviceName.toLowerCase() == 'training') {
    return {
      serviceName: 'training',
      constantSuccess: shareConstants.GET_TRAINING_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_TRAINING_SHARE_FAILURE
    };
  } else {
    return {
      serviceName: 'login',
      constantSuccess: shareConstants.GET_MEDWIKI_SHARE_SUCCESS,
      constantFaliure: shareConstants.GET_SESSION_SHARE_FALIURE,
      serviceType: serviceName.toLowerCase()
    };
  }
}

function ShareTemplates() {
  const Ddx = Loadable({
    loader: () => import('../../pages/askClirnet/DiagnosisHelp')
  });

  const { templateId, serviceName } = useParams();
  console.log('serviceName', serviceName, generateProps(serviceName));
  if (serviceName.toLowerCase() == 'openddx') {
    return <Ddx />;
  } else {
    switch (templateId) {
      // case '1':
      //   return <ShareTemplate1 {...generateProps(serviceName)} />;
      case '2':
        return <ShareTemplate2 {...generateProps(serviceName)} />;
      default:
        return <ShareTemplate1 {...generateProps(serviceName)} />;
    }
  }
}

export default ShareTemplates;
