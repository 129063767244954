// import Color, { Palette } from 'color-thief-react';
import React, { memo } from 'react';
import { reactHtmlParser, specialityPopOver } from '../../../common/common';
import SponsorLogoSwiper from './SponsorLogoSwiper';
// import ReactHtmlParser from 'react-html-parser';
import { toggleReference } from '../../../Store/actions';
import { useDispatch } from 'react-redux';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { surveys_static_image } from '../../../common/assets.common';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../components/tracking/constants';
import { removeHtmlTags } from '../../channel/utils';
const SurveyHeroCard = ({ data, channel_name, channel_logo, gtmTagVariable = '' }) => {
  console.log('sharedata', data);
  const {
    image,
    specialities,
    survey_title,
    survey_description,
    sponsor_logo,
    question_count,
    survey_points,
    survey_time
  } = data;
  const dispatch = useDispatch();

  function onReadMoreClick() {
    dispatch(toggleReference(true));
  }
  return (
    <TrackerWrapper
      data={data}
      type={CARD_VIEW}
      className="w-100 float-start rounded-3 shadow overflow-hidden bg-white shareLoginV2Featured p-3 p-md-4 position-relative z-1"
    >
      <div className="shareLoginV2FeaturedIn  d-flex flex-column flex-sm-row align-items-center">
        <div
          className="shareLoginV2FeaturedCircle position-absolute rounded-circle"
          style={{ backgroundColor: '#355fd3' }}
        ></div>
        <div className="shareLoginV2FeaturedPic position-relative h-auto pe-4 pb-4">
          {image && (
            <LazyImage
              src={image ?? surveys_static_image}
              className="object-fit-cover w-100 h-100 float-start"
              fallback_image={surveys_static_image}
            />
          )}
        </div>
        <div className="shareLoginV2FeaturedRight text-start ps-0 ps-md-20 mt-4 mt-md-0">
          {specialities && (
            <span className="text-black mb-2">
              <span className="fs-4 cursorPointer">{specialities?.split(',')[0]}</span>
              {specialityPopOver(specialities)}
            </span>
          )}
          <h2 className="fs-16 fw-medium text-black mb-2">{survey_title}</h2>
          {survey_description && (
            <h4 className="line-clamp-2 fs-4 fw-normal text-dark">
              {removeHtmlTags(survey_description)?.substring(0, 180)}
              {removeHtmlTags(survey_description)?.length > 180 && (
                <a className="text-primary cursorPointer" onClick={onReadMoreClick}>
                  {' '}
                  ...Read More
                </a>
              )}
            </h4>
          )}

          <div className="text-center spqLoginV2Numbers mt-4 rounded border border-light py-3 px-0 mw-100">
            <div className="row">
              <div className="col">
                <h3 className="fw-normal fs-5 text-black m-0">
                  <span className="fw-medium fs-3 d-block position-relative pb-2 mb-2">
                    {question_count}
                  </span>
                  Questions
                </h3>
              </div>
              <div className="col">
                <h3 className="fw-normal fs-5 text-black m-0">
                  <span className="fw-medium fs-3 d-block position-relative pb-2 mb-2">
                    {survey_time} <span className="fs-5">Min</span>
                  </span>
                  Duration
                </h3>
              </div>
              <div className="col">
                <h3 className="fw-normal fs-5 text-black m-0">
                  <span className="fw-medium fs-3 d-block position-relative pb-2 mb-2">
                    {survey_points}
                  </span>
                  Points
                </h3>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button
              href="javascript:void(0)"
              className={`"rounded btn btn-secondary fs-4 fw-normal ${gtmTagVariable}_surveyHeroCard_begin d-flex align-items-center gap-2`}
              onClick={() => dispatch(toggleReference(true))}
              aria-label="Begin"
            >
              Begin <i className="fs-4 ms-2 flaticon-next"></i>
            </button>
          </div>
          <div className="d-flex justify-content-between mt-4">
            {channel_logo && (
              <div className="shareLogineV2Doctor">
                <div className="w-100 float-start shareLoginV2FeaturedChannel d-flex align-items-center position-relative">
                  <div className="rounded-circle shareLoginV2ChanlPic d-flex align-items-center justify-content-center bg-light position-absolute top-0 start-0">
                    <LazyImage
                      src={channel_logo}
                      alt="Profile Image"
                      className="mw-100 mh-100 w-auto h-auto"
                      extraClass={
                        'w-100 h-100 position-relative d-flex justify-content-center align-items-center'
                      }
                      logo
                    />{' '}
                  </div>
                  <span className="d-block fs-5 text-dark">{channel_name}</span>
                </div>
              </div>
            )}
            {sponsor_logo && (
              <SponsorLogoSwiper sponsorLogo={sponsor_logo ? sponsor_logo.split(',') : []} />
            )}
          </div>
        </div>
      </div>
    </TrackerWrapper>
  );
};

export default memo(SurveyHeroCard);
