import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import JSONFormBuilder from '../JSONFormBuilder';
import Loader from '../../pages/ebook/components/Loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getLocalStorage,
  isExpired,
  setLocalStorage,
  setSessionStorage
} from '../../common/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  autoAuthSignUp,
  checkDuplicateEmail,
  checkDuplicateMobile,
  getUserCertificate,
  validateAutoAuthToken
} from '../../Store/actions';
import {
  authenticateUser,
  loginSuccess,
  loginWithEmail,
  otpVerification,
  verifyTheOtp
} from '../../Store/actions/login.action';
import { getVaultList } from '../../Store/actions/vault.action';
import axios from 'axios';
import { routeNames } from '../../router/constants';
import { toast } from 'react-toastify';
import { useTracker } from '../../TrackerFolder/TrackerComponent';

import { pageTitles } from '../../common/pageTitles';
import useRedirect from '../customHooks/useRedirect';
import { generateDataLayerGtm } from '../../helper/generateDataLayerGtm';
import { EVENT } from '../tracking/constants';

let claimedCertificateId = 0;
let phonenoFormat = /^\d{10}$/;
let reEmail =
  /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;

function AutoAuthModal() {
  const [enableLoginFlow, setEnableLoginFlow] = useState(0);
  const { navigateToSharePage, navigateToAssociatedPath } = useRedirect();
  const UserRegion = useSelector((state) => state.share.UserRegion);
  const location = useLocation();
  const { token, utm_source, user_type, user_id, content_type, content_id } = location?.state || {};
  const [formFields, setFormFields] = useState([]);
  const [loginFormFields, setLoginFormFields] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [err_msg, set_err_msg] = useState(null);
  const [userDetails, setuserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone_no: '',
    isd_no: '+91'
  });

  const user_origin = useRef(user_type);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackActivityEvent } = useTracker();
  let pageName = pageTitles.AutoAuth.AutoAuth;

  const SignUpUser = (userDetails, e) => {
    if (e) {
      e?.preventDefault();
    }
    console.log('userDetails -1', userDetails);
    setDisabled(true);
    if (!userDetails?.firstName) {
      toast.error('Please Enter Your First Name');
      setDisabled(false);
    } else if (!userDetails?.lastName) {
      toast.error('Please Enter Your Last Name');
      setDisabled(false);
    } else if (UserRegion !== 'GT' && !userDetails?.phone_no) {
      toast.error('Please Enter Your Phone Number');
      setDisabled(false);
    } else if (UserRegion !== 'GT' && !userDetails?.phone_no?.trim()?.match(phonenoFormat)) {
      toast.error('Please Enter a Valid Phone Number');
      setDisabled(false);
    } else if (!userDetails?.term_condition) {
      toast.error('Please Accept the terms and conditions');
      setDisabled(false);
    } else {
      if (userDetails?.email) {
        if (!userDetails?.email?.trim()?.match(reEmail)) {
          toast.error('Please Enter a valid Email Id');
          setDisabled(false);
        } else {
          checkEmailMobile(userDetails);
        }
      } else {
        checkEmailMobile(userDetails);
      }
    }
    trackActivityEvent(EVENT, {});
  };

  const checkEmailMobile = (userDetails) => {
    const phone_no = userDetails.phone_no.trim();
    const email = userDetails.email.trim();

    setDisabled(true);
    dispatch(
      checkDuplicateMobile(token, phone_no, (res) => {
        if (res != 'error') {
          if (res.status_code == 200) {
            if (email) {
              dispatch(
                checkDuplicateEmail(token, email, (resp) => {
                  if (resp != 'error') {
                    if (resp.status_code == 200) {
                      startSignUp(userDetails);
                    } else {
                      //login with email flow
                      setLoginFormFields([
                        {
                          name: 'email',
                          placeholder: 'Enter Email',
                          type: 'email',
                          label: 'Enter Email',
                          defaultValue: email,
                          required: true,
                          hidden: false
                        },
                        {
                          name: 'password',
                          placeholder: 'Enter Password',
                          type: 'password',
                          label: 'Enter Password',
                          required: true,
                          hidden: false
                        }
                      ]);
                      setDisabled(false);
                      setEnableLoginFlow(2);
                      set_err_msg('You are already registered with us please login to continue.');
                    }
                  }
                })
              );
            } else {
              startSignUp(userDetails);
            }
          } else {
            //login with mobile flow
            const payloadFormData = new FormData();

            payloadFormData.append('isdCode', userDetails?.isd_code);
            payloadFormData.append('phone_no', userDetails?.phone_no.trim());
            payloadFormData.append('utm_source', getLocalStorage('utm_source', ''));

            sendOTP(payloadFormData);
            setLoginFormFields([
              {
                name: 'phone_no',
                placeholder: 'Enter OTP',
                type: 'text',
                label: 'Enter OTP',
                defaultValue: userDetails?.phone_no.trim(),
                required: true,
                hidden: true
              },
              {
                name: 'isdCode',
                placeholder: 'isdCode',
                type: 'text',
                label: 'isdCode',
                defaultValue: userDetails?.isd_code,
                required: true,
                hidden: true
              },
              {
                name: 'otp',
                placeholder: 'Enter OTP',
                type: 'text',
                label: 'Enter OTP',
                defaultValue: '',
                required: true,
                hidden: false
              }
            ]);

            setEnableLoginFlow(1);
          }
        } else {
          startSignUp(userDetails);
        }
      })
    );
  };

  const startSignUp = (userDetails) => {
    console.log(userDetails, 'userDetails');
    let formData = new FormData();
    formData.append('first_name', userDetails?.firstName + ' ' + userDetails?.lastName);
    formData.append('isdCode', userDetails?.isd_code);
    formData.append('phone_no', userDetails?.phone_no?.trim());
    formData.append('email', userDetails?.email?.trim());
    formData.append('utm_source', getLocalStorage('utm_source', ''));
    formData.append('user_type', user_type);
    formData.append('user_id', user_id);
    formData.append('device_os', 'web');
    formData.append('certificate_claim_status', content_type == 'claim_certificate' ? 1 : 0);

    dispatch(
      autoAuthSignUp(token, formData, (response) => {
        if (response != 'error') {
          const new_token = response.data.token;

          if (response.status_code == 200) {
            ValidateUser(new_token);

            const { data } = response;

            if (
              data &&
              typeof data == 'object' &&
              data?.hasOwnProperty('certificate_id') &&
              content_type == 'claim_certificate'
            ) {
              const { certificate_id } = data;
              console.log('certificate_id', certificate_id);
              claimedCertificateId = '';
              claimedCertificateId = certificate_id;
            }
          } else if (response.status_code == 203) {
            toast.warning('You are already Registered. Please Login To Continue');
            if (content_type && content_id) {
              setSessionStorage('autoauth_location', `${content_type},${content_id}`);
            }

            navigate(`/Login/mobile/${userDetails.phone_no}`);
          } else {
            navigateToSharePage(content_type, content_id, utm_source);
          }
        } else {
          navigateToSharePage(content_type, content_id, utm_source);
        }
      })
    );
  };

  const getUserDetail = (token) => {
    if (content_type == 'claim_certificate' && user_origin.current == 'user_master') {
      dispatch(
        authenticateUser(token, (resp) => {
          if (resp != 'error') {
            if (resp.status_code == 200) {
              setLocalStorage('user', JSON.stringify(resp.data));

              dispatch(
                getVaultList((res) => {
                  if (res != 'error') {
                    setLocalStorage('vaultCount', res?.data?.length ?? 0);
                    dispatch(loginSuccess());
                    // navigateToAssociatedPath(content_type, content_id);
                  }
                })
              );

              //get Certificate
              let certificate_payload = new FormData();
              const claim_type = content_id.split('-')[0];
              const claim_type_id = content_id.split('-')[1];

              certificate_payload.append('certificate_type', claim_type);
              certificate_payload.append('type_id', claim_type_id);
              certificate_payload.append('first_name', resp?.data?.firstName);
              certificate_payload.append('last_name', resp?.data?.lastName);

              dispatch(
                getUserCertificate(token, certificate_payload, (resp) => {
                  if (resp != 'error') {
                    if (resp.status_code == 200) {
                      let { certificate_id } = resp.data;
                      claimedCertificateId = '';
                      claimedCertificateId = certificate_id;
                    }
                  }
                })
              );
            }
          } else {
            //fallback for cetificate page
          }
        })
      );
    } else {
      dispatch(
        authenticateUser(token, (resp) => {
          if (resp != 'error') {
            if (resp.status_code == 200) {
              setLocalStorage('user', JSON.stringify(resp.data));

              dispatch(
                getVaultList((res) => {
                  if (res != 'error') {
                    setLocalStorage('vaultCount', res?.data?.length ?? 0);
                    dispatch(loginSuccess());
                    // navigateToAssociatedPath(content_type, content_id);
                  }
                })
              );
            }
          }
        })
      );
    }
  };

  function ValidateUser(refresh_token) {
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/getAccessToken`, { token: refresh_token })
      .then((res) => {
        setLocalStorage('accessToken', res.data.data.token);
        setLocalStorage('refreshToken', refresh_token);
        getUserDetail(res.data.data.token);
        setDisabled(false);
        setFormFields([]);
        document.getElementsByTagName('html')[0].style = 'overflow:auto';
      })
      .catch((err) => {
        try {
          const response = axios.post(`${process.env.REACT_APP_API_URL}auth/getRefreshToken`, {
            token: refresh_token
          });

          setLocalStorage('accessToken', response.data.data.token);
          setLocalStorage('refreshToken', response.data.data.refresh_token);
          setDisabled(false);
          setFormFields([]);
          document.getElementsByTagName('html')[0].style = 'overflow:auto';
        } catch (error) {
          navigateToSharePage(content_type, content_id, utm_source);
        }
      });
  }

  function tokenValidation() {
    let accessToken = getLocalStorage('accessToken', '')
      ? getLocalStorage('accessToken', '')
      : null;
    let refreshToken = getLocalStorage('refreshToken', '')
      ? getLocalStorage('refreshToken', '')
      : null;
    if (accessToken && refreshToken && !isExpired(accessToken)) {
      getUserDetail(accessToken);
    } else if (accessToken && refreshToken && isExpired(accessToken)) {
      ValidateUser(refreshToken);
    } else {
      if (token == '' || token == undefined) {
        navigateToSharePage(content_type, content_id, utm_source);
      } else {
        dispatch(
          validateAutoAuthToken(token, (response) => {
            if (response == 'error') {
              navigateToSharePage(content_type, content_id, utm_source);
            } else {
              user_origin.current = response.data.origin;

              if (response.data.status == 'login') {
                ValidateUser(response.data.token);
              } else {
                if (response?.data?.token_status != 'unauthorized') {
                  var formField = [
                    {
                      name: 'firstName',
                      placeholder: 'First username',
                      type: 'text',
                      className: '',
                      defaultValue: response?.data?.first_name,
                      label: 'First Name',
                      required: true,
                      half: true
                    },
                    {
                      name: 'lastName',
                      placeholder: 'Last username',
                      type: 'text',
                      className: '',
                      defaultValue: response?.data?.last_name,
                      label: 'Last Name',
                      required: true,
                      half: true
                    }
                  ];

                  //check if email exists
                  if (response?.data?.email != '') {
                    if (!response?.data?.email?.match(reEmail)) {
                      formField.push({
                        name: 'email',
                        placeholder: 'Enter Your Email Id',
                        type: 'email',
                        className: '',
                        defaultValue: response?.data?.email,
                        label: 'Email Id',
                        required: true
                      });
                    } else {
                      formField.push({
                        name: 'email',
                        placeholder: 'Enter Your Email Id',
                        type: 'email',
                        className: '',
                        defaultValue: response?.data?.email,
                        label: 'Email Id',
                        required: true,
                        hidden: true
                      });
                    }
                  } else {
                    formField.push({
                      name: 'email',
                      placeholder: 'Enter Your Email Id',
                      type: 'email',
                      className: '',
                      defaultValue: response?.data?.email,
                      label: 'Email Id',
                      required: true
                    });
                  }

                  //check if mobile no exists
                  if (response?.data?.mobile != '') {
                    if (!response?.data?.mobile?.match(phonenoFormat)) {
                      formField.push({
                        name: 'phone_no',
                        placeholder: 'Enter Your Mobile Number',
                        type: 'tel',
                        className: '',
                        defaultValue: response?.data?.mobile,
                        label: 'Mobile Number',
                        required: true
                      });
                    } else {
                      formField.push({
                        name: 'phone_no',
                        placeholder: 'Enter Your Mobile Number',
                        type: 'tel',
                        className: '',
                        defaultValue: response?.data?.mobile,
                        label: 'Mobile Number',
                        required: true,
                        hidden: true
                      });
                    }
                  } else {
                    formField.push({
                      name: 'phone_no',
                      placeholder: 'Enter Your Mobile Number',
                      type: 'tel',
                      className: '',
                      defaultValue: response?.data?.mobile,
                      label: 'Mobile Number',
                      required: true
                    });
                  }

                  formField.push({
                    name: 'term_condition',
                    label: `I Accept`,
                    type: 'term&condition',
                    className: '',
                    defaultValue: true
                  });
                  setFormFields(formField);
                } else {
                  navigateToSharePage(content_type, content_id, utm_source);
                }
              }
            }
          })
        );
      }
    }
  }

  useEffect(() => {
    tokenValidation();
    generateDataLayerGtm(pageName);
    document.getElementsByTagName('html')[0].style = 'overflow:hidden';

    return () => {
      document.getElementsByTagName('html')[0].style = 'overflow:auto';
      setDisabled(false);
    };
  }, []);

  function sendOTP(payload) {
    setDisabled(true);
    dispatch(
      otpVerification(payload, (res) => {
        setDisabled(false);
      })
    );
  }

  function verifyOTP(payload, e) {
    console.log('called');
    if (e) {
      e?.preventDefault();
    }
    set_err_msg('');
    let formData = new FormData();
    formData.append('isdCode', payload?.isdCode);
    formData.append('phone_no', payload?.phone_no?.trim());
    formData.append('otp', payload?.otp?.trim());

    dispatch(
      verifyTheOtp(formData, (res) => {
        if (res != 'error') {
          if (res.status_code == 203) {
            set_err_msg(res.message);
            // setShowButton(true);
          } else if (res.status_code == 200) {
            if (res.data.status == 3) {
              ValidateUser(res.data.token);
            } else {
              set_err_msg(
                'This Mobile is not Registered,Please Use Your Email Id or Register with Us'
              );
            }
          } else {
            set_err_msg(res.message ? res.message : 'Something Went Wrong');
          }
        } else {
          set_err_msg(res.message ? res.message : 'Something Went Wrong');
        }
      })
    );
  }

  function verifyEmailPassword(payload, e) {
    if (e) {
      e?.preventDefault();
    }
    const { email = null, password = null } = payload || {};
    if (email == undefined || email == null || email == '') {
      set_err_msg('Please Enter Your Email');
    } else {
      let regexEmail =
        /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
      if (!email?.trim().match(regexEmail)) {
        set_err_msg('Please Enter a Valid Email');
      } else {
        if (password == undefined || password == null || password == '') {
          set_err_msg('Please Enter Your Password');
        } else {
          let formData = new FormData();
          formData.append('email', email?.trim());
          formData.append('password', password);
          formData.append('utm_source', getLocalStorage('utm_source', ''));
          dispatch(
            loginWithEmail(formData, (res) => {
              if (res != 'error') {
                if (res.status_code == 203) {
                  set_err_msg(res.message);
                } else if (res.status_code == 200) {
                  if (res.data.status == 3) {
                    ValidateUser(res.data.token);
                  } else {
                    set_err_msg('This Email is not Registered or Not Verified');
                  }
                } else {
                }
              } else {
                set_err_msg(res.message ? res.message : 'Something Went Wrong');
              }
            })
          );
        }
      }
    }
  }

  useEffect(() => {
    if (formFields.length <= 0) {
      document.getElementsByTagName('html')[0].style = 'overflow:auto';
    } else {
      document.getElementsByTagName('html')[0].style = 'overflow:hidden';
    }
  }, [formFields]);

  return (
    <Modal
      className={`centerModal ${formFields.length > 0 ? 'show' : ''}`}
      size="md"
      centered
      backdrop="static"
      show={formFields.length > 0}
      id="MainPage"
    >
      <Modal.Header className="position-relative p-3 p-md-4 border-0 mb-1">
        <Modal.Title className="fs-4 text-black fw-semibold">
          Please Verify Your details.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 p-md-4">
        {enableLoginFlow == 1 ? (
          <JSONFormBuilder
            fields={loginFormFields}
            btn={{
              className: 'col-12 fs-4 fw-normal btn btn-primary py-3 px-4 rounded',
              text: 'Login Now',
              onClick: verifyOTP
            }}
            disabled={disabled}
          />
        ) : enableLoginFlow == 2 ? (
          <JSONFormBuilder
            fields={loginFormFields}
            btn={{
              className: 'col-12 fs-4 fw-normal btn btn-primary py-3 px-4 rounded',
              text: 'Login Now',
              onClick: verifyEmailPassword
            }}
            disabled={disabled}
          />
        ) : (
          <JSONFormBuilder
            fields={formFields}
            btn={{
              className: 'col-12 fs-4 fw-normal btn btn-primary py-3 px-4 rounded',
              text: content_type == 'claim_certificate' ? 'Sign Up and Get Certificate' : 'Sign Up',
              onClick: SignUpUser
            }}
            disabled={disabled}
          />
        )}
        {err_msg ? (
          <div className="col-12 shareLoginFormRow text-danger text-center mt-4 position-relative p-0">
            {err_msg}
          </div>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AutoAuthModal;
