// import Color, { Palette } from 'color-thief-react';
import React, { memo } from 'react';
import DoctorVoice from '../../dashboard/components/DoctorVoice';
import SponsorLogoSwiper from './SponsorLogoSwiper';
const PollHeroCard = ({ data, channel_name, channel_logo, gtmTagVariable = '' }) => {
  console.log(data);

  return (
    <div className="w-100 float-start rounded-3">
      <div className="shareLoginV2FeaturedIn text-start d-flex flex-column flex-sm-row align-items-center shadow">
        <DoctorVoice data={data} isShare={data?.is_share} headText={'Polls'} />
      </div>
    </div>
  );
};

export default memo(PollHeroCard);
