import React, { memo } from 'react';
import '../../components/cards/medwiki/medwikiCardV1/css/style.scss';
import { CgArrowLongRight } from '../../components/icons';
import { Skeleton } from '../Skeleton';
const MedwikiLoader = () => {
  return (
    <>
      <div className="w-100 float-start medWikiCardV1Box shadow rounded-3 overflow-hidden bg-white pt-3 position-relative">
        <span className="graph-1 position-absolute start-0 bottom-0"></span>
        <span className="graph-2 position-absolute start-0 bottom-0"></span>

        <div className="crdV1topPart d-flex align-items-center justify-content-between position-relative py-1 px-3 ps-lg-4 mb-2">
          <span className="crdV1topPartGraph position-absolute h-100 start-0 top-50 translate-middle-y"></span>
          <div className="text-black crdV1Speclty d-flex gap-2">
            <span className={`fs-4 cursorPointer gtm_cl_medwikiCardV1_speciaities`}>
              <Skeleton variant="text" width={100} height={20} />
            </span>
          </div>
          <a href="javascript:void(0)" className="cardV1DotsMenu text-black">
            <Skeleton variant="circular" width={20} height={20} />
          </a>
        </div>
        <Skeleton variant="rectangular" width="100%" height={100} />
        <div className="w-100 float-start medWikiCrdV1Btm p-3 pb-4 ps-0">
          <div className="w-100 float-start d-flex text-start cardV1Topic ps-3 ps-lg-4 mb-2">
            <div className="cardV1TopicContent cursorPointer position-relative w-100">
              <h2
                className={`text-black fs-16 fw-medium line-clamp-3 gtm_cl_medwikiCardV1 lh-base`}
              >
                <Skeleton variant="text" width="90%" height={20} />
              </h2>
            </div>
          </div>
          <div className="w-100 float-start cardV1Ftr d-flex align-items-center justify-content-between flex-row-reverse ps-3 ps-lg-4">
            <div
              className={`cardV1FtrBtn d-inline-block overflow-hidden fs-4 fw-medium rippleEffect rounded cursorPointer gtm_cl_medwikiCardV1`}
            >
              <Skeleton variant="rounded" width={100} height={30} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(MedwikiLoader);
