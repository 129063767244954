import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import './css/doctorVoice.scss';
import { toast } from 'react-toastify';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ShareDetailsPage from '../../../components/ShareDetailPage';

import { isUserLoggedIn, log } from '../../../common/common';

import { Fade } from 'react-awesome-reveal';
import {
  RiBarChart2Line,
  MdKeyboardArrowRight,
  IoInformationCircleOutline
} from '../../../components/icons';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import ModalDoctorVoice from './ModalDoctorVoice';
// import ShowMoreText from '../../../components/showMoreText/ShowMoreText';
import ShowMoreText from 'react-show-more-text';
import useRedirect from '../../../components/customHooks/useRedirect';
import { CARD_CLICK, CARD_VIEW } from '../../../components/tracking/constants';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { onCardClick } from '../../../components/tracking/UserActions';
import { safeJsonParser } from '../../../utils/common';
import { toggleReference } from '../../../Store/actions';
import { useDispatch } from 'react-redux';

let is_api_call_permission = true;
const DoctorVoice = ({
  data,
  isShare,
  headText,
  isPoll,
  shareClick,
  isCompleted = false,
  gtmTagVariable = ''
}) => {
  const cardRef = useRef();
  const [currOptions, setCurrOptions] = useState({});
  const [ansGiven, setAnsGiven] = useState(false);
  const [toOpenModal, setToOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const triggerModalClose = (currOptions) => {
    setAnsGiven(true);
    setCurrOptions(currOptions);
  };
  const handleShow = useCallback(() => setShow(true), [show]);
  const handleClose = useCallback(() => {
    setShow(false);
  }, [show, currOptions, ansGiven]);

  const { trackActivityEvent } = useTracker();
  const { redirectTo } = useRedirect();
  console.log(data, 'data in doctor voice');

  if (data && (data?.data_json || data?.json_data)) {
    if (isPoll == true) {
      var parsedData = safeJsonParser(unescape(data.data_json), {});
    } else {
      var parsedData = safeJsonParser(unescape(data?.json_data), {});
    }
    //for dashboard please change to json_data

    if (typeof parsedData == 'object') {
      var qArray = Object.keys(parsedData.surveys).map((k) => {
        return parsedData.surveys[k];
      });
      var { question, options } = qArray[0];
    } else {
      // redirectTo('Unavailable');
    }
  }

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
  };

  useEffect(() => {
    if (isCompleted) {
      if (data && data.survey_id) {
        onPollSubmit('already submited', data.survey_id);
      }
    }
  }, [isCompleted]);
  useEffect(() => {
    setCurrOptions(options);

    if (options?.length > 2) {
      for (const _opt of options) {
        const prntWidth = document.querySelector(`#dbVoiceLabel_${_opt.value}`)?.clientWidth ?? 0;
        var textWidth = getTextWidth(_opt.label, null);

        if (prntWidth <= textWidth) {
          setToOpenModal(true);
          break;
        }
      }
    }
    return () => {
      is_api_call_permission = true;
    };
  }, []);

  const onPollSubmit = (selectedIndex, id) => {
    typeof trackActivityEvent == 'function' && trackActivityEvent(CARD_CLICK, data);
    qArray[0].selectedIndex = selectedIndex;

    if (is_api_call_permission) {
      is_api_call_permission = false;
      axiosInstance
        .post('survey/pollAnswer', {
          answer_json: JSON.stringify(qArray),
          survey_id: data.survey_id
        })
        .then((res) => {
          axiosInstance
            .get(`survey/pollResult?id=${data.survey_id}`)
            .then((response) => {
              log('Poll result', safeJsonParser(response.data.data, [])[0].options);
              setCurrOptions(safeJsonParser(response.data.data, [])[0]);
              setAnsGiven(true);
              console.log(data, 'data');

              // if ('user_survey_status' in data && data.user_survey_status !== 'completed') {
              axiosInstance
                .post('survey/addpoint', {
                  point: isPoll ? data.survey_points : data.point,
                  survey_id: data.survey_id
                })
                .then((resp) => {
                  is_api_call_permission = true;
                  const pollPoints = isPoll ? data.survey_points : data.point;
                  if (pollPoints > 0) {
                    toast.success(`You have got ${pollPoints} points`);
                  } else {
                    toast.success('Thank you for your response');
                  }
                })
                .catch((e) => {
                  log('Error', e);
                });
              // }
            })
            .catch((e) => {
              log('Error', e);
            });
        })
        .catch((e) => {
          log('Error', e);
        });
    }
  };

  return (
    <>
      <TrackerWrapper
        data={data}
        type={CARD_VIEW}
        className="w-100 rounded-3 dbDoctorVoiceIn overflow-hidden bg-white shadow position-relative h-100 p-3 p-md-4"
        id="dashboard_doctor_voice"
      >
        {/* <img src={docVoiceImage} alt="graphics" className="docVoiceImage opacity-75" /> */}
        <div className="w-100 d-flex justify-content-between align-items-center dbV2FeaturedBoxTtl pb-3 pb-md-4 border-bottom border-light">
          <div className="d-flex align-items-center gap-4">
            <span className="dbV2FeaturedBoxTtlIcon  d-inline-flex align-items-center">
              <RiBarChart2Line customClass="fs-2 text-secondary" />
            </span>
            <h1 className="fs-3 text-black fw-semibold position-relative ps-0">
              {headText ? `${headText}` : " Doctor's Voice"}
              {/* <span className="dbV2FeaturedBoxTtlInfoIcon ms-3">
              <IoInformationCircleOutline customClass="fs-1 text-black" />
            </span> */}
            </h1>
          </div>

          {isShare ? null : (
            <a href="javascript:void(0)" className="text-black d-flex align-items-center">
              <div className="d-flex align-items-center">
                <ShareDetailsPage
                  fullData={data}
                  shareClick={shareClick}
                  data={{ title: question, url: data?.deeplink, is_share: data?.is_share }}
                  gtmTagVariable="gtm_cl_dashboard_doctor_voice_share_button"
                />
              </div>
            </a>
          )}
        </div>
        <div
          className="w-100 dbDoctorVoiceContent dbBoxSlide position-relative z-1 pt-3 pt-md-4"
          ref={cardRef}
        >
          <div className="w-100" id="questionBox">
            {isShare ? (
              <div className="fs-16 text-black fw-medium">{question}</div>
            ) : (
              <ShowMoreText
                className="fs-16 text-black fw-medium"
                anchorClass={'show-more-less-clickable'}
                lines={4}
                expandByClick={false}
                onClick={handleShow}
                more={toOpenModal ? '...' : 'Show More'}
                less={toOpenModal ? '...' : 'Show Less'}
                width={cardRef?.current?.clientWidth ? cardRef?.current?.clientWidth : 0}
              >
                {question}
              </ShowMoreText>
            )}
          </div>

          <div className="clearfix"></div>
          <div className="w-100 dbDoctorVoiceAns mt-20 p-0">
            {!ansGiven ? (
              <div className="w-100 dbDoctorVoiceOptions">
                <Form>
                  {options?.slice(0, 4)?.map((_opt, _idx) => (
                    <Form.Check
                      className="p-0 mb-4 position-relative"
                      key={_opt.value}
                      name="dbDoctorVoiceAnser"
                      type="radio"
                      label={
                        <span
                          id={`dbVoiceLabel_${_opt.value}`}
                          className={`${options.length > 3 ? 'line-clamp-1' : ''}`}
                        >
                          {_opt.label}
                        </span>
                      }
                      id={`dbDoctorVoiceAns${_opt.value}`}
                      disabled={isShare || !isUserLoggedIn()}
                      onClick={(e) => {
                        if (isUserLoggedIn()) {
                          if (toOpenModal) {
                            e.preventDefault();
                            handleShow();
                          } else {
                            onPollSubmit(_opt.value, data.survey_id);
                          }
                        } else {
                          dispatch(toggleReference(true));
                        }
                      }}
                    />
                  ))}
                </Form>
              </div>
            ) : null}
            {ansGiven ? (
              <Fade bottom>
                <div className="w-100 doctoVoiceProgress">
                  {currOptions.options.map((_option, index) => (
                    <div className="doctoVoiceProgressRow position-relative mb-3" key={index + 1}>
                      <ProgressBar now={_option.vote} />
                      <div
                        className={`doctoVoiceProgressRowIn text-start py-3 px-4 position-relative z-2 overflow-hidden d-flex flex-row align-items-center ${
                          currOptions.selectedIndex == _option.value ? 'text-primary' : 'text-black'
                        }`}
                      >
                        <p className={`mb-0 ${options.length > 3 ? 'line-clamp-1' : ''}`}>
                          {_option.label}
                        </p>
                        <p className="p-0">{_option.vote}%</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Fade>
            ) : null}
          </div>
          {options?.length > 4 && !isShare && (
            <div className="w-100 text-center showMoreText position-relative">
              <span className="text-primary fw-medium cursorPointer fs-4" onClick={handleShow}>
                Show More
                <MdKeyboardArrowRight customClass="fs-16 align-middle d-inline-block ms-1" />
              </span>
            </div>
          )}
        </div>
      </TrackerWrapper>
      <ModalDoctorVoice
        headText={headText ? `${headText}` : " Doctor's Voice"}
        show={show}
        data={{ ...data, json_data: data?.json_data || data?.data_json }}
        handleClose={handleClose}
        triggerModalClose={(currOptions) => triggerModalClose(currOptions)}
        headingText="Doctor's Voice"
      />
    </>
  );
};

export default memo(DoctorVoice);
