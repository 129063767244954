import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getTimeZone } from '../../../../common/common';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

function convertAvailabilityForMentee(availability, mentorTimezone) {
  const menteeTimezone = getTimeZone();
  let toProceed =
    availability?.length > 0 &&
    mentorTimezone &&
    menteeTimezone &&
    mentorTimezone !== menteeTimezone;
  try {
    if (toProceed) {
      return availability.map((daySlot) => {
        const convertedSlots = daySlot.slots.map((slot) => {
          console.group('convertedSlots');
          console.log('slot', slot, 'from', slot.from, 'to', slot.to);
          console.groupEnd('convertedSlots');
          let fromInMenteeTimezone = convertTimezone(slot.from, mentorTimezone, menteeTimezone);
          let toInMenteeTimezone = convertTimezone(slot.to, mentorTimezone, menteeTimezone);

          // Check if the 'to' time is earlier than the 'from' time (spans to next day)
          if (dayjs(toInMenteeTimezone, 'HH:mm').isBefore(dayjs(fromInMenteeTimezone, 'HH:mm'))) {
            toInMenteeTimezone = dayjs
              .tz(`${dayjs().format('YYYY-MM-DD')} ${slot.to}:00`, mentorTimezone)
              .tz(menteeTimezone)
              .add(1, 'day') // Add a day to the 'to' time
              .format('HH:mm');
          }

          return { from: fromInMenteeTimezone, to: toInMenteeTimezone };
        });

        return { day: daySlot.day, slots: convertedSlots };
      });
    } else {
      return availability;
    }
  } catch (error) {
    return availability;
  }
}

function convertTimezone(time, fromTimezone, toTimezone) {
  return dayjs
    .tz(`${dayjs().format('YYYY-MM-DD')} ${time}:00`, fromTimezone)
    .tz(toTimezone)
    .format('HH:mm');
}
export default convertAvailabilityForMentee;
