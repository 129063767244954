import React from 'react';
import { PaginationLoader } from '../../../components/loader';

const Loader = ({isLoading}) => {
  if(!isLoading) return null;
  return (
    <PaginationLoader/>
  )
}

export default Loader
