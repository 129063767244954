import React, { memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { makeFirstLetterCapital } from '../../../common/common';
import Divider from '../../../components/baseUiV3/components/Divider';
import ResultArea from '../../ddi/components/ResultArea';
import WarningBox from '../../ddi/components/WarningBox';
const DdiResultShare = ({ id = 0, data }) => {
  const [result, setResult] = React.useState({});
  function fixJsonString(jsonString) {
    if (!jsonString) {
      return null;
    }
    // Replace single quotes with double quotes

    try {
      const correctedJsonString = jsonString?.replace(/'/g, '"');
      // Attempt to parse the corrected JSON string
      const jsonObject = JSON.parse(correctedJsonString);
      return jsonObject;
    } catch (error) {
      console.error('Error parsing JSON:', error?.message);
      return null;
    }
  }
  const [meds, setMeds] = React.useState([]);
  useEffect(() => {
    if (data?.request) {
      const { timestamp, parent_id, ddi_id, is_share: is_shared, owned } = data;
      let medicinesArr = Object.values(fixJsonString(data?.request))?.filter((i) => !!i);
      let ddiResult = fixJsonString(data?.response);

      let ratings = JSON.parse(data?.rating_parts);
      setMeds(medicinesArr);

      setResult({
        request: fixJsonString(data?.request),
        response: {
          DDi_list: ddiResult?.DDi_list
        },
        timestamp,
        parent_id,
        ddi_id,
        is_shared,
        owned
      });
    }
  }, [data]);
  const highlights = [
    {
      highlights_id: 1,
      highlights_title: 'Quick Search',
      highlights_description:
        'DDI, powered by CLIRNET, swiftly fetches relevant information from its extensive drug interaction database.'
    },
    {
      highlights_id: 2,
      highlights_title: 'Community Insights',
      highlights_description:
        'Tap into the wisdom of the community through user reviews, sharing and gaining valuable experiences on specific drug interactions.'
    },
    {
      highlights_id: 3,
      highlights_title: 'Insight Overview',
      highlights_description:
        'Dive into detailed information on interaction effects, severity levels, symptoms, and recommended actions in an easily digestible format.'
    },
    {
      highlights_id: 4,
      highlights_title: 'Reliable Information',
      highlights_description:
        'Trust in the accuracy of information with references from credible sources and regular updates aligned with the latest healthcare guidelines.'
    }
  ];
  return (
    <>
      {id && id != '0' && (
        <div className="rounded-3 bg-white z-1 border border-1 border-soft-primary p-20 px-md-70 py-md-50 clr_v3_cornerGraphicsDynamic position-relative">
          <div className="position-absolute top-0 start-0 w-100 h-100">
            <div className="clr_v3_cornerGraphicsDynamic__Graphics z-0 w-100 h-100 position-relative overflow-hidden rounded-3">
              <div
                className="position-absolute rounded-circle graphics-size-xs top left bg-primary"
                style={{ opacity: '.2' }}
              ></div>
              <div
                className="position-absolute rounded-circle graphics-size-md bg-primary top left"
                style={{ opacity: '.12' }}
              ></div>

              <div
                className="position-absolute rounded-circle graphics-size-xs bottom right bg-primary"
                style={{ opacity: '.2' }}
              ></div>
              <div
                className="position-absolute rounded-circle graphics-size-md bg-primary bottom right"
                style={{ opacity: '.12' }}
              ></div>
            </div>
          </div>

          <div className="d-flex flex-column gap-sm-20 gap-4">
            <HeroSection meds={meds} />
            <WarningBox />

            <ResultArea
              result={result}
              isLoading={false}
              className="overflow-hidden"
              styles={{ maxHeight: '30rem' }}
            />
            <span className="text-danger text-italic fs-4 fst-italic text-nowrap text-end">
              ** Sign in to see more
            </span>
          </div>
        </div>
      )}

      <div
        className="rounded-3 bg-white z-1 border border-1 border-soft-primary mt-4"
        id="targetElement"
      >
        <div className={`subscrbLanding__benefits d-flex p-md-20 border-bottom flex-column gap-2`}>
          <div className={`text-center`}>
            <h2 className={`text-primary mb-20 fs-1 fw-semibold mt-5 mt-md-0`}>
              <span className={`text-black d-block fs-4 mb-3 ps-3 fw-medium`}>Features</span>
              Explore our features
              <Divider position="center" className="mt-4" />
            </h2>
          </div>
          <div className="px-md-2 pb-md-5 py-20 px-5">
            <div className="row mx-md-n20 mx-n3 row-gap-4">
              {highlights.map((highlight) => (
                <div
                  className="col-12 col-md-6 px-3 px-md-20 subscrbLanding__benefits_items"
                  key={highlight.highlights_id}
                >
                  <div className="d-flex align-items-start gap-3">
                    <div className="d-flex justify-content-start align-items-end mb-md-4 mb-3">
                      <i className="flaticon-verify text-primary fs-36"></i>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <h4 className="text-black fs-3 fw-medium">{highlight.highlights_title}</h4>
                      <p>{highlight.highlights_description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(DdiResultShare);
function HeroSection({ meds = [] }) {
  return (
    <div className="rounded-3 bg-white position-relative z-1 border border-1 border-soft-primary p-md-20 p-4 clr_v3_cornerGraphicsDynamic ">
      <div className="d-flex flex-column gap-4">
        <h1 className="fs-2 fw-semibold text-black mb-3 text-center">
          Drug to Drug Interaction (DDI)
        </h1>

        <div className="clr_v3_detailsContent mb-3">
          <p className="text-center">
            Analyzes drugs, flags potential interactions, asseses the severity
          </p>
        </div>

        <div className="d-flex align-items-center justify-content-center flex-wrap gap-2">
          {meds.map((med, index) => {
            return (
              <>
                <span className="text-black text-nowrap rounded-pill border border-primary py-2 px-4 text-primary">
                  {makeFirstLetterCapital(med)}
                </span>
                {index < meds.length - 1 && (
                  <i className="flaticon-transfer-data fs-4 mx-2 text-primary"></i>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
