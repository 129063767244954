import React from 'react';
import noResultImg from './noResult/images/noResult.png';
import './noResult/css/noResult.scss';
const NoDataFound = ({
  header = 'No Data Found',
  subHeader = 'Data is not available',
  icon = '',
  sectionRemove = false
}) => {
  return sectionRemove ? (
    <></>
  ) : (
    <div className="w-100 noResult text-center">
      <img src={noResultImg} alt="No Result Found" className="noResultPic mb-3" />
      <h4 className="fs-2 fw-bold text-black mb-4">{header}</h4>
      <h6 className="fs-4 fw-normal text-black">{subHeader}</h6>
    </div>
  );
};

export default NoDataFound;
