import React, { memo, useEffect, useRef, useState } from 'react';
import {
  clinical_Video_static_image,
  grand_rounds_static_image
} from '../../../common/assets.common';
import { removeHtmlTags } from '../../../pages/channel/utils';
import LazyImage from '../../CustomLibraries/LazyImage';

import ContentCard from '../contentBasedCard/components/ContentCard';

import useRedirect from '../../customHooks/useRedirect';
import useShare from '../../customHooks/useShare';
import { useTrackUserActivity } from '../../customHooks/useTrackUserActivity';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';
import DoctorVoiceCard from './DoctorVoiceCard';
import SpeakerDoc from '../cards/SpeakerDoc';
import { Badge } from 'react-bootstrap';
import { CertificateIcon } from '../../icons';
import { isMobile } from 'react-device-detect';
import TooltipContainer from '../components/TooltipContainer';
import ReportContentPopover from '../cards/components/ReportContentPopover';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import dayjs from 'dayjs';
import { SHARE_CLICK } from '../../tracking/constants';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';

const RelatedCard = ({
  featured = false,
  type = '',
  src,
  cardType = '',
  className = null,
  image = null,
  title = '',
  contentType = '',
  sessionDoctorDetails = [],
  sponsor_logo = [],
  icon,
  type_id,
  internalType,
  id,
  sponsor_id,
  survey_id,
  pageId,
  fallbackImage = '',
  premiumContent,
  price = 20,
  userContentPayment = null,
  displayIcon,
  onClick = () => {},
  category,
  playTime,
  data,
  ...prop
}) => {
  // destructuring
  const {
    footerSection,
    shareIcon,
    point,
    startDateTime,
    sessionStatus,
    contentStatus,
    statusColor,
    duration,
    isVideo,
    isLive
  } = prop;
  const { trackActivityEvent } = useTracker();
  const { userActivityTracker } = useTrackUserActivity();
  const { contentAccessRedirect } = useContentAccessRedirect();
  const { redirectTo } = useRedirect();
  const share_url = useShare();
  const cardOptions = {
    flexDirection: cardType === 'small' ? 'row' : 'column'
  };
  // if (!type_id) return null;
  // else
  if (internalType === 'survey' && category === 'poll' && cardType === 'lg') {
    return (
      <DoctorVoiceCard icon="flaticon-poll" type={'poll'} data={data} isShare={data?.is_share} />
    );
  }

  function pad(string) {
    return ('0' + string).slice(-2);
  }

  function format(seconds) {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = pad(date.getUTCMinutes());
    const ss = pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${mm}:${ss}`;
    }
    return `${mm}:${ss}`;
  }

  // video
  const [loading, setLoading] = useState(false);
  const playerRef = useRef();
  const [showVideo, setShowVideo] = useState(false);
  const [videoRendered, setVideoRendered] = useState(false);
  const [clVideoref, inView] = useInView({
    fallbackInView: false,
    threshold: 0
  });
  const videoDetailsParent = useRef();
  const [aspectVideoContainerSize, setAspectVideoContainerSize] = useState('0px');
  const handleVideoShow = () => {
    setLoading(true);
    setShowVideo(true);
    setTimeout(() => {
      setShowVideo(false);
      setVideoRendered(false);
    }, 30000);
  };

  const handleStart = () => {
    setLoading(false);
    setVideoRendered(true);
  };
  const handleVideoClose = () => {
    setShowVideo(false);
    setLoading(false);
    setVideoRendered(false);
  };
  useEffect(() => {
    if (isMobile && !inView) {
      handleVideoClose();
    }
  }, [inView]);

  const onReady = React.useCallback(() => {
    if (playTime) {
      const timeToStart = playTime;
      playerRef.current.seekTo(timeToStart, 'seconds');
    }
  }, [playerRef.current]);

  const sponsorLogo =
    footerSection?.sponsorLogo && typeof footerSection?.sponsorLogo === 'string'
      ? footerSection?.sponsorLogo.split(',')
      : Array.isArray(footerSection?.sponsorLogo)
      ? footerSection?.sponsorLogo.map((_sp) => _sp.client_logo)
      : [];

  console.log('hhhhhhhhhhhhhhh', footerSection?.sponsorLogo);

  let UPCOMING_TBA_STATUS = 4;

  const noFooter =
    footerSection?.SpeakerDocs ||
    footerSection?.icon ||
    footerSection?.iconText ||
    footerSection?.sponsorLogo ||
    footerSection?.buttonTitle
      ? true
      : false;
  console.log(noFooter, 'noFooter');
  return (
    <ContentCard
      className={`${noFooter ? '' : 'pb-3'} clr_v3_card--related ${className ?? ''} ${
        internalType === 'archived_video'
          ? `clr_v3_card--clinicalVideos ${className ?? ''} ${loading ? 'loadPlayer' : ''}`
          : ''
      }
      ${internalType === 'epub' ? 'clr_v3_card--ebook' : ''}
      `}
      isLocked={displayIcon}
      type={cardType}
      options={{
        rounded: '3'
      }}
      data={data}
    >
      {displayIcon ? (
        <ContentCard.PremiumTag
          options={{
            iconSize: { default: '24', lg: '24' },
            fontSize: { default: '4', lg: '16' }
          }}
        ></ContentCard.PremiumTag>
      ) : (
        <></>
      )}
      <ContentCard.Container
        options={
          cardType != 'lg'
            ? { padding: { default: '3', md: '3' }, gap: { default: '2', md: '3' } }
            : {
                padding: { default: '0', md: '0' },
                paddingTop: { default: '0', md: '0' }
              }
        }
      >
        {cardType != 'small' && cardType != 'lg' && (
          <ContentCard.Header>
            {contentType && (
              <ContentCard.Tag
                options={{
                  iconName: `${icon}`,
                  iconSize: '16'
                }}
              >
                {contentType}
              </ContentCard.Tag>
            )}
          </ContentCard.Header>
        )}

        {/* card body */}
        <ContentCard.Body
          className={`${cardType === 'small' && 'mt-2 mt-md-0'}`}
          options={
            cardType === 'small' ? { flexDirection: 'row' } : { rowGap: { default: '3', md: '3' } }
          }
        >
          {internalType != 'epub' &&
          (internalType !== 'archived_video' ||
            (internalType === 'archived_video' && cardType === 'small')) ? (
            <ContentCard.Image
              onClick={onClick}
              options={cardType != 'lg' ? { rounded: '3' } : { rounded: '0' }}
            >
              <LazyImage
                src={image}
                alt="banner"
                fallback_image={fallbackImage ?? grand_rounds_static_image}
                className={`object-fit-cover w-100 h-100 transition`}
                extraClass="w-100 h-100 position-relative hover-image"
              />
              {isVideo == 'video' ? (
                <div className="overlay" onClick={onClick}>
                  <div className="position-absolute translate-middle start-50 top-50 playIconAnimation">
                    <i className="flaticon-play text-white position-absolute translate-middle start-50 top-50 z-1 display-3"></i>
                  </div>
                </div>
              ) : null}
              {cardType != 'small' && (
                <>
                  <div className="overlay bg-black bg-opacity-10"></div>
                  {isLive ? (
                    <div className="bg-white position-absolute bottom-0 start-0 ms-3 mb-3 z-2 rounded-1 shadow">
                      {true && data.session_status != 3 && (
                        <span className="text-danger fs-5 fw-semibold px-3 d-flex align-items-center gap-1">
                          <div className="pulseCircle me-2"></div> Live
                        </span>
                      )}
                    </div>
                  ) : startDateTime ? (
                    <>
                      {startDateTime && sessionStatus != UPCOMING_TBA_STATUS ? (
                        <Badge className="position-absolute bottom-0 start-0 ms-3 mb-3 z-2 rounded-1 shadow">
                          {dayjs(startDateTime).format('ddd')} {' | '}
                          {dayjs(startDateTime).format('MMM DD, YY')}
                          {' | '}
                          {dayjs(startDateTime).format('h:mm a')}{' '}
                        </Badge>
                      ) : sessionStatus == UPCOMING_TBA_STATUS ? (
                        <Badge className="position-absolute bottom-0 start-0 ms-3 mb-3 z-2 rounded-1 shadow">
                          Upcoming TBA
                        </Badge>
                      ) : null}
                    </>
                  ) : point ? (
                    <Badge className="position-absolute bottom-0 start-0 ms-3 mb-3 z-2 rounded-1 shadow">
                      {point}
                    </Badge>
                  ) : null}
                </>
              )}
            </ContentCard.Image>
          ) : null}

          {internalType === 'archived_video' && cardType !== 'small' ? (
            <div
              ref={videoDetailsParent}
              style={{ height: `${aspectVideoContainerSize}px` }}
              onMouseEnter={handleVideoShow}
              onMouseLeave={handleVideoClose}
              onTouchMove={handleVideoShow}
              className="cursorPointer"
            >
              <ContentCard.Image options={cardType != 'lg' ? { rounded: '3' } : { rounded: '0' }}>
                {!videoRendered && image && (
                  <>
                    <LazyImage
                      onClick={onClick}
                      src={image}
                      alt="banner"
                      fallback_image={clinical_Video_static_image}
                      className={`object-fit-cover w-100 h-100`}
                    />
                    <div className="overlay" onClick={onClick}>
                      <div className="position-absolute translate-middle start-50 top-50 playIconAnimation">
                        <i className="flaticon-play text-white position-absolute translate-middle start-50 top-50 z-1 display-3"></i>
                      </div>
                    </div>
                    {duration ? (
                      <span className="ms-5 text-white fs-4 rounded position-absolute translate-middle bottom-0 start-0 z-3 bg-black bg-opacity-75 py-1 px-2">
                        {format(duration)}
                      </span>
                    ) : null}
                  </>
                )}

                {showVideo && src && (
                  <div
                    className="w-100 h-100 cursorPointer bg-dark clr_v3_card__videoPlayerShow"
                    onClick={onClick}
                  >
                    <ReactPlayer
                      ref={playerRef}
                      onReady={onReady}
                      onStart={handleStart}
                      url={src}
                      playing={true}
                      controls={false}
                      width={'100%'}
                      height={'100%'}
                      muted={true}
                      playsinline={true}
                    />
                  </div>
                )}

                {loading && (
                  <div className="overlay clr_v3_card__videoPlayerloading" onClick={onClick}>
                    <div className="position-absolute translate-middle start-50 top-50 playIconAnimation">
                      <i className="flaticon-play text-white position-absolute translate-middle start-50 top-50 z-1 display-3"></i>
                    </div>
                  </div>
                )}
              </ContentCard.Image>
            </div>
          ) : null}

          {internalType === 'epub' && (
            <ContentCard.Image
              className={`justify-content-center`}
              options={cardType != 'lg' ? { bgColor: 'white' } : { bgColor: 'white', rounded: '0' }}
            >
              <div
                onClick={onClick}
                className="clr_v3_ebook__in my-3 border border-2 border-light position-relative"
              >
                <div className="position-relative overflow-hidden z-1" onClick={onClick}>
                  <LazyImage
                    // src={image}
                    // alt={title}
                    // fallback_image={ebook_static_image}
                    src={image}
                    alt="banner"
                    fallback_image={fallbackImage ?? grand_rounds_static_image}
                    className={`object-fit-cover w-100 h-100`}
                  />
                </div>
              </div>
            </ContentCard.Image>
          )}

          {title || contentType ? (
            <ContentCard.Content
              onClick={onClick}
              className={`${cardType === 'small' ? 'justify-content-center' : ''} ${
                cardType === 'lg' ? 'px-md-4 px-3 pt-0' : ''
              }`}
            >
              {(cardType === 'small' || cardType === 'lg') && (
                <ContentCard.Header>
                  {contentType && (
                    <ContentCard.Tag
                      options={{
                        iconName: `${icon}`,
                        iconSize: '16'
                      }}
                      contentStatus={contentStatus}
                      statusColor={statusColor}
                    >
                      {contentType}
                    </ContentCard.Tag>
                  )}

                  <ContentCard.Right>
                    {shareIcon &&
                    shareIcon?.isShare &&
                    shareIcon?.deeplink &&
                    shareIcon?.question ? (
                      <TooltipContainer text="Share">
                        <span
                          className={`${
                            cardType === 'small' ? 'icon-size-24 fs-4' : 'icon-size-32 fs-3'
                          }  clr_v3_card__icon transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative  clr_v3_icon-btn-1`}
                          onClick={(e) => {
                            e.stopPropagation();
                            trackActivityEvent(SHARE_CLICK, data);
                            share_url(shareIcon?.question, shareIcon?.deeplink);
                          }}
                        >
                          <i className="flaticon-share-arrow"></i>
                        </span>
                      </TooltipContainer>
                    ) : (
                      <></>
                    )}
                    {cardType === 'lg' && shareIcon?.moduleType ? (
                      <div onClick={(e) => e.stopPropagation()}>
                        <ReportContentPopover type={shareIcon?.moduleType} id={id} data={data} />
                      </div>
                    ) : null}
                  </ContentCard.Right>
                </ContentCard.Header>
              )}

              {title && (
                <ContentCard.Title
                  options={cardType != 'lg' ? { fontWeight: 'medium', fontSize: '4' } : {}}
                >
                  {removeHtmlTags(title)}
                </ContentCard.Title>
              )}
            </ContentCard.Content>
          ) : (
            <></>
          )}

          {cardType !== 'small' &&
          footerSection &&
          (footerSection?.SpeakerDocs ||
            footerSection?.icon ||
            footerSection?.iconText ||
            footerSection?.sponsorLogo ||
            footerSection?.buttonTitle) ? (
            <ContentCard.Footer
              className={`${cardType === 'lg' ? 'px-md-4 px-3 pb-md-4 pb-3' : ''}`}
            >
              {cardType === 'lg' ? (
                <>
                  {footerSection && (
                    <>
                      {footerSection?.SpeakerDocs ||
                      footerSection?.icon ||
                      footerSection?.iconText ? (
                        <ContentCard.LeftActionBar>
                          {footerSection?.SpeakerDocs ? (
                            <SpeakerDoc
                              fontSize={5}
                              iconSize={20}
                              smIconSize={24}
                              data={footerSection?.SpeakerDocs || []}
                            />
                          ) : footerSection?.icon || footerSection?.iconText ? (
                            <>
                              {footerSection?.icon && (
                                <ContentCard.FooterLeftIcon
                                  options={{ iconName: footerSection?.icon, fontSize: '5' }}
                                  onClick={onClick}
                                >
                                  {footerSection?.iconText ?? 0}
                                </ContentCard.FooterLeftIcon>
                              )}
                            </>
                          ) : null}
                        </ContentCard.LeftActionBar>
                      ) : null}
                      {/* right */}
                      {footerSection?.sponsorLogo && footerSection?.sponsorLogo?.length > 0 ? (
                        <ContentCard.Sponser sponsorLogo={sponsorLogo}></ContentCard.Sponser>
                      ) : footerSection?.buttonTitle ? (
                        <ContentCard.ButtonContainer onClick={onClick} className="ms-auto">
                          <ContentCard.Button
                            options={{ variant: 'outline-primary' }}
                            className="rounded-2 fs-5 py-2 px-3 "
                            onClick={onClick}
                          >
                            {footerSection?.buttonTitle}
                          </ContentCard.Button>
                        </ContentCard.ButtonContainer>
                      ) : null}
                    </>
                  )}
                </>
              ) : cardType === 'small' ? (
                <></>
              ) : (
                <ContentCard.ButtonContainer onClick={onClick} className="ms-auto">
                  <ContentCard.Button
                    options={{ variant: 'outline-primary' }}
                    className="rounded-2 fs-5 py-2 px-3 "
                    onClick={onClick}
                  >
                    View Details
                  </ContentCard.Button>
                </ContentCard.ButtonContainer>
              )}
            </ContentCard.Footer>
          ) : null}
        </ContentCard.Body>
      </ContentCard.Container>
    </ContentCard>
  );
};

export default memo(RelatedCard);
