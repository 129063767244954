import React, { memo } from 'react';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { ClirnetFullLogo } from '../../../components/icons';

const ClirnetIntro = ({ channel_name, channel_logo }) => {
  return (
    <div className="text-center d-none d-lg-flex d-md-flex flex-column align-items-center shareLoginV2LogoArea mb-5">
      <div className="w-100 h-100 position-relative">
        <img src={channel_logo} className="mb-4 shareLoginV2Logo mw-100" />
      </div>

      {/* <h1 className="fs-1 text-black fw-normal mb-2">Explore CLIRNET</h1> */}
      <h3 className="fs-3 text-primary fw-semibold m-0">{channel_name}</h3>
    </div>
  );
};

export default memo(ClirnetIntro);
